import React, { useState, useEffect } from 'react';
import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { Execution, Workflow } from '../../../../models/workflow';
import ExecutionDetails from '../ExecutionDetails';
import ExecutionFindings from '../ExecutionFindings';
import ExecutionWorkflow from '../ExecutionWorkflow';
import ExecutionTimeline from '../ExecutionTimeline';

interface ExecutionTabsProps {
  target: any;
  workflow: Workflow;
  execution?: Execution;
  initialTab?: string;
}

// Define the shape of each tab's configuration
interface TabConfig {
  key: string;
  label: string;
  component: React.ComponentType<any>;
  requiresExecution: boolean;
  getProps: (workflow: Workflow, execution?: Execution) => object;
}

const tabOptions: TabConfig[] = [
  {
    key: 'details',
    label: 'Details',
    component: ExecutionDetails,
    requiresExecution: false,
    getProps: (workflow, execution) => ({ workflow, execution }),
  },
  {
    key: 'timeline',
    label: 'Activity',
    component: ExecutionTimeline,
    requiresExecution: true,
    getProps: (_, execution) => ({ execution }),
  },
  {
    key: 'findings',
    label: 'Findings',
    component: ExecutionFindings,
    requiresExecution: true,
    getProps: (_, execution) => ({ execution }),
  },
  {
    key: 'workflow',
    label: 'Workflow',
    component: ExecutionWorkflow,
    requiresExecution: false,
    getProps: (workflow, execution) => ({ workflow, execution }),
  },
];

function ExecutionTabs({
  target,
  workflow,
  execution,
  initialTab = 'details',
}: ExecutionTabsProps) {
  // Find initial tab index, with proper fallback
  const initialTabIndex = tabOptions.findIndex(tab => tab.key === initialTab);
  const [tabIndex, setTabIndex] = useState(
    initialTabIndex !== -1 ? initialTabIndex : 0,
  );

  // Update tab index when initialTab prop changes
  useEffect(() => {
    const newIndex = tabOptions.findIndex(tab => tab.key === initialTab);
    if (newIndex !== -1) {
      setTabIndex(newIndex);
    }
  }, [initialTab]);

  return (
    <Tabs size="lg" index={tabIndex} onChange={setTabIndex} colorScheme="brand">
      <TabList>
        {tabOptions.map(
          tab =>
            (!tab.requiresExecution || execution) && (
              <Tab key={tab.key}>{tab.label}</Tab>
            ),
        )}
      </TabList>

      <TabPanels>
        {tabOptions.map(
          tab =>
            (!tab.requiresExecution || execution) && (
              <TabPanel key={tab.key} px={4} py={8}>
                {React.createElement(tab.component, {
                  target,
                  ...tab.getProps(workflow, execution),
                })}
              </TabPanel>
            ),
        )}
      </TabPanels>
    </Tabs>
  );
}

export default ExecutionTabs;
