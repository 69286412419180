// Mock value mapping functions for testing
import { FIELD_IDENTIFIERS } from '../../hooks/useQueryBuilder';

const mockRoleMap = new Map<string, string>([
  ['cm39bgcee00am7k78ty9dknxe', 'Senior Model Risk Manager'],
  ['abc123', 'Model Developer'],
  ['def456', 'Model Validator'],
]);

// Mock custom field details lookup
const mockCustomFieldDetails: Record<string, { label: string }> = {
  'custom_fields.isGenerativeAiModel': { label: 'Is Generative AI Model' },
  'custom_fields.estimatedExposure': { label: 'Estimated Exposure' },
  'custom_fields.areaOwner': { label: 'Area Owner' },
  'custom_fields.status': { label: 'Status' },
  'custom_fields.score': { label: 'Score' },
  'custom_fields.baseRisk': { label: 'Base Risk' },
  'custom_fields.riskMultiplier': { label: 'Risk Multiplier' },
  'custom_fields.mitigationScore': { label: 'Mitigation Score' },
  'custom_fields.region': { label: 'Region' },
  'custom_fields.exposure': { label: 'Exposure' },
  'custom_fields.priority': { label: 'Priority' },
  'custom_fields.lastUpdated': { label: 'Last Updated' },
  'custom_fields.isLegacy': { label: 'Is Legacy System' },
};

const mockValueMappers: Record<string, (value: any) => string> = {
  'user.roles': value => mockRoleMap.get(value) || value,
  'user.stakeholders': value => value, // No mapping needed, already human readable
  'custom_fields.areaOwner': value => value, // No mapping needed, already human readable
};

// Helper function to format dates
function formatDate(dateStr: string): string {
  try {
    const date = new Date(dateStr);
    if (isNaN(date.getTime())) return dateStr;
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  } catch (e) {
    return dateStr;
  }
}

// Helper function to check if a string is a date
function isDateString(str: any): boolean {
  if (typeof str !== 'string') return false;
  const date = new Date(str);
  return !isNaN(date.getTime());
}

interface JsonLogic {
  [key: string]: any;
}

interface Context {
  level?: number;
  currentItem?: string;
  arrayItems?: string[];
}

export function humanizeJsonLogic(
  logic: any,
  valueMappers: Record<string, (value: any) => string> = {},
  context: Context = {},
): string {
  if (logic === null || logic === undefined) {
    return '';
  }

  // Helper to format with bullets
  function formatWithBullets(items: string[], level: number = 0): string {
    const indent = '  '.repeat(level);
    return items
      .map(item => {
        // If item contains bullets, don't add another bullet
        if (item.includes('•')) {
          return `${indent}${item}`;
        }
        return `${indent}• ${item}`;
      })
      .join('\n');
  }

  // Helper to format OR conditions
  function formatOrConditions(conditions: string[], level: number = 0): string {
    const indent = '  '.repeat(level);
    if (conditions.length > 2) {
      return `${indent}Any of the following:\n${formatWithBullets(
        conditions,
        level + 1,
      )}`;
    }
    return conditions.join(' or ');
  }

  // Helper to format negation
  function formatNegation(condition: string): string {
    // Common patterns to make more readable
    if (condition.includes(' is ')) {
      return condition.replace(' is ', ' is not ');
    }
    if (condition.includes('user has')) {
      return condition.replace('user has', 'user does not have');
    }
    if (condition.includes('user is')) {
      return condition.replace('user is', 'user is not');
    }
    if (condition.startsWith('every ')) {
      return condition.replace('every ', 'not every ');
    }
    if (condition.startsWith('at least one ')) {
      return condition.replace('at least one ', 'none of the ');
    }
    // Default case
    return `does not match: ${condition}`;
  }

  // Handle primitive values
  if (typeof logic !== 'object') {
    if (isDateString(logic)) {
      return formatDate(logic);
    }
    // Handle booleans more naturally
    if (typeof logic === 'boolean') {
      return logic ? 'enabled' : 'disabled';
    }
    // Format numbers with commas
    if (typeof logic === 'number' || !isNaN(logic)) {
      return Number(logic).toLocaleString('en-US');
    }
    // Handle array context items
    if (Array.isArray(context.arrayItems)) {
      return context.arrayItems.join(' or ');
    }
    return String(logic);
  }

  // Handle var references
  if (logic.var !== undefined) {
    // Special case for empty var in array operations (refers to current item)
    if (logic.var === '') {
      return context.currentItem || 'value';
    }
    // Apply valueMappers for custom fields
    if (logic.var.startsWith('custom_fields.')) {
      const mappedValue = mapValue(logic.var, logic.var, valueMappers);
      return `Field "${mappedValue}"`;
    }
    return `${logic.var.split('.').pop()}`; // Just show the last part of the path
  }

  // Get the operator (first key) and its arguments
  const operator = Object.keys(logic)[0];
  const values = logic[operator];

  switch (operator) {
    case 'and': {
      const conditions = values
        .map((v: any) =>
          humanizeJsonLogic(v, valueMappers, {
            ...context,
            level: (context.level || 0) + 1,
          }),
        )
        .filter((v: string) => v);

      // Use bullets for complex AND conditions
      if (conditions.length > 2) {
        return `All of the following:\n${formatWithBullets(
          conditions,
          context.level || 0,
        )}`;
      }
      return conditions.join(', and ');
    }

    case 'or': {
      const conditions = values
        .map((v: any) => {
          const result = humanizeJsonLogic(v, valueMappers, {
            ...context,
            level: (context.level || 0) + 1,
          });
          // If it's an AND condition, wrap it in parentheses for clarity
          if (v.and && result.includes(', and ')) {
            return `(${result})`;
          }
          return result;
        })
        .filter((v: string) => v);

      return formatOrConditions(conditions, context.level || 0);
    }

    case 'in': {
      const [item, list] = values;
      const itemStr =
        typeof item === 'object'
          ? humanizeJsonLogic(item, valueMappers, context)
          : mapValue(item, list.var, valueMappers);
      const listStr = humanizeJsonLogic(list, valueMappers, context);

      // Special handling for roles and stakeholders
      if (list.var === FIELD_IDENTIFIERS.USER_ROLES) {
        return `user has the ${itemStr} role`;
      }
      if (list.var === FIELD_IDENTIFIERS.USER_MODEL_STAKEHOLDERS) {
        return `user is a ${itemStr}`;
      }
      return `${itemStr} is one of the ${listStr}`;
    }

    case 'some': {
      const [array, condition] = values;
      const arrayName = humanizeJsonLogic(array, valueMappers, context);

      // Special handling for email lists
      if (condition.in && Array.isArray(condition.in[1])) {
        const items = condition.in[1];
        return `at least one ${arrayName} is ${items.join(' or ')}`;
      }

      const conditionStr = humanizeJsonLogic(condition, valueMappers, {
        ...context,
        currentItem: 'item',
      });
      return `at least one ${arrayName} ${conditionStr}`;
    }

    case 'all': {
      const [array, condition] = values;
      const arrayName = humanizeJsonLogic(array, valueMappers, context);
      const conditionStr = humanizeJsonLogic(condition, valueMappers, {
        ...context,
        currentItem: 'item',
      });

      // Special handling for numeric comparisons
      if (condition['>=']) {
        return `every ${arrayName} is at least ${humanizeJsonLogic(
          condition['>='][1],
          valueMappers,
        )}`;
      }
      if (condition['<=']) {
        return `every ${arrayName} is at most ${humanizeJsonLogic(
          condition['<='][1],
          valueMappers,
        )}`;
      }
      if (condition['>']) {
        return `every ${arrayName} is greater than ${humanizeJsonLogic(
          condition['>'][1],
          valueMappers,
        )}`;
      }
      if (condition['<']) {
        return `every ${arrayName} is less than ${humanizeJsonLogic(
          condition['<'][1],
          valueMappers,
        )}`;
      }

      return `every ${arrayName} ${conditionStr}`;
    }

    case 'none': {
      const [array, condition] = values;
      const arrayName = humanizeJsonLogic(array, valueMappers, context);
      const conditionStr = humanizeJsonLogic(condition, valueMappers, {
        ...context,
        currentItem: 'item',
      });

      // Special handling for equality
      if (condition['==']) {
        return `no ${arrayName} is ${humanizeJsonLogic(
          condition['=='][1],
          valueMappers,
        )}`;
      }

      return `no ${arrayName} ${conditionStr}`;
    }

    case 'merge': {
      return values
        .map((v: any) => humanizeJsonLogic(v, valueMappers, context))
        .filter((v: string) => v)
        .join(' and ');
    }

    case '==': {
      const [left, right] = values;
      const leftStr = humanizeJsonLogic(left, valueMappers, context);
      const rightStr = humanizeJsonLogic(right, valueMappers, context);

      // Special handling for boolean fields
      if (right === true) return `${leftStr} is enabled`;
      if (right === false) return `${leftStr} is disabled`;

      return `${leftStr} is ${rightStr}`;
    }

    case '!=': {
      const [left, right] = values;
      const leftStr = humanizeJsonLogic(left, valueMappers, context);
      const rightStr = humanizeJsonLogic(right, valueMappers, context);

      // Special handling for boolean fields
      if (right === true) return `${leftStr} is not enabled`;
      if (right === false) return `${leftStr} is not disabled`;

      return `${leftStr} is not ${rightStr}`;
    }

    case '>': {
      const [left, right] = values;
      const leftStr = humanizeJsonLogic(left, valueMappers, context);
      const rightStr = humanizeJsonLogic(right, valueMappers, context);

      // Special handling for dates
      if (isDateString(right)) {
        return `${leftStr} is after ${rightStr}`;
      }
      return `${leftStr} is greater than ${rightStr}`;
    }

    case '>=': {
      const [left, right] = values;
      const leftStr = humanizeJsonLogic(left, valueMappers, context);
      const rightStr = humanizeJsonLogic(right, valueMappers, context);

      // Special handling for dates
      if (isDateString(right)) {
        return `${leftStr} is on or after ${rightStr}`;
      }
      return `${leftStr} is at least ${rightStr}`;
    }

    case '<': {
      const [left, right] = values;
      const leftStr = humanizeJsonLogic(left, valueMappers, context);
      const rightStr = humanizeJsonLogic(right, valueMappers, context);

      // Special handling for dates
      if (isDateString(right)) {
        return `${leftStr} is before ${rightStr}`;
      }
      return `${leftStr} is less than ${rightStr}`;
    }

    case '<=': {
      const [left, right] = values;
      const leftStr = humanizeJsonLogic(left, valueMappers, context);
      const rightStr = humanizeJsonLogic(right, valueMappers, context);

      // Special handling for dates
      if (isDateString(right)) {
        return `${leftStr} is on or before ${rightStr}`;
      }
      return `${leftStr} is at most ${rightStr}`;
    }

    case '!': {
      const result = humanizeJsonLogic(values, valueMappers, {
        ...context,
        level: (context.level || 0) + 1,
      });

      // If the result is already bulleted, wrap it with "None of the following"
      if (result.includes('•')) {
        return `None of the following:\n${result}`;
      }

      // If it's an OR condition, we need special handling
      if (result.includes(' or ')) {
        return `neither ${result.replace(/ or /g, ' nor ')}`;
      }

      // If it's an AND condition, we need special handling
      if (result.includes(', and ')) {
        return result.split(', and ').map(formatNegation).join(', and ');
      }

      return formatNegation(result);
    }

    case '+':
      return values
        .map((v: any) => humanizeJsonLogic(v, valueMappers, context))
        .join(' plus ');

    case '-':
      return values
        .map((v: any) => humanizeJsonLogic(v, valueMappers, context))
        .join(' minus ');

    case '*':
      return values
        .map((v: any) => humanizeJsonLogic(v, valueMappers, context))
        .join(' times ');

    case '/':
      return values
        .map((v: any) => humanizeJsonLogic(v, valueMappers, context))
        .join(' divided by ');

    default:
      return `Unknown operator: ${operator}`;
  }
}

function mapValue(
  value: any,
  varPath: string,
  valueMappers: Record<string, (value: any) => string>,
): string {
  if (valueMappers && valueMappers[varPath]) {
    return valueMappers[varPath](value);
  }
  return value;
}
