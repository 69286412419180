import {
  Box,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';
import {
  NoSymbolIcon,
  EllipsisVerticalIcon,
  PencilIcon,
} from '@heroicons/react/24/outline';
import { Execution, Workflow } from '../../../../models/workflow';
import {
  useNavigation,
  WorkflowManagerNavigationState,
} from '../../contexts/navigation/NavigationContext';
import { useWorkflow } from '../../contexts/workflow/WorkflowContext';

interface WorkflowOptionsMenuProps {
  workflow: Workflow;
  execution?: Execution;
}

interface MenuItemConfig {
  label: string;
  icon: any;
  onClick: () => Promise<void>;
  isDisabled: boolean;
  styles?: Record<string, any>;
}

function WorkflowOptionsMenu({
  workflow,
  execution,
}: WorkflowOptionsMenuProps) {
  const { navigate } = useNavigation();
  const { loadWorkflowExecution, canManageWorkflowExecution } = useWorkflow();

  const handleEditWorkflow = () => {
    window.location.href = `/settings/workflows/${workflow.cuid}/latest`;
  };

  const handleAbortWorkflow = async () => {
    // First load the workflow and execution data if we have an execution
    await loadWorkflowExecution(workflow.cuid!, execution?.cuid);

    // Then navigate to abort view
    navigate(WorkflowManagerNavigationState.DETAIL_ACTION_EXECUTION_ABORT);
  };

  const menuItems: MenuItemConfig[] = [
    {
      label: 'Edit Workflow',
      icon: PencilIcon,
      onClick: async () => handleEditWorkflow(),
      isDisabled: !canManageWorkflowExecution,
    },
    {
      label: 'Abort Workflow',
      icon: NoSymbolIcon,
      onClick: handleAbortWorkflow,
      isDisabled: !(
        canManageWorkflowExecution &&
        (!execution || execution.can_abort)
      ),
      styles: {
        _hover: {
          bg: 'red.100',
          color: 'red.600',
        },
      },
    },
  ];

  return (
    <Box onClick={e => e.stopPropagation()}>
      <Menu placement="bottom-end">
        <MenuButton
          as={IconButton}
          aria-label="Options"
          icon={<Icon as={EllipsisVerticalIcon} boxSize={6} />}
          variant="ghost"
        />
        <MenuList shadow="lg">
          {menuItems.map(({ label, icon, onClick, isDisabled, styles }) => (
            <MenuItem
              key={label}
              icon={<Icon as={icon} boxSize={6} />}
              onClick={onClick}
              isDisabled={isDisabled}
              {...styles}
            >
              {label}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </Box>
  );
}

export default WorkflowOptionsMenu;
