import { useQuery } from 'react-query';
import API from '../../api/API';
import {
  TInventoryModelLayout,
  TModelInventoryLayoutItem,
} from '../../models/inventory_model';
import { SchemaPropertyItem } from '../../models/json_schemas';
import { useContext } from 'react';
import { UsersContext } from '../../contexts';

function useModelPageLayout() {
  const { currentOrganization } = useContext(UsersContext);

  const queryResponse = useQuery(
    ['model-inventory', 'layout', currentOrganization?.cuid],
    async () => {
      try {
        const response = await API.GetModelPageLayout();
        return response;
      } catch (e) {
        return undefined;
      }
    },
    {
      staleTime: 1000 * 60 * 60,
    },
  );

  const modelPageLayoutResponse = queryResponse.data;

  const defaultPageLayout = (
    propertyItems: SchemaPropertyItem[],
  ): TInventoryModelLayout => {
    let layoutObject: TInventoryModelLayout = {
      cuid: undefined,
      layout: {
        mainColumnItems: [],
        sideColumnItems: [],
      },
    };

    const sortByTitle = (a: any, b: any) => {
      if (a.title < b.title) {
        return -1;
      }
      if (a.title > b.title) {
        return 1;
      }
      return 0;
    };

    propertyItems.sort(sortByTitle).forEach(propertyItem => {
      if (
        propertyItem.typeId === 'string:multi-line' ||
        propertyItem.typeId === 'array:attachments'
      ) {
        layoutObject.layout.mainColumnItems.push({
          propertyKey: propertyItem.key,
          typeId: propertyItem.typeId,
          label: propertyItem.title,
          isOmitted: false,
        });
      } else {
        layoutObject.layout.sideColumnItems.push({
          propertyKey: propertyItem.key,
          typeId: propertyItem.typeId,
          label: propertyItem.title,
          isOmitted: false,
        });
      }
    });

    // Separate attachment fields from other fields
    const attachmentFields = layoutObject.layout.mainColumnItems.filter(
      field => field.typeId === 'array:attachments',
    );
    const nonAttachmentFields = layoutObject.layout.mainColumnItems.filter(
      field => field.typeId !== 'array:attachments',
    );

    // Combine the sorted fields with attachment fields at the end
    layoutObject.layout.mainColumnItems = [
      ...nonAttachmentFields,
      ...attachmentFields,
    ];

    return layoutObject;
  };

  const transformLayoutResponseToLayout = (
    propertyItems: SchemaPropertyItem[],
  ): TInventoryModelLayout => {
    const mainColumnItems: TModelInventoryLayoutItem[] = [];
    const sideColumnItems: TModelInventoryLayoutItem[] = [];

    const layoutResponse = modelPageLayoutResponse!;

    layoutResponse.layout.mainColumn.forEach(item => {
      const propertyItem = propertyItems.find(
        property => property.key === item.propertyKey,
      );
      if (propertyItem) {
        mainColumnItems.push({
          propertyKey: item.propertyKey,
          label: propertyItem.title,
          isOmitted: item.isOmitted,
          typeId: propertyItem.typeId,
        });
      }
    });

    layoutResponse.layout.sideColumn.forEach(item => {
      const propertyItem = propertyItems.find(
        property => property.key === item.propertyKey,
      );
      if (propertyItem) {
        sideColumnItems.push({
          propertyKey: item.propertyKey,
          label: propertyItem.title,
          isOmitted: item.isOmitted,
          typeId: propertyItem.typeId,
        });
      }
    });

    // Find any properties that are not in the layout response and add them to the end
    propertyItems.forEach(propertyItem => {
      const mainColumnItem = mainColumnItems.find(
        item => item.propertyKey === propertyItem.key,
      );
      const sideColumnItem = sideColumnItems.find(
        item => item.propertyKey === propertyItem.key,
      );
      if (!mainColumnItem && !sideColumnItem) {
        if (
          propertyItem.typeId === 'string:multi-line' ||
          propertyItem.typeId === 'array:attachments'
        ) {
          mainColumnItems.push({
            propertyKey: propertyItem.key,
            label: propertyItem.title,
            isOmitted: false,
            typeId: propertyItem.typeId,
          });
        } else {
          sideColumnItems.push({
            propertyKey: propertyItem.key,
            label: propertyItem.title,
            isOmitted: false,
            typeId: propertyItem.typeId,
          });
        }
      }
    });

    return {
      cuid: layoutResponse.cuid,
      layout: {
        mainColumnItems,
        sideColumnItems,
      },
    };
  };

  return {
    isLoading: queryResponse.isLoading || queryResponse.isFetching || false,
    getPageLayout: (propertyItems: SchemaPropertyItem[]) => {
      if (modelPageLayoutResponse) {
        return transformLayoutResponseToLayout(propertyItems);
      } else {
        return defaultPageLayout(propertyItems);
      }
    },
    refetch: queryResponse.refetch,
  };
}

export { useModelPageLayout };
