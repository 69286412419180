import React from 'react';
import { Execution } from '../../../../models/workflow';
import { FindingFilters } from '../../../../models/finding';
import { TInventoryModel } from '../../../../models/inventory_model';
import FindingsWidget from '../../../FindingsWidget';

interface ExecutionFindingsProps {
  execution: Execution;
}

function ExecutionFindings({ execution }: ExecutionFindingsProps) {
  // Create filters for findings based on execution timeline
  const filters: FindingFilters = {
    inventory_models: [execution.target.cuid],
    created_at__gte: execution.timeline.starts_at,
    created_at__lte: execution.timeline.ends_at,
  };

  return (
    <FindingsWidget
      inventoryModel={execution.target as TInventoryModel}
      variant="execution-timeline"
      allowAddFinding={false}
      initialFilters={filters}
    />
  );
}

export default ExecutionFindings;
