import { Stack, StackDivider, Text } from '@chakra-ui/react';
import WorkflowApprovalItem from '../WorkflowApprovalItem';
import useApprovals from '../../hooks/useApprovals';
import { ApprovalVoterStatus } from '../../models/approval';

interface WorkflowApprovalActionsProps {
  variant?: 'page' | 'vertical';
  voterStatus?: ApprovalVoterStatus | null;
  approvalStatus?: 'approved' | 'rejected' | 'pending';
  targetType?: string;
  targetCuid?: string;
  executionCuid?: string;
  onVoteSubmitted?: () => void;
}
export default function WorkflowApprovalActions({
  variant,
  voterStatus,
  approvalStatus = 'pending',
  targetType,
  targetCuid,
  executionCuid,
  onVoteSubmitted,
}: WorkflowApprovalActionsProps) {
  const { data, isLoading } = useApprovals(
    voterStatus,
    approvalStatus,
    targetType,
    targetCuid,
    executionCuid,
  );

  if (!data || (!data?.length && variant !== 'page')) {
    return null;
  }

  if (!data?.length && !isLoading && variant === 'page') {
    return <Text>No approvals at this moment</Text>;
  }

  if (data?.length > 0) {
    return (
      <Stack w={'full'}>
        {data.map(voter => (
          <WorkflowApprovalItem
            key={voter.cuid}
            variant={variant}
            voter={voter}
            onVoteSubmitted={onVoteSubmitted}
          />
        ))}
        <StackDivider
          w={'full'}
          h={10}
          borderColor={'var(--chakra-colors-chakra-border-color)'}
        />
      </Stack>
    );
  }

  return null;
}
