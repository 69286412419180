import React from 'react';
import { NavigationProvider } from '../contexts/navigation/NavigationContext';
import { WorkflowProvider } from '../contexts/workflow/WorkflowContext';
import { ModalProvider } from '../contexts/modal/ModalContext';
import { UserActionProvider } from '../contexts/userAction/UserActionContext';

interface WorkflowManagerProviderProps {
  children: React.ReactNode;
  target: any;
}

export function WorkflowManagerProvider({
  children,
  target,
}: WorkflowManagerProviderProps) {
  return (
    <NavigationProvider>
      <WorkflowProvider target={target}>
        <ModalProvider>
          <UserActionProvider target={target}>{children}</UserActionProvider>
        </ModalProvider>
      </WorkflowProvider>
    </NavigationProvider>
  );
}
