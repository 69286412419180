import { FC } from 'react';
import { Tag, TagRightIcon, Text } from '@chakra-ui/react';
import { State } from '../../models/workflow_state';
import { removeAfterDot } from '../../utils';
import { ArrowsRightLeftIcon } from '@heroicons/react/24/outline';

interface ExecutionStateTagProps {
  state: State;
}

const ExecutionStateTag = ({ state }: ExecutionStateTagProps) => {
  const colorScheme = state
    ? removeAfterDot(state.colors?.primary || 'neutral')
    : 'neutral';

  return (
    <Tag colorScheme={colorScheme} size="sm" variant="outline">
      <TagRightIcon boxSize={4} as={ArrowsRightLeftIcon} mr={2} ml={1} />
      <Text noOfLines={1}>{state.name}</Text>
    </Tag>
  );
};

export default ExecutionStateTag;
