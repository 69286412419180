import { defineStyleConfig } from '@chakra-ui/react';

export const Toast = defineStyleConfig({
  baseStyle: {
    field: {},
    container: {},
    thumb: {},
    track: {
      _checked: {},
    },
  },
  defaultProps: {
    variant: 'subtle',
  },
});
