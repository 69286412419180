import { ChangeEvent, useContext, useEffect, useState } from 'react';
import API, { TOrganization } from '../../../api/API';
import { LoadingContainer } from '../../../components/LoadingContainer';
import UsersContext from '../../../contexts/UsersContext';
import SidebarContext from '../../../contexts/SidebarContext';
import { useMutation } from 'react-query';
import {
  Box,
  HStack,
  Heading,
  Input,
  Stack,
  Text,
  VStack,
  useColorModeValue,
  Select,
  useToast,
  SimpleGrid,
  Button,
} from '@chakra-ui/react';
import Breadcrumbs from '../../../components/Breadcrumbs';
import { ContentPageTitle } from '../../../components/Layout';
import { BusinessUnitsPanel } from './BusinessUnitsPanel';
import { UseCasesPanel } from './UseCasesPanel';
import MoreInfoPopOver from '../../../components/MoreInfoPopOver';
import { useAuth } from 'react-oidc-context';
import { CONFIG } from '../../../config';

export interface SelectOptions {
  label: string;
  value: string;
}

const ArrayToSelectOptions = (arr?: any[]): SelectOptions[] =>
  arr?.map(element => ({
    label: element.name || element,
    value: element.cuid || element.id || element,
  })) || [];

export default function Organization() {
  const { user } = useAuth();
  const { userHasPermission } = useContext(UsersContext);
  const { setInSettings } = useContext(SidebarContext);
  const [isLoading, setLoading] = useState(true);
  const [hasOrgNameChanged, setHasOrgNameChanged] = useState(false);
  const [orgName, setOrgName] = useState('');
  const [organization, setOrganization] = useState<TOrganization>();
  const toast = useToast();
  const {
    currentUser,
    currentOrganization,
    setCurrentOrganization,
    refetchCurrentUser,
  } = useContext(UsersContext);

  const canUpdateOrganization = userHasPermission(
    ['update_organization'],
    'all',
  );

  const patchOrganization = useMutation(
    ['organizations', 'me'],
    async () => {
      return API.UpdateOrganization(orgName);
    },
    {
      onSuccess: data => {
        setOrganization(data);
        setCurrentOrganization(data);
        setHasOrgNameChanged(false);
        refetchCurrentUser();
      },
      onError: error => {
        if (error instanceof Error) {
          toast({
            variant: 'subtle',
            title: 'Error updating organization name',
            description: API.getAPIErrorMessage(error),
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        }
      },
    },
  );

  const patchUserOrganization = useMutation(
    ['user', 'me'],
    async () => {
      return await API.PatchUserOrganization(currentUser?.cuid);
    },
    {
      onSuccess: data => {
        refetchCurrentUser();
      },
      onError: error => {
        if (error instanceof Error) {
          toast({
            variant: 'subtle',
            title: 'Error updating user',
            description: API.getAPIErrorMessage(error),
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        }
      },
    },
  );

  useEffect(() => {
    setInSettings(true);
    return () => {
      setInSettings(false);
    };
  }, []);

  const onInputValueChange = (e: any) => {
    setHasOrgNameChanged(
      currentOrganization?.name !== e.target.value && e.target.value !== '',
    );
    setOrgName(e.target.value);
  };

  const updateOrgName = async () => {
    patchOrganization.mutate();
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      setOrgName(currentOrganization?.name ?? '');
      setLoading(false);
    })();
  }, [user, currentOrganization]);

  const OnChangeOrganization = async (e: ChangeEvent<HTMLSelectElement>) => {
    const selectedOrg = currentUser?.organizations.find(
      org => org.cuid === e.target.value,
    );

    const isValidOrg = !!selectedOrg;

    if (isValidOrg) {
      setOrganization(selectedOrg);
      setOrgName(selectedOrg.name);
      setCurrentOrganization(selectedOrg);
      patchUserOrganization.mutate();
    }
  };

  const userHasMoreThanOneOrg = () =>
    currentUser && currentUser.organizations?.length > 1;

  return (
    <LoadingContainer isLoading={isLoading || !currentUser}>
      <VStack
        alignItems="start"
        spacing={0}
        paddingTop={12}
        mt={1}
        paddingBottom={16}
        px={14}
        gap={4}
        w="full"
        overflow="auto"
        className="no-scrollbar"
        data-testid="groups-settings"
        maxWidth={'7xl'}
        mx={'auto'}
      >
        <Box>
          <Breadcrumbs />
          <ContentPageTitle>
            Organization
            <MoreInfoPopOver
              title="Manage Your Organization"
              description="Your organization encompasses all your users, groups, and business units."
              link={`${CONFIG.VALIDMIND_DOCS_URL}/guide/configuration/managing-your-organization.html`}
              placement="right-end"
              iconProps={{
                ml: 2,
              }}
            />
          </ContentPageTitle>
        </Box>
        <Text fontSize="md">
          All of the users in this organization will see these values.
        </Text>
        {userHasMoreThanOneOrg() ? (
          <Stack
            w={'full'}
            gap={4}
            padding={4}
            border={'1px solid'}
            rounded={'md'}
            borderColor={'var(--chakra-colors-chakra-border-color)'}
            bg={useColorModeValue('neutral.50', 'neutral.900')}
          >
            <Heading as="h2" fontSize="large" fontWeight="semibold">
              Organization
            </Heading>
            <HStack spacing={4}>
              <Select
                onChange={OnChangeOrganization}
                value={
                  currentOrganization ? currentOrganization?.cuid : undefined
                }
                colorScheme="brand"
                rounded="lg"
                ml={2}
              >
                {ArrayToSelectOptions(currentUser?.organizations)
                  .sort((a, b) => a.label.localeCompare(b.label))
                  .map(option => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
              </Select>
            </HStack>
          </Stack>
        ) : null}
        <Stack
          w={'full'}
          gap={4}
          padding={4}
          border={'1px solid'}
          rounded={'md'}
          borderColor={'var(--chakra-colors-chakra-border-color)'}
        >
          <Heading as="h3">Organization Name</Heading>
          <HStack spacing={1}>
            <Input
              id="orgName"
              data-defaultvalue="My Organization Name"
              type="text"
              isReadOnly={!canUpdateOrganization}
              value={orgName}
              onChange={onInputValueChange}
              bg={useColorModeValue('white', 'neutral.800')}
            />
            {canUpdateOrganization && (
              <Button
                onClick={() => updateOrgName()}
                isDisabled={!hasOrgNameChanged}
                variant={'outline'}
              >
                Update
              </Button>
            )}
          </HStack>
        </Stack>
        <HStack w={'full'} gap={4} alignItems={'flex-start'}>
          <SimpleGrid columns={{ base: 1, xl: 2 }} gap={4} width={'full'}>
            <BusinessUnitsPanel />
            <UseCasesPanel />
          </SimpleGrid>
        </HStack>
      </VStack>
    </LoadingContainer>
  );
}
