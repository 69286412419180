import { useContext, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import ValidMindLogo from '../../components/ValidMindLogo';
import {
  Box,
  Button,
  Center,
  Checkbox,
  FormControl,
  FormHelperText,
  Grid,
  GridItem,
  HStack,
  Input,
  Link,
  Stack,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import API from '../../api/API';
import { AxiosError } from 'axios';
import { LoadingContainer } from '../../components/LoadingContainer';
import UsersContext from '../../contexts/UsersContext';
import ProductShots from '../../components/ProductShots';
import { Copyright } from '../../components/Copyright';
import { Label } from '../../components/Layout';
import { AITermsOfUseUrl } from '../../utils';
import { ExternalLinkIcon } from '@chakra-ui/icons';

export default function Onboarding() {
  const toast = useToast();
  const { currentUser } = useContext(UsersContext);
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [organizationName, setOrganizationName] = useState('');
  const [jobTitle, setJobTitle] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [hasOptedInForNewsletter, setHasOptedInForNewsletter] = useState(false);
  const [hasOptedInForSlack, setHasOptedInForSlack] = useState(false);
  const [hasAcceptedTermsAndConditions, setHasAcceptedTermsAndConditions] =
    useState(false);
  const [hasAcceptedTermsOfAIUse, setHasAcceptedTermsOfAIUse] = useState(false);
  const [populateDemoModels, setPopulateDemoModels] = useState(true);

  const [searchParams] = useSearchParams();

  const isInvitedUser = !!searchParams.get('invitedUser');

  useEffect(() => {
    if (currentUser) {
      setFirstName(currentUser.name);
      setLastName(currentUser.last_name || '');
    }
  }, [currentUser]);

  const { isLoading: organizationCheckIsLoading } = useQuery(
    ['onboarding-check'],
    async () => {
      // if the user is already part of an organization, this request will succeed.
      return await API.GetOrganization();
    },
    {
      refetchOnWindowFocus: false,
      onSuccess: () => {
        if (!isInvitedUser) {
          navigate('/');
        }
      },
    },
  );

  const createOrganization = useMutation(
    [],
    async () => {
      return await API.CreateOrganization(
        organizationName,
        firstName,
        lastName,
        jobTitle,
        hasOptedInForNewsletter,
        hasOptedInForSlack,
        hasAcceptedTermsAndConditions,
        hasAcceptedTermsOfAIUse,
        populateDemoModels,
      );
    },
    {
      onSuccess: () => {
        // refetch and hydrate queries in App.tsx
        queryClient.invalidateQueries({
          queryKey: ['me'],
        });
        queryClient.invalidateQueries({
          queryKey: ['users', 'me'],
        });
        navigate('/');
      },
      onError: (error: AxiosError) => {
        toast({
          variant: 'subtle',
          title: 'Error',
          description: API.getAPIErrorMessage(error),
          status: 'error',
        });
      },
    },
  );

  const updateUser = useMutation(
    [],
    async () => {
      return await API.PatchUserOrganization(currentUser?.cuid, {
        name: firstName,
        last_name: lastName,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ['me'],
        });
        navigate('/');
      },
      onError: (error: AxiosError) => {
        toast({
          variant: 'subtle',
          title: 'Error',
          description: API.getAPIErrorMessage(error),
          status: 'error',
        });
      },
    },
  );

  const onContinue = async () => {
    if (isInvitedUser) {
      await updateUser.mutateAsync();
      return;
    }
    createOrganization.mutateAsync();
  };

  const submitDisabled = isInvitedUser
    ? firstName?.length === 0 || lastName?.length === 0 || updateUser.isLoading
    : firstName?.length === 0 ||
      lastName?.length === 0 ||
      organizationName?.length === 0 ||
      jobTitle?.length === 0 ||
      !hasAcceptedTermsAndConditions ||
      !hasAcceptedTermsOfAIUse ||
      createOrganization.isLoading;

  return (
    <LoadingContainer isLoading={organizationCheckIsLoading}>
      <Grid
        templateColumns={{ base: 'repeat(1, 1fr)', xl: 'repeat(8, 1fr)' }}
        height={{ xl: '100vh' }}
        bg={'neutral.25'}
        color={'neutral.800'}
      >
        <GridItem colSpan={3}>
          <VStack height={'full'} justifyContent={'space-between'}>
            <Center height={'full'}>
              <VStack px={16} pt={16} alignItems={'self-start'} gap={8}>
                <Box color={'brand.base'}>
                  <ValidMindLogo />
                </Box>
                <Stack>
                  <Text fontSize={'md'} fontWeight={'semibold'}>
                    Almost done!
                  </Text>
                  <Text maxWidth={'prose'}>
                    Help us complete your profile to improve your experience.
                  </Text>
                </Stack>
                <Stack w={'full'}>
                  <Stack
                    w={'full'}
                    direction={{
                      base: 'column',
                      md: 'row',
                    }}
                  >
                    <FormControl pt={2} isRequired>
                      <Label mb={2}>FIRST NAME:</Label>
                      <Input
                        type="text"
                        bg={'white !important'}
                        borderColor={'neutral.400 !important'}
                        value={firstName}
                        onChange={event => setFirstName(event.target.value)}
                        focusBorderColor="brand.base"
                        _focus={{
                          borderColor: 'brand.base !important',
                        }}
                      />
                    </FormControl>
                    <FormControl pt={2} isRequired>
                      <Label mb={2}>LAST NAME:</Label>
                      <Input
                        type="text"
                        bg={'white !important'}
                        borderColor={'neutral.400 !important'}
                        value={lastName}
                        onChange={event => setLastName(event.target.value)}
                        focusBorderColor="brand.base"
                        _focus={{
                          borderColor: 'brand.base !important',
                        }}
                      />
                    </FormControl>
                  </Stack>
                  <Stack>
                    {!isInvitedUser && (
                      <FormControl pt={2} isRequired>
                        <Label mb={2}>ORGANIZATION:</Label>
                        <Input
                          type="text"
                          bg={'white !important'}
                          borderColor={'neutral.400 !important'}
                          value={organizationName}
                          onChange={event =>
                            setOrganizationName(event.target.value)
                          }
                          focusBorderColor="brand.base"
                          _focus={{
                            borderColor: 'brand.base !important',
                          }}
                        />
                      </FormControl>
                    )}
                    {!isInvitedUser && (
                      <FormControl pt={2} isRequired>
                        <Label mb={2}>JOB TITLE:</Label>
                        <Input
                          type="text"
                          bg={'white !important'}
                          borderColor={'neutral.400 !important'}
                          value={jobTitle}
                          onChange={event => setJobTitle(event.target.value)}
                          focusBorderColor="brand.base"
                          _focus={{
                            borderColor: 'brand.base !important',
                          }}
                        />
                      </FormControl>
                    )}
                    <FormControl pt={2}>
                      <Checkbox
                        size={'lg'}
                        onChange={e =>
                          setHasOptedInForNewsletter(e.target.checked)
                        }
                        isChecked={hasOptedInForNewsletter}
                      >
                        Yes, sign me up for updates from ValidMind
                      </Checkbox>
                      <FormHelperText color={'neutral.600'} pl={6}>
                        We send emails with news and insights about the world of
                        AI & model risk every month.
                      </FormHelperText>
                    </FormControl>
                    <FormControl pt={2}>
                      <Checkbox
                        size={'lg'}
                        onChange={e => setHasOptedInForSlack(e.target.checked)}
                        isChecked={hasOptedInForSlack}
                      >
                        Yes, I want to join your online community
                      </Checkbox>
                      <FormHelperText color={'neutral.600'} pl={6}>
                        You’ll receive an invite email to join our online space
                        for AI & model risk practitioners.
                      </FormHelperText>
                    </FormControl>
                    <FormControl pt={2}>
                      <Checkbox
                        size={'lg'}
                        onChange={e =>
                          setHasAcceptedTermsAndConditions(e.target.checked)
                        }
                        isRequired
                        isChecked={hasAcceptedTermsAndConditions}
                      >
                        <HStack>
                          <Text>Yes, I accept the </Text>
                          <Link
                            textDecoration={'underline'}
                            href="https://validmind.com/about/legal/terms-of-use/"
                            isExternal
                          >
                            terms & conditions
                            <ExternalLinkIcon
                              mx="2px"
                              fontSize={'xs'}
                              verticalAlign={'super'}
                            />
                          </Link>{' '}
                          <Text color={'red.400'}>*</Text>
                        </HStack>
                      </Checkbox>
                    </FormControl>
                    <FormControl pt={2}>
                      <Checkbox
                        size={'lg'}
                        onChange={e =>
                          setHasAcceptedTermsOfAIUse(e.target.checked)
                        }
                        isRequired
                        isChecked={hasAcceptedTermsOfAIUse}
                      >
                        <HStack>
                          <Text>Yes, I accept the </Text>
                          <Link
                            textDecoration={'underline'}
                            href={AITermsOfUseUrl}
                            isExternal
                          >
                            terms of AI use
                          </Link>{' '}
                          <Text color={'red.600'}>*</Text>
                        </HStack>
                      </Checkbox>
                    </FormControl>
                  </Stack>
                </Stack>
                <Stack w={'full'}>
                  <Button
                    onClick={onContinue}
                    variant={'primary'}
                    isDisabled={submitDisabled}
                    w={'full'}
                  >
                    {createOrganization.isLoading || updateUser.isLoading
                      ? 'Please wait...'
                      : 'Continue'}
                  </Button>
                </Stack>
              </VStack>
            </Center>
            <Box w={'full'}>
              <Center>
                <Copyright mt={0} mb={4} />
              </Center>
            </Box>
          </VStack>
        </GridItem>
        <GridItem
          colSpan={5}
          bg={
            'radial-gradient(90.44% 90.44% at 0% 43.78%, #DF2780 0%, #8A144D 100%)'
          }
          scrollBehavior={'smooth'}
          pos={'relative'}
        >
          <ProductShots />
        </GridItem>
      </Grid>
    </LoadingContainer>
  );
}
