import { useContext, useEffect, useMemo } from 'react';
import {
  Text,
  HStack,
  VStack,
  Box,
  Stack,
  Icon,
  Tag,
  TagLabel,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useColorModeValue,
  Heading,
  Button,
  useColorMode,
  useDisclosure,
  LinkBox,
  IconButton,
} from '@chakra-ui/react';
import { PlusIcon, LockClosedIcon } from '@heroicons/react/24/solid';
import Breadcrumbs from '../../../components/Breadcrumbs';
import AddRoleModal from '../Roles/AddRoleModal';
import { ContentPageTitle } from '../../../components/Layout';
import SidebarContext from '../../../contexts/SidebarContext';
import { TRole } from '../../../models/role';
import { useOrgRoles } from '../../../hooks/useOrgRoles';
import { getRoleUIProps } from '../../../utils';
import { useNavigate } from 'react-router-dom';
import MoreInfoPopOver from '../../../components/MoreInfoPopOver';
import { EllipsisVerticalIcon, EyeIcon } from '@heroicons/react/24/outline';
import { CONFIG } from '../../../config';
import UsersContext from '../../../contexts/UsersContext';

const LOCKED_STAKEHOLDER_ROLES = [
  'Model Developer',
  'Model Owner',
  'Model Validator',
];

export default function Stakeholders() {
  const navigate = useNavigate();
  const { setInSettings } = useContext(SidebarContext);
  const { orgRoles, isLoading } = useOrgRoles();
  const { colorMode } = useColorMode();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { refetchOrganization } = useContext(UsersContext);

  const bg = useColorModeValue('neutral.100', 'neutral.800');

  const onSuccessNewRole = (data: TRole) => {
    // Refresh organization data to make sure the newly added role is available immediately
    // This prevents having to refresh the page when adding a new role and then visiting models page
    refetchOrganization();
    onClose();
  };

  useEffect(() => {
    setInSettings(true);
    return () => {
      setInSettings(false);
    };
  }, []);

  const sortedRoles = useMemo(() => {
    return orgRoles
      .filter(role => role.scope === 'Model')
      .sort((a, b) => {
        // If both roles are locked or both are not locked, maintain alphabetical order
        const aIsLocked = LOCKED_STAKEHOLDER_ROLES.includes(a.name);
        const bIsLocked = LOCKED_STAKEHOLDER_ROLES.includes(b.name);

        if (aIsLocked === bIsLocked) {
          // For locked roles, maintain specific order
          if (aIsLocked) {
            return (
              LOCKED_STAKEHOLDER_ROLES.indexOf(a.name) -
              LOCKED_STAKEHOLDER_ROLES.indexOf(b.name)
            );
          }
          // For non-locked roles, sort alphabetically
          return a.name.localeCompare(b.name);
        }

        // If one is locked and other isn't, locked comes first
        return aIsLocked ? -1 : 1;
      });
  }, [orgRoles]);

  return (
    <VStack
      alignItems="start"
      spacing={0}
      paddingTop={12}
      mt={1}
      paddingBottom={16}
      px={14}
      gap={8}
      w="full"
      overflow="auto"
      className="no-scrollbar"
      maxWidth={'7xl'}
      mx={'auto'}
    >
      <Box>
        <Breadcrumbs />
        <ContentPageTitle>
          Model Stakeholder Types
          <MoreInfoPopOver
            title="Model Stakeholder Types"
            link={`${CONFIG.VALIDMIND_DOCS_URL}/guide/configuration/manage-roles.html`}
            placement="right-end"
            iconProps={{
              ml: 2,
            }}
          />
        </ContentPageTitle>
      </Box>
      <Text>
        Model stakeholders determine specific responsibilities and access levels
        for model review and approval processes.
      </Text>
      <Stack width={'full'} spacing={4}>
        <VStack alignItems="flex-start">
          <HStack justifyContent="space-between" w="full">
            <Heading as="h3">All Stakeholder Types</Heading>
            <Button
              isDisabled={false}
              onClick={onOpen}
              rounded="md"
              alignContent={'right'}
              leftIcon={<Icon as={PlusIcon} boxSize={5} />}
              variant={'ghost'}
              pt="5"
              pb="5"
            >
              Add New Stakeholder Type
            </Button>
          </HStack>
          <VStack width={'full'} gap={1} alignItems="flex-start">
            {sortedRoles.map(role => {
              const roleUIProps = getRoleUIProps(role.name);
              const isLockedRole = LOCKED_STAKEHOLDER_ROLES.includes(role.name);
              return (
                <LinkBox
                  p={4}
                  gap={4}
                  rounded={'md'}
                  border={'1px solid'}
                  transition={'all 0.3s ease-in-out'}
                  data-testid="group-container"
                  _hover={{
                    bg: useColorModeValue(
                      'brandSecondary.25',
                      'brandSecondary.950',
                    ),
                    borderColor: useColorModeValue(
                      'brandSecondary.100',
                      'brandSecondary.800',
                    ),
                    color: useColorModeValue('inherit', 'brandSecondary.25'),
                  }}
                  borderColor={useColorModeValue('neutral.200', 'neutral.800')}
                  role="group"
                  onClick={() => {
                    navigate(`/settings/stakeholders/${role.cuid}`);
                  }}
                  cursor={'pointer'}
                  width={'full'}
                  key={role.cuid}
                >
                  <HStack alignItems="flex-start" gap={4}>
                    <Box
                      display={'flex'}
                      rounded={'md'}
                      w={16}
                      h={16}
                      alignItems="center"
                      justifyContent="center"
                      backgroundColor={`${roleUIProps.colorScheme}.400`}
                      bg={
                        colorMode === 'light'
                          ? roleUIProps.colorScheme + '.200'
                          : roleUIProps.colorScheme + '.900'
                      }
                    >
                      <Icon as={roleUIProps.icon} boxSize={6} />
                    </Box>
                    <VStack
                      display={'flex'}
                      flex={1}
                      alignItems="flex-start"
                      gap={0}
                    >
                      <HStack
                        spacing={2}
                        display={'flex'}
                        flex={1}
                        alignItems="flex-start"
                      >
                        <Heading as="h4">{role.name}</Heading>
                        {isLockedRole && (
                          <Tag>
                            <Icon as={LockClosedIcon} mr={2} />
                            <TagLabel textTransform="uppercase">
                              Locked
                            </TagLabel>
                          </Tag>
                        )}
                      </HStack>
                      <Text>{role.description}</Text>
                    </VStack>
                    <Menu>
                      <MenuButton
                        as={IconButton}
                        variant="ghost"
                        aria-label="Options"
                        icon={<Icon as={EllipsisVerticalIcon} boxSize={6} />}
                        display={isOpen ? 'block' : 'none'}
                        _groupHover={{
                          display: 'block',
                        }}
                        onClick={event => {
                          event.stopPropagation();
                        }}
                      />
                      <MenuList>
                        <MenuItem
                          icon={<Icon as={EyeIcon} boxSize={5} />}
                          onClick={() => {
                            navigate(`/settings/stakeholders/${role.cuid}`);
                          }}
                        >
                          See Details
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  </HStack>
                </LinkBox>
              );
            })}
          </VStack>
        </VStack>
        <AddRoleModal
          isOpen={isOpen}
          onSuccess={onSuccessNewRole}
          onClose={onClose}
          roleScope="Model"
          isStakeholderRole={true}
        />
      </Stack>
    </VStack>
  );
}
