import { useContext, useEffect, useMemo, useState } from 'react';
import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Button,
  VStack,
  Spacer,
  InputGroup,
  InputLeftElement,
  Input,
  Icon,
  HStack,
  Text,
  Checkbox,
  Badge,
  Tag,
  TagLabel,
  Stack,
  CloseButton,
  useColorModeValue,
  As,
  Heading,
  BreadcrumbItem,
  Breadcrumb,
  BreadcrumbSeparator,
  BreadcrumbLink,
} from '@chakra-ui/react';
import { useQuery } from 'react-query';
import API from '../../api/API';
import { DataTable } from '../DataTable';
import AvatarProxy from '../AvatarProxy';
import {
  displayFormattedDateAndTime,
  displayFormattedDateFromDateString,
} from '../../utils';
import { Allotment } from 'allotment';
import 'allotment/dist/style.css';
import { LoadingContainer } from '../LoadingContainer';
import {
  Bars3CenterLeftIcon,
  CodeBracketSquareIcon,
  CogIcon,
  MagnifyingGlassIcon,
  PresentationChartLineIcon,
} from '@heroicons/react/24/outline';
import { ModelDocumentTypeEnum } from '../../models/model_document';
import InventoryModelContext from '../../contexts/InventoryModel';
import { TUnitMetric } from '../../models/unit_metric';
import { TTest } from '../../api/API';
import UnitMetricContent from '../Templates/UnitMetricContent';
import { TestResultContent } from '../Templates/ModelDocumentation/TestResultContent';
import { TBlockLibraryItem } from '../../models/block_library';
import BlockWrapper from '../Templates/BlockWrapper';
import { TextContentEditor } from '../TextContentEditor';
import { UsersContext } from '../../contexts';
import { ContentPageTitle } from '../Layout';
import { EmptyStateDisplay } from '../EmptyStateDisplay';
import { useNavigate } from 'react-router-dom';
import { RocketLaunchIcon } from '@heroicons/react/24/outline';
interface DocumentBlockModalProps {
  isOpen: boolean;
  onClose: () => void;
  onAddMetric: (content_type: string, content_id: string) => Promise<void>;
  onAddTest: (content_type: string, content_id: string) => Promise<void>;
  onAddLibraryBlock?: (blockLibraryCUID: string) => Promise<void>;
  defaultSelectedCategory?: SelectedCategory;
  showMetrics: boolean;
  documentType: ModelDocumentTypeEnum;
}

interface MetricsContentProps {
  unitMetricsData: TUnitMetric[] | undefined;
  selectedUnitMetrics: TUnitMetric[];
  setSelectedUnitMetrics: React.Dispatch<React.SetStateAction<TUnitMetric[]>>;
  previewUnitMetric: TUnitMetric | undefined;
  setPreviewUnitMetric: React.Dispatch<
    React.SetStateAction<TUnitMetric | undefined>
  >;
  searchQuery: string;
  setSearchQuery: React.Dispatch<React.SetStateAction<string>>;
  isLoadingMetrics: boolean;
  title: string;
  titlePrefix: string;
}

type CategoryProps = {
  title: string;
  subTitle: string;
  icon: As;
  items: {
    title: string;
    count: number;
    isSelected: boolean;
  }[];
  onClick: (index: number) => void;
};

const Category = ({ title, subTitle, icon, items, onClick }: CategoryProps) => {
  return (
    <VStack align={'flex-start'} justify={'flex-start'} w={'full'}>
      <HStack align={'flex-start'} justify={'flex-start'}>
        <Icon as={icon} boxSize={6} />
        <Text fontSize={'sm'} fontWeight={'bold'} textTransform={'uppercase'}>
          {title}
        </Text>
        <Text fontSize={'sm'} color={'neutral.500'}>
          ({subTitle})
        </Text>
      </HStack>
      <VStack align={'flex-start'} justify={'flex-start'} w={'full'} gap={0}>
        {items.map((item, index) => (
          <HStack
            justify={'space-between'}
            onClick={() => onClick(index)}
            cursor={'pointer'}
            w={'full'}
            h={'36px'}
            borderLeftWidth={item.isSelected ? '4px' : '0px'}
            borderLeftColor={item.isSelected ? 'brand.500' : 'transparent'}
            px={6}
            pl={item.isSelected ? 7 : 8}
            _hover={{
              bgColor: 'brandSecondary.25',
            }}
            transition={'background-color 0.3s ease-in-out'}
          >
            <Text
              color={item.isSelected ? 'brand.500' : 'neutral.600'}
              fontWeight={'bold'}
            >
              {item.title}
            </Text>
            <Tag
              bgColor={item.isSelected ? 'brand.100' : undefined}
              color={item.isSelected ? 'brand.500' : undefined}
            >
              {item.count}
            </Tag>
          </HStack>
        ))}
      </VStack>
    </VStack>
  );
};

const MetricsContent = ({
  unitMetricsData,
  selectedUnitMetrics,
  setSelectedUnitMetrics,
  previewUnitMetric,
  setPreviewUnitMetric,
  searchQuery,
  setSearchQuery,
  isLoadingMetrics,
  title,
  titlePrefix,
}: MetricsContentProps) => {
  const navigate = useNavigate();
  const { inventoryModel } = useContext(InventoryModelContext);

  const columnsMetrics = useMemo(
    () => [
      {
        id: 'checkbox',
        Cell: ({ row }: any) => {
          const foundIndex = selectedUnitMetrics.findIndex(
            (key: TUnitMetric) => key === row.original,
          );
          const isChecked = foundIndex !== -1;
          return (
            <Box
              display="flex"
              flex={1}
              alignItems="center"
              justifyContent="center"
            >
              <Checkbox
                isChecked={isChecked}
                onChange={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  if (e.target.checked) {
                    setSelectedUnitMetrics([
                      ...selectedUnitMetrics,
                      row.original,
                    ]);
                  } else {
                    setSelectedUnitMetrics(
                      selectedUnitMetrics.filter(
                        (key: TUnitMetric) => key !== row.original,
                      ),
                    );
                  }
                }}
              />
              {foundIndex !== -1 && (
                <Badge bgColor="brand" h="16px" lineHeight="16px">
                  {foundIndex + 1}
                </Badge>
              )}
            </Box>
          );
        },
      },
      {
        accessor: 'type',
        Header: 'Type',
        Cell: (data: any) => {
          const { row } = data;
          return (
            <Box display="flex" flex={1} flexDirection="column">
              <Text fontSize={'sm'} fontWeight={'bold'}>
                {row.values.type}
              </Text>
            </Box>
          );
        },
      },
      {
        accessor: 'name',
        Header: 'Name',
        Cell: (data: any) => {
          const { row } = data;
          return (
            <Box display="flex" flex={1} flexDirection="column">
              <Text fontSize={'sm'} fontWeight={'bold'}>
                {row.values.name}
              </Text>
              <Text
                fontSize={'sm'}
                color="neutral.500"
                textOverflow="ellipsis"
                overflow="hidden"
                maxW={'300px'}
                textAlign={'left'}
                style={{
                  direction: 'rtl',
                }}
              >
                {row.original.key}
              </Text>
            </Box>
          );
        },
      },
      {
        accessor: 'is_default',
        Header: 'Type',
        Cell: ({ value }: any) => {
          return <Text fontSize={'sm'}>{value ? 'Default' : 'Custom'}</Text>;
        },
      },
      {
        accessor: 'tag',
        Header: 'Tag',
        Cell: ({ row }: any) => {
          return (
            <Text fontSize={'sm'} fontFamily={'monospace'}>
              {row.values.tag}
            </Text>
          );
        },
      },
      {
        accessor: 'created_at',
        Header: 'Last Updated',
        Cell: ({ value }: any) => {
          return (
            <Text fontSize={'sm'}>{displayFormattedDateAndTime(value)}</Text>
          );
        },
      },
      {
        accessor: 'user',
        Header: 'Updated By',
        disableSortBy: true,
        Cell: ({ value }: any) => {
          return (
            <Box>
              <Tag key={value.cuid} size={'md'}>
                <AvatarProxy
                  src={value.picture}
                  size="xs"
                  name={value.name}
                  ml={-2}
                  mr={2}
                />
                <TagLabel>{value.name}</TagLabel>
              </Tag>
            </Box>
          );
        },
      },
    ],
    [selectedUnitMetrics],
  );

  const filteredMetricsData = useMemo(() => {
    if (!unitMetricsData) {
      return [];
    }
    return unitMetricsData.filter((test: TUnitMetric) => {
      if (!searchQuery) {
        return true;
      }
      return test.name.toLowerCase().includes(searchQuery.toLowerCase());
    });
  }, [unitMetricsData, searchQuery]);

  return (
    <LoadingContainer isLoading={isLoadingMetrics}>
      <VStack flex={1} alignItems="flex-start" h="full" w={'full'}>
        <Breadcrumb color="neutral.500">
          <BreadcrumbItem>
            <BreadcrumbLink as={Text}>
              Metric Over Time (Per model)
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
        </Breadcrumb>
        <HStack gap={5} color={'inherit'} mb={4}>
          <ContentPageTitle>
            {titlePrefix} {title}
          </ContentPageTitle>
        </HStack>

        {filteredMetricsData.length === 0 && searchQuery === '' && (
          <VStack w={'full'} justify={'center'} align={'center'}>
            <EmptyStateDisplay variant="no-reports">
              <Heading as={'h5'}>No {title.toLowerCase()} yet.</Heading>
              <Text align={'center'} maxW={'75%'}>
                To add metric over time blocks to the library, please make sure
                to run the associated documentation test suite. Please refer to
                ValidMind's documentation for more information.
              </Text>
              <Button
                variant={'link'}
                onClick={() => {
                  navigate(
                    `/model-inventory/${inventoryModel?.cuid}/getting-started`,
                  );
                }}
              >
                <Icon as={RocketLaunchIcon} boxSize={6} mr={2} />
                Get Started
              </Button>
            </EmptyStateDisplay>
          </VStack>
        )}

        {(filteredMetricsData.length > 0 || searchQuery !== '') && (
          <>
            <InputGroup w={'50%'}>
              <InputLeftElement pointerEvents="none">
                <Icon
                  as={MagnifyingGlassIcon}
                  boxSize={5}
                  color={'neutral.400'}
                />
              </InputLeftElement>
              <Input
                type="search"
                placeholder="Search by name"
                maxW="300px"
                onChange={e => {
                  setSearchQuery(e.target.value);
                }}
                value={searchQuery}
                w={'full'}
              />
            </InputGroup>

            <Allotment vertical separator>
              <Allotment.Pane>
                <Box w="full" h="full" overflow="scroll" mb={4}>
                  <DataTable
                    data={filteredMetricsData}
                    columns={columnsMetrics}
                    isInteractive={true}
                    pageSize={99999}
                    onClickRow={(row: any) => {
                      setPreviewUnitMetric(row.original);
                    }}
                    getRowProps={(row: any) => {
                      const defaultBgColor =
                        row.index % 2 === 0
                          ? useColorModeValue(
                              'var(--chakra-colors-neutral-25)',
                              'var(--chakra-colors-neutral-1000)',
                            )
                          : 'transparent';
                      const highlightedBgColor = useColorModeValue(
                        'var(--chakra-colors-brand-50)',
                        'var(--chakra-colors-brand-800)',
                      );

                      return {
                        style: {
                          background:
                            previewUnitMetric?.key === row.original.key
                              ? highlightedBgColor
                              : defaultBgColor,
                          borderBottom: '1px solid',
                          borderBottomColor: useColorModeValue(
                            'var(--chakra-colors-neutral-100)',
                            'var(--chakra-colors-neutral-850)',
                          ),
                        },
                      };
                    }}
                    emptyStateDisplay={
                      <VStack w={'full'} justify={'center'} align={'center'}>
                        <EmptyStateDisplay variant="no-reports">
                          <Heading as={'h5'}>
                            No {title.toLowerCase()} found
                          </Heading>
                          <Text align={'center'}>
                            We couldn't find any blocks with your search or
                            filter combination.
                            <br />
                            Please adjust your criteria and try again.
                          </Text>
                        </EmptyStateDisplay>
                      </VStack>
                    }
                  />
                </Box>
              </Allotment.Pane>
              {previewUnitMetric && (
                <Allotment.Pane>
                  <VStack h={'full'} p={4}>
                    <Stack alignItems={'flex-end'} w={'full'}>
                      <CloseButton
                        onClick={() => setPreviewUnitMetric(undefined)}
                      />
                    </Stack>
                    <Box
                      display="flex"
                      flex={1}
                      borderRadius={'md'}
                      px={4}
                      w={'full'}
                      overflowY="auto"
                    >
                      <UnitMetricContent
                        readOnly={true}
                        contents={{
                          content_id: previewUnitMetric.key,
                          content_type: 'unit_metric',
                        }}
                      />
                    </Box>
                  </VStack>
                </Allotment.Pane>
              )}
            </Allotment>
          </>
        )}
      </VStack>
    </LoadingContainer>
  );
};

interface TestsContentProps {
  testsData: TTest[] | undefined;
  selectedTests: TTest[];
  setSelectedTests: React.Dispatch<React.SetStateAction<TTest[]>>;
  previewTest: TTest | undefined;
  setPreviewTest: React.Dispatch<React.SetStateAction<TTest | undefined>>;
  searchQuery: string;
  setSearchQuery: React.Dispatch<React.SetStateAction<string>>;
  isLoadingTests: boolean;
  title: string;
  titlePrefix: string;
  documentType: ModelDocumentTypeEnum;
}

const TestsContent = ({
  testsData,
  selectedTests,
  setSelectedTests,
  previewTest,
  setPreviewTest,
  searchQuery,
  setSearchQuery,
  isLoadingTests,
  title,
  titlePrefix,
  documentType,
}: TestsContentProps) => {
  const navigate = useNavigate();
  const { inventoryModel } = useContext(InventoryModelContext);

  const columnsTests = useMemo(
    () => [
      {
        id: 'checkbox',
        Cell: ({ row }: any) => {
          const foundIndex = selectedTests.findIndex(
            (key: TTest) => key === row.original,
          );
          const isChecked = foundIndex !== -1;
          return (
            <Box
              display="flex"
              flex={1}
              alignItems="center"
              justifyContent="center"
            >
              <Checkbox
                isChecked={isChecked}
                size={'lg'}
                onChange={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  if (e.target.checked) {
                    setSelectedTests([...selectedTests, row.original]);
                  } else {
                    setSelectedTests(
                      selectedTests.filter(
                        (key: TTest) => key !== row.original,
                      ),
                    );
                  }
                }}
              />
              {foundIndex !== -1 && (
                <Badge fontFamily={'mono'} roundedLeft={0}>
                  {foundIndex + 1}
                </Badge>
              )}
            </Box>
          );
        },
      },
      {
        accessor: 'name',
        Header: 'Name',
        Cell: (data: any) => {
          const { row } = data;
          return (
            <Stack gap={0}>
              <Heading as="h5">{row.values.name}</Heading>
              <Text
                color="neutral.500"
                textOverflow="ellipsis"
                overflow="hidden"
                maxW={'prose'}
                textAlign={'left'}
              >
                {row.original.key}
              </Text>
            </Stack>
          );
        },
      },
      {
        accessor: 'is_default',
        Header: 'Type',
        Cell: ({ value }: any) => {
          return <Text fontSize={'sm'}>{value ? 'Default' : 'Custom'}</Text>;
        },
      },
      {
        accessor: 'tag',
        Header: 'Tag',
        Cell: ({ row }: any) => {
          return (
            <Text fontSize={'sm'} fontFamily={'monospace'}>
              {row.values.tag}
            </Text>
          );
        },
      },
      {
        accessor: 'models',
        Header: 'Models',
        Cell: ({ value }: any) => {
          return (
            <HStack>
              {value.map((m: string) => (
                <Tag>
                  <TagLabel fontFamily={'monospace'} fontSize={'sm'}>
                    {m}
                  </TagLabel>
                </Tag>
              ))}
            </HStack>
          );
        },
      },
      {
        accessor: 'datasets',
        Header: 'Datasets',
        Cell: ({ value }: any) => {
          return (
            <VStack alignItems={'flex-start'}>
              {value.map((ds: string) => (
                <Tag>
                  <TagLabel fontFamily={'monospace'} fontSize={'sm'}>
                    {ds}
                  </TagLabel>
                </Tag>
              ))}
            </VStack>
          );
        },
      },
      {
        accessor: 'created_at',
        Header: 'Last Updated',
        Cell: ({ value }: any) => {
          return (
            <Text fontSize={'sm'}>{displayFormattedDateAndTime(value)}</Text>
          );
        },
      },
      {
        accessor: 'user',
        Header: 'Updated By',
        disableSortBy: true,
        Cell: ({ value }: any) => {
          return (
            <Box>
              <Tag key={value.cuid} size={'md'}>
                <AvatarProxy
                  src={value.picture}
                  size="xs"
                  name={value.name}
                  ml={-2}
                  mr={2}
                />
                <TagLabel>{value.name}</TagLabel>
              </Tag>
            </Box>
          );
        },
      },
    ],
    [selectedTests],
  );

  const filteredTestsData = useMemo(() => {
    if (!testsData) {
      return [];
    }
    return testsData.filter((test: TTest) => {
      if (!searchQuery) {
        return true;
      }
      return test.name.toLowerCase().includes(searchQuery.toLowerCase());
    });
  }, [testsData, searchQuery]);

  return (
    <LoadingContainer isLoading={isLoadingTests}>
      <VStack flex={1} alignItems="flex-start" h="full" w={'full'}>
        <Breadcrumb color="neutral.500">
          <BreadcrumbItem>
            <BreadcrumbLink as={Text}>Test-Driven (Per Model)</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
        </Breadcrumb>
        <HStack gap={5} color={'inherit'} mb={4}>
          <ContentPageTitle>
            {titlePrefix} {title}
          </ContentPageTitle>
        </HStack>

        {filteredTestsData.length === 0 && searchQuery === '' && (
          <VStack w={'full'} justify={'center'} align={'center'}>
            <EmptyStateDisplay variant="no-reports">
              <Heading as={'h5'}>No {title.toLowerCase()} yet.</Heading>
              <Text align={'center'} maxW={'75%'}>
                To add test blocks to the library, please make sure to run the
                associated documentation test suite. Please refer to ValidMind's
                documentation for more information.
              </Text>
              <Button
                variant={'link'}
                onClick={() => {
                  navigate(
                    `/model-inventory/${inventoryModel?.cuid}/getting-started`,
                  );
                }}
              >
                <Icon as={RocketLaunchIcon} boxSize={6} mr={2} />
                Get Started
              </Button>
            </EmptyStateDisplay>
          </VStack>
        )}

        {(filteredTestsData.length > 0 || searchQuery !== '') && (
          <>
            <InputGroup w={'50%'}>
              <InputLeftElement pointerEvents="none">
                <Icon
                  as={MagnifyingGlassIcon}
                  boxSize={5}
                  color={'neutral.400'}
                />
              </InputLeftElement>
              <Input
                type="search"
                placeholder="Search by name"
                maxW="300px"
                onChange={e => {
                  setSearchQuery(e.target.value);
                }}
                value={searchQuery}
                w={'full'}
              />
            </InputGroup>

            <Allotment vertical separator>
              <Allotment.Pane>
                <Box w="full" h="full" overflow="scroll" mb={4}>
                  <DataTable
                    data={filteredTestsData}
                    columns={columnsTests}
                    isInteractive={true}
                    pageSize={99999}
                    onClickRow={(row: any) => {
                      setPreviewTest(row.original);
                    }}
                    getRowProps={(row: any) => {
                      const defaultBgColor =
                        row.index % 2 === 0
                          ? useColorModeValue(
                              'var(--chakra-colors-neutral-25)',
                              'var(--chakra-colors-neutral-1000)',
                            )
                          : 'transparent';
                      const highlightedBgColor = useColorModeValue(
                        'var(--chakra-colors-brand-25)',
                        'var(--chakra-colors-brand-800)',
                      );

                      return {
                        style: {
                          background:
                            previewTest?.key === row.original.key
                              ? highlightedBgColor
                              : defaultBgColor,
                          borderBottom: '1px solid',
                          color:
                            previewTest?.key === row.original.key
                              ? useColorModeValue(
                                  'var(--chakra-colors-brand-700)',
                                  'unset',
                                )
                              : 'unset',
                          borderBottomColor: useColorModeValue(
                            'var(--chakra-colors-neutral-100)',
                            'var(--chakra-colors-neutral-850)',
                          ),
                        },
                      };
                    }}
                    emptyStateDisplay={
                      <VStack w={'full'} justify={'center'} align={'center'}>
                        <EmptyStateDisplay variant="no-reports">
                          <Heading as={'h5'}>
                            No {title.toLowerCase()} found
                          </Heading>
                          <Text align={'center'}>
                            We couldn't find any blocks with your search or
                            filter combination.
                            <br />
                            Please adjust your criteria and try again.
                          </Text>
                        </EmptyStateDisplay>
                      </VStack>
                    }
                  />
                </Box>
              </Allotment.Pane>
              {previewTest && (
                <Allotment.Pane>
                  <VStack overflow="scroll" h={'full'} p={4}>
                    <Stack alignItems={'flex-end'} w={'full'}>
                      <CloseButton onClick={() => setPreviewTest(undefined)} />
                    </Stack>
                    <Box borderRadius={'md'} px={4}>
                      <TestResultContent
                        readOnly={true}
                        contents={{
                          content_id: previewTest.key,
                          content_type: documentType,
                        }}
                        documentType={documentType}
                      />
                    </Box>
                  </VStack>
                </Allotment.Pane>
              )}
            </Allotment>
          </>
        )}
      </VStack>
    </LoadingContainer>
  );
};

interface BlockLibraryContentProps {
  blocksData: TBlockLibraryItem[] | undefined;
  selectedBlocks: TBlockLibraryItem[];
  setSelectedBlocks: React.Dispatch<React.SetStateAction<TBlockLibraryItem[]>>;
  previewBlock: TBlockLibraryItem | undefined;
  setPreviewBlock: React.Dispatch<
    React.SetStateAction<TBlockLibraryItem | undefined>
  >;
  searchQuery: string;
  setSearchQuery: React.Dispatch<React.SetStateAction<string>>;
  isLoadingBlocks: boolean;
  title: string;
  titlePrefix: string;
}

const BlockLibraryContent = ({
  blocksData,
  selectedBlocks,
  setSelectedBlocks,
  previewBlock,
  setPreviewBlock,
  searchQuery,
  setSearchQuery,
  isLoadingBlocks,
  title,
  titlePrefix,
}: BlockLibraryContentProps) => {
  const navigate = useNavigate();
  const columnsBlocks = useMemo(
    () => [
      {
        id: 'checkbox',
        Cell: ({ row }: any) => {
          const foundIndex = selectedBlocks.findIndex(
            (key: TBlockLibraryItem) => key === row.original,
          );
          const isChecked = foundIndex !== -1;
          return (
            <Box
              display="flex"
              flex={1}
              alignItems="center"
              justifyContent="center"
            >
              <Checkbox
                isChecked={isChecked}
                onChange={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  if (e.target.checked) {
                    setSelectedBlocks([...selectedBlocks, row.original]);
                  } else {
                    setSelectedBlocks(
                      selectedBlocks.filter(
                        (key: TBlockLibraryItem) => key !== row.original,
                      ),
                    );
                  }
                }}
              />
              {foundIndex !== -1 && (
                <Badge bgColor="brand" h="16px" lineHeight="16px">
                  {foundIndex + 1}
                </Badge>
              )}
            </Box>
          );
        },
      },
      {
        id: 'icon',
        Header: '',
        size: 40,
        minSize: 20, // Set a minimum width
        maxSize: 100, // Set a maximum width
        Cell: ({ row }: any) => {
          return (
            <Box w={'fit-content'}>
              <Icon as={Bars3CenterLeftIcon} boxSize={6} />
            </Box>
          );
        },
      },
      {
        accessor: 'name',
        Header: 'Name',
        Cell: (data: any) => {
          const { row } = data;
          return (
            <Stack gap={0}>
              <Heading as="h5">{row.values.name}</Heading>
              <Text
                color="neutral.500"
                textOverflow="ellipsis"
                overflow="hidden"
                maxW={'prose'}
                textAlign={'left'}
              >
                {row.original.description}
              </Text>
            </Stack>
          );
        },
      },
      {
        accessor: 'is_shared',
        Header: 'Access',
        Cell: ({ value }: any) => {
          return <Text fontSize={'sm'}>{value ? 'Shared' : 'Private'}</Text>;
        },
      },
      {
        accessor: 'updated_at',
        Header: 'Last Updated',
        Cell: ({ value }: any) => {
          return (
            <Text fontSize={'sm'}>
              {displayFormattedDateFromDateString(value)}
            </Text>
          );
        },
      },
      {
        accessor: 'updated_by',
        Header: 'Updated By',
        disableSortBy: true,
        Cell: ({ value }: any) => {
          return (
            <Box>
              <Tag key={value.cuid} size={'md'}>
                <AvatarProxy
                  src={value.picture}
                  size="xs"
                  name={value.name}
                  ml={-2}
                  mr={2}
                />
                <TagLabel>{value.name}</TagLabel>
              </Tag>
            </Box>
          );
        },
      },
    ],
    [selectedBlocks],
  );

  const filteredBlocksData = useMemo(() => {
    if (!blocksData) {
      return [];
    }
    return blocksData.filter((block: TBlockLibraryItem) => {
      if (!searchQuery) {
        return true;
      }
      return block.name.toLowerCase().includes(searchQuery.toLowerCase());
    });
  }, [blocksData, searchQuery]);

  return (
    <LoadingContainer isLoading={isLoadingBlocks}>
      <VStack flex={1} alignItems="flex-start" h="full" w={'full'}>
        <Breadcrumb color="neutral.500">
          <BreadcrumbItem>
            <BreadcrumbLink as={Text}>Text (All Models)</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
        </Breadcrumb>
        <HStack gap={5} color={'inherit'} mb={4}>
          <ContentPageTitle>
            {titlePrefix} {title}
          </ContentPageTitle>
        </HStack>
        {filteredBlocksData.length === 0 && searchQuery === '' && (
          <VStack w={'full'} justify={'center'} align={'center'}>
            <EmptyStateDisplay variant="no-reports">
              <Heading as={'h5'}>No {title.toLowerCase()} yet.</Heading>
              <Text align={'center'} maxW={'75%'}>
                To add text blocks to the library, select an existing block from
                your documentation, monitoring, or validation report. You can
                also create a block directly in Settings &gt; Block Library.
              </Text>
              <Button
                variant={'link'}
                onClick={() => {
                  navigate(`/settings/block-library`);
                }}
              >
                <Icon as={CogIcon} boxSize={6} mr={2} />
                Go to Settings
              </Button>
            </EmptyStateDisplay>
          </VStack>
        )}
        {(filteredBlocksData.length > 0 || searchQuery !== '') && (
          <>
            <InputGroup w={'50%'}>
              <InputLeftElement pointerEvents="none">
                <Icon
                  as={MagnifyingGlassIcon}
                  boxSize={5}
                  color={'neutral.400'}
                />
              </InputLeftElement>
              <Input
                type="search"
                placeholder="Search by name"
                maxW="300px"
                onChange={e => {
                  setSearchQuery(e.target.value);
                }}
                value={searchQuery}
                w={'full'}
              />
            </InputGroup>

            <Allotment vertical separator>
              <Allotment.Pane>
                <Box w="full" h="full" overflow="scroll" mb={4}>
                  <DataTable
                    data={filteredBlocksData}
                    columns={columnsBlocks}
                    isInteractive={true}
                    pageSize={99999}
                    onClickRow={(row: any) => {
                      setPreviewBlock(row.original);
                    }}
                    getRowProps={(row: any) => {
                      const defaultBgColor =
                        row.index % 2 === 0
                          ? useColorModeValue(
                              'var(--chakra-colors-neutral-25)',
                              'var(--chakra-colors-neutral-1000)',
                            )
                          : 'transparent';
                      const highlightedBgColor = useColorModeValue(
                        'var(--chakra-colors-brand-50)',
                        'var(--chakra-colors-brand-800)',
                      );

                      return {
                        style: {
                          background:
                            previewBlock?.cuid === row.original.cuid
                              ? highlightedBgColor
                              : defaultBgColor,
                          borderBottom: '1px solid',
                          borderBottomColor: useColorModeValue(
                            'var(--chakra-colors-neutral-100)',
                            'var(--chakra-colors-neutral-850)',
                          ),
                        },
                      };
                    }}
                    emptyStateDisplay={
                      <VStack w={'full'} justify={'center'} align={'center'}>
                        <EmptyStateDisplay variant="no-reports">
                          <Heading as={'h5'}>
                            No {title.toLowerCase()} found
                          </Heading>
                          <Text align={'center'}>
                            We couldn't find any blocks with your search or
                            filter combination.
                            <br />
                            Please adjust your criteria and try again.
                          </Text>
                        </EmptyStateDisplay>
                      </VStack>
                    }
                  />
                </Box>
              </Allotment.Pane>
              {previewBlock && (
                <Allotment.Pane>
                  <VStack h={'full'} p={4}>
                    <Stack alignItems={'flex-end'} w={'full'}>
                      <CloseButton onClick={() => setPreviewBlock(undefined)} />
                    </Stack>
                    <Box
                      display="flex"
                      flex={1}
                      borderRadius={'md'}
                      px={4}
                      w={'full'}
                      overflowY="auto"
                    >
                      <BlockWrapper
                        contentId={previewBlock.cuid}
                        contentType={ModelDocumentTypeEnum.model_documentation}
                        documentType={ModelDocumentTypeEnum.model_documentation}
                        readOnly={true}
                        showDeleteConfirmation={() => {}}
                        setIsInView={() => {}}
                      >
                        <TextContentEditor
                          allowEdit={false}
                          text={previewBlock.content}
                          isLoading={false}
                          onSave={() => {}}
                          onCancel={() => {}}
                          onSaveRevisions={() => {}}
                        />
                      </BlockWrapper>
                    </Box>
                  </VStack>
                </Allotment.Pane>
              )}
            </Allotment>
          </>
        )}
      </VStack>
    </LoadingContainer>
  );
};

export type SelectedCategory = {
  type: 'tests' | 'metrics' | 'blocks';
  subType: 'vm' | 'custom' | 'private' | 'shared';
};

const DocumentBlockModal = ({
  isOpen,
  onClose,
  onAddMetric,
  onAddTest,
  onAddLibraryBlock,
  showMetrics,
  defaultSelectedCategory,
  documentType,
}: DocumentBlockModalProps) => {
  const { currentOrganization } = useContext(UsersContext);
  const [selectedCategory, setSelectedCategory] = useState<SelectedCategory>(
    defaultSelectedCategory || {
      type: 'tests',
      subType: 'vm',
    },
  );
  const { inventoryModel } = useContext(InventoryModelContext);
  const [selectedUnitMetrics, setSelectedUnitMetrics] = useState<TUnitMetric[]>(
    [],
  );
  const [previewUnitMetric, setPreviewUnitMetric] = useState<
    TUnitMetric | undefined
  >();
  const [selectedTests, setSelectedTests] = useState<TTest[]>([]);
  const [previewTest, setPreviewTest] = useState<TTest | undefined>();
  const [selectedBlocks, setSelectedBlocks] = useState<TBlockLibraryItem[]>([]);
  const [previewBlock, setPreviewBlock] = useState<
    TBlockLibraryItem | undefined
  >();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  const { data: unitMetricsData, isLoading: isLoadingMetrics } = useQuery(
    'unit-metrics',
    async () => {
      const unitMetrics = await API.GetUnitMetrics(inventoryModel!.cuid);
      unitMetrics.sort((a, b) => a.key.localeCompare(b.name));
      return unitMetrics;
    },
    {
      enabled: !!inventoryModel,
    },
  );

  const { data: testsData, isLoading: isLoadingTests } = useQuery(
    ['inventory-model', inventoryModel?.cuid, 'tests'],
    async () => {
      const testResultsKeys = await API.GetTests(inventoryModel!, documentType);
      return testResultsKeys.sort((a, b) => a.name.localeCompare(b.name));
    },
  );

  const { data: blockLibraryData, isLoading: isLoadingBlockLibrary } = useQuery(
    ['block-library', currentOrganization?.cuid],
    async () => {
      const blockLibrary = await API.GetBlockLibrary();
      return blockLibrary;
    },
  );

  const handleCategoryChange = (category: SelectedCategory) => {
    setPreviewTest(undefined);
    setSelectedTests([]);
    setPreviewUnitMetric(undefined);
    setSelectedUnitMetrics([]);
    setPreviewBlock(undefined);
    setSelectedBlocks([]);
    setSearchQuery('');
    setSelectedCategory(category);
  };

  useEffect(() => {
    if (defaultSelectedCategory) {
      handleCategoryChange(defaultSelectedCategory);
    }
  }, [defaultSelectedCategory]);

  const onInsert = async () => {
    setIsSubmitting(true);
    try {
      if (selectedCategory.type === 'metrics') {
        for (const metric of selectedUnitMetrics) {
          await onAddMetric('unit_metric', metric.key);
        }
      } else if (selectedCategory.type === 'blocks' && onAddLibraryBlock) {
        for (const block of selectedBlocks) {
          await onAddLibraryBlock(block.cuid);
        }
      } else {
        for (const test of selectedTests) {
          await onAddTest('test', test.key);
        }
      }
      onClose();
    } catch (e) {
      console.error(e);
    } finally {
      setIsSubmitting(false);
    }
  };

  const { vmMetrics, customMetrics } = useMemo(() => {
    if (!unitMetricsData) {
      return { vmMetrics: [], customMetrics: [] };
    }
    return unitMetricsData.reduce(
      (
        acc: { vmMetrics: TUnitMetric[]; customMetrics: TUnitMetric[] },
        metric: TUnitMetric,
      ) => {
        if (metric.is_default) {
          acc.vmMetrics.push(metric);
        } else {
          acc.customMetrics.push(metric);
        }
        return acc;
      },
      { vmMetrics: [], customMetrics: [] },
    );
  }, [unitMetricsData]);

  const { vmTests, customTests } = useMemo(() => {
    if (!testsData) {
      return { vmTests: [], customTests: [] };
    }
    return testsData.reduce(
      (acc: { vmTests: TTest[]; customTests: TTest[] }, test: TTest) => {
        if (test.key.startsWith('validmind.')) {
          acc.vmTests.push(test);
        } else {
          acc.customTests.push(test);
        }
        return acc;
      },
      { vmTests: [], customTests: [] },
    );
  }, [testsData]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      closeOnEsc
      closeOnOverlayClick
      scrollBehavior={'inside'}
      size={'6xl'}
    >
      <ModalOverlay />
      <ModalContent
        h="95vh"
        maxHeight="95vh"
        width="100%"
        maxW="95%"
        bgColor="white"
      >
        <ModalCloseButton />
        <ModalBody p={0} overflow="hidden">
          <HStack w="full" h="full" gap={0}>
            <VStack
              align="start"
              spacing={4}
              w="280px"
              p={4}
              borderRight="1px solid #00000010"
              h="full"
              pt={8}
            >
              <Category
                title="Text"
                subTitle="All models"
                icon={Bars3CenterLeftIcon}
                items={[
                  {
                    title: 'Private',
                    count: blockLibraryData?.private_blocks?.length || 0,
                    isSelected:
                      selectedCategory.type === 'blocks' &&
                      selectedCategory.subType === 'private',
                  },
                  {
                    title: 'Shared',
                    count: blockLibraryData?.shared_blocks?.length || 0,
                    isSelected:
                      selectedCategory.type === 'blocks' &&
                      selectedCategory.subType === 'shared',
                  },
                ]}
                onClick={index => {
                  handleCategoryChange({
                    type: 'blocks',
                    subType: index === 0 ? 'private' : 'shared',
                  });
                }}
              />
              <Category
                title="Test-driven"
                subTitle="Per model"
                icon={CodeBracketSquareIcon}
                items={[
                  {
                    title: 'VM Library',
                    count: vmTests.length,
                    isSelected:
                      selectedCategory.type === 'tests' &&
                      selectedCategory.subType === 'vm',
                  },
                  {
                    title: 'Custom',
                    count: customTests.length,
                    isSelected:
                      selectedCategory.type === 'tests' &&
                      selectedCategory.subType === 'custom',
                  },
                ]}
                onClick={(index: number) => {
                  handleCategoryChange({
                    type: 'tests',
                    subType: index === 0 ? 'vm' : 'custom',
                  });
                }}
              />
              {showMetrics && (
                <Category
                  title="Metric over time"
                  subTitle="Per model"
                  icon={PresentationChartLineIcon}
                  items={[
                    {
                      title: 'VM Library',
                      count: vmMetrics.length,
                      isSelected:
                        selectedCategory.type === 'metrics' &&
                        selectedCategory.subType === 'vm',
                    },
                    {
                      title: 'Custom',
                      count: customMetrics.length,
                      isSelected:
                        selectedCategory.type === 'metrics' &&
                        selectedCategory.subType === 'custom',
                    },
                  ]}
                  onClick={(index: number) => {
                    handleCategoryChange({
                      type: 'metrics',
                      subType: index === 0 ? 'vm' : 'custom',
                    });
                  }}
                />
              )}
            </VStack>
            <VStack
              flex={1}
              w={'full'}
              h={'full'}
              align={'flex-start'}
              pt={8}
              px={4}
              gap={0}
            >
              {selectedCategory.type === 'metrics' ? (
                <MetricsContent
                  unitMetricsData={
                    selectedCategory.subType === 'vm'
                      ? vmMetrics
                      : customMetrics
                  }
                  selectedUnitMetrics={selectedUnitMetrics}
                  setSelectedUnitMetrics={setSelectedUnitMetrics}
                  previewUnitMetric={previewUnitMetric}
                  setPreviewUnitMetric={setPreviewUnitMetric}
                  searchQuery={searchQuery}
                  setSearchQuery={setSearchQuery}
                  isLoadingMetrics={isLoadingMetrics}
                  titlePrefix={
                    selectedCategory.subType === 'vm' ? 'VM Library' : 'Custom'
                  }
                  title={'Metric Over Time Blocks'}
                />
              ) : selectedCategory.type === 'blocks' ? (
                <BlockLibraryContent
                  blocksData={
                    selectedCategory.subType === 'private'
                      ? blockLibraryData?.private_blocks || []
                      : blockLibraryData?.shared_blocks || []
                  }
                  selectedBlocks={selectedBlocks}
                  setSelectedBlocks={setSelectedBlocks}
                  previewBlock={previewBlock}
                  setPreviewBlock={setPreviewBlock}
                  searchQuery={searchQuery}
                  setSearchQuery={setSearchQuery}
                  isLoadingBlocks={isLoadingBlockLibrary}
                  titlePrefix={
                    selectedCategory.subType === 'private'
                      ? 'Private'
                      : 'Shared'
                  }
                  title={'Text Blocks'}
                />
              ) : (
                <TestsContent
                  testsData={
                    selectedCategory.subType === 'vm' ? vmTests : customTests
                  }
                  selectedTests={selectedTests}
                  setSelectedTests={setSelectedTests}
                  previewTest={previewTest}
                  setPreviewTest={setPreviewTest}
                  searchQuery={searchQuery}
                  setSearchQuery={setSearchQuery}
                  isLoadingTests={isLoadingTests}
                  titlePrefix={
                    selectedCategory.subType === 'vm' ? 'VM Library' : 'Custom'
                  }
                  title={'Test-Driven Blocks'}
                  documentType={documentType}
                />
              )}
              <ModalFooter w={'full'}>
                <Button variant="ghost" onClick={onClose}>
                  Cancel
                </Button>
                <Spacer />
                <Button
                  hidden={
                    (selectedCategory.type === 'metrics' &&
                      selectedUnitMetrics.length === 0) ||
                    (selectedCategory.type === 'tests' &&
                      selectedTests.length === 0) ||
                    (selectedCategory.type === 'blocks' &&
                      selectedBlocks.length === 0)
                  }
                  onClick={onInsert}
                  isDisabled={
                    (selectedCategory.type === 'metrics' &&
                      (selectedUnitMetrics.length === 0 || isSubmitting)) ||
                    (selectedCategory.type === 'tests' &&
                      (selectedTests.length === 0 || isSubmitting)) ||
                    (selectedCategory.type === 'blocks' &&
                      (selectedBlocks.length === 0 || isSubmitting))
                  }
                  isLoading={isSubmitting}
                  variant={'primary'}
                >
                  {selectedCategory.type === 'metrics'
                    ? `Insert ${selectedUnitMetrics.length} Metric${
                        selectedUnitMetrics.length > 1 ? 's' : ''
                      } Over Time to Document`
                    : selectedCategory.type === 'blocks'
                    ? `Insert ${selectedBlocks.length} Text Block${
                        selectedBlocks.length > 1 ? 's' : ''
                      } to Document`
                    : `Insert ${selectedTests.length} Test Result${
                        selectedTests.length > 1 ? 's' : ''
                      } to Document`}
                </Button>
              </ModalFooter>
            </VStack>
          </HStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default DocumentBlockModal;
