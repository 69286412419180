import { useContext, useEffect } from 'react';
import {
  Text,
  HStack,
  VStack,
  Box,
  Stack,
  Icon,
  Tag,
  TagLabel,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useColorModeValue,
  Heading,
  Button,
  useColorMode,
  useDisclosure,
  LinkBox,
  IconButton,
} from '@chakra-ui/react';
import { PlusIcon, LockClosedIcon } from '@heroicons/react/24/solid';
import Breadcrumbs from '../../../components/Breadcrumbs';
import AddRoleModal from './AddRoleModal';
import { ContentPageTitle } from '../../../components/Layout';
import SidebarContext from '../../../contexts/SidebarContext';
import { TRole } from '../../../models/role';
import { useOrgRoles } from '../../../hooks/useOrgRoles';
import { getRoleUIProps } from '../../../utils';
import { useNavigate } from 'react-router-dom';
import MoreInfoPopOver from '../../../components/MoreInfoPopOver';
import { EllipsisVerticalIcon, EyeIcon } from '@heroicons/react/24/outline';
import { CONFIG } from '../../../config';

export default function Roles() {
  const navigate = useNavigate();
  const { setInSettings } = useContext(SidebarContext);
  const { orgRoles, isLoading } = useOrgRoles();
  const { colorMode } = useColorMode();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const onSuccessNewRole = (data: TRole) => {
    // setLastAddedItemId(data.cuid)
    onClose();
  };

  useEffect(() => {
    setInSettings(true);
    return () => {
      setInSettings(false);
    };
  }, []);

  return (
    <VStack
      alignItems="start"
      spacing={0}
      paddingTop={12}
      mt={1}
      paddingBottom={16}
      px={14}
      gap={8}
      w="full"
      overflow="auto"
      className="no-scrollbar"
      maxWidth={'7xl'}
      mx={'auto'}
    >
      <Box>
        <Breadcrumbs />
        <ContentPageTitle>
          Roles
          <MoreInfoPopOver
            title="User Roles"
            link={`${CONFIG.VALIDMIND_DOCS_URL}/guide/configuration/manage-roles.html`}
            placement="right-end"
            iconProps={{
              ml: 2,
            }}
          />
        </ContentPageTitle>
      </Box>
      <Text>
        Roles determine the group of permissions that a user has within the
        organization.
      </Text>
      <Stack width={'full'} spacing={4}>
        <VStack alignItems="flex-start">
          <HStack justifyContent="space-between" w="full">
            <Heading as="h3">All Roles</Heading>
            <Button
              isDisabled={false}
              onClick={onOpen}
              rounded="md"
              alignContent={'right'}
              leftIcon={<Icon as={PlusIcon} boxSize={5} />}
              variant={'ghost'}
              pt="5"
              pb="5"
            >
              Add New Role
            </Button>
          </HStack>
          <VStack width={'full'} gap={1} alignItems="flex-start">
            {orgRoles
              .filter(role => role.scope !== 'Model')
              .map(role => {
                const roleUIProps = getRoleUIProps(role.name);
                return (
                  <LinkBox
                    p={4}
                    gap={4}
                    rounded={'md'}
                    border={'1px solid'}
                    transition={'all 0.3s ease-in-out'}
                    data-testid="group-container"
                    _hover={{
                      bg: useColorModeValue(
                        'brandSecondary.25',
                        'brandSecondary.950',
                      ),
                      borderColor: useColorModeValue(
                        'brandSecondary.100',
                        'brandSecondary.800',
                      ),
                      color: useColorModeValue('inherit', 'brandSecondary.25'),
                    }}
                    borderColor={useColorModeValue(
                      'neutral.200',
                      'neutral.800',
                    )}
                    role="group"
                    onClick={() => {
                      navigate(`/settings/roles/${role.cuid}`);
                    }}
                    cursor={'pointer'}
                    width={'full'}
                    key={role.cuid}
                  >
                    <HStack alignItems="flex-start" gap={4}>
                      <Box
                        display={'flex'}
                        rounded={'md'}
                        w={16}
                        h={16}
                        alignItems="center"
                        justifyContent="center"
                        backgroundColor={`${roleUIProps.colorScheme}.400`}
                        bg={
                          colorMode === 'light'
                            ? roleUIProps.colorScheme + '.200'
                            : roleUIProps.colorScheme + '.900'
                        }
                      >
                        <Icon as={roleUIProps.icon} boxSize={6} />
                      </Box>
                      <VStack
                        display={'flex'}
                        flex={1}
                        alignItems="flex-start"
                        gap={0}
                      >
                        <HStack
                          spacing={2}
                          display={'flex'}
                          flex={1}
                          alignItems="flex-start"
                        >
                          <Heading as="h4">{role.name}</Heading>
                          {(role.is_admin || role.is_staff) && (
                            <Tag>
                              <Icon as={LockClosedIcon} mr={2} />
                              <TagLabel textTransform="uppercase">
                                Locked
                              </TagLabel>
                            </Tag>
                          )}
                        </HStack>
                        <Text>{role.description}</Text>
                      </VStack>
                      <Menu>
                        <MenuButton
                          as={IconButton}
                          variant="ghost"
                          aria-label="Options"
                          icon={<Icon as={EllipsisVerticalIcon} boxSize={6} />}
                          visibility={isOpen ? 'visible' : 'hidden'}
                          _groupHover={{
                            visibility: 'visible',
                          }}
                          onClick={event => {
                            event.stopPropagation();
                          }}
                        />
                        <MenuList>
                          <MenuItem
                            icon={<Icon as={EyeIcon} boxSize={5} />}
                            onClick={() => {
                              navigate(`/settings/roles/${role.cuid}`);
                            }}
                          >
                            See Details
                          </MenuItem>
                        </MenuList>
                      </Menu>
                    </HStack>
                  </LinkBox>
                );
              })}
          </VStack>
        </VStack>
        <AddRoleModal
          isOpen={isOpen}
          onSuccess={onSuccessNewRole}
          onClose={onClose}
          roleScope="General"
        />
      </Stack>
    </VStack>
  );
}
