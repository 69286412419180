import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
  HStack,
  IconButton,
  useToast,
  FormHelperText,
} from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import AdminAPI, {
  Organization,
  PatchOrganizationRequest,
} from '../../api/AdminAPI';
import { Select } from 'chakra-react-select';
import { PlusIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { Icon } from '@chakra-ui/react';

interface EditOrganizationModalProps {
  isOpen: boolean;
  onClose: () => void;
  organization: Organization;
}

export function EditOrganizationModal({
  isOpen,
  onClose,
  organization,
}: EditOrganizationModalProps) {
  const [name, setName] = useState('');
  const [roleMappings, setRoleMappings] = useState<
    { group: string; role: string }[]
  >([]);
  const queryClient = useQueryClient();
  const toast = useToast();

  useEffect(() => {
    if (organization && isOpen) {
      setName(organization.name);
      // Convert existing mappings to array format
      const mappings = organization.oidc_mappings?.role_mappings || {};
      const mappingsArray = Object.entries(mappings).map(([group, role]) => ({
        group,
        role,
      }));
      setRoleMappings(mappingsArray);
    }
  }, [organization, isOpen]);

  const updateOrganizationMutation = useMutation({
    mutationFn: async (data: PatchOrganizationRequest) => {
      return AdminAPI.PatchOrganization(organization.cuid, data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['admin', 'organizations'] });
      toast({
        variant: 'subtle',
        title: 'Organization updated',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      handleClose();
    },
    onError: (error: any) => {
      toast({
        variant: 'subtle',
        title: 'Error updating organization',
        description: error.response?.data?.message || 'An error occurred',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    },
  });

  const handleSubmit = () => {
    // Convert array format back to object format
    const mappingsObject = roleMappings.reduce(
      (acc, { group, role }) => ({
        ...acc,
        [group]: role,
      }),
      {},
    );

    const updateData: PatchOrganizationRequest = {
      name,
      oidc_mappings: {
        role_mappings: mappingsObject,
      },
    };

    updateOrganizationMutation.mutate(updateData);
  };

  const handleClose = () => {
    setName('');
    setRoleMappings([]);
    onClose();
  };

  const addMapping = () => {
    setRoleMappings([...roleMappings, { group: '', role: '' }]);
  };

  const removeMapping = (index: number) => {
    setRoleMappings(roleMappings.filter((_, i) => i !== index));
  };

  const updateMapping = (
    index: number,
    field: 'group' | 'role',
    value: string,
  ) => {
    const newMappings = [...roleMappings];
    newMappings[index] = { ...newMappings[index], [field]: value };
    setRoleMappings(newMappings);
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit Organization</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={6} align="stretch">
            <FormControl isRequired>
              <FormLabel>Organization Name</FormLabel>
              <Input
                value={name}
                onChange={e => setName(e.target.value)}
                placeholder="Enter organization name"
              />
            </FormControl>

            <FormControl>
              <FormLabel>OIDC Role Mappings</FormLabel>
              <FormHelperText mb={4}>
                Map external identity provider groups or roles to ValidMind
                roles. When a user signs in, they will be automatically assigned
                to the mapped roles based on their identity provider group
                membership.
              </FormHelperText>

              <VStack spacing={4} align="stretch">
                {roleMappings.map((mapping, index) => (
                  <HStack key={index} spacing={4}>
                    <FormControl>
                      <Input
                        placeholder="External Group Name"
                        value={mapping.group}
                        onChange={e =>
                          updateMapping(index, 'group', e.target.value)
                        }
                      />
                    </FormControl>
                    <FormControl>
                      <Select
                        value={
                          mapping.role
                            ? {
                                value: mapping.role,
                                label:
                                  organization.roles.find(
                                    r => r.cuid === mapping.role,
                                  )?.name || mapping.role,
                              }
                            : null
                        }
                        onChange={option =>
                          updateMapping(index, 'role', option?.value || '')
                        }
                        options={organization.roles
                          .filter(role => role.scope !== 'Model')
                          .map(role => ({
                            value: role.cuid,
                            label: role.name,
                          }))}
                        placeholder="Select role"
                      />
                    </FormControl>
                    <IconButton
                      aria-label="Remove mapping"
                      icon={<Icon as={XMarkIcon} w={4} h={4} />}
                      onClick={() => removeMapping(index)}
                      variant="ghost"
                      colorScheme="red"
                      size="sm"
                    />
                  </HStack>
                ))}

                <Button
                  leftIcon={<PlusIcon />}
                  variant="secondary"
                  size="sm"
                  onClick={addMapping}
                >
                  Add Mapping
                </Button>
              </VStack>
            </FormControl>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button variant="tertiary" mr={3} onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={handleSubmit}
            isLoading={updateOrganizationMutation.isLoading}
            isDisabled={!name}
          >
            Update Organization
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
