import { NodeProps, Position, useReactFlow, useStore } from 'reactflow';
import { Box, Text, useColorModeValue, VStack } from '@chakra-ui/react';
import { generateRandomCodeForNodes } from '../../../../../../../utils';
import NodeBox from '../../../layouts/NodeBox';
import useWorkflow from '../../../../../../../hooks/useWorkflow';
import { BranchRouteNodeType } from '../../../types';
import '../styles.css';
import CustomHandle from '../../../handles/CustomHandle';
const zoomSelector = (s: any) => s.transform[2] >= 0.5;
function BranchRouteNode(props: NodeProps) {
  const { setSelectedNodeId } = useWorkflow();
  const { getEdges } = useReactFlow();
  const DEFAULT_HANDLE_STYLES = {
    background: useColorModeValue(
      'var(--chakra-colors-white)',
      'var(--chakra-colors-black)',
    ),
    borderColor: 'var(--chakra-colors-neutral-500)',
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: '50%',
    height: 12,
    width: 12,
  };
  const showContent = useStore(zoomSelector);
  const edgesConnectedToSource = getEdges().filter(
    edge => edge.source === props.id,
  );

  return (
    <NodeBox
      onDoubleClick={() => setSelectedNodeId!(props.data.branch.router_id)}
      bg={useColorModeValue('neutral.50', 'neutral.800')}
      border={1}
      borderColor={'var(--chakra-colors-chakra-border-color)'}
      borderStyle={'dashed'}
      nodeProps={props}
      rounded={'100'}
    >
      <VStack px={showContent ? 4 : 2} py={2}>
        <Box className="hex-container branch">
          <VStack gap={0} p={4}>
            <Text fontSize={showContent ? 'sm' : '2xl'}>
              {props.data.branch.name}
            </Text>
          </VStack>
        </Box>
      </VStack>
      <CustomHandle
        type="target"
        position={Position.Top}
        style={{
          top: -6,
        }}
      />
      <CustomHandle
        type="source"
        position={Position.Bottom}
        style={{
          ...DEFAULT_HANDLE_STYLES,
          bottom: -6,
          background:
            edgesConnectedToSource.length > 0
              ? DEFAULT_HANDLE_STYLES.background
              : useColorModeValue(
                  'var(--chakra-colors-red-200)',
                  'var(--chakra-colors-red-800)',
                ),
          borderColor:
            edgesConnectedToSource.length > 0
              ? DEFAULT_HANDLE_STYLES.borderColor
              : useColorModeValue(
                  'var(--chakra-colors-red-50)',
                  'var(--chakra-colors-red-500)',
                ),
        }}
      />
    </NodeBox>
  );
}

BranchRouteNode.type = 'branch_route';
BranchRouteNode.autoRuns = true;

BranchRouteNode.getDefaultNode = (): BranchRouteNodeType => {
  return {
    id: `${BranchRouteNode.type}_${generateRandomCodeForNodes()}`,
    type: BranchRouteNode.type,
    data: {
      branch: {
        router_id: null,
        name: null,
      },
      state_callbacks: {
        on_enter: [],
        on_exit: [],
      },
      transition_callbacks: {
        conditions: [
          {
            func: 'jsonlogic',
            args: {
              query: true,
            },
          },
        ],
        prepare: [],
        before: [],
        after: [],
      },
    },
    position: { x: 0, y: 0 },
  };
};

export default BranchRouteNode;
