import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
} from '@chakra-ui/react';
import { useRef, useState } from 'react';

export interface ConfirmModalProps {
  title: string;
  text: string;
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => Promise<void>;
  confirmButtonText?: string;
  confirmButtonColorScheme?: string;
}

export const ConfirmModal = ({
  title,
  text,
  isOpen,
  onClose,
  onConfirm,
  confirmButtonText = 'Confirm',
  confirmButtonColorScheme = 'brandSecondary',
}: ConfirmModalProps) => {
  const cancelRef = useRef() as any;
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleConfirm = async () => {
    setIsSubmitting(true);
    try {
      await onConfirm();
    } finally {
      setIsSubmitting(false);
      onClose();
    }
  };

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {title}
          </AlertDialogHeader>

          <AlertDialogBody>{text}</AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose} isDisabled={isSubmitting}>
              Cancel
            </Button>
            <Button
              variant="solid"
              colorScheme={confirmButtonColorScheme}
              onClick={handleConfirm}
              ml={3}
              isLoading={isSubmitting}
              loadingText="Submitting"
            >
              {confirmButtonText}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

// For backward compatibility
export const DeleteConfirmModal = (props: ConfirmModalProps) => (
  <ConfirmModal
    {...props}
    confirmButtonText={props.confirmButtonText || 'Delete'}
    confirmButtonColorScheme={props.confirmButtonColorScheme || 'red'}
  />
);
