import { useEffect, useState } from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
} from '@chakra-ui/react';

interface ResetWorkflowConfirmationModal {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => Promise<void>;
}

export const ResetWorkflowConfirmationModal = ({
  isOpen,
  onClose,
  onConfirm,
}: ResetWorkflowConfirmationModal) => {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(false);
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="3xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Reset Workflow</ModalHeader>
        <ModalCloseButton onClick={onClose} />
        <ModalBody>
          <VStack align="flex-start">
            <Text color="red">
              Warning: This action will reset the workflow for this model and
              set it to its initial state.
            </Text>
            <Text>
              Are you sure you want to reset the workflow for this model?
            </Text>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button isDisabled={isLoading} variant="ghost" onClick={onClose}>
            Cancel
          </Button>
          <Button
            isLoading={isLoading}
            colorScheme="brand"
            onClick={async () => {
              setIsLoading(true);
              try {
                await onConfirm();
              } finally {
                setIsLoading(false);
              }
            }}
          >
            Confirm
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
