import { useState, useEffect } from 'react';
import { useToast, Text, VStack, Heading } from '@chakra-ui/react';
import MainApp from './MainApp';
import BootstrapForm from './admin/pages/BootstrapForm';
import AdminAPI from './api/AdminAPI';
import { Spinner, Center, Button } from '@chakra-ui/react';

const BootstrapCheck = () => {
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(true);
  const [isBootstrapped, setIsBootstrapped] = useState(false);
  const [hasServerError, setHasServerError] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const checkBootstrap = async () => {
    setIsLoading(true);
    setHasServerError(false);
    setErrorMessage(null);

    try {
      const response = await AdminAPI.GetBootstrapStatus();
      if (response.isBootstrapped) {
        setIsBootstrapped(true);
      }
    } catch (error: any) {
      const status = error.response?.status;
      if (status === 500) {
        setHasServerError(true);
        setErrorMessage('The server encountered an internal error. Please try again later.');
      } else if (!error.response) {
        setHasServerError(true);
        setErrorMessage('Unable to connect to the server. Please check your internet connection and try again.');
      } else {
        setHasServerError(true);
        setErrorMessage('Failed to check bootstrap status. Please try again later.');
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    checkBootstrap();
  }, []);

  if (isLoading) {
    return (
      <Center h="100vh">
        <Spinner size="xl" />
      </Center>
    );
  }

  if (hasServerError) {
    return (
      <Center h="100vh">
        <VStack spacing={6} p={8} textAlign="center">
          <Heading size="lg" color="red.500">Connection Error</Heading>
          <Text fontSize="lg">{errorMessage}</Text>
          <Button colorScheme="blue" onClick={checkBootstrap}>
            Retry Connection
          </Button>
        </VStack>
      </Center>
    );
  }

  if (isBootstrapped) {
    return <MainApp />;
  }

  return <BootstrapForm onSuccess={checkBootstrap} />;
};

export default BootstrapCheck;
