import React, { createContext, useContext, useState, useCallback } from 'react';

interface ModalContextType {
  footerContent: React.ReactNode;
  setFooterContent: (content: React.ReactNode) => void;
  clearFooter: () => void;
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  externalCloseHandler: (() => void) | undefined;
  setExternalCloseHandler: (handler: (() => void) | undefined) => void;
}

const ModalContext = createContext<ModalContextType | undefined>(undefined);

export function ModalProvider({ children }: { children: React.ReactNode }) {
  const [footerContent, setFooterContent] = useState<React.ReactNode>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [externalCloseHandler, setExternalCloseHandler] = useState<
    (() => void) | undefined
  >(undefined);

  const clearFooter = useCallback(() => {
    setFooterContent(null);
  }, []);

  const onOpen = useCallback(() => {
    setIsOpen(true);
  }, []);

  const onClose = useCallback(() => {
    setIsOpen(false);
    clearFooter();
    if (externalCloseHandler) {
      externalCloseHandler();
    }
  }, [clearFooter, externalCloseHandler]);

  // Reset state when unmounting
  React.useEffect(() => {
    return () => {
      setIsOpen(false);
      clearFooter();
    };
  }, [clearFooter]);

  const value = React.useMemo(
    () => ({
      footerContent,
      setFooterContent,
      clearFooter,
      isOpen,
      onOpen,
      onClose,
      externalCloseHandler,
      setExternalCloseHandler,
    }),
    [footerContent, isOpen, onOpen, onClose, clearFooter, externalCloseHandler],
  );

  return (
    <ModalContext.Provider value={value}>{children}</ModalContext.Provider>
  );
}

export function useModal() {
  const context = useContext(ModalContext);
  if (context === undefined) {
    throw new Error('useModal must be used within a ModalProvider');
  }
  return context;
}
