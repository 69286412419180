import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  VStack,
  useToast,
  FormControl,
  Text,
  List,
  ListItem,
  ListIcon,
  HStack,
  Switch,
  Alert,
  AlertIcon,
  Box,
  AlertTitle,
  AlertDescription,
  useDisclosure,
  Spacer,
} from '@chakra-ui/react';
import { useState, useEffect, useRef } from 'react';
import { useQuery } from 'react-query';
import AdminAPI, { Organization } from '../../api/AdminAPI';
import FormSelect from '../pages/FormSelect';
import { TInventoryModel } from '../../models/inventory_model';
import { CheckIcon } from '@chakra-ui/icons';
import JSZip from 'jszip';
import { saveAs } from 'file-saver-es';
import { ExportOrganizationErrorsModal } from './ExportOrganizationErrorsModal';

interface ExportOrganizationModalProps {
  isOpen: boolean;
  onClose: () => void;
  organization: Organization;
}

export function ExportOrganizationModal({
  isOpen,
  onClose,
  organization,
}: ExportOrganizationModalProps) {
  const fileDownloadRef = useRef<HTMLAnchorElement | null>(null);
  const [downloading, setDownloading] = useState(false);
  const [name, setName] = useState('');
  const [errors, setErrors] = useState<Record<string, string[]>>({});
  const [includeModelDocumentation, setIncludeModelDocumentation] =
    useState(false);
  const [selectedModels, setSelectedModels] = useState<TInventoryModel[]>([]);
  const [modelInventory, setModelInventory] = useState<TInventoryModel[]>([]);
  const exportOrganizationErrorsModal = useDisclosure();
  const { isLoading } = useQuery(
    ['inventory-models'],
    async () => {
      return await AdminAPI.GetOrganizationInventoryModels(organization.cuid);
    },
    {
      onSuccess: data => setModelInventory(data),
      onError: err => {
        // track errors
      },
    },
  );
  const toast = useToast();

  useEffect(() => {
    if (organization && isOpen) {
      setName(organization.name);
    }
  }, [organization, isOpen]);

  const handleChangeModelValues = (modelNames: string[]) => {
    if (modelInventory) {
      const newModels = modelInventory.filter(model =>
        modelNames.includes(model.name),
      );
      setSelectedModels(newModels);
    }
  };

  const handleSelectAllModels = () => {
    if (modelInventory) {
      setSelectedModels(
        selectedModels.length === modelInventory.length ? [] : modelInventory,
      );
    }
  };

  const handleSubmit = async () => {
    try {
      setDownloading(true);
      setErrors({});
      let response;
      response = await AdminAPI.ExportOrganizationTemplate(organization.cuid, {
        inventory_model_cuids: selectedModels?.map(model => model.cuid),
        include_model_documentation: includeModelDocumentation,
      });
      // Convert response to Blob
      const orgTemplateJsonBlob = new Blob(
        [JSON.stringify(response.organization_template, null, 2)],
        {
          type: 'application/json',
        },
      );

      const modelDataJsonBlob = new Blob(
        [JSON.stringify(response.model_data_template, null, 2)],
        {
          type: 'application/json',
        },
      );

      setErrors(response.model_data_errors);

      // Create a new ZIP file
      const zip = new JSZip();
      zip.file('org_template.json', orgTemplateJsonBlob); // Add JSON file to ZIP
      zip.file('model_data_template.json', modelDataJsonBlob);

      // Generate ZIP file as Blob
      zip.generateAsync({ type: 'blob' }).then(zipBlob => {
        saveAs(zipBlob, `${organization.name}_org_template.zip`); // Trigger download
      });
    } catch (e: any) {
      toast({
        variant: 'subtle',
        title: 'Error exporting organization template',
        description: e.message || 'Failed to download organization template',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setDownloading(false);
    }
  };

  const handleClose = () => {
    setName('');
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose} size="3xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Export Organization Template for {name}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {Object.keys(errors).length > 0 && (
            <Alert status="warning" borderRadius="md" mb={4}>
              <AlertIcon />
              <Box>
                <AlertTitle fontWeight="medium">
                  Partial export completed
                </AlertTitle>
                <AlertDescription
                  whiteSpace="normal"
                  maxWidth="100%"
                  wordBreak="break-word"
                >
                  Your organization template and data template were successfully
                  exported, but some assets for certain models could not be
                  included.
                </AlertDescription>
                <Spacer h={2} />
                <Button
                  size="sm"
                  variant="link"
                  onClick={exportOrganizationErrorsModal.onOpen}
                >
                  View More Details
                </Button>
              </Box>
            </Alert>
          )}
          <VStack spacing={6} align="stretch">
            <Text color="gray.600" fontSize="md">
              Export a template file that can be used to create new
              organizations. The template will include:
            </Text>

            <List spacing={2} pl={4}>
              <ListItem display="flex" alignItems="center">
                <ListIcon as={CheckIcon} color="brand.500" w={4} h={4} />
                <Text color="gray.600">Sample models (selected below)</Text>
              </ListItem>
              <ListItem display="flex" alignItems="center">
                <ListIcon as={CheckIcon} color="brand.500" w={4} h={4} />
                <Text color="gray.600">
                  Default risk areas and validation guidelines
                </Text>
              </ListItem>
              <ListItem display="flex" alignItems="center">
                <ListIcon as={CheckIcon} color="brand.500" w={4} h={4} />
                <Text color="gray.600">
                  Default set of templates (documentation, validation report and
                  ongoing monitoring)
                </Text>
              </ListItem>
              <ListItem display="flex" alignItems="center">
                <ListIcon as={CheckIcon} color="brand.500" w={4} h={4} />
                <Text color="gray.600">
                  Default set of model inventory fields
                </Text>
              </ListItem>
            </List>

            <FormControl isRequired isDisabled={isLoading}>
              <FormSelect
                label="Models to Export"
                selectedItems={selectedModels}
                options={
                  modelInventory
                    ?.sort((a, b) => a.name.localeCompare(b.name))
                    .map(model => ({
                      value: model.name,
                      label: model.name,
                    })) || []
                }
                onChange={(newValues: string[]) =>
                  handleChangeModelValues(newValues)
                }
                onSelectAll={handleSelectAllModels}
                allSelected={selectedModels.length === modelInventory?.length}
                isTemplate={false}
              />
              <HStack alignItems={'self-start'}>
                <Switch
                  size={'md'}
                  isChecked={includeModelDocumentation}
                  isDisabled={selectedModels.length === 0}
                  onChange={e => setIncludeModelDocumentation(e.target.checked)}
                  colorScheme="brand"
                  mt={1}
                />
                <Text>Export full documentation for selected models</Text>
              </HStack>
            </FormControl>
          </VStack>
          <ExportOrganizationErrorsModal
            isOpen={exportOrganizationErrorsModal.isOpen}
            onClose={exportOrganizationErrorsModal.onClose}
            errors={errors}
          />
        </ModalBody>
        <ModalFooter>
          <Button variant="tertiary" mr={3} onClick={handleClose}>
            Cancel
          </Button>
          <a
            hidden
            ref={fileDownloadRef}
            download={`${organization.name}_org_template.zip`}
          />
          <Button
            variant="primary"
            onClick={handleSubmit}
            isLoading={downloading}
            isDisabled={!name}
          >
            Export Organization Template
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
