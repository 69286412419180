import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Button,
  FormControl,
  HStack,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Stack,
  Text,
  Textarea,
  useColorModeValue,
  useRadioGroup,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { useContext, useEffect, useState } from 'react';
import { Label } from '../Layout';
import { useMutation, useQueryClient } from 'react-query';
import API from '../../api/API';
import {
  TBlockLibraryItem,
  TBlockLibraryItemCreate,
  TBlockLibraryItemUpdate,
} from '../../models/block_library';
import { UsersContext } from '../../contexts';

import { CKEditorWrapper } from '../TextContentEditor/CKEditorWrapper';
import { CheckCircleIcon } from '@heroicons/react/24/solid';
import RadioCard from '../RadioCard';
interface BlockLibraryModalProps {
  isOpen: boolean;
  onClose: () => void;
  existingItem?: TBlockLibraryItem;
  mode: 'add' | 'edit' | 'duplicate';
  initialContent?: string;
}

const sharingOptions = ['Private', 'Shared'];

export default function BlockLibraryModal({
  isOpen,
  onClose,
  existingItem,
  mode,
  initialContent,
}: BlockLibraryModalProps) {
  const toast = useToast();
  const queryClient = useQueryClient();
  const { currentUser } = useContext(UsersContext);

  const [name, setName] = useState('');
  const [description, setDescription] = useState<string | undefined>(undefined);
  const [content, setContent] = useState('');
  const [isShared, setIsShared] = useState<boolean | undefined>(undefined);

  const { userHasPermission } = useContext(UsersContext);

  const canManageSharedBlockLibrary = userHasPermission(
    ['manage_shared_block_library'],
    'all',
  );

  useEffect(() => {
    if (existingItem) {
      if (mode === 'duplicate') {
        setName(`Copy of ${existingItem.name}`);
      } else {
        setName(existingItem.name);
      }
      setDescription(existingItem.description);
      setContent(existingItem.content);
      setIsShared(existingItem.is_shared);
    } else {
      setName('');
      setDescription('');
      setContent('');
      setIsShared(undefined);
    }
  }, [existingItem, mode]);

  useEffect(() => {
    // If the user cannot manage the shared block library,
    // then we should not allow them to create a shared block
    if (!canManageSharedBlockLibrary) {
      setIsShared(false);
    }
  }, [canManageSharedBlockLibrary]);

  useEffect(() => {
    if (isOpen && initialContent) {
      setContent(initialContent);
    }
  }, [isOpen, initialContent]);

  const createMutation = useMutation(
    ['block-library', 'create'],
    async (data: TBlockLibraryItemCreate) => {
      return API.CreateBlockLibraryItem(data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['block-library']);
        toast({
          variant: 'subtle',
          title: 'Block Library item created',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        onClose();
      },
    },
  );

  const updateMutation = useMutation(
    ['block-library', 'update'],
    async ({ cuid, data }: { cuid: string; data: TBlockLibraryItemUpdate }) => {
      return API.UpdateBlockLibraryItem(cuid, data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['block-library']);
        toast({
          title: 'Block Library item updated',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        onClose();
      },
    },
  );

  const handleSubmit = async () => {
    const data: TBlockLibraryItemCreate = {
      name,
      description,
      content,
      is_shared: isShared ?? false,
    };

    if (mode === 'edit' && existingItem) {
      await updateMutation.mutateAsync({
        cuid: existingItem.cuid,
        data,
      });
    } else {
      await createMutation.mutateAsync(data);
    }
  };

  const isSubmitting = createMutation.isLoading || updateMutation.isLoading;
  const isError = createMutation.isError || updateMutation.isError;

  const { getRadioProps } = useRadioGroup({
    name: 'isShared',
    defaultValue: undefined,
    onChange: (value: string) => {
      if (value === 'Private') {
        setIsShared(false);
      } else if (value === 'Shared') {
        setIsShared(true);
      }
    },
  });

  return (
    <Modal
      scrollBehavior="inside"
      trapFocus={false}
      isOpen={isOpen}
      onClose={onClose}
      size="6xl"
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {mode === 'add'
            ? 'Add Text Block to Library'
            : mode === 'edit'
            ? `Edit ${existingItem?.name}`
            : `Duplicate ${existingItem?.name}`}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {isError && (
            <Alert status="error" mb={4}>
              <AlertIcon />
              <AlertTitle>Error</AlertTitle>
              <AlertDescription>
                {API.getAPIErrorMessage(
                  createMutation.error || updateMutation.error,
                )}
              </AlertDescription>
            </Alert>
          )}
          <Stack spacing={4}>
            <FormControl isRequired>
              <Label mb={2}>Name</Label>
              <Input
                type="text"
                value={name}
                onChange={e => setName(e.target.value)}
              />
            </FormControl>

            <FormControl>
              <Label mb={2} isOptional>
                Description
              </Label>
              <Textarea
                value={description}
                onChange={e => setDescription(e.target.value)}
                rows={3}
              />
            </FormControl>

            <FormControl isRequired>
              <Label mb={2}>Content</Label>
              <CKEditorWrapper
                readOnly={false}
                text={content}
                onChange={(text: any) => {
                  setContent(text);
                }}
                enabledFeatures={{
                  images: true,
                  comments: false,
                  revisions: false,
                  deleteBlock: false,
                  generateWithAI: false,
                }}
                hideTooltip={true}
                showOutline={true}
                autoSave={false}
                containerStyle={{
                  width: '100%',
                  borderWidth: 1,
                  alignSelf: 'flex-start',
                  borderRadius: 4,
                }}
                withHeight
                useGenericUploadAdapter={true}
              />
            </FormControl>

            {canManageSharedBlockLibrary && (
              <FormControl display="flex" flexDirection="column">
                <Label mb={2}>Sharing</Label>
                <HStack>
                  {sharingOptions.map(value => {
                    const radio = getRadioProps({ value });
                    const selectedValue =
                      isShared === undefined
                        ? 'undefined'
                        : isShared
                        ? 'Shared'
                        : 'Private';
                    return (
                      <RadioCard
                        borderWidth={1}
                        px={4}
                        py={2}
                        color={
                          selectedValue === value
                            ? 'brand.800'
                            : useColorModeValue('neutral.800', 'neutral.200')
                        }
                        _hover={{
                          bg:
                            selectedValue === value
                              ? 'brand.25'
                              : 'brandSecondary.25',
                          borderColor:
                            selectedValue === value
                              ? 'brand.base'
                              : 'brandSecondary.600',
                        }}
                        rounded={'md'}
                        transition={'all 0.3s ease-out'}
                        {...radio}
                      >
                        <HStack alignItems={'flex-start'}>
                          <VStack gap={0} alignItems={'flex-start'}>
                            <Text fontWeight={'bold'}>
                              {value == sharingOptions[0]
                                ? sharingOptions[0]
                                : sharingOptions[1]}
                            </Text>
                            <Text>
                              {value == sharingOptions[0]
                                ? 'Only visible to you.'
                                : 'Accessible across your organization.'}
                            </Text>
                          </VStack>
                          <Spacer />
                          <Icon
                            as={CheckCircleIcon}
                            color={
                              selectedValue === value
                                ? 'brand.500'
                                : useColorModeValue(
                                    'neutral.200',
                                    'neutral.800',
                                  )
                            }
                            boxSize={5}
                            transition={'all 0.3s ease-in-out'}
                          />
                        </HStack>
                      </RadioCard>
                    );
                  })}
                </HStack>
              </FormControl>
            )}
          </Stack>
        </ModalBody>
        <ModalFooter justifyContent="space-between">
          <Button
            variant="ghost"
            mr={3}
            onClick={onClose}
            isDisabled={isSubmitting}
          >
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            isLoading={isSubmitting}
            isDisabled={!name || !content || isShared === undefined}
            variant={'primary'}
          >
            {mode === 'add'
              ? 'Add Block'
              : mode === 'edit'
              ? 'Save Block'
              : 'Duplicate Block'}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
