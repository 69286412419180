import React, { useEffect, useState, useMemo } from 'react';
import {
  Box,
  Center,
  Progress,
  Stack,
  HStack,
  Button,
  Text,
  Icon,
  Tag,
  Wrap,
  WrapItem,
  TagLeftIcon,
  TagLabel,
  Badge,
  Tooltip,
} from '@chakra-ui/react';
import { DataTable } from '../../components/DataTable';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import {
  ArrowLongLeftIcon,
  ArrowLongRightIcon,
  ExclamationCircleIcon,
} from '@heroicons/react/24/solid';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import API from '../../api/API';
import { TFinding } from '../../models';
import { SeverityTagColor, StatusTagColor } from '../../colors';
import {
  getFindingDisplayStatus,
  TFindingPaginated,
} from '../../models/finding';
import { statusText } from '../../components/FindingsList/FindingRow';
import { CubeIcon } from '@heroicons/react/24/outline';
import { displayFormattedDate } from '../../utils';
import dayjs from 'dayjs';
import AvatarProxy from '../AvatarProxy';
import { APIRequestSortBy } from '../../models/utils';

export const ALL_FINDINGS_COLUMNS = [
  {
    Header: 'Title',
    accessor: 'title',
    Cell: ({ row }: any) => {
      return <Text fontWeight={'semibold'}>{row.values.title}</Text>;
    },
    sortField: 'title',
    sortType: 'Alphabet',
  },
  {
    Header: 'Severity',
    accessor: 'severity',
    Cell: ({ value }: any) => {
      return (
        <Badge
          px={2}
          colorScheme={SeverityTagColor[value.name as keyof object]}
          variant={'subtle'}
          fontSize={'sm'}
        >
          {value.name}
        </Badge>
      );
    },
    sortField: 'severity',
    sortType: 'Severity',
  },
  {
    Header: 'Status',
    accessor: 'status',
    Cell: ({ row }: any) => {
      const finding = row.values as TFinding;
      const status = getFindingDisplayStatus(finding);
      return (
        <Tag
          data-testid="finding-status-badge"
          colorScheme={StatusTagColor[status as keyof object]}
          variant={'outline'}
        >
          <TagLeftIcon as={ExclamationTriangleIcon} />
          {statusText[status as keyof object]}
        </Tag>
      );
    },
    sortField: 'status',
    sortType: 'Alphabet',
  },
  {
    Header: 'Inventory Model',
    accessor: 'inventory_model.name',
    Cell: ({ value }: any) => {
      return (
        <HStack gap={1} alignItems={'flex-start'}>
          <Icon boxSize={4} as={CubeIcon} mt={0.5} />
          <Text>{value}</Text>
        </HStack>
      );
    },
    sortField: 'inventory_model.name',
    sortType: 'Alphabet',
  },
  {
    Header: 'Risk Area',
    accessor: 'risk_area',
    Cell: ({ value }: any) => {
      if (!value) return null;
      return <Text>{value.name}</Text>;
    },
    sortField: 'risk_area.name',
    sortType: 'Alphabet',
  },
  {
    Header: 'Business Unit',
    accessor: 'business_unit.name',
    sortField: 'business_unit.name',
    sortType: 'Alphabet',
  },
  {
    Header: 'Assigned To',
    accessor: 'owner',
    Cell: ({ value }: any) => {
      if (!value) {
        return null;
      }
      return (
        <Box>
          <Tag key={value.cuid} size={'md'}>
            <AvatarProxy
              src={value.picture}
              size="xs"
              name={value.name}
              ml={-2}
              mr={2}
            />
            <TagLabel>{value.name}</TagLabel>
          </Tag>
        </Box>
      );
    },
    sortField: 'owner',
    sortType: 'Alphabet',
  },
  {
    Header: 'Due Date',
    accessor: 'due_at',
    Cell: ({ row }: any) => {
      const finding = row.values as TFinding;
      const status = getFindingDisplayStatus(finding);

      if (!finding.due_at) {
        return null;
      }

      const isOverdue = status === 'past_due';
      return (
        <Wrap spacing={0}>
          <WrapItem ml={0}>
            {isOverdue ? (
              <Tooltip
                placement="right"
                hasArrow
                label={`Overdue by ${dayjs().diff(
                  dayjs(finding.due_at * 1000),
                  'days',
                )} ${
                  dayjs().diff(dayjs(finding.due_at * 1000), 'days') <= 1
                    ? 'day'
                    : 'days'
                }`}
              >
                <Tag
                  py={0}
                  pl={0}
                  size={'sm'}
                  rounded="md"
                  variant={'outline'}
                  colorScheme={'orange'}
                >
                  {isOverdue && (
                    <TagLeftIcon as={ExclamationCircleIcon} boxSize={6} />
                  )}
                  {displayFormattedDate(finding.due_at)}
                </Tag>
              </Tooltip>
            ) : (
              <Tag size={'sm'}>{displayFormattedDate(finding.due_at)}</Tag>
            )}
          </WrapItem>
          <WrapItem ml={3}>
            {isOverdue && <Text color={'red.600'} fontSize={'sm'}></Text>}
          </WrapItem>
        </Wrap>
      );
    },
    sortField: 'due_at',
    sortType: 'Date',
  },
  {
    Header: 'Creation date',
    accessor: 'created_at',
    Cell: ({ value }: any) => {
      if (!value) {
        return null;
      }
      return <Tag whiteSpace={'nowrap'}>{displayFormattedDate(value)}</Tag>;
    },
    sortField: 'created_at',
    sortType: 'Date',
  },
] as const;

export const getLabelFromSortType = (
  sortType: SortType,
  order: 'asc' | 'desc',
) => {
  if (sortType === 'Alphabet') {
    return order === 'asc' ? 'A to Z' : 'Z to A';
  }
  if (sortType === 'Date') {
    return order === 'asc' ? 'Oldest first' : 'Latest first';
  }
  if (sortType === 'Severity') {
    return order === 'asc' ? 'High to Low' : 'Low to High';
  }
  throw Error('Sort Type not supported');
};

export const sortFindingsOptions: APIRequestSortBy[] = [
  {
    label: 'Most severe first',
    field: 'severity',
    order: 'asc',
    orderLabel: getLabelFromSortType('Severity', 'asc'),
  },
  {
    label: 'Most recent first',
    field: 'created_at',
    order: 'desc',
    orderLabel: getLabelFromSortType('Date', 'desc'),
  },
  {
    label: 'Closest due date first',
    field: 'due_at',
    order: 'asc',
    orderLabel: getLabelFromSortType('Date', 'asc'),
  },
];

type SortType = typeof ALL_FINDINGS_COLUMNS[number]['sortType'];

interface FindingsTableProps {
  filters: any;
  limit?: number;
  columns: string[];
  sortBy: any;
  onTableSort?: (column: any[]) => void;
  onClickRow?: (row: any) => void;
  getData?: (page: number, pageSize: number) => Promise<TFindingPaginated>;
}

const FindingsTable: React.FC<FindingsTableProps> = ({
  filters,
  limit = 30,
  columns,
  sortBy,
  onTableSort,
  onClickRow,
  getData,
}) => {
  const [findings, setFindings] = useState<TFinding[]>([]);
  const [tableColumns, setTableColumns] = useState<any[]>([]);
  const [page, setPage] = useState(1);
  const navigate = useNavigate();

  const { isLoading, data, refetch, isRefetching } = useQuery(
    ['findings', filters, page, limit, sortBy],
    async () => {
      if (getData) {
        return await getData(page, limit);
      }
      return await API.GetFindings(page, limit, filters, sortBy);
    },
    {
      onSuccess: data => setFindings(data.results),
    },
  );

  const defaultTableSortBy = useMemo(
    () => [
      {
        id: sortBy?.field,
        desc: sortBy?.order === 'desc',
      },
    ],
    [sortBy],
  );

  useEffect(() => {
    refetch();
  }, [page]);

  useEffect(() => {
    setPage(1);
    refetch();
  }, [sortBy]);

  useEffect(() => {
    refetch();
  }, [filters, page, sortBy]);

  useEffect(() => {
    setPage(1);
    refetch();
  }, [getData]);

  useEffect(() => {
    setTableColumns([
      {
        Header: ' ',
        disableSortBy: true,
        Cell: ({ row }: any) => (
          <Center>
            <Icon
              as={ExclamationTriangleIcon}
              boxSize={6}
              color={'neutral.400'}
            />
          </Center>
        ),
      },
      ...columns.map(c => ALL_FINDINGS_COLUMNS.find(col => col.accessor === c)),
    ]);
  }, [columns]);

  return (
    <Box display="flex" overflow="hidden">
      <Stack
        gap={1}
        w={'full'}
        overflow="hidden"
        pt={isLoading || isRefetching ? 0 : 3}
      >
        {(isLoading || isRefetching) && (
          <Progress size="xs" isIndeterminate colorScheme="brand" />
        )}

        {data?.total === 0 && (
          <Center>
            <Text>
              No findings match your criteria. Try adjusting your filters.
            </Text>
          </Center>
        )}

        <Stack
          display={data && data.total > 0 ? 'block' : 'none'}
          overflowY="scroll"
        >
          <DataTable
            data={findings}
            columns={tableColumns}
            enableSort={!!onTableSort}
            pageSize={limit}
            isInteractive={true}
            onClickRow={onClickRow}
            manualSortBy={true}
            defaultSortBy={defaultTableSortBy}
            onSort={onTableSort}
          />
        </Stack>

        {data && data?.results?.length > 0 && (
          <Center>
            <HStack>
              <Button
                size="sm"
                variant="ghost"
                leftIcon={<Icon as={ArrowLongLeftIcon} />}
                onClick={() => setPage(page - 1)}
                isDisabled={page === 1}
              >
                Prev
              </Button>
              <Text>
                Showing {(page - 1) * limit + 1} to{' '}
                {Math.min(page * limit, data.total)} of {data.total} Findings
              </Text>
              <Button
                size="sm"
                variant="ghost"
                rightIcon={<Icon as={ArrowLongRightIcon} />}
                onClick={() => setPage(page + 1)}
                isDisabled={data.total / limit <= page}
              >
                Next
              </Button>
            </HStack>
          </Center>
        )}
      </Stack>
    </Box>
  );
};

export default FindingsTable;
