import { Handle, NodeProps, Position, useStore } from 'reactflow';
import {
  displayFormattedDateAndTime,
  generateRandomCodeForNodes,
} from '../../../../../../utils';
import {
  Button,
  HStack,
  Icon,
  IconButton,
  Text,
  Tooltip,
  useColorModeValue,
  VStack,
  Box,
  Tag,
  Stack,
} from '@chakra-ui/react';
import NodeBox from '../../layouts/NodeBox';
import { WaitNodeType } from '../../types';
import dayjs from 'dayjs';
import {
  Cog6ToothIcon,
  ArrowRightCircleIcon,
  ClockIcon,
} from '@heroicons/react/24/outline';
import { useContext } from 'react';
import useWorkflow from '../../../../../../hooks/useWorkflow';
import ResumeIn from '../../../../../../components/ResumeIn';
import useWorkflowExecution from '../../../../../../hooks/useWorkflowExecution';
import UsersContext from '../../../../../../contexts/UsersContext';
import useModelSchema from '../../../../../../hooks/useModelSchema';
import { ExecutionStatus } from '../../../../../../models/workflow';

const WAIT_TYPE_LABELS: Record<string, string> = {
  interval: 'Time interval',
  specific_date: 'Specific date/time',
  custom_field: 'Model field',
};

const formatTimeUnit = (amount: number, unit: string | null): string => {
  if (!unit) return '';
  const baseUnit = unit.toLowerCase().replace(/s$/, '');
  return amount === 1 ? baseUnit : `${baseUnit}s`;
};

const zoomSelector = (s: any) => s.transform[2] >= 0.5;
function WaitNode(props: NodeProps) {
  const { currentUser } = useContext(UsersContext);
  const { setSelectedNodeId } = useWorkflow();
  const { execution, resumeWait } = useWorkflowExecution();
  const isAdmin = currentUser?.roles.some(role => role.role.is_admin);
  const { propertyItems } = useModelSchema();
  const showContent = useStore(zoomSelector);

  const DEFAULT_HANDLE_STYLES = {
    background: useColorModeValue(
      'var(--chakra-colors-neutral-200)',
      'var(--chakra-colors-neutral-800)',
    ),
    borderColor: useColorModeValue(
      'var(--chakra-colors-neutral-50)',
      'var(--chakra-colors-neutral-500)',
    ),
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: '50%',
    height: 16,
    width: 16,
  };

  const getFieldDisplayName = (fieldKey: string) => {
    const field = propertyItems.find(prop => prop.key === fieldKey);
    return field?.title || fieldKey; // Fallback to key if title not found
  };

  const getWaitTypeDisplay = (type: string) => {
    switch (type) {
      case 'interval':
        return 'Wait for';
      case 'specific_date':
        return 'Wait until';
      case 'custom_field':
        return 'Wait until date in field';
      default:
        return 'Unknown wait type';
    }
  };

  if (props.data.state_callbacks.on_enter[0].args.wait_type === null) {
    return (
      <NodeBox title={'Wait'} icon={ClockIcon} nodeProps={props} rounded={'sm'}>
        <VStack w={'full'} px={4} py={2}>
          {showContent && (
            <Text fontStyle={'oblique'} w={40} textAlign={'center'}>
              This step hasn't been configured yet.
            </Text>
          )}
          <Button
            leftIcon={<Icon as={Cog6ToothIcon} boxSize={4} />}
            onClick={() => setSelectedNodeId!(props.id)}
            w={'full'}
          >
            Configure
          </Button>
        </VStack>
      </NodeBox>
    );
  }

  const onResumeWait = async () => {
    if (resumeWait) {
      resumeWait.mutate({});
    }
  };

  const canResumeWait = !!(
    execution &&
    [ExecutionStatus.WAITING].includes(execution.status) &&
    execution.current_node === props.id &&
    isAdmin
  );

  return (
    <NodeBox
      title={'WAIT'}
      icon={ClockIcon}
      bg={useColorModeValue('neutral.50', 'neutral.800')}
      border={2}
      borderColor={'var(--chakra-colors-chakra-border-color)'}
      borderStyle={'solid'}
      nodeProps={props}
      rounded={showContent ? 'lg' : 'full'}
    >
      <Handle
        type="target"
        position={Position.Top}
        style={{ ...DEFAULT_HANDLE_STYLES, top: -8 }}
      />

      {canResumeWait && (
        <Tooltip label="Double click to run it now">
          <IconButton
            // isRound={true}
            style={{
              position: 'absolute',
              top: '.5rem',
              left: '-1.9rem',
              borderRight: 0,
              background: 'var(--chakra-colors-neutral-25)',
            }}
            borderColor={'neutral.200'}
            aria-label="Run now!"
            size={'sm'}
            roundedRight={'0'}
            icon={<Icon as={ArrowRightCircleIcon} boxSize={6} />}
            onDoubleClick={onResumeWait}
            isLoading={resumeWait?.isLoading}
          />
        </Tooltip>
      )}

      <HStack alignItems={'flex-start'} px={4} py={2}>
        <VStack gap={1} alignItems={'flex-start'} w="full">
          <Stack
            w={'full'}
            direction={showContent ? 'row' : 'column'}
            alignItems={showContent ? 'flex-start' : 'center'}
          >
            {!showContent ? <Icon as={ClockIcon} boxSize={6} /> : ''}

            {showContent ? (
              <Text>
                {getWaitTypeDisplay(
                  props.data?.state_callbacks?.on_enter[0]?.args?.wait_type,
                )}
              </Text>
            ) : (
              ''
            )}
            {props.data?.state_callbacks?.on_enter[0]?.args?.wait_type ===
              'interval' && (
              <Text>
                {props.data?.state_callbacks?.on_enter[0]?.args?.wait_amount}{' '}
                {formatTimeUnit(
                  props.data?.state_callbacks?.on_enter[0]?.args?.wait_amount,
                  props.data?.state_callbacks?.on_enter[0]?.args?.wait_unit,
                )}
              </Text>
            )}

            {props.data?.state_callbacks?.on_enter[0]?.args?.wait_type ===
              'specific_date' && (
              <Tag size={showContent ? 'md' : 'lg'}>
                {displayFormattedDateAndTime(
                  dayjs(
                    props.data?.state_callbacks?.on_enter[0]?.args
                      ?.specific_datetime,
                  ).unix(),
                )}
              </Tag>
            )}

            {props.data?.state_callbacks?.on_enter[0]?.args?.wait_type ===
              'custom_field' && (
              <Text>
                {getFieldDisplayName(
                  props.data?.state_callbacks?.on_enter[0]?.args?.custom_field,
                )}
              </Text>
            )}

            {execution &&
              execution.status === 'waiting' &&
              execution.current_node === props.id &&
              execution.resume_wait_at && (
                <Box pt={1}>
                  <ResumeIn targetTimestamp={execution.resume_wait_at} />
                </Box>
              )}
          </Stack>
        </VStack>
      </HStack>

      <Handle
        type="source"
        position={Position.Bottom}
        style={{ ...DEFAULT_HANDLE_STYLES, bottom: -8 }}
      />
    </NodeBox>
  );
}

WaitNode.type = 'wait';
WaitNode.autoRuns = true;

WaitNode.getDefaultNode = (): WaitNodeType => {
  return {
    id: `${WaitNode.type}_${generateRandomCodeForNodes()}`,
    type: WaitNode.type,
    data: {
      state_callbacks: {
        on_enter: [
          {
            func: 'run_wait',
            args: {
              wait_type: null, // Default: 'interval' or 'specific_datetime'
              wait_unit: null, // Default unit for interval
              wait_amount: null, // Default amount for interval
              specific_datetime: null, // Null by default, used for 'specific_datetime'
              custom_field: null, // Optional: Model field reference
            },
          },
        ],
        on_exit: [],
      },
      transition_callbacks: {
        conditions: [],
        prepare: [],
        before: [],
        after: [],
      },
    },
    position: { x: 0, y: 0 },
  };
};

export default WaitNode;
