import {
  Box,
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
  useColorModeValue,
  useToast,
  Flex,
  Textarea,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverBody,
  Heading,
  ScaleFade,
  Tag,
  TagLeftIcon,
} from '@chakra-ui/react';
import {
  FC,
  useEffect,
  useRef,
  useState,
  useCallback,
  useContext,
} from 'react';
import AITextContentEditor, {
  AIGenerationConfig,
  AIGenerationStatus,
  AITextContentEditorMethods,
} from '../TextContentEditor/AITextContentEditor';
import { DownloadIcon, TimeIcon } from '@chakra-ui/icons';
import { EmptyStateDisplay } from '../EmptyStateDisplay';
import './Styles.css';
import ValidMindVLogo from '../ValidMindVLogo';
import UsersContext from '../../contexts/UsersContext';
import AvatarProxy from '../AvatarProxy';
import { Bars3BottomLeftIcon } from '@heroicons/react/24/outline';

interface ChatMessage {
  type: 'user' | 'assistant';
  content: string;
  timestamp: Date;
  text?: string;
  isHTML?: boolean;
}

interface GenerateWithAIModalProps {
  isOpen: boolean;
  onClose: () => void;
  onAccept: (text: string) => void;
  aiGenerationConfig: AIGenerationConfig;
  initialContent?: string;
}

interface SectionContext {
  title?: string;
  guidelines?: string[];
}

interface GuidelinesPopoverProps {
  sectionContext?: SectionContext;
}

const GuidelinesPopover: FC<GuidelinesPopoverProps> = ({ sectionContext }) => {
  if (!sectionContext?.guidelines || sectionContext.guidelines.length === 0) {
    return null;
  }

  const guidelines = sectionContext.guidelines || [];
  const guidelinesText = guidelines.length === 1 ? 'Guideline' : 'Guidelines';

  return (
    <Popover
      placement="top"
      trigger="hover"
      gutter={16}
      closeOnBlur={true}
      isLazy
    >
      <PopoverTrigger>
        <Tag variant="outline">
          <TagLeftIcon as={Bars3BottomLeftIcon} />
          {`${sectionContext.title || 'Section'} ${guidelinesText}`}
        </Tag>
      </PopoverTrigger>
      <PopoverContent
        width="600px"
        bg={useColorModeValue('white', 'neutral.800')}
        color={useColorModeValue('neutral.800', 'white')}
        boxShadow="lg"
        borderRadius="lg"
        borderWidth="1px"
        borderColor={useColorModeValue('neutral.200', 'neutral.600')}
      >
        <PopoverArrow />
        <PopoverBody p={0}>
          <Box
            p={4}
            borderBottom="1px solid"
            borderBottomColor={'var(--chakra-colors-chakra-border-color)'}
            borderTopRadius="md"
          >
            <Heading as="h3" size="sm" fontWeight="semibold">
              {`${sectionContext.title || 'Section'} ${guidelinesText}`}
            </Heading>
          </Box>
          <Box p={5} maxH="350px" overflow="auto">
            {guidelines.map((guideline, index) => (
              <Box
                key={index}
                mb={index < guidelines.length - 1 ? 4 : 0}
                dangerouslySetInnerHTML={{ __html: guideline }}
              />
            ))}
          </Box>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

const GenerateWithAIModal: FC<GenerateWithAIModalProps> = ({
  isOpen,
  onClose,
  onAccept,
  aiGenerationConfig,
  initialContent,
}) => {
  const chatContainerRef = useRef<HTMLDivElement>(null);
  const aiEditorRef = useRef<AITextContentEditorMethods>(null);
  const toast = useToast();
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const { currentUser } = useContext(UsersContext);

  // const defaultPrompt = 'Please provide the content for this section...';
  const defaultPrompt = '';
  const [prompt, setPrompt] = useState<string>(defaultPrompt);
  const [chatHistory, setChatHistory] = useState<ChatMessage[]>([]);
  const [aiStatus, setAiStatus] = useState<AIGenerationStatus>('idle');
  const [trapFocus, setTrapFocus] = useState(false);

  // const loadingMessages = [
  //   'Hold on—processing brilliance…',
  //   'Just a sec—thinking at full capacity…',
  //   'Give me a moment—almost there…',
  //   'Hang tight—loading something amazing…',
  //   'Wait for it—smart mode activated…',
  //   'One second—refining the perfect response…',
  //   'Hold up—greatness takes time…',
  //   'Stay with me—running on all cylinders…',
  //   'Patience, please—optimizing for the best answer…',
  //   'Wait a moment—a little patience goes a long way…',
  //   'Just a tick—gears are turning…',
  //   'Give it a second—hold on, I'm nearly there…',
  //   'Bear with me—precision takes a moment…',
  //   'Stay tuned—I'm crafting gold…',
  //   'Almost there—just a brief moment of genius…',
  //   'Hold on—perfecting my response…',
  //   'Processing—calibrating my best answer…',
  //   'Smart things take time—just a little…',
  //   'Trust me—I'm almost done…',
  //   'Final touches—wait for it…',
  // ];
  const loadingMessages = ['Generating content...'];

  const [loadingMessage, setLoadingMessage] = useState('');

  // Simplified scrollToBottom function
  const scrollToBottom = useCallback(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, []);

  const updateLastAssistantMessage = useCallback(
    (content: string) => {
      setChatHistory(prev => {
        const lastMessage = prev[prev.length - 1];
        if (lastMessage && lastMessage.type === 'assistant') {
          return [...prev.slice(0, -1), { ...lastMessage, content }];
        }
        return prev;
      });

      // Schedule scrolling after state update
      requestAnimationFrame(scrollToBottom);
    },
    [scrollToBottom],
  );

  // Handle scroll after chat history changes
  useEffect(() => {
    if (chatHistory.length > 0) {
      requestAnimationFrame(scrollToBottom);
    }
  }, [chatHistory, scrollToBottom]);

  useEffect(() => {
    setTrapFocus(true);

    if (isOpen) {
      if (initialContent?.trim()) {
        // Initialize with the existing content as an assistant message
        setChatHistory([
          {
            type: 'assistant',
            content: initialContent,
            timestamp: new Date(),
            isHTML: true,
          },
        ]);
        setPrompt('');
      } else {
        // Add welcome message when conversation is empty
        setChatHistory([
          {
            type: 'assistant',
            content:
              "<p>Hello! I'm here to assist you in generating content for this section, using the section guidelines and your model attributes as a foundation.</p><p>To begin, you can:</p><ul><li>Click &quot;Generate content&quot; below</li><li>Enter a custom prompt</li></ul><p>Let's get started.</p>",
            timestamp: new Date(),
            isHTML: true,
          },
        ]);
        setPrompt(defaultPrompt);
      }
    }
  }, [isOpen, initialContent]);

  // Add one mutation observer to handle content changes
  useEffect(() => {
    if (!chatContainerRef.current) return;

    const observer = new MutationObserver(() => {
      requestAnimationFrame(scrollToBottom);
    });

    observer.observe(chatContainerRef.current, {
      childList: true,
      subtree: true,
      characterData: true,
    });

    return () => observer.disconnect();
  }, [scrollToBottom]);

  // Function to auto-adjust textarea height
  const adjustTextareaHeight = useCallback(() => {
    const textarea = textAreaRef.current;
    if (textarea) {
      // Reset height to auto to get the correct scrollHeight
      textarea.style.height = 'auto';
      // Set the height to scrollHeight + a small buffer
      textarea.style.height = `${Math.min(textarea.scrollHeight, 200)}px`;
      // Scroll to the bottom of the textarea
      textarea.scrollTop = textarea.scrollHeight;
    }
  }, []);

  // Adjust height when prompt changes
  useEffect(() => {
    adjustTextareaHeight();
  }, [prompt, adjustTextareaHeight]);

  // Update loading message whenever AI status changes to generating
  useEffect(() => {
    if (aiStatus === 'generating') {
      const randomIndex = Math.floor(Math.random() * loadingMessages.length);
      setLoadingMessage(loadingMessages[randomIndex]);
    } else if (aiStatus === 'idle' && textAreaRef.current) {
      // Focus the textarea when generation is complete
      textAreaRef.current.focus();
    }
  }, [aiStatus]);
  const autoPrompt = () => {
    const defaultPromptText = 'Please provide content for this section';
    setPrompt(defaultPromptText);

    // Create a direct user message instead of depending on the prompt state
    const userMessage: ChatMessage = {
      type: 'user',
      content: defaultPromptText,
      timestamp: new Date(),
    };

    const assistantMessage: ChatMessage = {
      type: 'assistant',
      content: '',
      timestamp: new Date(),
    };

    const newChatHistory = [...chatHistory, userMessage, assistantMessage];
    setChatHistory(newChatHistory);

    // Update AI config with the chat history
    aiGenerationConfig.options = {
      ...aiGenerationConfig.options,
      chatHistory: newChatHistory.slice(0, -1).map(msg => ({
        role: msg.type === 'user' ? 'user' : 'assistant',
        content: msg.content,
      })),
    };

    // Scroll to bottom after adding new messages
    requestAnimationFrame(scrollToBottom);

    // Trigger generation
    setTimeout(() => {
      aiEditorRef.current?.generate();
    }, 0);

    // Clear the prompt
    setPrompt('');
  };

  const handleGenerate = () => {
    if (prompt.length > 10000) {
      toast({
        variant: 'subtle',
        title: 'Error',
        description:
          'Please enter a prompt that is less than 10000 characters.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    if (prompt.trim()) {
      const userMessage: ChatMessage = {
        type: 'user',
        content: prompt,
        timestamp: new Date(),
      };

      const assistantMessage: ChatMessage = {
        type: 'assistant',
        content: '',
        timestamp: new Date(),
      };

      const newChatHistory = [...chatHistory, userMessage, assistantMessage];
      setChatHistory(newChatHistory);

      aiGenerationConfig.options = {
        ...aiGenerationConfig.options,
        chatHistory: newChatHistory.slice(0, -1).map(msg => ({
          role: msg.type === 'user' ? 'user' : 'assistant',
          content: msg.content,
        })),
      };
    }

    // Scroll after adding new messages
    requestAnimationFrame(scrollToBottom);

    setTimeout(() => {
      aiEditorRef.current?.generate();
    }, 0);
    setPrompt('');
  };
  let showAutoPrompt = chatHistory.length === 0 && !prompt.trim();
  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
      size={'2xl'}
      trapFocus={trapFocus}
    >
      <ModalOverlay />
      <ModalContent maxHeight="calc(100vh - 40px)" maxWidth="75vw">
        <ModalHeader
          display="flex"
          alignItems="center"
          gap={2}
          borderBottomWidth="1px"
          borderBottomColor={useColorModeValue('neutral.100', 'neutral.700')}
          py={4}
        >
          <Heading as={'h3'}>
            ValidMind AI Content Builder <sup className="beta">Beta</sup>
          </Heading>
        </ModalHeader>
        <ModalCloseButton />

        <ModalBody>
          <VStack align="stretch" gap={4} w="100%">
            <Text>
              Our content builder generates high-quality written content using
              state-of-the-art Generative AI.
            </Text>

            {/* Empty state when no chat history */}
            {chatHistory.length === 0 && aiStatus !== 'generating' && (
              <EmptyStateDisplay variant="wait-app-doc">
                <Text>Enter a prompt below to generate content</Text>
              </EmptyStateDisplay>
            )}

            {/* Chat History */}
            <Box
              ref={chatContainerRef}
              flex={1}
              w="100%"
              overflowY="auto"
              maxH="calc(80vh - 200px)"
              h="calc(80vh - 200px)"
              rounded={'md'}
              borderWidth={1}
              borderColor={'var(--chakra-colors-chakra-border-color)'}
              bg={useColorModeValue('white', 'black')}
              id="chat-scroll-container"
              position="relative"
              display={chatHistory.length > 0 ? 'flex' : 'none'}
              flexDirection="column"
              sx={{
                '&::-webkit-scrollbar': { width: '8px' },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: useColorModeValue(
                    'rgba(0,0,0,0.2)',
                    'rgba(255,255,255,0.2)',
                  ),
                  borderRadius: '4px',
                },
              }}
            >
              <VStack
                p={chatHistory.length > 0 ? 2 : 0}
                align="stretch"
                flex="1"
                w="100%"
                minH="100%"
                gap={0}
                px={6}
                py={6}
                mb={10}
              >
                {/* Initial generation editor when chat is empty and we're generating */}
                {chatHistory.length === 0 && aiStatus === 'generating' && (
                  <Box alignSelf="flex-start" maxW="80%" w="fit-content">
                    <Box
                      bg={useColorModeValue('brand.50', 'whiteAlpha.50')}
                      borderLeft="2px solid"
                      borderColor="brand.500"
                      borderRadius="xl"
                      borderBottomLeftRadius="lg"
                      position="relative"
                      boxShadow="sm"
                    >
                      <AITextContentEditor
                        ref={aiEditorRef}
                        prompt={''}
                        aiGenerationConfig={aiGenerationConfig}
                        onStatusChanged={setAiStatus}
                        onContentChanged={scrollToBottom}
                        onTextChanged={text => {
                          if (text) {
                            updateLastAssistantMessage(text);
                          }
                        }}
                      />
                    </Box>
                  </Box>
                )}

                {/* Existing chat messages */}
                {chatHistory.map((msg, idx) => (
                  <Box
                    key={idx}
                    alignSelf={msg.type === 'user' ? 'flex-end' : 'flex-start'}
                    maxW="80%"
                    w="fit-content"
                  >
                    <ScaleFade
                      initialScale={0.95}
                      in={true}
                      transition={{
                        enter: { duration: 0.2 },
                      }}
                    >
                      {msg.type === 'user' ? (
                        <HStack alignItems={'flex-end'}>
                          <Box
                            bg={useColorModeValue(
                              'neutral.50',
                              'brandSecondary.25',
                            )}
                            color={useColorModeValue(
                              'black',
                              'brandSecondary.base',
                            )}
                            px={4}
                            py={2}
                            borderRadius={12}
                            borderBottomRightRadius={4}
                            mb={4}
                            boxShadow={'md'}
                            data-testid="user-message"
                          >
                            <Text>{msg.content}</Text>
                          </Box>
                          <Box w={12} h={12} minW={12}>
                            <AvatarProxy
                              size="full"
                              name={currentUser?.name}
                              src={currentUser?.picture}
                            />
                          </Box>
                        </HStack>
                      ) : (
                        <HStack alignItems={'flex-end'} pb={8}>
                          <Box
                            w={16}
                            h={16}
                            px={4}
                            pt={4}
                            minW={16}
                            bg={useColorModeValue('white', 'neutral.1000')}
                            rounded={'full'}
                            className={
                              msg.content !== '' ? '' : 'fake-shader vivid'
                            }
                            transition={'all 0.5s ease-in-out'}
                            data-testid="assistant-message"
                          >
                            <Box
                              visibility={
                                msg.content !== '' ? 'visible' : 'hidden'
                              }
                            >
                              <ValidMindVLogo />
                            </Box>
                          </Box>
                          <Box
                            bg={useColorModeValue('white', 'neutral.1000')}
                            boxShadow="0 2px 8px rgba(0, 0, 0, 0.085)"
                            borderRadius={12}
                            borderBottomLeftRadius={4}
                            position="relative"
                            px={4}
                            py={2}
                            mb={4}
                          >
                            {/* Show loading indicator when message is empty */}
                            {msg.content === '' && (
                              <Flex align="center" gap={3} minH="24px">
                                <Text color={'neutral.500'} fontStyle="italic">
                                  {loadingMessage}
                                </Text>
                              </Flex>
                            )}

                            {msg.isHTML ? (
                              <Box
                                className="ck ck-content"
                                dangerouslySetInnerHTML={{
                                  __html: msg.content,
                                }}
                              />
                            ) : (
                              <AITextContentEditor
                                ref={
                                  idx === chatHistory.length - 1
                                    ? aiEditorRef
                                    : undefined
                                }
                                prompt={msg.content}
                                aiGenerationConfig={{
                                  ...aiGenerationConfig,
                                  options: {
                                    ...aiGenerationConfig.options,
                                    chatHistory: chatHistory
                                      .slice(0, idx + 1)
                                      .map(m => ({
                                        role:
                                          m.type === 'user'
                                            ? 'user'
                                            : 'assistant',
                                        content: m.content,
                                      })),
                                  },
                                }}
                                onStatusChanged={status => {
                                  if (idx === chatHistory.length - 1) {
                                    setAiStatus(status);
                                  }
                                }}
                                onContentChanged={scrollToBottom}
                                onTextChanged={text => {
                                  if (idx === chatHistory.length - 1) {
                                    updateLastAssistantMessage(text);
                                  }
                                }}
                              />
                            )}
                            {msg.content && (
                              <HStack
                                gap={2}
                                mt={4}
                                w={'full'}
                                justifyContent={'flex-end'}
                              >
                                {/* Only show Generate Content button for the initial welcome message */}
                                {!initialContent &&
                                  chatHistory.length === 1 &&
                                  idx === 0 && (
                                    <Button size={'sm'} onClick={autoPrompt}>
                                      Generate Content
                                    </Button>
                                  )}
                                {chatHistory.length > 1 && idx !== 0 && (
                                  <Button
                                    variant="ghost"
                                    size={'sm'}
                                    leftIcon={
                                      idx === chatHistory.length - 1 &&
                                      aiStatus === 'generating' ? (
                                        <TimeIcon />
                                      ) : (
                                        <DownloadIcon />
                                      )
                                    }
                                    onClick={() => {
                                      onAccept(msg.content);
                                      onClose();
                                    }}
                                    hidden={
                                      idx === chatHistory.length - 1 &&
                                      aiStatus === 'generating'
                                    }
                                  >
                                    {idx === chatHistory.length - 1 &&
                                      aiStatus === 'generating'}

                                    {idx === chatHistory.length - 1 &&
                                    aiStatus === 'generating'
                                      ? 'Generating...'
                                      : 'Insert to document'}
                                  </Button>
                                )}
                              </HStack>
                            )}
                          </Box>
                        </HStack>
                      )}
                    </ScaleFade>
                  </Box>
                ))}
              </VStack>
            </Box>
            <VStack align="stretch" w="100%" mb={showAutoPrompt ? 0 : 4}>
              {/* Text Input Area */}
              <Box
                position="relative"
                border="1px solid"
                rounded={'md'}
                borderColor={'var(--chakra-colors-chakra-border-color)'}
                bg={useColorModeValue('white', 'neutral.950')}
                _focusWithin={{
                  borderColor: 'brand.base',
                  boxShadow: '0 0 0 1px var(--chakra-colors-brand-base)',
                }}
                _hover={{
                  borderColor: 'brandSecondary.300',
                }}
              >
                <Textarea
                  ref={textAreaRef}
                  value={prompt}
                  onChange={e => {
                    setPrompt(e.target.value);
                  }}
                  placeholder="Enter your prompt here..."
                  size="md"
                  // resize="none"
                  minH="120px"
                  maxH="240px"
                  overflowY="auto"
                  border="none"
                  _focus={{ boxShadow: 'none' }}
                  disabled={aiStatus === 'generating'}
                  onKeyDown={e => {
                    if (e.key === 'Enter') {
                      if (!e.shiftKey && prompt.trim()) {
                        e.preventDefault();
                        handleGenerate();
                      } else if (e.shiftKey) {
                        // Allow default behavior for Shift+Enter (new line)
                        // and schedule a height adjustment for the next frame
                        requestAnimationFrame(adjustTextareaHeight);
                      }
                    }
                  }}
                  pb={20}
                  px={4}
                  pt={4}
                  sx={{
                    '&:focus, &:active': {
                      pb: '40px',
                    },
                  }}
                />

                {/* Controls footer area - integrated with text input */}
                <Flex
                  position="absolute"
                  bottom={0}
                  left={0}
                  right={0}
                  p={4}
                  justify="space-between"
                  alignItems={'flex-end'}
                  align="center"
                  borderBottomRadius="md"
                  zIndex={2}
                  pointerEvents="auto"
                  bg={useColorModeValue('white', 'neutral.950')}
                >
                  <Box>
                    {/* Guidelines Popover Component */}
                    <GuidelinesPopover
                      sectionContext={aiGenerationConfig.sectionContext}
                    />
                  </Box>
                  <HStack>
                    {/* Send button */}
                    <Button
                      variant="primary"
                      onClick={handleGenerate}
                      isDisabled={!prompt.trim() || aiStatus === 'generating'}
                      isLoading={aiStatus === 'generating'}
                      loadingText="Generating..."
                    >
                      Send
                    </Button>
                  </HStack>
                </Flex>
              </Box>
            </VStack>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default GenerateWithAIModal;
