import {
  FormControl,
  Heading,
  HStack,
  Input,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Text,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import _ from 'lodash';
import { WaitNodeType, WaitType, WaitUnit } from '../../types';
import { Label } from '../../../../../../components/Layout';
import useModelSchema from '../../../../../../hooks/useModelSchema';
import BasePanel from '../BasePanel';
import useWorkflow from '../../../../../../hooks/useWorkflow';
import { convertLocalToUTC, convertUTCToLocal } from '../../../../../../utils';

interface WaitPanelProps {
  node: WaitNodeType;
  onAddNode: (node: WaitNodeType) => void;
  onDeleteNode: (node: WaitNodeType) => void;
}

const WaitPanel = ({ node, onAddNode, onDeleteNode }: WaitPanelProps) => {
  const { setSelectedNodeId } = useWorkflow();
  const [tempNode, setTempNode] = useState<WaitNodeType>(node);
  const { propertyItems } = useModelSchema();

  useEffect(() => {
    setTempNode(node);
  }, [node]);

  const onWaitTypeChange = (value: WaitType) => {
    if (tempNode) {
      let clonedNode = _.cloneDeep(tempNode);
      clonedNode.data.state_callbacks.on_enter[0].args.wait_type = value;
      if (value === 'interval') {
        clonedNode.data.state_callbacks.on_enter[0].args.wait_unit = 'minutes';
        clonedNode.data.state_callbacks.on_enter[0].args.wait_amount = 1;
      }
      setTempNode(clonedNode);
    }
  };

  const onSave = () => {
    if (tempNode) {
      onAddNode(tempNode);
      setSelectedNodeId!();
    }
  };

  return (
    <BasePanel
      title={'Configure Wait'}
      node={tempNode}
      onSave={onSave}
      onDeleteNode={onDeleteNode}
    >
      <FormControl>
        <Label mb={2}>RESUME WORKFLOW:</Label>
        <RadioGroup
          onChange={value => onWaitTypeChange(value as WaitType)}
          value={
            tempNode?.data.state_callbacks.on_enter[0].args.wait_type || ''
          }
        >
          <Stack spacing={3}>
            <Radio
              size={'lg'}
              bg={'white'}
              colorScheme="brand"
              value={'interval' as WaitType}
            >
              <Stack gap={0}>
                <Heading as={'h5'}>After Time Interval</Heading>
                <Text fontSize="sm">Waits for a certain amount of time</Text>
              </Stack>
            </Radio>
            <Radio
              size={'lg'}
              colorScheme="brand"
              value={'specific_date' as WaitType}
            >
              <Heading as={'h5'}>At Specified Date</Heading>
              <Text fontSize="sm">Waits until a specific date to continue</Text>
            </Radio>
            <Radio
              size={'lg'}
              colorScheme="brand"
              value={'custom_field' as WaitType}
            >
              <Heading as={'h5'}>At Specified Date on Model Field</Heading>
              <Text fontSize="sm">
                Waits until a specific date on a model field to continue
              </Text>
            </Radio>
          </Stack>
        </RadioGroup>
      </FormControl>

      {/* Interval Configuration */}
      {tempNode?.data.state_callbacks.on_enter[0].args.wait_type ===
        'interval' && (
        <FormControl>
          <Label mb={2}>Wait Duration</Label>
          <HStack>
            <NumberInput
              size="sm"
              value={
                tempNode.data.state_callbacks.on_enter[0].args.wait_amount || 0
              }
              onChange={value => {
                const clonedNode = _.cloneDeep(tempNode);
                clonedNode.data.state_callbacks.on_enter[0].args.wait_amount =
                  parseInt(value);
                setTempNode(clonedNode);
              }}
            >
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
            <Select
              size="sm"
              value={
                tempNode.data.state_callbacks.on_enter[0].args.wait_unit ||
                'minutes'
              }
              onChange={e => {
                const clonedNode = _.cloneDeep(tempNode);
                clonedNode.data.state_callbacks.on_enter[0].args.wait_unit = e
                  .target.value as WaitUnit;
                setTempNode(clonedNode);
              }}
            >
              <option value={'minutes' as WaitUnit}>Minutes</option>
              <option value={'hours' as WaitUnit}>Hours</option>
              <option value={'days' as WaitUnit}>Days</option>
            </Select>
          </HStack>
        </FormControl>
      )}

      {/* Specific Date Configuration */}
      {tempNode?.data.state_callbacks.on_enter[0].args.wait_type ===
        'specific_date' && (
        <FormControl>
          <Label mb={2}>Wait Until</Label>
          {/* This input needs to display dates in local timezone but store them in UTC */}
          <Input
            size="sm"
            type="datetime-local"
            value={
              convertUTCToLocal(
                tempNode.data.state_callbacks.on_enter[0].args
                  .specific_datetime || '',
              ) || ''
            }
            onChange={e => {
              const localDateTime = e.target.value; // Get local datetime from input
              const utcDateTime = convertLocalToUTC(localDateTime) as string; // Convert to UTC
              const clonedNode = _.cloneDeep(tempNode);
              clonedNode.data.state_callbacks.on_enter[0].args.specific_datetime =
                utcDateTime;
              setTempNode(clonedNode);
            }}
          />
        </FormControl>
      )}

      {/* Custom Field Configuration */}
      {tempNode?.data.state_callbacks.on_enter[0].args.wait_type ===
        'custom_field' && (
        <FormControl>
          <Label mb={2}>MODEL FIELD</Label>
          <Select
            size="sm"
            value={
              tempNode.data.state_callbacks.on_enter[0].args.custom_field || ''
            }
            onChange={e => {
              const clonedNode = _.cloneDeep(tempNode);
              clonedNode.data.state_callbacks.on_enter[0].args.custom_field =
                e.target.value;
              setTempNode(clonedNode);
            }}
            placeholder="Select a field"
          >
            {propertyItems
              .filter(
                field =>
                  field.typeId === 'string:date' ||
                  field.typeId === 'string:date-time' ||
                  field.typeId === 'string:code-python',
              )
              .map(field => (
                <option key={field.key} value={field.key}>
                  {field.title}
                  {field.typeId === 'string:code-python' && ' (Calculated)'}
                </option>
              ))}
          </Select>
          <Text fontSize="sm" color="gray.500" mt={2}>
            When selecting a calculated field, it must return an ISO 8601 date.
          </Text>
        </FormControl>
      )}
    </BasePanel>
  );
};

export default WaitPanel;
