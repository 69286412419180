import React, { useCallback, useEffect, useState } from 'react';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Button,
  FormControl,
  Icon,
  SimpleGrid,
  Spacer,
  Stack,
  Text,
  Textarea,
  useToast,
} from '@chakra-ui/react';
import { useMutation, useQueryClient } from 'react-query';
import { ArrowRightIcon } from '@heroicons/react/24/outline';
import {
  Execution,
  ExecutionStatus,
  Workflow,
} from '../../../../models/workflow';
import { useModal } from '../../contexts/modal/ModalContext';
import {
  useNavigation,
  WorkflowManagerNavigationState,
} from '../../contexts/navigation/NavigationContext';
import { useUserAction } from '../../contexts/userAction/UserActionContext';
import { useWorkflow } from '../../contexts/workflow/WorkflowContext';
import { Label } from '../../../Layout';
import useModelSchema from '../../../../hooks/useModelSchema';
import useFields from '../../../../hooks/useFields';
import {
  convertRJSFToCustomFieldJSON,
  getSchemaPropertyByKey,
  pickProperties,
} from '../../../../utils';
import CustomFieldRenderer from '../../../NewCustomFields/CustomFieldRenderer';
import { ActivityFeedWidgetQueryKey } from '../../../ActivityFeedWidget';
import API from '../../../../api/API';

interface WorkflowUserActionProps {
  target: any;
  workflow: Workflow;
  execution: Execution;
  fromStandalone?: boolean;
}

function WorkflowUserAction({
  target,
  workflow,
  execution,
  fromStandalone = false,
}: WorkflowUserActionProps) {
  const { setFooterContent, onClose } = useModal();
  const { navigate, params } = useNavigation();
  const { currentAction } = useUserAction();
  const { loadWorkflowExecution } = useWorkflow();
  const queryClient = useQueryClient();
  const toast = useToast();

  // Check if this was opened from standalone or has fromStandalone in params
  const shouldCloseAfterAction = fromStandalone || !!params.fromStandalone;

  const [notes, setNotes] = useState('');
  const { data: schemaData } = useModelSchema();
  const customFields = target.custom_fields;

  // Initialize fields state
  const { fieldValues, fieldErrors, setFieldValue, setFieldError } = useFields(
    pickProperties(customFields, currentAction?.required_fields || []),
  );

  // Field refs for validation
  const fieldRefs: Record<string, any> = {};

  // Get required custom fields
  const requiredCustomFields = React.useMemo(() => {
    if (!schemaData || !currentAction) return [];

    const requiredSchemaKeys = currentAction.required_fields;
    return requiredSchemaKeys.map(key => {
      const [schemaProperty, settingsProperty] = getSchemaPropertyByKey(
        schemaData,
        key,
      );
      return convertRJSFToCustomFieldJSON(
        key,
        schemaProperty,
        settingsProperty,
      );
    });
  }, [schemaData, currentAction]);

  // Submit action mutation
  const { mutate: submitAction, isLoading } = useMutation(
    async () => {
      if (!currentAction) throw new Error('No action selected');
      return API.PostWorkflowExecutionUserActions(
        workflow.cuid,
        execution.cuid,
        currentAction,
        fieldValues,
        notes,
      );
    },
    {
      onSuccess: async updatedExecution => {
        toast({
          variant: 'subtle',
          title: workflow.title,
          description: 'Action Completed Successfully',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });

        // First update the workflow context
        await loadWorkflowExecution(workflow.cuid, updatedExecution.cuid);

        // Then invalidate queries for other components
        queryClient.invalidateQueries(['targets', target.cuid, 'executions']);
        queryClient.invalidateQueries(['inventory-model', target.cuid]);
        queryClient.invalidateQueries([ActivityFeedWidgetQueryKey]);

        if (updatedExecution.status === ExecutionStatus.FINISHED) {
          queryClient.invalidateQueries([
            'targets',
            target.cuid,
            'workflows',
            'available',
          ]);
        }

        if (updatedExecution.pending_approval) {
          queryClient.invalidateQueries(['approvals', 'voters']);
        }

        // Close modal if from standalone
        if (shouldCloseAfterAction) {
          onClose();
          // but it's not working, please fix this ^
          navigate(WorkflowManagerNavigationState.DETAIL, {
            execution: updatedExecution,
          });
        } else {
          // Otherwise navigate back to detail view
          navigate(WorkflowManagerNavigationState.DETAIL, {
            execution: updatedExecution,
          });
        }
      },
      onError: error => {
        toast({
          variant: 'subtle',
          title: 'Error submitting action',
          description: API.getAPIErrorMessage(error),
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      },
    },
  );

  const handleSubmit = useCallback(() => {
    submitAction();
  }, [submitAction]);

  const handleCancel = useCallback(() => {
    if (shouldCloseAfterAction) {
      onClose();
      // but it's not working, please fix this ^ as well.
      navigate(WorkflowManagerNavigationState.DETAIL);
    } else {
      navigate(WorkflowManagerNavigationState.DETAIL);
    }
  }, [navigate, onClose, shouldCloseAfterAction]);

  // Update footer content
  useEffect(() => {
    setFooterContent(
      <>
        <Button onClick={handleCancel} variant="ghost">
          Cancel
        </Button>
        <Spacer />
        <Button
          onClick={handleSubmit}
          isLoading={isLoading}
          leftIcon={<Icon as={ArrowRightIcon} boxSize={4} />}
          isDisabled={isLoading}
          variant={'primary'}
        >
          {currentAction?.buttonLabel || 'Submit'}
        </Button>
      </>,
    );

    return () => setFooterContent(null);
  }, [handleCancel, handleSubmit, isLoading, currentAction]);

  if (!currentAction) {
    return (
      <Alert status="error">
        <AlertIcon />
        <AlertDescription>No action selected</AlertDescription>
      </Alert>
    );
  }

  return (
    <Stack gap={8}>
      {requiredCustomFields.length > 0 && (
        <Box>
          <SimpleGrid columns={1} spacing={8}>
            {requiredCustomFields.map(customField => {
              const fieldValue = fieldValues[customField.key];
              const fieldError = fieldErrors[customField.key];
              return (
                <Box key={customField.key}>
                  <Label mb={2}>{customField.label}</Label>
                  <CustomFieldRenderer
                    ref={ref => {
                      fieldRefs[customField.key] = ref;
                    }}
                    schema={customField}
                    value={fieldValue}
                    onChange={value => setFieldValue(customField.key, value)}
                    mode="edit"
                    onError={errors => setFieldError(customField.key, errors)}
                  />
                  {fieldError && fieldError.length > 0 && (
                    <Text fontSize="small" color="red.500">
                      {fieldError[0]}
                    </Text>
                  )}
                </Box>
              );
            })}
          </SimpleGrid>
        </Box>
      )}

      <Box>
        <FormControl>
          <Label mb={2}>Notes</Label>
          <Textarea
            placeholder="Add details about your action here..."
            value={notes}
            onChange={event => setNotes(event.target.value)}
            rows={6}
          />
        </FormControl>
      </Box>
    </Stack>
  );
}

export default WorkflowUserAction;
