import { useContext, useEffect, useMemo } from 'react';
import {
  Button,
  Heading,
  HStack,
  Icon,
  IconButton,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Tooltip,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react';
import Breadcrumbs from '../../../components/Breadcrumbs';
import { ContentPageTitle } from '../../../components/Layout';
import SidebarContext from '../../../contexts/SidebarContext';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { LoadingContainer } from '../../../components/LoadingContainer';
import API from '../../../api/API';
import PermissionsList from '../../../components/PermissionsList';
import EditPermissionsModal from '../../../components/EditPermissionsModal';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import useOrganizationUsersWithRoles from '../../../hooks/useOrganizationUsersWithRoles';
import UserRoleTable from '../../../components/UserRoleTable';
import { AddIcon } from '@chakra-ui/icons';
import CustomFieldForm from '../../../components/CustomFields/CustomField/CustomFieldForm';
import { RolePatchRequest } from '../../../models/role';
import {
  createStringCustomField,
  createStringMultilineCustomField,
} from '../../../utils';

const LOCKED_STAKEHOLDER_ROLES = [
  'Model Developer',
  'Model Owner',
  'Model Validator',
];

export default function RoleDetails() {
  const { organizationUsersWithRoles } = useOrganizationUsersWithRoles();
  const queryClient = useQueryClient();
  const toast = useToast();
  const { setInSettings } = useContext(SidebarContext);
  const { roleCUID } = useParams();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();
  const location = useLocation();
  const isStakeholderRole = location.pathname.includes(
    '/settings/stakeholders/',
  );

  // Update the breadcrumb navigation based on the path
  const breadcrumbBase = isStakeholderRole
    ? '/settings/stakeholders'
    : '/settings/roles';
  const breadcrumbLabel = isStakeholderRole ? 'Stakeholders' : 'Roles';

  useEffect(() => {
    setInSettings(true);
    return () => {
      setInSettings(false);
    };
  }, []);

  const {
    isLoading,
    data: roleDetails,
    refetch,
  } = useQuery(
    ['roles', roleCUID, 'details'],
    async () => {
      return await API.GetRolePermissions(roleCUID!);
    },
    {
      enabled: roleCUID !== null,
    },
  );

  const filteredUsers = useMemo(() => {
    return organizationUsersWithRoles.filter(user => {
      return user.roles.some(role => role.role.cuid === roleCUID);
    });
  }, [organizationUsersWithRoles, roleCUID]);

  const filteredCategories = useMemo(() => {
    if (!roleDetails) return undefined;
    const newCategories = {} as any;
    let count = 0;
    for (const categoryKey in roleDetails?.categories) {
      const category = roleDetails?.categories[categoryKey];
      const filteredActions = category.filter(action =>
        isStakeholderRole
          ? action.scope === 'Model' && action.assigned
          : action.assigned,
      );
      count += filteredActions.length;
      if (filteredActions.length > 0) {
        newCategories[categoryKey] = filteredActions;
      }
    }
    return { categories: newCategories, permissionsCount: count };
  }, [roleDetails, isStakeholderRole]);

  const patchOrganizationRole = useMutation(
    async (data: RolePatchRequest) => {
      return await API.PatchOrganizationRole(data);
    },
    {
      onSuccess: () => {
        refetch();
        toast({
          variant: 'subtle',
          title: 'Role updated successfully',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      },
    },
  );

  const onEditName = (data: any) => {
    const name = data.name;
    patchOrganizationRole.mutate({
      cuid: roleCUID!,
      name,
    });
  };

  const onEditDescription = (data: any) => {
    const description = data.description;
    patchOrganizationRole.mutate({
      cuid: roleCUID!,
      description,
    });
  };

  const readOnly =
    roleDetails?.role.is_admin ||
    roleDetails?.role.is_staff ||
    (isStakeholderRole &&
      LOCKED_STAKEHOLDER_ROLES.includes(roleDetails?.role.name || ''));

  return (
    <LoadingContainer isLoading={isLoading}>
      <EditPermissionsModal
        onClose={onClose}
        roleDetails={{
          ...roleDetails!,
          categories:
            isStakeholderRole && roleDetails
              ? Object.fromEntries(
                  Object.entries(roleDetails.categories).map(
                    ([key, actions]) => [
                      key,
                      actions.filter(action => action.scope === 'Model'),
                    ],
                  ),
                )
              : roleDetails?.categories || {},
        }}
        isOpen={isOpen}
        onSuccess={() => {
          refetch();
          toast({
            title: 'Permissions updated successfully',
            status: 'success',
            duration: 3000,
            isClosable: true,
          });
          onClose();
        }}
      />
      <VStack
        alignItems="start"
        spacing={8}
        paddingTop={12}
        mt={1}
        paddingBottom={16}
        px={14}
        gap={4}
        w="full"
        overflow="auto"
        className="no-scrollbar"
        maxWidth={'7xl'}
        mx={'auto'}
      >
        <VStack w={'full'} alignItems={'start'}>
          <Breadcrumbs
            overrideCrumbs={[
              { label: 'Settings', path: '/settings' },
              { label: breadcrumbLabel, path: breadcrumbBase },
              { label: roleDetails?.role.name || '', path: '' },
            ]}
          />

          {roleDetails && (
            <CustomFieldForm
              customField={createStringCustomField(
                'name',
                'Role Name',
                roleDetails.role.name,
              )}
              onEdit={onEditName}
              onCancel={data => {}}
              displayComponent={field => (
                <ContentPageTitle>{field.value}</ContentPageTitle>
              )}
              hideLabel={true}
              isLoading={patchOrganizationRole.isLoading}
              readOnly={readOnly}
            />
          )}
        </VStack>
        <VStack alignItems="start" w={'full'}>
          {roleDetails && (
            <CustomFieldForm
              customField={createStringMultilineCustomField(
                'description',
                'Role Description',
                roleDetails.role.description,
              )}
              onEdit={onEditDescription}
              onCancel={data => {}}
              displayComponent={field => <Text>{field.value}</Text>}
              hideLabel={true}
              isLoading={patchOrganizationRole.isLoading}
              readOnly={readOnly}
            />
          )}
        </VStack>
        <Tabs colorScheme="brand" w="full">
          <TabList>
            <Tab>Permissions</Tab>
            {!isStakeholderRole && <Tab>Users</Tab>}
          </TabList>
          <TabPanels>
            <TabPanel padding={0} w="full">
              <VStack w="full" alignItems="flex-start" mt={8} spacing={4}>
                <HStack justifyContent="space-between" w="full">
                  <Heading as={'h3'}>Assigned Permissions</Heading>
                  <Tooltip
                    isDisabled={!readOnly}
                    hasArrow
                    label={
                      isStakeholderRole
                        ? 'You cannot edit permissions for this stakeholder role.'
                        : 'You cannot edit permissions for the Customer Admin role.'
                    }
                    shouldWrapChildren
                    mt="3"
                  >
                    <Button isDisabled={readOnly} onClick={onOpen}>
                      Edit Permissions
                    </Button>
                  </Tooltip>
                </HStack>
                {filteredCategories?.categories &&
                filteredCategories?.permissionsCount ? (
                  <PermissionsList
                    mode="view"
                    categories={filteredCategories.categories}
                    permissionsScope={roleDetails ? roleDetails.role.scope : ''}
                  />
                ) : (
                  <Text fontStyle={'italic'}>
                    This role does not have any permissions yet.
                    <br />
                    Click "Edit Permissions" to select permissions to be added
                    to this role.
                  </Text>
                )}
              </VStack>
            </TabPanel>
            {!isStakeholderRole && (
              <TabPanel padding={0} w="full">
                <VStack w="full" alignItems="flex-start" mt={8} spacing={4}>
                  <Heading as={'h3'}>
                    Users with "{roleDetails?.role.name}" role
                  </Heading>
                  {filteredUsers && filteredUsers.length > 0 ? (
                    <UserRoleTable
                      users={filteredUsers}
                      enableRoleManagement={true}
                      onRoleAdded={() =>
                        queryClient.invalidateQueries(
                          'organization-users-with-roles',
                        )
                      }
                      onRoleDeleted={() =>
                        queryClient.invalidateQueries(
                          'organization-users-with-roles',
                        )
                      }
                    />
                  ) : (
                    <>
                      <Text fontStyle={'italic'}>
                        No users have this role assigned.
                        <br />
                        To add this role to a user, find the user in the{' '}
                        <Button
                          variant={'link'}
                          colorScheme="brand"
                          onClick={() => navigate('/settings/user-directory')}
                        >
                          User Directory
                        </Button>{' '}
                        and click the{' '}
                        <IconButton
                          aria-label={'add-role'}
                          data-testid={'add-role-plus-button'}
                          rounded={'full'}
                          size={'sm'}
                          onClick={() => {
                            null;
                          }}
                          icon={<Icon as={AddIcon} color={'neutral.500'} />}
                        />{' '}
                        button to select the role you want to add.
                      </Text>
                    </>
                  )}
                </VStack>
              </TabPanel>
            )}
          </TabPanels>
        </Tabs>
      </VStack>
    </LoadingContainer>
  );
}
