import React, { useState } from 'react';
import {
  Execution,
  Workflow,
  WorkflowUserActionForm,
} from '../../../../models/workflow';
import { WorkflowManagerNavigationState } from '../../contexts/navigation/NavigationContext';
import WorkflowActionButtons from '../WorkflowActionButtons';
import WorkflowManagerModal from '../WorkflowManagerModal';
import { WorkflowManagerProvider } from '../../providers/WorkflowManagerProvider';
import { ModalProvider } from '../../contexts/modal/ModalContext';

// Define the modal state type
interface ModalState {
  isOpen: boolean;
  executionCuid?: string;
  workflowCuid?: string;
  navigation?: WorkflowManagerNavigationState;
  initialDetailsTab?: string;
  userAction?: WorkflowUserActionForm;
}

interface StandaloneWorkflowActionsProps {
  target: any;
  workflow: Workflow;
  execution?: Execution;
}

function StandaloneWorkflowActions({
  target,
  workflow,
  execution,
}: StandaloneWorkflowActionsProps) {
  const [modalState, setModalState] = useState<ModalState>({
    isOpen: false,
  });

  // Close handler - reset the modal state
  const handleModalClose = () => {
    setModalState(prev => ({ ...prev, isOpen: false }));
  };

  // Action handler that will update modal state
  const handleAction = (actionState: Omit<ModalState, 'isOpen'>) => {
    setModalState({
      ...actionState,
      isOpen: true,
    });
  };

  return (
    <>
      {/* Action buttons */}
      <WorkflowManagerProvider target={target}>
        <ModalProvider>
          <WorkflowActionButtons
            target={target}
            workflow={workflow}
            execution={execution}
            onAction={handleAction}
          />
        </ModalProvider>
      </WorkflowManagerProvider>

      {/* Modal */}
      <WorkflowManagerModal
        target={target}
        executionCuid={modalState.executionCuid}
        workflowCuid={modalState.workflowCuid || workflow.cuid}
        navigation={modalState.navigation}
        initialDetailsTab={modalState.initialDetailsTab}
        userAction={modalState.userAction}
        isOpen={modalState.isOpen}
        onClose={handleModalClose}
      />
    </>
  );
}

export default StandaloneWorkflowActions;
