import {
  FormControl,
  Select,
  useDisclosure,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  HStack,
  SliderMark,
  Tooltip,
  useColorModeValue,
  Icon,
} from '@chakra-ui/react';
import { Edge, Node } from 'reactflow';
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import useWorkflow from '../../../../../../hooks/useWorkflow';
import { StatusSetNodeType } from '../../types';
import { Label } from '../../../../../../components/Layout';
import { useQuery } from 'react-query';
import API from '../../../../../../api/API';
import AddWorkflowStateModal from '../../../../../../components/AddWorkflowStateModal';
import { State } from '../../../../../../models/workflow_state';
import BasePanel from '../BasePanel';
import { DragHandleIcon } from '@chakra-ui/icons';

interface AddStateSetPanelProps {
  node: StatusSetNodeType;
  onAddNode: (node: StatusSetNodeType) => void;
  onDeleteNode: (node: StatusSetNodeType) => void;
  onAddEdge?: (edge: Edge) => void;
}

const AddStateSetNodePanel = ({
  onAddNode,
  onDeleteNode,
  node,
}: AddStateSetPanelProps) => {
  const { workflow } = useWorkflow();
  const [tempNode, setTempNode] = useState<Node>();
  const newStateModal = useDisclosure();

  const [showTooltip, setShowTooltip] = React.useState(false);

  const {
    data: states,
    isLoading,
    refetch,
  } = useQuery(['workflows', workflow?.cuid, 'states'], async () => {
    return await API.GetWorkflowStates(workflow!.cuid);
  });

  useEffect(() => {
    setTempNode(node);
  }, [node]);

  const onSave = () => {
    if (tempNode) {
      onAddNode(tempNode);
    }
  };

  const onStateChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value === '+') {
      newStateModal.onOpen();
      return;
    }

    if (tempNode && states && e.target.value) {
      let clonedNode = _.cloneDeep(tempNode);
      const state = states.find(s => s.cuid === e.target.value)!;
      clonedNode.data.state_callbacks.on_enter[0].args.cuid = state.cuid;
      clonedNode.data.state_callbacks.on_enter[0].args._name = state.name;
      setTempNode(clonedNode);
    }
  };

  const onSetProgressChange = (value: number) => {
    if (tempNode) {
      let clonedNode = _.cloneDeep(tempNode);
      clonedNode.data.state_callbacks.on_enter[1].args.progress = value;
      setTempNode(clonedNode);
    }
  };

  return (
    <>
      <BasePanel
        title={'Configure Workflow State Change'}
        node={tempNode}
        onDeleteNode={onDeleteNode}
        onSave={onSave}
      >
        <FormControl>
          <Label mb={2}>Set worklflow state to</Label>
          <Select
            value={tempNode?.data.state_callbacks.on_enter[0].args.cuid || ''}
            onChange={onStateChange}
            size="sm"
            rounded={'md'}
            focusBorderColor="brand.base"
          >
            <option value="">{isLoading ? 'Loading...' : 'Select'}</option>
            {states?.map(s => (
              <option key={s.cuid} value={s.cuid}>
                {s.name}
              </option>
            ))}
            <option value="+">+ Add New State</option>
          </Select>
        </FormControl>

        <FormControl>
          <Label mb={2}>Progress</Label>
          <HStack gap={4} pb={8} px={4}>
            <Slider
              flex="1"
              min={0}
              max={100}
              value={
                tempNode?.data.state_callbacks.on_enter[1].args.progress || 0
              }
              size="lg"
              colorScheme="green"
              onChange={onSetProgressChange}
              onMouseEnter={() => setShowTooltip(true)}
              onMouseLeave={() => setShowTooltip(false)}
              step={5}
            >
              <SliderTrack rounded={'full'} h={2}>
                <SliderFilledTrack />
              </SliderTrack>
              {[0, 10, 20, 30, 40, 50, 60, 70, 80, 90].map(val => (
                <SliderMark
                  key={val}
                  value={val}
                  mt={6}
                  fontSize="xs"
                  color="neutral.500"
                >
                  {val}%
                </SliderMark>
              ))}
              <Tooltip
                hasArrow
                bg="teal.500"
                color="white"
                placement="top"
                isOpen={showTooltip}
                label={`${
                  tempNode?.data.state_callbacks.on_enter[1].args.progress || 0
                }%`}
              >
                <SliderThumb
                  boxSize={8}
                  border={'1px solid'}
                  borderColor={useColorModeValue(
                    'brandSecondary.base',
                    'neutral.700',
                  )}
                  bg={useColorModeValue('white', 'black')}
                  _hover={{ bg: 'brandSecondary.25' }}
                  rounded={'md'}
                >
                  <Icon as={DragHandleIcon} boxSize={4} color="neutral.500" />
                </SliderThumb>
              </Tooltip>
            </Slider>
          </HStack>
        </FormControl>
      </BasePanel>
      <AddWorkflowStateModal
        isOpen={newStateModal.isOpen}
        workflow={workflow!}
        onClose={(state?: State) => {
          newStateModal.onClose();
          if (state && tempNode) {
            refetch();
            let clonedNode = _.cloneDeep(tempNode);
            clonedNode.data.state_callbacks.on_enter[0].args.cuid = state.cuid;
            clonedNode.data.state_callbacks.on_enter[0].args._name = state.name;
            setTempNode(clonedNode);
          }
        }}
      />
    </>
  );
};

export default AddStateSetNodePanel;
