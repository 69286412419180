import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  VStack,
  FormControl,
  Input,
  Textarea,
  ModalFooter,
  Button,
  Spacer,
} from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import { Label } from '../../../../components/Layout';

export type AddAttestationModalOnSaveProps = {
  name: string;
  description: string;
};

export type AddAttestationModalOnSaveResponse = {
  success: boolean;
  error?: string;
};

type AddAttestationModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onSave: ({
    name,
    description,
  }: AddAttestationModalOnSaveProps) => Promise<AddAttestationModalOnSaveResponse>;
};

export const AddAttestationModal = ({
  isOpen,
  onClose,
  onSave,
}: AddAttestationModalProps) => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit = async () => {
    setIsSubmitting(true);
    await onSave({ name, description });
    setIsSubmitting(false);
    onClose();
  };

  useEffect(() => {
    if (isOpen) {
      setName('');
      setDescription('');
    }
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      closeOnEsc
      closeOnOverlayClick
      scrollBehavior={'inside'}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add New Attestation</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack gap={4}>
            <FormControl>
              <Label mb={2}>Name</Label>
              <Input
                value={name}
                onChange={e => setName(e.target.value)}
                placeholder=""
              />
            </FormControl>
            <FormControl>
              <Label mb={2}>Description</Label>
              <Textarea
                value={description}
                onChange={e => setDescription(e.target.value)}
              ></Textarea>
            </FormControl>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button variant="ghost" onClick={onClose}>
            Cancel
          </Button>
          <Spacer />
          <Button
            isDisabled={!name || !description}
            isLoading={isSubmitting}
            onClick={onSubmit}
            variant={'primary'}
          >
            Add Attestation
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
