import { FormControl, Select, Stack } from '@chakra-ui/react';
import { Edge, Node, useStoreApi } from 'reactflow';
import { useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import { QueryBuilder, RuleGroupType } from 'react-querybuilder';
import { formatQuery } from 'react-querybuilder/formatQuery';
import { parseJsonLogic } from 'react-querybuilder/parseJsonLogic';
import useQueryBuilder from '../../../hooks/useQueryBuilder';
import { useStatusesWorkflows } from '../../../hooks/useStatuses';
import { WorkflowCategoryNames } from '../../../../../../models/statuses_workflow';
import useWorkflow from '../../../../../../hooks/useWorkflow';
import { StatusSetNodeType } from '../../types';
import { Label } from '../../../../../../components/Layout';
import { controlElements } from '../BranchPanel/BranchItem';
import BasePanel from '../BasePanel';

interface AddStatusSetPanelProps {
  node: StatusSetNodeType;
  onAddNode: (node: StatusSetNodeType) => void;
  onDeleteNode: (node: StatusSetNodeType) => void;
  onAddEdge?: (edge: Edge) => void;
}

const AddStatusSetNodePanel = ({
  onAddNode,
  onDeleteNode,
  node,
}: AddStatusSetPanelProps) => {
  const { workflow, setSelectedNodeId } = useWorkflow();
  const storeApi = useStoreApi();
  const [tempNode, setTempNode] = useState<Node>();
  const { query, setQuery, fields } = useQueryBuilder();
  const { data: statusesWorkflows } = useStatusesWorkflows();

  useEffect(() => {
    setTempNode(node);

    if (node && node.data.transition_callbacks.conditions.length > 0) {
      setQuery(
        parseJsonLogic(
          JSON.parse(node.data.transition_callbacks.conditions[0].args.query),
        ) as RuleGroupType,
      );
    }
  }, [node]);

  const onSave = () => {
    if (tempNode) {
      const clonedNode = _.cloneDeep(tempNode);
      clonedNode.data.transition_callbacks.conditions = [
        {
          func: 'jsonlogic',
          args: {
            query: JSON.stringify(formatQuery(query, 'jsonlogic')),
          },
        },
      ];
      onAddNode(clonedNode);
    }
  };

  const statusByCategoryInWorkflow = useMemo(() => {
    return statusesWorkflows?.filter(category =>
      category.categories[0].startsWith(
        workflow?.trigger_id.startsWith('Project') ? 'projects' : 'models',
      ),
    );
  }, [statusesWorkflows]);

  const onStatusChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    // const selected = statuses?.statuses.find(s => s.cuid === e.target.value);
    const flattened = statusesWorkflows?.flatMap(item =>
      item.statuses.map(subItem => ({
        ...subItem,
        entity: item.categories[0].startsWith('projects')
          ? 'Project'
          : 'InventoryModel',
      })),
    );
    const selected = flattened?.find(s => s.cuid === e.target.value);
    if (tempNode && selected) {
      let clonedNode = _.cloneDeep(tempNode);
      clonedNode.data.state_callbacks.on_enter[0].args.entity = selected.entity;
      clonedNode.data.state_callbacks.on_enter[0].args.cuid = selected.cuid;
      setTempNode(clonedNode);
    }
  };

  return (
    <BasePanel
      title={`Configure Model Stage Change`}
      node={tempNode}
      onDeleteNode={onDeleteNode}
      onSave={onSave}
    >
      <FormControl>
        <Label mb={2}>When these conditions are met</Label>

        <Stack>
          <QueryBuilder
            fields={fields}
            query={query}
            onQueryChange={setQuery}
            controlElements={controlElements}
          />
        </Stack>
      </FormControl>
      <FormControl>
        <Label mb={2}>Set model stage to</Label>
        <Select
          value={tempNode?.data.state_callbacks.on_enter[0].args.cuid || ''}
          onChange={onStatusChange}
          size="sm"
          rounded={'md'}
          focusBorderColor="brand.base"
        >
          <option value="">Select</option>

          {statusByCategoryInWorkflow?.map(category => (
            <optgroup
              label={
                WorkflowCategoryNames[category.categories[0] as keyof object]
              }
            >
              {category.statuses
                .filter(status => status.name !== 'Deleted')
                .map(status => (
                  <option value={status.cuid}>{status.name}</option>
                ))}
            </optgroup>
          ))}
        </Select>
      </FormControl>
    </BasePanel>
  );
};

export default AddStatusSetNodePanel;
