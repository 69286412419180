import { Tag, TagLeftIcon } from '@chakra-ui/react';
import { TAttestationStatuses } from '../../../models/attestation';
import _ from 'lodash';
import { ClipboardDocumentCheckIcon } from '@heroicons/react/24/outline';

type Props = {
  attestationStatus: TAttestationStatuses;
};

const AttestationStatusColorMap = {
  NOT_STARTED: 'blue',
  IN_PROGRESS: 'yellow',
  READY_FOR_REVIEW: 'green',
  REVIEWED: 'purple',
  SIGNED_OFF: 'teal',
};

export default function AttestationStatus({ attestationStatus }: Props) {
  return (
    <Tag
      colorScheme={AttestationStatusColorMap[attestationStatus]}
      variant={'outline'}
    >
      <TagLeftIcon as={ClipboardDocumentCheckIcon} boxSize={4} />

      {_.startCase(attestationStatus.toLowerCase())}
    </Tag>
  );
}
