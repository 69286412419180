import React from 'react';
import {
  HStack,
  Heading,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  VStack,
  useColorModeValue,
} from '@chakra-ui/react';
import { EllipsisVerticalIcon } from '@heroicons/react/24/outline';

export const GridViewItem = React.forwardRef(
  (
    {
      style,
      className,
      onMouseDown,
      onMouseUp,
      onTouchEnd,
      children,
      menuItems,
      title,
      isDraggable,
    }: any,
    ref: any,
  ) => {
    return (
      <VStack
        gap={0}
        ref={ref}
        w={'full'}
        h={'full'}
        rounded={'md'}
        borderWidth={1}
        alignItems="start"
        overflow={'hidden'}
        style={{ ...style }}
        className={className}
        bg={useColorModeValue('neutral.25', 'brandSecondary.1000')}
        role="group"
      >
        <HStack
          py={!!menuItems ? 3 : 4}
          pl={6}
          pr={2}
          gap={0}
          w="full"
          role="group"
          minHeight={14}
          alignItems="center"
          borderBottom={'1px solid'}
          justifyContent={'space-between'}
          borderBottomColor={'transparent'}
          transition={'all 0.2s ease-in-out'}
          display={title ? 'flex' : 'none'}
          _groupHover={{
            display: 'flex',
            bg: isDraggable
              ? useColorModeValue('neutral.100', 'neutral.950')
              : '',
            cursor: isDraggable ? 'grab' : 'auto',
          }}
          onMouseUp={onMouseUp}
          onTouchEnd={onTouchEnd}
          onMouseDown={onMouseDown}
        >
          <Heading as="h3">{title}</Heading>
          {!!menuItems && (
            <Menu>
              <MenuButton
                onMouseDown={e => e.stopPropagation()}
                onMouseUp={e => e.stopPropagation()}
                as={IconButton}
                aria-label="Options"
                icon={<Icon as={EllipsisVerticalIcon} boxSize={6} />}
                variant="ghost"
                visibility={'hidden'}
                _groupHover={{ visibility: 'visible' }}
                size="sm"
              />
              <MenuList
                onMouseDown={e => e.stopPropagation()}
                onMouseUp={e => e.stopPropagation()}
              >
                {menuItems}
              </MenuList>
            </Menu>
          )}
        </HStack>
        <VStack
          overflow="hidden"
          justifyContent="flex-start"
          width="full"
          height="full"
          px={6}
          pb={4}
          pt={2}
        >
          {children}
        </VStack>
      </VStack>
    );
  },
);
