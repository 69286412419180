import { useFlags as LDUseFlags } from 'launchdarkly-react-client-sdk';
import { CONFIG } from '../../config';

export interface AuthConfig {
  auth_create_user_enabled: boolean;
}

// TODO: Implement feature flags on our backend
//
// const APIFlags = () => {
//   const [flags, setFlags] = useState<TFeatureFlag>({});

//   const flagNames = [
//     {
//       ...
//     },
//   ];

//   const queryResponse = useQuery(
//     ['flags'],
//     async () => {
//       return await API.GetFeatureFlags(flagNames);
//     },
//     {
//       onSuccess: (flags_: TFeatureFlag) => {
//         setFlags(flags_);
//       },
//     },
//   );

//   return flags;
// };

// const useFlags = CONFIG.USE_LAUNCHDARKLY ? LDUseFlags : APIFlags;

export const OfflineFlags = {
  attestationsUi: false,
  authConfig: {
    auth_create_user_enabled: false,
  },
  globalMaxUploadSize: 50000000,
  validcheckRecommendationsRegulation: 'sr-11-7',
  workflowsV3: false,
};

const useFlags = CONFIG.USE_LAUNCHDARKLY ? LDUseFlags : () => OfflineFlags;

export { useFlags };
