import { createContext } from 'react';
import { UseMutationResult } from 'react-query';
import { SeekMutationVariables } from '../components/WorkflowExecutionsViewer';
import { Execution } from '../models/workflow';

type WorkflowExecutionContext = {
  execution?: Execution;
  seek?: UseMutationResult<Execution, unknown, SeekMutationVariables, unknown>;
  resumeWait?: UseMutationResult<Execution, unknown, any, unknown>;
};

const WorkflowExecutionContext = createContext<WorkflowExecutionContext>({});

export default WorkflowExecutionContext;
