import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  ModalFooter,
  Button,
  HStack,
  Tag,
  TagLabel,
  VStack,
  Textarea,
  Alert,
  AlertIcon,
  FormControl,
} from '@chakra-ui/react';
import {
  TAttestationExecution,
  TAttestationStatuses,
} from '../../../models/attestation';
import AttestationStatus from '../AttestationStatus';
import AvatarProxy from '../../AvatarProxy';
import { useCallback, useState } from 'react';
import { Label } from '../../Layout';

type TransitionResult = {
  success: boolean;
  message?: string;
};

type Props = {
  attestationExecution: TAttestationExecution;
  toStatus: TAttestationStatuses;
  isOpen: boolean;
  onClose: () => void;
  onAccept: (notes: string) => Promise<TransitionResult>;
};

export default function AttestationTransitionModal({
  attestationExecution,
  toStatus,
  isOpen,
  onClose,
  onAccept,
}: Props) {
  const [notes, setNotes] = useState('');
  const [error, setError] = useState<string>();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit = useCallback(async () => {
    setIsSubmitting(true);
    const result = await onAccept(notes);
    if (!result.success) {
      setError(result.message);
    } else {
      onClose();
    }
    setIsSubmitting(false);
  }, [notes, onAccept, onClose]);

  return (
    <Modal size="3xl" isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {attestationExecution.schedule_snapshot_json.name}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={4} align="flex-start">
            <HStack spacing={2}>
              <Text>Changing attestation status from</Text>
              <AttestationStatus
                attestationStatus={attestationExecution.current_status}
              />
              <Text>to</Text>
              <AttestationStatus attestationStatus={toStatus} />
            </HStack>
            <HStack spacing={4}>
              <Text>Attestation Owner</Text>
              <Tag key={attestationExecution.owner.cuid} size={'md'}>
                <AvatarProxy
                  src={attestationExecution.owner.picture}
                  size="xs"
                  name={attestationExecution.owner.name}
                  ml={-2}
                  mr={2}
                />
                <TagLabel>{attestationExecution.owner.name}</TagLabel>
              </Tag>
            </HStack>
            <FormControl>
              <Label mb={2}>NOTES</Label>
              <Textarea
                placeholder="Capture notes on the transition."
                value={notes}
                onChange={e => setNotes(e.target.value)}
              />
            </FormControl>
          </VStack>
          {error && (
            <Alert status="error">
              <AlertIcon />
              {error}
            </Alert>
          )}
        </ModalBody>
        <ModalFooter justifyContent={'space-between'}>
          <Button variant="ghost" onClick={onClose} isLoading={isSubmitting}>
            Cancel
          </Button>

          <Button
            variant="primary"
            onClick={onSubmit}
            isDisabled={isSubmitting || !notes}
            isLoading={isSubmitting}
          >
            Confirm
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
