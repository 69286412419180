import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { VStack, useToast, useDisclosure, Text } from '@chakra-ui/react';
import { CustomFieldProps } from '../types';
import {
  EntityAttachment,
  EntityAttachmentFile,
} from '../../../models/entity_attachments';
import { useFlags } from '../../../hooks/useFlags';
import API from '../../../api/API';
import InventoryModelContext from '../../../contexts/InventoryModel';
import prettyBytes from 'pretty-bytes';
import {
  FileDisplay,
  RemoveAttachmentModal,
  UploadFileButton,
  NoteState,
  RemoveAttachmentDetails,
} from '../../ManageAttachmentsField';

export default function CustomAttachments({
  schema,
  mode,
  value,
  onChange,
}: CustomFieldProps) {
  const { inventoryModel } = useContext(InventoryModelContext);
  const fileUploadRef = useRef<HTMLInputElement>(null);
  const [entityAttachments, setEntityAttachments] =
    useState<EntityAttachment>();
  const [notesState, setNotesState] = useState<NoteState[]>([]);
  const { globalMaxUploadSize } = useFlags();
  const toast = useToast();
  const removeModal = useDisclosure();
  const [removeModalAttachment, setRemoveModalAttachment] =
    useState<RemoveAttachmentDetails>();

  const maxFileSizeBytes = globalMaxUploadSize || 20000000;

  // Helper to update onChange with current attachment IDs
  const updateOnChangeValue = useCallback(
    (attachments?: EntityAttachmentFile[]) => {
      if (!attachments || attachments.length === 0) {
        onChange(undefined);
      } else {
        onChange(attachments.map(attachment => attachment.id));
      }
    },
    [onChange],
  );

  // Update handleFileUpload to trigger onChange after successful upload
  const handleFileUpload = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!e.target.files?.length) return;

      const file = e.target.files[0];

      if (file.size > maxFileSizeBytes) {
        toast({
          variant: 'subtle',
          title: 'File is too big',
          description: `The file exceeds the maximum size of ${prettyBytes(
            maxFileSizeBytes,
          )}`,
          status: 'warning',
          duration: 5000,
          isClosable: true,
        });
        return;
      }

      try {
        const newEntityAttachments = await API.UploadEntityAttachmentsFiles(
          'inventory_model',
          inventoryModel?.cuid!,
          schema.key,
          [file],
        );

        setEntityAttachments(newEntityAttachments);
        updateOnChangeValue(newEntityAttachments.attachments);

        toast({
          variant: 'subtle',
          title: 'File uploaded',
          description: `${file.name} has been uploaded successfully`,
          status: 'success',
          duration: 4000,
          isClosable: true,
        });

        if (fileUploadRef.current) {
          fileUploadRef.current.value = '';
        }
      } catch (error) {
        console.error('Error uploading file:', error);
        toast({
          variant: 'subtle',
          title: 'Error',
          description: 'Failed to upload file',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    },
    [value, maxFileSizeBytes, updateOnChangeValue],
  );

  // Update localDelete to trigger onChange after successful deletion
  const localDelete = useCallback(
    async (attachment: EntityAttachmentFile) => {
      try {
        const newEntityAttachments = await API.DeleteEntityAttachmentsFiles(
          'inventory_model',
          inventoryModel?.cuid!,
          schema.key,
          [attachment.id],
        );

        setEntityAttachments(newEntityAttachments);
        updateOnChangeValue(newEntityAttachments.attachments);

        setNotesState(prevState =>
          prevState.filter(note => note.id !== attachment.name),
        );

        toast({
          variant: 'subtle',
          title: 'File deleted',
          description: `${attachment.name} has been removed`,
          status: 'success',
          duration: 4000,
          isClosable: true,
        });
      } catch (error) {
        console.error('Error deleting file:', error);
        toast({
          variant: 'subtle',
          title: 'Error',
          description: 'Failed to delete file',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    },
    [value, updateOnChangeValue],
  );

  // Update useEffect to set initial onChange value when attachments are loaded
  useEffect(() => {
    (async () => {
      try {
        if (!inventoryModel?.cuid) return;

        const attachments = await API.GetEntityAttachments(
          'inventory_model',
          inventoryModel?.cuid!,
          schema.key,
        );
        setEntityAttachments(attachments);
        updateOnChangeValue(attachments.attachments);

        const filteredNotesState = attachments.attachments
          .filter(attachment => !!attachment.description)
          .map(attachment => ({
            id: attachment.name,
            isNoteAdded: !!attachment.description,
            note: attachment.description || '',
          }));

        setNotesState(filteredNotesState);
      } catch (e: any) {
        if (e.response?.status !== 404) {
          console.log(e);
        }
      }
    })();
  }, [inventoryModel?.cuid, schema.key, updateOnChangeValue]);

  // This mode is only when this component is used in a table
  if (mode === 'display') {
    return <Text></Text>;
  }

  return (
    <>
      <RemoveAttachmentModal
        isOpen={removeModal.isOpen}
        onClose={removeModal.onClose}
        isLocal={false}
        onConfirm={() => {
          removeModal.onClose();
          if (removeModalAttachment && entityAttachments?.attachments) {
            localDelete(
              entityAttachments?.attachments[removeModalAttachment.index],
            );
          }
        }}
      />
      <input
        ref={fileUploadRef}
        type="file"
        hidden
        onChange={handleFileUpload}
      />
      <VStack alignItems="flex-start" w="full" gap={1}>
        {entityAttachments?.attachments.map((attachment, attachmentIndex) => (
          <FileDisplay
            key={attachment.id}
            isLocal={false}
            fileName={attachment.name}
            size={attachment.size_in_bytes}
            mimeType={attachment.content_type}
            canDelete={true}
            userCUID={attachment.uploaded_by_cuid}
            isEditing={false}
            createdAt={attachment.created_at}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              window.open(attachment.url, '_blank');
            }}
            onClickNote={e => {}}
            onNoteChange={() => {}}
            onDelete={e => {
              e.preventDefault();
              e.stopPropagation();
              setRemoveModalAttachment({
                isLocal: false,
                index: attachmentIndex,
              });
              removeModal.onOpen();
            }}
            noteState={
              notesState.find(note => note.id === attachment.name) || {
                id: attachment.name,
                isNoteAdded: false,
                note: '',
              }
            }
            canAddNote={false}
          />
        ))}
        <UploadFileButton
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            if (!fileUploadRef || !fileUploadRef.current) return;
            fileUploadRef.current.click();
          }}
        />
      </VStack>
    </>
  );
}
