import { TDashboardVisualizationJSON } from '../../../../models/report';
import { useEffect, useState } from 'react';
import API from '../../../../api/API';
import { Box, Spinner, VStack } from '@chakra-ui/react';
import ScalableText from '../../../ScaleableText';
import { formatNumber } from '../../../../utils';

type Props = {
  visualizationJSON: TDashboardVisualizationJSON;
  chartClick: (params: any, data: any[]) => void;
};

const ReportCounter = ({
  visualizationJSON: { dataset, metrics = [], filtering, extraSettings },
  chartClick,
}: Props) => {
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      if (!dataset || metrics.length === 0) {
        return;
      }
      setLoading(true);
      const results = await API.GetReportingData(
        dataset,
        metrics,
        undefined,
        undefined,
        filtering,
      );
      setData(results);
      setLoading(false);
    })();
  }, [dataset, metrics, filtering]);

  if (loading) {
    return (
      <Box
        display="flex"
        flex={1}
        h="full"
        justifyContent="center"
        alignItems="center"
      >
        <Spinner />
      </Box>
    );
  }

  const displayValue = () => {
    if (!data || !data[0]?.metric) return '';

    const value = data[0].metric;
    // Only format the number if it's not an integer
    return Number.isInteger(value)
      ? value.toLocaleString()
      : formatNumber(value, 2);
  };

  return (
    <VStack
      onClick={() => {
        if (data && data[0]) {
          chartClick(
            {
              componentType: 'counter',
            },
            data[0],
          );
        }
      }}
      cursor={'pointer'}
      display="flex"
      alignItems="center"
      justifyContent="center"
      h="100%"
      w="100%"
      color={extraSettings?.valueColor || 'neutral.500'}
      fontWeight={'bold'}
    >
      <ScalableText>{displayValue()}</ScalableText>
    </VStack>
  );
};

export default ReportCounter;
