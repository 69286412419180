import { menuAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { defineMultiStyleConfig, definePartsStyle } =
  createMultiStyleConfigHelpers(parts.keys);

export const Menu = defineMultiStyleConfig({
  baseStyle: definePartsStyle({
    list: {
      bg: 'white',
      boxShadow: 'lg',
      color: 'inherit',
      minW: '3xs',
      py: '2',
      zIndex: 1,
      borderRadius: 'md',
      borderWidth: '1px',
      _dark: {
        bg: 'neutral.900',
      },
    },
    item: {
      py: '1.5',
      px: '3',
      transitionProperty: 'background',
      transitionDuration: 'ultra-fast',
      transitionTimingFunction: 'ease-in',
      _focus: {
        bg: 'brand.25',
      },
      _active: {
        bg: 'brand.25',
      },
      _expanded: {
        bg: 'gray.100',
      },
      _hover: {
        bg: 'brandSecondary.25',
        _dark: {
          bg: 'brandSecondary.850',
          color: 'brandSecondary.25',
        },
      },
      _dark: {
        bg: 'brandSecondary.950',
        color: 'brandSecondary.100',
      },
    },

    button: {
      transitionProperty: 'common',
      transitionDuration: 'normal',
    },
    groupTitle: {
      mx: 4,
      my: 2,
      fontWeight: 'semibold',
      fontSize: 'sm',
    },
    command: {
      opacity: 0.6,
    },
    divider: {
      my: 2,
      borderWidth: '1px',
      borderColor: 'inherit',
    },
  }),
});
