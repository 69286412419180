/**
 * Finding Model
 * */

import { TRiskArea } from './risk_area';
import { APIPaginatedResponse, keyable } from './utils';

export enum FindingStatus {
  draft = 'draft',
  open = 'open',
  closed = 'closed',
}

export const FindingSeverityLevels = {
  high: 1,
  medium: 2,
  low: 3,
};

export type TFindingFiltersOptions = {
  business_units: [
    {
      cuid: string;
      name: string;
    },
  ];
  assignees: [
    {
      cuid: string;
      name: string;
    },
  ];
  status: string[];
  risk_areas: [
    {
      cuid: string;
      name: string;
    },
  ];
  severities: [
    {
      name: string;
      id: number;
    },
  ];
  inventory_models: [
    {
      cuid: string;
      name: string;
    },
  ];
};

export type FindingFilters = {
  cuids?: string[];
  inventory_models?: string[];
  business_units?: string[];
  assignees?: string[];
  status?: string[];
  risk_areas?: string[];
  severities?: number[];
  include_archived_models?: boolean;
  created_at__lte?: number;
  created_at__gte?: number;
};

export type TFindingPaginated = APIPaginatedResponse<TFinding>;

export type TSeverity = {
  id: number;
  level: number;
  name: string;
  colors: {
    primary: string;
    secondary: string;
  };
};

export type TFinding = {
  finding_id: number;
  key: string;
  cuid: string;
  title: string;
  description: string;
  risk_area?: TRiskArea;
  status: FindingStatus;
  severity: TSeverity;
  documentation_section_id?: string | null;
  user: {
    cuid: string;
    email: string;
    name: string;
    picture: string;
  };
  owner: {
    cuid: string;
    email: string;
    name: string;
    picture: string;
  };
  metadata: keyable;
  due_at: number;
  created_at: number;
  updated_at: number;
  inventory_model?: {
    cuid: string;
    name: string;
  };
};

/**
 * Get the display status of a finding.
 *
 * If the finding is open and the due date is in the past, return 'past_due'.
 * Otherwise, return the finding status.
 */
export const getFindingDisplayStatus = (finding: TFinding) => {
  const today = new Date();
  const dueDate = finding.due_at && new Date(finding.due_at * 1000);
  if (
    (finding.status === undefined ||
      finding.status === null ||
      finding.status === FindingStatus.open) &&
    dueDate &&
    dueDate < today
  ) {
    return 'past_due';
  }
  return finding.status || FindingStatus.open;
};

export const getFindingSeverity = (finding: TFinding) => {
  if (finding.metadata && finding.metadata.severity) {
    return FindingSeverityLevels[finding.metadata.severity as keyof object];
  }
  return FindingSeverityLevels.low;
};

export const hasLevelOneFindings = (findings: TFinding[]) =>
  findings.some(f => getFindingSeverity(f) === FindingSeverityLevels.high);
