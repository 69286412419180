import {
  Heading,
  HStack,
  Icon,
  SlideFade,
  Spacer,
  Stack,
  Tag,
  Text,
  Tooltip,
  useColorModeValue,
} from '@chakra-ui/react';
import StatusSetNode from '../../nodes/StatusSetNode';
import {
  ArrowsPointingOutIcon,
  CubeIcon,
  SignalIcon,
  StopCircleIcon,
  UserGroupIcon,
} from '@heroicons/react/24/outline';
import StatusFormNode from '../../nodes/StatusFormNode';
import ApprovalNode from '../../nodes/ApprovalNode';
import { CursorArrowRaysIcon } from '@heroicons/react/20/solid';
import { DragHandleIcon } from '@chakra-ui/icons';
import useWorkflow from '../../../../../../hooks/useWorkflow';
import BranchRouterNode from '../../nodes/BranchNodes/BranchRouterNode';
import { ClockIcon } from '@heroicons/react/24/outline';
import WaitNode from '../../nodes/WaitNode';
import { useFlags } from '../../../../../../hooks/useFlags';
import StateSetNode from '../../nodes/StateSetNode';
import UserActionNode from '../../nodes/UserActionNode';
import { ReactNode } from 'react';
import EndNode from '../../nodes/EndNode';

const NodeButton = ({
  icon,
  label,
  description,
  nodeType,
  hidden = false,
  tooltip,
}: {
  icon: any;
  label: string;
  description?: string;
  nodeType: string;
  hidden?: boolean;
  tooltip?: ReactNode;
}) => {
  const onDragStart = (event: any) => {
    event.dataTransfer.setData('application/reactflow', nodeType);
    event.dataTransfer.effectAllowed = 'move';
  };

  return (
    <HStack
      py={4}
      pl={4}
      pr={4}
      gap={2}
      bg={useColorModeValue('white', 'neutral.700')}
      rounded={'md'}
      onDragStart={onDragStart}
      draggable
      _hover={{
        bg: useColorModeValue('brandSecondary.25', 'brandSecondary.600'),
      }}
      transition={'background-color 0.2s ease'}
      hidden={hidden}
      role="group"
    >
      <Icon as={icon} boxSize={6} alignSelf={'flex-start'} />
      <Stack gap={1}>
        <Heading as={'h5'} mt={0.5}>
          {label}
        </Heading>
        <Text>{description}</Text>
      </Stack>

      <Spacer />
      {tooltip && tooltip}
      <Icon
        as={DragHandleIcon}
        boxSize={4}
        cursor={'grab'}
        visibility={'hidden'}
        _groupHover={{
          visibility: 'visible',
        }}
        mt={1}
        alignSelf={'flex-start'}
      />
    </HStack>
  );
};

const NodesBar = () => {
  const { selectedNodeId, workflow } = useWorkflow();
  const { workflowsV3 } = useFlags();
  return (
    <SlideFade in={selectedNodeId === undefined} offsetX={'-4rem'}>
      <Stack
        bg={useColorModeValue('neutral.50', 'neutral.900')}
        p={4}
        border={'1px solid'}
        borderColor={useColorModeValue('neutral.200', 'neutral.800')}
        rounded={'md'}
        maxW={80}
        gap={4}
        maxH="70vh"
        overflowY="auto"
      >
        <Stack gap={2}>
          <Heading>Workflow Steps</Heading>
          <Text>Drag a step onto the canvas, then configure it.</Text>
        </Stack>
        <Stack gap={2}>
          <NodeButton
            icon={CubeIcon}
            label="Model Stage Change"
            nodeType={StatusSetNode.type}
            description="Updates model stage."
          />

          <NodeButton
            icon={SignalIcon}
            label="Workflow State Change"
            nodeType={StateSetNode.type}
            hidden={!workflowsV3 as boolean}
            description="Updates workflow state."
          />

          <NodeButton
            icon={CursorArrowRaysIcon}
            label="User Action"
            description="Displays button to trigger action."
            nodeType={StatusFormNode.type}
            hidden={workflow?.entity_name !== 'InventoryModel'}
            tooltip={
              workflowsV3 ? (
                <Tooltip
                  label="This action will be removed, use 'User Action v2' combined with 'Model Stage Change' and/or 'Workflow State Change'."
                  fontSize="md"
                >
                  <Tag size={'sm'} colorScheme={'yellow'}>
                    !
                  </Tag>
                </Tooltip>
              ) : undefined
            }
          />

          <NodeButton
            icon={CursorArrowRaysIcon}
            label="User Action v2"
            description="Displays button on active workflow to trigger action."
            nodeType={UserActionNode.type}
            hidden={!workflowsV3 as boolean}
          />

          <NodeButton
            icon={UserGroupIcon}
            label="Approval"
            description="Collects approvals or rejections from the configured roles."
            nodeType={ApprovalNode.type}
          />
          <NodeButton
            icon={ArrowsPointingOutIcon}
            description="Forks workflow depending conditions."
            label="Condition Branch"
            nodeType={BranchRouterNode.type}
          />
          <NodeButton
            icon={ClockIcon}
            label="Wait"
            description="Halts workflow for a predetermined amount of time."
            nodeType={WaitNode.type}
          />

          <NodeButton
            icon={StopCircleIcon}
            label="End"
            description="Determines when this workflow ends."
            nodeType={EndNode.type}
            hidden={!workflowsV3 as boolean}
          />
        </Stack>
      </Stack>
    </SlideFade>
  );
};

export default NodesBar;
