import { useQuery } from 'react-query';
import { GetAuthSettings } from '../api/API';

export interface AuthSettings {
    auth_provider_type: string;
    idp_user_creation_enabled: boolean;
    idp_change_password_enabled: boolean;
}

export function useAuthConfig() {
    const { data: authSettings, isLoading } = useQuery<AuthSettings>(
        ['auth-settings'],
        GetAuthSettings,
        {
            staleTime: 5 * 60 * 1000, // Cache for 5 minutes
        }
    );

    return {
        authSettings,
        isLoading,
        canChangePassword: authSettings?.idp_change_password_enabled ?? false,
    };
} 