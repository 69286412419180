import {
  Button,
  Icon,
  Link,
  Stack,
  Text,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
import AttributesRail from '../Layout/AttributesRail';
import { Label } from '../Layout';
import { useNavigate } from 'react-router-dom';
import { InfoIcon } from '@chakra-ui/icons';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';
import { TInventoryModel } from '../../models/inventory_model';
import { CONFIG } from '../../config';
import { DownloadMonitoringReportModal } from '../DownloadModal';
import DownloadOfflineDocument from '../DownloadOfflineDocument';

interface Props {
  inventoryModel: TInventoryModel;
  onOpenTemplateModal?: () => void;
}

export default function AttributesRailMonitoringOverview({
  inventoryModel,
  onOpenTemplateModal,
}: Props) {
  const navigate = useNavigate();
  return (
    <AttributesRail>
      {!inventoryModel.template_monitoring.is_offline ? (
        <DownloadMonitoringReportModal inventoryModel={inventoryModel} />
      ) : (
        <Stack>
          <DownloadOfflineDocument
            files={inventoryModel.template_monitoring.offline_files}
          />
        </Stack>
      )}
      <VStack alignItems={'flex-start'} gap={1}>
        <Icon
          as={InfoIcon}
          color={useColorModeValue('neutral.500', 'neutral.200')}
          mt={1}
        />
        <Text>
          Learn how to enable and review ongoing monitoring plans. <br />
          <Link
            isExternal
            href={`${CONFIG.VALIDMIND_DOCS_URL}/guide/monitoring/ongoing-monitoring.html`}
            target="_blank"
          >
            Open documentation
            <Icon ml={1} boxSize={3} as={ArrowTopRightOnSquareIcon} />
          </Link>
        </Text>
      </VStack>
      {onOpenTemplateModal && (
        <VStack align="start">
          <Label>ONGOING MONITORING TEMPLATE</Label>
          <Button
            variant={'link'}
            onClick={onOpenTemplateModal}
            whiteSpace={'normal'}
            textAlign={'left'}
            data-testid="view-monitoring-template-btn"
          >{`${inventoryModel?.template_monitoring.name} v${inventoryModel?.template_monitoring.version_number}`}</Button>
        </VStack>
      )}
    </AttributesRail>
  );
}
