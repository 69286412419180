import { useLocation } from 'react-router-dom';
import {
  VStack,
  Divider,
  LinkBox,
  LinkOverlay,
  useColorModeValue,
  Heading,
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { useContext } from 'react';
import UsersContext from '../../contexts/UsersContext';
import SidebarContext from '../../contexts/SidebarContext';
import { useFlags } from '../../hooks/useFlags';

interface MenuItemProps {
  label: string;
  path: string;
  isCurrent?: boolean;
}

const MenuItem = ({ label, path }: MenuItemProps) => {
  const location = useLocation();
  const currentPath = location.pathname.concat(location.hash);
  const isCurrent = currentPath.includes(path!);

  return (
    <LinkBox
      w="full"
      px={4}
      py={2}
      fontWeight={'semibold'}
      color={
        isCurrent
          ? useColorModeValue('brand.600', 'brand.base')
          : useColorModeValue('neutral.600', 'neutral.400')
      }
      cursor={isCurrent ? 'default' : 'pointer'}
      _hover={{
        bg: isCurrent
          ? 'transparent'
          : useColorModeValue('brandSecondary.25', 'brandSecondary.950'),
        color: isCurrent
          ? 'brand.base'
          : useColorModeValue('neutral.700', 'neutral.200'),
      }}
      borderEndRadius={4}
      borderStartRadius={isCurrent ? 0 : 4}
      boxShadow={
        isCurrent ? 'inset 3px 0px 0px 0px var(--chakra-colors-brand-base)' : 0
      }
      data-testid="settings-sidebar-menu-link"
      transition="all 0.2s ease-in-out"
      mt={'0 !important'}
    >
      <LinkOverlay as={RouterLink} to={path}>
        {label}
      </LinkOverlay>
    </LinkBox>
  );
};

interface SidebarSectionProps {
  sectionTitle: string;
  sectionItems: any[];
}

const SidebarSection = ({
  sectionTitle,
  sectionItems,
}: SidebarSectionProps) => {
  return (
    <VStack alignItems="start" w="full" gap={1}>
      <Heading as={'h6'}>{sectionTitle}</Heading>
      {sectionItems.map(({ label, path }) => (
        <MenuItem key={path} label={label} path={path} />
      ))}
    </VStack>
  );
};

export function SettingsSidebar() {
  const { inSettings } = useContext(SidebarContext);
  const { currentUser, userHasPermission } = useContext(UsersContext);
  const { attestationsUi, workflowsV3 } = useFlags();
  const dividerColor = useColorModeValue('neutral.200', 'neutral.800');

  const isOpen = inSettings;
  const isAdmin = currentUser?.roles.some(role => role.role.is_admin);

  const hasGroupPermission = userHasPermission(['read_group'], 'any');
  const hasRolePermission = userHasPermission(
    ['create_role', 'update_role'],
    'all',
  );

  const profileSettings = [
    {
      label: 'Profile',
      path: '/settings/profile',
    },
  ];

  const userPermissionsSettings = [];

  if (isAdmin) {
    userPermissionsSettings.push(
      {
        label: 'User Directory',
        path: '/settings/user-directory',
      },
      {
        label: 'Invite New Users',
        path: '/settings/invitation',
      },
    );
  }

  if (hasGroupPermission) {
    userPermissionsSettings.push({
      label: 'Groups',
      path: '/settings/groups',
    });
  }

  if (hasRolePermission) {
    userPermissionsSettings.push(
      {
        label: 'Roles',
        path: '/settings/roles',
      },
      {
        label: 'Stakeholders',
        path: '/settings/stakeholders',
      },
      {
        label: 'Permissions',
        path: '/settings/permissions',
      },
    );
  }

  const workspaceSettings = [
    {
      label: 'Organization',
      path: '/settings/organization',
    },
    {
      label: 'Templates',
      path: '/settings/templates',
    },
  ];

  if (userHasPermission(['read_inventory_schema'], 'all')) {
    workspaceSettings.push({
      label: 'Model Inventory Fields',
      path: '/settings/inventory-model-custom-fields',
    });
  }

  if (userHasPermission(['read_risk_area', 'read_guideline'], 'any')) {
    workspaceSettings.splice(1, 0, {
      label: 'Risk Areas & Validation Guidelines',
      path: '/settings/risk-areas',
    });
  }

  workspaceSettings.push({
    label: 'Block Library',
    path: '/settings/block-library',
  });

  if (attestationsUi && userHasPermission(['read_attestation'], 'all')) {
    workspaceSettings.push({
      label: 'Attestations',
      path: '/settings/attestations',
    });
  }

  if (isAdmin) {
    workspaceSettings.push({
      label: 'Model Stages',
      path: '/settings/statuses',
    });
    if (workflowsV3) {
      workspaceSettings.push({
        label: 'Workflow States',
        path: '/settings/workflow-states',
      });
    }
    workspaceSettings.push({ label: 'Workflows', path: '/settings/workflows' });
  }

  if (!isOpen) {
    return null;
  }

  return (
    <VStack
      w="240px"
      position="relative"
      alignItems="start"
      p={4}
      pt={12}
      mt={1}
      borderRight="1px"
      borderColor={'var(--chakra-colors-chakra-border-color)'}
      data-testid="settings-sidebar"
      overflow={'auto'}
      className="no-scrollbar"
    >
      <Heading as="h4">Settings</Heading>
      <VStack
        divider={<Divider color={dividerColor} />}
        w={'full'}
        gap={4}
        pt={4}
      >
        <SidebarSection
          sectionTitle="YOUR ACCOUNT"
          sectionItems={profileSettings}
        />
        <SidebarSection
          sectionTitle="USER PERMISSIONS"
          sectionItems={userPermissionsSettings}
        />
        <SidebarSection
          sectionTitle="WORKSPACE SETTINGS"
          sectionItems={workspaceSettings}
        />
      </VStack>
    </VStack>
  );
}
