import { keyable } from './utils';

export enum ReservedStages {
  Archived = 'Archived',
  Deleted = 'Deleted',
}

export interface TStatusesWorkflowStatus {
  id?: number;
  created_at?: number;
  cuid?: string;
  name: string;
  description?: string;
  is_initial?: boolean;
  colors: keyable;
  updated_at?: number;
  workflow_cuid?: string;
  is_reserved?: boolean;
}

export interface TStatusesWorkflow {
  categories: string[];
  created_at?: number;
  cuid: string;
  organization_cuid: string;
  statuses: TStatusesWorkflowStatus[];
  title: string;
  description: string;
  updated_at?: number;
}

export const WorkflowCategoryNames = {
  projects: 'Model Documentation',
  models: 'Model Stages',
};
