import { useContext, useEffect } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import {
  Box,
  Heading,
  HStack,
  Icon,
  Text,
  useDisclosure,
  VStack,
  Button,
} from '@chakra-ui/react';
import { PlusIcon } from '@heroicons/react/24/outline';
import API from '../../../api/API';
import Breadcrumbs from '../../../components/Breadcrumbs';
import SidebarContext from '../../../contexts/SidebarContext';
import UsersContext from '../../../contexts/UsersContext';
import { useGroups } from '../../../hooks/useGroups';
import { TGroup } from '../../../models/group';
import Group, { TGroupWithMembers } from './Group';
import { LoadingContainer } from '../../../components/LoadingContainer';
import { ContentPageTitle } from '../../../components/Layout';
import CreateGroupModal from './CreateGroupModal';
import MoreInfoPopOver from '../../../components/MoreInfoPopOver';
import { CONFIG } from '../../../config';

const getGroupWithMembers = async (
  group: TGroup,
): Promise<TGroupWithMembers> => {
  const members = await API.GetGroupUsers(group.cuid);
  return { ...group, members };
};

export default function Groups() {
  const { setInSettings } = useContext(SidebarContext);
  const { currentUser, userHasPermission } = useContext(UsersContext);
  const { groups } = useGroups();
  const queryClient = useQueryClient();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const canCreateGroup = userHasPermission(['create_group'], 'all');

  useEffect(() => {
    setInSettings(true);
    return () => {
      setInSettings(false);
      void queryClient.invalidateQueries(['groups-members']);
    };
  }, []);

  const { data: allGroupsWithMembers = [], isLoading } = useQuery(
    ['groups-members', groups],
    async (): Promise<TGroupWithMembers[]> => {
      if (!currentUser) {
        return [];
      }
      const allGroupsWithMembers = groups.map(getGroupWithMembers);
      return await Promise.all(allGroupsWithMembers);
    },
    {
      staleTime: 5 * 60 * 1000,
    },
  );

  return (
    <VStack
      alignItems="start"
      spacing={0}
      paddingTop={12}
      mt={1}
      paddingBottom={16}
      px={14}
      gap={8}
      w="full"
      overflow="auto"
      className="no-scrollbar"
      data-testid="groups-settings"
      maxWidth={'7xl'}
      mx={'auto'}
    >
      <Box>
        <Breadcrumbs />
        <ContentPageTitle>
          Groups
          <MoreInfoPopOver
            title="User Groups"
            link={`${CONFIG.VALIDMIND_DOCS_URL}/guide/configuration/manage-groups.html`}
            placement="right-end"
            iconProps={{
              ml: 2,
              fill: 'brand.base',
            }}
          />
        </ContentPageTitle>
      </Box>
      <Text fontSize="md">
        Groups are segments of users with the ability to view models associated
        with that group. Access to granular features within a group's set of
        models is further defined by roles and permissions.
      </Text>

      <VStack w={'full'} alignItems={'flex-start'} mb={4}>
        <Heading as="h3">Groups I Belong To</Heading>
        <LoadingContainer isLoading={isLoading}>
          <VStack
            w="full"
            data-testid="group-list"
            alignItems={'stretch'}
            gap={1}
          >
            {allGroupsWithMembers
              .filter(({ members }) =>
                members.some(({ cuid }) => cuid === currentUser?.cuid),
              )
              .map(group => (
                <Group key={group.cuid} group={group} />
              ))}
          </VStack>
        </LoadingContainer>
      </VStack>
      <VStack w={'full'}>
        <HStack w="full" alignItems="center" justifyContent="space-between">
          <Heading as="h3">All Groups</Heading>
          {canCreateGroup && (
            <Button
              onClick={onOpen}
              leftIcon={<Icon as={PlusIcon} boxSize={5} />}
              variant={'ghost'}
            >
              Create New Group
            </Button>
          )}
        </HStack>
        <LoadingContainer isLoading={isLoading}>
          <VStack w="full" data-testid="group-list" alignItems={'stretch'}>
            {allGroupsWithMembers.map(group => (
              <Group key={group.cuid} group={group} />
            ))}
          </VStack>
        </LoadingContainer>
      </VStack>
      <CreateGroupModal isOpen={isOpen} onClose={onClose} />
    </VStack>
  );
}
