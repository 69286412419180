import { ChangeEvent } from 'react';
import {
  Box,
  Button,
  Center,
  CircularProgress,
  FormControl,
  Heading,
  HStack,
  Icon,
  Select,
  Stack,
  Tab,
  TabList,
  TabPanels,
  Tabs,
  Text,
} from '@chakra-ui/react';
import { TEvent } from '../../models/event';
import EventRow from '../ActivityFeedWidget/EventRow';
import { EmptyStateDisplay } from '../EmptyStateDisplay';
import { ViewIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';

interface IEventListProps {
  events: TEvent[];
  isLoading: boolean;
  showOnlyMentions: boolean;
  setShowOnlyMentions: (showOnlyMentions: boolean) => void;
  variant: string;
  setTabIndex: (index: number) => void;
  tabs: string[];
  tabIndex: number;
  seeAllUrl?: string;
  disableLinks?: boolean;
}

const EventList = ({ ...props }: IEventListProps) => {
  const {
    events,
    isLoading,
    showOnlyMentions,
    setShowOnlyMentions,
    setTabIndex,
    tabs,
    tabIndex,
    seeAllUrl,
    disableLinks,
  } = props;
  const navigate = useNavigate();
  return (
    <Tabs colorScheme="brand" onChange={index => setTabIndex(index)}>
      <TabList hidden={false}>
        {tabs.map((tab, index) => (
          <Tab key={index}>{tab}</Tab>
        ))}
      </TabList>

      {isLoading ? (
        <Center p={12}>
          <Stack justify={'center'} align={'center'}>
            <CircularProgress
              size="40px"
              thickness="2px"
              isIndeterminate
              color="brand.base"
            />
            <Text fontSize={'sm'} color={'inherit'}>
              Please hold...
            </Text>
          </Stack>
        </Center>
      ) : (
        <TabPanels
          p={4}
          display="flex"
          flexDirection="column"
          maxH="100%"
          overflowY="scroll"
        >
          {tabs[tabIndex] === 'Comments' && (
            <FormControl display="flex" alignItems="center" gap={2} mb={4}>
              <Box ml="auto">
                <Select
                  size="sm"
                  value={showOnlyMentions ? 'mentions' : 'all'}
                  onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                    setShowOnlyMentions(e.target.value === 'mentions')
                  }
                >
                  <option value="all">Show all comments</option>
                  <option value="mentions">Show only mentions</option>
                </Select>
              </Box>
            </FormControl>
          )}

          {events && events.length > 0 ? (
            <Stack gap={0}>
              {events.map((event: TEvent, index: number) => (
                <EventRow
                  key={`event-${index}`}
                  event={event}
                  disableLinks={disableLinks}
                />
              ))}
            </Stack>
          ) : (
            <EmptyStateDisplay variant="no-activity">
              <Heading as={'h5'}>No recent activity</Heading>
              <Text align={'center'}>
                There is no recent activity for the selected filter.
              </Text>
            </EmptyStateDisplay>
          )}
        </TabPanels>
      )}

      <HStack justifyContent={'end'}>
        {seeAllUrl && (
          <Button
            variant={'ghost'}
            leftIcon={<Icon as={ViewIcon} boxSize={4} />}
            onClick={() => navigate(seeAllUrl)}
          >
            See All Activity
          </Button>
        )}
      </HStack>
    </Tabs>
  );
};

export default EventList;
