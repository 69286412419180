import { defineStyleConfig } from '@chakra-ui/react';

export const Button = defineStyleConfig({
  baseStyle: {
    fontWeight: 'bold',
    borderRadius: 'base',
    _focus: {
      outline: 'none',
      boxShadow: '0 0 0 1px var(--chakra-colors-brand-400)',
    },
  },

  variants: {
    primary: {
      bg: 'brandSecondary.600',
      color: 'brandSecondary.25',
      _hover: {
        bg: 'brandSecondary.700',
        color: 'white',
        _disabled: {
          bg: 'brandSecondary.500',
        },
      },
      _dark: {
        bg: 'brandSecondary.base',
        color: 'brandSecondary.100',
        _hover: {
          bg: 'brandSecondary.900',
          color: 'brandSecondary.25',
        },
        _focus: {
          borderColor: 'brand.400',
          boxShadow: '0 0 0 1px brand.400',
        },
      },
    },

    outline: {
      bg: 'white',
      border: '1px solid',
      color: 'brandSecondary.600',
      borderColor: 'brandSecondary.500',
      _hover: {
        bg: 'brandSecondary.25',
        color: 'brandSecondary.800',
      },
      _dark: {
        bg: 'brandSecondary.950',
        color: 'brandSecondary.100',
        borderColor: 'brandSecondary.850',
        _hover: {
          bg: 'brandSecondary.850',
          color: 'brandSecondary.25',
          borderColor: 'brandSecondary.700',
        },
      },
    },
    ghost: {
      bg: 'transparent !important',
      color: 'brandSecondary.600',
      _hover: {
        bg: 'brandSecondary.25 !important',
        color: 'brandSecondary.800',
      },
      _dark: {
        bg: 'transparent',
        color: 'brandSecondary.100',
        _hover: {
          bg: 'brandSecondary.950 !important',
          color: 'brandSecondary.25',
        },
        _focus: {
          borderColor: 'brand.400',
          boxShadow: '0 0 0 1px brand.400',
        },
      },
    },
    link: {
      color: 'brandSecondary.600',
      _hover: {
        color: 'brandSecondary.600',
      },
      _dark: {
        bg: 'transparent',
        color: 'brandSecondary.100',
        _hover: {
          color: 'brandSecondary.25',
        },
        _focus: {
          borderColor: 'brand.400',
          boxShadow: '0 0 0 1px brand.400',
        },
      },
    },
    get secondary() {
      return this.outline;
    },
    get tertiary() {
      return this.ghost;
    },
  },
  defaultProps: {
    variant: 'secondary',
  },
});
