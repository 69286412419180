import React, { useState, useEffect, useMemo } from 'react';
import {
  Box,
  Button,
  HStack,
  Stack,
  Text,
  Center,
  Icon,
  Progress,
  Code,
  Tag,
  TagLabel,
  Tooltip,
  ListItem,
  UnorderedList,
  Heading,
  SimpleGrid,
  TagLeftIcon,
} from '@chakra-ui/react';
import { DataTable } from '../../components/DataTable';
import { APIRequestSortBy } from '../../models/utils';
import { useQuery } from 'react-query';
import API from '../../api/API';
import {
  ArrowLongLeftIcon,
  ArrowLongRightIcon,
  CheckIcon,
  ExclamationCircleIcon,
} from '@heroicons/react/24/solid';
import { CubeIcon } from '@heroicons/react/24/outline';
import { removeAfterDot, convertRJSFToCustomFieldJSON } from '../../utils';
import AvatarProxy from '../AvatarProxy';
import InventoryModelTierLevelBadge from '../InventoryModelTierLevelBadge';
import CustomFieldRenderer from '../NewCustomFields/CustomFieldRenderer';
import {
  TInventoryModel,
  TInventoryModelPaginated,
} from '../../models/inventory_model';
import { isMissing } from '../NewCustomFields/CustomFieldValidator';
import useModelSchema from '../../hooks/useModelSchema';
import { getSchemaPropertyByKey } from '../../utils';
import { CustomFieldJSONSchema } from '../NewCustomFields/types';
import ModelInventoryItem from '../ModelInventoryItem';
import { EmptyStateDisplay } from '../EmptyStateDisplay';

interface ModelInventoryTableProps {
  displayTable: boolean;
  filters?: any;
  searchQuery?: string;
  sortBy: APIRequestSortBy | undefined;
  columns: any[];
  pageSize?: number;
  getData?: (
    page: number,
    pageSize: number,
  ) => Promise<TInventoryModelPaginated>;
  onTableSort?: (column: any[]) => void;
  onClickRow?: (row: any) => void;
}

export const MODELS_TABLE_ALL_NON_CUSTOM_COLUMNS = [
  {
    id: 'name',
    Header: 'Name',
    accessor: 'name',
    Cell: ({ row }: any) => {
      return <Text fontWeight={'bold'}>{row.values.name}</Text>;
    },
  },
  {
    id: 'business_unit',
    Header: 'Business Unit',
    accessor: 'business_unit.name',
    Cell: ({ value }: any) => {
      return <Text>{value}</Text>;
    },
  },
  {
    id: 'use',
    Header: 'Use',
    accessor: 'use',
    Cell: ({ value }: any) => {
      return <Text>{value}</Text>;
    },
  },
  {
    id: 'version',
    Header: 'Version',
    accessor: 'version',
    Cell: ({ value }: any) => {
      return <Code>{value}</Code>;
    },
  },
  {
    id: 'owner',
    Header: 'Owner',
    disableSortBy: true,
    accessor: (row: any) =>
      row.users.filter((u: any) => u.name === 'Model Owner'),
    Cell: ({ value }: any) => {
      return (
        <Box>
          {value.map((user: any) => (
            <Tag key={user.user.cuid} size={'md'}>
              <AvatarProxy
                src={user.user.picture}
                size="xs"
                name={user.user.name}
                ml={-2}
                mr={2}
              />
              <TagLabel>{user.user.name}</TagLabel>
            </Tag>
          ))}
        </Box>
      );
    },
  },
  {
    id: 'developers',
    Header: 'Developers',
    accessor: (row: any) =>
      row.users.filter((u: any) => u.name === 'Model Developer'),
    disableSortBy: true,
    Cell: ({ value }: any) => {
      return (
        <Box>
          {value.map((user: any) => (
            <Tag key={user.user.cuid} size={'md'}>
              <AvatarProxy
                src={user.user.picture}
                size="xs"
                name={user.user.name}
                ml={-2}
                mr={2}
              />
              <TagLabel>{user.user.name}</TagLabel>
            </Tag>
          ))}
        </Box>
      );
    },
  },
  {
    id: 'validators',
    Header: 'Validators',
    accessor: (row: any) =>
      row.users.filter((u: any) => u.name === 'Model Validator'),
    disableSortBy: true,
    Cell: ({ value }: any) => {
      return (
        <Box>
          {value.map((user: any) => (
            <Tag key={user.user.cuid} size={'md'}>
              <AvatarProxy
                src={user.user.picture}
                size="xs"
                name={user.user.name}
                ml={-2}
                mr={2}
              />
              <TagLabel>{user.user.name}</TagLabel>
            </Tag>
          ))}
        </Box>
      );
    },
  },
  {
    id: 'tier',
    Header: 'Tier',
    accessor: 'tiering',
    Cell: ({ value }: any) => {
      return <InventoryModelTierLevelBadge level={value} />;
    },
  },
  {
    id: 'status',
    Header: 'Stage',
    accessor: 'status',
    Cell: ({ value }: any) => {
      return (
        <Tag
          data-testid="status-badge"
          size="sm"
          colorScheme={value ? removeAfterDot(value.colors.primary) : ''}
        >
          <TagLeftIcon as={CubeIcon} />
          {value ? value.name : 'No status'}
        </Tag>
      );
    },
  },
  {
    id: 'is_vendor_model',
    Header: 'Is Vendor Model',
    accessor: 'is_vendor_model',
    disableSortBy: true,
    Cell: ({ value }: any) => {
      if (value) {
        return <Icon as={CheckIcon} boxSize={6} />;
      }
    },
  },
  {
    id: 'vendor_name',
    Header: 'Vendor Name',
    accessor: 'vendor_name',
    Cell: ({ value }: any) => {
      if (value) {
        return <Text>{value}</Text>;
      }
    },
  },
];

export const getErrorTooltip = (
  im: TInventoryModel,
  requiredCustomFields: CustomFieldJSONSchema[],
) => {
  const { custom_fields } = im;
  const missingRequiredFields: string[] = [];

  if (custom_fields) {
    requiredCustomFields.forEach(requiredField => {
      const requiredEntry = custom_fields[requiredField.key];
      if (isMissing(requiredEntry)) {
        missingRequiredFields.push(requiredField.label);
      }
    });
  }

  let tooltip;

  if (missingRequiredFields.length > 0) {
    tooltip = (
      <>
        <Text mb={3}>Missing required fields: </Text>
        <UnorderedList>
          {missingRequiredFields.map(missingField => (
            <ListItem>{missingField}</ListItem>
          ))}
        </UnorderedList>
      </>
    );
  }

  return tooltip;
};

const ModelInventoryTable: React.FC<ModelInventoryTableProps> = ({
  displayTable,
  filters,
  searchQuery = '',
  sortBy,
  columns,
  pageSize = 30,
  onTableSort,
  onClickRow,
  getData,
}) => {
  const [page, setPage] = useState(1);
  const { data: schemaData, propertyItems } = useModelSchema();

  /**
   * All columns map 1:1 to API query columns except two conditions:
   *
   * - `owner`, `developers`, and `validators` all map to `users`
   * - `tier` maps to `tiering`
   * - Any custom field maps to `custom_fields` in general
   */
  const apiQueryColumns = useMemo(() => {
    return Array.from(
      new Set(
        columns.map(column => {
          if (['owner', 'developers', 'validators'].includes(column)) {
            return 'users';
          }
          if (column === 'tier') {
            return 'tiering';
          }
          return column;
        }),
      ),
    );
  }, [columns, propertyItems]);

  const { data, isLoading, refetch, isRefetching } = useQuery(
    ['inventory-models', filters, searchQuery, page, sortBy, apiQueryColumns],
    async () => {
      if (getData) {
        return await getData(page, pageSize);
      }
      return await API.GetModelInventory(
        apiQueryColumns,
        filters,
        searchQuery,
        page,
        pageSize,
        sortBy,
      );
    },
    {
      onError: err => console.error(err),
    },
  );

  useEffect(() => {
    refetch();
  }, [columns, filters, searchQuery, page, sortBy]);

  useEffect(() => {
    refetch();
  }, [page]);

  useEffect(() => {
    setPage(1);
    refetch();
  }, [sortBy]);

  useEffect(() => {
    setPage(1);
    refetch();
  }, [searchQuery]);

  useEffect(() => {
    if (filters) {
      setPage(1);
      refetch();
    }
  }, [filters]);

  useEffect(() => {
    setPage(1);
    refetch();
  }, [getData]);

  const requiredCustomFields = useMemo(() => {
    if (schemaData) {
      const requiredSchemaKeys: string[] = [];

      Object.keys(schemaData.settings.properties).forEach(key => {
        if (schemaData.settings.properties[key].requiredOnRegistration) {
          requiredSchemaKeys.push(key);
        }
      });

      return requiredSchemaKeys.map(key => {
        const [schemaProperty, settingsProperty] = getSchemaPropertyByKey(
          schemaData,
          key,
        );
        return convertRJSFToCustomFieldJSON(
          key,
          schemaProperty,
          settingsProperty,
        );
      });
    }
    return [];
  }, [schemaData]);

  // update table columns with selected custom fields
  const tableColumns = useMemo(() => {
    let tableColumns = [
      {
        Header: ' ',
        Cell: ({ row }: any) => {
          const errorTooltip = getErrorTooltip(
            row.original,
            requiredCustomFields,
          );

          return (
            <Box display="flex">
              <Icon boxSize={6} color={'neutral.400'} as={CubeIcon} />
              {!!errorTooltip && (
                <Tooltip label={errorTooltip} hasArrow p={4}>
                  <Icon
                    boxSize={5}
                    as={ExclamationCircleIcon}
                    color="red.500"
                    position="relative"
                    //transform={'translate(-10px, 15px)'}
                    top={2}
                    left={-2}
                  />
                </Tooltip>
              )}
            </Box>
          );
        },
      },
    ] as any[];

    columns.forEach(columnKey => {
      //determine if column is regular field
      const nonCustomField = MODELS_TABLE_ALL_NON_CUSTOM_COLUMNS.find(
        a => a.id === columnKey,
      );

      if (nonCustomField) {
        tableColumns.push(nonCustomField);
      } else {
        // determine if column is custom field
        const customFieldSchema = propertyItems.find(p => p.key === columnKey);

        if (customFieldSchema) {
          tableColumns.push({
            id: customFieldSchema.key,
            Header: customFieldSchema.title,
            accessor: `custom_fields.${customFieldSchema.key}`,
            Cell: ({ value }: any) => {
              const schema = convertRJSFToCustomFieldJSON(
                customFieldSchema.key,
                null,
                customFieldSchema.settings,
              );
              return (
                <CustomFieldRenderer
                  schema={schema}
                  value={value}
                  mode={'display'}
                  onChange={() => {}}
                />
              );
            },
            disableSortBy: true,
          });
        } else {
          console.error('Column not found', columnKey);
        }
      }
    });

    return tableColumns;
  }, [columns, propertyItems, requiredCustomFields]);

  const defaultTableSortBy = [
    {
      id: sortBy?.field || 'created_at',
      desc: sortBy?.order === 'desc',
    },
  ];

  const inventoryModels = data?.results || [];

  return (
    <Box display="flex" overflow="hidden" w={'full'}>
      <Stack
        gap={1}
        w={'full'}
        overflow="hidden"
        pt={isRefetching || isLoading ? 0 : 3}
      >
        {(isRefetching || isLoading) && (
          <Progress size={'xs'} isIndeterminate colorScheme={'brand'} />
        )}
        {data?.total === 0 && (
          <Center>
            <EmptyStateDisplay variant="no-models">
              <Heading as={'h5'}>No models found</Heading>
              <Text align={'center'}>
                We couldn't find any models with your search or filter
                combination.
                <br />
                Please adjust your criteria and try again.
              </Text>
            </EmptyStateDisplay>
          </Center>
        )}
        <SimpleGrid
          spacing={2}
          columns={{ base: 1, xl: 2, '2xl': 3 }}
          display={!displayTable ? 'grid' : 'none'}
        >
          {inventoryModels.map(im => {
            let tooltip;
            const errorTooltip = getErrorTooltip(im, requiredCustomFields);

            if (errorTooltip) {
              tooltip = (
                <Tooltip label={errorTooltip} hasArrow p={4}>
                  <Icon
                    boxSize={6}
                    as={ExclamationCircleIcon}
                    color="red.500"
                    position="relative"
                    //transform={'translate(-10px, 15px)'}
                    top={3}
                    left={-3}
                  />
                </Tooltip>
              );
            }
            return (
              <ModelInventoryItem
                key={im.cuid}
                inventoryModel={im}
                tooltip={tooltip}
              />
            );
          })}
        </SimpleGrid>
        <Stack
          display={displayTable && data && data?.total > 0 ? 'block' : 'none'}
          pt={isRefetching || isLoading ? 1 : 0}
          overflowY="scroll"
        >
          <DataTable
            data={inventoryModels}
            columns={tableColumns}
            enableSort={!!onTableSort}
            pageSize={pageSize}
            isInteractive
            onClickRow={onClickRow}
            manualSortBy
            defaultSortBy={defaultTableSortBy}
            onSort={onTableSort}
          />
        </Stack>
        {data?.results?.length && data?.results?.length > 0 && (
          <Center>
            <HStack>
              <Button
                size="sm"
                variant="ghost"
                leftIcon={<Icon as={ArrowLongLeftIcon} />}
                onClick={() => setPage(prev => prev - 1)}
                isDisabled={page === 1}
              >
                Prev
              </Button>
              <Text fontSize="sm">
                Showing {(page - 1) * pageSize + 1} to{' '}
                {Math.min(page * pageSize, data.total)} of {data.total} Models
              </Text>
              <Button
                size="sm"
                variant="ghost"
                rightIcon={<Icon as={ArrowLongRightIcon} />}
                onClick={() => setPage(prev => prev + 1)}
                isDisabled={data.total / pageSize <= page}
              >
                Next
              </Button>
            </HStack>
          </Center>
        )}
      </Stack>
    </Box>
  );
};

export default ModelInventoryTable;
