import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Heading,
  Button,
  HStack,
  Switch,
  Text,
  Icon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  FormControl,
  FormLabel,
  Input,
  Flex,
  Stack,
  InputGroup,
  InputRightElement,
  FormErrorMessage,
  Alert,
  AlertIcon,
  InputLeftElement,
} from '@chakra-ui/react';
import { useState, useMemo } from 'react';
import {
  ChevronUpIcon,
  ChevronDownIcon,
  ArrowsUpDownIcon,
  PlusIcon,
  EyeIcon,
  EyeSlashIcon,
  MagnifyingGlassIcon,
} from '@heroicons/react/24/solid';
import { useOrganizations, useOrganizationDefaults } from '../../hooks/admin';
import { LoadingContainer } from '../../components/LoadingContainer';
import {
  DefaultTemplate,
  DefaultTemplateConfig,
  Organization,
  Template,
  DemoModel,
} from '../../api/AdminAPI';
import { displayFormattedDateAndTime } from '../../utils';
import { generateStrongPassword } from '../../utils';
import { isValidEmail } from '../../utils';
import { useMutation, useQueryClient } from 'react-query';
import AdminAPI, { CreateOrganizationRequest } from '../../api/AdminAPI';
import { useToast } from '@chakra-ui/react';
import FormSelect from './FormSelect';
import { Link } from 'react-router-dom';
import { useFlags, AuthConfig } from '../../hooks/useFlags';

type SortField = 'name' | 'created_at' | 'updated_at';
type SortOrder = 'asc' | 'desc';

function Organizations() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [newOrgName, setNewOrgName] = useState('');
  const { data: organizations, isLoading } = useOrganizations();
  const [currentPage, setCurrentPage] = useState(1);
  const [sortField, setSortField] = useState<SortField>('created_at');
  const [sortOrder, setSortOrder] = useState<SortOrder>('desc');
  const itemsPerPage = 10;
  const [selectedModels, setSelectedModels] = useState<DemoModel[]>([]);
  const [
    selectedModelDocumentationTemplates,
    setselectedModelDocumentationTemplates,
  ] = useState<Template[]>([]);
  const [
    selectedValidationReportTemplates,
    setselectedValidationReportTemplates,
  ] = useState<Template[]>([]);
  const [selectedMonitoringTemplates, setselectedMonitoringTemplates] =
    useState<Template[]>([]);
  const { defaults, isLoading: isLoadingDefaults } = useOrganizationDefaults();
  const [adminEmail, setAdminEmail] = useState('');
  const [adminName, setAdminName] = useState('');
  const [adminPassword, setAdminPassword] = useState(generateStrongPassword());
  const [showPassword, setShowPassword] = useState(false);
  const [populateDemoModels, setPopulateDemoModels] = useState(true);
  const [emailError, setEmailError] = useState<string>('');
  const queryClient = useQueryClient();
  const toast = useToast();
  const [error, setError] = useState<string | null>(null);
  const [searchQuery, setSearchQuery] = useState('');
  const {
    authConfig = {
      auth_create_user_enabled: false,
    },
  } = useFlags() as { authConfig: AuthConfig };

  const createOrganizationMutation = useMutation({
    mutationFn: async (organizationData: CreateOrganizationRequest) => {
      return AdminAPI.CreateOrganization(organizationData);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['admin', 'organizations'] });
      toast({
        variant: 'subtle',
        title: 'Organization created',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      setNewOrgName('');
      setSelectedModels([]);
      setselectedModelDocumentationTemplates([]);
      setselectedValidationReportTemplates([]);
      setselectedMonitoringTemplates([]);
      setAdminEmail('');
      setAdminName('');
      setAdminPassword(generateStrongPassword());
      setError(null);
      onClose();
    },
    onError: (error: Error) => {
      setError(error.message || 'Failed to create organization');
    },
  });

  const handleSort = (field: SortField) => {
    if (sortField === field) {
      // Toggle order: asc -> desc -> asc
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortOrder('asc');
    }
    // Reset to first page when sorting changes
    setCurrentPage(1);
  };

  const getSortIcon = (field: SortField) => {
    if (sortField !== field)
      return <Icon as={ArrowsUpDownIcon} ml={2} w={4} h={4} />;
    if (sortOrder === 'asc')
      return <Icon as={ChevronUpIcon} ml={2} w={4} h={4} />;
    return <Icon as={ChevronDownIcon} ml={2} w={4} h={4} />;
  };

  const filteredOrganizations = useMemo(() => {
    if (!organizations) return [];

    return organizations.filter(org => {
      const searchLower = searchQuery.toLowerCase();
      return (
        org.name.toLowerCase().includes(searchLower) ||
        org.cuid.toLowerCase().includes(searchLower)
      );
    });
  }, [organizations, searchQuery]);

  const sortedOrganizations = useMemo(() => {
    if (!filteredOrganizations) return [];

    return [...filteredOrganizations].sort((a, b) => {
      const aValue = a[sortField];
      const bValue = b[sortField];

      if (sortOrder === 'asc') {
        return aValue > bValue ? 1 : -1;
      }
      return aValue < bValue ? 1 : -1;
    });
  }, [filteredOrganizations, sortField, sortOrder]);

  const totalItems = sortedOrganizations?.length || 0;
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentOrganizations = sortedOrganizations?.slice(startIndex, endIndex);

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const handleSelectAllModels = () => {
    if (defaults?.initial_demo_models) {
      setSelectedModels(
        selectedModels.length === defaults.initial_demo_models.length
          ? []
          : defaults.initial_demo_models,
      );
    }
  };

  const handleChangeModelValues = (modelNames: string[]) => {
    if (defaults?.initial_demo_models) {
      const newModels = defaults.initial_demo_models.filter(model =>
        modelNames.includes(model.name),
      );
      setSelectedModels(newModels);
    }
  };

  const handleSelectAllTemplates = (modelType: keyof DefaultTemplate) => {
    if (defaults?.initial_templates) {
      switch (modelType) {
        case 'model_documentation':
          setselectedModelDocumentationTemplates(
            selectedModelDocumentationTemplates.length ===
              defaults.initial_templates[modelType].length
              ? []
              : defaults.initial_templates[modelType],
          );
          return;
        case 'validation_report':
          setselectedValidationReportTemplates(
            selectedValidationReportTemplates.length ===
              defaults.initial_templates[modelType].length
              ? []
              : defaults.initial_templates[modelType],
          );
          return;
        case 'monitoring':
          setselectedMonitoringTemplates(
            selectedMonitoringTemplates.length ===
              defaults.initial_templates[modelType].length
              ? []
              : defaults.initial_templates[modelType],
          );
          return;
      }
    }
  };

  const handleChangeTemplateValues = (
    modelType: keyof DefaultTemplate,
    templateIds: string[],
  ) => {
    if (defaults?.initial_templates) {
      const newTemplates = defaults.initial_templates[modelType].filter(
        template => templateIds.includes(template.id),
      );
      if (newTemplates) {
        switch (modelType) {
          case 'model_documentation':
            setselectedModelDocumentationTemplates(newTemplates);
            return;
          case 'validation_report':
            setselectedValidationReportTemplates(newTemplates);
            return;
          case 'monitoring':
            setselectedMonitoringTemplates(newTemplates);
            return;
        }
      }
    }
  };

  const handleCreateOrganization = () => {
    const initialTemplatesInput: DefaultTemplateConfig = {
      model_documentation: selectedModelDocumentationTemplates.map(
        template => template.id,
      ),
      validation_report: selectedValidationReportTemplates.map(
        template => template.id,
      ),
      monitoring: selectedMonitoringTemplates.map(template => template.id),
    };
    const organizationData: CreateOrganizationRequest = {
      name: newOrgName,
      demo_inventory_models: selectedModels.map(model => model.name),
      admin_user: {
        email: adminEmail,
        name: adminName,
        ...(authConfig.auth_create_user_enabled && { password: adminPassword }),
      },
      initial_templates: initialTemplatesInput,
      populate_demo_models: populateDemoModels,
    };

    createOrganizationMutation.mutate(organizationData);
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const email = e.target.value;
    setAdminEmail(email);

    if (!email) {
      setEmailError('Email is required');
    } else if (!isValidEmail(email)) {
      setEmailError('Please enter a valid email address');
    } else {
      setEmailError('');
    }
  };

  const isFormValid = () => {
    return (
      newOrgName.trim() &&
      adminName.trim() &&
      adminEmail.trim() &&
      isValidEmail(adminEmail) &&
      (!authConfig.auth_create_user_enabled || adminPassword.trim()) &&
      selectedModelDocumentationTemplates.length > 0 &&
      selectedValidationReportTemplates.length > 0 &&
      selectedMonitoringTemplates.length > 0
    );
  };

  const modalBodyContent = (
    <Stack spacing={4}>
      {error && (
        <Alert status="error">
          <AlertIcon />
          {error}
        </Alert>
      )}
      <FormControl>
        <FormLabel>Organization Name</FormLabel>
        <Input
          value={newOrgName}
          onChange={e => setNewOrgName(e.target.value)}
          placeholder="Enter organization name"
        />
      </FormControl>

      <Box borderWidth="1px" borderRadius="lg" p={4}>
        <Text fontWeight="bold" mb={2}>
          Admin User Details
        </Text>
        <Text fontSize="sm" color="gray.600" mb={4}>
          Note: If the admin email already exists, the name and password fields
          will be ignored.
        </Text>
        <Stack spacing={4}>
          <FormControl isRequired isInvalid={!!emailError}>
            <FormLabel>Email</FormLabel>
            <Input
              type="email"
              value={adminEmail}
              onChange={handleEmailChange}
              placeholder="Enter admin email"
            />
            {emailError && <FormErrorMessage>{emailError}</FormErrorMessage>}
          </FormControl>

          <FormControl isRequired>
            <FormLabel>Full Name</FormLabel>
            <Input
              value={adminName}
              onChange={e => setAdminName(e.target.value)}
              placeholder="Enter admin full name"
            />
          </FormControl>

          {authConfig.auth_create_user_enabled && (
            <FormControl isRequired>
              <FormLabel>Password</FormLabel>
              <InputGroup>
                <Input
                  type={showPassword ? 'text' : 'password'}
                  value={adminPassword}
                  onChange={e => setAdminPassword(e.target.value)}
                  placeholder="Enter password"
                />
                <InputRightElement width="4.5rem">
                  <Button
                    h="1.75rem"
                    size="sm"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    <Icon
                      as={showPassword ? EyeSlashIcon : EyeIcon}
                      w={4}
                      h={4}
                    />
                  </Button>
                </InputRightElement>
              </InputGroup>
              <Button
                size="sm"
                variant="link"
                mt={1}
                onClick={() => setAdminPassword(generateStrongPassword())}
              >
                Generate new password
              </Button>
            </FormControl>
          )}
        </Stack>
      </Box>

      <FormSelect
        label="Demo Models"
        selectedItems={selectedModels}
        options={
          defaults?.initial_demo_models?.map(template => ({
            value: template.name,
            label: template.name,
          })) || []
        }
        onChange={(newValues: string[]) => handleChangeModelValues(newValues)}
        onSelectAll={handleSelectAllModels}
        allSelected={
          selectedModels.length === defaults?.initial_demo_models?.length
        }
        isTemplate={false}
      >
        <HStack alignItems={'self-start'}>
          <Switch
            size={'md'}
            isChecked={populateDemoModels}
            isDisabled={selectedModels.length === 0}
            onChange={e => setPopulateDemoModels(e.target.checked)}
            colorScheme="brand"
            mt={1}
          />
          <Text>Populate demo models</Text>
        </HStack>
      </FormSelect>

      <FormSelect
        label="Demo Model Documentation Templates"
        selectedItems={selectedModelDocumentationTemplates}
        options={
          defaults?.initial_templates?.model_documentation?.map(template => ({
            value: template.id,
            label: template.name,
          })) || []
        }
        onChange={(newValues: string[]) =>
          handleChangeTemplateValues('model_documentation', newValues)
        }
        onSelectAll={() => handleSelectAllTemplates('model_documentation')}
        allSelected={
          selectedModelDocumentationTemplates.length ===
          defaults?.initial_templates?.model_documentation?.length
        }
        isTemplate={true}
      />
      <FormSelect
        label="Demo Validation Report Templates"
        selectedItems={selectedValidationReportTemplates}
        options={
          defaults?.initial_templates?.validation_report?.map(template => ({
            value: template.id,
            label: template.name,
          })) || []
        }
        onChange={(newValues: string[]) =>
          handleChangeTemplateValues('validation_report', newValues)
        }
        onSelectAll={() => handleSelectAllTemplates('validation_report')}
        allSelected={
          selectedValidationReportTemplates.length ===
          defaults?.initial_templates?.validation_report?.length
        }
        isTemplate={true}
      />
      <FormSelect
        label="Demo Monitoring Templates"
        selectedItems={selectedMonitoringTemplates}
        options={
          defaults?.initial_templates?.monitoring?.map(template => ({
            value: template.id,
            label: template.name,
          })) || []
        }
        onChange={(newValues: string[]) =>
          handleChangeTemplateValues('monitoring', newValues)
        }
        onSelectAll={() => handleSelectAllTemplates('monitoring')}
        allSelected={
          selectedMonitoringTemplates.length ===
          defaults?.initial_templates?.monitoring?.length
        }
        isTemplate={true}
      />
    </Stack>
  );

  return (
    <Box>
      <Flex justify="space-between" align="center" mb={6}>
        <Heading>Organizations</Heading>
        <Button
          variant="primary"
          leftIcon={<Icon as={PlusIcon} w={4} h={4} />}
          onClick={onOpen}
        >
          New Organization
        </Button>
      </Flex>

      <Text mb={4} color="gray.600">
        Total Organizations: {organizations?.length || 0}
      </Text>

      <InputGroup mb={4} maxW="md">
        <InputLeftElement pointerEvents="none">
          <Icon as={MagnifyingGlassIcon} color="gray.400" w={4} h={4} />
        </InputLeftElement>
        <Input
          placeholder="Search by name or CUID..."
          value={searchQuery}
          onChange={e => setSearchQuery(e.target.value)}
        />
      </InputGroup>

      <Modal isOpen={isOpen} onClose={onClose} size="2xl">
        <ModalOverlay />
        <ModalContent maxW="800px">
          <ModalHeader>Create New Organization</ModalHeader>
          <ModalCloseButton />
          <ModalBody>{modalBodyContent}</ModalBody>

          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={handleCreateOrganization}
              isDisabled={!isFormValid()}
              isLoading={createOrganizationMutation.isLoading}
            >
              Create
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <LoadingContainer isLoading={isLoading}>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th cursor="pointer" onClick={() => handleSort('name')}>
                Organization Name {getSortIcon('name')}
              </Th>
              <Th>CUID</Th>
              <Th cursor="pointer" onClick={() => handleSort('created_at')}>
                Created At {getSortIcon('created_at')}
              </Th>
              <Th cursor="pointer" onClick={() => handleSort('updated_at')}>
                Updated At {getSortIcon('updated_at')}
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {currentOrganizations?.map((org: Organization) => (
              <Tr key={org.cuid}>
                <Td>
                  <Link to={`/admin/organizations/${org.cuid}`}>
                    <Text
                      color="brand.500"
                      _hover={{ textDecoration: 'underline' }}
                    >
                      {org.name}
                    </Text>
                  </Link>
                </Td>
                <Td>{org.cuid}</Td>
                <Td>{displayFormattedDateAndTime(org.created_at)}</Td>
                <Td>{displayFormattedDateAndTime(org.updated_at)}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>

        {totalPages > 1 && (
          <HStack spacing={2} justify="center" mt={6}>
            <Button
              size="sm"
              onClick={() => handlePageChange(currentPage - 1)}
              isDisabled={currentPage === 1}
            >
              Previous
            </Button>
            <Text>
              Page {currentPage} of {totalPages}
            </Text>
            <Button
              size="sm"
              onClick={() => handlePageChange(currentPage + 1)}
              isDisabled={currentPage === totalPages}
            >
              Next
            </Button>
          </HStack>
        )}
      </LoadingContainer>
    </Box>
  );
}

export default Organizations;
