import { useEffect, useState } from 'react';
import {
  Button,
  Heading,
  HStack,
  Icon,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Skeleton,
  Stack,
  Text,
  List,
  ListItem,
  Box,
} from '@chakra-ui/react';
import { ArrowRightIcon, ChevronDownIcon } from '@heroicons/react/24/outline';
import JSONLogicHumanizer from '../../../JsonLogicHumanizer';
import {
  StatusFormNodeType,
  UserActionNodeType,
} from '../../../../pages/Settings/Workflows/components/types';
import {
  Execution,
  Workflow,
  WorkflowUserActionForm,
} from '../../../../models/workflow';
import { useWorkflow } from '../../contexts/workflow/WorkflowContext';
import {
  useNavigation,
  WorkflowManagerNavigationState,
} from '../../contexts/navigation/NavigationContext';
import { useUserAction } from '../../contexts/userAction/UserActionContext';
import { OnModalAction } from '../WorkflowActionButtons';
import { useQuery } from 'react-query';
import API from '../../../../api/API';

interface UserActionButtonsProps {
  workflow: Workflow;
  execution: Execution;
  actions?: WorkflowUserActionForm[];
  onAction?: OnModalAction;
}

function UserActionButtons({
  workflow,
  execution,
  actions: initialActions,
  onAction,
}: UserActionButtonsProps) {
  const { loadWorkflowExecution } = useWorkflow();
  const { navigate } = useNavigation();
  const { setCurrentAction } = useUserAction();
  const [actions, setActions] = useState<WorkflowUserActionForm[]>();

  // Fetch available user actions for the execution
  const { data: fetchedActions, isLoading } = useQuery(
    [
      'targets',
      execution.target.cuid,
      'executions',
      execution?.cuid,
      'user-actions',
    ],
    async () =>
      API.GetWorkflowExecutionUserActions(workflow.cuid!, execution?.cuid!),
    {
      enabled: !initialActions,
    },
  );

  useEffect(() => {
    if (!initialActions && fetchedActions) {
      setActions(fetchedActions);
    }
  }, [initialActions, fetchedActions]);

  const handleActionClick = async (action: WorkflowUserActionForm) => {
    if (onAction) {
      onAction({
        workflowCuid: workflow.cuid,
        executionCuid: execution.cuid,
        navigation: WorkflowManagerNavigationState.DETAIL_ACTION_USER_ACTION,
        userAction: action,
      });
    } else {
      await loadWorkflowExecution(workflow.cuid, execution.cuid);
      setCurrentAction(action);
      navigate(WorkflowManagerNavigationState.DETAIL_ACTION_USER_ACTION, {
        execution,
        userAction: action,
      });
    }
  };

  // Track actions that don't meet conditions
  const disabledActionsNodeSettings: (
    | UserActionNodeType
    | StatusFormNodeType
  )[] = [];

  // Enrich actions with additional data from pending user actions
  const enrichedActions = (execution.pending_user_actions || []).map(
    pendingAction => {
      const foundAction = (actions || []).find(
        action => action.trigger === pendingAction.id,
      );

      if (!foundAction) {
        disabledActionsNodeSettings.push(pendingAction);
      }

      return {
        trigger: pendingAction.id,
        buttonLabel: pendingAction.data.form.buttonLabel,
        required_fields: pendingAction.data.form.required_fields,
        disabled: !foundAction,
      };
    },
  );

  // Return null if no pending actions
  if ((execution.pending_user_actions || []).length === 0) {
    return null;
  }

  // Render single button or menu based on number of actions
  const renderActions = () => {
    if (enrichedActions.length === 1) {
      return (
        <Button
          onClick={() => handleActionClick(enrichedActions[0])}
          key={enrichedActions[0].trigger}
          aria-label={enrichedActions[0].trigger}
          leftIcon={<Icon as={ArrowRightIcon} boxSize={4} />}
          variant="outline"
          isDisabled={enrichedActions[0].disabled}
        >
          {enrichedActions[0].buttonLabel}
        </Button>
      );
    }

    return (
      <Menu>
        <MenuButton
          as={Button}
          variant="outline"
          rightIcon={<Icon as={ChevronDownIcon} boxSize={4} />}
        >
          Choose Action
        </MenuButton>
        <MenuList shadow="xl">
          {enrichedActions.map(action => (
            <MenuItem
              key={action.trigger}
              onClick={() => handleActionClick(action)}
              icon={<Icon as={ArrowRightIcon} boxSize={4} />}
              isDisabled={action.disabled}
            >
              {action.buttonLabel}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    );
  };

  return (
    <Skeleton isLoaded={!isLoading}>
      <Stack>
        {renderActions()}

        {/* Show disabled actions info */}
        {disabledActionsNodeSettings.length > 0 && (
          <HStack justifyContent="space-between" alignItems={'flex-start'}>
            <Popover placement="bottom-end">
              <PopoverTrigger>
                <Link fontSize="small" fontWeight={'normal'}>
                  <Text>
                    {disabledActionsNodeSettings.length ===
                    enrichedActions.length
                      ? 'No actions available for you.'
                      : 'Some actions are not available for you.'}
                  </Text>
                </Link>
              </PopoverTrigger>
              <PopoverContent>
                <PopoverHeader>
                  <Heading as={'h5'}>Conditions not met</Heading>
                </PopoverHeader>
                <PopoverArrow />
                <PopoverCloseButton />
                <PopoverBody>
                  <Stack spacing={4}>
                    <Text>
                      To access these actions, you must meet the required
                      conditions.
                    </Text>
                    {disabledActionsNodeSettings.map(setting => (
                      <Box key={setting.id}>
                        <Text fontWeight="bold" mb={1}>
                          {setting.data.form.buttonLabel}:
                        </Text>
                        <List spacing={1} pl={4} styleType="disc">
                          <ListItem>
                            <JSONLogicHumanizer
                              logic={
                                setting.data.transition_callbacks.conditions[0]
                                  .args.query
                              }
                            />
                          </ListItem>
                        </List>
                      </Box>
                    ))}
                  </Stack>
                </PopoverBody>
              </PopoverContent>
            </Popover>
          </HStack>
        )}
      </Stack>
    </Skeleton>
  );
}

export default UserActionButtons;
