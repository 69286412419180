import {
  Divider,
  FormControl,
  FormHelperText,
  Heading,
  HStack,
  Input,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Stack,
  Text,
  Textarea,
  VStack,
} from '@chakra-ui/react';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import { UsersContext } from '../../../../../../contexts';
import useWorkflow from '../../../../../../hooks/useWorkflow';
import { Label } from '../../../../../../components/Layout';
import { useOrgRoles } from '../../../../../../hooks/useOrgRoles';
import { ApprovalNodeType } from '../../types';
import QueryBuilder, { Field, RuleGroupType } from 'react-querybuilder';
import useQueryBuilder, {
  FIELD_IDENTIFIERS,
} from '../../../hooks/useQueryBuilder';
import { formatQuery } from 'react-querybuilder/formatQuery';
import { parseJsonLogic } from 'react-querybuilder/parseJsonLogic';
import { Edge } from 'reactflow';
import {
  controlElements,
  getQueryBuilderButton,
} from '../BranchPanel/BranchItem';
import BasePanel from '../BasePanel';

interface ApprovalPanelProps {
  node: ApprovalNodeType;
  onAddNode: (node: ApprovalNodeType) => void;
  onDeleteNode: (node: ApprovalNodeType) => void;
  onAddEdge?: (edge: Edge) => void;
}

const ApprovalPanel = ({
  node,
  onAddNode,
  onDeleteNode,
}: ApprovalPanelProps) => {
  const { currentOrganization } = useContext(UsersContext);
  const { setSelectedNodeId } = useWorkflow();
  const [tempNode, setTempNode] = useState<ApprovalNodeType>(node);
  const { orgRoles } = useOrgRoles();
  const { query, setQuery } = useQueryBuilder();

  useEffect(() => {
    setTempNode(node);
    if (node && tempNode.data.state_callbacks.on_enter[0].args.voters) {
      setQuery(
        parseJsonLogic(
          tempNode.data.state_callbacks.on_enter[0].args.voters,
        ) as RuleGroupType,
      );
    }
  }, [node]);

  const votersQueryFields = useMemo(() => {
    let fields: Field[] = [
      {
        name: FIELD_IDENTIFIERS.USER_ROLES,
        label: 'User Roles',
        operators: [{ name: '=', value: '=', label: 'is' } as const],
        valueEditorType: 'select',
        values: orgRoles
          .filter(role => role.scope !== 'Model')
          .map(role => ({
            name: role.cuid,
            label: role.name,
          })),
      },
      {
        name: FIELD_IDENTIFIERS.USER_MODEL_STAKEHOLDERS,
        label: 'Model Stakeholder Roles',
        operators: [{ name: '=', value: '=', label: 'is' } as const],
        valueEditorType: 'select',
        values: orgRoles
          .filter(role => role.scope === 'Model')
          .map(role => ({
            name: role.cuid,
            label: role.name,
          })),
      },
    ];

    const customFields =
      currentOrganization?.inventory_model_schema.custom.settings.properties ||
      {};
    const userEntityKeys = Object.keys(customFields).filter(
      key => customFields[key as keyof object].entity === 'User',
    );

    fields.push({
      name: 'model.custom_fields',
      label: 'Model Inventory Fields',
      operators: [{ name: '=', value: '=', label: 'is' } as const],
      valueEditorType: 'select',
      values: userEntityKeys.map(key => ({
        name: key,
        label: customFields[key as keyof object].label,
      })),
    });

    return fields;
  }, [currentOrganization, orgRoles]);

  useEffect(() => {
    setTempNode(node);
  }, [node]);

  const onThresholdChange = (val: number) => {
    if (tempNode) {
      let clonedNode = _.cloneDeep(tempNode);
      clonedNode.data.state_callbacks.on_enter[0].args.vote_threshold = val;
      setTempNode(clonedNode);
    }
  };

  const onTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (tempNode) {
      let clonedNode = _.cloneDeep(tempNode);
      clonedNode.data.state_callbacks.on_enter[0].args.title = e.target.value;
      setTempNode(clonedNode);
    }
  };

  const onMessageChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (tempNode) {
      let clonedNode = _.cloneDeep(tempNode);
      clonedNode.data.state_callbacks.on_enter[0].args.message = e.target.value;
      setTempNode(clonedNode);
    }
  };

  const onSave = () => {
    if (tempNode) {
      const votersQuery = formatQuery(query, 'jsonlogic');
      tempNode.data.state_callbacks.on_enter[0].args.voters = votersQuery;

      onAddNode(tempNode);
      setSelectedNodeId!();
    }
  };

  return (
    <BasePanel
      title={'Configure Approval Step'}
      node={tempNode}
      onDeleteNode={onDeleteNode}
      onSave={onSave}
    >
      <FormControl>
        <Label mb={2}>Approval Group</Label>

        <Stack>
          <QueryBuilder
            fields={votersQueryFields}
            query={query}
            onQueryChange={setQuery}
            controlElements={{
              ...controlElements,

              addGroupAction: () => null,
              combinatorSelector: () => null,
              addRuleAction: props =>
                getQueryBuilderButton({
                  ...props,
                  disabled: (props.rules?.length || 0) > 0,
                }),
            }}
          />
        </Stack>
      </FormControl>

      <FormControl>
        <Label mb={2}>Threshold</Label>
        <HStack gap={4}>
          <Slider
            value={
              tempNode?.data.state_callbacks.on_enter[0].args.vote_threshold
            }
            aria-label="slider-ex-6"
            onChange={val => onThresholdChange(val)}
            step={10}
            // TODO: We can calculate this value depending on the number of users in the group.
            colorScheme="brand"
          >
            <SliderTrack>
              <SliderFilledTrack />
            </SliderTrack>

            <SliderThumb />
          </Slider>
          <Text>
            {tempNode?.data.state_callbacks.on_enter[0].args.vote_threshold}%
          </Text>
        </HStack>

        <FormHelperText fontSize={'sm'}>
          Percentage of approvers required for resource to be approved.
        </FormHelperText>
      </FormControl>
      <Divider />
      <VStack alignItems={'flex-start'}>
        <Heading as={'h4'}>Approval Message</Heading>
        <Text fontSize={'sm'} maxW={'prose'}>
          When your resource reaches this approval step, the users in the
          selected group will receive the following message.
        </Text>
      </VStack>
      <FormControl>
        <Label mb={2}>Title</Label>
        <Input
          bg={'white'}
          size="sm"
          placeholder="Title"
          onChange={onTitleChange}
          value={tempNode?.data.state_callbacks.on_enter[0].args.title}
        />
      </FormControl>

      <FormControl>
        <Label mb={2}>Message</Label>

        <Textarea
          bg={'white'}
          placeholder="Message"
          rounded={'sm'}
          onChange={onMessageChange}
          value={tempNode?.data.state_callbacks.on_enter[0].args.message}
        />
      </FormControl>
    </BasePanel>
  );
};

export default ApprovalPanel;
