import {
  Box,
  Heading,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Card,
  CardBody,
  Switch,
  Flex,
  FormControl,
  GridItem,
  Text,
  Button,
  Input,
  VisuallyHidden,
  SimpleGrid,
  Center,
  useColorModeValue,
  Stack,
  DarkMode,
  LightMode,
  Textarea,
  HStack,
} from '@chakra-ui/react';
import { useState, useEffect, useRef } from 'react';
import { useToast } from '@chakra-ui/react';
import { useQueryClient } from 'react-query';
import AdminAPI from '../../api/AdminAPI';
import AdminUsers from './AdminUsers';
import useCustomLogo from '../../hooks/useCustomLogo';
import ValidMindLogo from '../../components/ValidMindLogo';
import { Icon } from '@chakra-ui/icons';
import ValidMindVLogo from '../../components/ValidMindVLogo';
import {
  ArrowUturnLeftIcon,
  CloudArrowUpIcon,
  ArrowDownTrayIcon,
  PlusIcon,
} from '@heroicons/react/24/outline';
import { saveAs } from 'file-saver-es';

const Settings = () => {
  // Separate refs for each logo type
  const lightFullLogoInputRef = useRef<HTMLInputElement>(null);
  const darkFullLogoInputRef = useRef<HTMLInputElement>(null);
  const lightMarkLogoInputRef = useRef<HTMLInputElement>(null);
  const darkMarkLogoInputRef = useRef<HTMLInputElement>(null);
  const templateUploadRef = useRef<HTMLInputElement>(null);
  const validationTemplateUploadRef = useRef<HTMLInputElement>(null);
  const [allowUserOnboarding, setAllowUserOnboarding] = useState(false);
  const [syncOidcRoles, setSyncOidcRoles] = useState(false);
  const [idpUserCreationEnabled, setIdpUserCreationEnabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [documentationDisclaimer, setDocumentationDisclaimer] = useState('');
  const [initialDisclaimer, setInitialDisclaimer] = useState('');
  const [documentationFooter, setDocumentationFooter] = useState('');
  const [initialFooter, setInitialFooter] = useState('');
  const { logos } = useCustomLogo();
  const toast = useToast();
  const [downloading, setDownloading] = useState(false);
  const [downloadingType, setDownloadingType] = useState<string | null>(null);

  const queryClient = useQueryClient(); // Load initial settings
  useEffect(() => {
    const loadSettings = async () => {
      try {
        setIsLoading(true);
        const response = await AdminAPI.GetGeneralSettings();
        setAllowUserOnboarding(response.allow_user_onboarding);
        setSyncOidcRoles(response.sync_oidc_roles);
        setIdpUserCreationEnabled(response.idp_user_creation_enabled);
        const disclaimerText =
          response.custom_texts?.documentation_export_disclaimer || '';
        setDocumentationDisclaimer(disclaimerText);
        setInitialDisclaimer(disclaimerText);
        const footerText = response.custom_texts?.doc_export_footer || '';
        setDocumentationFooter(footerText);
        setInitialFooter(footerText);
      } catch (error) {
        toast({
          variant: 'subtle',
          title: 'Error loading settings',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      } finally {
        setIsLoading(false);
      }
    };

    loadSettings();
  }, [toast]);

  const handleSettingChange = async (setting: string, value: boolean) => {
    try {
      setIsLoading(true);
      const response = await AdminAPI.UpdateGeneralSettings({
        [setting]: value,
      });

      // Update local state with the response from the server
      setAllowUserOnboarding(response.allow_user_onboarding);
      setSyncOidcRoles(response.sync_oidc_roles);
      setIdpUserCreationEnabled(response.idp_user_creation_enabled);

      toast({
        variant: 'subtle',
        title: 'Settings updated successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        variant: 'subtle',
        title: 'Error updating settings',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      // Revert the toggle if the API call fails
      if (setting === 'allow_user_onboarding') {
        setAllowUserOnboarding(!value);
      } else if (setting === 'sync_oidc_roles') {
        setSyncOidcRoles(!value);
      } else if (setting === 'idp_user_creation_enabled') {
        setIdpUserCreationEnabled(!value);
      }
    } finally {
      setIsLoading(false);
    }
  };
  const handleLogoUpload = async (
    event: React.ChangeEvent<HTMLInputElement>,
    logoType: 'light-full' | 'dark-full' | 'light-mark' | 'dark-mark',
  ) => {
    const file = event.target.files?.[0];
    if (!file) return;

    try {
      setIsLoading(true);
      const formData = new FormData();
      formData.append('logo', file);

      // Upload the logo first
      const { url } = await AdminAPI.UploadLogo(formData);

      // Then immediately update settings with the new URL based on logo type
      const settingsUpdate = {
        custom_logo_light_full: logoType === 'light-full' ? url : undefined,
        custom_logo_dark_full: logoType === 'dark-full' ? url : undefined,
        custom_logo_light_mark: logoType === 'light-mark' ? url : undefined,
        custom_logo_dark_mark: logoType === 'dark-mark' ? url : undefined,
      };

      await AdminAPI.UpdateGeneralSettings(settingsUpdate);

      // Invalidate queries to refresh the UI
      await queryClient.invalidateQueries(['settings']);
      await queryClient.invalidateQueries(['settings', 'logo']);

      toast({
        variant: 'subtle',
        title: 'Logo updated successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        variant: 'subtle',
        title: 'Error updating logo',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  // Separate handlers for each logo type
  const handleLightFullLogoButtonClick = () => {
    lightFullLogoInputRef.current?.click();
  };

  const handleDarkFullLogoButtonClick = () => {
    darkFullLogoInputRef.current?.click();
  };

  const handleLightMarkLogoButtonClick = () => {
    lightMarkLogoInputRef.current?.click();
  };

  const handleDarkMarkLogoButtonClick = () => {
    darkMarkLogoInputRef.current?.click();
  };

  const handleRestoreDefault = async (
    logoType: 'light-full' | 'dark-full' | 'light-mark' | 'dark-mark',
  ) => {
    try {
      setIsLoading(true);
      const settingsUpdate = {
        custom_logo_light_full: logoType === 'light-full' ? '' : undefined,
        custom_logo_dark_full: logoType === 'dark-full' ? '' : undefined,
        custom_logo_light_mark: logoType === 'light-mark' ? '' : undefined,
        custom_logo_dark_mark: logoType === 'dark-mark' ? '' : undefined,
      };

      await AdminAPI.UpdateGeneralSettings(settingsUpdate);

      // Invalidate the logo query to trigger a refresh
      await queryClient.invalidateQueries(['settings', 'logo']);

      toast({
        variant: 'subtle',
        title: 'Logo restored to default',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        variant: 'subtle',
        title: 'Error restoring default logo',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  // Individual handlers for disclaimer text
  const handleSaveDisclaimer = async () => {
    try {
      setIsLoading(true);
      await AdminAPI.UpdateGeneralSettings({
        custom_texts: {
          documentation_export_disclaimer: documentationDisclaimer,
          // Preserve existing footer value
          doc_export_footer: initialFooter,
        },
      });

      // Update initial value after successful save
      setInitialDisclaimer(documentationDisclaimer);

      toast({
        variant: 'subtle',
        title: 'Disclaimer text updated successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        variant: 'subtle',
        title: 'Error updating disclaimer text',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleClearDisclaimer = async () => {
    try {
      setIsLoading(true);
      await AdminAPI.UpdateGeneralSettings({
        custom_texts: {
          documentation_export_disclaimer: '',
          // Preserve existing footer value
          doc_export_footer: initialFooter,
        },
      });

      // Update local state after successful API call
      setDocumentationDisclaimer('');
      setInitialDisclaimer('');

      toast({
        variant: 'subtle',
        title: 'Disclaimer text cleared successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        variant: 'subtle',
        title: 'Error clearing disclaimer text',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  // New individual handlers for footer text
  const handleSaveFooter = async () => {
    try {
      setIsLoading(true);
      await AdminAPI.UpdateGeneralSettings({
        custom_texts: {
          // Preserve existing disclaimer value
          documentation_export_disclaimer: initialDisclaimer,
          doc_export_footer: documentationFooter,
        },
      });

      // Update initial value after successful save
      setInitialFooter(documentationFooter);

      toast({
        variant: 'subtle',
        title: 'Footer text updated successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        variant: 'subtle',
        title: 'Error updating footer text',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleClearFooter = async () => {
    try {
      setIsLoading(true);
      await AdminAPI.UpdateGeneralSettings({
        custom_texts: {
          // Preserve existing disclaimer value
          documentation_export_disclaimer: initialDisclaimer,
          doc_export_footer: '',
        },
      });

      // Update local state after successful API call
      setDocumentationFooter('');
      setInitialFooter('');

      toast({
        variant: 'subtle',
        title: 'Footer text cleared successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        variant: 'subtle',
        title: 'Error clearing footer text',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleDownloadTemplate = async (documentType: string) => {
    try {
      setDownloading(true);
      setDownloadingType(documentType);
      const templateBlob = await AdminAPI.GetReportingTemplate(documentType);
      const fileName = `${documentType}_template.docx`;
      saveAs(templateBlob, fileName);
    } catch (error) {
      toast({
        variant: 'subtle',
        title: 'Error downloading template',
        description: `Failed to download ${documentType} template`,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setDownloading(false);
      setDownloadingType(null);
    }
  };

  const handleTemplateUpload = async (event: React.ChangeEvent<HTMLInputElement>, documentType: string) => {
    const file = event.target.files?.[0];
    if (!file) return;

    try {
      setIsLoading(true);
      await AdminAPI.UploadReportingTemplate(documentType, file);

      toast({
        variant: 'subtle',
        title: 'Template uploaded successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        variant: 'subtle',
        title: 'Error uploading template',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleTemplateUploadButtonClick = () => {
    templateUploadRef.current?.click();
  };

  const handleValidationTemplateUploadButtonClick = () => {
    validationTemplateUploadRef.current?.click();
  };

  return (
    <Box>
      <Heading mb={6}>Settings</Heading>

      <Tabs colorScheme="brand" size={'lg'}>
        <TabList borderBottomWidth="1px" mb={6}>
          <Tab>General</Tab>
          <Tab>Admin Users</Tab>
        </TabList>

        <TabPanels>
          <TabPanel p={0}>
            <Card variant="outline" mb={6}>
              <CardBody>
                <Box mb={4}>
                  <Heading as={'h2'}>Branding</Heading>
                </Box>

                <SimpleGrid columns={2} gap={4}>
                  <GridItem
                    border={
                      '1px solid var(--chakra-colors-chakra-border-color)'
                    }
                    rounded={'md'}
                    p={4}
                  >
                    <Stack gap={8}>
                      <Stack>
                        <Heading as={'h3'}>Logo</Heading>
                        <Text>
                          This logo appears when the side navigation is
                          expanded.
                        </Text>
                      </Stack>
                      <Box
                        rounded={'md'}
                        bg={useColorModeValue('neutral.25', 'neutral.950')}
                        border={
                          '1px solid var(--chakra-colors-chakra-border-color)'
                        }
                        overflow={'hidden'}
                      >
                        <Stack direction={'row'}>
                          <Box w={'full'} p={4} bg={'neutral.25'}>
                            <LightMode>
                              <Heading as={'h5'} color={'neutral.1000'}>
                                Light mode
                              </Heading>
                              <Center py={12} px={6}>
                                <ValidMindLogo showPartnerLogo={true} />
                              </Center>
                              <FormControl>
                                <VisuallyHidden>
                                  <Input
                                    type="file"
                                    accept="image/*"
                                    onChange={e =>
                                      handleLogoUpload(e, 'light-full')
                                    }
                                    disabled={isLoading}
                                    ref={lightFullLogoInputRef}
                                  />
                                </VisuallyHidden>
                                <Stack
                                  direction={'row'}
                                  justifyContent={
                                    !logos.lightFull
                                      ? 'flex-end'
                                      : 'space-between'
                                  }
                                >
                                  <Button
                                    size={'sm'}
                                    onClick={() =>
                                      handleRestoreDefault('light-full')
                                    }
                                    variant="ghost"
                                    isLoading={isLoading}
                                    hidden={!logos.lightFull}
                                    leftIcon={
                                      <Icon
                                        as={ArrowUturnLeftIcon}
                                        boxSize={6}
                                      />
                                    }
                                  >
                                    Restore
                                  </Button>

                                  <Button
                                    size={'sm'}
                                    onClick={handleLightFullLogoButtonClick}
                                    colorScheme="brand"
                                    isLoading={isLoading}
                                    leftIcon={
                                      <Icon as={CloudArrowUpIcon} boxSize={6} />
                                    }
                                  >
                                    Update
                                  </Button>
                                </Stack>
                              </FormControl>
                            </LightMode>
                          </Box>
                          <Box w={'full'} bg={'neutral.1000'} p={4}>
                            <DarkMode>
                              <Heading as={'h4'} color={'neutral.25'}>
                                Dark mode
                              </Heading>
                              <Center py={12} px={6}>
                                <ValidMindLogo showPartnerLogo={true} />
                              </Center>
                              <FormControl>
                                <VisuallyHidden>
                                  <Input
                                    type="file"
                                    accept="image/*"
                                    onChange={e =>
                                      handleLogoUpload(e, 'dark-full')
                                    }
                                    disabled={isLoading}
                                    ref={darkFullLogoInputRef}
                                  />
                                </VisuallyHidden>

                                <Stack
                                  direction={'row'}
                                  justifyContent={
                                    !logos.darkFull
                                      ? 'flex-end'
                                      : 'space-between'
                                  }
                                >
                                  <Button
                                    size={'sm'}
                                    onClick={() =>
                                      handleRestoreDefault('dark-full')
                                    }
                                    variant="ghost"
                                    isLoading={isLoading}
                                    hidden={!logos.darkFull}
                                    leftIcon={
                                      <Icon
                                        as={ArrowUturnLeftIcon}
                                        boxSize={6}
                                      />
                                    }
                                  >
                                    Restore
                                  </Button>

                                  <Button
                                    size={'sm'}
                                    onClick={handleDarkFullLogoButtonClick}
                                    colorScheme="brand"
                                    isLoading={isLoading}
                                    leftIcon={
                                      <Icon as={CloudArrowUpIcon} boxSize={6} />
                                    }
                                  >
                                    Update
                                  </Button>
                                </Stack>
                              </FormControl>
                            </DarkMode>
                          </Box>
                        </Stack>
                      </Box>
                      <Box textAlign={'center'}>
                        <Text>
                          Recommended size for image. Width: 150px by Height:
                          26px.
                        </Text>
                      </Box>
                    </Stack>
                  </GridItem>
                  <GridItem
                    border={
                      '1px solid var(--chakra-colors-chakra-border-color)'
                    }
                    rounded={'md'}
                    p={4}
                  >
                    <Stack gap={8}>
                      <Stack>
                        <Heading as={'h3'}>Logo Mark</Heading>
                        <Text>
                          This appears when the side navigation is collapsed.
                        </Text>
                      </Stack>
                      <Box
                        rounded={'md'}
                        bg={useColorModeValue('neutral.25', 'neutral.950')}
                        border={
                          '1px solid var(--chakra-colors-chakra-border-color)'
                        }
                        overflow={'hidden'}
                      >
                        <Stack direction={'row'}>
                          <Box w={'full'} p={4} bg={'neutral.25'}>
                            <LightMode>
                              <Heading as={'h5'} color={'neutral.1000'}>
                                Light mode
                              </Heading>
                              <Center py={12} px={6}>
                                <ValidMindVLogo showPartnerLogo={true} />
                              </Center>
                              <FormControl>
                                <VisuallyHidden>
                                  <Input
                                    type="file"
                                    accept="image/*"
                                    onChange={e =>
                                      handleLogoUpload(e, 'light-mark')
                                    }
                                    disabled={isLoading}
                                    ref={lightMarkLogoInputRef}
                                  />
                                </VisuallyHidden>

                                <Stack
                                  direction={'row'}
                                  justifyContent={
                                    !logos.lightMark
                                      ? 'flex-end'
                                      : 'space-between'
                                  }
                                >
                                  <Button
                                    size={'sm'}
                                    onClick={() =>
                                      handleRestoreDefault('light-mark')
                                    }
                                    variant="ghost"
                                    isLoading={isLoading}
                                    hidden={!logos.lightMark}
                                    leftIcon={
                                      <Icon
                                        as={ArrowUturnLeftIcon}
                                        boxSize={6}
                                      />
                                    }
                                  >
                                    Restore
                                  </Button>

                                  <Button
                                    size={'sm'}
                                    onClick={handleLightMarkLogoButtonClick}
                                    colorScheme="brand"
                                    isLoading={isLoading}
                                    leftIcon={
                                      <Icon as={CloudArrowUpIcon} boxSize={6} />
                                    }
                                  >
                                    Update
                                  </Button>
                                </Stack>
                              </FormControl>
                            </LightMode>
                          </Box>
                          <Box w={'full'} bg={'neutral.1000'} p={4}>
                            <DarkMode>
                              <Heading as={'h4'} color={'neutral.25'}>
                                Dark mode
                              </Heading>
                              <Center py={12} px={6}>
                                <ValidMindVLogo showPartnerLogo={true} />
                              </Center>
                              <FormControl>
                                <VisuallyHidden>
                                  <Input
                                    type="file"
                                    accept="image/*"
                                    onChange={e =>
                                      handleLogoUpload(e, 'dark-mark')
                                    }
                                    disabled={isLoading}
                                    ref={darkMarkLogoInputRef}
                                  />
                                </VisuallyHidden>

                                <Stack
                                  direction={'row'}
                                  justifyContent={
                                    !logos.darkMark
                                      ? 'flex-end'
                                      : 'space-between'
                                  }
                                >
                                  <Button
                                    size={'sm'}
                                    onClick={() =>
                                      handleRestoreDefault('dark-mark')
                                    }
                                    variant="ghost"
                                    isLoading={isLoading}
                                    hidden={!logos.darkMark}
                                    leftIcon={
                                      <Icon
                                        as={ArrowUturnLeftIcon}
                                        boxSize={6}
                                      />
                                    }
                                  >
                                    Restore
                                  </Button>

                                  <Button
                                    size={'sm'}
                                    onClick={handleDarkMarkLogoButtonClick}
                                    colorScheme="brand"
                                    isLoading={isLoading}
                                    leftIcon={
                                      <Icon as={CloudArrowUpIcon} boxSize={6} />
                                    }
                                  >
                                    Update
                                  </Button>
                                </Stack>
                              </FormControl>
                            </DarkMode>
                          </Box>
                        </Stack>
                      </Box>
                      <Box textAlign={'center'}>
                        <Text>
                          Recommended size for image. Width: 30px by Height:
                          30px.
                        </Text>
                      </Box>
                    </Stack>

                    {/* <Stack gap={8}>
                    <Stack>
                    <Heading as={'h3'}>Logo Collapsed</Heading>
                    <Text>
                    This logo appears when the side navigation is
                    collapsed.
                    </Text>
                    </Stack>
                    <Box
                    rounded={'sm'}
                    p={8}
                    bg={useColorModeValue('neutral.25', 'neutral.950')}
                    border={
                    '1px solid var(--chakra-colors-chakra-border-color)'
                    }
                    >
                     <Center>
                     <ValidMindVLogo />
                     </Center>
                     </Box>
                     </Stack> */}
                  </GridItem>
                </SimpleGrid>
                <Box textAlign={'center'} mt={4}>
                  <Text>Supported file types: png, jpg, jpeg and gif.</Text>
                </Box>
              </CardBody>
            </Card>

            <Card variant="outline" mb={6}>
              <CardBody>
                <Box mb={4}>
                  <Heading as={'h2'}>Documentation Export</Heading>
                </Box>

                <Stack spacing={4}>
                  <Box>
                    <Heading as={'h3'} size="md" mb={2}>
                      Documentation Report Export Template
                    </Heading>
                    <Text mb={4}>
                      Configure the template used for exporting documentation reports. This template defines the structure and format of exported reports.
                    </Text>
                    <HStack spacing={4} mb={6}>
                      <Button
                        leftIcon={<Icon as={ArrowDownTrayIcon} w={4} h={4} />}
                        variant="tertiary"
                        onClick={() => handleDownloadTemplate('documentation_report')}
                        isLoading={downloading && downloadingType === 'documentation_report'}
                      >
                        Download Template
                      </Button>
                      <Button
                        leftIcon={<Icon as={PlusIcon} w={4} h={4} />}
                        variant="primary"
                        onClick={handleTemplateUploadButtonClick}
                        isLoading={isLoading}
                      >
                        Upload Template
                      </Button>
                      <VisuallyHidden>
                        <Input
                          type="file"
                          accept=".docx"
                          onChange={(e) => handleTemplateUpload(e, 'documentation_report')}
                          disabled={isLoading}
                          ref={templateUploadRef}
                        />
                      </VisuallyHidden>
                    </HStack>
                  </Box>

                  <Box>
                    <Heading as={'h3'} size="md" mb={2}>
                      Validation Report Export Template
                    </Heading>
                    <Text mb={4}>
                      Configure the template used for exporting validation reports. This template defines the structure and format of exported validation reports.
                    </Text>
                    <HStack spacing={4} mb={6}>
                      <Button
                        leftIcon={<Icon as={ArrowDownTrayIcon} w={4} h={4} />}
                        variant="tertiary"
                        onClick={() => handleDownloadTemplate('validation_report')}
                        isLoading={downloading && downloadingType === 'validation_report'}
                      >
                        Download Template
                      </Button>
                      <Button
                        leftIcon={<Icon as={PlusIcon} w={4} h={4} />}
                        variant="primary"
                        onClick={handleValidationTemplateUploadButtonClick}
                        isLoading={isLoading}
                      >
                        Upload Template
                      </Button>
                      <VisuallyHidden>
                        <Input
                          type="file"
                          accept=".docx"
                          onChange={(e) => handleTemplateUpload(e, 'validation_report')}
                          disabled={isLoading}
                          ref={validationTemplateUploadRef}
                        />
                      </VisuallyHidden>
                    </HStack>
                  </Box>

                  <Box>
                    <Heading as={'h3'} size="md" mb={2}>
                      Documentation export disclaimer
                    </Heading>
                    <Text mb={4}>
                      This text will appear as a disclaimer when users export
                      documentation.
                    </Text>
                    <FormControl>
                      <Textarea
                        value={documentationDisclaimer}
                        onChange={e =>
                          setDocumentationDisclaimer(e.target.value)
                        }
                        placeholder="Enter disclaimer text here..."
                        resize="vertical"
                        rows={5}
                        disabled={isLoading}
                      />
                    </FormControl>
                    <Flex justifyContent="flex-end" mt={4} gap={2}>
                      <Button
                        onClick={handleClearDisclaimer}
                        variant="ghost"
                        isLoading={isLoading}
                        isDisabled={!initialDisclaimer}
                      >
                        Clear
                      </Button>
                      <Button
                        onClick={handleSaveDisclaimer}
                        colorScheme="brand"
                        isLoading={isLoading}
                        isDisabled={
                          documentationDisclaimer === initialDisclaimer
                        }
                      >
                        Save
                      </Button>
                    </Flex>
                  </Box>

                  <Box>
                    <Heading as={'h3'} size="md" mb={2}>
                      Documentation export template footer text
                    </Heading>
                    <Text mb={4}>
                      This text will appear as a footer in exported Word
                      documents. Defaults to "Generated by ValidMind, Inc" if
                      not specified.
                    </Text>
                    <FormControl>
                      <Textarea
                        value={documentationFooter}
                        onChange={e => setDocumentationFooter(e.target.value)}
                        placeholder="Enter footer text here..."
                        resize="vertical"
                        rows={3}
                        disabled={isLoading}
                      />
                    </FormControl>
                    <Flex justifyContent="flex-end" mt={4} gap={2}>
                      <Button
                        onClick={handleClearFooter}
                        variant="ghost"
                        isLoading={isLoading}
                        isDisabled={!initialFooter}
                      >
                        Clear
                      </Button>
                      <Button
                        onClick={handleSaveFooter}
                        colorScheme="brand"
                        isLoading={isLoading}
                        isDisabled={documentationFooter === initialFooter}
                      >
                        Save
                      </Button>
                    </Flex>
                  </Box>
                </Stack>
              </CardBody>
            </Card>
            <Card variant="outline">
              <CardBody>
                <Box mb={4}>
                  <Heading size="md">Onboarding</Heading>
                </Box>
                <Flex align="center" gap={3}>
                  <Switch
                    id="user-onboarding"
                    colorScheme="brand"
                    size="md"
                    isChecked={allowUserOnboarding}
                    isDisabled={isLoading}
                    onChange={e =>
                      handleSettingChange(
                        'allow_user_onboarding',
                        e.target.checked,
                      )
                    }
                  />
                  <Box>
                    <Box fontSize="md" fontWeight="medium">
                      Enable user self-onboarding
                    </Box>
                    <Box fontSize="md" color="gray.500">
                      When enabled, users can self-register and create their own
                      organization.
                    </Box>
                  </Box>
                </Flex>
              </CardBody>
            </Card>

            <Card variant="outline">
              <CardBody>
                <Box mb={4}>
                  <Heading size="md">OIDC</Heading>
                </Box>
                <Flex align="center" gap={3}>
                  <Switch
                    id="sync-oidc-roles"
                    colorScheme="brand"
                    size="md"
                    isChecked={syncOidcRoles}
                    isDisabled={isLoading}
                    onChange={e =>
                      handleSettingChange('sync_oidc_roles', e.target.checked)
                    }
                  />
                  <Box>
                    <Box fontSize="md" fontWeight="medium">
                      Sync OIDC Roles
                    </Box>
                    <Box fontSize="md" color="gray.500">
                      When enabled, roles in OIDC claims will be automatically
                      synced with VM roles.
                    </Box>
                  </Box>
                </Flex>
              </CardBody>
            </Card>

            <Card variant="outline">
              <CardBody>
                <Box mb={4}>
                  <Heading size="md">Identity Provider</Heading>
                </Box>
                <Flex align="center" gap={3}>
                  <Switch
                    id="allow-idp-user-creation"
                    colorScheme="brand"
                    size="md"
                    isChecked={idpUserCreationEnabled}
                    isDisabled={isLoading}
                    onChange={e =>
                      handleSettingChange('idp_user_creation_enabled', e.target.checked)
                    }
                  />
                  <Box>
                    <Box fontSize="md" fontWeight="medium">
                      Enable user creation
                    </Box>
                    <Box fontSize="md" color="gray.500">
                      When enabled, allow automatic creation of users in Identity Provider (currently IPs supported are Auth0, Keycloak)
                    </Box>
                  </Box>
                </Flex>
              </CardBody>
            </Card>
          </TabPanel>
          <TabPanel>
            <AdminUsers />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default Settings;
