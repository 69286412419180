import {
  Button,
  CloseButton,
  Heading,
  HStack,
  SlideFade,
  Spacer,
  Stack,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
import { useState } from 'react';
import ConfirmationAlert from '../../../../../../components/ConfirmationAlert';
import useWorkflow from '../../../../../../hooks/useWorkflow';

interface BasePanelProps {
  title: string;
  node: any;
  onSave: () => void;
  onDeleteNode: (node: any) => void;
  children: React.ReactNode;
}

function BasePanel({
  title,
  node,
  onSave,
  onDeleteNode,
  children,
}: BasePanelProps) {
  const { setSelectedNodeId } = useWorkflow();
  const [confirmDelete, setConfirmDelete] = useState(false);

  const onConfirmDeleteStep = (confirmed: boolean) => {
    if (node && confirmed) {
      onDeleteNode(node);
    }
    setConfirmDelete(false);
  };

  const onClose = () => {
    setSelectedNodeId!();
  };

  return (
    <SlideFade in={true} offsetX={'4rem'}>
      <Stack
        p={4}
        mt={32}
        rounded={'md'}
        border={'1px solid'}
        bg={useColorModeValue('neutral.50', 'neutral.900')}
        borderColor={useColorModeValue('neutral.200', 'neutral.800')}
        maxHeight={'calc(100vh - 180px)'}
        overflow={'auto'}
      >
        <VStack gap={4} alignItems={'flex-start'}>
          <HStack w={'full'}>
            <Heading>{title}</Heading>
            <Spacer />
            <CloseButton onClick={onClose} />
          </HStack>

          {children}

          <HStack justifyContent={'space-between'} w={'full'}>
            <Button
              size={'sm'}
              onClick={() => setConfirmDelete(true)}
              _hover={{
                bg: 'red.100',
                color: 'red.700',
              }}
              variant={'ghost'}
            >
              Delete Step
            </Button>
            <Button
              size={'sm'}
              onClick={() => {
                onSave();
                onClose();
              }}
              variant={'primary'}
            >
              Update Step
            </Button>
          </HStack>
        </VStack>
      </Stack>
      <ConfirmationAlert
        title={`Deleting step`}
        dialogBody={'Are you sure?'}
        open={confirmDelete}
        onConfirm={onConfirmDeleteStep}
      />
    </SlideFade>
  );
}

export default BasePanel;
