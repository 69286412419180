import React, { useState } from 'react';
import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  UniqueIdentifier,
  DragOverlay,
  DragStartEvent,
  DragOverEvent,
  CollisionDetection,
  rectIntersection,
  useDroppable,
} from '@dnd-kit/core';
import { CSS } from '@dnd-kit/utilities';
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import {
  Heading,
  HStack,
  Icon,
  Tag,
  Text,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
import { DragHandleIcon } from '@chakra-ui/icons';
import { ArrowsRightLeftIcon } from '@heroicons/react/24/solid';

interface DraggableItemProps {
  item: ListItem;
  disableDrag?: boolean;
}

const DraggableItem: React.FC<DraggableItemProps> = ({ item, disableDrag }) => {
  const { attributes, listeners, setNodeRef, transform, transition, active } =
    useSortable({ id: item.id, disabled: disableDrag });

  const style: React.CSSProperties = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const cursor = disableDrag ? 'default' : 'grab';
  const hover = disableDrag
    ? {}
    : { bg: useColorModeValue('brandSecondary.25', 'neutral.800') };

  return (
    <HStack
      style={style}
      w={'full'}
      borderWidth={1}
      borderRadius="md"
      opacity={active?.id === item.id ? 0.2 : 1}
      cursor={cursor}
      bg={useColorModeValue('neutral.100', 'neutral.850')}
      borderColor={useColorModeValue('neutral.200', 'neutral.800')}
      _hover={hover}
      transition={'all .3s ease-in-out'}
      pl={4}
      pr={2}
      py={2}
      role="group"
    >
      <HStack
        ref={setNodeRef}
        {...attributes}
        {...listeners}
        width={'full'}
        justifyContent={'space-between'}
        cursor={cursor}
      >
        <Text cursor={cursor} userSelect="none">
          {item.label}
        </Text>
        {!disableDrag && (
          <Icon
            as={DragHandleIcon}
            boxSize={3}
            cursor={cursor}
            opacity={0}
            _groupHover={{
              opacity: 1,
            }}
          />
        )}
      </HStack>
    </HStack>
  );
};

type OverlayProps = {
  item: ListItem;
};

const Overlay: React.FC<OverlayProps> = ({ item }) => {
  return (
    <HStack
      p={2}
      bgColor={'neutral.50'}
      borderWidth={1}
      borderRadius="md"
      bg={useColorModeValue('brandSecondary.25', 'neutral.800')}
      borderColor={useColorModeValue('brandSecondary.50', 'neutral.850')}
      color={useColorModeValue('brandSecondary.500', 'neutral.500')}
      cursor={'grab'}
      shadow={'lg'}
    >
      <Text userSelect="none">{item.label}</Text>
    </HStack>
  );
};

interface ColumnProps {
  id: string;
  width?: string;
  label: string;
  columns: ListItem[];
  countLabel: string;
  disableDrag: boolean;
}

const Column: React.FC<ColumnProps> = ({
  id,
  columns,
  label,
  width,
  countLabel,
  disableDrag,
}) => {
  const { setNodeRef, isOver } = useDroppable({ id, disabled: disableDrag });

  return (
    <SortableContext
      items={columns.map(c => c.id)}
      strategy={verticalListSortingStrategy}
    >
      <VStack
        align="flex-start"
        h={'full'}
        rounded={'md'}
        border="1px solid"
        borderColor={useColorModeValue('neutral.200', 'neutral.800')}
        bg={useColorModeValue('white', 'neutral.1000')}
        p={4}
        minWidth={width}
        flexGrow={1}
        gap={1}
      >
        <HStack w="full" align="center" mb={4}>
          <Heading as={'h5'}>{label}</Heading>
          <Tag>
            {columns.length} {countLabel}
          </Tag>
        </HStack>
        <VStack
          ref={setNodeRef}
          borderRadius="md"
          width={'full'}
          gap={1}
          h="100%"
          overflowY="auto" // Enable scrolling when content overflow
        >
          {columns.length > 0 ? (
            columns.map(item => (
              <DraggableItem
                key={item.id}
                item={item}
                disableDrag={disableDrag}
              />
            ))
          ) : (
            <div></div> // Placeholder for empty columns
          )}
        </VStack>
      </VStack>
    </SortableContext>
  );
};

type ListItem = {
  id: string;
  label: string;
};

type ListBuilderProps = {
  readOnly: boolean;
  leftListLabel: string;
  rightListLabel: string;
  initialLeftList: ListItem[];
  initialRightList: ListItem[];
  height: number;
  onRightColumnChanged: (columns: ListItem[]) => void;
};

const ListBuilder: React.FC<ListBuilderProps> = ({
  readOnly,
  leftListLabel,
  rightListLabel,
  initialLeftList,
  initialRightList,
  height,
  onRightColumnChanged,
}) => {
  const [columns, setColumns] = useState<Record<string, ListItem[]>>({
    left: initialLeftList,
    right: initialRightList,
  });

  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);
  const [overId, setOverId] = useState<UniqueIdentifier | null>(null);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor),
  );

  function findContainer(id: UniqueIdentifier) {
    if (columns[id]) {
      return id; // This handles cases where the column ID itself is passed
    }

    return Object.keys(columns).find(key =>
      columns[key].some(item => item.id === id),
    );
  }

  function handleDragStart(event: DragStartEvent) {
    const { active } = event;
    const { id } = active;

    setActiveId(id);
  }

  function handleDragOver(event: DragOverEvent) {
    const { active, over } = event;
    const { id: activeId } = active;
    const overId = over?.id;

    if (!overId) {
      return;
    }

    const activeContainer = findContainer(activeId);
    const overContainer = findContainer(overId);

    if (
      !activeContainer ||
      !overContainer ||
      activeContainer === overContainer
    ) {
      return; // No change if active and over are in the same container
    }

    setColumns(prev => {
      const activeItems = prev[activeContainer];
      const overItems = prev[overContainer];

      // Find the indexes for the items
      const activeIndex = activeItems.findIndex(item => item.id === activeId);
      const overIndex = overItems.findIndex(item => item.id === overId);

      let newIndex;
      if (overId in prev) {
        // We're at the root droppable of a container
        newIndex = overItems.length + 1;
      } else {
        const isBelowLastItem = over && overIndex === overItems.length - 1;

        const modifier = isBelowLastItem ? 1 : 0;

        newIndex = overIndex >= 0 ? overIndex + modifier : overItems.length + 1;
      }

      return {
        ...prev,
        [activeContainer]: [
          ...prev[activeContainer].filter((item: any) => item.id !== activeId),
        ],
        [overContainer]: [
          ...prev[overContainer].slice(0, newIndex),
          columns[activeContainer][activeIndex],
          ...prev[overContainer].slice(newIndex, prev[overContainer].length),
        ],
      };
    });
  }

  const fixCursorSnapOffset: CollisionDetection = (args: any) => {
    // Bail out if keyboard activated
    if (!args.pointerCoordinates) {
      return rectIntersection(args);
    }
    const { x, y } = args.pointerCoordinates;
    const { width, height } = args.collisionRect;
    const updated = {
      ...args,
      // The collision rectangle is broken when using snapCenterToCursor. Reset
      // the collision rectangle based on pointer location and overlay size.
      collisionRect: {
        width,
        height,
        bottom: y + height / 2,
        left: x - width / 2,
        right: x + width / 2,
        top: y - height / 2,
      },
    };
    return rectIntersection(updated);
  };

  function handleDragEnd(event: any) {
    const { active, over } = event;
    const { id } = active;
    const { id: overId } = over;

    const activeContainer = findContainer(id);
    const overContainer = findContainer(overId);

    if (
      !activeContainer ||
      !overContainer ||
      activeContainer !== overContainer
    ) {
      return;
    }

    const activeIndex = columns[activeContainer].findIndex(
      (item: any) => item.id === id,
    );

    const overIndex = columns[overContainer].findIndex(
      (item: any) => item.id === overId,
    );

    if (activeIndex !== overIndex) {
      setColumns((columns: any) => {
        const newColumns = {
          ...columns,
          [overContainer]: arrayMove(
            columns[overContainer],
            activeIndex,
            overIndex,
          ),
        };
        onRightColumnChanged(newColumns.right);
        return newColumns;
      });
    } else {
      onRightColumnChanged(columns.right);
    }

    setActiveId(null);
  }

  const activeItem =
    columns.left.find(item => item.id === activeId) ||
    columns.right.find(item => item.id === activeId);

  return (
    <DndContext
      sensors={sensors}
      onDragStart={handleDragStart}
      onDragOver={handleDragOver}
      onDragEnd={handleDragEnd}
      collisionDetection={fixCursorSnapOffset}
    >
      <HStack
        gap={4}
        justifyContent="flex-start"
        alignItems="center"
        w={'full'}
        h={height}
      >
        <Column
          disableDrag={readOnly}
          label={leftListLabel}
          key={'left'}
          id={'left'}
          columns={columns.left}
          countLabel="Available"
        />
        <Icon as={ArrowsRightLeftIcon} boxSize={6} color="neutral.500" />
        <Column
          disableDrag={readOnly}
          label={rightListLabel}
          key={'right'}
          id={'right'}
          columns={columns.right}
          countLabel="Selected"
        />
      </HStack>
      <DragOverlay>
        {activeId && activeItem ? <Overlay item={activeItem} /> : null}
      </DragOverlay>
    </DndContext>
  );
};

export default ListBuilder;
