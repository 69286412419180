import {
  Box,
  ButtonGroup,
  Fade,
  HStack,
  Heading,
  Icon,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  IconButton,
  Input,
  Spacer,
  Stack,
  VStack,
  useColorModeValue,
  useToast,
  Button,
  LinkBox,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  FormControl,
} from '@chakra-ui/react';
import { forwardRef, useEffect, useRef, useState, useContext, FC } from 'react';
import {
  EllipsisVerticalIcon,
  PencilIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';
import { PlusIcon } from '@heroicons/react/20/solid';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import API from '../../../api/API';
import { TBusinessUnit } from '../../../models/business_unit';
import { UsersContext } from '../../../contexts';
import MoreInfoPopOver from '../../../components/MoreInfoPopOver';
import { DangerMenuItem } from '../../../components/DangerMenuItem';
import { Label } from '../../../components/Layout';
import { CONFIG } from '../../../config';

interface BusinessUnitItemProps {
  item: TBusinessUnit;
  canDelete: boolean;
  canUpdate: boolean;
  onDelete: (id: string) => void;
  onUpdateTitle: (id: string, newTitle: string) => void;
}

const BusinessUnitItem = forwardRef<HTMLDivElement, BusinessUnitItemProps>(
  function BusinessUnitItem(
    { item, canDelete, canUpdate, onDelete, onUpdateTitle },
    ref,
  ) {
    const [businessUnitTitle, setBusinessUnitTitle] = useState(item.name);
    const [isEditing, setIsEditing] = useState(false);

    const handleSubmit = () => {
      onUpdateTitle(item.cuid, businessUnitTitle);
      setIsEditing(false);
    };

    const handleCancel = () => {
      setBusinessUnitTitle(item.name);
      setIsEditing(false);
    };

    return (
      <LinkBox
        data-testid="business-unit-item"
        key={item.cuid}
        alignSelf={'stretch'}
        role="group"
        rounded={'md'}
        px={4}
        py={2}
        border={'1px solid'}
        _hover={{
          bg: useColorModeValue('brandSecondary.25', 'brandSecondary.950'),
          borderColor: useColorModeValue(
            'brandSecondary.100',
            'brandSecondary.800',
          ),
          color: useColorModeValue('inherit', 'brandSecondary.25'),
        }}
        borderColor={'var(--chakra-colors-chakra-border-color)'}
        transition={'all 0.3s ease-in-out'}
      >
        <Fade in={true} transition={{ enter: { duration: 0.5, delay: 0.2 } }}>
          <HStack w="full">
            {isEditing ? (
              <HStack w={'full'}>
                <Input
                  value={businessUnitTitle}
                  onChange={e => setBusinessUnitTitle(e.target.value)}
                  autoFocus
                />
                <ButtonGroup>
                  <Button onClick={handleCancel}>Cancel</Button>
                  <Button onClick={handleSubmit}>Save</Button>
                </ButtonGroup>
              </HStack>
            ) : (
              <Box flex={1}>{businessUnitTitle}</Box>
            )}
            <Spacer />
            <Menu>
              <MenuButton
                as={IconButton}
                variant="ghost"
                aria-label="Options"
                icon={<Icon as={EllipsisVerticalIcon} boxSize={6} />}
                visibility={'hidden'}
                _groupHover={{
                  visibility: 'visible',
                }}
                onClick={event => {
                  event.stopPropagation();
                }}
              />
              <MenuList>
                <MenuItem
                  icon={<Icon as={PencilIcon} boxSize={5} />}
                  onClick={() => setIsEditing(true)}
                  isDisabled={!canUpdate}
                >
                  Edit Details
                </MenuItem>

                <MenuDivider />
                {canDelete && (
                  <DangerMenuItem
                    icon={<Icon as={TrashIcon} boxSize={5} />}
                    onClick={event => {
                      onDelete(item.cuid);
                    }}
                  >
                    Delete Business Unit
                  </DangerMenuItem>
                )}
              </MenuList>
            </Menu>
          </HStack>
        </Fade>
      </LinkBox>
    );
  },
);

type AddBusinessUnitModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onAddBusinessUnit: (inputValue: string) => Promise<TBusinessUnit>;
};

const AddBusinessUnitModal: FC<AddBusinessUnitModalProps> = ({
  isOpen,
  onClose,
  onAddBusinessUnit,
}) => {
  const [inputValue, setInputValue] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={false}
      isCentered
      closeOnEsc
      size="3xl"
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add New Business Unit</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl data-testid="form-field-business-unit-name">
            <Label mb={2}>Business Unit Name</Label>

            <Input
              type="text"
              value={inputValue}
              onChange={e => setInputValue(e.target.value)}
              placeholder="Type a new business unit name"
            />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <ButtonGroup>
            {/* <Button
            variant="ghost"
            onClick={() => {
              setInputValue('');
              onClose();
            }}
          >
            Cancel
          </Button> */}
            <Button
              isLoading={isSubmitting}
              onClick={async () => {
                setIsSubmitting(true);
                await onAddBusinessUnit(inputValue);
                setInputValue('');
                onClose();
                setIsSubmitting(false);
              }}
              isDisabled={!inputValue || isSubmitting}
              leftIcon={<Icon as={PlusIcon} boxSize={5} />}
              variant={'primary'}
            >
              Add Business Unit
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export function BusinessUnitsPanel() {
  const [lastAddedItemId, setLastAddedItemId] = useState<string | null>(null);
  const newItemRef = useRef<HTMLDivElement>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { userHasPermission } = useContext(UsersContext);
  const toast = useToast();
  const queryClient = useQueryClient();

  const canReadBusinessUnit = userHasPermission(['read_business_unit'], 'all');
  const canAddBusinessUnit = userHasPermission(['add_business_unit'], 'all');
  const canDeleteBusinessUnit = userHasPermission(
    ['delete_business_unit'],
    'all',
  );
  const canUpdateBusinessUnit = userHasPermission(
    ['update_business_unit'],
    'all',
  );

  if (!canReadBusinessUnit) {
    return null;
  }

  const { data: businessUnits = [] } = useQuery(
    ['business-units'],
    async () => {
      return await API.GetOrganizationBusinessUnits();
    },
    {
      onSuccess: businessUnits =>
        businessUnits.sort((a, b) => {
          const nameA = a.name.toUpperCase();
          const nameB = b.name.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        }),
    },
  );

  const createBusinessUnitMutation = useMutation(
    [],
    async ({ name }: { name: string }) => {
      return API.PostOrganizationBusinessUnits({ name });
    },
    {
      onSuccess: data => {
        setLastAddedItemId(data.cuid);
        void queryClient.invalidateQueries('business-units');
        toast({
          variant: 'subtle',
          title: 'Business Unit was added.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      },
      onError: error => {
        if (error instanceof Error) {
          toast({
            variant: 'subtle',
            title: 'Unable to add Business Unit.',
            description: API.getAPIErrorMessage(error),
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        }
      },
    },
  );

  const updateBusinessUnitTitleMutation = useMutation(
    [],
    async ({ cuid, newTitle }: { cuid: string; newTitle: string }) => {
      return API.PatchOrganizationBusinessUnit(cuid, newTitle);
    },
    {
      onSuccess: () => {
        void queryClient.invalidateQueries('business-units');
      },
      onError: error => {
        if (error instanceof Error) {
          toast({
            variant: 'subtle',
            title: 'Error updating business unit',
            description: API.getAPIErrorMessage(error),
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        }
      },
    },
  );

  const deleteBusinessUnitMutation = useMutation(
    [],
    async ({ cuid }: { cuid: string }) => {
      return API.DeleteOrganizationBusinessUnit(cuid);
    },
    {
      onSuccess: () => {
        void queryClient.invalidateQueries('business-units');
        toast({
          variant: 'subtle',
          title: 'Business Unit was deleted.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      },
      onError: error => {
        if (error instanceof Error) {
          toast({
            variant: 'subtle',
            title: 'Unable to delete Business Unit',
            description: API.getAPIErrorMessage(error),
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        }
      },
    },
  );

  const onAddBusinessUnit = (inputValue: string): Promise<TBusinessUnit> => {
    return createBusinessUnitMutation.mutateAsync({ name: inputValue });
  };

  const onUpdateBusinessUnitTitle = (cuid: string, newTitle: string) => {
    updateBusinessUnitTitleMutation.mutate({ cuid, newTitle });
  };

  const onDeleteBusinessUnit = (cuid: string) => {
    deleteBusinessUnitMutation.mutate({ cuid });
  };

  useEffect(() => {
    if (lastAddedItemId && newItemRef.current) {
      newItemRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
      setLastAddedItemId(null);
    }
  }, [lastAddedItemId, businessUnits]);

  return (
    <Stack
      w="full"
      gap={4}
      padding={4}
      border="1px solid"
      rounded="md"
      borderColor={'var(--chakra-colors-chakra-border-color)'}
      h="full"
    >
      <HStack>
        <Heading as="h3">Business Units</Heading>
        <Spacer />
        <MoreInfoPopOver
          title="Manage Business Units"
          link={`${CONFIG.VALIDMIND_DOCS_URL}/guide/configuration/set-up-your-organization.html#manage-business-units`}
        />
      </HStack>
      <VStack alignItems="flex-start" w="full" gap={4}>
        {canAddBusinessUnit && (
          <Button
            onClick={onOpen}
            leftIcon={<Icon as={PlusIcon} boxSize={5} />}
            variant="ghost"
            alignSelf={'flex-end'}
          >
            Add New Business Unit
          </Button>
        )}
        <Box scrollBehavior="smooth" overflowY="auto" w="full" maxH={96}>
          <VStack w="full" gap={1}>
            {businessUnits.map(businessUnit => (
              <BusinessUnitItem
                ref={businessUnit.cuid === lastAddedItemId ? newItemRef : null}
                key={businessUnit.cuid}
                item={businessUnit}
                canDelete={canDeleteBusinessUnit}
                canUpdate={canUpdateBusinessUnit}
                onDelete={onDeleteBusinessUnit}
                onUpdateTitle={onUpdateBusinessUnitTitle}
              />
            ))}
          </VStack>
        </Box>
      </VStack>
      <AddBusinessUnitModal
        isOpen={isOpen}
        onClose={onClose}
        onAddBusinessUnit={onAddBusinessUnit}
      />
    </Stack>
  );
}
