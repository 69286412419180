import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { createNewSocket } from '../../socket';
import { Box } from '@chakra-ui/react';
import { Socket } from 'socket.io-client';

export interface AIGenerationConfig {
  initEvent: {
    eventName: string;
    args: object;
  };
  streamEvent: {
    eventName: string;
  };
  options?: {
    chatHistory?: { role: string; content: string }[];
    [key: string]: any;
  };
  // General context of the section where this text box is located
  sectionContext?: {
    title?: string;
    guidelines?: string[];
    [key: string]: any;
  };
}

interface AITextContentEditorProps {
  prompt: string;
  onStatusChanged: (status: AIGenerationStatus) => void;
  onContentChanged?: () => void;
  onTextChanged?: (text: string) => void;
  aiGenerationConfig: AIGenerationConfig;
}

export type AIGenerationStatus = 'idle' | 'generating';

export interface AITextContentEditorMethods {
  generate: () => void;
  getText: () => string;
}

const AITextContentEditor = forwardRef<
  AITextContentEditorMethods,
  AITextContentEditorProps
>((props, ref) => {
  const [text, setText] = useState('');
  const [status, setStatus] = useState<AIGenerationStatus>('idle');
  const [socket, setSocket] = useState<Socket>();

  const { prompt, aiGenerationConfig, onStatusChanged, onContentChanged } =
    props;

  useEffect(() => {
    onStatusChanged(status);
  }, [status]);

  const closeSocket = useCallback(() => {
    if (socket) {
      socket.off(aiGenerationConfig.streamEvent.eventName);
      if (socket.connected) {
        socket.disconnect();
      }
      setSocket(undefined);
    }
  }, [socket, aiGenerationConfig.streamEvent.eventName]);

  useEffect(() => {
    return () => {
      closeSocket();
    };
  }, [closeSocket]);

  const handleText = useCallback(
    (o: any) => {
      if (o.finished) {
        setStatus('idle');
        closeSocket();
      } else {
        setStatus('generating');
        setText(text => {
          let newText = text + o.content;

          if (props.onTextChanged) {
            props.onTextChanged(newText);
          }

          return newText;
        });

        if (onContentChanged) {
          onContentChanged();
        }
      }
    },
    [onContentChanged, props.onTextChanged],
  );

  const generate = useCallback(async () => {
    if (status === 'generating') {
      return;
    }

    try {
      closeSocket();
      setStatus('generating');
      setText('');

      const sock = await createNewSocket();
      setSocket(sock);

      sock.on(aiGenerationConfig.streamEvent.eventName, handleText);
      sock.emit(aiGenerationConfig.initEvent.eventName, {
        ...aiGenerationConfig.initEvent.args,
        prompt,
        chatHistory: aiGenerationConfig.options?.chatHistory || [],
      });
    } catch (error) {
      console.log({ error });
    }
  }, [status, closeSocket, handleText, aiGenerationConfig, prompt]);

  const getText = useCallback(() => {
    return text;
  }, [text]);

  useImperativeHandle(ref, () => ({
    generate,
    getText,
  }));

  return (
    <Box
      className="ck ck-content ck-content"
      dangerouslySetInnerHTML={{ __html: text }}
    />
  );
});

export default AITextContentEditor;
