import 'react-datepicker/dist/react-datepicker.css';
import {
  Box,
  Button,
  Flex,
  HStack,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Tag,
  TagCloseButton,
  TagLabel,
  Text,
  useColorModeValue,
  useDisclosure,
  VStack,
  Wrap,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
} from '@chakra-ui/react';
import { ContentPageTitle } from '../../components/Layout';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import API from '../../api/API';
import { allFiltersEmpty } from '../../utils';
import { FunnelIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { APIRequestSortBy } from '../../models/utils';
import { BarsArrowDownIcon } from '@heroicons/react/20/solid';
import { Copyright } from '../../components/Copyright';
import {
  ArrowDownTrayIcon,
  ClipboardDocumentCheckIcon,
  ExclamationTriangleIcon,
} from '@heroicons/react/24/outline';
import GetStartedChecklist from '../../components/GetStartedChecklist';

import { FindingFilters, TFinding } from '../../models/finding';
import ModelFindingsFilters from '../../components/ModelFindingsFilters';
import TableColumnPicker from '../../components/TableColumnPicker';
import { useUserUISettings } from '../../hooks/useUserUISettings';
import AvatarProxy from '../../components/AvatarProxy';
import MoreInfoPopOver from '../../components/MoreInfoPopOver';
import ManageViewsButton from '../../components/ManageViewsButton';
import { TSavedView } from '../../models/saved_view';
import FindingsTable, {
  ALL_FINDINGS_COLUMNS,
  getLabelFromSortType,
  sortFindingsOptions,
} from '../../components/FindingsTable';
import dayjs from 'dayjs';
import { CONFIG } from '../../config';
import { AttestationSidebarContext } from '../../components/AttestationSidebar';
import { FindingDetails } from '../ModelInventoryOverview/Findings/ViewFinding';
import { useFlags } from '../../hooks/useFlags';

type FilterDefinition = {
  accessors?: {
    id?: string;
    label?: string;
    picture?: string;
  };
  tag: string;
};

const filterDefs: { [key: string]: FilterDefinition } = {
  business_units: {
    accessors: {
      id: 'cuid',
      label: 'name',
    },
    tag: 'Business Unit',
  },
  status: {
    tag: 'Status',
  },
  assignees: {
    accessors: {
      id: 'cuid',
      label: 'name',
      picture: 'picture',
    },
    tag: 'Assigned To',
  },
  risk_areas: {
    accessors: {
      id: 'cuid',
      label: 'name',
    },
    tag: 'Risk Area',
  },
  severities: {
    accessors: {
      id: 'id',
      label: 'name',
    },
    tag: 'Severity',
  },
  inventory_models: {
    accessors: {
      id: 'cuid',
      label: 'name',
    },
    tag: 'Inventory Model',
  },
};

const defaultColumns = [
  'title',
  'severity',
  'status',
  'inventory_model.name',
  'due_at',
  'created_at',
];

const initialSortBy = sortFindingsOptions[0];

export default function ModelFindings() {
  const navigate = useNavigate();
  const { attestationsUi } = useFlags();
  const [searchParams, setSearchParams] = useSearchParams();
  const filtersState = searchParams.get('filters');
  const [selectedView, setSelectedView] = useState<TSavedView>();

  const { getModelFindingsColumns, updateModelFindingsColumns } =
    useUserUISettings();

  const storedColumnConfig = getModelFindingsColumns();

  const [selectedColumns, setSelectedColumns] = useState<string[]>(
    storedColumnConfig ? storedColumnConfig : defaultColumns,
  );

  const [selectedFilters, setSelectedFilters] = useState<
    FindingFilters | undefined
  >(filtersState ? JSON.parse(decodeURIComponent(filtersState)) : undefined);

  const [sortBy, setSortBy] = useState<APIRequestSortBy | undefined>(
    initialSortBy,
  );

  const {
    showSidebar,
    setShowSidebar,
    attestationExecutions,
    selectedAttestationExecution,
    setSelectedAttestationExecution,
  } = useContext(AttestationSidebarContext);
  const [selectedFindingSnapshot, setSelectedFindingSnapshot] =
    useState<TFinding>();
  const viewSnapshotDisclosure = useDisclosure();

  const { data: allFilterOptions, isLoading: loadingFilterOptions } = useQuery(
    ['model-findings', 'filters'],
    async () => {
      return API.GetFindingFilters();
    },
    {
      onError: err => {
        // track errors
      },
    },
  );

  useEffect(() => {
    if (selectedFilters) {
      setSearchParams(
        `?filters=${encodeURIComponent(JSON.stringify(selectedFilters))}`,
      );
    }
  }, [selectedFilters]);

  const onTableSort = (column: any[]) => {
    if (column.length > 0) {
      const c = ALL_FINDINGS_COLUMNS.find(s => s.accessor === column[0].id)!;
      const order = column[0].desc ? 'desc' : 'asc';

      setSortBy({
        label: c.Header,
        field: c.sortField,
        order,
        orderLabel: getLabelFromSortType(c.sortType, order),
      });
    } else {
      setSortBy(initialSortBy);
    }
  };

  const filterDisplays: any[] = [];

  // The purpose of this code is to map selectFilters, which contains list of ids
  // for each filter, into allFilterOptions, which contains the data record for
  // the filter
  if (selectedFilters && allFilterOptions) {
    Object.keys(selectedFilters).forEach((selectedFilterKey: string) => {
      const filterDef = filterDefs[selectedFilterKey];

      if (!filterDef) {
        return;
      }

      // @ts-ignore:
      const allFiltersForKey = allFilterOptions[selectedFilterKey];

      // @ts-ignore:
      selectedFilters[selectedFilterKey].forEach(selectedId => {
        const idKey = filterDef.accessors?.id;
        let label;
        let picture;

        if (idKey) {
          const filterRecord = allFiltersForKey.find(
            (a: any) => a[idKey] === selectedId,
          );
          label = filterRecord[filterDef.accessors?.label];
          picture = filterRecord[filterDef.accessors?.picture];
        } else {
          label = selectedId;
        }

        filterDisplays.push(
          <Tag
            variant={'outline'}
            colorScheme={'neutral'}
            key={selectedId}
            size={'md'}
          >
            {picture && (
              <AvatarProxy
                src={picture}
                size="xs"
                name={label}
                ml={-1}
                mr={2}
              />
            )}
            <TagLabel>
              <strong>{filterDef.tag}:</strong> {label}
            </TagLabel>
            <TagCloseButton
              onClick={() =>
                setSelectedFilters({
                  ...selectedFilters,
                  // @ts-ignore:
                  [selectedFilterKey]: selectedFilters[
                    selectedFilterKey
                  ].filter((o: any) => {
                    return o !== selectedId;
                  }),
                })
              }
            />
          </Tag>,
        );
      });
    });
  }

  const [isExporting, setIsExporting] = useState(false);

  const handleExport = async () => {
    try {
      setIsExporting(true);
      const csvData = await API.ExportFindings(selectedFilters, sortBy);

      const timestamp = dayjs().format('YYYY-MM-DD-HH-mm-ss');
      const url = window.URL.createObjectURL(csvData);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `findings-${timestamp}.csv`);
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Failed to export findings:', error);
    } finally {
      setIsExporting(false);
    }
  };

  const findingGetDataOverride = useMemo(() => {
    if (selectedAttestationExecution) {
      return async (page: number, pageSize: number) => {
        const { findings } = await API.GetAttestationExecutionSnapshotFindings(
          selectedAttestationExecution.cuid,
        );

        return {
          results: findings.slice((page - 1) * pageSize, page * pageSize),
          total: findings.length,
          limit: pageSize,
          page: page,
        };
      };
    }
    return undefined;
  }, [selectedAttestationExecution]);

  return (
    <VStack
      overflow="inherit"
      align="flex-start"
      gap={0}
      bg={
        selectedAttestationExecution
          ? useColorModeValue('brandSecondary.25', 'brandSecondary.900')
          : 'inherit'
      }
      borderTop={selectedAttestationExecution ? '2px solid' : 'none'}
      borderTopColor={'brandSecondary.500'}
    >
      <Modal
        isOpen={viewSnapshotDisclosure.isOpen}
        onClose={viewSnapshotDisclosure.onClose}
        size={'6xl'}
        scrollBehavior={'inside'}
      >
        <ModalOverlay />
        <ModalContent bgColor="white">
          <ModalHeader>{selectedFindingSnapshot?.title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FindingDetails
              finding={selectedFindingSnapshot!}
              allowAttributesEdit={false}
              allowCommentsAdd={false}
              allowEditMetadata={false}
              allowEditRemediationPlan={false}
              allowManageAttachments={false}
              attestationExecution={selectedAttestationExecution || undefined}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
      {selectedAttestationExecution && (
        <Stack justifyContent={'center'} pl={8} py={5}>
          <Breadcrumb as={'h5'}>
            <BreadcrumbItem>
              <BreadcrumbLink
                href="#"
                onClick={() => setSelectedAttestationExecution(null)}
                color={useColorModeValue(
                  'brandSecondary.600',
                  'brandSecondary.100',
                )}
              >
                Attestations
              </BreadcrumbLink>
            </BreadcrumbItem>

            <BreadcrumbItem isCurrentPage>
              <BreadcrumbLink href="#">
                {selectedAttestationExecution.schedule_snapshot_json.name}
              </BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>
        </Stack>
      )}
      <Box
        py={10}
        px={8}
        flex={1}
        overflow={'auto'}
        className="no-scrollbar"
        bg={useColorModeValue('white', 'black')}
      >
        <Stack>
          <HStack>
            <Flex width={'full'} justify={'space-between'}>
              <HStack gap={5} pl={2} color={'inherit'}>
                <Icon as={ExclamationTriangleIcon} boxSize={10} />
                <ContentPageTitle>
                  Model Findings
                  <MoreInfoPopOver
                    title="Model Findings"
                    description="View your findings across all models."
                    link={`${CONFIG.VALIDMIND_DOCS_URL}/guide/model-validation/view-filter-model-findings.html`}
                    placement="right-end"
                    iconProps={{
                      ml: 2,
                    }}
                  />
                </ContentPageTitle>
              </HStack>
            </Flex>
          </HStack>
          <HStack gap={8} w={'full'} pl={0.5} justifyContent={'space-between'}>
            {!selectedAttestationExecution ? (
              <ManageViewsButton
                viewType="finding"
                selectedView={selectedView}
                onViewSelected={(view: TSavedView) => {
                  setSelectedColumns(view.content.columns);
                  setSortBy(view.content.sortBy);
                  setSelectedFilters(view.content.filters);
                  setSelectedView(view);
                }}
                getCurrentContent={() => ({
                  columns: selectedColumns,
                  sortBy,
                  filters: selectedFilters,
                })}
              />
            ) : (
              <HStack />
            )}

            <HStack>
              {!selectedAttestationExecution && (
                <>
                  {allFilterOptions && (
                    <ModelFindingsFilters
                      selectedFilters={selectedFilters}
                      allFilterOptions={allFilterOptions}
                      setFilters={setSelectedFilters}
                    />
                  )}
                  <TableColumnPicker
                    selectedColumnIds={selectedColumns}
                    allColumns={ALL_FINDINGS_COLUMNS.map(c => ({
                      id: c.accessor,
                      label: c.Header,
                    }))}
                    setColumns={newColumns => {
                      updateModelFindingsColumns(newColumns);
                      setSelectedColumns(newColumns as any);
                    }}
                  />
                  <Button
                    leftIcon={<Icon as={ArrowDownTrayIcon} />}
                    onClick={handleExport}
                    variant="ghost"
                    isLoading={isExporting}
                    loadingText="Exporting..."
                    disabled={isExporting}
                  >
                    Export
                  </Button>
                  {attestationsUi && attestationExecutions.length > 0 && (
                    <Button
                      leftIcon={
                        <Icon as={ClipboardDocumentCheckIcon} boxSize={5} />
                      }
                      onClick={() => {
                        setShowSidebar(!showSidebar);
                      }}
                      variant="ghost"
                    >
                      Attestations
                    </Button>
                  )}
                </>
              )}
            </HStack>
          </HStack>

          <HStack
            px={5}
            alignItems={'flex-start'}
            gap={selectedFilters && !allFiltersEmpty(selectedFilters) ? 4 : 0}
          >
            {selectedFilters && !allFiltersEmpty(selectedFilters) && (
              <HStack alignItems={'flex-start'}>
                <Flex
                  alignItems={'center'}
                  gap={2}
                  color={'neutral.500'}
                  whiteSpace={'nowrap'}
                >
                  <Icon as={FunnelIcon} width={5} height={5} />
                  <Text fontSize={'sm'} fontWeight={'semibold'}>
                    Filtered by:
                  </Text>
                </Flex>

                <Flex>
                  {selectedFilters && (
                    <Wrap>
                      {selectedFilters.cuids &&
                        selectedFilters.cuids.length > 0 && (
                          <Tag
                            key={'cuid-filter-tag'}
                            size={'md'}
                            borderRadius="full"
                          >
                            <TagLabel>
                              <strong>ID Filter: </strong>{' '}
                              {selectedFilters.cuids.length} Findings
                            </TagLabel>
                            <TagCloseButton
                              onClick={() =>
                                setSelectedFilters({
                                  ...selectedFilters,
                                  cuids: undefined,
                                })
                              }
                            />
                          </Tag>
                        )}
                      {filterDisplays}
                    </Wrap>
                  )}
                </Flex>
              </HStack>
            )}

            {sortBy && (
              <HStack margin={'0 !important'}>
                <Flex
                  alignItems={'center'}
                  gap={2}
                  whiteSpace={'nowrap'}
                  color={'neutral.500'}
                >
                  <Icon as={BarsArrowDownIcon} width={5} height={5} />
                  <Text fontSize={'sm'} fontWeight={'semibold'}>
                    Sorted by:
                  </Text>

                  <Wrap>
                    {sortBy && (
                      <Tag key={sortBy.field} size={'md'}>
                        <TagLabel>
                          <strong>{sortBy.label}: </strong>
                          {sortBy.orderLabel}
                        </TagLabel>
                      </Tag>
                    )}
                  </Wrap>
                </Flex>
              </HStack>
            )}
            {selectedView && (
              <Button
                variant={'ghost'}
                size={'xs'}
                fontSize={'sm'}
                rounded={'full'}
                leftIcon={<Icon as={XMarkIcon} boxSize={4} />}
                onClick={() => {
                  setSelectedView(undefined);
                  setSelectedColumns(defaultColumns);
                  setSortBy(initialSortBy);
                  setSelectedFilters(undefined);
                }}
              >
                Clear All
              </Button>
            )}
          </HStack>
          <FindingsTable
            columns={selectedColumns}
            sortBy={sortBy}
            filters={selectedFilters}
            onClickRow={(row: any) => {
              if (!selectedAttestationExecution) {
                navigate(
                  `/model-inventory/${row.original.inventory_model.cuid}/findings/${row.original.cuid}`,
                );
              } else {
                setSelectedFindingSnapshot(row.original);
                viewSnapshotDisclosure.onOpen();
              }
            }}
            onTableSort={onTableSort}
            getData={findingGetDataOverride}
          />
        </Stack>
        <Copyright />
        <GetStartedChecklist />
      </Box>
    </VStack>
  );
}
