import { useContext, useEffect } from 'react';
import SidebarContext from '../../../../../contexts/SidebarContext';
import { useQuery } from 'react-query';
import API from '../../../../../api/API';
import {
  Box,
  Heading,
  HStack,
  Icon,
  IconButton,
  LinkBox,
  LinkOverlay,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spacer,
  Stack,
  Text,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
import { LoadingContainer } from '../../../../../components/LoadingContainer';
import Breadcrumbs from '../../../../../components/Breadcrumbs';
import { ContentPageTitle } from '../../../../../components/Layout';
import MoreInfoPopOver from '../../../../../components/MoreInfoPopOver';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { EllipsisVerticalIcon, EyeIcon } from '@heroicons/react/24/solid';

export default function WorkflowList() {
  const navigate = useNavigate();
  const { setInSettings } = useContext(SidebarContext);

  useEffect(() => {
    setInSettings(true);
    return () => {
      setInSettings(false);
    };
  }, []);

  const {
    data: workflows,
    isLoading,
    refetch,
  } = useQuery(['organizations', 'workflows2'], async () => {
    return API.GetWorkflows();
  });

  return (
    <VStack
      alignItems="start"
      spacing={0}
      paddingTop={12}
      mt={1}
      paddingBottom={16}
      px={14}
      gap={2}
      w="full"
      overflow="auto"
      className="no-scrollbar"
      maxWidth={'7xl'}
      mx={'auto'}
      role="group"
    >
      <LoadingContainer isLoading={false}>
        <Stack alignSelf={'stretch'} gap={8}>
          <Box>
            <Breadcrumbs />
            <ContentPageTitle>
              Workflow States
              <MoreInfoPopOver
                title="Customize Workflow States"
                link="https://docs.validmind.ai/guide/model-workflows/customize-workflow-states.html"
                placement="right-end"
                iconProps={{
                  ml: 2,
                }}
              />
            </ContentPageTitle>
          </Box>
          <Text>
            Workflow states are manipulated via workflow transitions and are
            used to track the status of workflows through your organization's
            processes.
          </Text>

          <VStack align={'stretch'} w={'full'} gap={1}>
            {workflows?.results.map(workflow => (
              <LinkBox
                gap={4}
                p={4}
                rounded={'md'}
                border={'1px solid'}
                _hover={{
                  bg: useColorModeValue(
                    'brandSecondary.25',
                    'brandSecondary.950',
                  ),
                  borderColor: useColorModeValue(
                    'brandSecondary.100',
                    'brandSecondary.800',
                  ),
                  color: useColorModeValue('inherit', 'brandSecondary.25'),
                }}
                borderColor={useColorModeValue('neutral.200', 'neutral.800')}
                transition={'all 0.3s ease-in-out'}
              >
                <LinkOverlay
                  as={RouterLink}
                  to={`/settings/workflow-states/${workflow.cuid}`}
                >
                  <HStack alignItems={'flex-start'}>
                    <VStack alignItems={'flex-start'} gap={0}>
                      <Heading as={'h4'}>{workflow.title}</Heading>
                      <Text>{workflow.description}</Text>
                    </VStack>
                    <Spacer />
                    <Menu>
                      <MenuButton
                        as={IconButton}
                        variant="ghost"
                        aria-label="Options"
                        icon={<Icon as={EllipsisVerticalIcon} boxSize={6} />}
                        display={'none'}
                        _groupHover={{
                          display: 'block',
                        }}
                      />
                      <MenuList>
                        <MenuItem
                          icon={<Icon as={EyeIcon} boxSize={5} />}
                          onClick={() =>
                            navigate(
                              `/settings/workflows/${workflow.cuid}/latest`,
                            )
                          }
                        >
                          See Details
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  </HStack>
                </LinkOverlay>
              </LinkBox>
            ))}
          </VStack>
        </Stack>
      </LoadingContainer>
    </VStack>
  );
}
