import React, { createContext, useContext, useState } from 'react';
import { useQueryClient, useMutation } from 'react-query';
import { ActivityFeedWidgetQueryKey } from '../../../ActivityFeedWidget';
import API from '../../../../api/API';
import { WorkflowUserActionForm } from '../../../../models/workflow';

interface UserActionContextType {
  currentAction: WorkflowUserActionForm | null;
  setCurrentAction: (action: WorkflowUserActionForm | null) => void;
  executeAction: (params: {
    execution: any;
    fieldValues: any;
    notes: string;
  }) => Promise<void>;
  isExecuting: boolean;
  error: Error | null;
}

const UserActionContext = createContext<UserActionContextType | undefined>(
  undefined,
);

export function UserActionProvider({
  children,
  target,
}: {
  children: React.ReactNode;
  target: any;
}) {
  const queryClient = useQueryClient();
  const [currentAction, setCurrentAction] =
    useState<WorkflowUserActionForm | null>(null);
  const [error, setError] = useState<Error | null>(null);

  const { mutateAsync: executeActionMutation, isLoading: isExecuting } =
    useMutation(
      async ({
        execution,
        fieldValues,
        notes,
      }: {
        execution: any;
        fieldValues: any;
        notes: string;
      }) => {
        if (!currentAction) throw new Error('No action selected');
        return await API.PostWorkflowExecutionUserActions(
          execution.workflow.cuid,
          execution.cuid,
          currentAction,
          fieldValues,
          notes,
        );
      },
      {
        onSuccess: execution => {
          // Invalidate relevant queries
          queryClient.invalidateQueries(['targets', target.cuid, 'executions']);
          queryClient.invalidateQueries(['inventory-model', target.cuid]);
          queryClient.invalidateQueries([ActivityFeedWidgetQueryKey]);

          if (execution.pending_approval) {
            queryClient.invalidateQueries(['approvals', 'voters']);
          }
        },
        onError: err => {
          setError(err as Error);
        },
      },
    );

  const executeAction = async (params: {
    execution: any;
    fieldValues: any;
    notes: string;
  }) => {
    try {
      await executeActionMutation(params);
      setCurrentAction(null); // Clear current action after successful execution
      setError(null);
    } catch (err) {
      setError(err as Error);
      throw err;
    }
  };

  return (
    <UserActionContext.Provider
      value={{
        currentAction,
        setCurrentAction,
        executeAction,
        isExecuting,
        error,
      }}
    >
      {children}
    </UserActionContext.Provider>
  );
}

export function useUserAction() {
  const context = useContext(UserActionContext);
  if (context === undefined) {
    throw new Error('useUserAction must be used within a UserActionProvider');
  }
  return context;
}
