import { ReactElement, useContext, useEffect, useState } from 'react';
import {
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  HStack,
  VStack,
  Box,
  Heading,
  Button,
  Icon,
  Stack,
  Input,
  useToast,
  useClipboard,
  Tooltip,
} from '@chakra-ui/react';
import { useQuery } from 'react-query';
import Breadcrumbs from '../../../components/Breadcrumbs';
import SidebarContext from '../../../contexts/SidebarContext';
import { TGroup } from '../../../models/group';
import {
  EnvelopeIcon,
  ClipboardDocumentIcon,
} from '@heroicons/react/24/outline';
import GroupSelect from '../../../components/GroupSelect';
import { InvitationRolesPicker } from './InvitationRolePicker';
import { TRole } from '../../../models';
import API from '../../../api/API';
import { TUserInvite } from '../../../models/user';
import { DataTable } from '../../../components/DataTable';
import { displayFormattedDate } from '../../../utils';
import { CellProps } from 'react-table';
import RolePill from '../../../components/RolePill';
import { LoadingContainer } from '../../../components/LoadingContainer';
import { ContentPageTitle, Label } from '../../../components/Layout';
import AvatarProxy from '../../../components/AvatarProxy';
import MoreInfoPopOver from '../../../components/MoreInfoPopOver';
import { CONFIG } from '../../../config';

interface Invitee {
  email: string;
  group: TGroup | undefined;
  roles: TRole[];
}

const createInviteePlaceholder = (): Invitee => ({
  email: '',
  group: undefined,
  roles: [],
});

function UserInfoAvatar({
  name,
  email,
  picture,
}: {
  name: string;
  email: string;
  picture: string;
}): ReactElement {
  return (
    <HStack gap={4}>
      <AvatarProxy name={name} boxSize={10} src={picture} ignoreFallback />
      <Box noOfLines={2}>
        <Text fontWeight={'semibold'} fontSize={'sm'}>
          {name}
        </Text>
        <Text fontSize={'xs'}>{email}</Text>
      </Box>
    </HStack>
  );
}

export default function Invitation() {
  const toast = useToast();
  const [invitees, setInvitees] = useState<Invitee[]>(() => [
    createInviteePlaceholder(),
  ]);
  const { setInSettings } = useContext(SidebarContext);
  const isInvitationEnabled =
    invitees.length > 0 &&
    invitees.every(
      invitee => invitee.email && invitee.group && invitee.roles.length > 0,
    );

  useEffect(() => {
    setInSettings(true);
    return () => {
      setInSettings(false);
    };
  }, []);

  const {
    data: invites = [],
    refetch,
    isFetching,
  } = useQuery(
    ['user-invites'],
    async () => {
      const response = await API.GetUserInvitations();
      return response.results.map(invite => ({
        ...invite,
        invite_link: `${window.location.origin}/?invite=${invite.cuid}`,
      }));
    },
    { initialData: [] },
  );

  const pendingInvites = invites.filter(
    invitation => invitation.status === 'pending',
  );
  const inviteHistory = invites.filter(
    invitation => invitation.status === 'accepted',
  );

  const handleSentInvitations = async () => {
    try {
      await Promise.all(
        invitees.map(invitee => {
          const { email, group, roles } = invitee;
          const groupCuids = group?.cuid ? [group.cuid] : [];
          return API.PostUserInvitation(
            email,
            groupCuids,
            roles.map(role => role.cuid),
          );
        }),
      );
      refetch();
      setInvitees([createInviteePlaceholder()]);
      toast({
        variant: 'subtle',
        status: 'success',

        isClosable: true,
        title: 'Invitation successfully sent',
        description: 'The user will be notified of your invitation.',
      });
    } catch (error) {
      toast({
        variant: 'subtle',
        status: 'error',

        isClosable: true,
        title: 'Invitation not sent',
        description: API.getAPIErrorMessage(error),
      });
    }
  };

  const pendingInvitesColumns = [
    {
      Header: 'Email',
      accessor: 'email',
    },
    {
      Header: 'Date Sent',
      accessor: 'created_at',
      Cell: ({ value }: CellProps<TUserInvite, TUserInvite['created_at']>) => (
        <Text>{displayFormattedDate(value)}</Text>
      ),
    },
    {
      Header: 'Group',
      accessor: 'groups',
      Cell: ({ value }: CellProps<TUserInvite, TUserInvite['groups']>) => (
        <Text>{value[0].name}</Text>
      ),
    },
    {
      Header: 'Role',
      accessor: 'roles',
      Cell: ({
        value: roles,
      }: CellProps<TUserInvite, TUserInvite['roles']>) => {
        return (
          <HStack gap={2}>
            {roles
              .filter(r => r.scope != 'Model')
              .map(role => (
                <RolePill key={role.cuid} role={role} />
              ))}
          </HStack>
        );
      },
    },
    {
      Header: 'Invite Link',
      accessor: 'invite_link',
      Cell: ({ value }: CellProps<TUserInvite, string>) => {
        const { hasCopied, onCopy } = useClipboard(value || '');

        return (
          <Tooltip
            label={hasCopied ? 'Copied!' : 'Copy invite link'}
            placement="top"
          >
            <Button
              leftIcon={<Icon as={ClipboardDocumentIcon} />}
              size="sm"
              variant="ghost"
              onClick={onCopy}
            >
              Copy Link
            </Button>
          </Tooltip>
        );
      },
    },
  ];

  const invitesHistoryColumns = [
    {
      Header: 'Name',
      accessor: 'joined_user',
      Cell: ({ value }: CellProps<TUserInvite, TUserInvite['joined_user']>) =>
        value ? (
          <UserInfoAvatar
            name={value.name}
            email={value.email}
            picture={value.picture}
          />
        ) : (
          'N/A'
        ),
    },
    {
      Header: 'Date Joined',
      accessor: 'joined_date',
      Cell: ({ value }: CellProps<TUserInvite, TUserInvite['joined_date']>) => (
        <Text>{value ? displayFormattedDate(value) : 'N/A'}</Text>
      ),
    },
    {
      Header: 'Invited By',
      accessor: 'invited_by_user',
      Cell: ({
        value,
      }: CellProps<TUserInvite, TUserInvite['invited_by_user']>) =>
        value ? (
          <UserInfoAvatar
            name={value.name}
            email={value.email}
            picture={value.picture}
          />
        ) : (
          'N/A'
        ),
    },
  ];

  return (
    <VStack
      alignItems="start"
      spacing={0}
      paddingTop={12}
      mt={1}
      paddingBottom={16}
      px={14}
      gap={8}
      w="full"
      overflow="auto"
      className="no-scrollbar"
      maxWidth={'7xl'}
      mx={'auto'}
    >
      <Box>
        <Breadcrumbs />
        <ContentPageTitle>
          Invite New Users
          <MoreInfoPopOver
            title="User Invitations"
            link={`${CONFIG.VALIDMIND_DOCS_URL}/guide/configuration/manage-users.html#user-invitations`}
            placement="right-end"
            iconProps={{
              ml: 2,
            }}
          />
        </ContentPageTitle>
      </Box>
      <Text fontSize="md">
        Invite users by adding their email addresses below.
      </Text>
      <Stack width={'full'} spacing={4}>
        <Heading as="h3">Invite by Email</Heading>
        <TableContainer
          borderRadius={'lg'}
          borderWidth={1}
          w={'full'}
          overflowY={'auto'}
        >
          <Table variant="unstyled" data-testid={'invitation-table'}>
            <Thead>
              <Tr>
                <Th>
                  <Label>EMAIL</Label>
                </Th>
                <Th>
                  <Label>GROUP</Label>
                </Th>
                <Th>
                  <Label>ROLE</Label>
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {invitees?.map((invitee, idx) => (
                <Tr key={idx}>
                  <Td>
                    <Input
                      data-testid="invitee-email-input"
                      placeholder="example@email.com"
                      value={invitee.email}
                      onChange={e =>
                        setInvitees(prevArr => {
                          const result = [...prevArr];
                          result[idx].email = e.target.value;
                          return result;
                        })
                      }
                    />
                  </Td>
                  <Td>
                    <GroupSelect
                      group={invitee.group}
                      onSelect={group =>
                        setInvitees(prevArr => {
                          const result = [...prevArr];
                          result[idx].group = group;
                          return result;
                        })
                      }
                    />
                  </Td>
                  <Td>
                    <InvitationRolesPicker
                      assignedRoles={invitee.roles}
                      onAssignRoles={roles =>
                        setInvitees(prevArr => {
                          const result = [...prevArr];
                          result[idx].roles = roles;
                          return result;
                        })
                      }
                      onRemoveRole={role =>
                        setInvitees(prevArr => {
                          const result = [...prevArr];
                          const assignedRoles = result[idx].roles;
                          result[idx].roles = assignedRoles.filter(
                            assignedRole => assignedRole.cuid !== role.cuid,
                          );
                          return result;
                        })
                      }
                    />
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
          <HStack justifyContent="flex-end" p={6} py={4}>
            <Button
              data-testid="send-invitation-button"
              isDisabled={!isInvitationEnabled}
              variant={'primary'}
              leftIcon={<Icon as={EnvelopeIcon} boxSize={5} />}
              onClick={handleSentInvitations}
            >
              Send Invite
            </Button>
          </HStack>
        </TableContainer>
      </Stack>
      {invites.length && (
        <Stack w="full" spacing={4} data-testid="pending-invitation">
          <Heading as="h3">Pending Invites</Heading>
          <LoadingContainer isLoading={isFetching}>
            <DataTable data={pendingInvites} columns={pendingInvitesColumns} />
          </LoadingContainer>
        </Stack>
      )}
      {invites.length && (
        <Stack w="full" spacing={4} data-testid="invitation-history">
          <Heading as="h3">Invite History</Heading>
          <LoadingContainer isLoading={isFetching}>
            {inviteHistory.length === 0 ? (
              <Text fontStyle={'italic'}>
                None of your invites have been accepted so far. Consider
                reaching out to the recipients directly, asking them to check
                their spam folder, or alternatively, you may want to consider
                resending the invites.
              </Text>
            ) : (
              <DataTable data={inviteHistory} columns={invitesHistoryColumns} />
            )}
          </LoadingContainer>
        </Stack>
      )}
    </VStack>
  );
}
