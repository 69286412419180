import {
  FormControl,
  Input,
  Select,
  Stack,
  Tag,
  TagCloseButton,
  TagLabel,
  Wrap,
} from '@chakra-ui/react';
import { Edge, Node } from 'reactflow';
import { useEffect, useMemo, useState } from 'react';
import QueryBuilder, { RuleGroupType } from 'react-querybuilder';
import 'react-querybuilder/dist/query-builder.css';
import { formatQuery } from 'react-querybuilder/formatQuery';
import { parseJsonLogic } from 'react-querybuilder/parseJsonLogic';
import _ from 'lodash';
import { useStatusesWorkflows } from '../../../hooks/useStatuses';
import useQueryBuilder from '../../../hooks/useQueryBuilder';
import FieldForm from '../../FieldForm';
import { WorkflowCategoryNames } from '../../../../../../models/statuses_workflow';
import useWorkflow from '../../../../../../hooks/useWorkflow';
import { StatusFormNodeType } from '../../types';
import { Label } from '../../../../../../components/Layout';
import { controlElements } from '../BranchPanel/BranchItem';
import BasePanel from '../BasePanel';

interface StatusFormPanelProps {
  node: StatusFormNodeType;
  onAddNode: (node: StatusFormNodeType) => void;
  onDeleteNode: (node: StatusFormNodeType) => void;
  onAddEdge?: (edge: Edge) => void;
}

const StatusFormPanel = ({
  node,
  onAddNode,
  onDeleteNode,
}: StatusFormPanelProps) => {
  const { workflow } = useWorkflow();
  const [tempNode, setTempNode] = useState<Node>();
  const { data: statusesWorkflows } = useStatusesWorkflows();
  const { query, setQuery, fields } = useQueryBuilder();

  useEffect(() => {
    setTempNode(node);
    if (node && node.data.transition_callbacks.conditions.length > 0) {
      setQuery(
        parseJsonLogic(
          JSON.parse(node.data.transition_callbacks.conditions[0].args.query),
        ) as RuleGroupType,
      );
    }
  }, [node]);

  const statusByCategoryInWorkflow = useMemo(() => {
    return statusesWorkflows?.filter(category =>
      category.categories[0].startsWith(
        workflow?.trigger_id.startsWith('Project') ? 'projects' : 'models',
      ),
    );
  }, [statusesWorkflows]);

  const onAddField = (field: string) => {
    if (tempNode) {
      let clonedNode = _.cloneDeep(tempNode);
      clonedNode.data.form.required_fields.push(field);
      clonedNode.data.transition_callbacks.prepare[0].args.required_fields.push(
        field,
      );
      clonedNode.data.state_callbacks.on_enter[1].args.required_fields.push(
        field,
      );
      setTempNode(clonedNode);
    }
  };

  const onRemoveField = (field: string) => {
    if (tempNode) {
      let clonedNode = _.cloneDeep(tempNode);
      clonedNode.data.form.required_fields =
        clonedNode.data.form.required_fields.filter((f: string) => f !== field);

      clonedNode.data.transition_callbacks.prepare[0].args.required_fields =
        clonedNode.data.transition_callbacks.prepare[0].args.required_fields.filter(
          (f: string) => f !== field,
        );

      clonedNode.data.state_callbacks.on_enter[1].args.required_fields =
        clonedNode.data.state_callbacks.on_enter[1].args.required_fields.filter(
          (f: string) => f !== field,
        );
      setTempNode(clonedNode);
    }
  };

  const onStatusChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    // const selected = statuses?.statuses.find(s => s.cuid === e.target.value);
    const flattened = statusesWorkflows?.flatMap(item =>
      item.statuses.map(subItem => ({
        ...subItem,
        entity: item.categories[0].startsWith('projects')
          ? 'Project'
          : 'InventoryModel',
      })),
    );
    const selected = flattened?.find(s => s.cuid === e.target.value);

    if (tempNode && selected) {
      let clonedNode = _.cloneDeep(tempNode);
      clonedNode.data.state_callbacks.on_enter[0].args.entity = selected.entity;
      clonedNode.data.state_callbacks.on_enter[0].args.cuid = selected.cuid;
      setTempNode(clonedNode);
    }
  };

  const onLabelChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (tempNode) {
      let clonedNode = _.cloneDeep(tempNode);
      clonedNode.data.form.buttonLabel = e.target.value;
      setTempNode(clonedNode);
    }
  };

  const onSave = () => {
    if (tempNode) {
      const clonedNode = _.cloneDeep(tempNode);
      clonedNode.data.transition_callbacks.conditions = [
        {
          func: 'jsonlogic',
          args: {
            query: JSON.stringify(formatQuery(query, 'jsonlogic')),
          },
        },
      ];
      onAddNode(clonedNode);
    }
  };

  return (
    <BasePanel
      title={'Configure User Action'}
      node={tempNode}
      onDeleteNode={onDeleteNode}
      onSave={onSave}
    >
      <FormControl>
        <Label mb={2}>Display action button when</Label>

        <Stack>
          <QueryBuilder
            fields={fields}
            query={query}
            onQueryChange={setQuery}
            controlElements={controlElements}
          />
        </Stack>
      </FormControl>

      <FormControl>
        <Label mb={2}>Action button Label</Label>
        <Input
          size="sm"
          value={tempNode?.data?.form.buttonLabel || ''}
          onChange={onLabelChange}
          focusBorderColor="brand.base"
          rounded={'md'}
        />
      </FormControl>

      <FormControl>
        <Label mb={2}>Request fields</Label>

        <FieldForm
          addedFields={tempNode?.data.form.required_fields || []}
          onAddField={onAddField}
        />
        <Wrap gap={1} p={2} w={96}>
          {(tempNode?.data.form.required_fields || []).map(
            (fieldName: string) => (
              <Tag
                key={fieldName}
                fontSize={'xs'}
                fontFamily={'monospace'}
                colorScheme="purple"
              >
                <TagLabel>{fieldName}</TagLabel>
                <TagCloseButton
                  onClick={() => {
                    onRemoveField(fieldName);
                  }}
                />
              </Tag>
            ),
          )}
        </Wrap>
      </FormControl>

      <FormControl>
        <Label mb={2}>To set status</Label>
        <Select
          value={tempNode?.data.state_callbacks.on_enter[0].args.cuid || ''}
          onChange={onStatusChange}
          size="sm"
          rounded={'md'}
          focusBorderColor="brand.base"
        >
          <option value="">Select</option>

          {statusByCategoryInWorkflow?.map((category, index) => (
            <optgroup
              key={index}
              label={
                WorkflowCategoryNames[category.categories[0] as keyof object]
              }
            >
              {category.statuses
                .filter(status => status.name !== 'Deleted')
                .map(status => (
                  <option key={status.cuid} value={status.cuid}>
                    {status.name}
                  </option>
                ))}
            </optgroup>
          ))}
        </Select>
      </FormControl>
    </BasePanel>
  );
};

export default StatusFormPanel;
