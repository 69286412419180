import axios, { AxiosError } from 'axios';
import { CONFIG } from '../config';
import { TAttachment } from '../models/attachment';
import { TFeatureFlag } from '../models/flags';
import {
  FindingFilters,
  TFinding,
  TFindingFiltersOptions,
  TFindingPaginated,
  TSeverity,
} from '../models/finding';
import {
  TInventoryModelMetadata,
  TInventoryModelMetadataContentIdEnum,
} from '../models/metadata';
import {
  OfflineDocumentationFile,
  Template,
  TemplateSection,
  TemplateSectionContents,
  TemplateValidation,
  TemplateVersion,
} from '../models/template';
import {
  TUser,
  TUserInvite,
  TUserWithoutRoles,
  UISettings,
} from '../models/user';
import {
  RolePatchRequest,
  TAssignedPermission,
  TPermissionsOverview,
  TRolePermissions,
  TRoleRequest,
  TUserRole,
  TUserRolePaginated,
} from '../models/role';
import {
  TCreateUseCaseRequest,
  TUseCase,
  TUseCaseCategory,
  TUseCasePaginated,
} from '../models/use_case';
import {
  APIPaginatedResponse,
  APIRequestSortBy,
  keyable,
} from '../models/utils';
import { TInventoryModelSearchResult } from '../models/search';
import { TDatasetField } from '../models/dataset_field';
import { TRole } from '../models';
import {
  TStatusesWorkflow,
  TStatusesWorkflowStatus,
} from '../models/statuses_workflow';
import {
  RJSFSchemaSettings,
  RJSFSchemaSettingsProperty,
  TInventoryModel,
  TInventoryModelCustomField,
  TInventoryModelDependency,
  TInventoryModelDocumentationSummary,
  TInventoryModelPaginated,
  TInventoryModelPost,
  TInventoryModelSchema,
  TInventoryModelUser,
  TInventoryModelUserPost,
  TInventoryModelValidationReportSummary,
  TModelInventoryFilters,
} from '../models/inventory_model';
import { EventFilters, TEventPaginated } from '../models/event';
import {
  TBusinessUnit,
  TCreateBusinessUnitRequest,
} from '../models/business_unit';
import {
  CommentsAdapterThreadAdded,
  TrackChangesAdapterSuggestionAdded,
  TrackChangesAdapterSuggestionUpdated,
  TSuggestion,
} from '../models/ckeditor';
import { TGroup, TGroupMembership } from '../models/group';
import {
  getModelDocumentType,
  ModelDocument,
  ModelDocumentTypeEnum,
} from '../models/model_document';
import {
  Assessment,
  AssessmentFinding,
  AssessmentOption,
  AssessmentOptionWithSummary,
  Guideline,
  GuidelineDependencies,
  GuidelineRequest,
} from '../models/guideline';
import { SchemaPropertyItem } from '../models/json_schemas';
import { RJSFSchema } from '@rjsf/utils';
import { JSONSchema7 } from 'json-schema';
import {
  TAddRiskAreaRequest,
  TRiskArea,
  TRiskAreaDependencies,
} from '../models/risk_area';
import {
  TDashboard,
  TDashboardItem,
  TDashboardItemTypes,
  TDashboardLayout,
  TDashboardVisualization,
  TDashboardVisualizationJSON,
  TReportGrouping,
  TReportingDataset,
  TReportMetric,
  TReportSorting,
  TWidget,
} from '../models/report';
import {
  EntityEventType,
  EntityName,
  Execution,
  ExpectedDurationUnit,
  RecurrenceUnit,
  ScheduleType,
  Workflow,
  WorkflowExecutionsResponse,
  WorkflowPaginated,
  WorkflowSource,
  WorkflowStatusForm,
  WorkflowUserActionForm,
} from '../models/workflow';
import { FieldValues } from '../hooks/useFields';
import { ApprovalVote, ApprovalVoter } from '../models/approval';
import { CodeTestResponse } from '../models/custom_field_code';
import { EntityAttachment, EntityType } from '../models/entity_attachments';
import { TUnitMetric } from '../models/unit_metric';
import { RuleGroupType } from 'react-querybuilder';
import { IFilterProps } from '../components/InputInfoModal';
import { TSavedView, TSavedViewTypes } from '../models/saved_view';
import { State, StateRequest } from '../models/workflow_state';
import { BlockConfig } from '../components/Templates/BlockWrapper';
import { v4 as uuidv4 } from 'uuid';
import {
  TAttestation,
  TAttestationExecution,
  TAttestationSchedule,
  TAttestationStatuses,
  TAttestationTransition,
} from '../models/attestation';
import {
  TBlockLibraryItem,
  TBlockLibraryItemCreate,
  TBlockLibraryItemUpdate,
} from '../models/block_library';

export type TInventoryModelAnnotation = {
  json: keyable;
  target: {
    cuid: string;
    type: string;
  };
  cuid?: string;
  created_at?: string;
  updated_at?: string;
  user?: TUser;
  comments?: TInventoryModelAnnotationComment[];
  context: {
    type: string;
    value: string;
  };
};

export type TInventoryModelAnnotationCKEditor = {
  threadId: string;
  context: keyable;
  attributes: keyable;
  resolvedAt: string | Date | null;
  resolvedBy: string | null;
  comments: TInventoryModelAnnotationCommentCKEditor[];
  createdAt: string | Date;
};

export type TInventoryModelAnnotationPaginated =
  APIPaginatedResponse<TInventoryModelAnnotation>;

export type TInventoryModelAnnotationComment = {
  text: string;
  cuid?: string;
  created_at?: string;
  updated_at?: string;
  user?: TUser;
  parent_cuid?: string | null;
  path?: string;
};

export type TInventoryModelAnnotationCommentCKEditor = {
  attributes: keyable;
  createdAt: string | Date;
  content: string;
  authorId: string;
  commentId: string;
};

export type TFigure = {
  cuid: string;
  filename: string;
  key: string;
  metadata: keyable;
  type: string;
  url: string;
  json_url: string;
  created_at: number;
  updated_at: number;
};

export type TFieldStatistics = {
  count: number;
  histogram: any;
  // Will replace histogram
  histograms: any;
  missing: number;
  n_missing: number;
  name: string;
  type: string;
  mean: number;
  std: number;
  min: number;
  max: number;
  '25%': number;
  '50%': number;
  '75%': number;
};

export type TDatasetStatistics = {
  [field: string]: TFieldStatistics;
};

export type TFieldCorrelation = {
  field: string;
  value: number;
  p_value?: number;
};

export type TDatasetCorrelations = {
  pearson: [[TFieldCorrelation]];
};

export type TDataset = {
  type: string;
  correlations: TDatasetCorrelations;
  fields: TDatasetField[];
  shape?: {
    rows: number;
    columns: number;
  };
  statistics: TDatasetStatistics;
  targets: {
    target_column: string;
    description?: string;
    class_labels: object;
  };
};

export enum FeatureFlags {
  vmai_suggestions = 'vmai-suggestions',
  vmai_interpretations = 'vmai-interpretations',
}

export type TSubscription = {
  cuid: string;
  name: string;
  type: string;
};

export type TOrganization = {
  cuid: string;
  name: string;
  api_clients: [
    {
      api_key: string;
      api_secret: string;
    },
  ];
  feature_flags: keyable;
  default_group: TGroup;
  roles: TRole[];
  subscription: TSubscription;
  inventory_model_schema: {
    custom: TInventoryModelSchema;
    core: TInventoryModelSchema;
  };
};

export type TOrganizationConnection = {
  connection: string;
};

export type TSummaryItem = {
  type: string;
  data: any;
  metadata?: {
    title?: string;
  };
};

export type TInputResult = {
  id: number;
  cuid: string;
  name: string;
  type: 'model' | 'dataset';
  metadata: any;
  created_at: string;
  updated_at: string;
};

export type TFiltersResult = {
  date_range: {
    start_date: number;
    end_date: number;
  };
  ran_by: {
    cuid: string;
    name: string;
  }[];
  models: string[];
  datasets: string[];
};

export type TComment = {
  user: {
    cuid: string;
    name: string;
    email: string;
    picture: string;
  };
  created_at: number;
  cuid: number;
  section: string;
  text: string;
  title: string;
  item_cuid: string;
  item_type: string;
  attachments: TAttachment[];
  updated_at: number;
};

export type TTestResult = {
  cuid: string;
  test_name: string;
  title?: string;
  passed: boolean;
  params: keyable;
  summary?: TSummaryItem[];
  created_at: number;
  ref_id?: string;
  inputs: TInputResult[];
  user?: TUser;
  content_type: ModelDocumentTypeEnum;
};

export type TTestResultKey = {
  cuid: string;
  test_name: string;
  passed: boolean;
};

export type TTest = {
  key: string;
  name: string;
  tag: string;
  models: string[];
  datasets: string[];
  created_at: string;
  user: TUser;
};

export type TFindingRequest = {
  title: string;
  description: string;
  risk_area_cuid: string;
  metadata?: {
    severity?: string;
  };
  severity?: number;
  due_at?: number;
  owner_cuid?: string;
  documentation_section_id: string | null;
};

type PagedResponse<T> = {
  limit: number;
  page: number;
  results: T[];
  total: number;
};

export let getAccessToken: (() => string | undefined) | null = null;
let getOrganization: () => TOrganization | null;

export const setOrganizationRetrievalMethod = (
  OrganizationRetrievalMethod: () => TOrganization | null,
) => {
  getOrganization = OrganizationRetrievalMethod;
};

export const setTokenRetrievalMethod = (
  tokenRetrievalMethod: () => string | undefined,
) => {
  getAccessToken = tokenRetrievalMethod;
};

const axiosInstance = axios.create({
  baseURL: `${CONFIG.REACT_APP_API_URL}`,
});

axiosInstance.interceptors.request.use(async config => {
  // Always add X-Request-Id header with UUID
  config.headers = {
    ...config.headers,
    'X-Request-Id': uuidv4(),
  };

  // Add organization header if available
  if (getOrganization) {
    let org = getOrganization();
    if (org) {
      config.headers['X-Organization-Id'] = org.cuid;
    }
  }

  // Add authorization header if available
  if (getAccessToken) {
    try {
      const token = getAccessToken();
      config.headers['Authorization'] = `Bearer ${token}`;
    } catch (error) {
      console.error('Failed to get access token:', error);
    }
  }

  return config;
});

export const AuthHeaders = (accessToken: string): {} => ({
  Authorization: `Bearer ${accessToken}`,
});

const GetCurrentUser = async (): Promise<TUser> => {
  const result = await axiosInstance.get('/current_user');
  return result.data;
};

const PatchCurrentUserUISettings = async (
  uiSettings: UISettings,
): Promise<TUser> => {
  const result = await axiosInstance.patch('/users/ui-settings', {
    ...uiSettings,
  });
  return result.data;
};

const PatchUserOrganization = async (
  currentUserId: string | undefined,
  userPropertiesToUpdate?: any,
): Promise<TUser> => {
  const result = await axiosInstance.patch(`/users/${currentUserId}`, {
    ...userPropertiesToUpdate,
  });
  return result.data;
};

const GetOrganization = async (): Promise<TOrganization> => {
  const result = await axiosInstance.get('/organization');
  return result.data;
};

const GetOrganizationConnection = async (
  domain: string,
): Promise<TOrganizationConnection> => {
  const result = await axiosInstance.get(`/organization/sso?domain=${domain}`);
  return result.data;
};

const GetOrganizationAssessmentOptions = async (): Promise<
  AssessmentOption[]
> => {
  const result = await axiosInstance.get('/organizations/assessment-options');
  return result.data;
};

const GetOrganizationUsers = async (): Promise<TUserWithoutRoles[]> => {
  const result = await axiosInstance.get('/organization/users');
  return result.data;
};

const GetOrganizationBusinessUnits = async (): Promise<TBusinessUnit[]> => {
  const result = await axiosInstance.get('/organization/business-units');
  return result.data;
};

const PostOrganizationBusinessUnits = async (
  businessUnit: TCreateBusinessUnitRequest,
): Promise<TBusinessUnit> => {
  const result = await axiosInstance.post(
    '/organization/business-units',
    businessUnit,
  );
  return result.data;
};

const DeleteOrganizationBusinessUnit = async (
  businessUnitCuid: string,
): Promise<TBusinessUnit> => {
  const result = await axiosInstance.delete(
    `/organization/business-units/${businessUnitCuid}`,
  );
  return result.data;
};

const PatchOrganizationBusinessUnit = async (
  businessUnitCuid: string,
  newTitle: string,
): Promise<TBusinessUnit> => {
  const result = await axiosInstance.patch(
    `/organization/business-units/${businessUnitCuid}`,
    { name: newTitle },
  );
  return result.data;
};

const GetOrganizationRoles = async (): Promise<TRole[]> => {
  const result = await axiosInstance.get('/organization/roles');
  return result.data;
};

const GetDocument = async (
  inventoryModel: TInventoryModel,
  documentType?: string,
): Promise<TInventoryModel> => {
  let url = `/inventory-models/${inventoryModel.cuid}/document/${documentType}`;
  const result = await axiosInstance.get(url);
  return result.data;
};

const GetInventoryModelSearch = async (
  inventoryModel: TInventoryModel,
  query: string,
): Promise<TInventoryModelSearchResult[]> => {
  const result = await axiosInstance.get(
    `/inventory-models/${inventoryModel.cuid}/search?q=${query}`,
  );
  return result.data;
};

const GetModelInventoryMetadataByContentId = async (
  inventoryModel: TInventoryModel,
  contentId: TInventoryModelMetadataContentIdEnum | string,
  contentType: string,
  truncateAt?: number,
  truncateBy: 'characters' | 'words' = 'characters',
  appendString: string = '...',
): Promise<TInventoryModelMetadata> => {
  const params: Record<string, any> = { content_type: contentType };
  // Add truncation parameters if provided
  if (truncateAt !== undefined) {
    params.truncateAt = truncateAt;
    params.truncateBy = truncateBy;
    params.appendString = appendString;
  }

  const result = await axiosInstance.get(
    `/inventory-models/${inventoryModel.cuid}/metadata/${contentId}`,
    {
      params,
    },
  );
  return result.data;
};

export type AnnotationFilters = {
  inventoryModels?: string[];
  targets?: string[];
};

const GetAnnotations = async (
  filters?: AnnotationFilters,
  page?: number,
  limit?: number,
): Promise<TInventoryModelAnnotationPaginated> => {
  let params: any = { ...filters, page, limit };
  const result = await axiosInstance.get(`/annotations`, {
    params,
  });
  return result.data;
};

const PostInventoryModelMetadata = async (
  inventoryModel: TInventoryModel,
  documentType: ModelDocumentTypeEnum,
  metadata: TInventoryModelMetadata,
  silent?: boolean,
  isComment?: boolean,
): Promise<TInventoryModelMetadata> => {
  delete metadata.created_at;
  delete metadata.updated_at;
  var url = `/inventory-models/${inventoryModel.cuid}/metadata/${documentType}`;
  if (isComment) {
    url = `${url}/for-comments`;
  }
  if (silent) {
    url = `${url}?silent=${silent}`;
  }
  const result = await axiosInstance.post(url, { ...metadata });
  return result.data;
};

const PostInventoryModelMetadataAttachment = async (
  inventoryModelCuid: string,
  content_id: string,
  file: File,
): Promise<TInventoryModelMetadata> => {
  // this method is not used in the codebase
  const formData = new FormData();
  formData.append(`attachments[]`, file);

  const result = await axiosInstance.post(
    `/inventory-models/${inventoryModelCuid}/metadata/${content_id}/attachments`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );
  return result.data;
};

const GetInventoryModelFigures = async (
  inventoryModelCuid: string,
  metadata?: keyable,
): Promise<TFigure[]> => {
  const baseUrl = `${CONFIG.REACT_APP_API_URL}/inventory-models/${inventoryModelCuid}/figures`;
  const url = metadata
    ? `${baseUrl}?metadata=${JSON.stringify(metadata)}&isByModelId=true`
    : `${baseUrl}?isByModelId=true`;
  const result = await axiosInstance.get(url);
  return result.data;
};

const GetInventoryModelFigureByKey = async (
  inventoryModelCuid: string,
  key: string,
): Promise<TFigure> => {
  // this method is not used in the codebase
  const result = await axiosInstance.get(
    `/inventory-models/${inventoryModelCuid}/figure/${key}`,
  );
  return result.data;
};

const GetEvents = async (
  page: number = 1,
  limit: number = 50,
  filters?: EventFilters,
  sortBy?: APIRequestSortBy,
  fromDate?: string,
  toDate?: string,
): Promise<TEventPaginated> => {
  let params: any = { ...filters, page, limit };
  if (sortBy) {
    params = { ...params, sort_by: sortBy.field, sort_order: sortBy.order };
  }

  if (fromDate) {
    params.from_date = fromDate;
  }

  if (toDate) {
    params.to_date = toDate;
  }

  const result = await axiosInstance.get('/events', {
    params,
  });
  return result.data;
};

const GetFeatureFlags = async (flags: any): Promise<TFeatureFlag[]> => {
  const result = await axiosInstance.post('/flags', {
    flags,
  });
  return result.data;
};

const GetTestResultsFiltersForInventoryModel = async (
  inventoryModelCuid: string,
  key: string,
  contentType?: ModelDocumentTypeEnum,
  page?: number,
  limit?: number,
): Promise<IFilterProps> => {
  const config = {
    params: {
      page,
      limit,
      content_type: contentType,
    },
  };
  const result = await axiosInstance.get(
    `${CONFIG.REACT_APP_API_URL}/inventory-models/${inventoryModelCuid}/test_results/${key}/history/filters`,
    config,
  );
  return result.data;
};

const GetCommentsForInventoryModel = async (
  inventoryModel: TInventoryModel,
  itemCuid?: string,
): Promise<TComment[]> => {
  const result = await axiosInstance.get(
    `/inventory-models/${inventoryModel.cuid}/comments?inventory_model_cuid=${inventoryModel.cuid}&item_cuid=${itemCuid}`,
  );
  return result.data;
};

const GetTestResults = async (
  inventoryModelCuid: string,
): Promise<TTestResult[]> => {
  const result = await axiosInstance.get(
    `/inventory-models/${inventoryModelCuid}/test_results?isByModelId=true`,
  );
  return result.data;
};

const GetTestResultForInventoryModel = async (
  inventoryModelCuid: string,
  name: string,
  contentType?: ModelDocumentTypeEnum,
  fields: string[] = ['all'],
): Promise<TTestResult> => {
  const result = await axiosInstance.get(
    `/inventory-models/${inventoryModelCuid}/test_results/${name}?isByModelId=true`,
    {
      params: {
        fields,
        content_type: contentType,
      },
    },
  );
  return result.data;
};

const GetTestResultHistoryForInventoryModel = async (
  inventoryModelCuid: string,
  name: string,
  contentType?: ModelDocumentTypeEnum,
  page?: number,
  limit?: number,
  params?: Record<string, any>,
  sortBy?: string,
  sortOrder?: string,
): Promise<PagedResponse<TTestResult>> => {
  const config = {
    params: {
      page,
      limit,
      content_type: contentType,
      ...params,
      sort_by: sortBy,
      sort_order: sortOrder,
    },
  };

  const result = await axiosInstance.get(
    `/inventory-models/${inventoryModelCuid}/test_results/${name}/history`,
    config,
  );
  return result.data;
};

const PostCommentForInventoryModel = async (
  inventoryModel: TInventoryModel,
  title: string,
  text: string,
  item_type: string,
  item_cuid: string,
  attachments?: File[],
): Promise<any> => {
  const formData = new FormData();
  formData.append('title', title);
  formData.append('text', text);
  formData.append('item_type', item_type);
  formData.append('item_cuid', item_cuid);

  if (attachments) {
    for (let i = 0; i < attachments.length; i++) {
      formData.append(`attachments[]`, attachments[i]);
    }
  }

  const result = await axiosInstance.post(
    `/inventory-models/${inventoryModel.cuid}/comments`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );
  return result.data;
};

const UpdateOrganization = async (name: string): Promise<TOrganization> => {
  const result = await axiosInstance.put('/organization', {
    name,
  });
  return result.data;
};

const GetTemplates = async (includeCopies?: boolean): Promise<Template[]> => {
  let params = {};
  if (includeCopies) {
    params = {
      include_copies: includeCopies,
    };
  }
  const result = await axiosInstance.get(`/templates`, {
    params,
  });
  return result.data;
};

const GetTemplateVersion = async (
  templateId: string,
  versionId: string = 'latest',
): Promise<Template> => {
  const result = await axiosInstance.get(
    `/templates/${templateId}?version=${versionId}`,
  );
  return result.data;
};

const SwapTemplate = async (
  inventoryModel: TInventoryModel,
  toTemplateId: string,
  toVersionId: string,
  notes: string,
  documentType: 'model_documentation' | 'validation_report' | 'monitoring',
): Promise<Template> => {
  const result = await axiosInstance.post(
    `/inventory-models/${inventoryModel.cuid}/templates/${documentType}/latest/swap/${toTemplateId}/${toVersionId}`,
    { notes },
  );
  return result.data;
};

const SetTemplate = async (
  inventoryModel: TInventoryModel,
  documentType: 'model_documentation' | 'validation_report' | 'monitoring',
  templateCuid: string,
): Promise<Template> => {
  const result = await axiosInstance.post(
    `/inventory-models/${inventoryModel.cuid}/templates/${documentType}`,
    {
      template: templateCuid,
    },
  );
  return result.data;
};

const UpdateTemplate = async (
  templateId: string,
  templateType: 'model_documentation' | 'validation_report',
  description: string,
  json: any,
): Promise<Template> => {
  const result = await axiosInstance.put(
    `/templates/${templateId}?type=${templateType}`,
    {
      description,
      json,
    },
  );
  return result.data;
};

const ValidateTemplate = async (
  templateId: string,
  json: any,
): Promise<TemplateValidation> => {
  const result = await axiosInstance.post(`/templates/${templateId}/validate`, {
    json,
  });
  return result.data;
};

const GetFindingSeverities = async (): Promise<TSeverity[]> => {
  const result = await axiosInstance.get('/findings/severities');
  return result.data;
};

const GetFindings = async (
  page: number = 1,
  limit: number = 100,
  filters?: FindingFilters,
  sortBy?: APIRequestSortBy,
): Promise<TFindingPaginated> => {
  const filterParams: Record<string, string> = {};

  if (filters) {
    Object.keys(filters).forEach(filterKey => {
      const filterValue: any = filters[filterKey as keyof object];
      if (Array.isArray(filterValue)) {
        // Join array values with commas for the request
        filterParams[filterKey] = filterValue.join(',');
      } else if (typeof filterValue === 'boolean') {
        // Convert boolean to string
        filterParams[filterKey] = filterValue.toString();
      } else if (filterValue !== undefined && filterValue !== null) {
        // Handle numbers, strings, or other scalar values
        filterParams[filterKey] = String(filterValue);
      }
    });
  }

  // Build the request parameters
  let params: Record<string, string | number> = {
    ...filterParams,
    page,
    limit,
  };

  if (sortBy) {
    params = {
      ...params,
      sort_by: sortBy.field,
      sort_order: sortBy.order,
    };
  }

  const result = await axiosInstance.get('/findings', {
    params,
  });

  return result.data;
};

const GetFindingFilters = async (): Promise<TFindingFiltersOptions> => {
  const result = await axiosInstance.get('/findings/filters');
  return result.data;
};

const GetFinding = async (
  inventoryModel: TInventoryModel,
  findingCuid: string,
): Promise<TFinding> => {
  const result = await axiosInstance.get(
    `/inventory-models/${inventoryModel.cuid}/findings/${findingCuid}`,
  );
  return result.data;
};

const GetFindingLinkedAssessmentFindings = async (
  inventoryModel: TInventoryModel,
  findingCuid: string,
): Promise<AssessmentFinding[]> => {
  const result = await axiosInstance.get(
    `/inventory-models/${inventoryModel.cuid}/findings/${findingCuid}/assessments`,
  );
  return result.data;
};

const PostFinding = async (
  inventoryModel: TInventoryModel,
  findingRequestBody: TFindingRequest,
): Promise<TFinding> => {
  const result = await axiosInstance.post(
    `/inventory-models/${inventoryModel.cuid}/findings`,
    findingRequestBody,
  );
  return result.data;
};

const UpdateFinding = async (
  inventoryModel: TInventoryModel,
  findingCuid: string,
  findingObject: keyable,
): Promise<TFinding> => {
  const result = await axiosInstance.put(
    `/inventory-models/${inventoryModel.cuid}/findings/${findingCuid}`,
    findingObject,
  );
  return result.data;
};

const DeleteFinding = async (
  inventoryModelCuid: string,
  findingCuid: string,
): Promise<TFinding> => {
  const result = await axiosInstance.delete(
    `/inventory-models/${inventoryModelCuid}/findings/${findingCuid}`,
  );
  return result.data;
};

const PostFindingAttachment = async (
  inventoryModel: TInventoryModel,
  findingCuid: string,
  file: File,
): Promise<TInventoryModelMetadata> => {
  const formData = new FormData();
  formData.append(`attachments[]`, file);

  const result = await axiosInstance.post(
    `/inventory-models/${inventoryModel.cuid}/findings/${findingCuid}/attachments`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );
  return result.data;
};

const DownloadReport = async (
  documentType: string,
  inventoryModel: TInventoryModel,
  format: string,
  downloadFilename: string,
  includeComplianceSummary?: boolean,
  includeGuidelines?: boolean,
  includeGuidelineAdherence?: boolean,
): Promise<any> => {
  return await axiosInstance.get(
    `/reports/${inventoryModel.cuid}/${documentType}/${inventoryModel.cuid}`,
    {
      params: {
        include_compliance_summary: includeComplianceSummary,
        include_guidelines: includeGuidelines,
        include_guideline_adherence: includeGuidelineAdherence,
        format,
        downloadFilename,
      },
      responseType: format === 'json' ? 'text' : 'blob',
    },
  );
};

const GetStatusesWorkflows = async (): Promise<TStatusesWorkflow[]> => {
  const response = await axiosInstance.get(`/statuses-workflows`);
  return response.data;
};

const GetStatusesWorkflow = async (
  statusesWorkflowCuid: string,
): Promise<TStatusesWorkflow> => {
  const response = await axiosInstance.get(
    `/statuses-workflows/${statusesWorkflowCuid}`,
  );
  return response.data;
};

const PostStatusesWorkflowStatus = async (
  workflowCuid: string,
  workflowStatus: TStatusesWorkflowStatus,
): Promise<TStatusesWorkflowStatus> => {
  const response = await axiosInstance.post(
    `/statuses-workflows/${workflowCuid}/status`,
    { ...workflowStatus },
  );
  return response.data;
};

const PutStatusesWorkflowStatus = async (
  workflowCuid: string,
  workflowStatus: TStatusesWorkflowStatus,
): Promise<TStatusesWorkflowStatus> => {
  const response = await axiosInstance.put(
    `/statuses-workflows/${workflowCuid}/status`,
    { ...workflowStatus },
  );
  return response.data;
};

const DeleteStatusesWorkflowStatus = async (
  workflowCuid: string,
  workflowStatus: TStatusesWorkflowStatus,
): Promise<TStatusesWorkflowStatus> => {
  const response = await axiosInstance.delete(
    `/statuses-workflows/${workflowCuid}/status/${workflowStatus.cuid}`,
  );
  return response.data;
};

/**
 * Checks if the there is a response body from the Axios response
 * and if the response boyd has a "message" field. Return this
 * or default to returning the error message
 */
const getAPIErrorMessage = (error: any): string => {
  const axiosError = error as AxiosError;
  if (
    axiosError.response &&
    axiosError.response.data &&
    axiosError.response.data.message
  ) {
    return axiosError.response.data.message;
  }
  return axiosError.message;
};

const GetOrganizationUseCaseCategories = async (
  organization: TOrganization | null,
): Promise<PagedResponse<TUseCaseCategory>> => {
  const result = await axiosInstance.get(
    `/organization/${organization?.cuid}/use_cases/categories`,
  );
  return result.data;
};

const GetOrganizationUseCases = async (
  organization: TOrganization | null,
  filters?: TModelInventoryFilters,
  searchQuery?: string,
  page: number = 1,
  limit: number = 100,
  sortBy?: APIRequestSortBy,
): Promise<TUseCasePaginated> => {
  let parsedParams: any = { page, limit };

  if (sortBy) {
    parsedParams = {
      ...parsedParams,
      sort_by: sortBy.field,
      sort_order: sortBy.order,
    };
  }

  // prepare the filters by converting some objects to key,value pairs
  if (filters) {
    parsedParams = {
      ...parsedParams,
      ...filters,
    };
  }

  if (searchQuery) {
    parsedParams = {
      ...parsedParams,
      q: searchQuery,
    };
  }

  // Remove fields with null, undefined, or empty arrays
  parsedParams = Object.fromEntries(
    Object.entries(parsedParams).filter(([_, value]) => {
      return (
        value !== null &&
        value !== undefined &&
        !(Array.isArray(value) && value.length === 0)
      );
    }),
  );

  let params = new URLSearchParams(parsedParams as keyof object).toString();

  const result = await axiosInstance.get(
    `/organization/${organization?.cuid}/use_cases?${params}`,
  );
  return result.data;
};

const PostOrganizationUseCase = async (
  organization: TOrganization | null,
  useCase: TCreateUseCaseRequest,
): Promise<TUseCase> => {
  const result = await axiosInstance.post(
    `/organization/${organization?.cuid}/use_cases`,
    useCase,
  );
  return result.data;
};

const DeleteOrganizationUseCase = async (
  organization: TOrganization | null,
  useCaseCuid: string,
): Promise<void> => {
  await axiosInstance.delete(
    `/organization/${organization?.cuid}/use_case/${useCaseCuid}`,
  );
};

const GetModelInventory = async (
  columns: string[],
  filters?: TModelInventoryFilters,
  searchQuery?: string,
  page: number = 1,
  limit: number = 100,
  sortBy?: APIRequestSortBy,
): Promise<TInventoryModelPaginated> => {
  // prepare the get params to be added to the API url
  let params = '';

  // Always include cuid, name, model_id, tiering, users, created_at, updated_at at a minimum
  // Since they are used in the Cards and Table views
  const requiredColumns = [
    'cuid',
    'name',
    'model_id',
    'tiering',
    'users',
    'created_at',
    'updated_at',
  ];

  let parsedParams: any = {
    page,
    limit,
    columns: Array.from(new Set([...requiredColumns, ...columns])),
  };

  if (sortBy) {
    parsedParams = {
      ...parsedParams,
      sort_by: sortBy.field,
      sort_order: sortBy.order,
    };
  }

  // prepare the filters by converting some objects to key,value pairs
  if (filters) {
    parsedParams = {
      ...parsedParams,
      ...filters,
      owners: filters?.owners?.map(u => u.cuid) || [],
      developers: filters?.developers?.map(u => u.cuid) || [],
      validators: filters?.validators?.map(u => u.cuid) || [],
      business_units: filters?.business_units?.map(bu => bu.cuid) || [],
      status: filters?.status?.map(s => s.cuid) || [],
      is_vendor_model: filters?.is_vendor_model || undefined,
    };
  }

  if (searchQuery) {
    parsedParams = {
      ...parsedParams,
      q: searchQuery,
    };
  }

  // Remove fields with null, undefined, or empty arrays
  parsedParams = Object.fromEntries(
    Object.entries(parsedParams).filter(([_, value]) => {
      return (
        value !== null &&
        value !== undefined &&
        !(Array.isArray(value) && value.length === 0)
      );
    }),
  );

  params = new URLSearchParams(parsedParams as keyof object).toString();

  const result = await axiosInstance.get(`/inventory-models?${params}`);
  return result.data;
};

const GetModelInventoryFilters = async (
  customFields?: SchemaPropertyItem[],
): Promise<TModelInventoryFilters> => {
  let url = '/inventory-models/filters';
  if (customFields) {
    const keys = customFields.map(column => column.key).join(',');
    url = `${url}?custom_fields=${keys}`;
  }
  const result = await axiosInstance.get(url);
  return result.data;
};

const GetInventoryModel = async (cuid: string): Promise<TInventoryModel> => {
  const result = await axiosInstance.get(`/inventory-models/${cuid}`);
  return result.data;
};

const CreateInventoryModel = async (
  inventoryModel: TInventoryModelPost,
  allow_duplicates: boolean,
): Promise<TInventoryModel> => {
  const response = await axiosInstance.post(`/inventory-models`, {
    ...inventoryModel,
    allow_duplicates,
  });
  return response.data;
};

const PostInventoryModelUser = async (
  inventoryModel: TInventoryModel,
  inventoryModelUser: TInventoryModelUserPost,
): Promise<TInventoryModel> => {
  const response = await axiosInstance.post(
    `/inventory-models/${inventoryModel.cuid}/users`,
    { ...inventoryModelUser },
  );
  return response.data;
};

const DeleteInventoryModelUser = async (
  inventoryModel: TInventoryModel,
  inventoryModelUser: TInventoryModelUser,
): Promise<TInventoryModel> => {
  const response = await axiosInstance.delete(
    `/inventory-models/${inventoryModel.cuid}/users/${inventoryModelUser.cuid}`,
  );
  return response.data;
};

/**
 * Retrieve a JSON image from a pre-signed URL
 */
const GetJSONImage = async (url: string): Promise<string> => {
  const result = await axiosInstance.get(url);
  return result.data;
};

const PostAnnotation = async (
  inventoryModel: TInventoryModel,
  target: TInventoryModelMetadata,
  thread: CommentsAdapterThreadAdded,
): Promise<TInventoryModelAnnotationCKEditor> => {
  const result = await axiosInstance.post(
    `/inventory-models/${inventoryModel.cuid}/annotations`,
    { thread, target },
  );
  return result.data;
};

const GetAnnotation = async (
  inventoryModel: TInventoryModel,
  annotationId: string,
): Promise<TInventoryModelAnnotationCKEditor> => {
  const result = await axiosInstance.get(
    `/inventory-models/${inventoryModel.cuid}/annotations/${annotationId}`,
  );
  return result.data;
};

const PostComment = async (
  inventoryModel: TInventoryModel,
  threadId: string,
  content: string,
  commentId?: string,
): Promise<TInventoryModelAnnotationCommentCKEditor> => {
  const result = await axiosInstance.post(
    `/inventory-models/${inventoryModel.cuid}/annotations/${threadId}/comments`,
    {
      commentId,
      content,
    },
  );
  return result.data;
};

const PatchComment = async (
  inventoryModel: TInventoryModel,
  threadId: string,
  commentId: string,
  content: string,
): Promise<TInventoryModelAnnotationCommentCKEditor> => {
  const result = await axiosInstance.patch(
    `/inventory-models/${inventoryModel.cuid}/annotations/${threadId}/comments/${commentId}`,
    {
      content,
    },
  );
  return result.data;
};

const DeleteComment = async (
  inventoryModel: TInventoryModel,
  threadId: string,
  commentId: string,
): Promise<TInventoryModelAnnotationComment> => {
  const result = await axiosInstance.delete(
    `/inventory-models/${inventoryModel.cuid}/annotations/${threadId}/comments/${commentId}`,
  );
  return result.data;
};

const DeleteAnnotation = async (
  inventoryModel: TInventoryModel,
  threadId: string,
): Promise<TInventoryModelAnnotationComment> => {
  const result = await axiosInstance.delete(
    `/inventory-models/${inventoryModel.cuid}/annotations/${threadId}`,
  );
  return result.data;
};

const ResolveAnnotation = async (
  inventoryModel: TInventoryModel,
  threadId: string,
  resolved: boolean,
): Promise<TInventoryModelAnnotation> => {
  const result = await axiosInstance.patch(
    `/inventory-models/${inventoryModel.cuid}/annotations/${threadId}`,
    {
      resolved,
    },
  );
  return result.data;
};

const PostSuggestion = async (
  inventoryModel: TInventoryModel,
  target: TInventoryModelMetadata,
  suggestion: TrackChangesAdapterSuggestionAdded,
): Promise<TSuggestion> => {
  const result = await axiosInstance.post(
    `/inventory-models/${inventoryModel.cuid}/suggestions`,
    { suggestion, target },
  );
  return result.data;
};

const GetSuggestion = async (
  inventoryModel: TInventoryModel,
  suggestionId: string,
): Promise<TSuggestion> => {
  const result = await axiosInstance.get(
    `/inventory-models/${inventoryModel.cuid}/suggestions/${suggestionId}`,
  );
  return result.data;
};

const PatchSuggestion = async (
  inventoryModel: TInventoryModel,
  suggestionId: string,
  patchData: TrackChangesAdapterSuggestionUpdated,
): Promise<TSuggestion> => {
  const result = await axiosInstance.patch(
    `/inventory-models/${inventoryModel.cuid}/suggestions/${suggestionId}`,
    patchData,
  );
  return result.data;
};

const GetRevisions = async (
  inventoryModel: TInventoryModel,
  metadataId: string,
): Promise<any[]> => {
  const result = await axiosInstance.get(
    `/inventory-models/${inventoryModel.cuid}/revisions/${metadataId}`,
  );
  return result.data;
};

const PatchRevisions = async (
  inventoryModel: TInventoryModel,
  metadataId: string,
  revisionsData: any,
  documentType?: string,
): Promise<any> => {
  let url = `/inventory-models/${inventoryModel.cuid}/revisions/${metadataId}`;
  if (documentType) {
    url = `${url}/for-comments-on/${documentType}`;
  }
  const result = await axiosInstance.patch(url, revisionsData);
  return result.data;
};

const AddBlockToSection = async (
  inventoryModel: TInventoryModel,
  documentType: 'model_documentation' | 'validation_report' | 'monitoring',
  templateSection: TemplateSection,
  index: number,
  content: TemplateSectionContents,
): Promise<any> => {
  const response = await axiosInstance.post(
    `/inventory-models/${inventoryModel.cuid}/templates/${documentType}/latest/add-block`,
    { templateSection, index, content },
  );
  return response.data;
};

const RemoveBlockFromSection = async (
  inventoryModel: TInventoryModel,
  documentType: 'model_documentation' | 'validation_report' | 'monitoring',
  templateSection: TemplateSection,
  content: TemplateSectionContents,
): Promise<any> => {
  const response = await axiosInstance.post(
    `/inventory-models/${inventoryModel.cuid}/templates/${documentType}/latest/remove-block`,
    { templateSection, content },
  );
  return response.data;
};

const GetModelInventoryTestResultsKeys = async (
  inventoryModel: TInventoryModel,
  published_only: boolean = false,
  content_type?: ModelDocumentTypeEnum,
): Promise<TTestResultKey[]> => {
  let params: any = {
    published_only,
  };

  if (content_type) {
    params = {
      ...params,
      content_type,
    };
  }

  const response = await axiosInstance.get(
    `/inventory-models/${inventoryModel.cuid}/test-results/keys`,
    {
      params,
    },
  );
  return response.data;
};

const AddUsersToGroup = async (
  groupCuid: string,
  userCuids: string[],
): Promise<TGroupMembership[]> => {
  const response = await axiosInstance.post(`/groups/${groupCuid}/users`, {
    users: userCuids,
  });
  return response.data;
};

const RemoveUsersFromGroup = async (
  groupCuid: string,
  userCuids: string[],
): Promise<{ removed: string[] }> => {
  const response = await axiosInstance.delete(`/groups/${groupCuid}/users`, {
    data: {
      users: userCuids,
    },
  });
  return response.data;
};

const CreateGroup = async (
  name: string,
  description: string,
): Promise<TUserRole> => {
  const result = await axiosInstance.post('/groups', {
    name,
    description,
  });
  return result.data;
};

const GetGroups = async (
  page?: number,
): Promise<APIPaginatedResponse<TGroup>> => {
  const params = page ? { page } : {};
  const response = await axiosInstance.get('/groups', {
    params,
  });
  return response.data;
};

const DeleteGroup = async (groupId: string): Promise<void> => {
  await axiosInstance.delete(`/groups/${groupId}`);
};

const GetGroupUsers = async (groupId: string): Promise<TUser[]> => {
  const response = await axiosInstance.get(`/groups/${groupId}/users`);
  return response.data;
};

const GetGroupTemplates = async (groupId: string): Promise<Template[]> => {
  const response = await axiosInstance.get(
    `/groups/${groupId}/resources?type=Template`,
  );
  return response.data;
};

const GetGroupWorkflows = async (groupId: string): Promise<Template[]> => {
  const response = await axiosInstance.get(
    `/groups/${groupId}/resources?type=StatusesWorkflow`,
  );
  return response.data;
};

const GetGroupInventoryModels = async (
  groupId: string,
): Promise<TInventoryModel[]> => {
  const response = await axiosInstance.get(
    `/groups/${groupId}/resources?type=InventoryModel`,
  );
  return response.data;
};

const CreateOrganization = async (
  organizationName: string,
  name: string,
  lastName: string,
  jobTitle: string,
  hasOptedInForNewsletter: boolean,
  hasOptedInForSlack: boolean,
  hasAcceptedTermsAndConditions: boolean,
  hasAcceptedTermsOfAIUse: boolean,
  populateDemoModels: boolean,
): Promise<TOrganization> => {
  const response = await axiosInstance.post('/organizations', {
    name,
    organization_name: organizationName,
    last_name: lastName,
    job_title: jobTitle,
    opted_in_for_newsletter: hasOptedInForNewsletter,
    opted_in_for_slack: hasOptedInForSlack,
    accepted_tyc: hasAcceptedTermsAndConditions,
    accepted_ai_terms: hasAcceptedTermsOfAIUse,
    populate_demo_models: populateDemoModels,
  });
  return response.data;
};

const GetValidationGuidelineSummary = async (
  inventoryModel: TInventoryModel,
  contentIds: string[],
): Promise<AssessmentOptionWithSummary[]> => {
  const result = await axiosInstance.get(
    `/inventory-models/${
      inventoryModel.cuid
    }/guidelines/assessments/summary?content_ids=${contentIds.join(',')}`,
  );
  return result.data;
};
const GetValidationGuideline = async (
  inventoryModel: TInventoryModel,
  contentId: string,
): Promise<Guideline> => {
  const result = await axiosInstance.get(
    `/inventory-models/${inventoryModel.cuid}/guidelines/${contentId}`,
  );
  return result.data;
};

const PostValidationGuidelineAssessment = async (
  inventoryModel: TInventoryModel,
  contentId: string,
  assessmentOptionCuid: string,
): Promise<Assessment> => {
  const result = await axiosInstance.post(
    `/inventory-models/${inventoryModel.cuid}/guidelines/${contentId}/assessments`,
    {
      assessment_option_cuid: assessmentOptionCuid,
    },
  );
  return result.data;
};

const PatchValidationGuidelineAssessment = async (
  inventoryModel: TInventoryModel,
  contentId: string,
  assessment: Assessment,
): Promise<Assessment> => {
  const result = await axiosInstance.patch(
    `/inventory-models/${inventoryModel.cuid}/guidelines/${contentId}/assessments`,
    {
      notes: assessment.notes,
    },
  );
  return result.data;
};

const PatchValidationGuidelineAssessmentEvidences = async (
  inventoryModel: TInventoryModel,
  guidelineContentId: string,
  documentType: ModelDocumentTypeEnum,
  contents: TemplateSectionContents[],
): Promise<{
  added: Array<Pick<TTestResult, 'cuid'>>;
  deleted: Array<Pick<TTestResult, 'cuid'>>;
}> => {
  const result = await axiosInstance.patch(
    `/inventory-models/${inventoryModel.cuid}/guidelines/${guidelineContentId}/assessments/evidences`,
    {
      documentType,
      contents,
    },
  );
  return result.data;
};

const DeleteValidationGuidelineAssessmentEvidences = async (
  inventoryModel: TInventoryModel,
  guidelineContentId: string,
  documentType: ModelDocumentTypeEnum,
  content: TemplateSectionContents,
) => {
  const result = await axiosInstance.delete(
    `/inventory-models/${inventoryModel.cuid}/guidelines/${guidelineContentId}/assessments/evidences/${documentType}/${content.content_type}/${content.content_id}`,
  );
  return result.data;
};

const PatchValidationGuidelineAssessmentFindings = async (
  inventoryModel: TInventoryModel,
  contentId: string,
  findingCuids: string[],
): Promise<{
  added: Array<Pick<TFinding, 'cuid'>>;
  deleted: Array<Pick<TFinding, 'cuid'>>;
}> => {
  const result = await axiosInstance.patch(
    `/inventory-models/${inventoryModel.cuid}/guidelines/${contentId}/assessments/findings`,
    {
      findings: findingCuids,
    },
  );
  return result.data;
};

const GetInvitationCheck = async (
  inviteCuid: string,
): Promise<{ result: boolean; user_exists: boolean }> => {
  const response = await axiosInstance.get(
    `/user-invites/check?invite=${inviteCuid}`,
  );
  return response.data;
};

const PostInvitationAccept = async (
  inviteCuid: string | null,
): Promise<any> => {
  const response = await axiosInstance.post(
    `/user-invites/accept?invite=${inviteCuid}`,
    {},
  );
  return response.data;
};

const GetUserInvitations = async (
  page: number = 1,
  limit: number = 100,
  status?: 'pending' | 'accepted',
): Promise<APIPaginatedResponse<TUserInvite>> => {
  const params = { page, limit, status };
  const result = await axiosInstance.get('/user-invites', {
    params,
  });
  return result.data;
};

const PostUserInvitation = async (
  email: string,
  groups: Array<TGroup['cuid']>,
  roles: Array<TRole['cuid']>,
): Promise<{ result: boolean }> => {
  const response = await axiosInstance.post('/user-invites', {
    email,
    groups,
    roles,
  });
  return response.data;
};

const ListUserRoles = async (): Promise<TUserRolePaginated> => {
  const response = await axiosInstance.get('/user-roles');
  return response.data;
};

const CreateUserRole = async (
  user_id: string,
  role_id: string,
): Promise<TUserRole> => {
  const result = await axiosInstance.post('/user-roles', {
    user_id,
    role_id,
  });
  return result.data;
};

const DeleteUserRole = async (userRoleId: string): Promise<TUserRole> => {
  const result = await axiosInstance.delete(`/user-roles/${userRoleId}`);
  return result.data;
};

const GetInventoryModelSchema = async (): Promise<TInventoryModelSchema> => {
  const result = await axiosInstance.get('/inventory-models/schema');
  return result.data;
};

const PutInventoryModelSchema = async (
  schema: RJSFSchema,
  settings: RJSFSchemaSettings,
  operation: 'add' | 'remove' | 'update',
  properties: {
    schema: JSONSchema7;
    settings: RJSFSchemaSettingsProperty;
  },
): Promise<TInventoryModelSchema> => {
  const result = await axiosInstance.put('/inventory-models/schema', {
    schema,
    settings,
    operation,
    properties,
  });
  return result.data;
};

const GetModelInventoryCustomField = async (
  inventoryModel: TInventoryModel,
  key: string,
): Promise<TInventoryModelCustomField> => {
  const result = await axiosInstance.get(
    `/inventory-models/${inventoryModel.cuid}/fields/${key}`,
  );
  return result.data;
};

const PostModelInventoryCustomField = async (
  inventoryModel: TInventoryModel,
  key: string,
  formData: any,
): Promise<TInventoryModelCustomField> => {
  const result = await axiosInstance.post(
    `/inventory-models/${inventoryModel.cuid}/fields/${key}`,
    formData,
  );
  return result.data;
};

const GetModelsByTemplateVersion = async (
  documentType: string,
  template: Template,
  templateVersion: TemplateVersion,
): Promise<TInventoryModel[]> => {
  const response = await axiosInstance.get(
    `/templates/${template.cuid}/version/${templateVersion.cuid}/models?document_type=${documentType}`,
  );
  return response.data;
};

const DuplicateTemplate = async (
  template: Template,
  templateVersion: TemplateVersion,
  description: string,
  new_name: string,
): Promise<Template> => {
  const response = await axiosInstance.post(
    `/templates/${template.cuid}/version/${templateVersion.cuid}/duplicate`,
    { description, new_name },
  );
  return response.data;
};

const GetRiskAreas = async (): Promise<PagedResponse<TRiskArea>> => {
  const result = await axiosInstance.get('/risk-areas');
  return result.data;
};

const PostRiskArea = async (
  riskArea: TAddRiskAreaRequest,
): Promise<TRiskArea> => {
  const result = await axiosInstance.post(`/risk-areas`, riskArea);
  return result.data;
};

const PatchRiskArea = async (
  riskAreaCuid: string,
  riskArea: TAddRiskAreaRequest,
): Promise<TRiskArea> => {
  const result = await axiosInstance.patch(
    `/risk-areas/${riskAreaCuid}`,
    riskArea,
  );
  return result.data;
};

const DeleteRiskArea = async (riskAreaCuid: string): Promise<TRiskArea> => {
  const result = await axiosInstance.delete(`/risk-areas/${riskAreaCuid}`);
  return result.data;
};

const GetRiskAreaDependencies = async (
  riskAreaCuid: string,
): Promise<TRiskAreaDependencies> => {
  const result = await axiosInstance.get(
    `/risk-areas/${riskAreaCuid}/check-dependencies`,
  );
  return result.data;
};

const GetWorkflows = async (): Promise<WorkflowPaginated> => {
  const result = await axiosInstance.get('/workflows');
  return result.data;
};

const PostWorkflow = async (
  title: string,
  description: string,
  triggerId: string,
  triggerType: string,
  entityName?: EntityName,
  entityEventType?: EntityEventType,
  scheduleType?: ScheduleType,
  recurrenceUnit?: RecurrenceUnit,
  recurrenceIntervalCount?: number,
  recurrenceTimeOfDay?: string,
  recurrenceDayOfWeek?: string,
  recurrenceDayOfMonth?: number,
  recurrenceCronExpression?: string,
  expectedDurationAmount?: number,
  expectedDurationUnit?: ExpectedDurationUnit,
): Promise<Workflow> => {
  const result = await axiosInstance.post(
    `${CONFIG.REACT_APP_API_URL}/workflows`,
    {
      title,
      description,
      trigger_id: triggerId,
      trigger_type: triggerType,
      entity_name: entityName,
      entity_event_type: entityEventType,
      schedule_type: scheduleType,
      recurrence_unit: recurrenceUnit,
      recurrence_interval_count: recurrenceIntervalCount || null,
      recurrence_time_of_day: recurrenceTimeOfDay,
      recurrence_day_of_week: recurrenceDayOfWeek,
      recurrence_day_of_month: recurrenceDayOfMonth || null,
      recurrence_cron_expression: recurrenceCronExpression,
      expected_duration_amount: expectedDurationAmount || null,
      expected_duration_unit: expectedDurationUnit,
    },
  );
  return result.data;
};

const UpdateWorkflow = async (
  cuid: string,
  title: string,
  description: string,
  triggerId: string,
  triggerType: string,
  entityName?: EntityName,
  entityEventType?: EntityEventType,
  scheduleType?: ScheduleType,
  recurrenceUnit?: RecurrenceUnit,
  recurrenceIntervalCount?: number,
  recurrenceTimeOfDay?: string,
  recurrenceDayOfWeek?: string,
  recurrenceDayOfMonth?: number,
  recurrenceCronExpression?: string,
  expectedDurationAmount?: number,
  expectedDurationUnit?: ExpectedDurationUnit,
): Promise<Workflow> => {
  const result = await axiosInstance.put(`/workflows/${cuid}`, {
    title,
    description,
    trigger_id: triggerId,
    trigger_type: triggerType,
    entity_name: entityName,
    entity_event_type: entityEventType,
    schedule_type: scheduleType,
    recurrence_unit: recurrenceUnit,
    recurrence_interval_count: recurrenceIntervalCount || null,
    recurrence_time_of_day: recurrenceTimeOfDay,
    recurrence_day_of_week: recurrenceDayOfWeek,
    recurrence_day_of_month: recurrenceDayOfMonth || null,
    recurrence_cron_expression: recurrenceCronExpression,
    expected_duration_amount: expectedDurationAmount || null,
    expected_duration_unit: expectedDurationUnit,
  });
  return result.data;
};

const GetWorkflow = async (
  workflowCuid: string,
  version: string,
): Promise<Workflow> => {
  const result = await axiosInstance.get(
    `/workflows/${workflowCuid}?version=${version}`,
  );
  return result.data;
};

const PostWorkflowVersionSource = async (
  workflowCuid: string,
  version: string,
  source: WorkflowSource,
): Promise<Workflow> => {
  const result = await axiosInstance.post(
    `/workflows/${workflowCuid}/${version}`,
    { source },
  );
  return result.data;
};

const GetStatusesWorkflowStatus = async (
  statusCuid: string,
): Promise<TStatusesWorkflowStatus> => {
  const result = await axiosInstance.get(`/statuses/${statusCuid}`);
  return result.data;
};

const GetWorkflowStatusForms = async (
  triggerId: string,
  entityCuid: string,
): Promise<WorkflowStatusForm[]> => {
  const result = await axiosInstance.get(
    `/workflows/status_form/${triggerId}/${entityCuid}`,
  );
  return result.data;
};

const TriggerWorkflowStatusForm = async (
  triggerId: string,
  entityCuid: string,
  trigger: string,
  fieldsValues: FieldValues,
  notes: string,
): Promise<WorkflowStatusForm[]> => {
  const result = await axiosInstance.post(
    `/workflows/status_form/${triggerId}/${entityCuid}`,
    {
      trigger: trigger,
      field_values: fieldsValues,
      notes,
    },
  );
  return result.data;
};

const GetApprovalVoters = async (
  voterStatus?: string | null,
  approvalStatus?: string,
  targetType?: string,
  targetCuid?: string,
  executionCuid?: string,
): Promise<ApprovalVoter[]> => {
  const result = await axiosInstance.get(`/approvals/voters`, {
    params: {
      voter_status: voterStatus,
      approval_status: approvalStatus,
      target_type: targetType,
      target_cuid: targetCuid,
      execution_cuid: executionCuid,
    },
  });
  return result.data;
};

const PostApprovalVote = async (
  voterCuid: string,
  vote: ApprovalVote,
  notes: string,
): Promise<ApprovalVoter> => {
  const result = await axiosInstance.post(`/approvals/voters/${voterCuid}`, {
    vote,
    notes,
  });
  return result.data;
};

const GetWorkflowExecutionsForTarget = async (
  targetCuid: string,
  statuses: string[] = ['active', 'waiting', 'scheduled'],
  workflowCuid?: string,
): Promise<Execution[]> => {
  const params = {
    status: statuses?.join(','),
    workflow: workflowCuid || '',
  };

  const result = await axiosInstance.get(
    `${CONFIG.REACT_APP_API_URL}/workflows/targets/${targetCuid}/executions`,
    {
      params,
    },
  );
  return result.data;
};

const DrawExecutionsForTarget = async (
  targetCuid: string,
  statuses: string[] = ['active', 'waiting', 'scheduled'],
): Promise<string> => {
  const params = {
    status: statuses?.join(','),
  };

  const result = await axiosInstance.get(
    `${CONFIG.REACT_APP_API_URL}/workflows/targets/${targetCuid}/executions/draw`,
    {
      params,
    },
  );
  return result.data;
};

const GetAvailableWorkflowsForTarget = async (
  targetCuid: string,
  entityName?: EntityName,
  filterManualTriggerEnabled: boolean = true,
): Promise<Workflow[]> => {
  const params = {
    manual_trigger_enabled: filterManualTriggerEnabled,
    entity_name: entityName || '',
  };
  const result = await axiosInstance.get(
    `${CONFIG.REACT_APP_API_URL}/workflows/targets/${targetCuid}/workflows-available`,
    {
      params,
    },
  );
  return result.data;
};

const StartWorkflowExecution = async (
  workflowCuid: string,
  targetCuid: string,
  startDate: string,
): Promise<Execution> => {
  const params: any = {};
  if (startDate) {
    params['start_date'] = startDate;
  }
  const result = await axiosInstance.post(
    `${CONFIG.REACT_APP_API_URL}/workflows/${workflowCuid}/targets/${targetCuid}/start`,
    params,
    {},
  );
  return result.data;
};

const RescheduleWorkflowExecution = async (
  workflowCuid: string,
  executionCuid: string,
  startDate: string,
): Promise<Execution> => {
  const params: any = {};
  if (startDate) {
    params['start_date'] = startDate;
  }
  const result = await axiosInstance.post(
    `${CONFIG.REACT_APP_API_URL}/workflows/${workflowCuid}/executions/${executionCuid}/reschedule`,
    params,
    {},
  );
  return result.data;
};

const GetWorkflowExecution = async (
  workflowCuid: string,
  executionCuid?: string,
): Promise<WorkflowExecutionsResponse> => {
  const url = executionCuid
    ? `${CONFIG.REACT_APP_API_URL}/workflows/${workflowCuid}/executions/${executionCuid}`
    : `${CONFIG.REACT_APP_API_URL}/workflows/${workflowCuid}/executions`;

  const result = await axiosInstance.get(url);
  return result.data;
};

const GetWorkflowExecutionUserActions = async (
  workflowCuid: string,
  executionCuid: string,
): Promise<WorkflowUserActionForm[]> => {
  const result = await axiosInstance.get(
    `${CONFIG.REACT_APP_API_URL}/workflows/${workflowCuid}/executions/${executionCuid}/user-actions`,
  );
  return result.data;
};

const PostWorkflowExecutionUserActions = async (
  workflowCuid: string,
  executionCuid: string,
  action: WorkflowUserActionForm,
  fieldsValues: FieldValues,
  notes: string,
): Promise<Execution> => {
  const result = await axiosInstance.post(
    `${CONFIG.REACT_APP_API_URL}/workflows/${workflowCuid}/executions/${executionCuid}/user-actions`,
    {
      ...action,
      field_values: fieldsValues,
      notes,
    },
    {},
  );
  return result.data;
};

const PostWorkflowExecutionAbort = async (
  workflowCuid: string,
  executionCuid: string,
  startNewExecution: boolean = false,
): Promise<Execution> => {
  const result = await axiosInstance.post(
    `${CONFIG.REACT_APP_API_URL}/workflows/${workflowCuid}/executions/${executionCuid}/abort`,
    {
      start_new_execution: startNewExecution,
    },
  );
  return result.data;
};

const GetDocumentationSummary = async (
  inventoryModelCUID: string,
  contentType: string = 'model_documentation',
): Promise<TInventoryModelDocumentationSummary> => {
  const result = await axiosInstance.get(
    `/inventory-models/${inventoryModelCUID}/documentation-overview/${contentType}`,
  );
  return result.data;
};

const GetValidationReportSummary = async (
  inventoryModelCUID: string,
): Promise<TInventoryModelValidationReportSummary> => {
  const result = await axiosInstance.get(
    `/inventory-models/${inventoryModelCUID}/validation-report-overview`,
  );
  return result.data;
};

const PatchDocumentSectionStatus = async (
  inventoryModelCUID: string,
  documentType: 'model_documentation' | 'validation_report',
  sectionId: string,
  status: 'in_progress' | 'done',
): Promise<any> => {
  const response = await axiosInstance.patch(
    `/inventory-models/${inventoryModelCUID}/document-section-status/${documentType}/${sectionId}`,
    { status },
  );
  return response.data;
};

const PatchModelInventoryGroup = async (
  inventoryModelCUID: string,
  groupCUID: string,
): Promise<TInventoryModel> => {
  const response = await axiosInstance.patch(
    `/inventory-models/${inventoryModelCUID}/set-group`,
    { group_cuid: groupCUID },
  );
  return response.data;
};

const GetGuidelines = async (
  riskAreaCuid?: string,
  paging?: {
    limit: number;
  },
): Promise<PagedResponse<Guideline>> => {
  const limit = paging?.limit || 100;

  const params = {
    risk_area_cuid: riskAreaCuid,
    limit: limit,
  };

  const result = await axiosInstance.get('/guidelines', {
    params,
  });
  return result.data;
};

const PostGuideline = async (
  guideline: GuidelineRequest,
): Promise<Guideline> => {
  const result = await axiosInstance.post('/guidelines', guideline);
  return result.data;
};

const PatchGuideline = async (
  guidelineCuid: string,
  guideline: Omit<GuidelineRequest, 'content_id'>,
): Promise<Guideline> => {
  const result = await axiosInstance.patch(
    `/guidelines/${guidelineCuid}`,
    guideline,
  );
  return result.data;
};

const DeleteGuideline = async (guidelineCuid: string): Promise<Guideline> => {
  const result = await axiosInstance.delete(`/guidelines/${guidelineCuid}`);
  return result.data;
};

const GetGuidelineDependencies = async (
  guidelineCuid: string,
): Promise<GuidelineDependencies> => {
  const result = await axiosInstance.get(
    `/guidelines/${guidelineCuid}/check-dependencies`,
  );
  return result.data;
};

const PostRole = async (role: TRoleRequest): Promise<TRole> => {
  const result = await axiosInstance.post('/roles', role);
  return result.data;
};

const PatchOrganizationRole = async (
  role: RolePatchRequest,
): Promise<TRole> => {
  const result = await axiosInstance.patch(`/roles/${role.cuid}`, role);
  return result.data;
};

const GetRolePermissions = async (
  roleCUID: string,
): Promise<TRolePermissions> => {
  const response = await axiosInstance.get(`/roles/${roleCUID}/permissions`);
  let role = response.data.role;
  let categories = response.data.categories;

  Object.keys(categories).forEach(category => {
    categories[category] = categories[category].filter(
      (a: TAssignedPermission) => !a.scope || a.scope === (role.scope || ''),
    );
  });

  return response.data;
};

const PatchRolePermissions = async (
  roleCUID: string,
  rolePatch: {
    added_permissions?: string[];
    deleted_permissions?: string[];
  },
): Promise<Boolean> => {
  const response = await axiosInstance.patch(
    `/roles/${roleCUID}/permissions`,
    rolePatch,
  );
  return response.data;
};

const GetPermissionsOverview = async (): Promise<TPermissionsOverview> => {
  const response = await axiosInstance.get(`/roles/permissions-overview`);
  return response.data;
};

const GetUserPermissions = async (
  scope?: string,
  cuid?: string,
): Promise<string[]> => {
  const response = await axiosInstance.get(
    `/users/me/permissions?${scope ? `scope=${scope}&cuid=${cuid}` : ''}`,
  );
  return response.data;
};

const PutUserAcceptTerms = async (
  accept_tos: boolean = false,
  accept_ai_terms: boolean = false,
): Promise<void> => {
  await axiosInstance.put(`/users/me/accept-terms`, undefined, {
    params: {
      accept_tos,
      accept_ai_terms,
    },
  });
};

const GetTests = async (
  inventoryModel: TInventoryModel,
  content_type?: ModelDocumentTypeEnum,
): Promise<TTest[]> => {
  const response = await axiosInstance.get(
    `/inventory-models/${inventoryModel.cuid}/tests?content_type=${content_type}`,
  );
  return response.data;
};

const UploadOfflineDocument = async (
  inventoryModel: TInventoryModel,
  documentType: 'model_documentation' | 'validation_report' | 'monitoring',
  attachments: File[],
): Promise<TInventoryModel> => {
  const formData = new FormData();
  for (let i = 0; i < attachments.length; i++) {
    formData.append(`attachments[]`, attachments[i]);
  }

  const result = await axiosInstance.post(
    `/inventory-models/${inventoryModel.cuid}/documents/${documentType}/upload`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );
  return result.data;
};

const DeleteOfflineDocument = async (
  inventoryModel: TInventoryModel,
  documentType: 'model_documentation' | 'validation_report' | 'monitoring',
): Promise<TInventoryModel> => {
  const result = await axiosInstance.delete(
    `/inventory-models/${inventoryModel.cuid}/documents/${documentType}/upload`,
  );
  return result.data;
};

const DownloadOfflineDocument = async (
  file: OfflineDocumentationFile,
): Promise<Blob> => {
  const response = await axiosInstance.get(file.url, {
    responseType: 'blob',
  });

  return response.data;
};

export const WorkflowSeekTo = async (
  workflowCuid: string,
  executionCuid: string,
  nodeTargetId: string,
): Promise<Execution> => {
  const response = await axiosInstance.post(
    `/workflows/${workflowCuid}/executions/${executionCuid}/seek`,
    {
      node_target_id: nodeTargetId,
    },
  );
  return response.data;
};

export const WorkflowResumeWait = async (
  workflowCuid: string,
  executionCuid: string,
): Promise<Execution> => {
  const response = await axiosInstance.post(
    `/workflows/${workflowCuid}/executions/${executionCuid}/resume-wait`,
    {},
  );
  return response.data;
};

const PostInventoryModelDependencies = async (
  inventoryModel: TInventoryModel,
  dependencies: TInventoryModelDependency[],
): Promise<TInventoryModel> => {
  const response = await axiosInstance.post(
    `/inventory-models/${inventoryModel.cuid}/dependencies`,
    { dependencies },
  );
  return response.data;
};

const PostCodeTest = async (
  code: string,
  variables: any,
  execute: boolean,
): Promise<CodeTestResponse> => {
  const response = await axiosInstance.post('/custom-fields/code/test', {
    code,
    variables,
    execute,
  });
  return response.data;
};

const PostInventoryModelStage = async (
  inventoryModel: TInventoryModel,
  workflowStatus: TStatusesWorkflowStatus,
): Promise<TInventoryModel> => {
  const response = await axiosInstance.post(
    `/inventory-models/${inventoryModel.cuid}/stages`,
    {
      stage: workflowStatus.cuid,
    },
  );
  return response.data;
};

const GetEntityAttachments = async (
  entity_type: EntityType,
  entity_cuid: string,
  entity_field_id: string,
): Promise<EntityAttachment> => {
  const response = await axiosInstance.get(
    `/entity-attachments/${entity_type}/${entity_cuid}/${entity_field_id}`,
  );
  return response.data;
};

const UploadEntityAttachmentsFiles = async (
  entity_type: EntityType,
  entity_cuid: string,
  entity_field_id: string,
  attachments: File[],
): Promise<EntityAttachment> => {
  const formData = new FormData();
  for (let i = 0; i < attachments.length; i++) {
    formData.append(`files[]`, attachments[i]);
  }

  const result = await axiosInstance.post(
    `/entity-attachments/${entity_type}/${entity_cuid}/${entity_field_id}/upload_files`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );
  return result.data;
};

const AddEntityAttachmentsDescription = async (
  entity_type: EntityType,
  entity_cuid: string,
  attachment_id: string,
  description: string,
): Promise<any> => {
  const result = await axiosInstance.post(
    `/entity-attachments/${entity_type}/${entity_cuid}/attachments/${attachment_id}/description`,
    { description },
  );
  return result.data;
};

const DeleteEntityAttachmentsDescription = async (
  entity_type: EntityType,
  entity_cuid: string,
  attachment_id: string,
): Promise<any> => {
  const result = await axiosInstance.delete(
    `/entity-attachments/${entity_type}/${entity_cuid}/attachments/${attachment_id}/description`,
  );
  return result.data;
};

const DeleteEntityAttachmentsFiles = async (
  entity_type: EntityType,
  entity_cuid: string,
  entity_field_id: string,
  file_ids: string[],
): Promise<EntityAttachment> => {
  const result = await axiosInstance.delete(
    `/entity-attachments/${entity_type}/${entity_cuid}/${entity_field_id}/delete_files`,
    {
      params: {
        file_ids: file_ids,
      },
    },
  );
  return result.data;
};

export const GetUnitMetrics = async (
  inventoryModelCUID: string,
): Promise<TUnitMetric[]> => {
  const response = await axiosInstance.get(
    `/inventory-models/${inventoryModelCUID}/unit_metrics`,
  );
  return response.data;
};

export const GetUnitMetricsValuesForKey = async (
  inventoryModelCUID: string,
  key: string,
  start?: string,
  end?: string,
  limit: number = 999999,
): Promise<PagedResponse<TUnitMetric>> => {
  const response = await axiosInstance.get(
    `/inventory-models/${inventoryModelCUID}/unit_metrics/${key}`,
    {
      params: {
        start,
        end,
        limit,
      },
    },
  );
  return response.data;
};

export const GetModelDocument = async (
  modelDocumentId: string,
): Promise<ModelDocument> => {
  /* This is the best way to get a model document
   * Presents the opportunity to include document cuids to
   * the rbac policies and enforce on a more granular way
   * */
  const response = await axiosInstance.get(
    `model-documents/${modelDocumentId}`,
  );
  return response.data;
};

export const ResendVerificationEmail = async (
  auth0UserId: string,
): Promise<{ verification_sent: Boolean }> => {
  const response = await axiosInstance.post(
    `/resend-verification-email/${auth0UserId}`,
  );
  return response.data;
};

export const GetReportingDatasets = async (): Promise<TReportingDataset[]> => {
  const response = await axiosInstance.get(`/reporting/datasets`);
  return response.data;
};

export const GetReportingData = async (
  dataset: string,
  metrics?: TReportMetric[],
  grouping?: TReportGrouping[],
  sorting?: TReportSorting[],
  filtering?: RuleGroupType,
): Promise<any> => {
  const response = await axiosInstance.post(`/reporting/query`, {
    dataset,
    metrics,
    grouping,
    sorting,
    filtering,
  });
  return response.data;
};

export const GetDashboards = async (
  dashboardType: string,
): Promise<TDashboard[]> => {
  const response = await axiosInstance.get(`/dashboards/${dashboardType}`);
  return response.data;
};

export const GetDashboard = async (cuid: string): Promise<TDashboard> => {
  const response = await axiosInstance.get(`/dashboard/${cuid}`);
  return response.data;
};

export const UpdateDashboard = async (
  cuid: string,
  data: {
    name: string;
    description?: string;
    layout?: TDashboardLayout[];
  },
): Promise<TDashboard> => {
  const response = await axiosInstance.put(`/dashboard/${cuid}`, data);
  return response.data;
};

export const DeleteDashboard = async (
  cuid: string,
): Promise<{ message: string }> => {
  const response = await axiosInstance.delete(`/dashboard/${cuid}`);
  return response.data;
};

export const CreateDashboard = async (data: {
  name: string;
  description?: string;
  dashboard_type: string;
}): Promise<TDashboard> => {
  const response = await axiosInstance.post(`/dashboard`, data);
  return response.data;
};

export const CreateDashboardVisualization = async (
  dashboard_cuid: string,
  json: TDashboardVisualizationJSON,
): Promise<TDashboardVisualization> => {
  const response = await axiosInstance.post(
    `/dashboard-visualization/${dashboard_cuid}`,
    {
      json,
    },
  );
  return response.data;
};

export const UpdateDashboardVisualization = async (
  visualization_cuid: string,
  json: TDashboardVisualizationJSON,
): Promise<TDashboardVisualization> => {
  const response = await axiosInstance.put(
    `/dashboard-visualization/${visualization_cuid}`,
    {
      json,
    },
  );
  return response.data;
};

export const DeleteDashboardVisualization = async (
  visualization_cuid: string,
): Promise<TDashboardVisualization> => {
  const response = await axiosInstance.delete(
    `/dashboard-visualization/${visualization_cuid}`,
  );
  return response.data;
};

export const CreateDashboardReference = async (
  dashboardCUID: string,
  itemType: TDashboardItemTypes,
  itemCUID: string,
): Promise<TDashboardItem> => {
  const response = await axiosInstance.post(
    `/dashboard/${dashboardCUID}/reference/${itemType}/${itemCUID}`,
    {},
  );
  return response.data;
};

export const DeleteDashboardReference = async (
  dashboardCUID: string,
  referenceCUID: string,
): Promise<void> => {
  const response = await axiosInstance.delete(
    `/dashboard/${dashboardCUID}/reference/${referenceCUID}`,
  );
  return response.data;
};

// Get Saved Views
export const GetSavedViews = async (
  type: TSavedViewTypes,
): Promise<TSavedView[]> => {
  const response = await axiosInstance.get(`/saved-views/${type}`);
  return response.data;
};

// Create Saved View
export const CreateSavedView = async (
  data: Omit<
    TSavedView,
    'cuid' | 'created_at' | 'updated_at' | 'created_by' | 'organization_id'
  >,
): Promise<TSavedView> => {
  const response = await axiosInstance.post(`/saved-view`, data);
  return response.data;
};

export const UpdateSavedView = async (
  savedViewCUID: string,
  data: {
    name?: string;
    description?: string;
    content?: any;
  },
): Promise<TSavedView> => {
  const response = await axiosInstance.put(
    `/saved-view/${savedViewCUID}`,
    data,
  );
  return response.data;
};

export const DeleteSavedView = async (
  savedViewCUID: string,
): Promise<{ message: string }> => {
  const response = await axiosInstance.delete(`/saved-view/${savedViewCUID}`);
  return response.data;
};

export const GetWidgets = async (): Promise<TWidget[]> => {
  const response = await axiosInstance.get(`/widgets`);
  return response.data;
};

export const ExportFindings = async (
  filters?: FindingFilters,
  sortBy?: APIRequestSortBy,
): Promise<Blob> => {
  const filterParams: any = {};

  if (filters) {
    Object.keys(filters).forEach(filterKey => {
      //@ts-ignore
      const filterGroup = filters[filterKey];
      if (Array.isArray(filterGroup)) {
        filterParams[filterKey] = filterGroup.join(',');
      } else if (typeof filterGroup === 'boolean') {
        filterParams[filterKey] = filterGroup.toString();
      }
    });
  }

  let params: any = { ...filterParams, format: 'csv' };

  if (sortBy) {
    params = { ...params, sort_by: sortBy.field, sort_order: sortBy.order };
  }

  const result = await axiosInstance.get(`/findings`, {
    params,
    headers: {
      Accept: 'text/csv',
    },
    responseType: 'blob',
  });

  return result.data;
};

export const ExportModelInventory = async (
  columns?: string[],
  filters?: TModelInventoryFilters,
  searchQuery?: string,
  sortBy?: APIRequestSortBy,
): Promise<Blob> => {
  let parsedParams: any = { format: 'csv' };

  if (sortBy) {
    parsedParams = {
      ...parsedParams,
      sort_by: sortBy.field,
      sort_order: sortBy.order,
    };
  }

  if (filters) {
    parsedParams = {
      ...parsedParams,
      ...filters,
      owners: filters?.owners?.map(u => u.cuid) || [],
      developers: filters?.developers?.map(u => u.cuid) || [],
      validators: filters?.validators?.map(u => u.cuid) || [],
      business_units: filters?.business_units?.map(bu => bu.cuid) || [],
      status: filters?.status?.map(s => s.cuid) || [],
      is_vendor_model: filters?.is_vendor_model || undefined,
    };
  }

  if (searchQuery) {
    parsedParams = {
      ...parsedParams,
      q: searchQuery,
    };
  }

  // Remove fields with null, undefined, or empty arrays
  parsedParams = Object.fromEntries(
    Object.entries(parsedParams).filter(([_, value]) => {
      return (
        value !== null &&
        value !== undefined &&
        !(Array.isArray(value) && value.length === 0)
      );
    }),
  );

  // Use URLSearchParams like GetModelInventory does
  const params = new URLSearchParams({
    ...parsedParams,
    columns: columns?.join(','),
  } as keyof object).toString();

  const result = await axiosInstance.get(`/inventory-models?${params}`, {
    headers: {
      Accept: 'text/csv',
    },
    responseType: 'blob',
  });

  return result.data;
};

export type TModelPageLayoutItem = {
  propertyKey: string;
  isOmitted: boolean;
};

// Define the type for ModelPageLayout
export type TModelPageLayoutResponse = {
  id: number;
  cuid: string;
  user_id: number;
  organization_id: number;
  layout: {
    mainColumn: TModelPageLayoutItem[];
    sideColumn: TModelPageLayoutItem[];
  };
  created_at: string;
  updated_at: string;
};

// Fetch all model page layouts for the current user and organization
const GetModelPageLayout = async (): Promise<TModelPageLayoutResponse> => {
  const response = await axiosInstance.get('/model-page-layout');
  return response.data;
};

// Create a new model page layout
const CreateModelPageLayout = async (data: {
  layout: {
    mainColumn: TModelPageLayoutItem[];
    sideColumn: TModelPageLayoutItem[];
  };
}): Promise<TModelPageLayoutResponse> => {
  const response = await axiosInstance.post('/model-page-layout', data);
  return response.data;
};

// Update an existing model page layout
const UpdateModelPageLayout = async (data: {
  layout: {
    mainColumn: TModelPageLayoutItem[];
    sideColumn: TModelPageLayoutItem[];
  };
}): Promise<TModelPageLayoutResponse> => {
  const response = await axiosInstance.put(`/model-page-layout`, data);
  return response.data;
};

// Delete a model page layout by ID
const DeleteModelPageLayout = async (
  layoutId: number,
): Promise<{ message: string }> => {
  const response = await axiosInstance.delete(`/model-page-layout/${layoutId}`);
  return response.data;
};

const GetWorkflowStates = async (workflowCuid: string): Promise<State[]> => {
  const result = await axiosInstance.get(
    `/workflows/${workflowCuid}/states`,
    {},
  );
  return result.data;
};

const PostWorkflowState = async (
  workflowCuid: string,
  state: State,
): Promise<State> => {
  const result = await axiosInstance.post(
    `/workflows/${workflowCuid}/states`,
    state,
    {},
  );
  return result.data;
};

const PatchWorkflowState = async (
  workflowCuid: string,
  stateCuid: string,
  state: Partial<StateRequest>,
): Promise<State> => {
  const result = await axiosInstance.patch(
    `/workflows/${workflowCuid}/states/${stateCuid}`,
    state,
    {},
  );
  return result.data;
};

const GetWorkflowState = async (
  workflowCuid: string,
  stateCuid: string,
): Promise<State> => {
  const result = await axiosInstance.get(
    `/workflows/${workflowCuid}/states/${stateCuid}`,
    {},
  );
  return result.data;
};

const DeleteWorkflowState = async (
  workflowCuid: string,
  stateCuid: string,
): Promise<void> => {
  await axiosInstance.delete(
    `/workflows/${workflowCuid}/states/${stateCuid}`,
    {},
  );
};

const GetBlockConfig = async (
  inventoryModelCUID: string,
  documentType: string,
  contentType: string,
  contentId: string,
): Promise<BlockConfig> => {
  try {
    // TODO: some code paths refer to "documentation" instead of "model_documentation"
    const newDocumentType = getModelDocumentType(documentType);
    const response = await axiosInstance.get(
      `/inventory-models/${inventoryModelCUID}/block_configs/${newDocumentType}/${contentType}/${contentId}`,
    );
    return response.data.config;
  } catch (error) {
    return {};
  }
};

const UpdateBlockConfig = async (
  inventoryModelCUID: string,
  documentType: string,
  contentType: string,
  contentId: string,
  config: BlockConfig,
): Promise<BlockConfig> => {
  // TODO: some code paths refer to "documentation" instead of "model_documentation"
  const newDocumentType = getModelDocumentType(documentType);
  const response = await axiosInstance.put(
    `/inventory-models/${inventoryModelCUID}/block_configs/${newDocumentType}/${contentType}/${contentId}`,
    {
      config,
    },
  );
  return response.data.config;
};

// Get all attestations
export const GetAttestations = async (): Promise<TAttestation[]> => {
  const response = await axiosInstance.get('/attestations');
  return response.data;
};

// Create a new attestation
export const GetAttestationByCUID = async (
  cuid: string,
): Promise<TAttestation> => {
  const response = await axiosInstance.get(`/attestation/${cuid}`);
  return response.data;
};

// Create a new attestation
export const CreateAttestation = async (data: {
  name: string;
  description?: string;
}): Promise<TAttestation> => {
  const response = await axiosInstance.post('/attestations', data);
  return response.data;
};

// Update an existing attestation by CUID
export const UpdateAttestation = async (
  cuid: string,
  data: {
    name?: string;
    description?: object;
    advance_reminder?: string;
    custom_fields_json?: object[];
    questionaire_template?: string;
  },
): Promise<TAttestation> => {
  const response = await axiosInstance.put(`/attestation/${cuid}`, data);
  return response.data;
};

// Delete an attestation by CUID
export const DeleteAttestation = async (
  cuid: string,
): Promise<{ message: string }> => {
  const response = await axiosInstance.delete(`/attestation/${cuid}`);
  return response.data;
};

// Get all schedules for a specific attestation
export const GetAttestationSchedules = async (
  cuid: string,
): Promise<TAttestationSchedule[]> => {
  const response = await axiosInstance.get(`/attestation/${cuid}/schedules`);
  return response.data;
};

// Create a schedule for a specific attestation
export const CreateAttestationSchedule = async (
  cuid: string,
  data: {
    name: string;
    start_date: string; // ISO 8601 format
    end_date: string; // ISO 8601 format
  },
): Promise<TAttestationSchedule> => {
  const response = await axiosInstance.post(
    `/attestation/${cuid}/schedules`,
    data,
  );
  return response.data;
};

// Update a schedule for a specific attestation
export const UpdateAttestationSchedule = async (
  attestationCuid: string,
  scheduleCuid: string,
  data: {
    name?: string;
    start_date?: string; // ISO 8601 format
    end_date?: string; // ISO 8601 format
  },
): Promise<TAttestationSchedule> => {
  const response = await axiosInstance.put(
    `/attestation/${attestationCuid}/schedule/${scheduleCuid}`,
    data,
  );
  return response.data;
};

// Delete a schedule for a specific attestation
export const DeleteAttestationSchedule = async (
  attestationCuid: string,
  scheduleCuid: string,
): Promise<{ message: string }> => {
  const response = await axiosInstance.delete(
    `/attestation/${attestationCuid}/schedule/${scheduleCuid}`,
  );
  return response.data;
};

export const GetAttestationExecutions = async (): Promise<
  TAttestationExecution[]
> => {
  const response = await axiosInstance.get(`/attestation-executions`);
  return response.data;
};

export const GetAttestationExecutionSnapshotModels = async (
  attestationExecutionCUID: string,
): Promise<{
  models: TInventoryModel[];
  schema: TInventoryModelSchema;
}> => {
  const response = await axiosInstance.get(
    `/attestation-executions/${attestationExecutionCUID}/models`,
  );
  return response.data;
};

export const GetAttestationExecutionSnapshotFindings = async (
  attestationExecutionCUID: string,
): Promise<{
  findings: TFinding[];
}> => {
  const response = await axiosInstance.get(
    `/attestation-executions/${attestationExecutionCUID}/findings`,
  );
  return response.data;
};

export const TransitionAttestationExecutionStatus = async (
  attestationExecutionCUID: string,
  toStatus: TAttestationStatuses,
  notes: string,
  questionaire?: string,
): Promise<TAttestationTransition> => {
  const response = await axiosInstance.post(
    `/attestation-executions/${attestationExecutionCUID}/transition-to-status/${toStatus}`,
    {
      notes: notes,
      questionaire: questionaire,
    },
  );
  return response.data;
};

export const GetAttestationExecutionActivity = async (
  attestationExecutionCUID: string,
): Promise<TAttestationTransition[]> => {
  const response = await axiosInstance.get(
    `/attestation-executions/${attestationExecutionCUID}/activity`,
  );
  return response.data;
};

const GetAttestationExecutionSnapshotEntityAttachments = async (
  attestationExecutionCUID: string,
  entity_type: EntityType,
  entity_cuid: string,
  entity_field_id: string,
): Promise<{
  entity_attachments: EntityAttachment;
}> => {
  const response = await axiosInstance.get(
    `/attestation-executions/${attestationExecutionCUID}/entity-attachments/${entity_type}/${entity_cuid}/${entity_field_id}`,
  );
  return response.data;
};

// Execute a specific attestation schedule
// for admin-users only
export const ExecuteAttestationSchedule = async (
  attestationCuid: string,
  scheduleCuid: string,
): Promise<Boolean> => {
  const response = await axiosInstance.post(
    `/attestation/${attestationCuid}/schedule/${scheduleCuid}/execute`,
  );

  return response.status === 200;
};

type LogoSettings = {
  custom_logo_light_full: string | null;
  custom_logo_dark_full: string | null;
  custom_logo_light_mark: string | null;
  custom_logo_dark_mark: string | null;
};

const GetCustomLogo = async (): Promise<LogoSettings> => {
  const result = await axiosInstance.get<LogoSettings>('/settings/logo');
  return result.data;
};

const GetBlockLibrary = async (): Promise<{
  private_blocks: TBlockLibraryItem[];
  shared_blocks: TBlockLibraryItem[];
}> => {
  const response = await axiosInstance.get('/block-library');
  return response.data;
};

const CreateBlockLibraryItem = async (
  data: TBlockLibraryItemCreate,
): Promise<TBlockLibraryItem> => {
  const response = await axiosInstance.post('/block-library', data);
  return response.data;
};

const UpdateBlockLibraryItem = async (
  cuid: string,
  data: TBlockLibraryItemUpdate,
): Promise<TBlockLibraryItem> => {
  const response = await axiosInstance.put(`/block-library/${cuid}`, data);
  return response.data;
};

const DeleteBlockLibraryItem = async (
  cuid: string,
): Promise<{ message: string }> => {
  const response = await axiosInstance.delete(`/block-library/${cuid}`);
  return response.data;
};

const AddLibraryBlockToSection = async (
  inventoryModel: TInventoryModel,
  documentType: 'model_documentation' | 'validation_report' | 'monitoring',
  templateSection: TemplateSection,
  index: number,
  content: TemplateSectionContents,
  blockLibraryCUID: string,
): Promise<any> => {
  const response = await axiosInstance.post(
    `/inventory-models/${inventoryModel.cuid}/templates/${documentType}/latest/add-library-block/${blockLibraryCUID}`,
    { templateSection, index, content },
  );
  return response.data;
};

export interface AuthSettings {
  auth_provider_type: string;
  idp_user_creation_enabled: boolean;
  idp_change_password_enabled: boolean; // Changed from idp_change_password_allowed
}

export const GetAuthSettings = async (): Promise<AuthSettings> => {
  const response = await axiosInstance.get('/settings/auth');
  return response.data;
};

const actions = {
  getAPIErrorMessage,
  GetCurrentUser,
  GetOrganization,
  GetOrganizationConnection,
  GetOrganizationUsers,
  GetOrganizationRoles,
  GetOrganizationUseCases,
  GetOrganizationUseCaseCategories,
  PostOrganizationUseCase,
  DeleteOrganizationUseCase,
  GetInventoryModelSearch,
  GetModelInventoryMetadataByContentId,
  PostInventoryModelMetadata,
  PostInventoryModelMetadataAttachment,
  GetEvents,
  GetCommentsForInventoryModel,
  GetTestResults,
  GetTestResultForInventoryModel,
  GetTestResultHistoryForInventoryModel,
  PostCommentForInventoryModel,
  UpdateOrganization,
  GetInventoryModelFigures,
  GetInventoryModelFigureByKey,
  GetTemplates,
  GetTemplateVersion,
  UpdateTemplate,
  GetFindings,
  GetFindingLinkedAssessmentFindings,
  GetFindingFilters,
  GetFinding,
  PostFinding,
  UpdateFinding,
  DeleteFinding,
  PostAnnotation,
  GetAnnotation,
  DeleteAnnotation,
  ResolveAnnotation,
  PostComment,
  PatchComment,
  DeleteComment,
  PostSuggestion,
  GetSuggestion,
  PatchSuggestion,
  GetRevisions,
  PatchRevisions,
  PostFindingAttachment,
  GetStatusesWorkflows,
  GetStatusesWorkflow,
  PostStatusesWorkflowStatus,
  PutStatusesWorkflowStatus,
  DeleteStatusesWorkflowStatus,
  GetModelInventory,
  GetModelInventoryFilters,
  GetInventoryModel,
  GetFindingSeverities,
  GetAnnotations,
  DownloadReport,
  GetOrganizationBusinessUnits,
  PostOrganizationBusinessUnits,
  DeleteOrganizationBusinessUnit,
  PatchOrganizationBusinessUnit,
  CreateInventoryModel,
  PostInventoryModelUser,
  DeleteInventoryModelUser,
  GetJSONImage,
  SwapTemplate,
  SetTemplate,
  AddBlockToSection,
  RemoveBlockFromSection,
  GetModelInventoryTestResultsKeys,
  AddUsersToGroup,
  RemoveUsersFromGroup,
  CreateGroup,
  GetGroups,
  DeleteGroup,
  GetGroupUsers,
  GetGroupTemplates,
  GetGroupWorkflows,
  GetGroupInventoryModels,
  CreateOrganization,
  PatchCurrentUserUISettings,
  PatchUserOrganization,
  GetValidationGuideline,
  PostValidationGuidelineAssessment,
  PatchValidationGuidelineAssessment,
  PatchValidationGuidelineAssessmentEvidences,
  DeleteValidationGuidelineAssessmentEvidences,
  PatchValidationGuidelineAssessmentFindings,
  GetOrganizationAssessmentOptions,
  GetValidationGuidelineSummary,
  GetInvitationCheck,
  PostInvitationAccept,
  GetUserInvitations,
  PostUserInvitation,
  ListUserRoles,
  CreateUserRole,
  DeleteUserRole,
  GetInventoryModelSchema,
  PutInventoryModelSchema,
  GetModelInventoryCustomField,
  PostModelInventoryCustomField,
  ValidateTemplate,
  DuplicateTemplate,
  GetModelsByTemplateVersion,
  GetRiskAreas,
  PostRiskArea,
  PatchRiskArea,
  DeleteRiskArea,
  GetRiskAreaDependencies,
  GetDocumentationSummary,
  GetWorkflows,
  GetWorkflow,
  PostWorkflow,
  PostWorkflowVersionSource,
  GetStatusesWorkflowStatus,
  GetWorkflowStatusForms,
  TriggerWorkflowStatusForm,
  GetApprovalVoters,
  PostApprovalVote,
  GetWorkflowExecutionsForTarget,
  GetAvailableWorkflowsForTarget,
  StartWorkflowExecution,
  RescheduleWorkflowExecution,
  GetWorkflowExecutionUserActions,
  PostWorkflowExecutionUserActions,
  PostWorkflowExecutionAbort,
  GetWorkflowExecution,
  DrawExecutionsForTarget,
  PatchDocumentSectionStatus,
  GetValidationReportSummary,
  PatchModelInventoryGroup,
  GetGuidelines,
  PostGuideline,
  PatchGuideline,
  DeleteGuideline,
  GetGuidelineDependencies,
  PostRole,
  GetRolePermissions,
  PatchRolePermissions,
  GetPermissionsOverview,
  GetUserPermissions,
  PutUserAcceptTerms,
  GetDocument,
  GetFeatureFlags,
  GetTests,
  UploadOfflineDocument,
  DeleteOfflineDocument,
  DownloadOfflineDocument,
  PostInventoryModelDependencies,
  PostCodeTest,
  WorkflowSeekTo,
  WorkflowResumeWait,
  PatchOrganizationRole,
  PostInventoryModelStage,
  GetEntityAttachments,
  UploadEntityAttachmentsFiles,
  DeleteEntityAttachmentsFiles,
  AddEntityAttachmentsDescription,
  DeleteEntityAttachmentsDescription,
  GetUnitMetrics,
  GetUnitMetricsValuesForKey,
  GetModelDocument,
  ResendVerificationEmail,
  GetReportingDatasets,
  GetReportingData,
  GetDashboards,
  GetDashboard,
  UpdateDashboard,
  DeleteDashboard,
  CreateDashboard,
  CreateDashboardVisualization,
  UpdateDashboardVisualization,
  DeleteDashboardVisualization,
  CreateDashboardReference,
  DeleteDashboardReference,
  GetTestResultsFiltersForInventoryModel,
  GetSavedViews,
  UpdateSavedView,
  CreateSavedView,
  DeleteSavedView,
  GetWidgets,
  ExportFindings,
  ExportModelInventory,
  GetModelPageLayout,
  CreateModelPageLayout,
  UpdateModelPageLayout,
  DeleteModelPageLayout,
  GetWorkflowStates,
  PostWorkflowState,
  PatchWorkflowState,
  GetWorkflowState,
  DeleteWorkflowState,
  GetBlockConfig,
  UpdateBlockConfig,
  GetAttestations,
  GetAttestationByCUID,
  CreateAttestation,
  UpdateAttestation,
  DeleteAttestation,
  GetAttestationSchedules,
  CreateAttestationSchedule,
  UpdateAttestationSchedule,
  DeleteAttestationSchedule,
  GetAttestationExecutions,
  GetAttestationExecutionSnapshotModels,
  GetAttestationExecutionSnapshotFindings,
  TransitionAttestationExecutionStatus,
  GetAttestationExecutionActivity,
  GetAttestationExecutionSnapshotEntityAttachments,
  ExecuteAttestationSchedule,
  GetCustomLogo,
  GetBlockLibrary,
  CreateBlockLibraryItem,
  UpdateBlockLibraryItem,
  DeleteBlockLibraryItem,
  AddLibraryBlockToSection,
  UpdateWorkflow,
  GetAuthSettings,
};

export default actions;
