import dayjs from 'dayjs';
import { Tag } from '@chakra-ui/react';
import { displayFormattedDateAndTime } from '../../utils';

function ResumeIn({ targetTimestamp }: { targetTimestamp: number }) {
  const seconds = Math.floor(targetTimestamp); // Truncate to whole seconds
  const milliseconds = Math.round((targetTimestamp - seconds) * 1000); // Get milliseconds
  const targetTime = dayjs.unix(seconds).add(milliseconds, 'millisecond');
  const now = dayjs();
  const timeRemaining = now.to(targetTime);

  return (
    <Tag>
      {displayFormattedDateAndTime(targetTimestamp)} ({timeRemaining})
    </Tag>
  );
}

export default ResumeIn;
