import { tabsAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts.keys);

export const Tabs = defineMultiStyleConfig({
  baseStyle: {
    tab: {
      bg: 'neutral.100',
      px: '10rem',
      roundedTop: 'md',
      background: 'transparent',
      _hover: {
        bg: 'neutral.100',
        _dark: {
          bg: 'neutral.850',
        },
      },
      _dark: {
        bg: 'neutral.850',
        background: 'transparent',
      },
      _selected: {
        borderBottom: '3px solid',
        borderColor: 'brand.400',
        fontWeight: 'bold',
        _hover: {
          bg: 'transparent',
          _dark: {
            bg: 'transparent',
          },
        },
        _dark: {
          borderBottomColor: 'brand.400',

          color: 'brand.400',
          background: 'transparent',
        },
      },
    },
    tablist: {
      gap: '1px',
    },
    tabpanel: {},
  },
  variants: {
    enclosed: {
      tab: {
        _selected: {
          _dark: {
            borderBottomColor: 'neutral.900',
          },
        },
      },
    },
  },
  defaultProps: {
    colorScheme: 'brand',
  },
});
