import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Timeline, TimelineOptions } from 'vis-timeline';
import 'vis-timeline/styles/vis-timeline-graph2d.min.css';
import {
  Box,
  Heading,
  HStack,
  Image,
  Select,
  Skeleton,
  Spacer,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useQuery } from 'react-query';
import API from '../../api/API';
import { displayFormattedDateToISO } from '../../utils';
import dayjs from 'dayjs';
import { Execution } from '../../models/workflow';
import { Label } from '../Layout';
import './styles.css';
import { TimelineItem } from 'vis-timeline/types';
import WorkflowManagerModal from '../WorkflowManagerModal/components/WorkflowManagerModal';
import { WorkflowManagerNavigationState } from '../WorkflowManagerModal/contexts/navigation/NavigationContext';
import { useFlags } from '../../hooks/useFlags';
import { EmptyStateDisplay } from '../EmptyStateDisplay';

interface WorkflowsExecutionsTimelineWidgetProps {
  target: any;
}

export interface CustomTimelineItem extends TimelineItem {
  data?: any;
}

const WorkflowsExecutionsTimelineWidget: React.FC<
  WorkflowsExecutionsTimelineWidgetProps
> = ({ target }) => {
  const timelineRef = useRef<HTMLDivElement | null>(null);
  const timelineInstance = useRef<Timeline | null>(null);
  const { workflowsV3 } = useFlags();

  const [selectedExecution, setSelectedExecution] = useState<Execution | null>(
    null,
  );
  const [filter, setFilter] = useState<string>('all');

  // Initialize timeline once
  useEffect(() => {
    if (!timelineInstance.current && timelineRef.current) {
      timelineInstance.current = new Timeline(timelineRef.current, [], options);
    }

    return () => {
      if (timelineInstance.current) {
        timelineInstance.current.destroy();
        timelineInstance.current = null; // Reset after destruction
      }
    };
  }, []);

  // Fetch executions
  const executionsStates = ['active', 'waiting', 'scheduled', 'finished'];
  const {
    data: allExecutions = [],
    isLoading,
    error,
  } = useQuery(
    ['targets', target.cuid, 'executions', executionsStates],
    async () => {
      return API.GetWorkflowExecutionsForTarget(target.cuid, executionsStates);
    },
  );

  // temporal experiment
  const { data: base64image } = useQuery(
    ['targets', target.cuid, 'executions', 'pytransition'],
    async () => {
      return API.DrawExecutionsForTarget(target.cuid, [
        'active',
        'waiting',
        'scheduled',
        'finished',
      ]);
    },
    {
      enabled: false,
    },
  );

  // Filter executions
  const filteredExecutions = useMemo(() => {
    return filter === 'all'
      ? allExecutions
      : allExecutions.filter(execution => execution.cuid === filter);
  }, [filter, allExecutions]);

  // Generate timeline events
  const sortedEvents = useMemo((): CustomTimelineItem[] => {
    const now = dayjs();

    return filteredExecutions
      .map(execution => {
        let delayDays: number | undefined;
        if (execution.timeline.delayed_since) {
          const delayedSinceDate = dayjs.unix(execution.timeline.delayed_since);
          delayDays = now.diff(delayedSinceDate, 'days');
        }

        return {
          id: execution.cuid,
          group: 1,
          content: `<div>
            ${execution.execution_id}
            -
            ${execution.workflow.title}
            ${
              execution.progress
                ? `<strong>(${execution.progress}%)</strong>`
                : ''
            }
            ${delayDays ? `<span>${delayDays}d</span>` : ''}
            </div>`,
          start: displayFormattedDateToISO(execution.timeline.starts_at),
          end: displayFormattedDateToISO(execution.timeline.ends_at),
          className: execution.timeline.class_names.join(' '),
          data: execution,
        };
      })
      .sort(
        (a, b) => new Date(a.start).getTime() - new Date(b.start).getTime(),
      );
  }, [filteredExecutions]);

  // Timeline options
  const options = useMemo((): TimelineOptions => {
    const o: TimelineOptions = {
      orientation: 'top',
      start: dayjs().startOf('day').toISOString(),
      end: dayjs().endOf('day').toISOString(),
      zoomMin: 1000 * 60 * 60 * 24 * 7,
      zoomMax: 1000 * 60 * 60 * 24 * 365,
      showCurrentTime: true,
      editable: false,
      clickToUse: true,
    };

    if (sortedEvents.length === 0) {
      return o;
    }

    return {
      ...o,
      start: dayjs(sortedEvents[0].start).subtract(2, 'days').toISOString(),
      end: dayjs(sortedEvents[sortedEvents.length - 1].end)
        .add(2, 'days')
        .toISOString(),
    };
  }, [sortedEvents]);

  useEffect(() => {
    if (!timelineInstance.current) return;

    const handleSelect = (event: any) => {
      const selectedItemId = event.items[0];
      if (selectedItemId) {
        setSelectedExecution(
          allExecutions.find(execution => execution.cuid === selectedItemId) ||
            null,
        );
      }
    };

    timelineInstance.current.off('select');
    timelineInstance.current.on('select', handleSelect);

    return () => timelineInstance.current?.off('select', handleSelect);
  }, [allExecutions]);

  // Update timeline options separately
  useEffect(() => {
    timelineInstance.current?.setOptions(options);
  }, [options]);

  // Update timeline items separately
  useEffect(() => {
    timelineInstance.current?.setItems(sortedEvents);
  }, [sortedEvents]);

  return (
    <>
      <VStack
        w="full"
        gap={2}
        justifyContent="flex-start"
        alignItems={filteredExecutions.length > 0 ? 'flex-end' : 'center'}
      >
        <HStack w="full">
          <Heading as="h3">Workflows</Heading>
          <Spacer />
          <Skeleton isLoaded={!isLoading}>
            {filteredExecutions.length > 0 && (
              <HStack>
                <Label>Show</Label>
                <Select
                  value={filter}
                  onChange={e => setFilter(e.target.value)}
                >
                  <option value="all">All Workflows</option>
                  {allExecutions.map(execution => (
                    <option key={execution.cuid} value={execution.cuid}>
                      {execution.workflow.title}
                    </option>
                  ))}
                </Select>
              </HStack>
            )}
          </Skeleton>
        </HStack>
        {isLoading && (
          <Skeleton rounded={'md'} w={'full'} height={'12rem'}></Skeleton>
        )}

        <Box
          ref={timelineRef}
          w={'full'}
          display={filteredExecutions.length > 0 ? 'block' : 'none'}
        />

        {filteredExecutions.length === 0 && (
          <EmptyStateDisplay variant="no-activity">
            <Heading as={'h5'}>No workflows are currently running</Heading>
            <Text>Check the available workflows to run on this model.</Text>
            <>
              {workflowsV3 && (
                // List the available workflows modal
                <WorkflowManagerModal
                  target={target}
                  variant={'show-all-workflows-btn'}
                />
              )}
            </>
          </EmptyStateDisplay>
        )}

        <>
          {workflowsV3 && filteredExecutions.length > 0 && (
            // List the available workflows modal
            <WorkflowManagerModal
              target={target}
              variant={'show-all-workflows-btn'}
            />
          )}
        </>

        {/*temporal experiment*/}
        {base64image && (
          <Box>
            <Image
              src={'data:image/jpeg;base64,' + base64image}
              alt="Workflow of workflows example"
              objectFit="cover"
            />
          </Box>
        )}
      </VStack>

      {selectedExecution && (
        <WorkflowManagerModal
          target={target}
          workflowCuid={selectedExecution.workflow.cuid}
          executionCuid={selectedExecution.cuid}
          isOpen={true}
          navigation={WorkflowManagerNavigationState.DETAIL}
          onClose={() => setSelectedExecution(null)}
        />
      )}
    </>
  );
};

export default WorkflowsExecutionsTimelineWidget;
