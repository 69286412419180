import { TModelInventoryFilters } from '../../models/inventory_model';
import {
  Button,
  Flex,
  HStack,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Spacer,
  Spinner,
  Stack,
  Switch,
} from '@chakra-ui/react';
import { useEffect, useMemo, useState } from 'react';
import { TUser } from '../../models';
import { useQuery } from 'react-query';
import API from '../../api/API';
import { FunnelIcon } from '@heroicons/react/24/outline';
import { Label } from '../Layout';
import { TBusinessUnit } from '../../models/business_unit';
import { TStatusesWorkflowStatus } from '../../models/statuses_workflow';
import { Icon } from '@chakra-ui/icons';
import { ChakraStylesConfig, MultiValue, Select } from 'chakra-react-select';

export interface SelectOptions {
  label: string;
  value: string;
}

const chakraStyles: ChakraStylesConfig = {
  container: (provided: any) => ({
    ...provided,
    minW: '250px',
  }),
};

const ArrayToSelectOptions = (arr?: any[]): SelectOptions[] => {
  return (
    arr?.map(element => ({
      label: element.name || element,
      value: element.cuid || element.id || element,
    })) || []
  );
};

interface ModelInventoryFiltersProps {
  filters?: TModelInventoryFilters;
  setFilters: (filters: TModelInventoryFilters) => void;
}
export default function ModelInventoryFilters({
  filters,
  setFilters,
}: ModelInventoryFiltersProps) {
  const [tiers, setTiers] = useState<string[]>([]);
  const [businessUnits, setBusinessUnits] = useState<TBusinessUnit[]>([]);
  const [status, setStatus] = useState<TStatusesWorkflowStatus[]>([]);
  const [uses, setUses] = useState<string[]>([]);
  const [performanceRatings, setPerformanceRatings] = useState<number[]>([]);
  const [owners, setOwners] = useState<TUser[]>([]);
  const [developers, setDevelopers] = useState<TUser[]>([]);
  const [validators, setValidators] = useState<TUser[]>([]);
  const [isVendorModel, setIsVendorModel] = useState<boolean>();
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const { data: availableFilters, isLoading } = useQuery(
    ['model-inventory', 'filters'],
    async () => {
      return API.GetModelInventoryFilters();
    },
    {
      onError: err => {
        // track errors
      },
    },
  );

  useEffect(() => {
    if (filters) {
      setTiers(filters.tiering);
      setStatus(filters.status);
      setOwners(filters.owners);
      setDevelopers(filters.developers);
      setValidators(filters.validators);
      setPerformanceRatings(filters.performance_rating);
      setBusinessUnits(filters.business_units);
      setUses(filters.uses);
      setIsVendorModel(filters.is_vendor_model);
    }
  }, [filters]);

  const onApplyFilters = () => {
    handleClose();

    setFilters({
      cuids: [],
      tiering: tiers,
      business_units: businessUnits,
      owners: owners,
      developers: developers,
      validators: validators,
      status: status,
      uses: uses,
      performance_rating: performanceRatings,
      is_vendor_model: isVendorModel,
    });
  };

  const onClearFilters = () => {
    setTiers([]);
    setBusinessUnits([]);
    setOwners([]);
    setDevelopers([]);
    setValidators([]);
    setStatus([]);
    setUses([]);
    setPerformanceRatings([]);
    setIsVendorModel(undefined);
  };

  const filterDefs = useMemo(() => {
    return [
      {
        name: 'Model Tiering',
        control: 'multi-select',
        options: ArrayToSelectOptions(availableFilters?.tiering || []),
        selected: ArrayToSelectOptions(tiers),
        onChange: (newValues: MultiValue<SelectOptions>) => {
          const newTiers = newValues.map(v => v.value);
          setTiers(newTiers);
        },
      },
      {
        name: 'Business Units',
        control: 'multi-select',
        options: ArrayToSelectOptions(availableFilters?.business_units || []),
        selected: ArrayToSelectOptions(businessUnits),
        onChange: (newValues: MultiValue<SelectOptions>) => {
          const newBusinessUnits =
            availableFilters?.business_units.filter(u =>
              newValues.map(v => v.value).includes(u.cuid),
            ) || [];
          setBusinessUnits(newBusinessUnits);
        },
      },
      {
        name: 'Model stage',
        control: 'multi-select',
        options: ArrayToSelectOptions(availableFilters?.status || []),
        selected: ArrayToSelectOptions(status),
        onChange: (newValues: MultiValue<SelectOptions>) => {
          const newStatuses =
            availableFilters?.status.filter(s =>
              newValues.map(v => v.value).includes(s.cuid!),
            ) || [];
          setStatus(newStatuses);
        },
      },
      {
        name: 'Owners',
        control: 'multi-select',
        options: ArrayToSelectOptions(availableFilters?.owners || []),
        selected: ArrayToSelectOptions(owners),
        onChange: (newValues: MultiValue<SelectOptions>) => {
          const newOwners =
            availableFilters?.owners.filter(u =>
              newValues.map(v => v.value).includes(u.cuid),
            ) || [];
          setOwners(newOwners);
        },
      },
      {
        name: 'Developers',
        control: 'multi-select',
        options: ArrayToSelectOptions(availableFilters?.developers || []),
        selected: ArrayToSelectOptions(developers),
        onChange: (newValues: MultiValue<SelectOptions>) => {
          const newDevelopers =
            availableFilters?.developers.filter(u =>
              newValues.map(v => v.value).includes(u.cuid),
            ) || [];
          setDevelopers(newDevelopers);
        },
      },
      {
        name: 'Validators',
        control: 'multi-select',
        options: ArrayToSelectOptions(availableFilters?.validators || []),
        selected: ArrayToSelectOptions(validators),
        onChange: (newValues: MultiValue<SelectOptions>) => {
          const newValidators =
            availableFilters?.validators.filter(u =>
              newValues.map(v => v.value).includes(u.cuid),
            ) || [];
          setValidators(newValidators);
        },
      },
      {
        name: 'Use',
        control: 'multi-select',
        options: ArrayToSelectOptions(availableFilters?.uses || []),
        selected: ArrayToSelectOptions(uses),
        onChange: (newValues: MultiValue<SelectOptions>) => {
          const newUses = newValues.map(v => v.value);
          setUses(newUses);
        },
      },
      {
        name: 'Vendor Model',
        control: 'toggle',
        value: isVendorModel,
        onChange: (e: any) => {
          setIsVendorModel(e.target.checked);
        },
      },
    ];
  }, [
    availableFilters,
    tiers,
    businessUnits,
    status,
    owners,
    developers,
    validators,
    uses,
    isVendorModel,
  ]);

  return (
    <>
      <Popover
        strategy={'absolute'}
        closeOnBlur={true}
        closeOnEsc={true}
        isOpen={isOpen}
        onOpen={handleOpen}
        onClose={handleClose}
      >
        <PopoverTrigger>
          <Button
            leftIcon={<Icon as={FunnelIcon} boxSize={5} />}
            variant={'ghost'}
          >
            Filter
          </Button>
        </PopoverTrigger>
        <Portal>
          <PopoverContent w={'80vw'} maxW={'90rem'} mr={5} boxShadow={'xl'}>
            <PopoverArrow />
            <PopoverHeader
              py={4}
              px={10}
              fontSize={'lg'}
              fontWeight={'semibold'}
            >
              Select Your Filters
            </PopoverHeader>
            <PopoverCloseButton size={'md'} m={2} />
            <PopoverBody
              py={5}
              px={10}
              // This is needed because there a bug with the portal
              // where select controls are still clickable even
              // after the modal is hidden
              style={{ pointerEvents: isOpen ? 'initial' : 'none' }}
            >
              {isLoading ? (
                <Stack align={'center'}>
                  <Spinner color="brand.base" />
                  <small>Loading filters...</small>
                </Stack>
              ) : (
                <HStack spacing={8} align={'stretch'} flexWrap="wrap">
                  {filterDefs
                    .sort((a: any, b: any) => (a.name > b.name ? 1 : -1))
                    .map(filterDef => {
                      return (
                        <Stack>
                          <Label>{filterDef.name}</Label>
                          {filterDef.control === 'multi-select' && (
                            <Select
                              isMulti
                              size={'sm'}
                              colorScheme="brand"
                              options={filterDef.options as any}
                              menuShouldScrollIntoView={false}
                              menuPortalTarget={document.body}
                              classNamePrefix="chakra-react-select"
                              tagVariant="solid"
                              //hideSelectedOptions={false}
                              closeMenuOnSelect={false}
                              // components={selectCustomComponents}
                              value={filterDef.selected}
                              onChange={filterDef.onChange as any}
                              placeholder="Any"
                              chakraStyles={chakraStyles}
                              menuPosition="fixed"
                            />
                          )}
                          {filterDef.control === 'toggle' && (
                            <Switch
                              isChecked={filterDef.value}
                              onChange={filterDef.onChange as any}
                              size={'lg'}
                              colorScheme="brand"
                            />
                          )}
                        </Stack>
                      );
                    })}

                  {/*
                    Comment custom fields for now until we have a better way to display ALL the fields dynamically.
                    {customColumns?.map(column => {
                    return (
                      <Stack>
                        <Label >{column.title}</Label>
                        <CheckboxGroup colorScheme="brand" value={[]}>
                          <Stack spacing={2}>
                            <Checkbox onChange={e => {}} isChecked={false}>
                              All
                            </Checkbox>

                            {availableFilters?.custom_fields?.[
                              column.key
                            ].values.map((value: string) => {
                              return (
                                <Checkbox
                                  key={value}
                                  value={value}
                                  onChange={event => {}}
                                >
                                  {value}
                                </Checkbox>
                              );
                            })}
                          </Stack>
                        </CheckboxGroup>
                      </Stack>
                    );
                  })}*/}

                  {/* <Stack>
                    <Label >Performance Rating</Label>
                    <CheckboxGroup

                      value={performanceRatings}
                    >
                      <Stack spacing={2}>
                        <Checkbox
                          onChange={e =>
                            onSelectedCheckboxAllOption(
                              e.target.checked,
                              setPerformanceRatings,
                              availableFilters?.performance_rating!,
                            )
                          }
                          isChecked={
                            availableFilters?.performance_rating.length ===
                            performanceRatings.length
                          }
                        >
                          All
                        </Checkbox>

                        {availableFilters?.performance_rating.map(pr => (
                          <Checkbox
                            key={pr}
                            value={pr}
                            onChange={event =>
                              onSelectedCheckboxOption(
                                pr,
                                event.target.checked,
                                setPerformanceRatings,
                                performanceRatings,
                                obj => obj !== pr,
                              )
                            }
                          >
                            <InventoryModelPerformanceRating
                              rating={pr.toString()}
                            />
                          </Checkbox>
                        ))}
                      </Stack>
                    </CheckboxGroup>
                  </Stack> */}

                  {/* <Stack>
                    <VStack spacing={8}>
                      <Stack>
                        <Label>Last Completed Validation</Label>
                        <HStack>
                          <DatePicker
                            customInput={<DatePickerInput />}
                            selected={lastCompletedIVMDateFrom}
                            onChange={(date: any) =>
                              setLastCompletedIVMDateFrom(date)
                            }
                            selectsStart
                            startDate={lastCompletedIVMDateFrom}
                            endDate={lastCompletedIVMDateTo}
                            minDate={
                              availableFilters?.last_completed_imv_dates &&
                              dayjs(
                                availableFilters.last_completed_imv_dates[0] *
                                  1000,
                              ).toDate()
                            }
                            maxDate={
                              availableFilters?.last_completed_imv_dates &&
                              dayjs(
                                availableFilters.last_completed_imv_dates[1] *
                                  1000,
                              ).toDate()
                            }
                          />
                          <DatePicker
                            customInput={<DatePickerInput />}
                            selected={lastCompletedIVMDateTo}
                            onChange={(date: any) =>
                              setLastCompletedIVMDateTo(date)
                            }
                            selectsEnd
                            startDate={lastCompletedIVMDateFrom}
                            endDate={lastCompletedIVMDateTo}
                            minDate={
                              availableFilters?.last_completed_imv_dates &&
                              dayjs(
                                availableFilters.last_completed_imv_dates[0] *
                                  1000,
                              ).toDate()
                            }
                            maxDate={
                              availableFilters?.last_completed_imv_dates &&
                              dayjs(
                                availableFilters.last_completed_imv_dates[1] *
                                  1000,
                              ).toDate()
                            }
                          />
                        </HStack>
                      </Stack>

                      <Stack>
                        <Label>Next Schedule Validation</Label>
                        <HStack>
                          <DatePicker
                            customInput={<DatePickerInput />}
                            selected={nextPlannedIVMDateFrom}
                            onChange={(date: any) =>
                              setNextPlannedIVMDateFrom(date)
                            }
                            selectsStart
                            startDate={nextPlannedIVMDateFrom}
                            endDate={nextPlannedIVMDateTo}
                            minDate={
                              availableFilters?.next_planned_imv_dates &&
                              dayjs(
                                availableFilters.next_planned_imv_dates[0] *
                                  1000,
                              ).toDate()
                            }
                            maxDate={
                              availableFilters?.next_planned_imv_dates &&
                              dayjs(
                                availableFilters.next_planned_imv_dates[1] *
                                  1000,
                              ).toDate()
                            }
                          />
                          <DatePicker
                            customInput={<DatePickerInput />}
                            selected={nextPlannedIVMDateTo}
                            onChange={(date: any) =>
                              setNextPlannedIVMDateTo(date)
                            }
                            selectsEnd
                            startDate={nextPlannedIVMDateFrom}
                            endDate={nextPlannedIVMDateTo}
                            minDate={
                              availableFilters?.next_planned_imv_dates &&
                              dayjs(
                                availableFilters.next_planned_imv_dates[0] *
                                  1000,
                              ).toDate()
                            }
                            maxDate={
                              availableFilters?.next_planned_imv_dates &&
                              dayjs(
                                availableFilters.next_planned_imv_dates[1] *
                                  1000,
                              ).toDate()
                            }
                          />
                        </HStack>
                      </Stack>
                    </VStack>
                  </Stack> */}
                </HStack>
              )}
            </PopoverBody>
            <PopoverFooter p={5}>
              <Flex justifyContent={'flex-end'}>
                <Button variant="ghost" mr={3} onClick={onClearFilters}>
                  Clear All Filters
                </Button>
                <Spacer />
                <Button onClick={onApplyFilters} variant={'primary'}>
                  Apply Filters
                </Button>
              </Flex>
            </PopoverFooter>
          </PopoverContent>
        </Portal>
      </Popover>
    </>
  );
}
