import { NodeProps, Position, useStore } from 'reactflow';
import { Box, Text, useColorModeValue } from '@chakra-ui/react';
import { EndNodeType } from '../../types';
import NodeBox from '../../layouts/NodeBox';
import CustomHandle from '../../handles/CustomHandle';

function EndNode(props: NodeProps) {
  const zoomSelector = (s: any) => s.transform[2] >= 0.5;
  const showNodeDetails = useStore(zoomSelector);
  return (
    <NodeBox rounded={'full'} nodeProps={props}>
      <Box
        bg={useColorModeValue('red.100', 'red.800')}
        rounded={'full'}
        border={'1px solid'}
        borderColor={'green.500'}
        p={8}
      >
        <CustomHandle
          type="target"
          position={Position.Top}
          style={{ bottom: -6 }}
        />
        <Text fontWeight={'bold'} fontSize={showNodeDetails ? 'sm' : '2xl'}>
          End
        </Text>
      </Box>
    </NodeBox>
  );
}

EndNode.type = 'end';
EndNode.autoRuns = true;

EndNode.getDefaultNode = (): EndNodeType => {
  return {
    id: 'end',
    type: EndNode.type,
    position: { x: 0, y: 0 },
    data: {
      state_callbacks: {
        on_enter: [
          {
            func: 'run_save_execution_parameter',
            args: {
              key: 'status',
              value: 'finished',
            },
          },
        ],
        on_exit: [],
      },
      transition_callbacks: {
        conditions: [],
        prepare: [],
        before: [],
        after: [],
      },
    },
  };
};

export default EndNode;
