import { Box, HStack, Progress, Text } from '@chakra-ui/react';

interface ExecutionProgressProps {
  progress: number;
}

const ExecutionProgress = ({ progress }: ExecutionProgressProps) => {
  return (
    <HStack w={'full'} maxW={'10rem'}>
      <Box w={'full'}>
        <Progress
          size="sm"
          value={progress}
          rounded={'full'}
          colorScheme="green"
          border={'1px solid var(--chakra-colors-chakra-border-color)'}
          bg={'var(--chakra-colors-chakra-body-bg)'}
        />
      </Box>
      <Text fontSize={'sm'} color={'neutral.500'}>
        {progress}%
      </Text>
    </HStack>
  );
};

export default ExecutionProgress;
