import { TFinding } from '../../models';
import {
  Heading,
  HStack,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Tag,
  TagLeftIcon,
  Text,
} from '@chakra-ui/react';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { Icon } from '@chakra-ui/icons';
import FindingRow from '../FindingsList/FindingRow';

const FindingIndicator = ({
  findings,
  severity,
}: {
  findings: TFinding[];
  severity: 'High' | 'Medium' | 'Low';
}) => {
  const colorMap = {
    High: 'red',
    Medium: 'yellow',
    Low: 'neutral',
  };
  return (
    <Popover trigger="hover" placement={'top-end'}>
      <PopoverTrigger>
        <Tag
          variant={'outline'}
          colorScheme={colorMap[severity]}
          rounded={'full'}
        >
          <TagLeftIcon boxSize={4} as={ExclamationTriangleIcon} />
          {findings.length}
        </Tag>
      </PopoverTrigger>
      <PopoverContent shadow={'md'} w={'40rem'}>
        <PopoverArrow />
        <PopoverHeader background={'transparent'}>
          <Heading as={'h4'}>
            <HStack alignItems={'flex-end'} justifyItems={'flex-start'} pt={2}>
              <Icon as={ExclamationTriangleIcon} boxSize={5} mt={0.5} />
              <Text whiteSpace={'normal'}>
                {findings.length} Unresolved {severity} Risk Model Findings
              </Text>
            </HStack>
          </Heading>
        </PopoverHeader>
        <PopoverBody bg={'white'}>
          {findings.map(finding => (
            <FindingRow key={finding.cuid} finding={finding} />
          ))}
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default FindingIndicator;
