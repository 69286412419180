import { switchAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { defineMultiStyleConfig, definePartsStyle } =
  createMultiStyleConfigHelpers(parts.keys);

export const Switch = defineMultiStyleConfig({
  baseStyle: {
    field: {},
    container: {},
    thumb: {},
    track: {
      _checked: {},
    },
  },
  defaultProps: {
    colorScheme: 'brand',
  },
});
