import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  useToast,
  VStack,
  InputGroup,
  InputRightElement,
  Icon,
  Text,
} from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline';
import { generateStrongPassword } from '../../utils';
import { useAuthConfig } from '../../hooks/useAuthConfig';

interface EditUserModalProps {
  isOpen: boolean;
  onClose: () => void;
  user: {
    cuid: string;
    first_name: string | null;
    last_name: string | null;
    email: string;
    job_title: string | null;
  };
  onUpdate: (data: {
    first_name: string;
    last_name?: string;
    email: string;
    job_title: string;
    password?: string;
  }) => Promise<void>;
}

export default function EditUserModal({
  isOpen,
  onClose,
  user,
  onUpdate,
}: EditUserModalProps) {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [jobTitle, setJobTitle] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const { canChangePassword } = useAuthConfig();

  useEffect(() => {
    if (isOpen) {
      setFirstName(user.first_name || '');
      setLastName(user.last_name || '');
      setEmail(user.email);
      setJobTitle(user.job_title || '');
      setPassword('');
    }
  }, [isOpen, user]);

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      const updateData: {
        first_name: string;
        last_name?: string;
        email: string;
        job_title: string;
        password?: string;
      } = {
        first_name: firstName,
        email,
        job_title: jobTitle,
      };

      if (lastName) {
        updateData.last_name = lastName;
      }

      if (password) {
        updateData.password = password;
      }

      await onUpdate(updateData);
      toast({
        title: 'User updated successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      onClose();
    } catch (error) {
      toast({
        title: 'Error updating user',
        description: 'Please try again',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit User</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={4}>
            <FormControl>
              <FormLabel>First Name</FormLabel>
              <Input
                value={firstName}
                onChange={e => setFirstName(e.target.value)}
                placeholder="Enter first name"
              />
            </FormControl>
            <FormControl>
              <FormLabel>Last Name</FormLabel>
              <Input
                value={lastName}
                onChange={e => setLastName(e.target.value)}
                placeholder="Enter last name"
              />
            </FormControl>
            <FormControl>
              <FormLabel>Email</FormLabel>
              <Input
                value={email}
                onChange={e => setEmail(e.target.value)}
                placeholder="Enter email"
              />
            </FormControl>
            <FormControl>
              <FormLabel>Job Title</FormLabel>
              <Input
                value={jobTitle}
                onChange={e => setJobTitle(e.target.value)}
                placeholder="Enter job title"
              />
            </FormControl>
            {canChangePassword && (
              <FormControl>
                <FormLabel>New Password</FormLabel>
                <InputGroup>
                  <Input
                    type={showPassword ? 'text' : 'password'}
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                    placeholder="Leave blank to keep current password"
                  />
                  <InputRightElement width="4.5rem">
                    <Button
                      h="1.75rem"
                      size="sm"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      <Icon
                        as={showPassword ? EyeSlashIcon : EyeIcon}
                        strokeWidth={2}
                      />
                    </Button>
                  </InputRightElement>
                </InputGroup>
                <Text fontSize="sm" color="gray.500" mt={1}>
                  Leave blank to keep current password
                </Text>
                <Button
                  size="sm"
                  variant="link"
                  onClick={() => setPassword(generateStrongPassword())}
                  mt={2}
                >
                  Generate strong password
                </Button>
              </FormControl>
            )}
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button variant="ghost" mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button
            colorScheme="brand"
            onClick={handleSubmit}
            isLoading={isLoading}
          >
            Save Changes
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
