import {
  Alert,
  AlertIcon,
  Box,
  Heading,
  Skeleton,
  Stack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { useQuery } from 'react-query';
import API from '../../api/API';
import { EntityName, Execution } from '../../models/workflow';
import { FieldValues } from '../../hooks/useFields';
import { Label } from '../Layout';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import ExecutionStateTag from '../ExecutionStateTag';
import ExecutionProgress from '../ExecutionProgress';
import WorkflowApprovalActions from '../WorkflowApprovalActions';
import WorkflowManagerModal from '../WorkflowManagerModal/components/WorkflowManagerModal';
import { WorkflowManagerNavigationState } from '../WorkflowManagerModal/contexts/navigation/NavigationContext';
import StandaloneWorkflowActions from '../WorkflowManagerModal/components/StandaloneWorkflowActions';
import { EmptyStateDisplay } from '../EmptyStateDisplay';

const WorkflowExecutions: React.FC<{
  target: any;
  entityName: EntityName;
  customFields: FieldValues;
}> = ({ target, entityName, customFields }) => {
  const [execution, setExecution] = useState<Execution>();
  const executionStatuses = ['active', 'waiting', 'scheduled'];
  const { data: executions = [], isLoading } = useQuery(
    ['targets', target.cuid, 'executions', executionStatuses],
    async () => {
      return API.GetWorkflowExecutionsForTarget(target.cuid, executionStatuses);
    },
  );

  const now = dayjs();
  let delayDays: number | 0;

  return (
    <Stack gap={4}>
      <Label>Active Workflows</Label>
      <Stack>
        {executions.length > 0 ? (
          executions.map((execution: Execution) => {
            if (execution.timeline.delayed_since) {
              const delayedSinceDate = dayjs.unix(
                execution.timeline.delayed_since,
              );
              delayDays = now.diff(delayedSinceDate, 'days', true);
            }

            return (
              <Skeleton isLoaded={!isLoading} key={execution.cuid}>
                <Box pointerEvents="auto">
                  <Stack
                    id={`execution-${execution.cuid}`}
                    gap={0}
                    role="group"
                    rounded={'md'}
                    border={'1px solid'}
                    bg={useColorModeValue('neutral.25', 'black')}
                    borderColor={'var(--chakra-colors-chakra-border-color)'}
                    _hover={{
                      cursor: 'pointer',
                      bg: useColorModeValue(
                        'brandSecondary.25',
                        'brandSecondary.900',
                      ),
                      color: useColorModeValue(
                        'brandSecondary.800',
                        'brandSecondary.25',
                      ),
                      borderColor: 'brandSecondary.600',
                    }}
                    color={useColorModeValue(
                      'brandSecondary.600',
                      'brandSecondary.100',
                    )}
                    transition={'background, border 0.3s ease-in-out'}
                    onClick={() => {
                      setExecution(execution);
                    }}
                    data-testid="workflow-execution-card"
                  >
                    {execution.timeline.delayed_since && (
                      <Alert
                        status="error"
                        variant="subtle"
                        py={1}
                        px={4}
                        roundedBottom={0}
                        roundedTop={4}
                        boxShadow={useColorModeValue(
                          '0 0 0 1px var(--chakra-colors-red-50)',
                          '0 0 0 1px var(--chakra-colors-red-800)',
                        )}
                        // color={useColorModeValue('red.50', 'red.50')}
                        color={'var(--alert-fg)'}
                        fontWeight={'bold'}
                        fontSize={'sm'}
                        borderBottom={'none'}
                        boxSizing="border-box"
                        mb={'1px'}
                        _groupHover={{
                          mb: '0',
                          borderBottom: useColorModeValue(
                            '1px solid var(--chakra-colors-red-50)',
                            '1px solid var(--chakra-colors-red-800)',
                          ),
                          boxShadow: 'none',
                        }}
                      >
                        <AlertIcon />
                        <Text>
                          {`Late by ${delayDays.toFixed(1)} day${
                            delayDays && delayDays > 1 ? 's' : ''
                          }`}
                        </Text>
                      </Alert>
                    )}
                    <Stack gap={8} p={4}>
                      <Stack gap={4}>
                        <Heading as={'h5'}>
                          {`${execution.execution_id} - ${execution.workflow.title}`}
                        </Heading>

                        {execution.progress && (
                          <ExecutionProgress progress={execution.progress} />
                        )}

                        {execution.state && (
                          <Box>
                            <ExecutionStateTag state={execution.state} />
                          </Box>
                        )}

                        <StandaloneWorkflowActions
                          target={target}
                          workflow={execution.workflow}
                          execution={execution}
                        />
                      </Stack>
                      <WorkflowApprovalActions
                        variant="vertical"
                        targetCuid={target.cuid}
                        executionCuid={execution.cuid}
                      />
                    </Stack>
                  </Stack>
                </Box>
              </Skeleton>
            );
          })
        ) : (
          <EmptyStateDisplay variant="no-activity" size={'sm'}>
            <Heading as={'h5'}>No workflows are currently running</Heading>
            <Text>Check the available workflows to run on this model.</Text>
          </EmptyStateDisplay>
        )}
      </Stack>

      {execution && (
        <WorkflowManagerModal
          target={target}
          workflowCuid={execution.workflow.cuid}
          executionCuid={execution.cuid}
          isOpen={true}
          navigation={WorkflowManagerNavigationState.DETAIL}
          onClose={() => setExecution(undefined)}
        />
      )}
    </Stack>
  );
};

export default WorkflowExecutions;
