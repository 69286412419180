import {
  Select,
  Stack,
  Tag,
  TagLeftIcon,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import { upperFirst } from 'lodash';
import { TInventoryModel } from '../../models/inventory_model';
import EditableField from '../EditableField';
import { useEffect, useMemo, useState } from 'react';
import ConfirmationAlert from '../ConfirmationAlert';
import { useMutation } from 'react-query';
import API from '../../api/API';
import { useNavigate } from 'react-router-dom';
import { CONFIG } from '../../config';
import { useStatusesWorkflows } from '../../pages/Settings/Workflows/hooks/useStatuses';
import {
  ReservedStages,
  TStatusesWorkflowStatus,
} from '../../models/statuses_workflow';
import { removeAfterDot } from '../../utils';
import { CubeIcon } from '@heroicons/react/24/outline';

interface ModelStageFieldProps {
  inventoryModel: TInventoryModel;
  onSave?: () => void;
  readOnly?: boolean;
}

const ModelStageField: React.FC<ModelStageFieldProps> = ({
  inventoryModel,
  onSave,
  readOnly = false,
}) => {
  const toast = useToast();
  const navigate = useNavigate();
  const { data: workflowStatuses } = useStatusesWorkflows();

  const [currentStage, setCurrentStage] = useState<
    TStatusesWorkflowStatus | undefined
  >(inventoryModel.status);
  const [pendingStage, setPendingStage] =
    useState<TStatusesWorkflowStatus | null>(null);
  const [confirmationState, setConfirmationState] = useState({
    archive: false,
    delete: false,
  });

  // update current stage when inventory model status changes
  useEffect(() => {
    if (inventoryModel.status) {
      setCurrentStage(inventoryModel.status);
    }
  }, [inventoryModel.status]);

  // available stages from workflow
  const availableStages: TStatusesWorkflowStatus[] = useMemo(() => {
    return workflowStatuses && workflowStatuses.length > 0
      ? workflowStatuses[0].statuses
      : [];
  }, [workflowStatuses]);

  // check if stage has changed
  const stageChanged = useMemo(() => {
    return currentStage !== inventoryModel.status;
  }, [inventoryModel.status, currentStage]);

  const postStage = useMutation(
    async (stage: TStatusesWorkflowStatus) => {
      return API.PostInventoryModelStage(inventoryModel, stage);
    },
    {
      onError: error => {
        if (error instanceof Error) {
          toast({
            variant: 'subtle',
            title: 'Error',
            description: error.message,
            status: 'error',
            duration: 9000,
            isClosable: true,
          });
        }
      },
    },
  );

  const showSuccessToast = (stageName: string) => {
    const message =
      stageName === ReservedStages.Archived
        ? `Model "${inventoryModel.name}" has been archived successfully`
        : stageName === ReservedStages.Deleted
        ? `Model "${inventoryModel.name}" has been deleted successfully`
        : `Model "${inventoryModel.name}" has been updated successfully`;

    toast({
      variant: 'subtle',
      description: message,
      status: 'success',
      duration: 3000,
      isClosable: true,
    });
  };

  const handleSave = async (onFinished: (success: boolean) => void) => {
    if (!stageChanged || !currentStage) {
      onFinished(true);
      return;
    }

    if (currentStage.name === ReservedStages.Archived) {
      setPendingStage(currentStage);
      setConfirmationState(prev => ({ ...prev, archive: true }));
      onFinished(false); // Keep form open
    } else if (currentStage.name === ReservedStages.Deleted) {
      setPendingStage(currentStage);
      setConfirmationState(prev => ({ ...prev, delete: true }));
      onFinished(false); // Keep form open
    } else {
      await updateModelStage(currentStage, onFinished);
    }
  };

  // update model stage
  const updateModelStage = async (
    stage: TStatusesWorkflowStatus,
    onFinished: (success: boolean) => void,
  ) => {
    postStage.mutate(stage, {
      onSuccess: () => {
        showSuccessToast(stage.name);

        if (stage.name === ReservedStages.Deleted) {
          navigate('/model-inventory', { replace: true });
        }

        if (onSave) {
          onSave();
        }

        onFinished(true);
      },
      onError: () => {
        onFinished(false);
      },
    });
  };

  const handleConfirmation = (
    confirmed: boolean,
    type: 'archive' | 'delete',
  ) => {
    if (confirmed && currentStage) {
      updateModelStage(currentStage, success => {
        if (!success) {
          setCurrentStage(inventoryModel.status);
        }
      });
    } else {
      setCurrentStage(inventoryModel.status);
    }

    setConfirmationState(prev => ({
      ...prev,
      [type]: false,
    }));
    setPendingStage(null);
  };

  return (
    <>
      {/* Confirmation dialogs */}
      <ConfirmationAlert
        open={confirmationState.archive}
        onConfirm={confirmed => handleConfirmation(confirmed, 'archive')}
        title={'Archive Model'}
        dialogBody={'Are you sure you want to archive this model?'}
        confirmButton={'Yes, archive it'}
      />
      <ConfirmationAlert
        open={confirmationState.delete}
        onConfirm={confirmed => handleConfirmation(confirmed, 'delete')}
        title={'Delete Model'}
        dialogBody={'Are you sure you want to delete this model?'}
        confirmButton={'Yes, delete it'}
      />

      {/* EditableField component */}
      <EditableField
        readOnly={readOnly}
        onCancel={() => setPendingStage(null)}
        onSave={handleSave}
        title={'Model Stage'}
        moreInfoPopoverProps={{
          title: 'Model Stage',
          description:
            "Keep your model inventory accurate and up to date with your organization's current resources.",
          link: `${CONFIG.VALIDMIND_DOCS_URL}/guide/model-inventory/archive-delete-models.html`,
          iconProps: {
            ml: 2,
            opacity: 0,
            _groupHover: { opacity: 1 },
          },
        }}
        renderField={(mode: 'edit' | 'read-only') => (
          <Stack w={'full'}>
            {mode === 'edit' ? (
              <Select
                bg={useColorModeValue('white', 'neutral.850')}
                focusBorderColor="brand.base"
                value={currentStage ? currentStage.cuid : ''}
                onChange={e => {
                  setCurrentStage(
                    availableStages.find(s => s.cuid === e.target.value),
                  );
                }}
              >
                {availableStages.map(s => (
                  <option key={s.cuid} value={s.cuid}>
                    {upperFirst(s.name)}
                  </option>
                ))}
              </Select>
            ) : (
              <Tag
                data-testid="current-model-stage"
                colorScheme={
                  currentStage
                    ? removeAfterDot(currentStage.colors.primary)
                    : 'neutral'
                }
                size="lg"
                w={'max-content'}
              >
                <TagLeftIcon as={CubeIcon} />
                {currentStage ? currentStage.name : 'No stage'}
              </Tag>
            )}
          </Stack>
        )}
      />
    </>
  );
};

export default ModelStageField;
