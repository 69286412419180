import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  VStack,
  List,
  Box,
  ListItem,
  Code,
} from '@chakra-ui/react';
import { Label } from '../../components/Layout';

interface ExportOrganizationErrorsModalProps {
  isOpen: boolean;
  onClose: () => void;
  errors: Record<string, string[]>;
}

export function ExportOrganizationErrorsModal({
  isOpen,
  onClose,
  errors,
}: ExportOrganizationErrorsModalProps) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="3xl" isCentered={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Export Organization Errors</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={6} align="stretch" mb={4}>
            <List spacing={2}>
              {Object.keys(errors).map(modelName => {
                return (
                  <Box>
                    <Label mb={2}>{modelName}</Label>
                    {errors[modelName].map((error, index) => (
                      <ListItem display="flex" alignItems="center">
                        <Code
                          key={index}
                          display="block"
                          fontSize="sm"
                          colorScheme="red"
                          w="full"
                        >
                          {error}
                        </Code>
                      </ListItem>
                    ))}
                  </Box>
                );
              })}
            </List>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
