import { NodeProps, Position, useStore } from 'reactflow';
import {
  Button,
  CircularProgress,
  Icon,
  IconButton,
  Progress,
  Stack,
  Tag,
  TagLeftIcon,
  Text,
  Tooltip,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
import { useQuery } from 'react-query';
import {
  generateRandomCodeForNodes,
  removeAfterDot,
} from '../../../../../../utils';
import API from '../../../../../../api/API';
import NodeBox from '../../layouts/NodeBox';
import { SignalIcon } from '@heroicons/react/20/solid';
import {
  ArrowsRightLeftIcon,
  Cog6ToothIcon,
} from '@heroicons/react/24/outline';
import useWorkflow from '../../../../../../hooks/useWorkflow';
import { StateSetNodeType } from '../../types';
import { ForwardIcon } from '@heroicons/react/24/outline';
import useWorkflowExecution from '../../../../../../hooks/useWorkflowExecution';
import { useContext } from 'react';
import UsersContext from '../../../../../../contexts/UsersContext';
import CustomHandle from '../../handles/CustomHandle';
import { ExecutionStatus } from '../../../../../../models/workflow';

const zoomSelector = (s: any) => s.transform[2] >= 0.5;

function StateSetNode(props: NodeProps) {
  const { currentUser } = useContext(UsersContext);
  const { workflow, setSelectedNodeId } = useWorkflow();
  const { execution, seek } = useWorkflowExecution();
  const isAdmin = currentUser?.roles.some(role => role.role.is_admin);
  const showContent = useStore(zoomSelector);

  const stateCuid = props.data.state_callbacks.on_enter[0].args.cuid;

  const { data: state, isLoading } = useQuery(
    ['workflows', workflow?.cuid, 'states', stateCuid],
    async () => {
      return API.GetWorkflowState(workflow!.cuid, stateCuid);
    },
    {
      enabled: !!stateCuid,
    },
  );

  if (!stateCuid) {
    return (
      <NodeBox
        title={'Workflow State Change'}
        icon={SignalIcon}
        nodeProps={props}
        rounded={'lg'}
      >
        <VStack w={'full'} px={4} py={2}>
          {showContent && (
            <Text fontStyle={'oblique'} w={40} textAlign={'center'}>
              This step hasn't been configured yet.
            </Text>
          )}
          <Button
            leftIcon={<Icon as={Cog6ToothIcon} boxSize={4} />}
            onClick={() => setSelectedNodeId!(props.id)}
            w={'full'}
          >
            Configure
          </Button>
        </VStack>
      </NodeBox>
    );
  }

  if (isLoading) {
    return (
      <NodeBox
        title={'Workflow State Change'}
        icon={SignalIcon}
        nodeProps={props}
        rounded={'lg'}
      >
        <Stack justify={'center'} align={'center'}>
          <CircularProgress
            size="40px"
            thickness="2px"
            isIndeterminate
            color="brand.base"
          />
          <Text fontSize={'sm'} color={'inherit'}>
            Please hold...
          </Text>
        </Stack>
      </NodeBox>
    );
  }

  const onSeekToHere = async () => {
    if (seek) {
      seek.mutate({ nodeTargetId: props.id });
    }
  };

  const canSeek = !!(
    execution &&
    seek &&
    isAdmin &&
    [ExecutionStatus.ACTIVE].includes(execution.status)
  );

  return (
    <NodeBox
      title={'Workflow State Change'}
      icon={SignalIcon}
      nodeProps={props}
      border={2}
      borderColor={useColorModeValue('neutral.200', 'neutral.700')}
      borderStyle={'solid'}
      rounded={showContent ? 'lg' : 'full'}
      pt={showContent ? 0 : 2}
      px={showContent ? 0 : 4}
    >
      <CustomHandle
        type="target"
        position={Position.Top}
        style={{ top: -6 }}
        id="top"
      />

      <>
        {canSeek && (
          <Tooltip label="Double click to transition here">
            <IconButton
              // isRound={true}
              style={{
                position: 'absolute',
                top: '.5rem',
                left: '-1.9rem',
                borderRight: 0,
                background: 'var(--chakra-colors-neutral-25)',
              }}
              borderColor={'neutral.200'}
              aria-label="Seek to Here"
              size={'sm'}
              roundedRight={'0'}
              icon={<Icon as={ForwardIcon} boxSize={6} />}
              onDoubleClick={onSeekToHere}
              isLoading={
                seek?.variables?.nodeTargetId == props.id && seek?.isLoading
              }
            />
          </Tooltip>
        )}
      </>

      <VStack
        w={'full'}
        alignItems={'center'}
        gap={1}
        px={showContent ? 4 : 2}
        py={2}
      >
        {showContent ? <Text fontSize={'sm'}>Set workflow state to</Text> : ''}
        <Tag
          data-testid="status-badge"
          size={showContent ? 'sm' : '2xl'}
          colorScheme={removeAfterDot(state?.colors?.primary!)}
          variant={'outline'}
        >
          <TagLeftIcon as={ArrowsRightLeftIcon} />
          {state?.name}
        </Tag>
      </VStack>

      <VStack
        w={'full'}
        alignItems={'center'}
        gap={1}
        px={showContent ? 4 : 2}
        py={2}
      >
        {showContent ? (
          <Text fontSize={'sm'}>Set workflow progress to</Text>
        ) : (
          ''
        )}
        <Progress
          value={props.data.state_callbacks.on_enter[1].args.progress}
          rounded={'full'}
          colorScheme="green"
          border={'1px solid var(--chakra-colors-chakra-border-color)'}
          bg={'var(--chakra-colors-chakra-body-bg)'}
          w={'full'}
        />
        {showContent ? (
          <Text fontSize={'sm'}>
            {props.data.state_callbacks.on_enter[1].args.progress}%
          </Text>
        ) : (
          ''
        )}
      </VStack>
      <CustomHandle
        type="source"
        position={Position.Bottom}
        style={{ bottom: -6 }}
        id="bottom"
      />
    </NodeBox>
  );
}

StateSetNode.type = 'state_set';
StateSetNode.autoRuns = true;

StateSetNode.getDefaultNode = (): StateSetNodeType => {
  return {
    id: `${StateSetNode.type}_${generateRandomCodeForNodes()}`,
    type: StateSetNode.type,
    data: {
      state_callbacks: {
        on_enter: [
          {
            func: 'run_set_state',
            args: { cuid: null, _name: null },
          },
          {
            func: 'set_progress',
            args: { progress: null },
          },
        ],
        on_exit: [],
      },
      transition_callbacks: {
        conditions: [],
        prepare: [],
        before: [],
        after: [],
      },
    },
    position: { x: 0, y: 0 },
  };
};

export default StateSetNode;
