import { Text } from '@chakra-ui/react';
import { humanizeJsonLogic } from '../../pages/Settings/Workflows/components/utils/humanizeJsonLogic';
import { useOrgRoles } from '../../hooks/useOrgRoles';
import useModelSchema from '../../hooks/useModelSchema';
import { FIELD_IDENTIFIERS } from '../../pages/Settings/Workflows/hooks/useQueryBuilder';

interface JSONLogicHumanizerProps {
  logic: any;
}

function JSONLogicHumanizer({ logic }: JSONLogicHumanizerProps) {
  let jsonLogicQuery;
  try {
    jsonLogicQuery = JSON.parse(logic);
  } catch (error) {
    console.error('Invalid JSON Logic query:', logic);
    jsonLogicQuery = null;
  }

  const { propertyItems } = useModelSchema();
  const { orgRoles } = useOrgRoles();

  // Generate value mappers dynamically from customFieldSchemaItems
  const valueMappers = propertyItems.reduce((mappers, item) => {
    // Map the custom field key to its displayable label
    mappers[`custom_fields.${item.key}`] = () =>
      item.settings.label || item.title;
    return mappers;
  }, {} as Record<string, (value: any) => string>);

  // Add role mappings to valueMappers
  const roleMappings = orgRoles
    .filter(r => r.scope !== 'Model')
    .reduce((mappings, role) => {
      mappings[`${FIELD_IDENTIFIERS.USER_ROLES}.${role.cuid}`] = () =>
        role.name; // Map role ID to role name
      return mappings;
    }, {} as Record<string, (value: any) => string>);

  const stakeholdersMappings = orgRoles
    .filter(r => r.scope === 'Model')
    .reduce((mappings, role) => {
      mappings[`${FIELD_IDENTIFIERS.USER_MODEL_STAKEHOLDERS}.${role.cuid}`] =
        () => role.name;
      return mappings;
    }, {} as Record<string, (value: any) => string>);

  // Add a general mapper for user.roles
  valueMappers[FIELD_IDENTIFIERS.USER_ROLES] = value => {
    const role = orgRoles.find(role => role.cuid === value);
    return role ? role.name : value; // Return the role name if found, otherwise return the original value
  };

  valueMappers[FIELD_IDENTIFIERS.USER_MODEL_STAKEHOLDERS] = value => {
    const role = orgRoles.find(role => role.cuid === value);
    return role ? role.name : value;
  };

  // Merge custom field mappers and role mappers
  const mergedMappers = {
    ...valueMappers,
    ...roleMappings,
    ...stakeholdersMappings,
  };
  const humanized = humanizeJsonLogic(jsonLogicQuery, mergedMappers);

  return (
    <Text
      dangerouslySetInnerHTML={{
        __html: jsonLogicQuery
          ? humanized.replace(/\n/g, '<br />')
          : 'Invalid condition format',
      }}
    />
  );
}

export default JSONLogicHumanizer;
