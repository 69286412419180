import { useCallback, useContext, useEffect, useState } from 'react';
import RecordDetailsPage from '../../../../components/Layout/RecordDetailsPage';
import {
  Box,
  Button,
  FormControl,
  Heading,
  HStack,
  Icon,
  Link,
  Select,
  SimpleGrid,
  Spacer,
  Stack,
  StackDivider,
  Text,
  useColorModeValue,
  useDisclosure,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverHeader,
  PopoverArrow,
  VStack,
  Switch,
  PopoverCloseButton,
  FormLabel,
} from '@chakra-ui/react';

import InventoryModelContext from '../../../../contexts/InventoryModel';
import MasterHeader from '../../../../components/Layout/MasterHeader';
import { Label } from '../../../../components/Layout';
import MasterStatusBar from '../../../../components/Layout/MasterStatusBar';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import API from '../../../../api/API';
import ModelInventoryItem from '../../../../components/ModelInventoryItem';
import FindingsWidget from '../../../../components/FindingsWidget';
import ActivityFeedWidget, {
  ActivityFeedWidgetQueryKey,
} from '../../../../components/ActivityFeedWidget';
import {
  InventoryModelStages,
  TInventoryModel,
  TInventoryModelDependency,
  TInventoryModelUser,
  TInventoryModelUserPost,
} from '../../../../models/inventory_model';
import DependenciesFlow from './DependenciesFlow';
import { Edge, MarkerType, Node } from 'reactflow';
import { EventFilters } from '../../../../models/event';
import { Copyright } from '../../../../components/Copyright';
import { canUpdateInventoryModel } from '../../../../auth/utils';
import MasterSearchBar from '../../../../components/Layout/MasterSearchBar';
import UsersContext from '../../../../contexts/UsersContext';
import AttributesRail from '../../../../components/Layout/AttributesRail';
import CoreField from '../../../../components/CustomFields/CoreField';
import WorkflowExecutionsViewer from '../../../../components/WorkflowExecutionsViewer';
import WorkflowApprovalActions from '../../../../components/WorkflowApprovalActions';
import WorkflowStatusActions from '../../../../components/WorkflowStatusActions';
import { FieldValues } from '../../../../hooks/useFields';
import ManagedCustomField from '../../../../components/NewCustomFields/ManagedCustomField';
import ManagedField from '../../../../components/ManagedField';
import _ from 'lodash';
import { EmptyStateDisplay } from '../../../../components/EmptyStateDisplay';
import { useGroups } from '../../../../hooks/useGroups';
import EditableField from '../../../../components/EditableField';
import { TGroup, TRole } from '../../../../models';
import {
  ArrowTopRightOnSquareIcon,
  ExclamationCircleIcon,
  Squares2X2Icon,
  UserPlusIcon,
} from '@heroicons/react/24/outline';
import { DependencyManagerModal } from './DependenciesFlow/DependencyManagerModal';
import { InfoIcon } from '@chakra-ui/icons';
import ManageAttachmentsField from '../../../../components/ManageAttachmentsField';
import ModelStageField from '../../../../components/ModelStageField';
import MoreInfoPopOver from '../../../../components/MoreInfoPopOver';
import AvatarProxy from '../../../../components/AvatarProxy';
import WorkflowExecutions from '../../../../components/WorkflowExecutions';
import { useFlags } from '../../../../hooks/useFlags';
import { useModelPageLayout } from '../../../../hooks/useModelPageLayout';
import EditModelLayoutModal from '../../../../components/EditModelLayoutModal';
import WorkflowsExecutionsTimelineWidget from '../../../../components/WorkflowsExecutionsTimelineWidget';
import { EntityName } from '../../../../models/workflow';
import { CONFIG } from '../../../../config';
import { WorkflowManagerNavigationState } from '../../../../components/WorkflowManagerModal/contexts/navigation/NavigationContext';
import WorkflowManagerModal from '../../../../components/WorkflowManagerModal/components/WorkflowManagerModal';
import useModelSchema from '../../../../hooks/useModelSchema';
import { SchemaPropertyItem } from '../../../../models/json_schemas';
import { TAttestationExecution } from '../../../../models/attestation';
import { LoadingContainer } from '../../../../components/LoadingContainer';

interface InventoryModelDependenciesItemProps {
  dependency: TInventoryModelDependency;
}

function InventoryModelDependenciesItem({
  dependency,
}: InventoryModelDependenciesItemProps) {
  const { data: inventoryModel, isLoading } = useQuery(
    ['model-inventory', dependency.depends_on.cuid],
    async () => {
      return API.GetInventoryModel(dependency.depends_on.cuid);
    },
    {
      onError: err => {
        // track errors
      },
    },
  );

  if (inventoryModel) {
    return <ModelInventoryItem inventoryModel={inventoryModel} layout="row" />;
  }

  return null;
}

function InventoryModelDependencies() {
  const { inventoryModel } = useContext(InventoryModelContext);
  const [initialNodes, setInitialNodes] = useState<Node[]>([]);
  const [initialEdges, setInitialEdges] = useState<Edge[]>([]);

  useEffect(() => {
    const deps = inventoryModel?.dependencies || [];

    const _initialNodes: Node[] = [];
    const _initialEdges: Edge[] = [];
    _initialNodes.push({
      id: inventoryModel?.cuid!,
      position: { x: 0, y: 0 },
      data: { label: `${inventoryModel?.name!}` },
      className: 'current-model-in-dependency',
      style: {
        fontFamily: 'inherit',
        backgroundColor: 'var(--chakra-colors-neutral-100)',
        color: 'var(--chakra-colors-neutral-700)',
        borderColor: 'var(--chakra-colors-brand-base)',
        borderRadius: 4,
        borderWidth: 1,
      },
    });

    // inserts upstream dependencies, connecting to this model
    deps
      .filter(d => d.category === 'upstream')
      .map(d => {
        _initialNodes.push({
          id: d.depends_on.cuid,
          position: { x: 0, y: 0 },
          data: { label: d.depends_on.name },
          style: {
            backgroundColor: 'var(--chakra-colors-sky-200)',
            color: 'var(--chakra-colors-sky-800)',
            borderColor: 'var(--chakra-colors-sky-500)',
            fontWeight: 'bold',
            borderRadius: 8,
          },
        });
        _initialEdges.push({
          source: d.depends_on.cuid,
          target: inventoryModel?.cuid!,
          id: d.cuid,
          label: 'Upstream',
          style: {
            stroke: 'var(--chakra-colors-sky-500)',
            strokeDasharray: '5, 5',
            strokeWidth: 2,
          },
          markerEnd: {
            type: MarkerType.Arrow,
            color: 'var(--chakra-colors-sky-500)',
          },
        });
      });

    // inserts downstream dependencies, connecting from this model
    deps
      .filter(d => d.category === 'downstream')
      .map(d => {
        _initialNodes.push({
          id: d.depends_on.cuid,
          position: { x: 0, y: 0 },
          data: { label: d.depends_on.name },
          style: {
            backgroundColor: 'var(--chakra-colors-purple-200)',
            color: 'var(--chakra-colors-purple-800)',
            borderColor: 'var(--chakra-colors-purple-500)',
            borderRadius: 4,
            borderWidth: 1,
          },
        });
        _initialEdges.push({
          source: inventoryModel?.cuid!,
          target: d.depends_on.cuid,
          id: d.cuid,
          label: 'Downstream',
          style: {
            stroke: 'var(--chakra-colors-purple-500)',
            strokeDasharray: '5, 5',
            strokeWidth: 2,
          },
          markerEnd: {
            type: MarkerType.Arrow,
            color: 'var(--chakra-colors-purple-500)',
          },
        });
      });

    setInitialNodes(_initialNodes);
    setInitialEdges(_initialEdges);
  }, [inventoryModel?.dependencies]);

  // inserts this model, the center of the dependency graph
  // const within_deps_label = deps
  //   .filter(d => d.category === 'within')
  //   .map(d => d.depends_on.name)
  //   .join(',');

  return (
    <Stack>
      {initialNodes.length > 0 && (
        <Box
          width={'full'}
          height={300}
          border={'1px solid'}
          borderColor={'neutral.200'}
          borderRadius={8}
          bg={'white'}
          mt={4}
        >
          <DependenciesFlow
            key={inventoryModel?.cuid}
            initialNodes={initialNodes}
            initialEdges={initialEdges}
          />
        </Box>
      )}

      <Stack>
        {inventoryModel?.dependencies.map(dep => (
          <InventoryModelDependenciesItem key={dep.cuid} dependency={dep} />
        ))}
      </Stack>
    </Stack>
  );
}

interface InventoryModelOverviewProps {}

export interface DeleteInventoryModelUserVariables {
  inventoryModel: TInventoryModel;
  inventoryModelUser: TInventoryModelUser;
}

export interface CreateInventoryModelUserVariables {
  inventoryModel: TInventoryModel;
  inventoryModelUserPost: TInventoryModelUserPost;
}

interface IInventoryModelUser {
  email: string;
  name: string;
  picture: string;
  role?: TRole;
}

export const StakeholderItem = ({ user }: { user?: IInventoryModelUser }) => {
  return (
    <HStack>
      {user ? (
        <AvatarProxy name={user.name} src={user.picture} size={'sm'} />
      ) : (
        <Icon as={ExclamationCircleIcon} boxSize={5} color={'neutral.500'} />
      )}

      <VStack align={'start'} spacing={0}>
        <Text>
          {user ? (
            <Text>{user.name}</Text>
          ) : (
            <Text color={'neutral.500'}>Unassigned</Text>
          )}
        </Text>
        <Text fontSize="sm" margin={'0px'}>
          {user?.role?.name}
        </Text>
      </VStack>
    </HStack>
  );
};

type InventoryModelDetailsProps = {
  inventoryModel: TInventoryModel;
  schema: SchemaPropertyItem[];
  attestationExecution?: TAttestationExecution;
};

type VisibleRolesState = {
  [key: string]: boolean;
};

export const InventoryModelDetails: React.FC<InventoryModelDetailsProps> = ({
  inventoryModel,
  schema,
  attestationExecution,
}) => {
  const { workflowsV3 } = useFlags();
  const { currentUser, currentOrganization } = useContext(UsersContext);
  const { userHasInventoryModelPermission } = useContext(InventoryModelContext);
  const {
    isLoading: pageLayoutResponseLoading,
    getPageLayout,
    refetch: refetchLayout,
  } = useModelPageLayout();
  const queryClient = useQueryClient();
  const { groups } = useGroups();

  const isAdmin =
    !attestationExecution &&
    currentUser?.roles.some(role => role.role.is_admin);

  const [group, setGroup] = useState<TGroup | undefined>(inventoryModel?.group);
  const [additionalVisibleRoles, setAdditionalVisibleRoles] =
    useState<VisibleRolesState>({});

  useEffect(() => {
    setGroup(inventoryModel?.group);
  }, [inventoryModel?.group.cuid]);

  const invalidateInventoryModelQueries = useCallback(
    (inventoryModel: TInventoryModel) => {
      queryClient.invalidateQueries(['inventory-model', inventoryModel.cuid]);
      queryClient.invalidateQueries([ActivityFeedWidgetQueryKey]);
    },
    [queryClient],
  );

  const deleteInventoryModelUser = useMutation(
    async (variables: DeleteInventoryModelUserVariables) => {
      const { inventoryModel, inventoryModelUser } = variables;
      return API.DeleteInventoryModelUser(inventoryModel, inventoryModelUser);
    },
    {
      onSuccess: (data, { inventoryModel }) => {
        invalidateInventoryModelQueries(inventoryModel);
      },
    },
  );

  // useMutation to use API.CreateInventoryModelUser
  const createInventoryModelUser = useMutation(
    async (variables: CreateInventoryModelUserVariables) => {
      const { inventoryModel, inventoryModelUserPost } = variables;
      return API.PostInventoryModelUser(inventoryModel, inventoryModelUserPost);
    },
    {
      onSuccess: (data, { inventoryModel }) => {
        invalidateInventoryModelQueries(inventoryModel);
      },
    },
  );

  const roleNameMapping: Record<string, string> = {
    'Model Developer': 'Developers',
    'Model Validator': 'Validators',
    'Model Owner': 'Owners',
    Owners: 'Model Owner',
    Validators: 'Model Validator',
    Developers: 'Model Developer',
  };

  const updateUsers = (
    userCUIDs: string[],
    existingUsers: TInventoryModelUser[],
    label: string,
  ): Promise<any[]> => {
    const existingUsersCUIDs = existingUsers.map(u => u.user.cuid);
    const newUsers = _.difference(
      userCUIDs,
      existingUsers.map(u => u.user.cuid),
    );
    const removedUsers = _.difference(existingUsersCUIDs, userCUIDs);

    const promises: Promise<any>[] = [];

    if (inventoryModel) {
      newUsers.forEach(cuid => {
        promises.push(
          createInventoryModelUser.mutateAsync({
            inventoryModel,
            inventoryModelUserPost: {
              user: { cuid: cuid },
              name: roleNameMapping[label] || label,
            },
          }),
        );
      });

      removedUsers.forEach(cuid => {
        const inventoryModelUser = existingUsers.find(
          u => cuid === u.user.cuid,
        );
        if (inventoryModelUser) {
          promises.push(
            deleteInventoryModelUser.mutateAsync({
              inventoryModel,
              inventoryModelUser,
            }),
          );
        }
      });
    }
    return Promise.all(promises);
  };

  const eventFilters: EventFilters = {
    inventory_models: [inventoryModel?.cuid!],
  };

  const roleUsers = (roleName: string) => {
    if (inventoryModel) {
      return inventoryModel.users.filter(u => u.role.name === roleName);
    }
    return [];
  };

  const isReadOnlyField = (key: string) => {
    const canUpdateField =
      isAdmin || userHasInventoryModelPermission(['update_cf_' + key], 'any');
    return !canUpdateField;
  };

  const readOnlyFields =
    !!attestationExecution ||
    !canUpdateInventoryModel(
      currentUser!,
      inventoryModel!.users.filter(u =>
        ['Model Owner', 'Model Validator'].includes(u.name),
      ),
    ) ||
    inventoryModel?.stage !== InventoryModelStages.ACTIVE;

  const roleNameOrder: Record<string, number> = {
    'Model Owner': 1,
    'Model Developer': 2,
    'Model Validator': 3,
  };

  const LOCKED_MODEL_ROLES = [
    'Model Owner',
    'Model Developer',
    'Model Validator',
  ];

  // Determine which roles have users and should be shown by default
  const rolesWithUsers =
    currentOrganization?.roles
      .filter(r => r.scope === 'Model' && !LOCKED_MODEL_ROLES.includes(r.name))
      .filter(r => roleUsers(r.name).length > 0)
      .map(r => r.name) || [];

  const visibleRoles = [
    ...LOCKED_MODEL_ROLES,
    ...rolesWithUsers,
    ...Object.entries(additionalVisibleRoles)
      .filter(
        ([role, isVisible]) => isVisible && !rolesWithUsers.includes(role),
      )
      .map(([role]) => role),
  ];

  const roleControls = currentOrganization?.roles
    .filter(r => r.scope === 'Model' && visibleRoles.includes(r.name))
    .map(r => ({
      label: roleNameMapping[r.name] || r.name,
      sequence: roleNameOrder[r.name] || 99, // Default high sequence for additional roles
      existingUsers: roleUsers(r.name),
    }))
    .sort((a, b) => a.sequence - b.sequence);

  const getVendorNames = useCallback<() => Promise<string[]>>(async () => {
    const res = await API.GetModelInventory(
      ['vendor_name'],
      undefined,
      undefined,
      1,
      10000,
    );
    return Promise.resolve(
      _.chain(res.results)
        .filter(model => !!model.is_vendor_model)
        .map(model => model.vendor_name!)
        .compact()
        .uniq()
        .sort()
        .value(),
    );
  }, []);

  const editModalLayout = useDisclosure();

  const pageLayout = getPageLayout(schema);

  const visibleMainColumnCustomFields =
    pageLayout?.layout.mainColumnItems.filter(item => !item.isOmitted) || [];

  const visibleSideColumnCustomFields =
    pageLayout?.layout.sideColumnItems.filter(item => !item.isOmitted) || [];

  const customFieldsLoading = pageLayoutResponseLoading;

  const ManageVisibleRolesButton = () => {
    const availableRoles =
      currentOrganization?.roles
        .filter(
          r =>
            r.scope === 'Model' &&
            !LOCKED_MODEL_ROLES.includes(r.name) &&
            !rolesWithUsers.includes(r.name),
        )
        .map(r => r.name) || [];

    if (availableRoles.length === 0) return null;

    return (
      <Popover
        placement="bottom-start"
        closeOnBlur={true}
        closeOnEsc={true}
        autoFocus={true}
      >
        <PopoverTrigger>
          <Button
            leftIcon={<Icon as={UserPlusIcon} boxSize={6} />}
            variant="ghost"
          >
            Manage Model Stakeholders
          </Button>
        </PopoverTrigger>
        <PopoverContent
          w={'96'}
          maxW={'90rem'}
          bgColor={useColorModeValue('neutral.50', 'neutral.900')}
          boxShadow={'xl'}
          maxHeight={'80vh'}
        >
          <PopoverArrow />
          <PopoverCloseButton size={'md'} />
          <PopoverHeader>
            <HStack>
              <Heading as="h5">Model Stakeholder Types</Heading>
              <MoreInfoPopOver
                title="Model Stakeholder Types"
                description="Select the stakeholder types that should be visible in the model inventory."
                link={`${CONFIG.VALIDMIND_DOCS_URL}/guide/model-inventory/customize-model-inventory-layout.html`}
              />
            </HStack>
          </PopoverHeader>
          <PopoverBody mb={2}>
            <VStack align="start" gap={2}>
              {availableRoles.map(role => (
                <HStack
                  key={role}
                  onClick={e => {
                    e.stopPropagation();
                  }}
                >
                  <Switch
                    isChecked={additionalVisibleRoles[role]}
                    onChange={e => {
                      setAdditionalVisibleRoles(prev => ({
                        ...prev,
                        [role]: e.target.checked,
                      }));
                      e.stopPropagation();
                    }}
                    colorScheme="brand"
                    size="md"
                    onClick={e => {
                      e.stopPropagation();
                    }}
                    id={role.replace(' ', '_')}
                  />
                  <FormLabel
                    htmlFor={role.replace(' ', '_')}
                    m={'unset'}
                    fontSize={'sm'}
                  >
                    {role}
                  </FormLabel>
                </HStack>
              ))}
            </VStack>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    );
  };

  return (
    <>
      <EditModelLayoutModal
        existingLayout={pageLayout}
        isOpen={editModalLayout.isOpen}
        onClose={editModalLayout.onClose}
        onSave={async newLayout => {
          if (!newLayout.cuid) {
            // Create new layout
            await API.CreateModelPageLayout({
              layout: {
                mainColumn: newLayout.layout.mainColumnItems,
                sideColumn: newLayout.layout.sideColumnItems,
              },
            });
          } else {
            // Update existing layout
            await API.UpdateModelPageLayout({
              layout: {
                mainColumn: newLayout.layout.mainColumnItems,
                sideColumn: newLayout.layout.sideColumnItems,
              },
            });
          }

          // refetch layout
          refetchLayout();
        }}
      />
      <RecordDetailsPage
        right={
          <AttributesRail>
            {!attestationExecution && (
              <VStack alignItems={'flex-start'} gap={1}>
                <Icon
                  as={InfoIcon}
                  color={useColorModeValue('neutral.500', 'neutral.200')}
                  mt={1}
                />
                <Text>
                  Need help editing this page?
                  <br />
                  <Link
                    isExternal
                    href={`${CONFIG.VALIDMIND_DOCS_URL}/guide/model-inventory/edit-model-inventory-fields.html`}
                    target="_blank"
                  >
                    Open documentation
                    <Icon ml={1} boxSize={3} as={ArrowTopRightOnSquareIcon} />
                  </Link>
                </Text>
              </VStack>
            )}
            <VStack align="start" spacing={0} w={'full'}>
              {inventoryModel && (
                <CoreField
                  inventoryModel={inventoryModel}
                  propertyKey={'model_id'}
                  readOnly={readOnlyFields}
                />
              )}
            </VStack>
            <VStack align="start" spacing={0} w={'full'}>
              {inventoryModel && (
                <CoreField
                  inventoryModel={inventoryModel}
                  propertyKey={'tiering'}
                  readOnly={readOnlyFields}
                />
              )}
            </VStack>

            {inventoryModel?.version && (
              <VStack align="start" spacing={0}>
                <Label>Version</Label>
                <Text as={'pre'}>{inventoryModel?.version}</Text>
              </VStack>
            )}

            {inventoryModel && (
              <>
                <FormControl role="group">
                  <VStack alignItems={'flex-start'}>
                    <HStack gap={0} w="full">
                      <ModelStageField
                        inventoryModel={inventoryModel}
                        onSave={() =>
                          invalidateInventoryModelQueries(inventoryModel)
                        }
                        readOnly={!isAdmin}
                      />
                    </HStack>
                  </VStack>
                </FormControl>

                <>
                  {/* --- Pending Deprecation Warning, replace for next stack below --- */}
                  {!attestationExecution && !workflowsV3 && (
                    // # TODO: remove me and my children when v3 is released
                    <FormControl role="group">
                      <VStack alignItems={'flex-start'}>
                        <WorkflowExecutionsViewer
                          triggerId={'InventoryModel.created'}
                          targetCuid={inventoryModel.cuid}
                        />
                      </VStack>
                    </FormControl>
                  )}

                  {!attestationExecution && !workflowsV3 && (
                    // # TODO: remove me and my children when v3 is released
                    <WorkflowStatusActions
                      triggerId={'InventoryModel.created'}
                      entityCuid={inventoryModel.cuid}
                      fromStatus={inventoryModel.status}
                      defaultCustomFieldValues={
                        inventoryModel.custom_fields as FieldValues
                      }
                      onSave={() => {
                        invalidateInventoryModelQueries(inventoryModel);
                        queryClient.invalidateQueries(['approvals', 'voters']);
                      }}
                    />
                  )}
                  {/* --- End Pending Deprecation Warning --- */}
                </>

                {!attestationExecution && workflowsV3 && (
                  <Stack spacing={4}>
                    <WorkflowExecutions
                      target={inventoryModel}
                      entityName={EntityName.INVENTORY_MODEL}
                      customFields={inventoryModel.custom_fields as FieldValues}
                    />

                    <WorkflowManagerModal
                      variant={'show-all-workflows-btn'}
                      target={inventoryModel}
                      navigation={WorkflowManagerNavigationState.LIST}
                      initialListingTab={'available'}
                    />
                  </Stack>
                )}
              </>
            )}

            {inventoryModel && (
              <VStack align="start" spacing={0} w={'full'}>
                <EditableField
                  readOnly={readOnlyFields}
                  onSave={async onFinished => {
                    await API.PatchModelInventoryGroup(
                      inventoryModel.cuid,
                      group!.cuid,
                    );

                    onFinished(true);
                  }}
                  onCancel={() => {
                    setGroup(inventoryModel.group);
                  }}
                  title={'Group'}
                  renderField={(mode: 'edit' | 'read-only') => {
                    if (mode === 'edit') {
                      return (
                        <Select
                          bg={useColorModeValue('white', 'neutral.850')}
                          focusBorderColor="brand.base"
                          value={group?.cuid}
                          onChange={e => {
                            setGroup(
                              groups.find(g => g.cuid === e.target.value),
                            );
                          }}
                        >
                          {groups.map(group => (
                            <option key={group.cuid} value={group.cuid}>
                              {group.name}
                            </option>
                          ))}
                        </Select>
                      );
                    }
                    return <Text>{group?.name}</Text>;
                  }}
                />
              </VStack>
            )}

            <VStack align="start" spacing={0} w={'full'}>
              {inventoryModel && (
                <CoreField
                  inventoryModel={inventoryModel}
                  propertyKey={'business_unit'}
                  readOnly={readOnlyFields}
                />
              )}
            </VStack>

            <VStack align="start" spacing={0} w={'full'}>
              {inventoryModel && (
                <CoreField
                  inventoryModel={inventoryModel}
                  propertyKey={'use_case'}
                  readOnly={readOnlyFields}
                />
              )}
            </VStack>

            <VStack align="start" spacing={0} w={'full'}>
              {inventoryModel && (
                <CoreField
                  isCoreField={true}
                  inventoryModel={inventoryModel}
                  propertyKey={'is_vendor_model'}
                  readOnly={readOnlyFields}
                />
              )}
            </VStack>
            <VStack align="start" spacing={0} w={'full'}>
              {inventoryModel && inventoryModel.is_vendor_model && (
                <ManagedField
                  jsonSchema={{
                    label: 'Vendor Name',
                    type: 'auto-complete',
                    props: {
                      suggestions: getVendorNames,
                    },
                    key: 'vendor_name',
                    returnType: 'array',
                    isRequired: false,
                  }}
                  readOnly={readOnlyFields}
                  initialValue={inventoryModel.vendor_name}
                  onEdit={async (text, onSuccess) => {
                    await API.PostModelInventoryCustomField(
                      inventoryModel,
                      'vendor_name',
                      {
                        vendor_name: text,
                      },
                    );
                    onSuccess();
                  }}
                />
              )}
            </VStack>

            {!customFieldsLoading &&
              inventoryModel &&
              visibleSideColumnCustomFields.length > 0 && (
                <Stack w={'full'}>
                  <VStack gap={12} alignItems={'flex-start'} w={'full'}>
                    {visibleSideColumnCustomFields.map(item => (
                      <ManagedCustomField
                        key={item.propertyKey}
                        inventoryModel={inventoryModel}
                        propertyKey={item.propertyKey}
                        readOnly={isReadOnlyField(item.propertyKey)} // permissions for custom fields
                      />
                    ))}
                  </VStack>
                </Stack>
              )}
            {!readOnlyFields && (
              <Button
                leftIcon={<Icon as={Squares2X2Icon} boxSize={6} />}
                onClick={editModalLayout.onOpen}
                size={'sm'}
                data-testid="customize-pdp-layout-trigger"
              >
                Customize Layout
              </Button>
            )}
          </AttributesRail>
        }
      >
        {!attestationExecution && (
          <VStack w={'full'}>
            <HStack w={'full'}>
              <MasterSearchBar />
            </HStack>
            <MasterHeader>
              <MasterStatusBar
                item={inventoryModel}
                isMain={true}
                url="/model-inventory"
                readOnly={readOnlyFields}
              />
            </MasterHeader>
          </VStack>
        )}
        {inventoryModel && inventoryModel.cuid && (
          <>
            {/*
              voterStatus is null here because we still want to
              show the approval results even if the user has already voted.
            */}
            <WorkflowApprovalActions
              targetType={'InventoryModel'}
              targetCuid={inventoryModel.cuid}
              voterStatus={null}
            />
          </>
        )}
        <VStack
          divider={
            <StackDivider
              borderColor={'var(--chakra-colors-chakra-border-color)'}
            />
          }
          spacing={12}
          align="stretch"
        >
          <VStack align="stretch" spacing={6}>
            {/* Show More Roles button in its own row at the top */}
            <Box alignSelf="flex-end">
              <ManageVisibleRolesButton />
            </Box>

            {/* Locked roles grid */}
            <SimpleGrid columns={{ md: 1, lg: 2, xl: 3 }} gap={8}>
              {inventoryModel && currentUser && (
                <>
                  {roleControls
                    ?.filter(rc =>
                      LOCKED_MODEL_ROLES.includes(
                        roleNameMapping[rc.label] || rc.label,
                      ),
                    )
                    .map(roleControl => (
                      <ManagedField
                        key={roleControl.label}
                        jsonSchema={{
                          label: roleControl.label,
                          type: 'model-user',
                          props: {
                            many: true,
                          },
                          key: roleControl.label,
                          returnType: 'array',
                          isRequired: false,
                        }}
                        readOnly={readOnlyFields}
                        initialValue={roleControl.existingUsers.map(
                          u => u.user.cuid,
                        )}
                        onEdit={async (newUserCUIDs, onSuccess) => {
                          await updateUsers(
                            newUserCUIDs,
                            roleControl.existingUsers,
                            roleControl.label,
                          );
                          onSuccess();
                        }}
                        overrideDisplay={mode => {
                          if (
                            mode !== 'edit' &&
                            roleControl.existingUsers.length === 0
                          ) {
                            if (attestationExecution) {
                              return null;
                            }
                            return (
                              <EmptyStateDisplay variant="no-user" size="sm">
                                <Heading as={'h5'}>
                                  No {roleControl.label.toLowerCase()} have been
                                  set yet.
                                </Heading>
                                <Text align={'center'}>Click to add one.</Text>
                              </EmptyStateDisplay>
                            );
                          }
                          return null;
                        }}
                      />
                    ))}
                </>
              )}

              {/* Additional roles grid if any are selected */}
              {roleControls?.some(
                rc =>
                  !LOCKED_MODEL_ROLES.includes(
                    roleNameMapping[rc.label] || rc.label,
                  ),
              ) &&
                roleControls
                  ?.filter(
                    rc =>
                      !LOCKED_MODEL_ROLES.includes(
                        roleNameMapping[rc.label] || rc.label,
                      ),
                  )
                  .map(roleControl => (
                    <ManagedField
                      key={roleControl.label}
                      jsonSchema={{
                        label: roleControl.label,
                        type: 'model-user',
                        props: {
                          many: true,
                        },
                        key: roleControl.label,
                        returnType: 'array',
                        isRequired: false,
                      }}
                      readOnly={readOnlyFields}
                      initialValue={roleControl.existingUsers.map(
                        u => u.user.cuid,
                      )}
                      onEdit={async (newUserCUIDs, onSuccess) => {
                        await updateUsers(
                          newUserCUIDs,
                          roleControl.existingUsers,
                          roleControl.label,
                        );
                        onSuccess();
                      }}
                      overrideDisplay={mode => {
                        if (
                          mode !== 'edit' &&
                          roleControl.existingUsers.length === 0
                        ) {
                          if (attestationExecution) {
                            return null;
                          }
                          return (
                            <EmptyStateDisplay variant="no-user" size="sm">
                              <Heading as={'h5'}>
                                No {roleControl.label.toLowerCase()} have been
                                set yet.
                              </Heading>
                              <Text align={'center'}>Click to add one.</Text>
                            </EmptyStateDisplay>
                          );
                        }
                        return null;
                      }}
                    />
                  ))}
            </SimpleGrid>
          </VStack>

          {!attestationExecution && workflowsV3 ? (
            <Stack>
              <WorkflowsExecutionsTimelineWidget target={inventoryModel} />
            </Stack>
          ) : null}

          {!attestationExecution && (
            <>
              <HStack>
                <Heading as={'h3'}>Model Interdependencies</Heading>
                <MoreInfoPopOver
                  title="Model Interdependencies"
                  description="Link two or more models in your inventory together."
                  link={`${CONFIG.VALIDMIND_DOCS_URL}/guide/model-inventory/configure-model-interdependencies.html`}
                />
                {inventoryModel &&
                  inventoryModel.stage === InventoryModelStages.ACTIVE &&
                  (inventoryModel?.dependencies || []).length > 0 && (
                    <>
                      <Spacer />
                      <DependencyManagerModal
                        inventoryModel={inventoryModel}
                        buttonVariant="ghost"
                      />
                    </>
                  )}
              </HStack>
              {(inventoryModel?.dependencies || []).length > 0 ? (
                <InventoryModelDependencies />
              ) : (
                <EmptyStateDisplay variant="no-workflow">
                  <Heading as={'h5'}>
                    No dependencies have been set yet.
                  </Heading>
                  <Text pb={4}>
                    Dependencies are useful to understand how this model is
                    <br />
                    affected by or affects other models in your inventory.
                  </Text>
                  {inventoryModel &&
                    inventoryModel.stage === InventoryModelStages.ACTIVE && (
                      <DependencyManagerModal
                        inventoryModel={inventoryModel}
                        buttonVariant="ghost"
                      />
                    )}
                </EmptyStateDisplay>
              )}
            </>
          )}

          <Stack gap={10}>
            {inventoryModel?.purpose && (
              <Box>
                <CoreField
                  inventoryModel={inventoryModel}
                  propertyKey={'purpose'}
                  readOnly={readOnlyFields}
                />
              </Box>
            )}

            {!customFieldsLoading &&
              inventoryModel &&
              visibleMainColumnCustomFields && (
                <>
                  {visibleMainColumnCustomFields.map(item => {
                    if (item.typeId === 'array:attachments') {
                      return (
                        <ManageAttachmentsField
                          key={item.propertyKey}
                          entityType="inventory_model"
                          entityCuid={inventoryModel?.cuid}
                          entityFieldId={item.propertyKey}
                          canUpload={!readOnlyFields}
                          canDelete={!readOnlyFields}
                          title={item.label}
                          getEntityAttachmentsFn={
                            !!attestationExecution
                              ? async (
                                  entityType,
                                  entityCuid,
                                  entityFieldId,
                                ) => {
                                  const response =
                                    await API.GetAttestationExecutionSnapshotEntityAttachments(
                                      attestationExecution.cuid,
                                      entityType,
                                      entityCuid,
                                      entityFieldId,
                                    );

                                  return response.entity_attachments;
                                }
                              : undefined
                          }
                        />
                      );
                    }

                    return (
                      <Box key={item.propertyKey}>
                        <ManagedCustomField
                          inventoryModel={inventoryModel}
                          propertyKey={item.propertyKey}
                          readOnly={isReadOnlyField(item.propertyKey)}
                        />
                      </Box>
                    );
                  })}
                </>
              )}
          </Stack>

          {!attestationExecution && (
            <Stack>
              <FindingsWidget
                inventoryModel={inventoryModel}
                variant={'model-inventory-summary'}
                allowAddFinding={
                  inventoryModel?.stage === InventoryModelStages.ACTIVE
                }
              />
            </Stack>
          )}

          <Stack>
            <ActivityFeedWidget
              variant={
                !!attestationExecution ? 'snapshot' : 'model-inventory-summary'
              }
              filters={eventFilters}
              seeAllUrl={
                !attestationExecution
                  ? `/model-inventory/${inventoryModel?.cuid}/activity/`
                  : undefined
              }
              toDateISO={attestationExecution?.created_at}
            />
          </Stack>
        </VStack>
        {!attestationExecution && <Copyright />}
      </RecordDetailsPage>
    </>
  );
};

export default function InventoryModelOverview({}: InventoryModelOverviewProps) {
  const { inventoryModel } = useContext(InventoryModelContext);
  const { propertyItems } = useModelSchema();

  if (!inventoryModel) {
    return <LoadingContainer isLoading={true}>Loading</LoadingContainer>;
  }

  return (
    <InventoryModelDetails
      inventoryModel={inventoryModel}
      schema={propertyItems}
    />
  );
}
