import dayjs from 'dayjs';
import { ValueEditor, ValueEditorProps } from 'react-querybuilder';
import { displayFormattedDateAndTime } from '../../../utils';
import DatePicker from 'react-datepicker';
import { Input } from '@chakra-ui/react';

export const CustomValueEditor = (props: ValueEditorProps) => {
  if (props.fieldData.datatype === 'date') {
    const currentDate = dayjs();
    const selectedDate = props.value ? dayjs(Number(props.value)) : currentDate;

    return (
      <DatePicker
        value={displayFormattedDateAndTime(selectedDate.unix())}
        selected={selectedDate.toDate()}
        onChange={(d: Date) => {
          const newDate = d ? dayjs(d) : currentDate;
          props.handleOnChange(newDate.valueOf().toString());
        }}
        customInput={<Input w="235px" />}
      />
    );
  }
  return <ValueEditor {...props} />;
};
