import {
  Box,
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Stack,
  VStack,
} from '@chakra-ui/react';
import { Select } from 'chakra-react-select';
import React, { useEffect } from 'react';
import { useQuery } from 'react-query';
import API from '../../../api/API';
import { TDashboard, TDashboardVisualization } from '../../../models/report';
import RenderVisualization from '../../Reporting/Visualizations/RenderVisualization';
import { Label } from '../../Layout';
import { GridViewItem } from '../../GridView/GridViewItem';

const FieldSet = ({
  label,
  children,
}: {
  label: string;
  children: React.ReactNode;
}) => (
  <Stack
    w="full"
    p={2}
    border={1}
    borderRadius={'md'}
    borderStyle={'solid'}
    alignItems={'normal'}
    borderColor={'var(--chakra-colors-chakra-border-color)'}
    gap={2}
  >
    <Label>{label}</Label>
    {children}
  </Stack>
);

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (visualization: TDashboardVisualization) => Promise<void>;
}

const AddVisualizationFromAnalytics: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  onSubmit,
}) => {
  const [selectedDashboard, setSelectedDashboard] =
    React.useState<TDashboard>();
  const [selectedVisualization, setSelectedVisualization] =
    React.useState<TDashboardVisualization>();
  const [isRenderReady, setIsRenderReady] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const { isLoading: isLoadingDatasets, data: dashboards } = useQuery(
    ['reporting', 'report', 'dashboards'],
    async () => {
      return await API.GetDashboards('report');
    },
    {
      initialData: [],
    },
  );

  useEffect(() => {
    if (isOpen && selectedDashboard && selectedVisualization) {
      setTimeout(() => {
        setIsRenderReady(true);
      }, 500);
    } else {
      setIsRenderReady(false);
    }
  }, [isOpen, selectedDashboard, selectedVisualization]);

  const dashboardOptions =
    dashboards?.map(d => ({
      label: d.name,
      value: d.cuid,
    })) || [];

  const visualizationOptions =
    selectedDashboard?.dashboard_items
      .filter(di => di.item_type === 'DashboardVisualization')
      .map(v => {
        const visualization = v.item as TDashboardVisualization;
        return {
          label: visualization.json.title,
          value: visualization.cuid,
        };
      }) || [];

  if (!isOpen) {
    return null;
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      closeOnEsc
      closeOnOverlayClick
      scrollBehavior={'inside'}
      size={'6xl'}
    >
      <ModalOverlay />
      <ModalContent
        h={selectedDashboard && selectedVisualization ? '95vh' : 'auto'}
        w={selectedDashboard && selectedVisualization ? '100%' : '33%'}
        transition={'all .3s ease-in-out'}
        maxW="95%"
        maxH="95vh"
      >
        <ModalHeader>Add Analytics Visualization</ModalHeader>
        <ModalCloseButton />
        <ModalBody display="flex" flex={1} overflow={'visible'}>
          <HStack spacing={4} flex={1}>
            <VStack
              minWidth={
                selectedDashboard && selectedVisualization ? '33%' : '100%'
              }
              h="100%"
              align="flex-start"
            >
              <FieldSet label="Analytics Page">
                <Select
                  size={'md'}
                  classNamePrefix="chakra-react-select"
                  isSearchable={false}
                  onChange={e => {
                    const foundDashboard = dashboards?.find(
                      d => d.cuid === e?.value,
                    );
                    const firstVisualization = foundDashboard?.dashboard_items
                      .filter(di => di.item_type === 'DashboardVisualization')
                      .at(0);
                    setSelectedDashboard(foundDashboard);
                    setSelectedVisualization(
                      firstVisualization?.item as
                        | TDashboardVisualization
                        | undefined,
                    );
                  }}
                  options={dashboardOptions}
                  value={dashboardOptions?.find(
                    d => d.value === selectedDashboard?.cuid,
                  )}
                />
              </FieldSet>
              {selectedDashboard && (
                <FieldSet label="Visualization">
                  <Select
                    size={'md'}
                    classNamePrefix="chakra-react-select"
                    isSearchable={false}
                    onChange={e => {
                      const foundVisualization =
                        selectedDashboard.dashboard_items
                          .filter(
                            di => di.item_type === 'DashboardVisualization',
                          )
                          .find(v => v.item.cuid === e?.value);
                      setSelectedVisualization(
                        foundVisualization?.item as
                          | TDashboardVisualization
                          | undefined,
                      );
                    }}
                    options={visualizationOptions}
                    value={visualizationOptions?.find(
                      v => v.value === selectedVisualization?.cuid,
                    )}
                  />
                </FieldSet>
              )}
            </VStack>
            {selectedDashboard && selectedVisualization && (
              <VStack h="full" flexGrow={1}>
                <Box
                  w="100%"
                  h="100%"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  {isRenderReady && (
                    <GridViewItem
                      style={{
                        width: '90%',
                        height: '75%',
                      }}
                      title={selectedVisualization.json.title}
                    >
                      <RenderVisualization
                        isEditing
                        visualizationJSON={selectedVisualization.json}
                      />
                    </GridViewItem>
                  )}
                </Box>
              </VStack>
            )}
          </HStack>
        </ModalBody>
        <ModalFooter>
          <Button variant="ghost" onClick={onClose}>
            Cancel
          </Button>
          <Spacer />
          <Button
            isLoading={isSubmitting}
            variant={'primary'}
            onClick={async () => {
              if (selectedDashboard && selectedVisualization) {
                setIsSubmitting(true);
                onSubmit({
                  ...selectedVisualization,
                });
                setIsSubmitting(false);
              }
            }}
            isDisabled={
              isLoadingDatasets || !selectedDashboard || !selectedVisualization
            }
          >
            Add Visualization
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddVisualizationFromAnalytics;
