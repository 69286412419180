import { useQuery } from 'react-query';
import API from '../../api/API';

interface LogoData {
  lightFull: string | null;
  darkFull: string | null;
  lightMark: string | null;
  darkMark: string | null;
}

export function useCustomLogo() {
  const queryResponse = useQuery<LogoData>(
    ['settings', 'logo'],
    async () => {
      const settings = await API.GetCustomLogo();
      return {
        lightFull: settings.custom_logo_light_full || null,
        darkFull: settings.custom_logo_dark_full || null,
        lightMark: settings.custom_logo_light_mark || null,
        darkMark: settings.custom_logo_dark_mark || null,
      };
    },
    {
      staleTime: 0, // Always fetch fresh data
      cacheTime: 0, // Don't cache the data
    }
  );

  return {
    logos: queryResponse.data || {
      lightFull: null,
      darkFull: null,
      lightMark: null,
      darkMark: null,
    },
    ...queryResponse,
  };
}

export default useCustomLogo;
