import {
  Button,
  CircularProgress,
  Flex,
  FormControl,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Square,
  Stack,
  Text,
  Textarea,
  useToast,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import API from '../../api/API';
import { Label } from '../Layout';
import { colors } from '../AddStatusModal';
import { Workflow } from '../../models/workflow';
import { State } from '../../models/workflow_state';

interface AddWorkflowStateModalProps {
  isOpen: boolean;
  workflow: Workflow;
  initialState?: State;
  onClose: (state?: State) => void;
}

interface PatchWorkflowMutation {
  workflowCuid: string;
  stateCuid: string;
  state: State;
}

interface PostWorkflowMutation {
  workflowCuid: string;
  state: State;
}

export default function AddWorkflowStateModal({
  isOpen,
  workflow,
  initialState,
  onClose,
}: AddWorkflowStateModalProps) {
  const [state, setState] = useState<State | undefined>();
  const toast = useToast();

  useEffect(() => {
    setState(initialState);
  }, [initialState]);

  const { data: roles, isLoading: rolesIsLoading } = useQuery(
    ['org-roles'],
    async () => {
      return await API.GetOrganizationRoles();
    },
    { initialData: () => [] },
  );

  const updateState = useMutation(
    async ({ workflowCuid, stateCuid, state }: PatchWorkflowMutation) => {
      return API.PatchWorkflowState(workflowCuid, stateCuid, state);
    },
    {
      onSuccess: data => {
        toast({
          variant: 'subtle',
          title: `State "${state?.name}" updated successfully.`,
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        closeModal(state);
      },
      onError: (error: Error) => {
        toast({
          variant: 'subtle',
          title: 'Error updating state.',
          description: API.getAPIErrorMessage(error),
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      },
    },
  );

  const createState = useMutation(
    async ({ workflowCuid, state }: PostWorkflowMutation) => {
      return API.PostWorkflowState(workflowCuid, state);
    },
    {
      onSuccess: data => {
        toast({
          variant: 'subtle',
          title: `State "${state?.name}" created successfully.`,
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        closeModal(data);
      },
      onError: (error: Error) => {
        toast({
          variant: 'subtle',
          title: 'Error updating state.',
          description: API.getAPIErrorMessage(error),
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      },
    },
  );

  const closeModal = (state?: State) => {
    onClose(state);
    setState(undefined);
  };

  const onClickSave = () => {
    if (state) {
      const sanitizedState = {
        ...state,
        description: state.description || '', // Ensure description is at least an empty string
      };

      if (state.cuid) {
        // update
        updateState.mutate({
          workflowCuid: workflow.cuid,
          stateCuid: state.cuid,
          state: sanitizedState,
        });
      } else {
        // create
        createState.mutate({
          workflowCuid: workflow.cuid,
          state: sanitizedState,
        });
      }
    }
  };

  if (rolesIsLoading) {
    return (
      <Stack justify={'center'} align={'center'}>
        <CircularProgress
          size="40px"
          thickness="2px"
          isIndeterminate
          color="brand.base"
        />
        <Text fontSize={'sm'} color={'inherit'}>
          Please hold...
        </Text>
      </Stack>
    );
  }

  return (
    <>
      <Modal isCentered isOpen={isOpen} onClose={closeModal} size="3xl">
        <ModalOverlay />
        <ModalContent>
          {state?.cuid ? (
            <ModalHeader>{state.name}</ModalHeader>
          ) : (
            <ModalHeader>Add Workflow State</ModalHeader>
          )}

          <ModalCloseButton />
          <ModalBody>
            <Stack gap={4}>
              <FormControl isRequired>
                <Label mb={2}>Name</Label>
                <Input
                  type="text"
                  value={state?.name}
                  onChange={event =>
                    setState({ ...state, name: event.target.value })
                  }
                />
              </FormControl>

              <FormControl isRequired>
                <Label mb={2}>Description</Label>
                <Textarea
                  value={state?.description || ''}
                  onChange={event =>
                    setState({ ...state, description: event.target.value })
                  }
                  rows={4}
                ></Textarea>
              </FormControl>

              <FormControl isRequired>
                <Label mb={2}>Color</Label>
                <HStack gap={2}>
                  {colors.map(c => (
                    <Square
                      key={c.primary}
                      size={10}
                      rounded={'md'}
                      border={1}
                      borderStyle="solid"
                      borderWidth={
                        (state?.colors || colors[0]).primary === c.primary
                          ? 2
                          : 1
                      }
                      borderColor={
                        (state?.colors || colors[0]).primary === c.primary
                          ? 'brand.base'
                          : c.secondary
                      }
                      bgColor={c.primary}
                      cursor={'pointer'}
                      onClick={() => setState({ ...state, colors: c })}
                      _hover={{
                        bgColor: c.secondary,
                      }}
                      transition={'all 0.3s ease-in-out'}
                    />
                  ))}
                </HStack>
              </FormControl>
            </Stack>
          </ModalBody>

          <ModalFooter>
            <Flex width="100%">
              <Button onClick={() => closeModal()} variant={'ghost'}>
                Cancel
              </Button>
              <Spacer />
              <Button
                disabled={false}
                onClick={onClickSave}
                variant={'primary'}
                isLoading={createState.isLoading || updateState.isLoading}
              >
                Save
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
