import { useState } from 'react';
import {
  As,
  Box,
  Flex,
  HStack,
  Icon,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
  Stack,
  Text,
  Tooltip,
  useColorModeValue,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import {
  CodeBracketSquareIcon,
  PresentationChartLineIcon,
} from '@heroicons/react/24/outline';
import { v4 as uuidv4 } from 'uuid';
import { TemplateSectionContents } from '../../models/template';
import { useContext, useMemo } from 'react';
import { canUpdateMetadata } from '../../auth/utils';
import {
  getModelDocumentType,
  ModelDocumentTypeEnum,
} from '../../models/model_document';
import UsersContext from '../../contexts/UsersContext';
import { Bars3CenterLeftIcon, PlusIcon } from '@heroicons/react/20/solid';
import InventoryModelContext from '../../contexts/InventoryModel';
import DocumentBlockModal, { SelectedCategory } from '../DocumentBlockModal';

interface BlockExplorerProps {
  contentIndex: number;
  addContentBlock: (
    index: number,
    content: TemplateSectionContents,
  ) => Promise<void>;
  addLibraryBlock: (
    index: number,
    content: TemplateSectionContents,
    blockLibraryCUID: string,
  ) => Promise<void>;
}

const CustomMenuItem = ({
  icon,
  title,
  description,
  onClick,
}: {
  icon: As;
  title: string;
  description: string;
  onClick: () => void;
}) => {
  return (
    <MenuItem onClick={onClick}>
      <HStack>
        <Box
          w={10}
          h={10}
          border={'1px solid'}
          borderColor={useColorModeValue('neutral.200', 'brandSecondary.850')}
          borderRadius={'md'}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <Icon as={icon} boxSize={6} />
        </Box>
        <VStack align={'flex-start'} gap={0}>
          <Text>{title}</Text>
          <Text fontSize={'sm'} opacity={0.7}>
            {description}
          </Text>
        </VStack>
      </HStack>
    </MenuItem>
  );
};

export function BlockExplorer({
  contentIndex,
  addContentBlock,
  addLibraryBlock,
}: BlockExplorerProps) {
  const [isFocused, setIsFocused] = useState(false);
  const blockSelectionModal = useDisclosure();
  const [defaultSelectedCategory, setDefaultSelectedCategory] = useState<
    SelectedCategory | undefined
  >(undefined);
  const { inventoryModel, templates } = useContext(InventoryModelContext);

  const { currentUser } = useContext(UsersContext);
  const documentType = getModelDocumentType(templates.current.documentType);
  const canAddBlocks = useMemo(
    () =>
      currentUser
        ? canUpdateMetadata(currentUser, inventoryModel?.users!, documentType)
        : false,
    [currentUser, inventoryModel?.users, documentType],
  );

  const onAddMetadataTextBlock = () => {
    const block = {
      content_type: 'text',
      content_id: uuidv4(),
    };
    addContentBlock(contentIndex, block);
  };

  const onAddTestDrivenBlock = async (
    content_type: string,
    content_id: string,
  ): Promise<void> => {
    const block = {
      content_type,
      content_id,
    };
    await addContentBlock(contentIndex++, block);
  };

  const onAddMetricOverTimeBlock = async (
    content_type: string,
    content_id: string,
  ): Promise<void> => {
    const block = {
      content_type,
      content_id,
    };
    await addContentBlock(contentIndex++, block);
  };

  const onAddLibraryBlock = async (blockLibraryCUID: string): Promise<void> => {
    const block = {
      content_type: 'text',
      content_id: uuidv4(),
    };
    await addLibraryBlock(contentIndex++, block, blockLibraryCUID);
  };

  if (!canAddBlocks) {
    return <></>;
  }

  return (
    <Flex
      height={6}
      width={'100%'}
      position={'relative'}
      alignItems={'stretch'}
      maxWidth={'1220px'}
    >
      <Box>
        <Menu
          placement={'auto'}
          offset={[0, -25]}
          onClose={() => {
            setIsFocused(false);
          }}
        >
          <Tooltip
            gutter={-25}
            rounded={'md'}
            hasArrow={false}
            boxShadow={'none'}
            border={'1px solid'}
            placement={'bottom'}
            outline={'1px solid'}
            isDisabled={isFocused}
            color={useColorModeValue(
              'brandSecondary.600',
              'brandSecondary.100',
            )}
            borderColor={useColorModeValue(
              'brandSecondary.500',
              'brandSecondary.850',
            )}
            outlineColor={useColorModeValue('white', 'black')}
            bg={useColorModeValue('white', 'brandSecondary.950')}
            label={
              <Icon
                as={PlusIcon}
                boxSize={4}
                mt={1}
                color={useColorModeValue(
                  'brandSecondary.500',
                  'brandSecondary.100',
                )}
              />
            }
          >
            <MenuButton
              width={'full'}
              height={6}
              bg={'transparent'}
              role="group"
              flexDirection={'row'}
              _hover={{ bg: 'transparent' }}
              _active={{ bg: 'transparent' }}
              position={'absolute'}
              onClick={() => {
                setIsFocused(true);
              }}
            >
              <Stack>
                <Box
                  w={'full'}
                  h={1}
                  bg={'transparent'}
                  borderTop={isFocused ? '1px solid' : '1px solid'}
                  borderColor={isFocused ? 'brand.base' : 'transparent'}
                  transition={'all .3s ease-in-out'}
                  _groupHover={{
                    borderColor: isFocused
                      ? 'brand.base'
                      : useColorModeValue(
                          'brandSecondary.300',
                          'brandSecondary.700',
                        ),
                    bg: 'transparent',
                  }}
                  _groupActive={{
                    borderColor: isFocused ? 'brand.base' : 'neutral.400',
                  }}
                ></Box>
              </Stack>
            </MenuButton>
          </Tooltip>
          <MenuList
            overflow={'hidden'}
            boxShadow={'md'}
            border={'1px solid'}
            borderColor={'brand.base'}
            borderRadius={'md'}
          >
            <MenuGroup title="NEW">
              <CustomMenuItem
                icon={Bars3CenterLeftIcon}
                title="Text Block"
                description="Start writing from scratch."
                onClick={onAddMetadataTextBlock}
              />
            </MenuGroup>
            <MenuDivider
              borderColor={'var(--chakra-colors-chakra-border-color)'}
            />
            <MenuGroup title="FROM LIBRARY">
              <CustomMenuItem
                icon={Bars3CenterLeftIcon}
                title="Text Block"
                description="Insert previously saved Text Blocks."
                onClick={() => {
                  setDefaultSelectedCategory({
                    type: 'blocks',
                    subType: 'private',
                  });
                  blockSelectionModal.onOpen();
                }}
              />
              <CustomMenuItem
                icon={CodeBracketSquareIcon}
                title="Test-Driven"
                description="Insert test results from your model."
                onClick={() => {
                  setDefaultSelectedCategory({
                    type: 'tests',
                    subType: 'vm',
                  });
                  blockSelectionModal.onOpen();
                }}
              />
              {documentType === ModelDocumentTypeEnum.monitoring && (
                <CustomMenuItem
                  icon={PresentationChartLineIcon}
                  title="Metric Over Time"
                  description="Insert metrics from your model."
                  onClick={() => {
                    setDefaultSelectedCategory({
                      type: 'metrics',
                      subType: 'vm',
                    });
                    blockSelectionModal.onOpen();
                  }}
                />
              )}
            </MenuGroup>
          </MenuList>
        </Menu>
      </Box>
      <DocumentBlockModal
        isOpen={blockSelectionModal.isOpen}
        onClose={blockSelectionModal.onClose}
        onAddMetric={onAddMetricOverTimeBlock}
        onAddTest={onAddTestDrivenBlock}
        onAddLibraryBlock={onAddLibraryBlock}
        showMetrics={documentType === ModelDocumentTypeEnum.monitoring}
        defaultSelectedCategory={defaultSelectedCategory}
        documentType={documentType}
      />
    </Flex>
  );
}
