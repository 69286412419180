import { Text, Input, useColorModeValue } from '@chakra-ui/react';
import { CustomFieldProps, CustomFieldTypes } from '../types';
import NullFieldDisplay from '../Generic/NullFieldDisplay';
import dayjs from 'dayjs';
import {
  displayFormattedDateAndTime,
  displayFormattedDate,
} from '../../../utils';

const DateFormatMap = {
  date: 'YYYY-MM-DD',
  'date-time': 'YYYY-MM-DDTHH:mm',
} as {
  [key in CustomFieldTypes]: string;
};

export default function CustomDatePicker({
  schema,
  mode,
  value,
  onChange,
}: CustomFieldProps) {
  let inputType = 'date';
  const parsedValue = value ? parseInt(value) : undefined;

  if (schema.type === 'date-time') {
    inputType = 'datetime-local';
  }

  if (mode === 'display' || mode === 'read-only') {
    if (!!value) {
      const unixTime = dayjs(parsedValue).unix();
      if (schema.type === 'date-time') {
        return <Text>{displayFormattedDateAndTime(unixTime)}</Text>;
      } else {
        return <Text>{displayFormattedDate(unixTime)}</Text>;
      }
    } else if (mode === 'read-only') {
      return <NullFieldDisplay />;
    } else {
      return null;
    }
  }

  return (
    <Input
      type={inputType}
      value={
        value
          ? dayjs(parsedValue).format(DateFormatMap[schema.type])
          : undefined
      }
      onChange={e => {
        onChange(`${dayjs(e.target.value).valueOf()}`);
      }}
      focusBorderColor="brand.base"
      bg={useColorModeValue('white', 'neutral.850')}
    />
  );
}
