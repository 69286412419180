import {
  Box,
  Icon,
  Link,
  useColorModeValue,
  VStack,
  Text,
  HStack,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  Flex,
} from '@chakra-ui/react';
import {
  ArrowLeftEndOnRectangleIcon,
  CogIcon,
  UsersIcon,
  Cog6ToothIcon,
  HomeIcon,
  EllipsisVerticalIcon,
} from '@heroicons/react/24/outline';
import ValidMindLogo from '../../ValidMindLogo';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { useAuth } from 'react-oidc-context';
import { useCurrentAdminUser } from '../../../hooks/admin';
import AvatarProxy from '../../AvatarProxy';

// Define sidebar items
const SidebarItems = [
  // {
  //   label: 'Home',
  //   icon: HomeIcon,
  //   path: '/admin',
  // },
  {
    label: 'Organizations',
    icon: UsersIcon,
    path: '/admin/organizations',
  },
  {
    label: 'Users',
    icon: UsersIcon,
    path: '/admin/users',
  },
  {
    label: 'Organization Defaults',
    icon: CogIcon,
    path: '/admin/organization-defaults',
  },
  {
    label: 'Tools',
    icon: UsersIcon,
    path: '/admin/tools',
  },
  {
    label: 'Settings',
    icon: Cog6ToothIcon,
    path: '/admin/settings',
  },
];

const AdminLayout = ({ children }: any) => {
  const { signoutRedirect } = useAuth();
  const location = useLocation();
  const { user: currentAdminUser, isLoading: isUserLoading } =
    useCurrentAdminUser(true);

  const bgColor = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.700');
  const nameColor = useColorModeValue('neutral.600', 'neutral.300');

  const onLogout = () => {
    signoutRedirect();
  };

  const onGoToDashboard = () => {
    window.location.href = '/';
  };

  // Update this function to check if a path is active
  const isPathActive = (itemPath: string) => {
    // For organization and user routes, match both the list and detail views
    if (itemPath === '/admin/organizations' || itemPath === '/admin/users') {
      return location.pathname.startsWith(itemPath);
    }
    // For other routes, exact match
    return location.pathname === itemPath;
  };

  return (
    <Flex h="100vh">
      {/* Sidebar */}
      <Box
        w="15%"
        maxW="400px"
        bg={bgColor}
        borderRight="1px"
        borderColor={borderColor}
        py={8}
        position="fixed"
        h="full"
        display="flex"
        flexDirection="column"
      >
        <Box px={4} mb={8}>
          <ValidMindLogo showPartnerLogo={true} />
        </Box>

        <VStack spacing={2} align="stretch" flex="1">
          {SidebarItems.map(item => (
            <Link
              key={item.path}
              as={RouterLink}
              to={item.path}
              textDecoration="none"
              _hover={{ textDecoration: 'none' }}
              _focus={{ textDecoration: 'none' }}
            >
              <Flex
                align="center"
                p={3}
                mx={4}
                borderRadius="lg"
                role="group"
                cursor="pointer"
                bg={isPathActive(item.path) ? 'brand.base' : 'transparent'}
                color={isPathActive(item.path) ? 'white' : 'gray.700'}
                _hover={{
                  bg: isPathActive(item.path) ? 'brand.500' : 'neutral.100',
                }}
              >
                <Icon
                  as={item.icon}
                  mr={4}
                  w={4}
                  h={4}
                  color={isPathActive(item.path) ? 'white' : 'brand.base'}
                />
                <Text fontSize="sm" fontWeight="medium">
                  {item.label}
                </Text>
              </Flex>
            </Link>
          ))}
        </VStack>

        {/* User Menu (replaces the direct Logout button) */}
        <Box px={4} mt="auto" mb={4}>
          <HStack spacing={2} alignItems="center">
            <AvatarProxy size="sm" name={currentAdminUser?.name} />
            <Flex justifyContent="space-between" alignItems="center" flex={1}>
              <Text fontSize="sm" fontWeight={500} color={nameColor}>
                {currentAdminUser?.name || 'Admin User'}
              </Text>
              <Menu placement="top-end">
                <MenuButton
                  as={IconButton}
                  aria-label="Options"
                  icon={<Icon as={EllipsisVerticalIcon} boxSize={6} />}
                  variant="ghost"
                  size="sm"
                />
                <MenuList boxShadow="xl">
                  <MenuItem
                    icon={<Icon as={HomeIcon} boxSize={5} />}
                    onClick={onGoToDashboard}
                  >
                    ValidMind Dashboard
                  </MenuItem>
                  <MenuItem
                    icon={<Icon as={ArrowLeftEndOnRectangleIcon} boxSize={5} />}
                    onClick={onLogout}
                  >
                    Logout
                  </MenuItem>
                </MenuList>
              </Menu>
            </Flex>
          </HStack>
        </Box>
      </Box>

      {/* Main Content */}
      <Box ml="15%" w="85%" p={8}>
        {children}
      </Box>
    </Flex>
  );
};

export default AdminLayout;
