import {
  Box,
  Button,
  DarkMode,
  Heading,
  HStack,
  Icon,
  LightMode,
  Spacer,
  Stack,
  Text,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
import { useContext, useMemo } from 'react';
import { Copyright } from '../../components/Copyright';
import UsersContext from '../../contexts/UsersContext';
import GetStartedChecklist from '../../components/GetStartedChecklist';
import AvatarProxy from '../../components/AvatarProxy';
import MoreInfoPopOver from '../../components/MoreInfoPopOver';
import DashboardPage from '../../components/DashboardPage';
import { CONFIG } from '../../config';
import { AttestationSidebarContext } from '../../components/AttestationSidebar';
import {
  ArrowRightIcon,
  ClipboardDocumentCheckIcon,
} from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';

const AttestationBanner = () => {
  const { currentUser, userHasPermission } = useContext(UsersContext);
  const { attestationExecutions } = useContext(AttestationSidebarContext);
  const navigate = useNavigate();

  const isAttestationReviewer = userHasPermission(
    ['review_attestation'],
    'any',
  );
  const isAttestationApprover = userHasPermission(
    ['approve_attestation'],
    'any',
  );

  const pendingAttestation = useMemo(() => {
    return attestationExecutions.find(attestationExecution => {
      const isAttestationOwner =
        attestationExecution.owner.cuid === currentUser?.cuid;
      switch (attestationExecution.current_status) {
        case 'NOT_STARTED':
          if (isAttestationOwner) {
            return true;
          }
          return false;
        case 'IN_PROGRESS':
          if (isAttestationOwner) {
            return true;
          }
          return false;
        case 'READY_FOR_REVIEW':
          if (isAttestationReviewer) {
            return true;
          }
          return false;
        case 'REVIEWED':
          if (isAttestationApprover) {
            return true;
          }
          return false;
        case 'SIGNED_OFF':
          return false;
      }
    });
  }, [
    attestationExecutions,
    currentUser,
    isAttestationApprover,
    isAttestationReviewer,
  ]);

  if (!pendingAttestation) {
    return null;
  }

  return (
    <Box
      px={8}
      pb={4}
      pt={2}
      rounded={'md'}
      bg="linear-gradient(45deg, var(--secondary-500, #2B7F89) 0%, var(--secondary-400, #44969F) 50%, var(--secondary-300, #60B9C5) 100%);"
      mb={4}
    >
      <DarkMode>
        <HStack
          align="flex-start"
          color={'var(--chakra-colors-chakra-body-text)'}
        >
          <Icon
            mt={2}
            as={ClipboardDocumentCheckIcon}
            boxSize={8}
            lineHeight="40px"
          />
          <VStack align="flex-start" gap={2} mt={2}>
            <Heading as={'h2'}>You have attestations pending action</Heading>
            <Text color="white">
              Please complete your pending attestations to stay on schedule.
            </Text>
          </VStack>
          <Spacer />
          <LightMode>
            <Button
              alignSelf={'flex-end'}
              onClick={() => {
                navigate('/model-inventory?open_attestations=true');
              }}
              rightIcon={<Icon as={ArrowRightIcon} boxSize={6} />}
              // variant={'ghost'}
            >
              Open Attestations
            </Button>
          </LightMode>
        </HStack>
      </DarkMode>
    </Box>
  );
};

export default function Dashboard() {
  const { currentUser: user } = useContext(UsersContext);

  return (
    <Box
      px={8}
      flex={1}
      w={'full'}
      overflow={'auto'}
      className="no-scrollbar"
      bg={useColorModeValue('white', 'black')}
    >
      <HStack alignItems={'center'} pt={9} mb={4} gap={4}>
        <AvatarProxy size="md" name={user?.name} src={user?.picture} />

        <Stack>
          <HStack>
            <Heading as={'h1'}>Welcome, {user?.first_name}!</Heading>
            <MoreInfoPopOver
              title="Customize Your Dashboard"
              link={`${CONFIG.VALIDMIND_DOCS_URL}/guide/configuration/customize-your-dashboard.html`}
            />
          </HStack>
        </Stack>
      </HStack>
      <AttestationBanner />

      <DashboardPage dashboardType="user" />

      <Copyright />

      <GetStartedChecklist />
    </Box>
  );
}
