import { useMemo } from 'react';
import dayjs from 'dayjs';
import { Execution, ExecutionStatus } from '../../../../models/workflow';

export const useDelayDays = (execution?: Execution) => {
  return useMemo(() => {
    if (!execution) return null;

    if (
      [
        ExecutionStatus.ABORTED,
        ExecutionStatus.FINISHED,
        ExecutionStatus.DELETE,
      ].includes(execution.status)
    ) {
      return null;
    }

    const now = dayjs();
    if (execution?.timeline?.delayed_since) {
      const delayedSinceDate = dayjs.unix(execution.timeline.delayed_since);
      return now.diff(delayedSinceDate, 'days', true);
    }
    return null;
  }, [execution]);
};
