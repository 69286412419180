import { useEffect } from 'react';
import { Box, useToast } from '@chakra-ui/react';
import { useMutation, useQueryClient } from 'react-query';
import ReactFlow, {
  Background,
  Controls,
  MiniMap,
  Node,
  useEdgesState,
  useNodesState,
} from 'reactflow';
import { Execution, Workflow } from '../../../../models/workflow';
import WorkflowContext from '../../../../contexts/WorkflowContext';
import WorkflowExecutionContext from '../../../../contexts/WorkflowExecutionContext';
import {
  nodeTypes,
  edgeTypes,
} from '../../../../pages/Settings/Workflows/components/WorkflowCanvas';
import FloatingConnectionLine from '../../../../pages/Settings/Workflows/components/edges/FloatingEdge/FloatingConnectionLine';
import API from '../../../../api/API';
import { ActivityFeedWidgetQueryKey } from '../../../ActivityFeedWidget';
import { useWorkflow } from '../../contexts/workflow/WorkflowContext';

interface ExecutionWorkflowProps {
  workflow: Workflow;
  execution?: Execution;
}

function ExecutionWorkflow({ workflow, execution }: ExecutionWorkflowProps) {
  const { loadWorkflowExecution } = useWorkflow();
  const toast = useToast();
  const queryClient = useQueryClient();
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);

  // Initialize nodes and edges
  useEffect(() => {
    const workflowNodes = workflow.version.source.nodes.map((node: Node) => {
      if (execution) {
        node.data.execution = execution;
        if (node.id === execution.current_node) {
          return {
            ...node,
            className: 'current-workflow-node',
          };
        }
      }
      return node;
    });

    setNodes(workflowNodes);
    setEdges(workflow.version.source.edges || []);
  }, [workflow, execution]);

  // Mutation for seeking to a specific node
  const seek = useMutation(
    async ({ nodeTargetId }: { nodeTargetId: string }) => {
      if (!execution) throw new Error('No execution available');
      const result = await API.WorkflowSeekTo(
        workflow.cuid,
        execution.cuid,
        nodeTargetId,
      );
      await loadWorkflowExecution(workflow.cuid, result.cuid); // Load new execution state
      return result;
    },
    {
      onSuccess: execution => {
        toast({
          variant: 'subtle',
          title: execution.workflow.title,
          description: 'Workflow execution seeked successfully',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });

        // Invalidate relevant queries
        queryClient.invalidateQueries([
          'targets',
          execution.target.cuid,
          'executions',
        ]);
        queryClient.invalidateQueries([
          'inventory-model',
          execution.target.cuid,
        ]);
        queryClient.invalidateQueries([ActivityFeedWidgetQueryKey]);
      },
      onError: error => {
        toast({
          variant: 'subtle',
          title: 'Error seeking workflow execution',
          description: API.getAPIErrorMessage(error),
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      },
    },
  );

  // Mutation for resuming a waiting workflow
  const resumeWait = useMutation(
    async () => {
      if (!execution) throw new Error('No execution available');
      const result = await API.WorkflowResumeWait(
        workflow.cuid,
        execution.cuid,
      );
      await loadWorkflowExecution(workflow.cuid, result.cuid); // Load new execution state
      return result;
    },
    {
      onSuccess: execution => {
        toast({
          variant: 'subtle',
          title: execution.workflow.title,
          description: 'Workflow execution resumed successfully',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });

        // Invalidate relevant queries
        queryClient.invalidateQueries([
          'targets',
          execution.target.cuid,
          'executions',
        ]);
        queryClient.invalidateQueries([
          'inventory-model',
          execution.target.cuid,
        ]);
        queryClient.invalidateQueries([ActivityFeedWidgetQueryKey]);
      },
    },
  );

  return (
    <Box
      w="full"
      position="relative"
      h="calc(100vh - clamp(100px, 18vh, 180px))"
    >
      <WorkflowContext.Provider value={{ workflow }}>
        <WorkflowExecutionContext.Provider
          value={{
            execution,
            seek,
            resumeWait,
          }}
        >
          <ReactFlow
            nodes={nodes}
            edges={edges}
            nodeTypes={nodeTypes}
            edgeTypes={edgeTypes}
            connectionLineComponent={FloatingConnectionLine}
            fitView
            fitViewOptions={{
              nodes: nodes.filter(
                node => node.className === 'current-workflow-node',
              ),
            }}
            minZoom={0.2}
            maxZoom={1}
          >
            <Background />
            <MiniMap />
            <Controls />
          </ReactFlow>
        </WorkflowExecutionContext.Provider>
      </WorkflowContext.Provider>
    </Box>
  );
}

export default ExecutionWorkflow;
