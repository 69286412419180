import React from 'react';
import { Route, Routes } from 'react-router-dom';
import WorkflowList from './pages/WorkflowsList';
import WorkflowStatesDetail from './pages/WorkflowStatesDetail';

export default function WorkflowsStates() {
  return (
    <Routes>
      <Route path="/" element={<WorkflowList />} />
      <Route path=":workflowCuid" element={<WorkflowStatesDetail />} />
    </Routes>
  );
}
