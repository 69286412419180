import { useCallback, useEffect, useState } from 'react';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  FormControl,
  Icon,
  Spacer,
  Stack,
  Switch,
  Text,
  useToast,
} from '@chakra-ui/react';
import { useMutation, useQueryClient } from 'react-query';
import { ArrowRightIcon } from '@heroicons/react/24/outline';
import { Execution, Workflow } from '../../../../models/workflow';
import { useModal } from '../../contexts/modal/ModalContext';
import {
  useNavigation,
  WorkflowManagerNavigationState,
} from '../../contexts/navigation/NavigationContext';
import API from '../../../../api/API';
import { ActivityFeedWidgetQueryKey } from '../../../ActivityFeedWidget';
import { Label } from '../../../Layout';
import { useWorkflow } from '../../contexts/workflow/WorkflowContext';

interface WorkflowAbortProps {
  target: any;
  workflow: Workflow;
  execution: Execution;
}

function WorkflowAbort({ target, workflow, execution }: WorkflowAbortProps) {
  const { setFooterContent } = useModal();
  const { navigate } = useNavigation();
  const { loadWorkflowExecution } = useWorkflow();
  const queryClient = useQueryClient();
  const toast = useToast();

  const [startNewExecution, setStartNewExecution] = useState(false);

  // Abort workflow mutation
  const { mutate: abortWorkflow, isLoading } = useMutation(
    async () => {
      return API.PostWorkflowExecutionAbort(
        workflow.cuid,
        execution.cuid,
        startNewExecution,
      );
    },
    {
      onSuccess: async newExecution => {
        // Show success toast
        toast({
          variant: 'subtle',
          title: workflow.title,
          description: 'Workflow Aborted Successfully',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });

        // Invalidate relevant queries
        queryClient.invalidateQueries(['targets', target.cuid, 'executions']);
        queryClient.invalidateQueries([ActivityFeedWidgetQueryKey]);
        queryClient.invalidateQueries(['approvals', 'voters']);

        // Navigate based on whether we're starting a new execution
        if (startNewExecution) {
          await loadWorkflowExecution(workflow.cuid, newExecution.cuid);
          navigate(WorkflowManagerNavigationState.DETAIL, {
            executionCuid: newExecution.cuid,
          });
        } else {
          navigate(WorkflowManagerNavigationState.LIST, {
            initialListingTab: 'aborted',
          });
        }
      },
      onError: error => {
        toast({
          variant: 'subtle',
          title: 'Error aborting workflow',
          description: API.getAPIErrorMessage(error),
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      },
    },
  );

  const handleConfirmAbort = useCallback(() => {
    abortWorkflow();
  }, [abortWorkflow]);

  const handleCancel = useCallback(() => {
    navigate(WorkflowManagerNavigationState.DETAIL);
  }, [navigate]);

  // Update footer content
  useEffect(() => {
    setFooterContent(
      <>
        <Button onClick={handleCancel} mr={4} variant={'ghost'}>
          No, Cancel
        </Button>
        <Spacer />
        <Button
          onClick={handleConfirmAbort}
          isLoading={isLoading}
          isDisabled={isLoading}
          leftIcon={<Icon as={ArrowRightIcon} boxSize={4} />}
          variant={'primary'}
          _hover={{
            bg: 'red.600',
          }}
        >
          Yes, Abort Workflow
        </Button>
      </>,
    );

    return () => setFooterContent(null);
  }, [handleCancel, handleConfirmAbort, isLoading]);

  return (
    <Stack spacing={4}>
      <Text>
        This action will abort the workflow for this model and set it to its
        initial state.
      </Text>

      <FormControl>
        <Label mb={2}>Restart workflow after aborting.</Label>
        <Switch
          size={'lg'}
          id="start-new-workflow"
          isChecked={startNewExecution}
          onChange={e => setStartNewExecution(e.target.checked)}
        />
      </FormControl>

      {isLoading && (
        <Alert status="info">
          <AlertIcon />
          <AlertDescription>Aborting workflow execution...</AlertDescription>
        </Alert>
      )}
    </Stack>
  );
}

export default WorkflowAbort;
