import {
  Box,
  Button,
  ButtonGroup,
  Collapse,
  FormControl,
  Heading,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  SimpleGrid,
  Spacer,
  Stack,
  Text,
  Textarea,
  useColorModeValue,
  useDisclosure,
  useToast,
  VStack,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';
import { useMutation, useQueryClient } from 'react-query';
import API from '../../api/API';
import { ApprovalVote, ApprovalVoter } from '../../models/approval';
import { useState } from 'react';
import ModelInventoryItem from '../ModelInventoryItem';
import { TInventoryModel } from '../../models/inventory_model';
import { Label } from '../Layout';
import { CheckIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { Icon } from '@chakra-ui/icons';
import AvatarProxy from '../AvatarProxy';
import {
  ChevronDownIcon,
  ChevronUpIcon,
  DocumentTextIcon,
} from '@heroicons/react/24/outline';
import { ActivityFeedWidgetQueryKey } from '../ActivityFeedWidget';
import { useFlags } from '../../hooks/useFlags';

interface WorkflowApprovalItemProps {
  variant?: 'page' | 'vertical';
  voter: ApprovalVoter;
  onVoteSubmitted?: () => void;
}

interface VoteVariables {
  vote: ApprovalVote;
  notes: string;
}

const VoterStatusColumns: {
  vote: ApprovalVote;
  bgColor: string;
  darkBgColor: string;
}[] = [
  {
    vote: 'approved',
    bgColor: 'green.25',
    darkBgColor: 'green.900',
  },
  {
    vote: 'rejected',
    bgColor: 'red.25',
    darkBgColor: 'red.900',
  },
  {
    vote: null,
    bgColor: 'neutral.100',
    darkBgColor: 'neutral.800',
  },
];

const VoteDetailsModal = ({
  voter,
  onClose,
  isOpen,
}: {
  voter?: ApprovalVoter;
  onClose: () => void;
  isOpen: boolean;
}) => {
  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose} size="3xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text>Notes for {voter?.user.name}</Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing={4}>
            <Textarea value={voter?.notes} bg={'white'} readOnly />
          </Stack>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose}>Close</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default function WorkflowApprovalItem({
  variant,
  voter,
  onVoteSubmitted,
}: WorkflowApprovalItemProps) {
  const queryClient = useQueryClient();
  const toast = useToast();
  const [notes, setNotes] = useState('');
  const [voted, setVoted] = useState<ApprovalVote>(null);
  const [showVoters, setShowVoters] = useState(false);
  const [voterForDetails, setVoterForDetails] =
    useState<ApprovalVoter | null>();
  const { workflowsV3 } = useFlags();

  const voterDetailsModal = useDisclosure();

  const vote = useMutation(
    async ({ vote, notes }: VoteVariables) => {
      return API.PostApprovalVote(voter.cuid, vote, notes);
    },
    {
      onSuccess: approvalVoter => {
        toast({
          variant: 'subtle',
          title: 'Decision submitted successfully',
          status: 'success',
          isClosable: true,
        });
        onVoteSubmitted && onVoteSubmitted();

        // update the approval request anywhere is displayed
        queryClient.invalidateQueries(['approvals', 'voters']);

        // get the new events generated by this vote
        queryClient.invalidateQueries([ActivityFeedWidgetQueryKey]);

        if (workflowsV3) {
          // update the executions
          queryClient.invalidateQueries([
            'targets',
            approvalVoter.approval.execution.target.cuid,
            'executions',
          ]);
        } else {
          queryClient.invalidateQueries(['workflows']);
        }
      },
      onError: error => {
        if (error instanceof Error) {
          toast({
            variant: 'subtle',
            title: error.message,
            status: 'error',
            isClosable: true,
          });
        }
      },
      onSettled: () => {
        onClose();
      },
    },
  );
  const onSubmit = async () => {
    vote.mutate({ vote: voted, notes });
  };

  const target_display_component = {
    InventoryModel: (
      <Box bg={useColorModeValue('white', 'neutral.850')} rounded={'md'}>
        <ModelInventoryItem
          inventoryModel={voter.approval.execution.target as TInventoryModel}
        />
      </Box>
    ),
  };

  const onClose = () => {
    setVoted(null);
  };

  const alreadyVoted = voter.vote !== null;

  return (
    <>
      <VoteDetailsModal
        isOpen={voterDetailsModal.isOpen}
        voter={voterForDetails || undefined}
        onClose={voterDetailsModal.onClose}
      />
      <Stack
        data-testid={'workflow-approval-module'}
        gap={4}
        border={
          variant === 'vertical'
            ? 'none'
            : '1px solid var(--chakra-colors-chakra-border-color)'
        }
        bg={
          variant === 'vertical'
            ? 'none'
            : useColorModeValue('neutral.50', 'neutral.900')
        }
        p={variant === 'vertical' ? 'none' : '4'}
        rounded={variant === 'vertical' ? 'none' : 'md'}
      >
        <Stack
          id={`approval-${voter.approval.cuid}`}
          gap={4}
          direction={variant === 'vertical' ? 'column' : 'row'}
        >
          <Stack w={'full'}>
            <Heading
              as={'h5'}
              color={useColorModeValue('neutral.600', 'neutral.400')}
            >
              {voter.approval.title}
            </Heading>
            <Text color={'var(--chakra-colors-chakra-body-text)'}>
              {voter.approval.message}
            </Text>
          </Stack>
          <Stack w={'full'} alignItems={'flex-end'}>
            {!alreadyVoted && (
              <ButtonGroup
                isAttached
                variant={'outline'}
                size={variant === 'vertical' ? 'sm' : 'md'}
              >
                <Button
                  bg={'green.25'}
                  color={'green.700'}
                  _hover={{ bg: 'green.50' }}
                  _dark={{ bg: 'green.100' }}
                  onClick={e => {
                    e.stopPropagation();
                    setVoted('approved');
                  }}
                  isActive={voted === 'approved'}
                  leftIcon={<Icon as={CheckIcon} boxSize={5} />}
                >
                  Approve
                </Button>
                <Button
                  bg={'red.25'}
                  color={'red.700'}
                  _hover={{ bg: 'red.50' }}
                  _dark={{ bg: 'red.100' }}
                  onClick={e => {
                    e.stopPropagation();
                    setVoted('rejected');
                  }}
                  isActive={voted === 'rejected'}
                  leftIcon={<Icon as={XMarkIcon} boxSize={5} />}
                >
                  Reject
                </Button>
              </ButtonGroup>
            )}
            <Button
              onClick={e => {
                setShowVoters(!showVoters);
                e.stopPropagation();
              }}
              variant={'ghost'}
              rightIcon={
                <Icon
                  as={showVoters ? ChevronUpIcon : ChevronDownIcon}
                  boxSize={5}
                />
              }
              w={variant === 'vertical' ? 'full' : 'auto'}
            >
              {showVoters ? 'Hide' : 'Show'} Approvers
            </Button>
          </Stack>
        </Stack>

        {variant === 'page' && (
          <>
            {
              target_display_component[
                voter.approval.execution.target_type as 'InventoryModel'
              ]
            }
          </>
        )}

        <Collapse in={alreadyVoted || showVoters} animateOpacity>
          <SimpleGrid columns={variant === 'vertical' ? 1 : 3} gap={2}>
            {VoterStatusColumns.map(statusColumn => {
              const votersForStatus = voter.approval.voters.filter(
                v => v.vote === statusColumn.vote,
              );
              return (
                <VStack
                  key={statusColumn.vote || 'pending'}
                  align="flex-start"
                  bgColor={useColorModeValue('white', 'neutral.850')}
                  gap={2}
                  h={'full'}
                  border={'1px solid'}
                  borderColor={'var(--chakra-colors-chakra-border-color)'}
                  w={'full'}
                  rounded={'md'}
                  overflow={'hidden'}
                >
                  <Box
                    bg={useColorModeValue(
                      statusColumn.bgColor,
                      statusColumn.darkBgColor,
                    )}
                    w={'full'}
                    p={2}
                  >
                    <Heading as={'h6'}>
                      {(statusColumn.vote || 'pending').toUpperCase()}
                    </Heading>
                  </Box>

                  <Wrap gap={2} p={2} w={'full'}>
                    {votersForStatus.length === 0 ? (
                      <Box w={'full'} textAlign={'center'}>
                        <Text
                          color="neutral.500"
                          fontStyle={'italic'}
                          fontSize={variant === 'vertical' ? 'sm' : 'md'}
                        >
                          {`No one has ${statusColumn.vote} yet.`}
                        </Text>
                      </Box>
                    ) : (
                      votersForStatus.map(v => {
                        return (
                          <WrapItem key={v.cuid}>
                            <HStack
                              bg={useColorModeValue(
                                'neutral.100',
                                'neutral.800',
                              )}
                              rounded={'full'}
                              pr={2}
                            >
                              <AvatarProxy
                                size={'xs'}
                                name={v.user.name}
                                src={
                                  v.user.picture ||
                                  'https://placekitten.com/100/100'
                                }
                              />
                              <Text fontSize={'sm'} lineHeight={'normal'}>
                                {v.user.name}
                              </Text>
                            </HStack>
                            {v.notes && (
                              <Popover trigger="hover">
                                <PopoverTrigger>
                                  <Icon as={DocumentTextIcon} />
                                </PopoverTrigger>
                                <PopoverContent shadow={'md'}>
                                  <PopoverArrow />
                                  <PopoverHeader
                                    fontSize={'sm'}
                                    fontWeight={'bold'}
                                    roundedTop={'md'}
                                  >
                                    Notes
                                  </PopoverHeader>
                                  <PopoverBody rounded={'md'} pt={0}>
                                    {v.notes}
                                  </PopoverBody>
                                </PopoverContent>
                              </Popover>
                            )}
                          </WrapItem>
                        );
                      })
                    )}
                  </Wrap>
                </VStack>
              );
            })}
          </SimpleGrid>
        </Collapse>
      </Stack>
      <Modal isCentered isOpen={!!voted} onClose={onClose} size="3xl">
        <ModalOverlay />
        <ModalContent data-testid="change-status-modal">
          <ModalHeader>
            <Text>{voted === 'approved' ? 'Approve' : 'Reject'}</Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box>
              <FormControl isRequired>
                <Label mb={2}>NOTES:</Label>
                <Textarea
                  value={notes}
                  onChange={e => setNotes(e.target.value)}
                  bg={'white'}
                  placeholder={'Enter your decision notes here. '}
                />
              </FormControl>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={() => setVoted(null)}
              disabled={false}
              variant={'ghost'}
            >
              Cancel
            </Button>
            <Spacer />
            <Button
              onClick={onSubmit}
              isLoading={vote.isLoading}
              variant={'primary'}
              isDisabled={!notes.trim()}
            >
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
