import { useEffect } from 'react';
import {
  Alert,
  AlertIcon,
  AlertTitle,
  Heading,
  HStack,
  Stack,
} from '@chakra-ui/react';
import {
  useNavigation,
  WorkflowManagerNavigationState,
} from '../../contexts/navigation/NavigationContext';
import ExecutionStateTag from '../../../ExecutionStateTag';
import { LoadingContainer } from '../../../LoadingContainer';
import { useWorkflow } from '../../contexts/workflow/WorkflowContext';
import WorkflowActionButtons from '../WorkflowActionButtons';
import ExecutionTabs from '../ExecutionTabs';
import WorkflowOptionsMenu from '../WorkflowOptionsMenu';
import WorkflowStart from '../WorkflowStart';
import WorkflowAbort from '../WorkflowAbort';
import WorkflowUserAction from '../WorkflowUserAction';
import WorkflowApprovalActions from '../../../WorkflowApprovalActions';
import API from '../../../../api/API';

interface WorkflowDetailsProps {
  target: any;
  initialDetailsTab?: string;
}

function WorkflowDetails({ target, initialDetailsTab }: WorkflowDetailsProps) {
  const { workflow, execution, loading, loadWorkflowExecution, error } =
    useWorkflow();
  const { currentView, params } = useNavigation();
  const activeTab = params.initialDetailsTab || initialDetailsTab;

  // Effect to load data after navigation
  useEffect(() => {
    const loadData = async () => {
      await loadWorkflowExecution(params.workflowCuid!, params.executionCuid);
    };

    if (params.isLoading) {
      loadData();
    }
  }, [params.executionCuid, params.workflowCuid, params.isLoading]);

  const getTitle = () => {
    switch (currentView) {
      case WorkflowManagerNavigationState.DETAIL:
        return `${
          execution?.execution_id ? `${execution.execution_id} -` : ``
        } ${workflow?.title}`;
      case WorkflowManagerNavigationState.DETAIL_ACTION_START:
        return 'Start Workflow';
      case WorkflowManagerNavigationState.DETAIL_ACTION_USER_ACTION:
        return params.userAction?.buttonLabel || 'User Action';
      case WorkflowManagerNavigationState.DETAIL_ACTION_EXECUTION_ABORT:
        return 'Are you sure you want to abort this workflow?';
      case WorkflowManagerNavigationState.DETAIL_ACTION_EXECUTION_RESCHEDULE:
        return 'Reschedule Workflow';
      default:
        return '';
    }
  };

  const getExecutionStateTag = () => {
    if (
      !execution?.state ||
      currentView !== WorkflowManagerNavigationState.DETAIL
    ) {
      return null;
    }
    return <ExecutionStateTag state={execution.state} />;
  };

  if (error) {
    return (
      <Alert status="error">
        <AlertIcon />
        <AlertTitle>{API.getAPIErrorMessage(error)}</AlertTitle>
      </Alert>
    );
  }

  return (
    <LoadingContainer isLoading={!workflow || loading}>
      <Stack gap={4}>
        <HStack justifyContent="space-between">
          <HStack>
            <Heading>{getTitle()}</Heading>
            {getExecutionStateTag()}
          </HStack>

          {currentView === WorkflowManagerNavigationState.DETAIL && (
            <HStack>
              <WorkflowActionButtons
                target={target}
                workflow={workflow!}
                execution={execution}
              />
              <WorkflowOptionsMenu workflow={workflow!} execution={execution} />
            </HStack>
          )}
        </HStack>

        {currentView === WorkflowManagerNavigationState.DETAIL && (
          <>
            {execution && (
              <WorkflowApprovalActions
                targetCuid={target.cuid}
                executionCuid={execution.cuid}
              />
            )}

            <ExecutionTabs
              target={target}
              workflow={workflow!}
              execution={execution}
              initialTab={activeTab}
            />
          </>
        )}

        {/* start and reschedule */}
        {currentView === WorkflowManagerNavigationState.DETAIL_ACTION_START && (
          <WorkflowStart target={target} workflow={workflow!} />
        )}
        {currentView ===
          WorkflowManagerNavigationState.DETAIL_ACTION_EXECUTION_RESCHEDULE &&
          execution && (
            <WorkflowStart
              target={target}
              workflow={workflow!}
              execution={execution}
              isReschedule={true}
            />
          )}

        {/* workflow action from user action node */}
        {currentView ===
          WorkflowManagerNavigationState.DETAIL_ACTION_USER_ACTION &&
          execution && (
            <WorkflowUserAction
              target={target}
              workflow={workflow!}
              execution={execution}
              fromStandalone={!!params.fromStandalone}
            />
          )}

        {/* abort */}
        {currentView ===
          WorkflowManagerNavigationState.DETAIL_ACTION_EXECUTION_ABORT &&
          execution && (
            <WorkflowAbort
              workflow={workflow!}
              target={target}
              execution={execution}
            />
          )}
      </Stack>
    </LoadingContainer>
  );
}

export default WorkflowDetails;
