import { APIPaginatedResponse } from './utils';
import { TStatusesWorkflowStatus } from './statuses_workflow';
import { TInventoryModel } from './inventory_model';
import { TUser } from './user';
import { Node, Edge } from 'reactflow';
import { State } from './workflow_state';
import { Approval } from './approval';
import {
  ApprovalNodeType,
  StatusFormNodeType,
  UserActionNodeType,
} from '../pages/Settings/Workflows/components/types';

export enum TriggerType {
  APPLICATION_EVENT = 'application-event',
  MANUAL = 'manual',
  SCHEDULE = 'schedule',
  WORKFLOW_CALL = 'workflow-call',
}

export enum EntityName {
  INVENTORY_MODEL = 'InventoryModel',
  FINDING = 'Finding',
}

export enum EntityEventType {
  CREATED = 'created',
}

export enum ScheduleType {
  SPECIFIC_DATETIME = 'specific-datetime',
  RECURRING = 'recurring',
}

export enum RecurrenceUnit {
  DAYS = 'days',
  WEEKS = 'weeks',
  MONTHS = 'months',
  CRON = 'cron',
}

export enum Status {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  ARCHIVED = 'archived',
}

export enum ExpectedDurationUnit {
  DAYS = 'days',
  WEEKS = 'weeks',
  MONTHS = 'months',
  YEARS = 'years',
}

export enum ExecutionStatus {
  SCHEDULED = 'scheduled',
  ACTIVE = 'active',
  WAITING = 'waiting',
  ABORTED = 'aborted',
  DELETE = 'deleted',
  FINISHED = 'finished',
}

export type WorkflowSource = {
  nodes: Node[];
  edges: Edge[];
};

export type Version = {
  cuid: string;
  number: number;
  source: WorkflowSource;
};

export type Workflow = {
  id: number;
  cuid: string;
  workflow_id: string;
  title: string;
  trigger_id: string;
  description: string;
  created_at: number;
  updated_at: number;
  version: Version;

  trigger_type: TriggerType;
  entity_name?: string;
  entity_event_type?: string;
  schedule_type?: ScheduleType;
  recurrence_unit?: RecurrenceUnit;
  recurrence_interval_count?: number;
  recurrence_day_of_week?: string;
  recurrence_day_of_month?: number;
  recurrence_time_of_day?: string;
  recurrence_cron_expression?: string;
  manual_trigger_enabled: boolean;
  status: Status;
  expected_duration_amount?: number;
  expected_duration_unit?: ExpectedDurationUnit;
};

export type WorkflowStatusForm = {
  trigger: string;
  required_fields: string[];
  buttonLabel: string;
  status: TStatusesWorkflowStatus;
};

export type WorkflowUserActionForm = {
  trigger: string;
  required_fields: string[];
  buttonLabel: string;
  disabled?: boolean;
};

export type WorkflowPaginated = APIPaginatedResponse<Workflow>;

export type ExecutionStep = {
  cuid: string;
  created_at: number;
  tags: string[];
  execution_data: any;
  ran_by?: TUser;
};

export type Execution = {
  id: number;
  cuid: string;
  execution_id: string;
  target_type: string;
  target: TInventoryModel | any;
  status: ExecutionStatus;
  current_node: string;
  workflow: Workflow;
  steps: ExecutionStep[];
  state?: State;
  progress?: number;
  scheduled_at?: number;
  resume_wait_at?: number;
  created_at: number;
  started_at?: number;
  expected_end_at?: number;
  finished_at?: number;
  aborted_at?: number;
  can_abort: boolean;

  pending_approval?: {
    node: ApprovalNodeType;
    approval: Approval;
  };

  pending_user_actions?: (UserActionNodeType | StatusFormNodeType)[];

  timeline_starts_at?: number;
  timeline_ends_at?: number;
  timeline_class_name?: string;

  timeline: {
    starts_at: number;
    ends_at: number;
    class_names: string[];
    delayed_since?: number;
  };
};

export interface WorkflowExecutionsResponse {
  workflow: Workflow;
  executions: Execution[];
}
