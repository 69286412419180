import * as React from 'react';
import {
  useExpanded,
  useFilters,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';
import { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Grid,
  HStack,
  Icon,
  useColorModeValue,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from '@chakra-ui/react';
import './data-table.css';
import {
  ChevronUpIcon,
  ChevronDownIcon,
  ArrowRightIcon,
  ArrowLeftIcon,
} from '@heroicons/react/20/solid';

const DEFAULT_PAGE_SIZE = 15;

export type ColumnSort = {
  id: string;
  desc: boolean;
};

export type SortingState = ColumnSort[];

interface SortIconProps {
  isSorted: boolean;
  isDesc: boolean;
  color: string;
}

interface SortBoxProps {
  condition: boolean;
  isDesc: boolean;
  color?: string;
}

const SortIcon = ({ isSorted, isDesc, color }: SortIconProps) => (
  <Icon
    as={isDesc ? ChevronDownIcon : ChevronUpIcon}
    w={4}
    h={4}
    color={color}
  />
);

const EmptyBox = () => <Box w={4}></Box>;

const SortBox = ({
  condition,
  isDesc,
  color = 'neutral.500',
}: SortBoxProps) => (
  <Box mb={'2px !important'}>
    {condition ? (
      <SortIcon isSorted={condition} isDesc={isDesc} color={color} />
    ) : (
      <EmptyBox />
    )}
  </Box>
);

export interface IDataTableProps {
  columns: Array<any>;
  data: any;
  getCellProps?: Function;
  getHeaderProps?: Function;
  getRowProps?: Function;
  tableProps?: object;
  enableSort?: boolean;
  renderRowSubComponent?: Function;
  pageSize?: number;
  onUpdateData?: Function;
  onClickRow?: Function;
  onDoubleClickRow?: Function;
  isInteractive?: boolean;
  onSort?: (column: any) => void;
  manualSortBy?: boolean;
  defaultSortBy?: SortingState;
  emptyStateDisplay?: React.ReactNode;
}

const defaultCellStyle: object = {
  backgroundColor: 'transparent',
  padding: '.5rem 1rem',
  color: 'inherit',
};

export const defaultGetHeaderProps = (column: any) => ({
  style: {
    textAlign: 'left',
    padding: '.5rem 1rem',
    height: '3rem',
    color: 'inherit',
    textTransform: 'uppercase',
    fontSize: '0.857rem',
    borderBottom: '1px solid #E2E8F0',
    borderBottomColor: 'inherit',
  },
});

export function DataTable({
  columns,
  data,
  getCellProps,
  getHeaderProps,
  getRowProps,
  tableProps,
  enableSort = false,
  renderRowSubComponent,
  pageSize,
  onUpdateData,
  onClickRow,
  onDoubleClickRow,
  isInteractive = false,
  onSort,
  manualSortBy = false,
  defaultSortBy,
  emptyStateDisplay,
}: IDataTableProps) {
  const [currentPage, setCurrentPage] = useState(0);
  const PAGE_SIZE = pageSize || DEFAULT_PAGE_SIZE;
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    visibleColumns,
    // @ts-ignore
    page,
    // @ts-ignore
    canPreviousPage,
    // @ts-ignore
    canNextPage,
    // @ts-ignore
    pageOptions,
    // @ts-ignore
    gotoPage,
    // @ts-ignore
    nextPage,
    // @ts-ignore
    previousPage,
    // @ts-ignore
    state: { expanded, pageIndex, sortBy },
  } = useTable(
    {
      columns,
      data,
      manualSortBy,
      initialState: {
        // @ts-ignore
        pageIndex: currentPage,
        pageSize: PAGE_SIZE,
        sortBy: defaultSortBy || [],
      },
      onUpdateData,
    },
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
  );

  useEffect(() => {
    onSort && onSort(sortBy);
  }, [sortBy]);

  useEffect(() => {
    setCurrentPage(pageIndex);
  }, [pageIndex]);

  // Default striped background
  const defaultGetRowProps = (row: any) => ({
    style: {
      background:
        row.index % 2 === 0
          ? useColorModeValue(
              'var(--chakra-colors-neutral-25)',
              'var(--chakra-colors-neutral-1000)',
            )
          : useColorModeValue('white', 'black'),
      borderTop: '1px solid ',
      borderTopColor: useColorModeValue(
        'var(--chakra-colors-neutral-50)',
        'var(--chakra-colors-neutral-950)',
      ),
    },
  });

  // Default styles for the sub row that gets rendered with expanded rows
  const defaultGetSubRowProps = (row: any) => ({
    style: {
      background:
        row.index % 2 === 0
          ? useColorModeValue('white', 'black')
          : useColorModeValue('neutral.25', 'neutral.900'),
      height: '40px',
    },
  });

  const getRowPropsFn = getRowProps || defaultGetRowProps;
  const getHeaderPropsFn = getHeaderProps || defaultGetHeaderProps;

  const headerProps = (column: any) =>
    enableSort
      ? column.getHeaderProps([
          column.getSortByToggleProps(),
          getHeaderPropsFn(column),
        ])
      : column.getHeaderProps(getHeaderPropsFn(column));

  const hasFilters = useMemo(
    () => columns.findIndex(c => c.hasOwnProperty('filter')) >= 0,
    [columns],
  );

  return (
    <Grid id="table" w="100%" templateColumns="1fr" overflow="hidden">
      <Grid
        templateColumns="minmax(0, 1fr)"
        overflowX="auto"
        overflowY="auto"
        borderRadius={'md'}
        border={'1px solid'}
        borderColor={'var(--chakra-colors-chakra-border-color)'}
      >
        <table
          style={{
            tableLayout: 'auto',
          }}
          {...getTableProps(tableProps)}
          className={isInteractive ? 'interactive-table' : ''}
        >
          <thead>
            {headerGroups.map((headerGroup: any, index: number) => (
              <tr
                key={`header-group-${index}`}
                {...headerGroup.getHeaderGroupProps()}
              >
                {headerGroup.headers.map((column: any, index: number) => (
                  <th
                    key={`header-group-header-${index}`}
                    {...headerProps(column)}
                  >
                    <HStack>
                      <Box>{column.render('Header')}</Box>
                      {enableSort && (
                        <SortBox
                          condition={column.isSorted}
                          isDesc={column.isSortedDesc}
                        />
                      )}
                    </HStack>
                  </th>
                ))}
              </tr>
            ))}
            {hasFilters &&
              headerGroups.map((headerGroup: any, index: number) => (
                <tr key={`filters-${index}`}>
                  {headerGroup.headers.map((column: any, index: number) => (
                    <th
                      key={`header-group-filter-header-${index}`}
                      {...getHeaderPropsFn(column)}
                    >
                      {column.canFilter ? column.render('Filter') : null}
                    </th>
                  ))}
                </tr>
              ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row: any, idx: number) => {
              prepareRow(row);
              const rowStyles = { ...row.getRowProps(getRowPropsFn(row)) };
              if (idx === page.length - 1) {
                rowStyles.style = {
                  ...rowStyles.style,
                  borderBottom: 'none',
                };
              }
              return (
                // Use a React.Fragment here so the table markup is still valid
                <React.Fragment key={`row-fragment-${idx}`}>
                  <tr
                    {...rowStyles}
                    key={`row-${idx}`}
                    onClick={() => onClickRow && onClickRow(row)}
                    onDoubleClick={() =>
                      onDoubleClickRow && onDoubleClickRow(row)
                    }
                  >
                    {row.cells.map((cell: any, cellIdx: number) => {
                      return (
                        <td
                          key={`td-${cellIdx}`}
                          {...cell.getCellProps([
                            {
                              style: defaultCellStyle,
                            },
                            {
                              style: cell.column.style,
                            },
                            (getCellProps && getCellProps(cell)) || {},
                          ])}
                        >
                          {cell.render('Cell')}
                        </td>
                      );
                    })}
                  </tr>
                  {row.isExpanded && renderRowSubComponent ? (
                    <tr {...defaultGetSubRowProps(row)} key={`subrow-${idx}`}>
                      <td
                        key={`subrow-td-${idx}`}
                        colSpan={visibleColumns.length}
                      >
                        {renderRowSubComponent({ row })}
                      </td>
                    </tr>
                  ) : null}
                </React.Fragment>
              );
            })}
          </tbody>
          {rows.length === 0 && emptyStateDisplay && (
            <tr>
              <td colSpan={visibleColumns.length}>{emptyStateDisplay}</td>
            </tr>
          )}
        </table>
      </Grid>

      {rows.length > PAGE_SIZE && (
        <HStack w={'100%'} justifyContent={'center'} m={4} gap={2}>
          <Button
            leftIcon={<Icon as={ArrowLeftIcon} boxSize={5} />}
            onClick={() => previousPage()}
            isDisabled={!canPreviousPage}
            variant={'ghost'}
          >
            Prev
          </Button>
          <HStack gap={0.5}>
            {(() => {
              let pages = [];
              const totalPages = pageOptions.length;
              const currentPage = pageIndex;
              const maxVisible = 5; // Maximum number of visible page buttons

              // Always show first page
              pages.push(
                <Button
                  key={0}
                  onClick={() => gotoPage(0)}
                  isDisabled={currentPage === 0}
                  variant={'ghost'}
                  color={currentPage === 0 ? 'brand.base' : 'inherit'}
                >
                  1
                </Button>,
              );

              // Calculate range of visible pages
              let startPage = Math.max(
                1,
                currentPage - Math.floor(maxVisible / 2),
              );
              let endPage = Math.min(
                totalPages - 2,
                startPage + maxVisible - 1,
              );

              // Adjust start if we're near the end
              if (endPage - startPage < maxVisible - 1) {
                startPage = Math.max(1, endPage - maxVisible + 1);
              }

              // Add ellipsis before first page if needed
              if (startPage > 1) {
                pages.push(
                  <Menu key="menu-before" placement="bottom">
                    <MenuButton
                      as={Button}
                      size="sm"
                      variant="ghost"
                      minWidth="40px"
                      height="32px"
                    >
                      ...
                    </MenuButton>
                    <MenuList
                      minWidth="100px"
                      maxHeight="240px"
                      overflowY="auto"
                    >
                      {Array.from(
                        { length: startPage - 1 },
                        (_, i) => i + 1,
                      ).map(pageNum => (
                        <MenuItem
                          key={`page-${pageNum}`}
                          onClick={() => gotoPage(pageNum)}
                        >
                          {pageNum + 1}
                        </MenuItem>
                      ))}
                    </MenuList>
                  </Menu>,
                );
              }

              // Add page numbers
              for (let i = startPage; i <= endPage; i++) {
                pages.push(
                  <Button
                    key={i}
                    onClick={() => gotoPage(i)}
                    isDisabled={currentPage === i}
                    variant={'ghost'}
                    color={currentPage === i ? 'brand.base' : 'inherit'}
                  >
                    {i + 1}
                  </Button>,
                );
              }

              // Add ellipsis before last page if needed
              if (endPage < totalPages - 2) {
                pages.push(
                  <Menu key="menu-after" placement="bottom">
                    <MenuButton
                      as={Button}
                      size="sm"
                      variant="ghost"
                      minWidth="40px"
                      height="32px"
                    >
                      ...
                    </MenuButton>
                    <MenuList
                      minWidth="100px"
                      maxHeight="240px"
                      overflowY="auto"
                    >
                      {Array.from(
                        { length: totalPages - 2 - endPage },
                        (_, i) => endPage + i + 1,
                      ).map(pageNum => (
                        <MenuItem
                          key={`page-${pageNum}`}
                          onClick={() => gotoPage(pageNum)}
                        >
                          {pageNum + 1}
                        </MenuItem>
                      ))}
                    </MenuList>
                  </Menu>,
                );
              }

              // Always show last page if we have more than one page
              if (totalPages > 1) {
                pages.push(
                  <Button
                    key={totalPages - 1}
                    onClick={() => gotoPage(totalPages - 1)}
                    isDisabled={currentPage === totalPages - 1}
                    variant={'ghost'}
                    color={
                      currentPage === totalPages - 1 ? 'brand.base' : 'inherit'
                    }
                  >
                    {totalPages}
                  </Button>,
                );
              }

              return pages;
            })()}
          </HStack>
          <Button
            rightIcon={<Icon as={ArrowRightIcon} boxSize={5} />}
            onClick={() => nextPage()}
            isDisabled={!canNextPage}
            variant={'ghost'}
          >
            Next
          </Button>
        </HStack>
      )}
    </Grid>
  );
}
