import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useToast } from '@chakra-ui/react';
import API from '../../../api/API';
import { StatusChangedToast } from '../../Layout/Toasts';
import { TextContentEditor } from '../../TextContentEditor';
import { SectionContentsProps } from '../../Layout/DocumentationPage';
import { TInventoryModelMetadata } from '../../../models/metadata';
import { canUpdateMetadata } from '../../../auth/utils';
import { ActivityFeedWidgetQueryKey } from '../../ActivityFeedWidget';
import { getModelDocumentType } from '../../../models/model_document';
import UsersContext from '../../../contexts/UsersContext';
import { AIGenerationConfig } from '../../TextContentEditor/AITextContentEditor';
import DocumentationContext from '../../../contexts/DocumentationContext';
import { InventoryModelStages } from '../../../models/inventory_model';
import InventoryModelContext from '../../../contexts/InventoryModel';
import { useAuth } from 'react-oidc-context';
import BlockWrapper from '../BlockWrapper';
import ConfirmationAlert from '../../ConfirmationAlert';

interface UpdateMetadataVariables {
  metadata: TInventoryModelMetadata;
  clearAnnotation?: boolean;
  silent?: boolean;
  documentType?: string;
}

interface PatchRevisionsVariables {
  revisionsData: any[];
  documentType?: string;
}

type MetadataContentEditorProps = SectionContentsProps & {
  hideToolbar?: boolean;
};

export function MetadataContentEditor({
  contents,
  removeBlock,
  readOnly,
  overrideDocumentType,
  customAIGenerationConfig,
  hideToolbar = false,
}: MetadataContentEditorProps) {
  // MetadataContentEditor expects a single contentId
  const metadataContentId = contents.content_id;
  const metadataContentType = contents.content_type;
  const { inventoryModel, templates } = useContext(InventoryModelContext);
  const { currentUser } = useContext(UsersContext);
  const { currentSection } = useContext(DocumentationContext);
  const queryClient = useQueryClient();
  const id = inventoryModel!.cuid;
  const toast = useToast();
  const auth = useAuth();
  const { user } = auth;
  const [originalText, setOriginalText] = useState('');
  const [currentDocumentType, setCurrentDocumentType] = useState(
    overrideDocumentType || templates.current.documentType,
  );
  const [aiGenerationConfig, setAIGenerationConfig] =
    useState<AIGenerationConfig>();
  const [isReadOnly, setIsReadOnly] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);

  useEffect(() => {
    setCurrentDocumentType(
      overrideDocumentType || templates.current.documentType,
    );
  }, [templates.current.documentType, overrideDocumentType]);

  useEffect(() => {
    if (!readOnly) {
      setIsReadOnly(inventoryModel!.stage !== InventoryModelStages.ACTIVE);
    } else {
      setIsReadOnly(readOnly);
    }
  }, [readOnly]);

  const queryKey = [
    'inventory-model',
    id,
    'metadata',
    currentDocumentType,
    metadataContentId,
  ];
  const documentType = getModelDocumentType(currentDocumentType);
  // TODO: handle errors in the UI with the `error` key

  const hasEditPerms = useMemo(
    () =>
      currentUser
        ? canUpdateMetadata(currentUser, inventoryModel!.users!, documentType)
        : false,
    [currentUser, inventoryModel!.users, documentType],
  );

  const { data: metadata, isLoading } = useQuery(
    queryKey,
    async () => {
      return await API.GetModelInventoryMetadataByContentId(
        inventoryModel!,
        metadataContentId!,
        getModelDocumentType(currentDocumentType),
      );
    },
    {
      retry: false,
      // enabled: !isEditing, // avoids reloading the metadata into the revision history while editing,
      // it doesn't work nicely since when `isEditing` is back to true it will cause a fetch,
      // propagating down the server metadata and triggering a save with the old state, it messes up with the
      // content metadata and therefore with its revision history.
      refetchOnWindowFocus: false, // avoids reloading the metadata into the revision history while editing,
      // this one works but shuts down the perception of realtime updates.
      onSuccess: data => {
        setOriginalText(data.text);
      },
    },
  );

  const postMetadata = useMutation(
    queryKey,
    async ({ metadata, silent, documentType }: UpdateMetadataVariables) => {
      // silent is used when saving reference for annotations or attaching new content attachments.
      // when silent it won't register activity feed.
      return API.PostInventoryModelMetadata(
        inventoryModel!,
        getModelDocumentType(currentDocumentType),
        metadata,
        silent,
        !!documentType,
      );
    },
    {
      onSuccess: (data, { silent }) => {
        // MetadataContentEditor can be used with an event widget that shows the changes that
        // has been done to the metadata. We need to invalidate the widget's query to make sure
        // it is updated after this mutation.
        queryClient.invalidateQueries({
          queryKey: [ActivityFeedWidgetQueryKey],
        });
      },
      onError: error => {
        if (error instanceof Error) {
          // TODO: Track
          toast({
            variant: 'subtle',
            duration: 3000,
            isClosable: true,
            render: () => (
              <StatusChangedToast
                message="Could not save content"
                error={error.message}
                status="error"
              />
            ),
          });
        }
      },
    },
  );

  const postRevisions = useMutation(
    ['revisions'],
    async ({ revisionsData, documentType }: PatchRevisionsVariables) => {
      return await API.PatchRevisions(
        inventoryModel!,
        metadata!.cuid!,
        revisionsData,
        documentType,
      );
    },
  );

  const onSave = (
    newText: string,
    silent: boolean = false,
    documentType?: string,
  ) => {
    let metadataToSave = metadata;
    if (!metadataToSave) {
      metadataToSave = {
        cuid: undefined,
        content_id: metadataContentId,
        content_type: getModelDocumentType(currentDocumentType),
        text: '',
      };
    }
    metadataToSave.text = newText;
    return postMetadata.mutateAsync({
      metadata: metadataToSave,
      silent,
      documentType,
    });
  };

  const onSaveRevisions = (revisionsData: any[], documentType?: string) => {
    return postRevisions.mutateAsync({ revisionsData, documentType });
  };

  const onCancel = (silent: boolean = true) => {
    if (metadata) {
      metadata.text = originalText;
      postMetadata.mutate({ metadata, silent });
    }
  };

  useEffect(() => {
    (async () => {
      if (customAIGenerationConfig) {
        setAIGenerationConfig(customAIGenerationConfig);
      } else {
        // Add default section context if available
        let sectionContext = {};
        if (
          currentSection &&
          typeof currentSection !== 'string' &&
          currentSection.guidelines
        ) {
          sectionContext = {
            title: currentSection.title,
            guidelines: currentSection.guidelines,
          };
        }

        // default setup for qualitative text
        setAIGenerationConfig({
          initEvent: {
            eventName: 'generate-qualitative-text',
            args: {
              accessToken: user?.access_token,
              inventory_model_cuid: inventoryModel!.cuid!,
              content_id: metadata?.content_id!,
              content_type: getModelDocumentType(currentDocumentType),
              user_cuid: currentUser?.cuid,
            },
          },
          streamEvent: {
            eventName: `completions-${metadata?.content_id}`,
          },
          sectionContext,
        });
      }
    })();
  }, [metadata, inventoryModel, currentSection, customAIGenerationConfig]);

  const onDeleteConfirmed = (confirmed: boolean) => {
    if (confirmed) {
      if (removeBlock) {
        removeBlock(metadata as any);
      }
    }
    setConfirmDelete(false);
  };

  let content = null;

  // We don't want to enable the add to block library for test description content
  const enableAddToBlockLibrary =
    !metadataContentId.startsWith('test_description:');

  if (hideToolbar) {
    content = (
      <TextContentEditor
        metadata={metadata}
        text={metadata?.text || ''}
        isLoading={isLoading}
        onSave={onSave}
        onCancel={onCancel}
        onSaveRevisions={onSaveRevisions}
        allowEdit={isReadOnly ? false : hasEditPerms}
        removeBlock={removeBlock}
        aiGenerationConfig={aiGenerationConfig}
        enableAddToBlockLibrary={enableAddToBlockLibrary}
      />
    );
  } else {
    content = (
      <BlockWrapper
        contentId={metadataContentId}
        contentType={metadataContentType}
        documentType={documentType!}
        readOnly={isReadOnly}
        showDeleteConfirmation={() => setConfirmDelete(true)}
        setIsInView={() => {}}
      >
        <TextContentEditor
          metadata={metadata}
          text={metadata?.text || ''}
          isLoading={isLoading}
          onSave={onSave}
          onCancel={onCancel}
          onSaveRevisions={onSaveRevisions}
          allowEdit={isReadOnly ? false : hasEditPerms}
          removeBlock={removeBlock}
          aiGenerationConfig={aiGenerationConfig}
          enableAddToBlockLibrary={enableAddToBlockLibrary}
        />
      </BlockWrapper>
    );
  }

  return (
    <>
      <ConfirmationAlert
        open={confirmDelete}
        title={'Remove text block'}
        dialogBody={"Are you sure you'd like to remove this block?"}
        onConfirm={onDeleteConfirmed}
      />
      {content}
    </>
  );
}
