import { Route, Routes, useNavigate } from 'react-router-dom';
import { useCallback, useContext, useEffect } from 'react';
import SidebarContext from '../../../contexts/SidebarContext';
import { LoadingContainer } from '../../../components/LoadingContainer';
import {
  Box,
  Text,
  VStack,
  Heading,
  Icon,
  Button,
  Flex,
  useColorModeValue,
  useDisclosure,
  HStack,
} from '@chakra-ui/react';
import Breadcrumbs from '../../../components/Breadcrumbs';
import { ContentPageTitle } from '../../../components/Layout';
import MoreInfoPopOver from '../../../components/MoreInfoPopOver';
import { EmptyStateDisplay } from '../../../components/EmptyStateDisplay';
import { PlusIcon } from '@heroicons/react/24/solid';
import {
  AddAttestationModal,
  AddAttestationModalOnSaveProps,
  AddAttestationModalOnSaveResponse,
} from './AddAttestationsModal';
import API from '../../../api/API';
import { useQuery } from 'react-query';
import UsersContext from '../../../contexts/UsersContext';
import EditAttestation from './EditAttestation';
import { useFlags } from '../../../hooks/useFlags';

function AttestationsList() {
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { userHasPermission, currentOrganization } = useContext(UsersContext);

  const attestationsQuery = useQuery(
    ['attestations', currentOrganization?.cuid],
    async () => {
      return await API.GetAttestations();
    },
    {
      refetchOnWindowFocus: false,
    },
  );

  const handleAddAttestation = useCallback(
    async ({
      name,
      description,
    }: AddAttestationModalOnSaveProps): Promise<AddAttestationModalOnSaveResponse> => {
      try {
        const newAttestation = await API.CreateAttestation({
          name,
          description,
        });

        await attestationsQuery.refetch();

        return {
          success: true,
        };
      } catch (e: any) {
        return {
          success: false,
          error: e.message,
        };
      }
    },
    [],
  );

  const canAddAttestation = userHasPermission(['add_attestation'], 'all');

  const hasAttestations = (attestationsQuery.data?.length || 0) > 0;

  return (
    <>
      <AddAttestationModal
        isOpen={isOpen}
        onClose={onClose}
        onSave={handleAddAttestation}
      />
      <VStack
        alignItems="start"
        spacing={0}
        paddingTop={12}
        mt={1}
        paddingBottom={16}
        px={14}
        gap={8}
        w="full"
        overflow="auto"
        className="no-scrollbar"
        maxWidth={'7xl'}
        mx={'auto'}
      >
        <LoadingContainer isLoading={attestationsQuery.isLoading}>
          <Box w="full">
            <Breadcrumbs />
            <HStack w="full" justify="space-between">
              <ContentPageTitle
                display="flex"
                flexDirection="row"
                alignItems="center"
              >
                Attestations
                <MoreInfoPopOver
                  title="Configure Workflows"
                  link="https://docs.validmind.ai/guide/model-workflows/set-up-model-workflows.html"
                  placement="right-end"
                  iconProps={{
                    ml: 2,
                  }}
                />
              </ContentPageTitle>
              {hasAttestations && canAddAttestation && (
                <Flex w="full" justify="flex-end">
                  <Button
                    leftIcon={<Icon as={PlusIcon} boxSize={5} />}
                    variant={'primary'}
                    onClick={onOpen}
                  >
                    Add New Attestation
                  </Button>
                </Flex>
              )}
            </HStack>
          </Box>
          <Text>
            Set the attestation period, schedule reminders, select the relevant
            Model Inventory fields, and create a questionnaire template for the
            attestation process.
          </Text>
          <VStack w={'full'}>
            {!hasAttestations && (
              <Flex
                direction="column"
                p={4}
                gap={4}
                alignSelf="stretch"
                borderRadius="lg"
                borderWidth={1}
                borderColor={useColorModeValue('neutral.200', 'neutral.800')}
                backgroundColor={useColorModeValue('neutral.50', 'neutral.900')}
                data-testid="to-be-assigned-container"
              >
                <EmptyStateDisplay variant="no-reports">
                  <Heading as={'h5'}>No attestations added yet.</Heading>
                  {canAddAttestation && (
                    <Button
                      leftIcon={<Icon as={PlusIcon} boxSize={5} />}
                      variant={'ghost'}
                      onClick={onOpen}
                    >
                      Add New Attestation
                    </Button>
                  )}
                </EmptyStateDisplay>
              </Flex>
            )}
            {hasAttestations &&
              attestationsQuery.data?.map(attestation => (
                <Flex
                  width={'full'}
                  p={4}
                  cursor="pointer"
                  rounded={'md'}
                  borderWidth={1}
                  bg={useColorModeValue('white', 'transparent')}
                  borderColor={useColorModeValue('neutral.200', 'neutral.800')}
                  _hover={{
                    bg: useColorModeValue(
                      'brandSecondary.25',
                      'brandSecondary.950',
                    ),
                    borderColor: useColorModeValue(
                      'brandSecondary.100',
                      'brandSecondary.800',
                    ),
                    color: useColorModeValue('inherit', 'brandSecondary.25'),
                  }}
                  transition="all 0.3s ease-in-out"
                  onClick={() => navigate(`./${attestation.cuid}`)}
                >
                  <HStack>
                    <VStack align="flex-start">
                      <Text fontWeight={600}>{attestation.name}</Text>
                      <Text>{attestation.description}</Text>
                    </VStack>
                  </HStack>
                </Flex>
              ))}
          </VStack>
        </LoadingContainer>
      </VStack>
    </>
  );
}

export default function Attestations() {
  const { setInSettings } = useContext(SidebarContext);
  const { attestationsUi } = useFlags();

  useEffect(() => {
    setInSettings(true);
    return () => {
      setInSettings(false);
    };
  }, []);

  if (!attestationsUi) {
    return <></>;
  }

  return (
    <Routes>
      <Route path="/" element={<AttestationsList />} />
      <Route path="/:cuid" element={<EditAttestation />} />
    </Routes>
  );
}
