import React, { createContext, useCallback, useContext, useState } from 'react';
import {
  Execution,
  Workflow,
  WorkflowUserActionForm,
} from '../../../../models/workflow';

export enum WorkflowManagerNavigationState {
  LIST = 'LIST',
  DETAIL = 'DETAIL',
  DETAIL_ACTION = 'DETAIL-ACTION',
  DETAIL_ACTION_START = 'DETAIL-ACTION-START',
  DETAIL_ACTION_USER_ACTION = 'DETAIL-ACTION-USER-ACTION',
  DETAIL_ACTION_EXECUTION_ABORT = 'DETAIL-ACTION-EXECUTION-ABORT',
  DETAIL_ACTION_EXECUTION_RESCHEDULE = 'DETAIL-ACTION-EXECUTION-RESCHEDULE',
}

export interface NavigationParams {
  workflowCuid?: string;
  executionCuid?: string;
  initialDetailsTab?: string;
  initialListingTab?: string;
  userAction?: WorkflowUserActionForm;
  workflow?: Workflow;
  execution?: Execution;
  isLoading?: boolean;
  listingTab?: string;
  fromStandalone?: boolean; // Add this new property
}

export interface NavigationState {
  view: WorkflowManagerNavigationState;
  params: NavigationParams;
}

export interface NavigationContextType {
  currentView: WorkflowManagerNavigationState;
  params: NavigationParams;
  navigate: (
    view: WorkflowManagerNavigationState,
    params?: NavigationParams,
  ) => void;
  goBack: () => void;
}

const NavigationContext = createContext<NavigationContextType | undefined>(
  undefined,
);

export function NavigationProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [navigationStack, setNavigationStack] = useState<NavigationState[]>([]);
  const [currentView, setCurrentView] =
    useState<WorkflowManagerNavigationState>(
      WorkflowManagerNavigationState.LIST,
    );
  const [currentParams, setCurrentParams] = useState<NavigationParams>({});

  const navigate = useCallback(
    (view: WorkflowManagerNavigationState, params: NavigationParams = {}) => {
      setNavigationStack(prev => [
        ...prev,
        {
          view: currentView,
          params: currentParams,
        },
      ]);
      setCurrentView(view);
      setCurrentParams(params);
    },
    [currentView, currentParams],
  );

  const goBack = useCallback(() => {
    const previous = navigationStack[navigationStack.length - 1];
    if (previous) {
      setCurrentView(previous.view);
      setCurrentParams(previous.params);
      setNavigationStack(prev => prev.slice(0, -1));
    }
  }, [navigationStack]);

  return (
    <NavigationContext.Provider
      value={{
        currentView,
        params: currentParams,
        navigate,
        goBack,
      }}
    >
      {children}
    </NavigationContext.Provider>
  );
}

export function useNavigation() {
  const context = useContext(NavigationContext);
  if (context === undefined) {
    throw new Error('useNavigation must be used within a NavigationProvider');
  }
  return context;
}
