import React from 'react';
import {
  As,
  Text,
  HStack,
  Icon,
  Box,
  Button,
  IconButton,
} from '@chakra-ui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';

interface FloatingActionBarProps {
  selectedItems: string[];
  actions: {
    label: string;
    icon: As;
    colorScheme: string;
    onClick: () => void;
  }[];
  onClose: () => void;
}

const FloatingActionBar: React.FC<FloatingActionBarProps> = ({
  selectedItems,
  actions,
  onClose,
}) => {
  return (
    <HStack
      bg="neutral.700"
      borderRadius={6}
      p={2}
      boxShadow="md"
      position="fixed"
      bottom={4}
      left="50%"
      transform="translateX(-50%)"
      zIndex="sticky"
      gap={2}
      minW="400px"
    >
      <HStack justifyContent="space-between" w="full">
        <HStack>
          <Box
            bg="brand.500"
            borderRadius="md"
            display="flex"
            alignItems="center"
            justifyContent="center"
            py={1}
            px={3}
          >
            <Text color="white">{selectedItems.length}</Text>
          </Box>
          <Text color="white">selected</Text>
        </HStack>
        <HStack>
          {actions.map((action, index) => (
            <Button
              aria-label={action.label}
              leftIcon={<Icon as={action.icon} />}
              color={action.colorScheme}
              onClick={() => action.onClick()}
              variant="link"
            >
              {action.label}
            </Button>
          ))}
          <IconButton
            aria-label="Close"
            icon={<Icon as={XMarkIcon} />}
            onClick={onClose}
            variant="link"
            color="white"
          />
        </HStack>
      </HStack>
    </HStack>
  );
};

export default FloatingActionBar;
