import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  VStack,
  FormControl,
  Input,
  ModalFooter,
  Button,
  Spacer,
  HStack,
  Alert,
  AlertIcon,
} from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import { Label } from '../../../../components/Layout';
import { TAttestationSchedule } from '../../../../models/attestation';
import DatePicker from 'react-datepicker';

import './styles.css';
import dayjs from 'dayjs';

export type AddOrEditScheduleModalOnSaveProps = {
  existingScheduleCUID?: string;
  name: string;
  startDate: string;
  endDate: string;
};

export type AddOrEditScheduleModalOnSaveResponse = {
  success: boolean;
  error?: string;
};

type AddOrEditScheduleModalProps = {
  isOpen: boolean;
  existingSchedule?: TAttestationSchedule;
  onClose: () => void;
  onSave: (
    obj: AddOrEditScheduleModalOnSaveProps,
  ) => Promise<AddOrEditScheduleModalOnSaveResponse>;
};

export const AddOrEditScheduleModal = ({
  isOpen,
  existingSchedule,
  onClose,
  onSave,
}: AddOrEditScheduleModalProps) => {
  const [name, setName] = useState('');
  const [startDate, setStartDate] = useState<Date | undefined>();
  const [endDate, setEndDate] = useState<Date | undefined>();

  const [error, setError] = useState<string | undefined>();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit = async () => {
    setIsSubmitting(true);
    setError(undefined);
    const result = await onSave({
      name,
      startDate: startDate?.toISOString() || '',
      endDate: endDate?.toISOString() || '',
      existingScheduleCUID: existingSchedule?.cuid,
    });

    setIsSubmitting(false);

    if (result.success) {
      onClose();
    } else {
      setError(result.error);
    }
  };

  useEffect(() => {
    if (isOpen) {
      if (existingSchedule) {
        setName(existingSchedule.name);
        setStartDate(new Date(existingSchedule.start_date));
        setEndDate(new Date(existingSchedule.end_date));
      } else {
        setName('');
        setStartDate(undefined);
        setEndDate(undefined);
      }
    }
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      closeOnEsc
      closeOnOverlayClick
      scrollBehavior={'inside'}
      size="xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {!existingSchedule ? 'Add' : 'Edit'} Attestation Period
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack gap={4}>
            <FormControl>
              <Label mb={2}>Name</Label>
              <Input
                value={name}
                onChange={e => setName(e.target.value)}
                placeholder=""
              />
            </FormControl>
            <HStack w="full">
              <FormControl>
                <Label mb={2}>Start Date</Label>
                <DatePicker
                  autoComplete="off"
                  minDate={dayjs().add(1, 'day').toDate()}
                  selected={startDate}
                  popperProps={{ strategy: 'fixed' }}
                  onChange={e => setStartDate(e as Date)}
                  customInput={<Input />}
                />
              </FormControl>
              <FormControl>
                <Label mb={2}>End Date</Label>
                <DatePicker
                  autoComplete="off"
                  disabled={!startDate}
                  minDate={startDate}
                  selected={endDate}
                  popperProps={{ strategy: 'fixed' }}
                  onChange={e => setEndDate(e as Date)}
                  customInput={<Input />}
                />
              </FormControl>
            </HStack>
            {error && (
              <Alert status="error">
                <AlertIcon />
                {error}
              </Alert>
            )}
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button variant="ghost" onClick={onClose}>
            Cancel
          </Button>
          <Spacer />
          <Button
            isDisabled={!name || !startDate || !endDate}
            isLoading={isSubmitting}
            onClick={onSubmit}
            variant={'primary'}
          >
            {!existingSchedule ? 'Add' : 'Save'} Period
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
