import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Text,
} from '@chakra-ui/react';
import {
  useNavigation,
  WorkflowManagerNavigationState,
} from '../../contexts/navigation/NavigationContext';
import { useWorkflow } from '../../contexts/workflow/WorkflowContext';

function WorkflowBreadcrumb() {
  const { currentView, navigate, params } = useNavigation();
  const { workflow, clearWorkflowData } = useWorkflow();

  if (currentView === WorkflowManagerNavigationState.LIST) {
    return <Text fontSize="3x">All Workflows</Text>;
  }

  const handleAllWorkflowsClick = () => {
    clearWorkflowData(); // Clear workflow data before navigating
    navigate(WorkflowManagerNavigationState.LIST, {
      listingTab: params.listingTab,
    });
  };

  const handleWorkflowClick = () => {
    navigate(WorkflowManagerNavigationState.DETAIL);
  };

  const getActionText = () => {
    switch (currentView) {
      case WorkflowManagerNavigationState.DETAIL_ACTION_START:
        return 'Start Workflow';
      case WorkflowManagerNavigationState.DETAIL_ACTION_USER_ACTION:
        return 'User Action';
      case WorkflowManagerNavigationState.DETAIL_ACTION_EXECUTION_ABORT:
        return 'Abort Workflow';
      case WorkflowManagerNavigationState.DETAIL_ACTION_EXECUTION_RESCHEDULE:
        return 'Reschedule Workflow';
      default:
        return '';
    }
  };

  const isActionView = currentView.startsWith('DETAIL-ACTION');
  const actionText = getActionText();

  return (
    <Breadcrumb fontWeight="normal" fontSize="md" color="neutral.500">
      <BreadcrumbItem>
        <BreadcrumbLink onClick={handleAllWorkflowsClick}>
          All Workflows
        </BreadcrumbLink>
      </BreadcrumbItem>

      {workflow && (
        <BreadcrumbItem>
          <BreadcrumbLink
            onClick={handleWorkflowClick}
            isCurrentPage={!isActionView}
          >
            {workflow.title}
          </BreadcrumbLink>
        </BreadcrumbItem>
      )}

      {isActionView && actionText && (
        <BreadcrumbItem isCurrentPage>
          <BreadcrumbLink>{actionText}</BreadcrumbLink>
        </BreadcrumbItem>
      )}
    </Breadcrumb>
  );
}

export default WorkflowBreadcrumb;
