import {
  Box,
  Button,
  Text,
  useDisclosure,
  Checkbox,
  Spinner,
  Center,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  Icon,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Tag,
  TagLabel,
  VStack,
} from '@chakra-ui/react';
import { ContentPageTitle } from '../../../components/Layout';
import { HStack } from '@chakra-ui/react';
import BlockLibraryModal from '../../../components/BlockLibraryModal';
import { DataTable } from '../../../components/DataTable';
import { useState, useContext, useEffect } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import API from '../../../api/API';
import { TBlockLibraryItem } from '../../../models/block_library';
import { EllipsisVerticalIcon } from '@heroicons/react/24/outline';
import AvatarProxy from '../../../components/AvatarProxy';
import { useToast } from '@chakra-ui/react';
import { DeleteConfirmModal } from '../../../components/DashboardPage/DeleteConfirmModal';
import FloatingActionBar from '../../../components/FloatingActionBar';
import { TrashIcon } from '@heroicons/react/24/outline';
import { UsersContext } from '../../../contexts';
import { displayFormattedDateAndTime } from '../../../utils';
import SidebarContext from '../../../contexts/SidebarContext';
import Breadcrumbs from '../../../components/Breadcrumbs';

type ModalMode = 'add' | 'edit' | 'duplicate';

export default function BlockLibrary() {
  const modalDisclosure = useDisclosure();
  const deleteModalDisclosure = useDisclosure();
  const batchDeleteModalDisclosure = useDisclosure();
  const [selectedBlocks, setSelectedBlocks] = useState<string[]>([]);
  const [selectedBlock, setSelectedBlock] = useState<
    TBlockLibraryItem | undefined
  >();
  const [modalMode, setModalMode] = useState<ModalMode>('add');
  const queryClient = useQueryClient();
  const toast = useToast();
  const { currentOrganization, userHasPermission } = useContext(UsersContext);
  const { setInSettings } = useContext(SidebarContext);

  const canManageSharedBlockLibrary = userHasPermission(
    ['manage_shared_block_library'],
    'all',
  );

  useEffect(() => {
    setInSettings(true);
    return () => {
      setInSettings(false);
    };
  }, []);

  const {
    data: blocks,
    isLoading,
    error,
  } = useQuery<{
    private_blocks: TBlockLibraryItem[];
    shared_blocks: TBlockLibraryItem[];
  }>(['block-library', currentOrganization?.cuid], () => API.GetBlockLibrary());

  const handleEdit = (block: TBlockLibraryItem) => {
    setSelectedBlock(block);
    setModalMode('edit');
    modalDisclosure.onOpen();
  };

  const handleDuplicate = (block: TBlockLibraryItem) => {
    setSelectedBlock(block);
    setModalMode('duplicate');
    modalDisclosure.onOpen();
  };

  const handleAdd = () => {
    setSelectedBlock(undefined);
    setModalMode('add');
    modalDisclosure.onOpen();
  };

  const handleModalClose = () => {
    setSelectedBlock(undefined);
    setModalMode('add');
    modalDisclosure.onClose();
  };

  const handleDeleteClick = (block: TBlockLibraryItem) => {
    setSelectedBlock(block);
    deleteModalDisclosure.onOpen();
  };

  const handleConfirmDelete = async () => {
    if (deleteModalDisclosure.isOpen && selectedBlock) {
      try {
        await API.DeleteBlockLibraryItem(selectedBlock.cuid);
        queryClient.invalidateQueries(['block-library']);
        toast({
          title: 'Block deleted successfully',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        setSelectedBlock(undefined);
      } catch (error) {
        toast({
          title: 'Error deleting block',
          description: API.getAPIErrorMessage(error),
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    } else if (batchDeleteModalDisclosure.isOpen && selectedBlocks.length > 0) {
      try {
        const promises = selectedBlocks.map(async block => {
          await API.DeleteBlockLibraryItem(block);
        });
        await Promise.all(promises);
        queryClient.invalidateQueries(['block-library']);
        toast({
          title: 'Blocks deleted successfully',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        setSelectedBlocks([]);
      } catch (error) {
        toast({
          title: 'Error deleting blocks',
          description: API.getAPIErrorMessage(error),
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    }
  };

  const columns = [
    {
      Header: '',
      accessor: 'cuid',
      Cell: ({ row }: any) => (
        <Checkbox
          isChecked={selectedBlocks.includes(row.original.cuid)}
          onChange={e => {
            if (e.target.checked) {
              setSelectedBlocks([...selectedBlocks, row.original.cuid]);
            } else {
              setSelectedBlocks(
                selectedBlocks.filter(id => id !== row.original.cuid),
              );
            }
          }}
        />
      ),
    },
    {
      Header: 'Name',
      accessor: 'name',
    },
    {
      Header: 'Access',
      accessor: 'is_shared',
      Cell: ({ value }: any) => (
        <Text textTransform="capitalize">{value ? 'Shared' : 'Private'}</Text>
      ),
    },
    {
      Header: 'Last Updated At',
      accessor: 'updated_at',
      Cell: ({ value }: any) => (
        <Text>
          {displayFormattedDateAndTime(
            Math.floor(new Date(value).getTime() / 1000),
          )}
        </Text>
      ),
    },
    {
      Header: 'Last Updated By',
      accessor: 'updated_by',
      Cell: ({ value }: any) => {
        return (
          <Box>
            <Tag key={value.cuid} size={'md'}>
              <AvatarProxy
                src={value.picture}
                size="xs"
                name={value.name}
                ml={-2}
                mr={2}
              />
              <TagLabel>{value.name}</TagLabel>
            </Tag>
          </Box>
        );
      },
    },
    {
      Header: '',
      accessor: 'actions',
      Cell: ({ row }: any) => (
        <Menu>
          <MenuButton
            as={Button}
            variant="ghost"
            size="sm"
            rightIcon={<Icon as={EllipsisVerticalIcon} boxSize={5} />}
          />
          <MenuList>
            <MenuItem onClick={() => handleEdit(row.original)}>
              Edit Details
            </MenuItem>
            <MenuItem onClick={() => handleDuplicate(row.original)}>
              Duplicate Text Block
            </MenuItem>
            <MenuDivider />
            <MenuItem
              color="red.500"
              onClick={() => handleDeleteClick(row.original)}
            >
              Delete Text Block
            </MenuItem>
          </MenuList>
        </Menu>
      ),
    },
  ];

  const sharedLibraryColumns = [...columns];

  if (!canManageSharedBlockLibrary) {
    // Remove the actions column
    sharedLibraryColumns.pop();

    // Remove the checkboxes column
    sharedLibraryColumns.shift();
  }

  return (
    <VStack
      alignItems="start"
      spacing={0}
      paddingTop={12}
      mt={1}
      paddingBottom={16}
      px={14}
      w="full"
      overflow="auto"
      className="no-scrollbar"
      data-testid="groups-settings"
      maxWidth={'7xl'}
      mx={'auto'}
    >
      <Breadcrumbs />
      <HStack gap={5} color={'inherit'} mb={8}>
        <ContentPageTitle>Block Library</ContentPageTitle>
        <Button colorScheme="brand" onClick={handleAdd} size="sm">
          Add New Block
        </Button>
      </HStack>
      <Box mb={6}>
        <Text>
          Manage and edit text blocks, including their details and settings.
          Create new blocks, copy existing ones, or delete multiple at a time.
        </Text>
      </Box>

      <Box mb={8} w="full">
        {isLoading ? (
          <Center py={8}>
            <Spinner size="lg" color="brand.500" />
          </Center>
        ) : error ? (
          <Alert status="error">
            <AlertIcon />
            <AlertTitle>Error loading blocks</AlertTitle>
            <AlertDescription>{API.getAPIErrorMessage(error)}</AlertDescription>
          </Alert>
        ) : (
          <Tabs>
            <TabList>
              <Tab>Private Blocks</Tab>
              <Tab>Shared Blocks</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <DataTable
                  columns={columns}
                  data={blocks?.private_blocks || []}
                  isInteractive={true}
                  enableSort={true}
                />
              </TabPanel>
              <TabPanel>
                <DataTable
                  columns={sharedLibraryColumns}
                  data={blocks?.shared_blocks || []}
                  isInteractive={true}
                  enableSort={true}
                />
              </TabPanel>
            </TabPanels>
          </Tabs>
        )}
      </Box>
      <BlockLibraryModal
        isOpen={modalDisclosure.isOpen}
        onClose={handleModalClose}
        mode={modalMode}
        existingItem={selectedBlock}
      />
      <DeleteConfirmModal
        isOpen={
          deleteModalDisclosure.isOpen || batchDeleteModalDisclosure.isOpen
        }
        onClose={() => {
          if (batchDeleteModalDisclosure.isOpen) {
            batchDeleteModalDisclosure.onClose();
            setSelectedBlocks([]);
          } else {
            deleteModalDisclosure.onClose();
            setSelectedBlock(undefined);
          }
        }}
        onConfirm={handleConfirmDelete}
        title="Confirm Delete"
        text={`Are you sure you want to delete? Previously used instances will not be affected, but they will no longer be available for future use.`}
      />
      {selectedBlocks.length > 0 && (
        <FloatingActionBar
          selectedItems={selectedBlocks}
          actions={[
            {
              label: 'Delete',
              icon: TrashIcon,
              onClick: () => batchDeleteModalDisclosure.onOpen(),
              colorScheme: 'red.300',
            },
          ]}
          onClose={() => {
            setSelectedBlocks([]);
          }}
        />
      )}
    </VStack>
  );
}
