import { useCallback, useEffect, useState } from 'react';
import ReactFlow, {
  addEdge,
  Background,
  Connection,
  ConnectionLineType,
  Edge,
  EdgeTypes,
  MarkerType,
  Node,
  Position,
  useEdgesState,
  useNodesState,
  ReactFlowInstance,
} from 'reactflow';
import dagre from 'dagre';

import CustomNode from './CustomNode';
import CustomEdge from './CustomEdge';
import { SmartBezierEdge } from '@tisoap/react-flow-smart-edge';

import 'reactflow/dist/style.css';
import { Box } from '@chakra-ui/react';

const nodeTypes = {
  custom: CustomNode,
};

const edgeTypes: EdgeTypes = {
  custom: CustomEdge,
  smart: SmartBezierEdge,
};

const dagreGraph = new dagre.graphlib.Graph();
dagreGraph.setDefaultEdgeLabel(() => ({}));

const nodeWidth = 172;
const nodeHeight = 36;

const getLayoutedElements = (
  nodes: Node[],
  edges: Edge[],
  direction = 'LR',
) => {
  const isHorizontal = direction === 'LR';
  dagreGraph.setGraph({ rankdir: direction });

  nodes.forEach(node => {
    dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
  });

  edges.forEach(edge => {
    dagreGraph.setEdge(edge.source, edge.target);
  });

  dagre.layout(dagreGraph);

  nodes.forEach(node => {
    const nodeWithPosition = dagreGraph.node(node.id);
    node.targetPosition = isHorizontal ? Position.Left : Position.Top;
    node.sourcePosition = isHorizontal ? Position.Right : Position.Bottom;

    node.position = {
      x: (nodeWithPosition.x - nodeWidth / 2) * 1.5,
      y: (nodeWithPosition.y - nodeHeight / 2) * 1.5,
    };

    return node;
  });

  return { nodes, edges };
};

interface IBasicFlowProps {
  initialNodes: Node[];
  initialEdges: Edge[];
}

const DependenciesFlow = ({ initialNodes, initialEdges }: IBasicFlowProps) => {
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [isInteractive, setIsInteractive] = useState(false);

  useEffect(() => {
    const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(
      initialNodes,
      initialEdges,
    );
    setNodes([...layoutedNodes]);
    setEdges([...layoutedEdges]);
  }, [initialNodes, initialEdges]);

  const onConnect = useCallback(
    (params: Edge | Connection) =>
      setEdges(eds =>
        addEdge(
          { ...params, type: ConnectionLineType.SmoothStep, animated: true },
          eds,
        ),
      ),
    [],
  );

  const defaultEdgeOptions = {
    markerEnd: {
      type: MarkerType.ArrowClosed,
    },
  };

  // Enable interactions when clicking inside ReactFlow
  const onClickInside = () => setIsInteractive(true);

  // Disable interactions when clicking outside
  useEffect(() => {
    const onClickOutside = (event: MouseEvent) => {
      if (!event.target) return;
      if (!(event.target as HTMLElement).closest('.react-flow')) {
        setIsInteractive(false);
      }
    };

    document.addEventListener('click', onClickOutside);
    return () => document.removeEventListener('click', onClickOutside);
  }, []);

  // Prevent ReactFlow from capturing scroll when not interactive
  useEffect(() => {
    const flowContainer = document.querySelector('.react-flow-container');

    if (flowContainer) {
      if (!isInteractive) {
        flowContainer.addEventListener('wheel', e => e.stopPropagation(), {
          passive: false,
        });
      } else {
        flowContainer.removeEventListener('wheel', e => e.stopPropagation());
      }
    }
  }, [isInteractive]);

  return (
    <Box
      w="100%"
      h="100%"
      transition="box-shadow 0.3s ease-in-out"
      boxShadow={
        isInteractive ? '0 0 0 1px var(--chakra-colors-brand-base)' : 'none'
      }
      cursor={isInteractive ? 'default' : 'pointer'}
      onClick={onClickInside}
      rounded={'md'}
    >
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        defaultEdgeOptions={defaultEdgeOptions}
        onInit={(instance: ReactFlowInstance) =>
          setTimeout(() => instance.fitView(), 10)
        }
        zoomOnScroll={isInteractive} // Disable zoom unless clicked
        panOnDrag={isInteractive} // Disable panning unless clicked
        elementsSelectable={isInteractive} // Allow selection only when active
        selectionOnDrag={isInteractive} // Allow selection dragging only when active
      >
        <Background />
      </ReactFlow>
    </Box>
  );
};

export default DependenciesFlow;
