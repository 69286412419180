import { FieldDisplayProps } from '../../index';
import { Text } from '@chakra-ui/react';
import NullFieldDisplay from '../NullFieldDisplay';
import { displayFormattedDateAndTime } from '../../../../../utils';
import dayjs from 'dayjs';

const DateFieldDisplay = ({ field }: FieldDisplayProps) => {
  const fieldHasValue = field.value !== null;
  const unixTime = dayjs(field.value).unix();
  if (fieldHasValue) {
    return <Text>{displayFormattedDateAndTime(unixTime)}</Text>;
  }
  return <NullFieldDisplay />;
};

export default DateFieldDisplay;
