import React, { useCallback } from 'react';
import { Box, Button, Icon, Stack } from '@chakra-ui/react';
import { ArrowRightIcon } from '@heroicons/react/24/outline';
import {
  useNavigation,
  WorkflowManagerNavigationState,
} from '../../contexts/navigation/NavigationContext';
import { useWorkflow } from '../../contexts/workflow/WorkflowContext';
import UserActionButtons from '../UserActionButtons';
import {
  Execution,
  ExecutionStatus,
  Workflow,
  WorkflowUserActionForm,
} from '../../../../models/workflow';

export interface ModalActionState {
  executionCuid?: string;
  workflowCuid?: string;
  navigation?: WorkflowManagerNavigationState;
  initialDetailsTab?: string;
  userAction?: WorkflowUserActionForm;
}

export type OnModalAction = (state: ModalActionState) => void;

interface WorkflowActionButtonsProps {
  target: any;
  workflow: Workflow;
  execution?: Execution;
  onAction?: OnModalAction;
}

function WorkflowActionButtons({
  target,
  workflow,
  execution,
  onAction,
}: WorkflowActionButtonsProps) {
  const { navigate } = useNavigation();
  const { loadWorkflowExecution, canManageWorkflowExecution } = useWorkflow();

  const handleOnClickRunWorkflow = useCallback(async () => {
    if (onAction) {
      onAction({
        workflowCuid: workflow.cuid,
        navigation: WorkflowManagerNavigationState.DETAIL_ACTION_START,
      });
    } else {
      await loadWorkflowExecution(workflow.cuid);
      navigate(WorkflowManagerNavigationState.DETAIL_ACTION_START, {
        workflow,
      });
    }
  }, [navigate, workflow, loadWorkflowExecution, onAction]);

  const handleOnClickResumeWorkflow = useCallback(async () => {
    if (onAction) {
      onAction({
        workflowCuid: workflow.cuid,
        executionCuid: execution?.cuid,
        navigation: WorkflowManagerNavigationState.DETAIL,
        initialDetailsTab: 'workflow',
      });
    } else {
      await loadWorkflowExecution(workflow.cuid, execution!.cuid);
      navigate(WorkflowManagerNavigationState.DETAIL, {
        workflow,
        execution,
        initialDetailsTab: 'workflow',
      });
    }
  }, [navigate, workflow, execution, loadWorkflowExecution, onAction]);

  const handleOnClickRescheduleWorkflow = useCallback(async () => {
    if (onAction) {
      onAction({
        executionCuid: execution?.cuid,
        workflowCuid: workflow.cuid,
        navigation:
          WorkflowManagerNavigationState.DETAIL_ACTION_EXECUTION_RESCHEDULE,
      });
    } else {
      await loadWorkflowExecution(workflow.cuid, execution?.cuid);
      navigate(
        WorkflowManagerNavigationState.DETAIL_ACTION_EXECUTION_RESCHEDULE,
        {
          workflow,
          execution,
        },
      );
    }
  }, [navigate, workflow, execution, loadWorkflowExecution, onAction]);

  // Stop event propagation to prevent parent element's click event
  const handleBoxClick = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  return (
    <Box onClick={handleBoxClick} data-testid="workflow-actions">
      {!execution && canManageWorkflowExecution && (
        <Button
          onClick={handleOnClickRunWorkflow}
          leftIcon={<Icon as={ArrowRightIcon} boxSize={4} />}
        >
          Run Workflow
        </Button>
      )}

      {/* Action buttons for existing execution */}
      {execution && (
        <Stack>
          {/* User Action Buttons */}
          {execution.status === ExecutionStatus.ACTIVE && (
            <UserActionButtons
              workflow={workflow}
              execution={execution}
              onAction={onAction}
            />
          )}

          {/* Reschedule button - only for scheduled executions */}
          {execution.status === ExecutionStatus.SCHEDULED &&
            canManageWorkflowExecution && (
              <Button
                onClick={handleOnClickRescheduleWorkflow}
                leftIcon={<Icon as={ArrowRightIcon} boxSize={4} />}
              >
                Reschedule
              </Button>
            )}

          {/* Resume button - only for waiting executions */}
          {execution.status === ExecutionStatus.WAITING &&
            canManageWorkflowExecution && (
              <Button
                onClick={handleOnClickResumeWorkflow}
                leftIcon={<Icon as={ArrowRightIcon} boxSize={4} />}
              >
                Resume Workflow
              </Button>
            )}
        </Stack>
      )}
    </Box>
  );
}

export default WorkflowActionButtons;
