import { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
} from '@chakra-ui/react';
import { ArrowsRightLeftIcon } from '@heroicons/react/24/outline';
import { WorkflowManagerProvider } from '../../providers/WorkflowManagerProvider';
import { ModalProvider, useModal } from '../../contexts/modal/ModalContext';
import {
  useNavigation,
  WorkflowManagerNavigationState,
} from '../../contexts/navigation/NavigationContext';
import WorkflowsList from '../WorkflowsList';
import WorkflowDetails from '../WorkflowDetails';
import WorkflowBreadcrumb from '../Breadcrumb';
import { useWorkflow } from '../../contexts/workflow/WorkflowContext';
import { WorkflowUserActionForm } from '../../../../models/workflow';
import { useUserAction } from '../../contexts/userAction/UserActionContext';
import { LoadingContainer } from '../../../LoadingContainer';

interface WorkflowManagerModalProps {
  target: any;
  workflowCuid?: string;
  executionCuid?: string;
  variant?: 'show-all-workflows-btn';
  onClose?: () => void;
  isOpen?: boolean;
  initialDetailsTab?: string;
  initialListingTab?: string;
  navigation?: WorkflowManagerNavigationState;
  userAction?: WorkflowUserActionForm;
}

// Internal Modal Content Component
const WorkflowManagerModalContent = ({
  target,
  workflowCuid,
  executionCuid,
  initialDetailsTab,
  initialListingTab,
  navigation: initialNavigation,
  userAction: initialUserAction,
}: Omit<WorkflowManagerModalProps, 'variant' | 'isOpen' | 'onClose'>) => {
  const { currentView, navigate } = useNavigation();
  const { footerContent } = useModal();
  const { loadWorkflowExecution, clearWorkflowData } = useWorkflow();
  const { setCurrentAction } = useUserAction();
  const [isInitialLoading, setIsInitialLoading] = useState(true);

  // Handle initial data loading and navigation
  useEffect(() => {
    const loadInitialData = async () => {
      clearWorkflowData();
      try {
        await loadWorkflowExecution(workflowCuid!, executionCuid);

        // Set initial user action if provided
        if (initialUserAction) {
          setCurrentAction(initialUserAction);
        }

        // Navigate after data is loaded
        if (initialNavigation) {
          navigate(initialNavigation, {
            workflowCuid,
            executionCuid,
            initialDetailsTab,
            initialListingTab,
            userAction: initialUserAction,
            fromStandalone: true, // Mark as from standalone
          });
        } else if (executionCuid || workflowCuid) {
          navigate(WorkflowManagerNavigationState.DETAIL, {
            workflowCuid,
            executionCuid,
            initialDetailsTab,
            fromStandalone: true, // Mark as from standalone
          });
        }
      } catch (error) {
        console.error('Error loading initial data:', error);
      } finally {
        setIsInitialLoading(false);
      }
    };

    if (workflowCuid || executionCuid) {
      loadInitialData();
    } else {
      setIsInitialLoading(false);
    }
  }, [executionCuid, workflowCuid, initialNavigation, initialUserAction]);

  if (isInitialLoading) {
    return <LoadingContainer isLoading={true} />;
  }

  return (
    <>
      <ModalHeader>
        <WorkflowBreadcrumb />
      </ModalHeader>
      <ModalBody data-testid="workflow-modal">
        {currentView === WorkflowManagerNavigationState.LIST ? (
          <WorkflowsList
            target={target}
            initialListingTab={initialListingTab}
          />
        ) : (
          <WorkflowDetails
            target={target}
            initialDetailsTab={initialDetailsTab}
          />
        )}
      </ModalBody>
      {footerContent && <ModalFooter>{footerContent}</ModalFooter>}
    </>
  );
};

// Button component for "See all workflows"
const ModalButton = ({
  onOpen,
  disabled,
}: {
  onOpen: () => void;
  disabled?: boolean;
}) => (
  <Stack>
    <Button
      onClick={onOpen}
      variant="ghost"
      leftIcon={<Icon as={ArrowsRightLeftIcon} boxSize={6} />}
      isDisabled={disabled}
    >
      See all workflows
    </Button>
  </Stack>
);

// Main Modal Component
function WorkflowManagerModal({
  variant,
  target,
  workflowCuid,
  executionCuid,
  onClose,
  isOpen: isInitiallyOpen = false,
  initialDetailsTab,
  initialListingTab,
  navigation,
  userAction,
}: WorkflowManagerModalProps) {
  // Local state for tracking modal open/close
  const [isControlledOpen, setIsControlledOpen] = useState(isInitiallyOpen);

  // Update local state when prop changes
  useEffect(() => {
    setIsControlledOpen(isInitiallyOpen);
  }, [isInitiallyOpen]);

  // Handle close event
  const handleClose = useCallback(() => {
    setIsControlledOpen(false);
    if (onClose) {
      onClose();
    }
  }, [onClose]);

  // Handle open event for button variant
  const handleOpen = useCallback(() => {
    setIsControlledOpen(true);
  }, []);

  // Render button if variant is specified
  if (variant === 'show-all-workflows-btn') {
    return (
      <>
        <ModalButton onOpen={handleOpen} disabled={isControlledOpen} />
        {isControlledOpen && (
          <Modal
            isOpen={true}
            onClose={handleClose}
            isCentered
            size="6xl"
            closeOnOverlayClick={false}
          >
            <ModalOverlay />
            <ModalContent>
              <ModalCloseButton />
              <WorkflowManagerProvider target={target}>
                <WorkflowManagerModalContent
                  target={target}
                  workflowCuid={workflowCuid}
                  executionCuid={executionCuid}
                  initialDetailsTab={initialDetailsTab}
                  initialListingTab={initialListingTab}
                  navigation={navigation}
                  userAction={userAction}
                />
              </WorkflowManagerProvider>
            </ModalContent>
          </Modal>
        )}
      </>
    );
  }

  // Regular modal rendering
  return isControlledOpen ? (
    <Modal
      isOpen={true}
      onClose={handleClose}
      isCentered
      size="6xl"
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <WorkflowManagerProvider target={target}>
          <ModalProvider>
            <WorkflowManagerModalContent
              target={target}
              workflowCuid={workflowCuid}
              executionCuid={executionCuid}
              initialDetailsTab={initialDetailsTab}
              initialListingTab={initialListingTab}
              navigation={navigation}
              userAction={userAction}
            />
          </ModalProvider>
        </WorkflowManagerProvider>
      </ModalContent>
    </Modal>
  ) : null;
}

export default WorkflowManagerModal;
