import {
  Badge,
  Box,
  Button,
  Code,
  HStack,
  Link,
  Tag,
  TagLeftIcon,
  Text,
  Tooltip,
  VStack,
  Center,
} from '@chakra-ui/react';
import {
  ArrowForwardIcon,
  Icon,
  InfoOutlineIcon,
  SettingsIcon,
} from '@chakra-ui/icons';
import {
  ArrowsRightLeftIcon,
  ChatBubbleLeftIcon,
  CheckIcon,
  CubeIcon,
  ExclamationTriangleIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { LinkProps } from '@chakra-ui/layout';
import React, { useContext } from 'react';
import _ from 'lodash';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { TEvent } from '../../../models/event';
import { getModelDocumentType } from '../../../models/model_document';
import {
  displayFormattedDateAndTime,
  displayRelativeDate,
  removeAfterDot,
} from '../../../utils';
import UsersContext from '../../../contexts/UsersContext';
import { DOCUMENTATION_SECTION_STATUS } from '../../DocumentSectionStatusSelect';
import AvatarProxy from '../../AvatarProxy';

import AttestationStatus from '../../AttestationSidebar/AttestationStatus';

const getEventUserNameDisplay = (userName: string) => {
  const { currentUser: user } = useContext(UsersContext);
  if (user?.name === userName) {
    return 'You';
  }
  return userName;
};
// Helper to check if we are rendering events inside a model inventory path
const isModelInventoryPath = () => {
  const currentPath = window.location.pathname;
  return currentPath.match(/^\/model-inventory\/\w+/) !== null;
};

interface EventRowLayoutProps {
  event: TEvent;
  circles?: JSX.Element[];
  children: any;
  icon?: React.ComponentType<any>;
  actorName: string;
}

function EventRowLayout({
  event,
  circles,
  children,
  icon,
  actorName,
}: EventRowLayoutProps) {
  const navigate = useNavigate();
  let avatars = [];
  if (!circles) {
    avatars.push(
      <InfoOutlineIcon
        key={'info'}
        fontSize={20}
        color={'neutral.300'}
        ml={1}
        pr={0.5}
      />,
    );
  } else {
    avatars = circles;
  }
  const [isOpen, setIsOpen] = React.useState(false);
  const handleModelClick = () => {
    navigate(event.context.on_inventory_model.url);
  };
  return (
    <HStack
      alignItems={'flex-start'}
      position={'relative'}
      sx={{
        '&:after': {
          left: '0.9rem',
          width: '2px',
          height: '100%',
          content: '" "',
          'z-index': '0',
          position: 'absolute',
          background: 'neutral.200',
        },
        '&:last-child:after': {
          background: 'transparent',
        },
      }}
      data-testid="activity-feed-widget-item"
    >
      <VStack gap={0} zIndex={1}>
        <Box
          bg={icon ? 'neutral.200' : 'transparent'}
          rounded={'full'}
          p={1}
          ml={0.5}
        >
          <Center>
            {icon ? (
              <Icon as={icon} boxSize={5} color={'neutral.600'} />
            ) : (
              <Box
                boxSize={2}
                rounded={'full'}
                bg={'neutral.200'}
                mt={1}
                ml={'0.35rem'}
              ></Box>
            )}
          </Center>
        </Box>
      </VStack>
      <VStack align={'stretch'} gap={1} pt={0.5}>
        {children}
        <HStack
          color={'neutral.500'}
          gap={1}
          alignItems={'center'}
          pb={6}
          fontSize={'sm'}
        >
          <Text>{actorName}</Text>
          <Text>•</Text>
          {!isModelInventoryPath() && event.inventory_model && (
            <>
              <Button
                size={'xs'}
                colorScheme="neutral"
                variant={'link'}
                fontSize={'sm'}
                fontWeight={'normal'}
                leftIcon={<Icon boxSize={4} as={CubeIcon} />}
                onClick={handleModelClick}
              >
                {event.inventory_model.name}
              </Button>
              <Text>•</Text>
            </>
          )}
          <Tooltip
            label={displayFormattedDateAndTime(event.created_at)}
            hasArrow
          >
            <Text>{displayRelativeDate(event.created_at)}</Text>
          </Tooltip>
        </HStack>
        {/* <LinkBox onClick={() => setIsOpen(!isOpen)} cursor={'pointer'}></LinkBox> */}

        {/*<Collapse in={isOpen}>
        <Box>
        <Text>Hello</Text>
        </Box>
        </Collapse>*/}
      </VStack>
    </HStack>
  );
}

interface IVerbRenderer {
  [key: string]: (event: TEvent) => JSX.Element;
}

interface IActionObjectTypeMap {
  [key: string]: IVerbRenderer;
}

const devFrameworkIcon = (
  <Icon as={SettingsIcon} boxSize={6} color={'neutral.200'} />
);

/**
 * Extracts the metadata label from the event display name. This event can be associated
 * with metadata updates or metadata comments (annotations).
 *
 * We do this because in some cases the event might be related to a comment addedd to a
 * test driven block where the associated target is a text block. In this case we want
 * to display the test description title
 */
function getMetadataEventLabel(event: any, contextKey: string = 'target') {
  const defaultLabel = event.context[contextKey]!.displayName.split('.').pop();
  const eventUrl = event.context[contextKey]?.url;
  if (!eventUrl) {
    return defaultLabel;
  }

  // Remove query part as well
  const pathParts = eventUrl.split('?')[0].split('/');
  const contentBlockParts = pathParts[pathParts.length - 1].replace('-', '_');

  // If contentBlockParts starts with "test-description:" then the link belongs to a text block
  // inside a test driven block. The `content_id` is the last part of the path after the colon.
  const isTextBlock = contentBlockParts.startsWith('test_description:');

  if (!isTextBlock) {
    return defaultLabel;
  }

  const testTitle = contentBlockParts.split('.').pop();
  return `Description for ${_.startCase(testTitle)}`;
}

const getRenderers = (disableLinks: boolean): IActionObjectTypeMap => {
  const getSafeLink = (label: string, to?: string, linkProps?: LinkProps) => {
    if (!linkProps) {
      linkProps = { fontWeight: 'bold' };
    }
    if (to && !disableLinks) {
      return (
        <Link as={RouterLink} to={to} {...linkProps}>
          {label}
        </Link>
      );
    }
    return <Text>{label}</Text>;
  };

  const inventoryModelNameSuffix = (event: TEvent) => {
    if (!isModelInventoryPath()) {
      return (
        <span>
          on{' '}
          {getSafeLink(
            event.inventory_model.name,
            `/model-inventory/${event.inventory_model.cuid}/overview`,
          )}
        </span>
      );
    }
    return '';
  };

  return {
    InventoryModel: {
      created: (event: TEvent) => {
        return (
          <EventRowLayout
            event={event}
            icon={CubeIcon}
            actorName={event.context.actor.displayName}
            circles={[
              <AvatarProxy
                borderWidth={0}
                key={event.context.actor.displayName}
                name={event.context.actor.displayName}
                src={event.context.actor.picture}
              />,
            ]}
          >
            <Text>
              {getEventUserNameDisplay(event.context.actor.displayName)} added{' '}
              {getSafeLink(event.context.link!.label, event.context.link!.url)}{' '}
              to the model inventory.
            </Text>
            <Link></Link>
          </EventRowLayout>
        );
      },
      'model-archived': (event: TEvent) => {
        return (
          <EventRowLayout
            event={event}
            icon={CubeIcon}
            actorName={event.context.actor.displayName}
            circles={[
              <Icon
                as={SettingsIcon}
                boxSize={6}
                color={'neutral.200'}
                key={event.context.actor.displayName}
              />,
            ]}
          >
            <Text>
              {getEventUserNameDisplay(event.context.actor.displayName)}{' '}
              archived{' '}
              {getSafeLink(
                event.context.action_object.displayName,
                `/model-inventory/${event.action_object.cuid}/overview`,
              )}{' '}
              from the model inventory
            </Text>
          </EventRowLayout>
        );
      },
      'model-deleted': (event: TEvent) => {
        return (
          <EventRowLayout
            event={event}
            actorName={event.context.actor.displayName}
            circles={[
              <AvatarProxy
                borderWidth={0}
                key={event.context.actor.displayName}
                name={event.context.actor.displayName}
                src={event.context.actor.picture}
              />,
            ]}
          >
            <Text>
              {getEventUserNameDisplay(event.context.actor.displayName)} deleted{' '}
              {event.context.action_object.displayName}
              from the model inventory
            </Text>
          </EventRowLayout>
        );
      },
      'model-restored': (event: TEvent) => {
        return (
          <EventRowLayout
            event={event}
            actorName={event.context.actor.displayName}
            circles={[
              <AvatarProxy
                borderWidth={0}
                key={event.context.actor.displayName}
                name={event.context.actor.displayName}
                src={event.context.actor.picture}
              />,
            ]}
          >
            <Text>
              {getEventUserNameDisplay(event.context.actor.displayName)}{' '}
              restored{' '}
              {getSafeLink(
                event.context.action_object.displayName,
                `/model-inventory/${event.action_object.cuid}/overview`,
              )}{' '}
              to the model inventory
            </Text>
          </EventRowLayout>
        );
      },
      'updated-field': (event: TEvent) => {
        return (
          <EventRowLayout
            event={event}
            actorName={event.context.actor.displayName}
            circles={[
              <AvatarProxy
                borderWidth={0}
                key={event.context.actor.displayName}
                name={event.context.actor.displayName}
                src={event.context.actor.picture}
              />,
            ]}
          >
            <Text>
              {getEventUserNameDisplay(event.context.actor.displayName)} updated
              field{' '}
              <Code>
                {
                  event.context.metadata.field_info.schema.properties[
                    event.context.metadata.field_key
                  ].title
                }
              </Code>{' '}
              on{' '}
              {getSafeLink(
                event.inventory_model.name,
                event.context.link?.url ||
                  `/model-inventory/${event.inventory_model.cuid}/overview`,
              )}
            </Text>
          </EventRowLayout>
        );
      },
    },
    Finding: {
      created: (event: TEvent) => {
        return (
          <EventRowLayout
            event={event}
            icon={ExclamationTriangleIcon}
            actorName={event.context.actor.displayName}
            circles={[
              <AvatarProxy
                borderWidth={0}
                key={event.context.actor.displayName}
                name={event.context.actor.displayName}
                src={event.context.actor.picture}
              />,
            ]}
          >
            <Text>
              {getEventUserNameDisplay(event.context.actor.displayName)} created
              a{' '}
              {getSafeLink(
                event.context.action_object.displayName,
                event.context.action_object.url,
              )}
              {' inventory model finding '}
              {inventoryModelNameSuffix(event)}
            </Text>
          </EventRowLayout>
        );
      },
      updated: (event: TEvent) => {
        return (
          <EventRowLayout
            event={event}
            icon={ExclamationTriangleIcon}
            actorName={event.context.actor.displayName}
            circles={[
              <AvatarProxy
                borderWidth={0}
                key={event.context.actor.displayName}
                name={event.context.actor.displayName}
                src={event.context.actor.picture}
              />,
            ]}
          >
            <Text>
              {getEventUserNameDisplay(event.context.actor.displayName)} updated{' '}
              {getSafeLink(
                event.context.action_object.displayName,
                event.context.action_object.url,
              )}{' '}
              inventory model finding
            </Text>
          </EventRowLayout>
        );
      },
      deleted: (event: TEvent) => {
        return (
          <EventRowLayout
            event={event}
            icon={ExclamationTriangleIcon}
            actorName={event.context.actor.displayName}
            circles={[
              <AvatarProxy
                borderWidth={0}
                key={event.context.actor.displayName}
                name={event.context.actor.displayName}
                src={event.context.actor.picture}
              />,
            ]}
          >
            <Text>
              {getEventUserNameDisplay(event.context.actor.displayName)} deleted{' '}
              {event.context.action_object.displayName} on inventory model
              findings
            </Text>
          </EventRowLayout>
        );
      },
    },
    Metadata: {
      updated: (event: TEvent) => {
        return (
          <EventRowLayout
            event={event}
            actorName={event.context.actor.displayName}
            circles={[
              <AvatarProxy
                borderWidth={0}
                key={event.context.actor.displayName}
                name={event.context.actor.displayName}
                src={event.context.actor.picture}
              />,
            ]}
          >
            <Text>
              {getEventUserNameDisplay(event.context.actor.displayName)} updated{' '}
              {getSafeLink(
                getMetadataEventLabel(event, 'action_object'),
                event.context.action_object.url,
              )}{' '}
              on{' '}
              {event.context.action_object.contentType ===
              getModelDocumentType('documentation')
                ? 'model documentation'
                : 'validation report'}{' '}
              {inventoryModelNameSuffix(event)}
            </Text>
          </EventRowLayout>
        );
      },
      logged: (event: TEvent) => {
        return (
          <EventRowLayout
            event={event}
            actorName={
              event.actor.type === 'User'
                ? event.context.actor.displayName
                : 'Library'
            }
            circles={[
              event.actor.type == 'User' ? (
                <AvatarProxy
                  borderWidth={0}
                  key={event.context.actor.displayName}
                  name={event.context.actor.displayName}
                  src={event.context.actor.picture}
                />
              ) : (
                devFrameworkIcon
              ),
            ]}
          >
            <Text>
              {event.actor.type === 'User'
                ? event.context.actor.displayName
                : 'Library'}{' '}
              logged a new description for Test{' '}
              <Tooltip label={event.context.action_object.displayName} hasArrow>
                <Code>
                  {event.context.action_object.displayName.split('.').pop()}
                </Code>
              </Tooltip>{' '}
              {inventoryModelNameSuffix(event)}
            </Text>
          </EventRowLayout>
        );
      },
    },
    Annotation: {
      annotated: (event: TEvent) => {
        return (
          <EventRowLayout
            event={event}
            icon={ChatBubbleLeftIcon}
            actorName={event.context.actor.displayName}
            circles={[
              <AvatarProxy
                borderWidth={0}
                key={event.context.actor.displayName}
                name={event.context.actor.displayName}
                src={event.context.actor.picture}
              />,
            ]}
          >
            <Text>
              {getEventUserNameDisplay(event.context.actor.displayName)}{' '}
              commented on{' '}
              {getSafeLink(
                getMetadataEventLabel(event),
                event.context.target?.url,
              )}{' '}
              {inventoryModelNameSuffix(event)}
            </Text>
          </EventRowLayout>
        );
      },
      'annotated-resolved': (event: TEvent) => {
        return (
          <EventRowLayout
            event={event}
            actorName={event.context.actor.displayName}
            circles={[
              <AvatarProxy
                borderWidth={0}
                key={event.context.actor.displayName}
                name={event.context.actor.displayName}
                src={event.context.actor.picture}
              />,
            ]}
          >
            <Text>
              {getEventUserNameDisplay(event.context.actor.displayName)} marked
              annotation as resolved on{' '}
              {getSafeLink(
                event.context.target!.displayName,
                event.context.target?.url,
              )}{' '}
              {inventoryModelNameSuffix(event)}
            </Text>
          </EventRowLayout>
        );
      },
      'annotated-restored': (event: TEvent) => {
        return (
          <EventRowLayout
            event={event}
            actorName={event.context.actor.displayName}
            circles={[
              <AvatarProxy
                borderWidth={0}
                key={event.context.actor.displayName}
                name={event.context.actor.displayName}
                src={event.context.actor.picture}
              />,
            ]}
          >
            <Text>
              {getEventUserNameDisplay(event.context.actor.displayName)}{' '}
              restored annotation on{' '}
              {getSafeLink(
                event.context.target!.displayName,
                event.context.target?.url,
              )}{' '}
              {inventoryModelNameSuffix(event)}
            </Text>
          </EventRowLayout>
        );
      },
    },
    AnnotationCommentMention: {
      'mentioned-in-comments': (event: TEvent) => {
        const { currentUser } = useContext(UsersContext);
        const sameAuthor =
          event.context.actor.displayName === currentUser!.name;
        const mentionedIsMe =
          event.context.action_object.user.displayName === currentUser!.name;

        let circles = [
          <AvatarProxy
            borderWidth={0}
            key={event.context.actor.cuid}
            name={event.context.actor.displayName}
            src={event.context.actor.picture}
          />,
        ];

        if (!sameAuthor) {
          circles.push(
            <AvatarProxy
              borderWidth={0}
              key={event.context.actor.displayName}
              name={event.context.action_object.user.displayName}
              src={event.context.action_object.user.picture}
            />,
          );
        }

        return (
          <EventRowLayout
            event={event}
            actorName={event.context.actor.displayName}
            circles={circles}
          >
            <Text>
              {getEventUserNameDisplay(event.context.actor.displayName)}{' '}
              mentioned{' '}
              {mentionedIsMe
                ? 'to you'
                : `to ${event.context.action_object.user.displayName}`}
              {' in a comment on '}
              {getSafeLink(
                event.context.target!.displayName,
                event.context.target?.url,
              )}{' '}
            </Text>
          </EventRowLayout>
        );
      },
    },
    AnnotationComment: {
      replied: (event: TEvent) => {
        const sameAuthor =
          event.context.action_object.displayName ===
          event.context.actor.displayName;
        let circles = [
          <AvatarProxy
            borderWidth={0}
            key={event.context.actor.cuid}
            name={event.context.actor.displayName}
            src={event.context.actor.picture}
          />,
        ];

        if (!sameAuthor) {
          circles.push(
            <AvatarProxy
              borderWidth={0}
              key={event.context.actor.displayName}
              name={event.context.action_object.displayName}
              src={event.context.action_object.picture}
            />,
          );
        }

        return (
          <EventRowLayout
            event={event}
            actorName={event.context.actor.displayName}
            circles={circles}
          >
            <Text>
              {getEventUserNameDisplay(event.context.actor.displayName)} replied
              to a comment{' '}
              {sameAuthor
                ? ''
                : `to ${event.context.action_object.displayName}`}{' '}
              on{' '}
              {getSafeLink(
                event.context.target!.displayName,
                event.context.target?.url,
              )}{' '}
            </Text>
          </EventRowLayout>
        );
      },
    },
    Suggestion: {
      suggested: (event: TEvent) => {
        return (
          <EventRowLayout
            event={event}
            actorName={event.context.actor.displayName}
            circles={[
              <AvatarProxy
                borderWidth={0}
                key={event.context.actor.displayName}
                name={event.context.actor.displayName}
                src={event.context.actor.picture}
              />,
            ]}
          >
            <Text>
              {getEventUserNameDisplay(event.context.actor.displayName)}{' '}
              suggested content "{event.context.metadata?.type}" on{' '}
              {getSafeLink(
                event.context.target!.displayName,
                event.context.target?.url,
              )}{' '}
            </Text>
          </EventRowLayout>
        );
      },
      'suggestion-state-changed': (event: TEvent) => {
        return (
          <EventRowLayout
            event={event}
            actorName={event.context.actor.displayName}
            circles={[
              <AvatarProxy
                borderWidth={0}
                key={event.context.actor.displayName}
                name={event.context.actor.displayName}
                src={event.context.actor.picture}
              />,
            ]}
          >
            <Text>
              {getEventUserNameDisplay(event.context.actor.displayName)} "
              {event.context.metadata.to_state}" suggestion on{' '}
              {getSafeLink(
                event.context.target!.displayName,
                event.context.target?.url,
              )}{' '}
            </Text>
          </EventRowLayout>
        );
      },
    },
    TestRun: {
      'run-started': (event: TEvent) => {
        return (
          <EventRowLayout
            event={event}
            actorName={
              event.actor.type === 'User'
                ? event.context.actor.displayName
                : 'Library'
            }
            circles={[
              event.actor.type == 'User' ? (
                <AvatarProxy
                  borderWidth={0}
                  key={event.context.actor.displayName}
                  name={event.context.actor.displayName}
                  src={event.context.actor.picture}
                />
              ) : (
                devFrameworkIcon
              ),
            ]}
          >
            <Text>
              {event.actor.type === 'User'
                ? event.context.actor.displayName
                : 'Library'}{' '}
              started a new test run{' '}
              <Code>({event.context.action_object.displayName})</Code>
            </Text>{' '}
          </EventRowLayout>
        );
      },
    },
    TestResult: {
      logged: (event: TEvent) => {
        return (
          <EventRowLayout
            event={event}
            actorName={
              event.actor.type === 'User'
                ? event.context.actor.displayName
                : 'Library'
            }
            circles={[
              event.actor.type == 'User' ? (
                <AvatarProxy
                  borderWidth={0}
                  key={event.context.actor.displayName}
                  name={event.context.actor.displayName}
                  src={event.context.actor.picture}
                />
              ) : (
                devFrameworkIcon
              ),
            ]}
          >
            <Text>
              {event.actor.type === 'User'
                ? event.context.actor.displayName
                : 'Library'}{' '}
              logged new results for Test{' '}
              <Tooltip label={event.context.action_object.displayName} hasArrow>
                <Code>
                  {event.context.action_object.displayName.split('.').pop()}
                </Code>
              </Tooltip>{' '}
            </Text>
          </EventRowLayout>
        );
      },
    },
    Dataset: {
      logged: (event: TEvent) => {
        return (
          <EventRowLayout
            event={event}
            actorName={
              event.actor.type === 'User'
                ? event.context.actor.displayName
                : 'Library'
            }
            circles={[
              event.actor.type == 'User' ? (
                <AvatarProxy
                  borderWidth={0}
                  key={event.context.actor.displayName}
                  name={event.context.actor.displayName}
                  src={event.context.actor.picture}
                />
              ) : (
                devFrameworkIcon
              ),
            ]}
          >
            <Text>
              {event.actor.type === 'User'
                ? event.context.actor.displayName
                : 'Library'}{' '}
              logged{' '}
              <Tooltip label={event.context.action_object.displayName} hasArrow>
                <Code>
                  {event.context.action_object.displayName.split('.').pop()}
                </Code>
              </Tooltip>
            </Text>
          </EventRowLayout>
        );
      },
    },
    Approval: {
      'started-approval': (event: TEvent) => {
        return (
          <EventRowLayout
            event={event}
            icon={ArrowsRightLeftIcon}
            actorName={event.context.actor.displayName}
            circles={[
              <AvatarProxy
                borderWidth={0}
                key={event.context.actor.displayName}
                name={event.context.actor.displayName}
                src={event.context.actor.picture}
              />,
            ]}
          >
            <Text>
              Approval step started on workflow
              {event.context.metadata.workflow && (
                <Tag>{event.context.metadata.workflow.title}</Tag>
              )}
            </Text>
          </EventRowLayout>
        );
      },
      'closed-approval': (event: TEvent) => {
        return (
          <EventRowLayout
            event={event}
            actorName={event.context.actor.displayName}
            circles={[
              <AvatarProxy
                borderWidth={0}
                key={event.context.actor.displayName}
                name={event.context.actor.displayName}
                src={event.context.actor.picture}
              />,
            ]}
          >
            <Text>
              The approval process for workflow{' '}
              {event.context.metadata.workflow && (
                <Tag>{event.context.metadata.workflow.title}</Tag>
              )}{' '}
              ended. The outcome was{' '}
              <Badge
                colorScheme={
                  event.context.metadata.approval.status === 'approved'
                    ? 'green'
                    : 'red'
                }
              >
                <HStack gap={1} alignItems={'flex-start'}>
                  <Icon
                    as={
                      event.context.metadata.approval.status === 'approved'
                        ? CheckIcon
                        : XMarkIcon
                    }
                    boxSize={4}
                  />
                  <Text>{event.context.metadata.approval.status}</Text>
                </HStack>
              </Badge>
            </Text>
          </EventRowLayout>
        );
      },
    },
    ApprovalVoter: {
      'voted-approval': (event: TEvent) => {
        return (
          <EventRowLayout
            event={event}
            actorName={event.context.actor.displayName}
            circles={[
              <AvatarProxy
                borderWidth={0}
                key={event.context.actor.displayName}
                name={event.context.actor.displayName}
                src={event.context.actor.picture}
              />,
            ]}
          >
            <Text>
              A new vote was added to the approval process for workflow{' '}
              {event.context.metadata.workflow && (
                <Tag>{event.context.metadata.workflow.title}</Tag>
              )}
            </Text>
          </EventRowLayout>
        );
      },
    },
    Execution: {
      'reset-workflow': (event: TEvent) => {
        return (
          <EventRowLayout
            event={event}
            icon={ArrowsRightLeftIcon}
            actorName={event.context.action_object.displayName}
            circles={[
              <AvatarProxy
                borderWidth={0}
                key={event.context.actor.displayName}
                name={event.context.actor.displayName}
                src={event.context.actor.picture}
              />,
            ]}
          >
            <Text>
              Workflow{' '}
              <Tag>
                {event.context.metadata.execution.execution_id} -{' '}
                {event.context.metadata.workflow.title}
              </Tag>{' '}
              was reset.
            </Text>
          </EventRowLayout>
        );
      },
      'aborted-workflow': (event: TEvent) => {
        return (
          <EventRowLayout
            event={event}
            icon={ArrowsRightLeftIcon}
            actorName={event.context.action_object.displayName}
            circles={[
              <AvatarProxy
                borderWidth={0}
                key={event.context.actor.displayName}
                name={event.context.actor.displayName}
                src={event.context.actor.picture}
              />,
            ]}
          >
            <Text>
              Workflow{' '}
              <Tag>
                {event.context.metadata.execution.execution_id} -{' '}
                {event.context.metadata.workflow.title}
              </Tag>{' '}
              was aborted.
            </Text>
          </EventRowLayout>
        );
      },
      'workflow-execution-started': (event: TEvent) => {
        return (
          <EventRowLayout
            event={event}
            icon={ArrowsRightLeftIcon}
            actorName={
              event.actor.type === 'User'
                ? event.context.actor.displayName
                : 'Library'
            }
            circles={
              event.actor.type === 'User'
                ? [
                    <AvatarProxy
                      borderWidth={0}
                      key={event.context.actor.displayName}
                      name={event.context.actor.displayName}
                      src={event.context.actor.picture}
                    />,
                  ]
                : undefined
            }
          >
            <Text>
              Workflow{' '}
              <Tag>
                {event.context.metadata.execution.execution_id} -{' '}
                {event.context.metadata.workflow.title}
              </Tag>{' '}
              started.
            </Text>
          </EventRowLayout>
        );
      },
      'workflow-execution-ended': (event: TEvent) => {
        return (
          <EventRowLayout
            event={event}
            icon={ArrowsRightLeftIcon}
            actorName={
              event.actor.type === 'User'
                ? event.context.actor.displayName
                : 'Library'
            }
          >
            <Text>
              <Tag>{event.context.metadata.workflow.title}</Tag> workflow
              finalized.
            </Text>
          </EventRowLayout>
        );
      },
      'workflow-execution-scheduled': (event: TEvent) => {
        return (
          <EventRowLayout
            event={event}
            icon={ArrowsRightLeftIcon}
            actorName={
              event.actor.type === 'User'
                ? event.context.actor.displayName
                : 'Library'
            }
            circles={
              event.actor.type === 'User'
                ? [
                    <AvatarProxy
                      borderWidth={0}
                      key={event.context.actor.displayName}
                      name={event.context.actor.displayName}
                      src={event.context.actor.picture}
                    />,
                  ]
                : undefined
            }
          >
            <Text>
              Workflow <Tag>{event.context.metadata.workflow.title}</Tag> was
              scheduled to start on{' '}
              {displayFormattedDateAndTime(event.context.metadata.scheduled_at)}.
            </Text>
          </EventRowLayout>
        );
      },
    },
    StateHistory: {
      'transitioned-workflow-state': (event: TEvent) => {
        return (
          <EventRowLayout
            event={event}
            icon={ArrowsRightLeftIcon}
            actorName={event.context.actor.displayName}
            circles={[
              <AvatarProxy
                borderWidth={0}
                key={event.context.actor.displayName}
                name={event.context.actor.displayName}
                src={event.context.actor.picture}
              />,
            ]}
          >
            <Text>
              The workflow state of{' '}
              <Tag>
                {event.context.metadata.execution.execution_id} -{' '}
                {event.context.metadata.workflow.title}
              </Tag>{' '}
              was updated to{' '}
              <Tag
                data-testid="status-badge"
                colorScheme={removeAfterDot(
                  event.context.metadata.transition.to_state.colors.primary,
                )}
                variant={'outline'}
              >
                <TagLeftIcon boxSize={4} as={ArrowsRightLeftIcon} />
                {event.context.metadata.transition.to_state.name}
              </Tag>
            </Text>
          </EventRowLayout>
        );
      },
    },
    StatusesWorkflowTransitionHistory: {
      'transitioned-status': (event: TEvent) => {
        return (
          <EventRowLayout
            event={event}
            icon={CubeIcon}
            actorName={event.context.actor.displayName}
            circles={[
              <AvatarProxy
                borderWidth={0}
                key={event.context.actor.displayName}
                name={event.context.actor.displayName}
                src={event.context.actor.picture}
              />,
            ]}
          >
            <Text>
              Model stage was updated to{' '}
              <Tag
                data-testid="status-badge"
                colorScheme={removeAfterDot(
                  event.context.action_object.transition.to_status.colors
                    .primary,
                )}
              >
                <TagLeftIcon boxSize={4} as={CubeIcon} />
                {event.context.action_object.transition.to_status.name}
              </Tag>{' '}
            </Text>
          </EventRowLayout>
        );
      },
    },
    StatusesWorkflowTransition: {
      'inventory-model-transitioned-status': (event: TEvent) => {
        return (
          <EventRowLayout
            event={event}
            icon={CubeIcon}
            actorName={event.context.actor.displayName}
            circles={[
              <AvatarProxy
                borderWidth={0}
                key={event.context.actor.displayName}
                name={event.context.actor.displayName}
                src={event.context.actor.picture}
              />,
            ]}
          >
            <Text>
              The model stage was updated to{' '}
              <Tag
                data-testid="status-badge"
                colorScheme={removeAfterDot(
                  event.context.action_object.transition.from_status.colors
                    .primary,
                )}
              >
                <TagLeftIcon boxSize={4} as={CubeIcon} />
                {event.context.action_object.transition.to_status.name}
              </Tag>{' '}
            </Text>
          </EventRowLayout>
        );
      },
    },
    TemplateVersion: {
      created: (event: TEvent) => {
        return (
          <EventRowLayout
            event={event}
            actorName={event.context.actor.displayName}
            circles={[
              <AvatarProxy
                borderWidth={0}
                key={event.context.actor.displayName}
                name={event.context.actor.displayName}
                src={event.context.actor.picture}
              />,
            ]}
          >
            <Text>
              {getEventUserNameDisplay(event.context.actor.displayName)} created
              anew version of template{' '}
              {getSafeLink(
                event.context.target!.displayName,
                event.context.target?.url,
              )}{' '}
            </Text>
          </EventRowLayout>
        );
      },
      'swapped-template': (event: TEvent) => {
        return (
          <EventRowLayout
            event={event}
            actorName={event.context.actor.displayName}
            circles={[
              <AvatarProxy
                borderWidth={0}
                key={event.context.actor.displayName}
                name={event.context.actor.displayName}
                src={event.context.actor.picture}
              />,
            ]}
          >
            <Text>
              {getEventUserNameDisplay(event.context.actor.displayName)} swapped
              template from {event.context.action_object!.displayName} to{' '}
              {event.context.target!.displayName}{' '}
            </Text>
          </EventRowLayout>
        );
      },
    },
    DocumentSectionStatus: {
      updated: (event: TEvent) => {
        let circles = [
          <AvatarProxy
            borderWidth={0}
            key={event.context.actor.cuid}
            name={event.context.actor.displayName}
            src={event.context.actor.picture}
          />,
        ];
        const fromStatusObject =
          event.context.action_object.fromStatus === 'In Progress'
            ? DOCUMENTATION_SECTION_STATUS[0]
            : DOCUMENTATION_SECTION_STATUS[1];
        const toStatusObject =
          event.context.action_object.toStatus === 'In Progress'
            ? DOCUMENTATION_SECTION_STATUS[0]
            : DOCUMENTATION_SECTION_STATUS[1];

        return (
          <EventRowLayout
            event={event}
            actorName={event.context.actor.displayName}
            circles={circles}
          >
            <Text>
              {getEventUserNameDisplay(event.context.actor.displayName)} updated{' '}
              {getSafeLink(
                event.context.action_object.sectionName,
                event.context.action_object?.url,
              )}{' '}
              section status from{' '}
              <Tag
                data-testid="status-badge"
                colorScheme={removeAfterDot(fromStatusObject.bgColor)}
              >
                {event.context.action_object.fromStatus}
              </Tag>{' '}
              <ArrowForwardIcon />
              <Tag
                data-testid="status-badge"
                colorScheme={removeAfterDot(toStatusObject.bgColor)}
              >
                {event.context.action_object.toStatus}
              </Tag>
            </Text>
          </EventRowLayout>
        );
      },
    },
    InventoryModelSchema: {
      'updated-schema': (event: TEvent) => {
        const operation = event.context.metadata.operation;
        // Remove operations are handled differently
        const fieldLabel =
          operation === 'remove'
            ? event.context.metadata.properties.settings.label
            : event.context.metadata.field?.label;

        return (
          <EventRowLayout
            event={event}
            actorName={event.context.actor.displayName}
            circles={[
              <AvatarProxy
                borderWidth={0}
                key={event.context.actor.displayName}
                name={event.context.actor.displayName}
                src={event.context.actor.picture}
              />,
            ]}
          >
            <Text>
              {getEventUserNameDisplay(event.context.actor.displayName)}{' '}
              {operation === 'add'
                ? 'added'
                : operation === 'remove'
                ? 'removed'
                : 'updated'}{' '}
              field <Code>{fieldLabel}</Code>{' '}
              {operation === 'remove' ? 'from' : 'to'} the{' '}
              {getSafeLink('Model Inventory', event.context.link?.url)}
            </Text>
          </EventRowLayout>
        );
      },
    },
    UserRole: {
      created: (event: TEvent) => {
        return (
          <EventRowLayout
            event={event}
            actorName={event.context.actor.displayName}
            circles={[
              <AvatarProxy
                borderWidth={0}
                key={event.context.actor.displayName}
                name={event.context.actor.displayName}
                src={event.context.actor.picture}
              />,
            ]}
          >
            <Text>
              {getEventUserNameDisplay(event.context.actor.displayName)}{' '}
              assigned role{' '}
              <Code>{event.context.metadata.created.role.name}</Code> to{' '}
              {event.context.target?.displayName ||
                event.context.metadata.to.name}
            </Text>
          </EventRowLayout>
        );
      },
    },
    AttestationExecution: {
      updated: (event: TEvent) => {
        return (
          <EventRowLayout
            event={event}
            actorName={event.context.actor.displayName}
            circles={[
              <AvatarProxy
                borderWidth={0}
                key={event.context.actor.displayName}
                name={event.context.actor.displayName}
                src={event.context.actor.picture}
              />,
            ]}
          >
            <Text>
              {getEventUserNameDisplay(event.context.actor.displayName)}{' '}
              transitioned from{' '}
              <AttestationStatus
                attestationStatus={event.context.action_object.from_status}
              />{' '}
              to{' '}
              <AttestationStatus
                attestationStatus={event.context.action_object.to_status}
              />{' '}
              on{' '}
              <Code>
                {event.context.action_object.owner.name}'s Attestation (
                {event.context.action_object.scheduleName})
              </Code>
            </Text>
          </EventRowLayout>
        );
      },
    },
    OrganizationRole: {
      created: (event: TEvent) => {
        return (
          <EventRowLayout
            event={event}
            actorName={event.context.actor.displayName}
            circles={[
              <AvatarProxy
                borderWidth={0}
                key={event.context.actor.displayName}
                name={event.context.actor.displayName}
                src={event.context.actor.picture}
              />,
            ]}
          >
            <Text>
              {getEventUserNameDisplay(event.context.actor.displayName)} created
              a new role <Code>{event.context.action_object.displayName}</Code>{' '}
              in {event.context.target?.displayName || 'the organization'}
            </Text>
          </EventRowLayout>
        );
      },
    },
    default: {
      default: (event: TEvent) => {
        return (
          <EventRowLayout event={event} actorName={event.details}>
            <Text>{event.details}</Text>
          </EventRowLayout>
        );
      },
    },
  };
};

interface IEventRowProps {
  event: TEvent;
  disableLinks?: boolean;
}

export default function EventRow({ event, disableLinks }: IEventRowProps) {
  let eventRowDetails;

  // Validate required fields
  if (!event.action_object?.type || !event.verb) {
    console.warn('Event missing required fields:', {
      hasActionObject: !!event.action_object,
      hasActionObjectType: !!event.action_object?.type,
      hasVerb: !!event.verb,
      event: event,
    });
    return (
      <EventRowLayout event={event} actorName={event.details || 'Unknown'}>
        <Text>{event.details || 'Invalid event data'}</Text>
      </EventRowLayout>
    );
  }

  try {
    eventRowDetails = getRenderers(!!disableLinks)[
      event.action_object.type as keyof object
    ][event.verb as keyof object](event);
  } catch (KeyError) {
    console.warn('Event failed to render with specific renderer:', {
      type: event.action_object.type,
      verb: event.verb,
      event: event,
    });
    eventRowDetails = getRenderers(!!disableLinks)['default']['default'](event);
  }

  return eventRowDetails;
}

interface EventDebugViewProps {
  event: TEvent;
}

function EventDebugView({ event }: EventDebugViewProps) {
  return (
    <VStack align="stretch" spacing={2}>
      {/* Core properties */}
      <Box>
        <Text fontWeight="semibold">Core Properties:</Text>
        <Code display="block" whiteSpace="pre" p={2}>
          {JSON.stringify(
            {
              verb: event.verb,
              created_at: event.created_at,
              details: event.details,
            },
            null,
            2,
          )}
        </Code>
      </Box>
      {/* Actor info */}
      {event.actor && (
        <Box>
          <Text fontWeight="semibold">Actor:</Text>
          <Code display="block" whiteSpace="pre" p={2}>
            {JSON.stringify(event.actor, null, 2)}
          </Code>
        </Box>
      )}
      {/* Action object info */}
      {event.action_object && (
        <Box>
          <Text fontWeight="semibold">Action Object:</Text>
          <Code display="block" whiteSpace="pre" p={2}>
            {JSON.stringify(event.action_object, null, 2)}
          </Code>
        </Box>
      )}
      {/* Context info */}
      {event.context && (
        <Box>
          <Text fontWeight="semibold">Context:</Text>
          <Code display="block" whiteSpace="pre" p={2}>
            {JSON.stringify(event.context, null, 2)}
          </Code>
        </Box>
      )}
      {/* Inventory Model info */}
      {event.inventory_model && (
        <Box>
          <Text fontWeight="semibold">Inventory Model:</Text>
          <Code display="block" whiteSpace="pre" p={2}>
            {JSON.stringify(event.inventory_model, null, 2)}
          </Code>
        </Box>
      )}
    </VStack>
  );
}
