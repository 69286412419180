import { useCallback, useState } from 'react';
import {
  useStore,
  getBezierPath,
  BaseEdge,
  EdgeLabelRenderer,
  useReactFlow,
} from 'reactflow';
import { getEdgeParams } from '../../utils';
import { EdgeProps } from '@reactflow/core/dist/esm/types/edges';
import { Box, Button, Icon, Text } from '@chakra-ui/react';
import { TrashIcon } from '@heroicons/react/24/outline';
import ConfirmationAlert from '../../../../../../components/ConfirmationAlert';

function FloatingEdge({
  id,
  source,
  target,
  markerStart,
  markerEnd,
  style,
  selected,
  label,
}: EdgeProps & { label?: string }) {
  const { deleteElements } = useReactFlow();
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  const sourceNode = useStore(
    useCallback(store => store.nodeInternals.get(source), [source]),
  );
  const targetNode = useStore(
    useCallback(store => store.nodeInternals.get(target), [target]),
  );

  if (!sourceNode || !targetNode) {
    return null;
  }

  const { sx, sy, tx, ty, sourcePos, targetPos } = getEdgeParams(
    sourceNode,
    targetNode,
  );

  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX: sx,
    sourceY: sy,
    sourcePosition: sourcePos,
    targetPosition: targetPos,
    targetX: tx,
    targetY: ty,
  });

  const onRemoveEdge = (confirmed: boolean) => {
    if (confirmed) {
      deleteElements({ edges: [{ id }] });
    }
    setShowDeleteConfirm(false);
  };

  const calculateEdgeBoundingBox = () => {
    return {
      w: Math.max(Math.abs(sx - tx), 30),
      h: Math.max(Math.abs(sy - ty), 30),
    };
  };

  let _style = { ...style };
  if (selected) {
    _style = {
      ...style,
      stroke: 'var(--chakra-colors-brand-base)',
    };
  }
  return (
    <>
      <BaseEdge
        path={edgePath}
        markerStart={markerStart}
        markerEnd={markerEnd}
        id={id}
        style={_style}
      />
      <EdgeLabelRenderer>
        {/* Label Container */}
        {label && (
          <Box
            position={'absolute'}
            transform={`translate(-50%, -50%) translate(${labelX}px,${labelY}px)`}
            bg={label.trim() === 'Approved' ? 'green.200' : 'red.200'}
            py={1}
            px={2}
            rounded={'md'}
            border={'1px solid'}
            borderColor={label.trim() === 'Approved' ? 'green.300' : 'red.300'}
          >
            <Text>{label.trim()}</Text>
          </Box>
        )}
        {/* Delete Button */}
        <Button
          onClick={() => setShowDeleteConfirm(true)}
          position={'absolute'}
          transform={`translate(-50%, -50%) translate(${labelX}px,${labelY}px)`}
          rounded={'full'}
          opacity={0}
          _hover={{ opacity: 1 }}
          pointerEvents={'all'}
          transition={'all 0.3s ease-in-out'}
          aria-label="Delete"
        >
          <Icon as={TrashIcon} boxSize={4} />
        </Button>
      </EdgeLabelRenderer>
      <ConfirmationAlert
        open={showDeleteConfirm}
        onConfirm={onRemoveEdge}
        title={'Delete Connection'}
        dialogBody={'Are you sure you want to delete this connection?'}
      />
    </>
  );
}

export default FloatingEdge;
