import { useState } from 'react';
import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import { Document, Page, pdfjs } from 'react-pdf';
import {
  Box,
  Text,
  useColorMode,
  IconButton,
  Button,
  Icon,
  HStack,
  VStack,
  Divider,
  useColorModeValue,
} from '@chakra-ui/react';
import {
  MagnifyingGlassMinusIcon,
  MagnifyingGlassPlusIcon,
} from '@heroicons/react/24/solid';

interface Props {
  fileUrl: string;
}

pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@4.4.168/build/pdf.worker.min.mjs`;

const PdfViewer = ({ fileUrl }: Props) => {
  const [numPages, setNumPages] = useState<number | null>(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [scale, setScale] = useState(1);
  const { colorMode } = useColorMode();

  const zoomIn = () => setScale(prevScale => Math.min(prevScale + 0.25, 3.0)); // Max zoom level of 3x
  const zoomOut = () => setScale(prevScale => Math.max(prevScale - 0.25, 0.5)); // Min zoom level of 0.5x

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };

  const handleThumbnailClick = (pageIndex: number) => {
    setPageNumber(pageIndex);
  };

  const goToPrevPage = () =>
    setPageNumber(prevPage => Math.max(prevPage - 1, 1));
  const goToNextPage = () =>
    setPageNumber(prevPage =>
      numPages ? Math.min(prevPage + 1, numPages) : prevPage,
    );

  return (
    <HStack
      gap={4}
      w={'full'}
      alignItems={'flex-start'}
      divider={<Divider orientation="vertical" />}
    >
      {/* Sidebar with Thumbnails */}
      <Document file={fileUrl} onLoadSuccess={onDocumentLoadSuccess}>
        <VStack overflowY="auto" height="75vh" p={2} gap={4} mt={12}>
          {Array.from(new Array(numPages), (el, index) => (
            <VStack
              key={`thumb_${index + 1}`}
              cursor="pointer"
              onClick={() => handleThumbnailClick(index + 1)}
              mb={2}
              _hover={{
                opacity: 0.7,
              }}
              gap={0}
            >
              <Box
                border={'2px solid'}
                borderColor={
                  pageNumber === index + 1
                    ? 'brand.base'
                    : useColorModeValue('neutral.200', 'neutral.800')
                }
                pointerEvents={'none'}
                user-select={'none'}
              >
                <Page pageNumber={index + 1} width={100} />
              </Box>
              <Text textAlign="center" mt={1}>
                Page {index + 1}
              </Text>
            </VStack>
          ))}
        </VStack>
      </Document>

      {/* Main View */}
      <VStack w={'full'} gap={4} maxH={'80vh'}>
        <HStack justifyContent={'space-between'} w={'full'}>
          <IconButton
            aria-label={`Zoom Out`}
            icon={<Icon as={MagnifyingGlassMinusIcon} boxSize={6} />}
            onClick={zoomOut}
            disabled={scale <= 0.5}
          >
            Zoom Out
          </IconButton>
          <IconButton
            aria-label={`Zoom Out`}
            icon={<Icon as={MagnifyingGlassPlusIcon} boxSize={6} />}
            onClick={zoomIn}
            disabled={scale >= 3.0}
          ></IconButton>
        </HStack>
        <Box
          w={'full'}
          overflow={'auto'}
          maxW={'3xl'}
          border={'2px solid'}
          borderColor={'var(--chakra-colors-chakra-border-color)'}
        >
          <Document file={fileUrl}>
            <Page pageNumber={pageNumber} scale={scale} />
          </Document>
        </Box>
        {/* Paginator Buttons */}
        <HStack justifyContent="space-between" alignItems="center" w={'full'}>
          <Button onClick={goToPrevPage} hidden={pageNumber <= 1} mx={2}>
            Previous
          </Button>
          <Text>
            Current Page: {pageNumber} of {numPages}
          </Text>
          <Button
            onClick={goToNextPage}
            hidden={numPages ? pageNumber >= numPages : false}
            mx={2}
          >
            Next
          </Button>
        </HStack>
      </VStack>
    </HStack>
  );
};

export default PdfViewer;
