import {
  Box,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  useToast,
  Text,
  Input,
  VStack,
  Avatar,
  HStack,
  Tag,
  TagLabel,
  TagCloseButton,
  List,
  ListItem,
  Flex,
  Heading,
} from '@chakra-ui/react';
import { useState, useEffect, useMemo, useRef } from 'react';
import AdminAPI, { AdminUser, User } from '../../api/AdminAPI';
import { Icon } from '@chakra-ui/react';
import { PlusIcon } from '@heroicons/react/24/solid';

const AdminUsers = () => {
  const [adminUsers, setAdminUsers] = useState<AdminUser[]>([]);
  const [allUsers, setAllUsers] = useState<User[]>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState<User[]>([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  const loadAdminUsers = async () => {
    try {
      setIsLoading(true);
      const users = await AdminAPI.GetAdminUsers();
      setAdminUsers(users);
    } catch (error) {
      console.error('Error loading users:', error);
      toast({
        variant: 'subtle',
        title: 'Error loading admin users',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const loadAllUsers = async () => {
    try {
      const users = await AdminAPI.GetUsers();
      setAllUsers(users);
    } catch (error) {
      console.error('Error loading users:', error);
    }
  };

  useEffect(() => {
    loadAdminUsers();
    loadAllUsers();
  }, []);

  const filteredUsers = useMemo(() => {
    if (!searchQuery.trim()) return [];

    // Get set of existing admin user emails
    const adminEmails = new Set(
      adminUsers.map(admin => admin.email.toLowerCase()),
    );
    // Get set of selected user emails
    const selectedEmails = new Set(
      selectedUsers.map(selected => selected.email.toLowerCase()),
    );

    console.log('Filtering with:', {
      totalUsers: allUsers.length,
      adminEmails: Array.from(adminEmails),
      selectedEmails: Array.from(selectedEmails),
      searchQuery,
    });

    return allUsers.filter(user => {
      // Check if user is already an admin
      const isAdmin = adminEmails.has(user.email.toLowerCase());
      if (isAdmin) {
        console.log(`${user.name} is already admin`);
        return false;
      }

      // Check if user is already selected
      const isSelected = selectedEmails.has(user.email.toLowerCase());
      if (isSelected) {
        console.log(`${user.name} is already selected`);
        return false;
      }

      // Check if user matches search
      const matchesSearch =
        user.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
        user.name.toLowerCase().includes(searchQuery.toLowerCase());

      if (!matchesSearch) {
        console.log(`${user.name} doesn't match search`);
        return false;
      }

      return true;
    });
  }, [searchQuery, allUsers, adminUsers, selectedUsers]);

  const handleUserSelect = (user: User) => {
    setSelectedUsers([...selectedUsers, user]);
    setSearchQuery('');
    setIsDropdownOpen(false);
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const handleRemoveUser = (userToRemove: User) => {
    setSelectedUsers(
      selectedUsers.filter(user => user.cuid !== userToRemove.cuid),
    );
  };

  const handleModalClose = () => {
    setSearchQuery('');
    setSelectedUsers([]);
    setIsDropdownOpen(false);
    onClose();
  };

  const handleAddUsers = async () => {
    if (selectedUsers.length === 0) return;

    try {
      setIsLoading(true);

      // Add users sequentially
      for (const user of selectedUsers) {
        await AdminAPI.CreateAdminUser({
          user_cuid: user.cuid,
          first_name: user.first_name || '',
          last_name: user.last_name || '',
          email: user.email,
        });
      }

      // Refresh RBAC after adding admin users
      await AdminAPI.PatchRBAC('refresh');

      toast({
        variant: 'subtle',
        title: `Successfully added ${selectedUsers.length} admin user${
          selectedUsers.length > 1 ? 's' : ''
        }`,
        status: 'success',
        duration: 3000,
        isClosable: true,
      });

      handleModalClose();
      await loadAdminUsers();
    } catch (error) {
      toast({
        variant: 'subtle',
        title: 'Error adding admin users',
        description: 'Please try again',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const renderUserValue = (value: any): string => {
    if (value === null || value === undefined) return '-';
    if (typeof value === 'string') return value;
    if (typeof value === 'object') return JSON.stringify(value);
    return String(value);
  };

  const formatTimestamp = (timestamp: string | number): string => {
    try {
      // Convert Unix timestamp to milliseconds (JavaScript uses milliseconds)
      const date = new Date(Number(timestamp) * 1000);
      return date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      });
    } catch (error) {
      return '-';
    }
  };

  return (
    <Box>
      <Flex justify="space-between" align="center" mb={6}>
        <Heading>Admin Users</Heading>
        <Button
          leftIcon={<Icon as={PlusIcon} w={4} h={4} />}
          onClick={onOpen}
          variant="primary"
          size="md"
        >
          Add Admin Users
        </Button>
      </Flex>

      <Text mb={4} color="gray.600">
        Total Admin Users: {adminUsers?.length || 0}
      </Text>

      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>First Name</Th>
            <Th>Last Name</Th>
            <Th>Email</Th>
            <Th>Role</Th>
            <Th>Created At</Th>
          </Tr>
        </Thead>
        <Tbody>
          {adminUsers.map(user => (
            <Tr key={user.cuid}>
              <Td>{renderUserValue(user.first_name)}</Td>
              <Td>{renderUserValue(user.last_name)}</Td>
              <Td>{renderUserValue(user.email)}</Td>
              <Td>{renderUserValue(user.role)}</Td>
              <Td>{formatTimestamp(user.created_at)}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>

      {adminUsers.length === 0 && !isLoading && (
        <Text textAlign="center" mt={4} color="gray.500">
          No admin users found
        </Text>
      )}

      <Modal isOpen={isOpen} onClose={handleModalClose} size="md">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Admin Users</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={4} align="stretch">
              {selectedUsers.length > 0 && (
                <HStack spacing={2} flexWrap="wrap">
                  {selectedUsers.map(user => (
                    <Tag
                      key={user.cuid}
                      size="lg"
                      borderRadius="full"
                      variant="subtle"
                      colorScheme="brand"
                    >
                      <Avatar
                        src={user.picture}
                        size="xs"
                        name={user.name}
                        ml={-1}
                        mr={2}
                      />
                      <TagLabel>{user.name}</TagLabel>
                      <TagCloseButton onClick={() => handleRemoveUser(user)} />
                    </Tag>
                  ))}
                </HStack>
              )}

              <Box position="relative">
                <Input
                  ref={inputRef}
                  placeholder="Search users by name or email"
                  value={searchQuery}
                  onChange={e => {
                    setSearchQuery(e.target.value);
                    setIsDropdownOpen(true);
                  }}
                  onFocus={() => setIsDropdownOpen(true)}
                />

                {isDropdownOpen && filteredUsers.length > 0 && (
                  <List
                    position="absolute"
                    top="100%"
                    left={0}
                    right={0}
                    maxH="200px"
                    overflowY="auto"
                    bg="white"
                    borderRadius="md"
                    boxShadow="lg"
                    zIndex={1}
                    mt={1}
                    border="1px solid"
                    borderColor="gray.200"
                    _dark={{
                      bg: 'gray.700',
                      borderColor: 'gray.600',
                    }}
                  >
                    {filteredUsers.map(user => (
                      <ListItem
                        key={user.cuid}
                        px={4}
                        py={2}
                        cursor="pointer"
                        _hover={{ bg: 'gray.50' }}
                        _dark={{
                          _hover: { bg: 'gray.600' },
                        }}
                        onClick={() => handleUserSelect(user)}
                      >
                        <HStack>
                          <Avatar
                            size="sm"
                            name={user.name}
                            src={user.picture}
                          />
                          <Box>
                            <Text fontWeight="medium">{user.name}</Text>
                            <Text fontSize="sm" color="gray.500">
                              {user.email}
                            </Text>
                          </Box>
                        </HStack>
                      </ListItem>
                    ))}
                  </List>
                )}
              </Box>
            </VStack>
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={handleModalClose} size="sm">
              Cancel
            </Button>
            <Button
              colorScheme="brand"
              onClick={handleAddUsers}
              isLoading={isLoading}
              isDisabled={selectedUsers.length === 0}
              size="sm"
            >
              Add Users
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default AdminUsers;
