import { NodeProps, Position, useStore } from 'reactflow';
import {
  Box,
  CircularProgress,
  HStack,
  Icon,
  ListItem,
  Stack,
  Tag,
  TagLabel,
  Text,
  UnorderedList,
  useColorModeValue,
  VStack,
  Button,
} from '@chakra-ui/react';
import { useStatus } from '../../../hooks/useStatuses';
import {
  generateRandomCodeForNodes,
  removeAfterDot,
} from '../../../../../../utils';
import NodeBox from '../../layouts/NodeBox';
import { CursorArrowRaysIcon } from '@heroicons/react/20/solid';
import useWorkflow from '../../../../../../hooks/useWorkflow';
import { Cog6ToothIcon } from '@heroicons/react/24/outline';
import { StatusFormNodeType } from '../../types';
import { ArrowDownIcon } from '@heroicons/react/24/solid';
import CustomHandle from '../../handles/CustomHandle';

const zoomSelector = (s: any) => s.transform[2] >= 0.5;
function StatusFormNode(props: NodeProps) {
  const statusCuid = props.data.state_callbacks.on_enter[0].args.cuid;
  const statusEntity = props.data.state_callbacks.on_enter[0].args.entity;
  const { setSelectedNodeId } = useWorkflow();
  const showContent = useStore(zoomSelector);
  const { data: status, isLoading } = useStatus(statusCuid);

  if (!statusCuid || !statusEntity) {
    return (
      <NodeBox
        title={'User Action'}
        icon={CursorArrowRaysIcon}
        nodeProps={props}
        rounded={'sm'}
      >
        <VStack w={'full'} px={4} py={2}>
          {showContent && (
            <Text fontStyle={'oblique'} w={40} textAlign={'center'}>
              This step hasn't been configured yet.
            </Text>
          )}
          <Button
            leftIcon={<Icon as={Cog6ToothIcon} boxSize={4} />}
            onClick={() => setSelectedNodeId!(props.id)}
            w={'full'}
          >
            Configure
          </Button>
        </VStack>
      </NodeBox>
    );
  }

  if (isLoading) {
    return (
      <NodeBox
        title={'USER ACTION'}
        icon={CursorArrowRaysIcon}
        bg={useColorModeValue('neutral.50', 'neutral.800')}
        border={2}
        borderColor={'var(--chakra-colors-chakra-border-color)'}
        borderStyle={'solid'}
        nodeProps={props}
        rounded={'lg'}
      >
        <Stack justify={'center'} align={'center'}>
          <CircularProgress
            size="40px"
            thickness="2px"
            isIndeterminate
            color="brand.base"
          />
          <Text fontSize={'sm'} color={'inherit'}>
            Please hold...
          </Text>
        </Stack>
      </NodeBox>
    );
  }

  return (
    <NodeBox
      onDoubleClick={() => setSelectedNodeId!(props.id)}
      title={'User Action'}
      icon={CursorArrowRaysIcon}
      bg={useColorModeValue('neutral.50', 'neutral.800')}
      border={2}
      borderColor={'var(--chakra-colors-chakra-border-color)'}
      borderStyle={'solid'}
      nodeProps={props}
      rounded={'lg'}
      p={0}
    >
      <HStack alignItems={'flex-start'}>
        <VStack gap={2} alignItems={'flex-start'}>
          <Box>
            <VStack
              gap={0}
              w={'full'}
              rounded={'md'}
              px={showContent ? 4 : 2}
              py={2}
            >
              <Button
                variant={'outline'}
                pointerEvents={'none'}
                size={showContent ? 'sm' : 'lg'}
              >
                {props.data.form.buttonLabel}
              </Button>
              <VStack
                w={'full'}
                alignItems={'center'}
                gap={1}
                px={showContent ? 4 : 0}
                py={showContent ? 2 : 0}
              >
                {showContent ? (
                  <Text fontSize={'sm'}>
                    Transitions{' '}
                    {statusEntity === 'InventoryModel' ? 'Model' : 'Document'}{' '}
                    to
                  </Text>
                ) : (
                  <Icon as={ArrowDownIcon} boxSize={5} />
                )}

                <Tag
                  data-testid="status-badge"
                  size={showContent ? 'sm' : '2xl'}
                  colorScheme={removeAfterDot(status!.colors.primary)}
                >
                  {status!.name}
                </Tag>
              </VStack>
            </VStack>
            {props.data.form.required_fields.length > 0 && showContent && (
              <VStack mx={4} mb={4}>
                <Text fontSize={'sm'}>Request fields:</Text>
                <UnorderedList spacing={0.5}>
                  {props.data.form.required_fields.map((field: string) => {
                    return (
                      <ListItem>
                        <Tag
                          key={field}
                          fontSize={'xs'}
                          fontFamily={'monospace'}
                          colorScheme="purple"
                        >
                          <TagLabel>{field}</TagLabel>
                        </Tag>
                      </ListItem>
                    );
                  })}
                </UnorderedList>
              </VStack>
            )}
          </Box>
        </VStack>
      </HStack>
      <CustomHandle
        type="target"
        position={Position.Top}
        style={{ top: -6 }}
        id="top"
      />
      <CustomHandle
        type="target"
        position={Position.Right}
        style={{ right: -6 }}
        id="right"
      />
      <CustomHandle
        type="source"
        position={Position.Left}
        style={{ left: -6 }}
        id="left"
      />
      <CustomHandle
        type="source"
        position={Position.Bottom}
        style={{ bottom: -6 }}
        id="bottom"
      />
    </NodeBox>
  );
}

StatusFormNode.type = 'status_form';
StatusFormNode.autoRuns = false;

StatusFormNode.getDefaultNode = (): StatusFormNodeType => {
  return {
    id: `${StatusFormNode.type}_${generateRandomCodeForNodes()}`,
    type: StatusFormNode.type,
    data: {
      state_callbacks: {
        on_enter: [
          {
            func: 'run_save_status',
            args: { entity: null, cuid: null },
          },
          {
            func: 'run_save_fields',
            args: {
              required_fields: [],
            },
          },
        ],
        on_exit: [],
      },
      form: {
        required_fields: [],
        buttonLabel: 'Submit',
      },
      transition_callbacks: {
        prepare: [
          {
            func: 'validate_form',
            args: {
              required_fields: [],
            },
          },
        ],
        conditions: [],
        before: [],
        after: [],
      },
    },
    position: { x: 0, y: 0 },
  };
};

export default StatusFormNode;
