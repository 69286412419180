import { Box, Tooltip, useColorModeValue, useRadio } from '@chakra-ui/react';

export default function RadioCard(props: any) {
  const { getInputProps, getCheckboxProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getCheckboxProps();
  const { label, children, ...rest } = props;
  return (
    <Box as="label" w={'full'}>
      <input {...input} />
      <Tooltip hasArrow label={label} openDelay={300}>
        <Box
          {...checkbox}
          cursor="pointer"
          borderRadius="md"
          border={'1px solid transparent'}
          borderColor={useColorModeValue('neutral.200', 'neutral.400')}
          bg={useColorModeValue('white', 'neutral.700')}
          _checked={{
            bg: useColorModeValue('brand.25', 'neutral.400'),
            borderColor: 'brand.base',
          }}
          _hover={{
            bg: input.checked
              ? 'brand.50'
              : useColorModeValue('neutral.50', 'neutral.700'),
            borderColor: input.checked
              ? 'brand.base'
              : useColorModeValue('neutral.300', 'neutral.400'),
          }}
          px={3}
          py={3}
          transition={'all 0.3s ease-out'}
          {...rest}
        >
          {children}
        </Box>
      </Tooltip>
    </Box>
  );
}
