import {
  FormControl,
  Input,
  Stack,
  Tag,
  TagCloseButton,
  TagLabel,
  Wrap,
} from '@chakra-ui/react';
import { Edge, Node } from 'reactflow';
import { useEffect, useState } from 'react';
import QueryBuilder, { RuleGroupType } from 'react-querybuilder';
import 'react-querybuilder/dist/query-builder.css';
import { formatQuery } from 'react-querybuilder/formatQuery';
import { parseJsonLogic } from 'react-querybuilder/parseJsonLogic';
import _ from 'lodash';
import useQueryBuilder from '../../../hooks/useQueryBuilder';
import FieldForm from '../../FieldForm';
import { StatusFormNodeType } from '../../types';
import { Label } from '../../../../../../components/Layout';
import { controlElements } from '../BranchPanel/BranchItem';
import BasePanel from '../BasePanel';

interface UserActionPanelProps {
  node: StatusFormNodeType;
  onAddNode: (node: StatusFormNodeType) => void;
  onDeleteNode: (node: StatusFormNodeType) => void;
  onAddEdge?: (edge: Edge) => void;
}

const UserActionPanel = ({
  node,
  onAddNode,
  onDeleteNode,
}: UserActionPanelProps) => {
  const [tempNode, setTempNode] = useState<Node>();
  const { query, setQuery, fields } = useQueryBuilder();

  useEffect(() => {
    setTempNode(node);
    if (node && node.data.transition_callbacks.conditions.length > 0) {
      setQuery(
        parseJsonLogic(
          JSON.parse(node.data.transition_callbacks.conditions[0].args.query),
        ) as RuleGroupType,
      );
    }
  }, [node]);

  const onAddField = (field: string) => {
    if (tempNode) {
      let clonedNode = _.cloneDeep(tempNode);
      clonedNode.data.form.required_fields.push(field);
      clonedNode.data.transition_callbacks.prepare[0].args.required_fields.push(
        field,
      );
      clonedNode.data.state_callbacks.on_enter[0].args.required_fields.push(
        field,
      );
      setTempNode(clonedNode);
    }
  };

  const onRemoveField = (field: string) => {
    if (tempNode) {
      let clonedNode = _.cloneDeep(tempNode);
      clonedNode.data.form.required_fields =
        clonedNode.data.form.required_fields.filter((f: string) => f !== field);

      clonedNode.data.transition_callbacks.prepare[0].args.required_fields =
        clonedNode.data.transition_callbacks.prepare[0].args.required_fields.filter(
          (f: string) => f !== field,
        );

      clonedNode.data.state_callbacks.on_enter[0].args.required_fields =
        clonedNode.data.state_callbacks.on_enter[0].args.required_fields.filter(
          (f: string) => f !== field,
        );
      setTempNode(clonedNode);
    }
  };

  const onLabelChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (tempNode) {
      let clonedNode = _.cloneDeep(tempNode);
      clonedNode.data.form.buttonLabel = e.target.value;
      setTempNode(clonedNode);
    }
  };

  const onSave = () => {
    if (tempNode) {
      const clonedNode = _.cloneDeep(tempNode);
      clonedNode.data.transition_callbacks.conditions = [
        {
          func: 'jsonlogic',
          args: {
            query: JSON.stringify(formatQuery(query, 'jsonlogic')),
          },
        },
      ];
      onAddNode(clonedNode);
    }
  };

  return (
    <BasePanel
      title={'Configure User Action'}
      node={tempNode}
      onDeleteNode={onDeleteNode}
      onSave={onSave}
    >
      <FormControl>
        <Label mb={2}>Display action button when</Label>

        <Stack>
          <QueryBuilder
            fields={fields}
            query={query}
            onQueryChange={setQuery}
            controlElements={controlElements}
          />
        </Stack>
      </FormControl>

      <FormControl>
        <Label mb={2}>Action button Label</Label>
        <Input
          size="sm"
          value={tempNode?.data?.form.buttonLabel || ''}
          onChange={onLabelChange}
          focusBorderColor="brand.base"
          rounded={'md'}
        />
      </FormControl>

      <FormControl>
        <Label mb={2}>Request fields</Label>

        <FieldForm
          addedFields={tempNode?.data.form.required_fields || []}
          onAddField={onAddField}
        />
        <Wrap gap={1} p={2} w={96}>
          {(tempNode?.data.form.required_fields || []).map(
            (fieldName: string) => (
              <Tag
                key={fieldName}
                fontSize={'xs'}
                fontFamily={'monospace'}
                colorScheme="purple"
              >
                <TagLabel>{fieldName}</TagLabel>
                <TagCloseButton
                  onClick={() => {
                    onRemoveField(fieldName);
                  }}
                />
              </Tag>
            ),
          )}
        </Wrap>
      </FormControl>
    </BasePanel>
  );
};

export default UserActionPanel;
