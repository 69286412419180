import {
  As,
  Box,
  BoxProps,
  HStack,
  Icon,
  LinkBox,
  LinkOverlay,
  Stack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { Copyright } from '../../components/Copyright';
import { PathRouteProps, Link as RouterLink } from 'react-router-dom';
import {
  ArrowsRightLeftIcon,
  BookOpenIcon,
  Cog6ToothIcon,
  RectangleGroupIcon,
  UserGroupIcon,
  UserIcon,
  EnvelopeIcon,
  WrenchScrewdriverIcon,
  UsersIcon,
  SignalIcon,
  BuildingLibraryIcon,
  AdjustmentsVerticalIcon,
  ChevronRightIcon,
  Bars3BottomLeftIcon,
  ForwardIcon,
  ClipboardDocumentCheckIcon,
  Bars3CenterLeftIcon,
} from '@heroicons/react/24/outline';
import { useContext } from 'react';
import UsersContext from '../../contexts/UsersContext';
import { ContentPageTitle } from '../../components/Layout';
import MoreInfoPopOver from '../../components/MoreInfoPopOver';
import { CONFIG } from '../../config';
import { useFlags } from '../../hooks/useFlags';

interface SettingGroupProps extends BoxProps {
  title: string;
  description: string;
  children: React.ReactNode;
  helpTitle?: string;
  helpDescription?: string;
  helpLink?: string;
}

const SettingGroup = ({
  children,
  title,
  description,
  helpTitle,
  helpDescription,
  helpLink,
}: SettingGroupProps) => (
  <Box
    p={6}
    w="full"
    rounded={8}
    borderWidth={1}
    bg={useColorModeValue('neutral.50', 'neutral.900')}
    borderColor={'var(--chakra-colors-chakra-border-color)'}
    role="group"
  >
    <HStack>
      <Text fontSize="xl" fontWeight="semibold" color="inherit">
        {title}
      </Text>
      {helpTitle && (
        <MoreInfoPopOver
          title={helpTitle}
          description={helpDescription}
          link={helpLink}
          placement="bottom"
          iconProps={{
            opacity: 0,
            _groupHover: { opacity: 1 },
          }}
        />
      )}
    </HStack>
    <Text paddingBottom={3} paddingTop={4}>
      {description}
    </Text>
    <Stack gap={1}>{children}</Stack>
  </Box>
);

interface SettingLinkProps {
  title: string;
  icon: As;
  description: string;
  path: PathRouteProps['path'];
}

const SettingLink = ({ title, icon, description, path }: SettingLinkProps) => (
  <LinkBox
    as={Box}
    role="group"
    p={5}
    w="full"
    data-testid="setting-link"
    rounded={'md'}
    borderWidth={1}
    bg={useColorModeValue('white', 'transparent')}
    borderColor={'var(--chakra-colors-chakra-border-color)'}
    _hover={{
      bg: useColorModeValue('brandSecondary.25', 'brandSecondary.950'),
      borderColor: useColorModeValue(
        'brandSecondary.100',
        'brandSecondary.800',
      ),
      color: useColorModeValue('inherit', 'brandSecondary.25'),
    }}
    transition="all 0.3s ease-in-out"
  >
    <HStack>
      <Icon as={icon} boxSize={6} />
      <Text fontSize={'md'} fontWeight="bold">
        <LinkOverlay as={RouterLink} to={path}>
          {title}
        </LinkOverlay>
      </Text>
    </HStack>
    <HStack justify="space-between">
      <Text pl={8}>{description}</Text>
      <Icon
        as={ChevronRightIcon}
        color={useColorModeValue('neutral.500', 'neutral.400')}
        _groupHover={{ color: useColorModeValue('neutral.600', 'neutral.300') }}
        boxSize={4}
        alignSelf={'center'}
      />
    </HStack>
  </LinkBox>
);

export default function Settings() {
  const { currentUser } = useContext(UsersContext);

  const isAdmin = currentUser?.roles.some(role => role.role.is_admin);

  const { userHasPermission } = useContext(UsersContext);
  const { attestationsUi, workflowsV3 } = useFlags();

  const hasGroupPermission = userHasPermission(['read_group'], 'any');
  const hasRolePermission = userHasPermission(
    ['create_role', 'update_role'],
    'all',
  );
  const hasRiskAreaPermission = userHasPermission(
    ['read_risk_area', 'read_guideline'],
    'any',
  );
  const hasInventorySchemaPermission = userHasPermission(
    ['read_inventory_schema'],
    'all',
  );

  const hasAttestationPermission = userHasPermission(
    ['read_attestation'],
    'any',
  );

  return (
    <Box
      px={8}
      flex={1}
      w="full"
      overflow={'auto'}
      className="no-scrollbar"
      bg={useColorModeValue('white', 'black')}
      maxWidth={'7xl'}
      mx={'auto'}
    >
      <HStack gap={5} color={'inherit'} pt={10} mb={8}>
        <Icon as={Cog6ToothIcon} boxSize={10} />
        <ContentPageTitle>Settings</ContentPageTitle>
      </HStack>

      <Stack direction={{ base: 'column', xl: 'row' }} gap={8}>
        <Stack direction={'column'} gap={8} w={'full'}>
          <SettingGroup
            title="Your Account"
            description="Manage your account, profile details, and notification settings."
            // helpTitle="Learn about your account"
            // helpDescription="Manage your account, profile details, and notification settings."
            // helpLink="https://www.internet.com"
          >
            <SettingLink
              title="Profile"
              path="/settings/profile"
              description="Edit your personal profile information."
              icon={UserIcon}
            />
          </SettingGroup>
          {(isAdmin || hasGroupPermission) && (
            <SettingGroup
              title="User Permissions"
              description="View the entire user directory, manage roles & permissions, groups, and invite new users."
              helpTitle="User Permissions"
              helpDescription="Control and organize who has access to the ValidMind platform and specific features."
              helpLink={`${CONFIG.VALIDMIND_DOCS_URL}/guide/configuration/managing-users.html`}
            >
              {isAdmin && (
                <>
                  <SettingLink
                    title="User Directory"
                    path="/settings/user-directory"
                    description="See a list of all the users in your organization."
                    icon={BookOpenIcon}
                  />
                  <SettingLink
                    title="Invite New Users"
                    path="/settings/invitation"
                    description="Invite new users via email to join your organization."
                    icon={EnvelopeIcon}
                  />
                </>
              )}
              {hasGroupPermission && (
                <SettingLink
                  title="Groups"
                  path="/settings/groups"
                  description="Control what models your users have access to with groups."
                  icon={UserGroupIcon}
                />
              )}
              {hasRolePermission && (
                <>
                  <SettingLink
                    title="Roles"
                    path="/settings/roles"
                    description="Manage the different roles in your organization."
                    icon={UsersIcon}
                  />
                  <SettingLink
                    title="Permissions"
                    path="/settings/permissions"
                    description="Manage what user roles are able to do or not in the system."
                    icon={WrenchScrewdriverIcon}
                  />
                </>
              )}
            </SettingGroup>
          )}
        </Stack>
        <SettingGroup
          title="Workspace Settings"
          description="Manage your organization's global workplace settings."
        >
          <>
            <SettingLink
              title="Organization"
              path="/settings/organization"
              description="Edit your organization's name and API key."
              icon={BuildingLibraryIcon}
            />
            {hasRiskAreaPermission && (
              <SettingLink
                title="Risk Areas & Validation Guidelines"
                path="/settings/risk-areas"
                description="Edit your organization's Risk Areas and Validation Guidelines."
                icon={Bars3BottomLeftIcon}
              />
            )}
            <SettingLink
              title="Templates"
              path="/settings/templates"
              description="View and manage your documentation templates."
              icon={RectangleGroupIcon}
            />
            {hasInventorySchemaPermission && (
              <SettingLink
                title="Model Inventory Fields"
                path="/settings/inventory-model-custom-fields"
                description="Define additional fields that all your models in your inventory will have."
                icon={AdjustmentsVerticalIcon}
              />
            )}
            {isAdmin && (
              <>
                <SettingLink
                  title="Model Stages"
                  path="/settings/statuses"
                  description="View and manage your model stages."
                  icon={SignalIcon}
                />
                <SettingLink
                  title="Workflows"
                  path="/settings/workflows"
                  description="View and manage your workflows."
                  icon={ArrowsRightLeftIcon}
                />
                {workflowsV3 && (
                  <SettingLink
                    title="Workflow States"
                    path="/settings/workflow-states"
                    description="View and manage your workflows states."
                    icon={ForwardIcon}
                  />
                )}
              </>
            )}
            <SettingLink
              title="Block Library"
              path="/settings/block-library"
              description="Manage and configure your block library settings."
              icon={Bars3CenterLeftIcon}
            />
            {attestationsUi && hasAttestationPermission && (
              <SettingLink
                title="Attestations"
                path="/settings/attestations"
                description="Manage attestation questionnaires, critical fields, and scheduling."
                icon={ClipboardDocumentCheckIcon}
              />
            )}
          </>
        </SettingGroup>
      </Stack>
      <Copyright />
    </Box>
  );
}
