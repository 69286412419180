import { useQuery } from 'react-query';
import API from '../../api/API';
import { TInventoryModel } from '../../models/inventory_model';
import { useNavigate, useParams } from 'react-router-dom';
import { AxiosError } from 'axios';
import { useToast } from '@chakra-ui/react';

function useCurrentDocument(
  inventoryModel: TInventoryModel,
  documentTypeParam?: string,
) {
  const queryResponse = useQuery(
    ['inventory-model', inventoryModel.cuid, documentTypeParam],
    async () => {
      return API.GetDocument(inventoryModel, documentTypeParam);
    },
    {
      staleTime: 1000 * 60 * 10,
    },
  );
  return queryResponse;
}

function useCurrentModel() {
  // Always scope the metric to a inventory model identifier
  const { id } = useParams();
  const navigate = useNavigate();
  const toast = useToast();

  const queryResponse = useQuery(
    ['inventory-model', id],
    async () => {
      return API.GetInventoryModel(id!);
    },
    {
      staleTime: 1000 * 60 * 10,
      onError: (error: AxiosError) => {
        if (error.response?.status === 404) {
          navigate('/model-inventory', { replace: true });
          toast({
            variant: 'subtle',
            title: 'Model not found',
            status: 'error',
            isClosable: true,
          });
        }
      },
    },
  );

  return { inventoryModel: queryResponse.data, ...queryResponse };
}

export { useCurrentModel, useCurrentDocument };
