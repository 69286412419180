import { useContext, useEffect, useState } from 'react';
import SidebarContext from '../../../../../contexts/SidebarContext';
import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  Icon,
  Square,
  Stack,
  Text,
  useColorModeValue,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { LoadingContainer } from '../../../../../components/LoadingContainer';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import API from '../../../../../api/API';
import Breadcrumbs from '../../../../../components/Breadcrumbs';
import { ContentPageTitle } from '../../../../../components/Layout';
import { AddIcon, DeleteIcon } from '@chakra-ui/icons';
import AddWorkflowStateModal from '../../../../../components/AddWorkflowStateModal';
import { EmptyStateDisplay } from '../../../../../components/EmptyStateDisplay';
import { State } from '../../../../../models/workflow_state';
import ConfirmationAlert from '../../../../../components/ConfirmationAlert';

interface DeleteWorkflowStateMutation {
  workflowCuid: string;
  state: State;
}

export default function WorkflowStatesDetail() {
  const { workflowCuid } = useParams();
  const { setInSettings } = useContext(SidebarContext);
  const [openModal, setOpenModal] = useState(false);
  const [selectedState, setSelectedState] = useState<State | undefined>();
  const [shouldDelete, setShouldDelete] = useState<State | undefined>();
  const toast = useToast();

  useEffect(() => {
    setInSettings(true);
    return () => {
      setInSettings(false);
    };
  }, []);

  const { data: workflow } = useQuery(['workflows', workflowCuid], async () => {
    return API.GetWorkflow(workflowCuid!, 'latest');
  });

  const {
    data: states,
    isLoading,
    refetch: refetchStates,
  } = useQuery(['workflows', workflowCuid, 'states'], async () => {
    return API.GetWorkflowStates(workflowCuid!);
  });

  const deleteState = useMutation(
    [],
    async ({ workflowCuid, state }: DeleteWorkflowStateMutation) => {
      return API.DeleteWorkflowState(workflowCuid, state.cuid!);
    },
    {
      onSuccess: (data, { state }, context) => {
        refetchStates();
        toast({
          variant: 'subtle',
          title: `State "${state.name}" deleted successfully.`,
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      },
    },
  );

  const onEdit = (state: State) => {
    setSelectedState(state);
    setOpenModal(true);
  };

  const onDelete = (state: State) => {
    setShouldDelete(state);
  };

  const onDeleteConfirm = (confirmation: boolean) => {
    if (confirmation && shouldDelete) {
      deleteState.mutate({
        workflowCuid: workflowCuid!,
        state: shouldDelete,
      });
    }
    setShouldDelete(undefined);
  };

  return (
    <VStack
      alignItems="start"
      spacing={0}
      paddingTop={12}
      mt={1}
      paddingBottom={16}
      px={14}
      gap={2}
      w="full"
      overflow="auto"
      className="no-scrollbar"
      maxWidth={'7xl'}
      mx={'auto'}
    >
      <LoadingContainer isLoading={isLoading}>
        <Stack alignSelf={'stretch'} gap={8}>
          <Box>
            <Breadcrumbs />
            <ContentPageTitle>{workflow?.title}</ContentPageTitle>
          </Box>
          <Text>{workflow?.description}</Text>
          <HStack spacing={2} align={'stretch'} justifyContent={'end'}>
            <Button
              onClick={() => setOpenModal(true)}
              leftIcon={<Icon as={AddIcon} boxSize={4} />}
              variant={'ghost'}
            >
              Add New Workflow State
            </Button>
          </HStack>

          {workflow && states && (
            <Stack maxWidth={'7xl'} mx={'auto'} w={'full'}>
              <VStack gap={1} alignSelf={'stretch'}>
                {states.length > 0 ? (
                  states.map(state => (
                    <Box
                      key={state.cuid}
                      alignSelf={'stretch'}
                      mt={'0 !important'}
                      role="group"
                      bg={useColorModeValue('neutral.50', 'neutral.900')}
                      _hover={{
                        bg: useColorModeValue('neutral.100', 'neutral.850'),
                        cursor: 'pointer',
                      }}
                      rounded={'md'}
                      px={2}
                      py={1}
                      border={'1px solid'}
                      borderColor={useColorModeValue(
                        'neutral.200',
                        'neutral.800',
                      )}
                      onClick={() => onEdit(state)}
                      transition={'all 0.2s ease-in-out'}
                    >
                      <Flex alignSelf={'stretch'}>
                        <Square
                          m={4}
                          size={6}
                          rounded={'md'}
                          border={1}
                          borderStyle="solid"
                          borderColor={state?.colors?.tertiary}
                          bgColor={state?.colors?.primary}
                        ></Square>

                        <VStack align={'start'} m={4} flex="1" spacing={1}>
                          <Heading as={'h4'}>{state.name}</Heading>
                          <Text>{state.description}</Text>
                        </VStack>

                        <VStack
                          alignItems="flex-end"
                          visibility={'hidden'}
                          _groupHover={{
                            display: 'flex',
                            visibility: 'visible',
                          }}
                        >
                          <Button
                            disabled={false}
                            variant={'ghost'}
                            onClick={e => {
                              e.stopPropagation(); // Stop the event from bubbling to the parent
                              onDelete(state);
                            }}
                            leftIcon={<DeleteIcon />}
                            colorScheme={'red'}
                          >
                            Delete
                          </Button>
                        </VStack>
                      </Flex>
                    </Box>
                  ))
                ) : (
                  <EmptyStateDisplay variant="no-workflow">
                    <Heading as={'h5'}>No workflow states yet.</Heading>
                    <Text align={'center'}>
                      Click "+ Add New Workflow State" to get started.
                    </Text>
                  </EmptyStateDisplay>
                )}
              </VStack>
            </Stack>
          )}
        </Stack>
      </LoadingContainer>
      <ConfirmationAlert
        open={shouldDelete !== undefined}
        onConfirm={onDeleteConfirm}
        title={'Please confirm'}
        dialogBody={`Are you sure you want to delete "${shouldDelete?.name}"? (TODO: search usage and warn the user)`}
      />
      {workflow && (
        <AddWorkflowStateModal
          isOpen={openModal}
          workflow={workflow}
          initialState={selectedState}
          onClose={() => {
            refetchStates();
            setSelectedState(undefined);
            setOpenModal(false);
          }}
        />
      )}
    </VStack>
  );
}
