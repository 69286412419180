import { Button, Icon, Text, VStack } from '@chakra-ui/react';
import { Node, useReactFlow, useStoreApi } from 'reactflow';
import { useEffect, useState } from 'react';
import _, { forEach } from 'lodash';
import useWorkflow from '../../../../../../hooks/useWorkflow';
import { BranchRouterNodeType } from '../../types';
import { generateRandomCodeForNodes } from '../../../../../../utils';
import BranchItem from './BranchItem';
import BranchRouteNode from '../../nodes/BranchNodes/BranchRouteNode';
import type { Edge } from '@reactflow/core/dist/esm/types/edges';
import BranchRouterNode from '../../nodes/BranchNodes/BranchRouterNode';
import { PlusIcon } from '@heroicons/react/24/outline';
import BasePanel from '../BasePanel';

interface BranchNodePanelProps {
  node: BranchRouterNodeType;
  onAddNode: (node: BranchRouterNodeType) => void;
  onDeleteNode: (node: BranchRouterNodeType) => void;
  onAddEdge?: (edge: Edge) => void;
}

export interface Branch {
  is_default: boolean;
  trigger_id: string;
  name: string;
  query: any;
}

const BranchNodePanel = ({
  onAddNode,
  onDeleteNode,
  onAddEdge,
  node,
}: BranchNodePanelProps) => {
  const { setSelectedNodeId, setForceUpdateKey } = useWorkflow();
  const { deleteElements } = useReactFlow();
  const storeApi = useStoreApi();
  const [tempNode, setTempNode] = useState<Node>();

  const [confirmDelete, setConfirmDelete] = useState(false);
  const [branches, setBranches] = useState<Branch[]>([]);

  useEffect(() => {
    setTempNode(node);
    if (
      node &&
      node.data.state_callbacks.on_enter[0].args.branches.length > 0
    ) {
      setBranches(node.data.state_callbacks.on_enter[0].args.branches);
    }
  }, [node]);

  const onSave = () => {
    if (tempNode) {
      const clonedNode = _.cloneDeep(tempNode);

      // identify branches deleted from the ui to remove their nodes, remove them at the end
      const deletedBranches =
        clonedNode.data.state_callbacks.on_enter[0].args.branches.filter(
          (b: Branch) => !branches.find(b1 => b1.trigger_id === b.trigger_id),
        );

      // update the branches
      clonedNode.data.state_callbacks.on_enter[0].args.branches = branches;
      onAddNode(clonedNode);

      // temporary solution to auto layout the branches below the router node
      const branchSpacing = 250; // Horizontal space between branches
      const branchCount =
        clonedNode.data.state_callbacks.on_enter[0].args.branches.length;
      const totalWidth = (branchCount - 1) * branchSpacing; // Total width of the branches

      // upsert the existing route nodes corresponding to each branch
      forEach(
        clonedNode.data.state_callbacks.on_enter[0].args.branches,
        (b: Branch, index: number) => {
          // initialize the default branch if it's not
          if (b.is_default && !b.trigger_id) {
            b.trigger_id =
              clonedNode.id.replace(
                BranchRouterNode.type,
                BranchRouteNode.type,
              ) + '_default';
          }

          const { getNodes } = storeApi.getState();
          let branchRouteNode = getNodes().find(n => n.id === b.trigger_id);
          if (!branchRouteNode) {
            branchRouteNode = BranchRouteNode.getDefaultNode();
            branchRouteNode.id = b.trigger_id;

            branchRouteNode.position.x =
              clonedNode.position.x - totalWidth / 2 + index * branchSpacing;
            branchRouteNode.position.y = clonedNode.position.y + 200;
          }
          branchRouteNode.data.branch.router_id = clonedNode.id;
          branchRouteNode.data.branch.name = b.name;
          branchRouteNode.data.branch.is_default = b.is_default;
          branchRouteNode.data.transition_callbacks.conditions[0].args.query =
            b.query;

          if (b.is_default) {
            branchRouteNode.deletable = false;
          }

          onAddNode(branchRouteNode);

          // add its corresponding edge, not deletable
          const edgeId = `reactflow__edge-${clonedNode.id}-${branchRouteNode?.id}`;
          const edge: Edge = {
            type: 'smoothstep',
            id: edgeId,
            source: clonedNode.id,
            target: branchRouteNode.id,
            deletable: false,
          };

          onAddEdge!(edge);
        },
      );

      // delete the nodes corresponding to the deleted branches
      deletedBranches.forEach((b: Branch) => {
        deleteElements({ nodes: [{ id: b.trigger_id }] });
      });

      setForceUpdateKey!(Math.random());
    }
  };

  const onAddBranch = () => {
    const trigger_id = `${
      BranchRouteNode.type
    }_${generateRandomCodeForNodes()}`;
    const branch = { trigger_id, name: '', query: false, is_default: false };
    setBranches([...branches, branch]);
  };

  const onDeleteBranch = (branch: Branch) => {
    setBranches(branches.filter(b => b.trigger_id !== branch.trigger_id));
  };

  const onEditBranch = (branch: Branch) => {
    setBranches(
      branches.map(b => (b.trigger_id === branch.trigger_id ? branch : b)),
    );
  };

  return (
    <BasePanel
      title={'Configure Condition Branch'}
      node={tempNode}
      onDeleteNode={onDeleteNode}
      onSave={onSave}
    >
      <VStack hidden={branches.length === 1} mb={8}>
        {branches
          .filter(b => !b.is_default)
          .map(branch => (
            <BranchItem
              key={branch.trigger_id}
              branch={branch}
              onEdit={onEditBranch}
              onDelete={onDeleteBranch}
            />
          ))}
        <Button
          onClick={onAddBranch}
          leftIcon={<Icon as={PlusIcon} boxSize={5} />}
          size={'sm'}
          variant={'outline'}
          alignSelf={'flex-end'}
        >
          Add Branch
        </Button>
      </VStack>
      <VStack w={'full'} p={8} hidden={branches.length > 1}>
        <Text alignSelf={'center'}>Start by adding a branch</Text>
        <Button
          onClick={onAddBranch}
          leftIcon={<Icon as={PlusIcon} boxSize={5} />}
          size={'sm'}
          variant={'outline'}
          alignSelf={'center'}
        >
          Add Branch
        </Button>
      </VStack>
    </BasePanel>
  );
};

export default BranchNodePanel;
