import React, { createContext, useCallback, useContext } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import API from '../../../../api/API';
import { Execution, Workflow } from '../../../../models/workflow';
import { UsersContext } from '../../../../contexts';

interface WorkflowContextType {
  workflow?: Workflow;
  execution?: Execution;
  loading: boolean;
  error: Error | undefined;
  refreshWorkflow: () => Promise<void>;
  refreshExecution: () => Promise<void>;
  loadWorkflowExecution: (
    workflowCuid: string,
    executionCuid?: string,
  ) => Promise<void>;
  clearWorkflowData: () => void;
  canManageWorkflowExecution: boolean;
  canReadWorkflowExecution: boolean;
}

const WorkflowContext = createContext<WorkflowContextType | undefined>(
  undefined,
);

export function WorkflowProvider({
  children,
  target,
}: {
  children: React.ReactNode;
  target: any;
}) {
  const { userHasPermission } = useContext(UsersContext);

  const canManageWorkflowExecution = userHasPermission(
    ['manage_workflow_execution'],
    'any',
  );

  const canReadWorkflowExecution = userHasPermission(
    ['read_workflow_execution'],
    'any',
  );

  const queryClient = useQueryClient();
  const [error, setError] = React.useState<Error | undefined>(undefined);

  // Add clearWorkflowData function
  const clearWorkflowData = useCallback(() => {
    queryClient.setQueryData(['workflow'], undefined);
    queryClient.setQueryData(['execution'], undefined);
  }, [queryClient]);

  const {
    data: workflow,
    isLoading: isLoadingWorkflow,
    refetch: refetchWorkflow,
  } = useQuery(['workflow'], async () => undefined, {
    enabled: false,
  });

  const {
    data: execution,
    isLoading: isLoadingExecution,
    refetch: refetchExecution,
  } = useQuery(['execution'], async () => undefined, {
    enabled: false,
  });

  const loadWorkflowExecution = async (
    workflowCuid: string,
    executionCuid?: string,
  ) => {
    try {
      const { workflow, executions } = await API.GetWorkflowExecution(
        workflowCuid,
        executionCuid,
      );

      queryClient.setQueryData(['workflow'], workflow);

      if (executions && executions.length > 0) {
        queryClient.setQueryData(['execution'], executions[0]);
      }
      setError(undefined);
    } catch (err) {
      setError(err as Error);
    }
  };

  const refreshWorkflow = async () => {
    await refetchWorkflow();
  };

  const refreshExecution = async () => {
    await refetchExecution();
  };

  return (
    <WorkflowContext.Provider
      value={{
        workflow,
        execution,
        loading: isLoadingWorkflow || isLoadingExecution,
        error,
        refreshWorkflow,
        refreshExecution,
        loadWorkflowExecution,
        clearWorkflowData,
        canManageWorkflowExecution,
        canReadWorkflowExecution,
      }}
    >
      {children}
    </WorkflowContext.Provider>
  );
}

export function useWorkflow() {
  const context = useContext(WorkflowContext);
  if (context === undefined) {
    throw new Error('useWorkflow must be used within a WorkflowProvider');
  }
  return context;
}
