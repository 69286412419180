import React, { useContext, useMemo, useRef, useState } from 'react';
import API from '../../../api/API';
import _, { toInteger } from 'lodash';
import { useMutation, UseMutationResult, useQuery } from 'react-query';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Button,
  FormControl,
  HStack,
  Icon,
  Input,
  ListItem,
  Select,
  Stack,
  Tag,
  TagLeftIcon,
  Text,
  UnorderedList,
  useColorModeValue,
  useToast,
  VStack,
} from '@chakra-ui/react';
import {
  ContentPageH2,
  EditableContentPageTitle,
  Label,
} from '../../../components/Layout';
import { useNavigate, useParams } from 'react-router-dom';
import RecordDetailsPage from '../../../components/Layout/RecordDetailsPage';
import {
  displayFormattedDate,
  displayFormattedYMDDate,
  FindingStatuses,
  FindingStatusMap,
} from '../../../utils';
import { StatusChangedToast } from '../../../components/Layout/Toasts';
import CommentsFooter from '../../../components/CommentsFooter';
import { TFinding, TSeverity } from '../../../models/finding';
import { TUserWithoutRoles } from '../../../models/user';
import RemediationPlanTextBox from './RemediationPlanTextBox';
import {
  canUpdateFinding,
  isInventoryModelDeveloper,
  isInventoryModelValidator,
} from '../../../auth/utils';
import { Copyright } from '../../../components/Copyright';
import UsersContext from '../../../contexts/UsersContext';
import MasterSearchBar from '../../../components/Layout/MasterSearchBar';
import AttributesRail from '../../../components/Layout/AttributesRail';
import RichTextContentEditor from '../../../components/RichTextContentEditor';
import RiskAreaContext from '../../../contexts/RiskAreaContext';
import DocumentSectionSelect from '../../../components/DocumentSectionSelect';
import AvatarProxy from '../../../components/AvatarProxy';
import { InventoryModelStages } from '../../../models/inventory_model';
import ManageAttachmentsField from '../../../components/ManageAttachmentsField';
import ConfirmationAlert from '../../../components/ConfirmationAlert';
import { AxiosError } from 'axios';
import {
  ExclamationTriangleIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';
import InventoryModelContext from '../../../contexts/InventoryModel';
import { AssessmentFinding } from '../../../models/guideline';
import { LoadingContainer } from '../../../components/LoadingContainer';
import { TAttestationExecution } from '../../../models/attestation';
import { StatusTagColor } from '../../../colors';

const severityFontColorMap = {
  low: 'neutral.600',
  medium: 'neutral.800',
  high: 'white',
};

const AttributesRailForFindings = ({
  finding,
  assessmentsFindings,
  orgUsers,
  severities,
  onAttributeChange,
  readOnly = false,
}: {
  finding?: TFinding;
  assessmentsFindings?: AssessmentFinding[];
  orgUsers?: TUserWithoutRoles[];
  severities?: TSeverity[];
  onAttributeChange: (data: any) => void;
  readOnly?: boolean;
}) => {
  const { riskAreas } = useContext(RiskAreaContext);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const navigate = useNavigate();
  const toast = useToast();
  const { userHasInventoryModelPermission } = useContext(InventoryModelContext);

  const hasEditPerms = userHasInventoryModelPermission(
    ['update_finding'],
    'any',
  );

  const severityFontColor =
    severityFontColorMap[
      (finding?.severity.name.toLowerCase() || 'low') as keyof object
    ];

  const onAttributeChangeDebounced = useRef(
    _.debounce(value => {
      onAttributeChange({
        due_at: new Date(value).getTime(),
      });
    }, 1000),
  ).current;

  const deleteFinding = useMutation(
    ['inventory-model', finding?.inventory_model?.cuid, 'findings'],
    async () => {
      return API.DeleteFinding(finding!.inventory_model!.cuid, finding?.cuid!);
    },
    {
      onSuccess: () => {
        navigate(-1); // back
        toast({
          variant: 'subtle',
          duration: 3000,
          isClosable: true,
          render: () => (
            <StatusChangedToast
              message="Finding has been deleted"
              status="success"
            />
          ),
        });
      },
      onError: error => {
        if (error instanceof Error) {
          toast({
            variant: 'subtle',
            duration: 3000,
            isClosable: true,
            render: () => (
              <StatusChangedToast
                message="Could not delete finding"
                error={error.message}
                status="error"
              />
            ),
          });
        }
      },
    },
  );

  const onDeleteConfirmed = (confirmed: boolean) => {
    if (confirmed) {
      deleteFinding.mutate();
    }
    setConfirmDelete(false);
  };

  return (
    <AttributesRail>
      <FormControl>
        <Label mb={2}>STATUS</Label>
        {hasEditPerms ? (
          <Select
            data-testid="finding-status-select"
            bg="white"
            onChange={e => onAttributeChange({ status: e.target.value })}
            value={finding?.status || 'open'}
          >
            {FindingStatuses.map(status => (
              <option key={status} value={status}>
                {FindingStatusMap[status]}
              </option>
            ))}
          </Select>
        ) : (
          <Tag
            data-testid="finding-status-badge"
            colorScheme={
              StatusTagColor[(finding?.status as keyof object) || 'open']
            }
            variant={'outline'}
            size={'lg'}
          >
            <TagLeftIcon as={ExclamationTriangleIcon} />
            {FindingStatusMap[(finding?.status as keyof object) || 'open']}
          </Tag>
        )}
      </FormControl>
      <FormControl>
        <Label mb={2}>SEVERITY</Label>
        {hasEditPerms ? (
          <Select
            data-testid="finding-severity-select"
            onChange={e =>
              onAttributeChange({
                severity: severities?.find(
                  s => s.id === toInteger(e.target.value),
                )?.level!,
              })
            }
            value={finding?.severity.id}
          >
            {severities?.map(s => (
              <option key={s.id} value={s.id}>
                Level {s.level} ({s.name})
              </option>
            ))}
          </Select>
        ) : (
          <Text
            p={2}
            rounded={'md'}
            fontWeight={'bold'}
            color={severityFontColor}
            backgroundColor={finding?.severity.colors.primary}
          >
            {finding?.severity.name}
          </Text>
        )}
      </FormControl>
      <FormControl>
        <Label mb={2}>RISK AREA</Label>
        {hasEditPerms ? (
          <Select
            data-testid="finding-area-select"
            bg="white"
            onChange={e => {
              onAttributeChange({ risk_area_cuid: e.target.value });
            }}
            value={finding?.risk_area?.cuid}
          >
            {riskAreas.sort().map(area => (
              <option key={area.cuid} value={area.cuid}>
                {area.name}
              </option>
            ))}
          </Select>
        ) : (
          <Text>{finding?.risk_area?.name || 'Not set'}</Text>
        )}
      </FormControl>
      <FormControl>
        <Label mb={2}>DUE ON</Label>
        {hasEditPerms ? (
          <Input
            data-testid="finding-due-select"
            type="date"
            bg="white"
            placeholder="Target date for finding remediation"
            defaultValue={
              finding?.due_at ? displayFormattedYMDDate(finding?.due_at) : ''
            }
            onChange={e => onAttributeChangeDebounced(e.target.value)}
            w="100%"
            size="sm"
          />
        ) : (
          <Text>
            {finding?.due_at
              ? displayFormattedDate(finding?.due_at)
              : 'Not set'}
          </Text>
        )}
      </FormControl>
      <FormControl>
        <Label mb={2}>ASSIGNED TO</Label>
        {hasEditPerms ? (
          <Select
            data-testid="finding-assigned-to-select"
            bg="white"
            onChange={e => onAttributeChange({ owner_cuid: e.target.value })}
            value={finding?.owner?.cuid || ''}
          >
            <option key="" value="">
              Unassigned
            </option>
            {orgUsers &&
              orgUsers.map(user => (
                <option key={user.cuid} value={user.cuid}>
                  {user.name}
                </option>
              ))}
          </Select>
        ) : (
          <Text>{finding?.owner ? finding.owner.name : 'Unassigned'}</Text>
        )}
      </FormControl>
      <FormControl>
        <Label mb={2}>Documentation Section</Label>
        <DocumentSectionSelect
          readOnly={!hasEditPerms}
          sectionId={finding?.documentation_section_id}
          setSectionId={sectionId => {
            onAttributeChange({
              documentation_section_id: sectionId || null,
            });
          }}
          documentType="documentation"
        />
      </FormControl>
      <VStack align="start" spacing={0}>
        <Label mb={2}>CREATED BY</Label>
        <HStack gap={2}>
          <AvatarProxy
            name={finding?.user.name}
            src={finding?.user.picture}
            size={'sm'}
          />
          <Text color="inherit">{finding?.user.name}</Text>
        </HStack>
      </VStack>
      <VStack align="start" spacing={0}>
        <Label mb={2}>DATE CREATED</Label>
        <Tag>{displayFormattedDate(finding?.created_at)}</Tag>
      </VStack>
      <VStack align="start" spacing={0}>
        <Label mb={2}>LAST UPDATED</Label>
        <Tag>
          {displayFormattedDate(finding?.updated_at || finding?.created_at)}
        </Tag>
      </VStack>
      {/* {assessmentsFindings && assessmentsFindings.length > 0 && (
        <VStack align="start" spacing={0}>
          <Label mb={2}>VALIDATION REPORT</Label>
          <Tag>
            As evidence in {assessmentsFindings?.length || 0} assessment
            {assessmentsFindings?.length === 1 ? '' : 's'}
          </Tag>
        </VStack>
      )} */}

      {hasEditPerms && (
        <Stack hidden={false}>
          <ConfirmationAlert
            size={
              assessmentsFindings && assessmentsFindings.length > 0
                ? 'xl'
                : 'md'
            }
            open={confirmDelete}
            onConfirm={onDeleteConfirmed}
            dialogBody={
              <Box>
                {assessmentsFindings && assessmentsFindings.length > 0 && (
                  <Alert status="warning" borderRadius="md" mb={4}>
                    <AlertIcon />
                    <Box>
                      <AlertDescription>
                        <VStack align="start" spacing={3} width="full">
                          <Text fontWeight="semibold">
                            Warning: This finding will be removed from the
                            following validation report assessments:
                          </Text>
                          <Box pl={4} width="full">
                            <UnorderedList spacing={2}>
                              {assessmentsFindings.map(af => (
                                <ListItem key={af.assessment?.guideline?.cuid}>
                                  <Text>
                                    {af.assessment?.guideline?.risk_area?.name}:{' '}
                                    {af.assessment?.guideline?.title}
                                  </Text>
                                </ListItem>
                              ))}
                            </UnorderedList>
                          </Box>
                        </VStack>
                      </AlertDescription>
                    </Box>
                  </Alert>
                )}

                <Text>Are you sure you want to delete this finding?</Text>
              </Box>
            }
            title={'Delete Finding'}
            confirmButton={'Yes, delete it'}
          />
          <Button
            onClick={() => setConfirmDelete(true)}
            isLoading={deleteFinding.isLoading}
            leftIcon={<Icon as={TrashIcon} boxSize={4} />}
            variant={'outline'}
            _hover={{
              bg: useColorModeValue('red.50', 'red.700'),
              color: useColorModeValue('red.500', 'red.500'),
            }}
          >
            Delete Finding
          </Button>
        </Stack>
      )}
    </AttributesRail>
  );
};

type FindingDetailsProps = {
  finding: TFinding;
  allowEditMetadata: boolean;
  allowEditRemediationPlan: boolean;
  allowAttributesEdit: boolean;
  allowCommentsAdd: boolean;
  allowManageAttachments: boolean;
  assessmentsFindings?: AssessmentFinding[];
  updateFindingMutation?: UseMutationResult<TFinding>;
  attestationExecution?: TAttestationExecution;
};

export const FindingDetails: React.FC<FindingDetailsProps> = ({
  finding,
  allowEditMetadata,
  allowEditRemediationPlan,
  allowAttributesEdit,
  allowCommentsAdd,
  allowManageAttachments,
  assessmentsFindings,
  updateFindingMutation,
  attestationExecution,
}) => {
  const { organizationUsers } = useContext(UsersContext);

  const { data: severities } = useQuery(['finding-severities'], async () => {
    return await API.GetFindingSeverities();
  });

  return (
    <Stack px={10} w={'full'} alignItems={'center'}>
      <RecordDetailsPage
        right={
          <AttributesRailForFindings
            finding={finding}
            assessmentsFindings={assessmentsFindings}
            orgUsers={organizationUsers}
            severities={severities}
            onAttributeChange={(data: any) =>
              updateFindingMutation?.mutate(data)
            }
            readOnly={allowAttributesEdit}
          />
        }
      >
        {!attestationExecution && (
          <HStack w={'full'} pb={8}>
            <MasterSearchBar />
          </HStack>
        )}
        {/* TODO: need a title container for RecordDetailsPage */}
        {!attestationExecution && (
          <EditableContentPageTitle
            onSave={(newTitle: string) =>
              updateFindingMutation?.mutate({ title: newTitle })
            }
            onCancel={() => {}}
            allowEdit={allowEditMetadata}
          >
            {finding?.title}
          </EditableContentPageTitle>
        )}
        <RichTextContentEditor
          text={finding?.description || ''}
          onSave={(editorText: string) =>
            updateFindingMutation?.mutate({ description: editorText })
          }
          allowEdit={allowEditMetadata}
        />
        <Stack>
          <ContentPageH2>Proposed Remediation Plan</ContentPageH2>
          {finding && (
            <RemediationPlanTextBox
              finding={finding}
              allowEditMetadata={allowEditRemediationPlan || false}
            />
          )}
        </Stack>
        <Stack>
          {finding && (
            <ManageAttachmentsField
              entityType="finding"
              entityCuid={finding.cuid}
              entityFieldId="attachments"
              canUpload={allowManageAttachments}
              canDelete={allowManageAttachments}
              getEntityAttachmentsFn={
                !!attestationExecution
                  ? async (entityType, entityCuid, entityFieldId) => {
                      const response =
                        await API.GetAttestationExecutionSnapshotEntityAttachments(
                          attestationExecution.cuid,
                          entityType,
                          entityCuid,
                          entityFieldId,
                        );

                      return response.entity_attachments;
                    }
                  : undefined
              }
            />
          )}
        </Stack>
        {!attestationExecution && (
          <CommentsFooter
            itemType="Finding"
            itemCuid={finding.cuid}
            allowAdd={allowCommentsAdd}
          />
        )}
        {!attestationExecution && <Copyright />}
      </RecordDetailsPage>
    </Stack>
  );
};

export default function ViewFinding() {
  const { finding_cuid } = useParams();
  const { inventoryModel } = useContext(InventoryModelContext);
  const toast = useToast();
  const navigate = useNavigate();
  const { currentUser, userHasPermission } = useContext(UsersContext);
  const [finding, setFinding] = useState<TFinding | null>(null);

  const { isLoading } = useQuery(
    ['inventory-model', inventoryModel?.cuid, 'findings', finding_cuid],
    async () => {
      const fetchedFinding = await API.GetFinding(
        inventoryModel!,
        finding_cuid!,
      );
      setFinding(fetchedFinding);
      return fetchedFinding;
    },
    {
      onError: error => {
        navigate('/', { replace: true });
        if (error instanceof Error) {
          let title = 'Error fetching finding';
          let description = 'An error occurred while fetching the finding.';
          if ((error as AxiosError)?.response?.status === 404) {
            title = 'Finding not found';
            description = 'The finding you are looking for does not exist.';
          }
          toast({
            variant: 'subtle',
            status: 'error',
            position: 'bottom',
            isClosable: true,
            title,
            description,
          });
        }
      },
    },
  );

  const { data: assessmentsFindings } = useQuery(
    [
      'inventory-model',
      inventoryModel?.cuid,
      'findings',
      finding_cuid,
      'assessments',
    ],
    async () => {
      return await API.GetFindingLinkedAssessmentFindings(
        inventoryModel!,
        finding_cuid!,
      );
    },
  );

  const allowEditMetadata = useMemo(
    () =>
      currentUser && inventoryModel?.stage === InventoryModelStages.ACTIVE
        ? canUpdateFinding(currentUser)
        : false,
    [currentUser, inventoryModel],
  );

  // Allow developers and validators to edit the remediation plan
  const allowEditRemediationPlan = useMemo(
    () =>
      currentUser && inventoryModel?.stage === InventoryModelStages.ACTIVE
        ? isInventoryModelDeveloper(currentUser, inventoryModel) ||
          isInventoryModelValidator(currentUser, inventoryModel)
        : false,
    [currentUser, inventoryModel],
  );

  const updateFindingMutation = useMutation(
    ['inventory-model', inventoryModel?.cuid, 'update-finding', finding_cuid],
    async (data: any) => {
      return API.UpdateFinding(inventoryModel!, finding_cuid!, data);
    },
    {
      onSuccess: (updatedFinding: TFinding) => {
        setFinding(updatedFinding);
        toast({
          variant: 'subtle',
          duration: 3000,
          isClosable: true,
          render: () => (
            <StatusChangedToast
              message="Finding has been updated"
              status="success"
            />
          ),
        });
      },
      onError: error => {
        if (error instanceof Error) {
          // TODO: Track
          toast({
            variant: 'subtle',
            duration: 3000,
            isClosable: true,
            render: () => (
              <StatusChangedToast
                message="Could not update finding"
                error={error.message}
                status="error"
              />
            ),
          });
        }
      },
    },
  );

  const allowAttributesEdit =
    inventoryModel?.stage !== InventoryModelStages.ACTIVE;

  const allowCommentsAdd =
    inventoryModel?.stage === InventoryModelStages.ACTIVE;

  const canManageAttachments = userHasPermission(
    ['manage_finding_attachments'],
    'all',
  );

  if (isLoading || !finding) {
    return <LoadingContainer isLoading={true}>Loading</LoadingContainer>;
  }

  return (
    <FindingDetails
      finding={finding}
      assessmentsFindings={assessmentsFindings}
      allowEditMetadata={allowEditMetadata}
      allowEditRemediationPlan={allowEditRemediationPlan || false}
      allowCommentsAdd={allowCommentsAdd}
      allowAttributesEdit={allowAttributesEdit}
      allowManageAttachments={canManageAttachments}
      updateFindingMutation={updateFindingMutation}
    />
  );
}
