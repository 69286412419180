import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  HStack,
  VStack,
  Collapse,
  ModalFooter,
} from '@chakra-ui/react';
import {
  TAttestationExecution,
  TAttestationTransition,
} from '../../../models/attestation';
import { useQuery } from 'react-query';
import API from '../../../api/API';
import { useMemo, useState } from 'react';
import dayjs from 'dayjs';
import AttestationStatus from '../AttestationStatus';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';

type Props = {
  attestationExecution: TAttestationExecution;
  isOpen: boolean;
  onClose: () => void;
};

type ActivityItemProps = {
  transition: TAttestationTransition;
};

const ActivityItem = ({ transition }: ActivityItemProps) => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  return (
    <VStack spacing={4} align="flex-start">
      <VStack
        spacing={1}
        align="flex-start"
        onClick={() => setIsCollapsed(!isCollapsed)}
        cursor="pointer"
      >
        <HStack spacing={1}>
          <Text>Attestation status changed to</Text>
          <AttestationStatus attestationStatus={transition.to_status} />
          {isCollapsed && <ChevronDownIcon />}
          {!isCollapsed && <ChevronUpIcon />}
        </HStack>
        <Text fontSize="sm" opacity={0.6}>
          {transition.transitioned_by.name} &#x2022;{' '}
          {dayjs(transition.created_at).fromNow()}
        </Text>
      </VStack>
      <Collapse in={!isCollapsed} animateOpacity>
        <VStack
          spacing={2}
          align="flex-start"
          borderWidth={1}
          borderRadius={4}
          paddingX={4}
          paddingY={2}
        >
          <Text fontSize="sm" fontWeight="bold">
            Notes
          </Text>
          <Text>{transition.notes}</Text>
        </VStack>
      </Collapse>
    </VStack>
  );
};

export default function AttestationActivityModal({
  attestationExecution,
  isOpen,
  onClose,
}: Props) {
  const { data, isLoading } = useQuery(
    ['attestation', attestationExecution.cuid, 'activity'],
    async () => {
      return API.GetAttestationExecutionActivity(attestationExecution.cuid);
    },
  );

  const groupedActivity = useMemo(() => {
    const grouped: Record<string, TAttestationTransition[]> = {};

    // Group by date
    data?.forEach((activity: TAttestationTransition) => {
      const formattedDate = dayjs(activity.created_at).format('YYYY-MM-DD');
      if (!grouped[formattedDate]) {
        grouped[formattedDate] = [];
      }
      grouped[formattedDate].push(activity);
    });

    // Create array of grouped activity
    return Object.keys(grouped)
      .map(date => ({
        date,
        activity: grouped[date],
      }))
      .sort((a, b) => dayjs(b.date).unix() - dayjs(a.date).unix());
  }, [data]);

  return (
    <Modal scrollBehavior="inside" size="3xl" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {attestationExecution.schedule_snapshot_json.name} Activity
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={6} align="flex-start">
            {groupedActivity.map(({ date, activity }) => {
              let formattedDate = dayjs(date).format('dddd, MMMM D');
              return (
                <VStack key={date} align="flex-start" spacing={4}>
                  <Text fontWeight="bold">{formattedDate}</Text>
                  <VStack ml={4} align="flex-start">
                    {activity.map(transition => (
                      <ActivityItem
                        key={transition.cuid}
                        transition={transition}
                      />
                    ))}
                  </VStack>
                </VStack>
              );
            })}
          </VStack>
        </ModalBody>
        <ModalFooter></ModalFooter>
      </ModalContent>
    </Modal>
  );
}
