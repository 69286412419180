import { useContext, useEffect, useState } from 'react';
import { defaultOperators, Field, RuleGroupType } from 'react-querybuilder';
import { JSONSchema7 } from 'json-schema';
import { UsersContext } from '../../../../../contexts';
import { useOrgRoles } from '../../../../../hooks/useOrgRoles';
import _ from 'lodash';

export const FIELD_IDENTIFIERS = {
  USER_ROLES: 'user.roles',
  USER_MODEL_STAKEHOLDERS: 'user.model_stakeholders',
  MODEL_CUSTOM_FIELDS: 'model.custom_fields',
} as const;

const defaultQuery = {
  combinator: 'and',
  rules: [],
};

export default function useQueryBuilder(
  initialQuery: RuleGroupType = defaultQuery,
) {
  const { currentOrganization } = useContext(UsersContext);
  const [fields, setFields] = useState<Field[]>([]);
  const { orgRoles, isLoading: isRolesLoading } = useOrgRoles();
  const [query, setQuery] = useState<RuleGroupType>(initialQuery);

  useEffect(() => {
    // Initialize the __fields array with predefined field configurations
    let __fields: Field[] = [
      {
        name: FIELD_IDENTIFIERS.USER_ROLES,
        label: 'User Roles',
        operators: [
          { name: 'contains', value: 'contains', label: 'contains' } as const,
          {
            name: 'doesNotContain',
            value: 'doesNotContain',
            label: 'does not contain',
          } as const,
        ],
        valueEditorType: 'select',
        // Filter out roles with scope === 'Model' and map the remaining roles to options
        values: orgRoles
          .filter(role => role.scope !== 'Model')
          .map(role => ({
            name: role.cuid,
            label: role.name,
          })),
      },
      {
        name: FIELD_IDENTIFIERS.USER_MODEL_STAKEHOLDERS,
        label: 'Model Stakeholder Roles',
        operators: [
          { name: 'contains', value: 'contains', label: 'contains' } as const,
          {
            name: 'doesNotContain',
            value: 'doesNotContain',
            label: 'does not contain',
          } as const,
        ],
        valueEditorType: 'select',
        // Filter out roles with scope === 'Model'
        values: orgRoles
          .filter(role => role.scope == 'Model')
          .map(role => ({
            name: role.cuid,
            label: role.name,
          })),
      },
    ];

    // Helper function to get additional properties for custom fields based on their type
    function getItemProps(field: JSONSchema7) {
      if (field.type === 'boolean') {
        return {
          valueEditorType: 'checkbox',
          operators: defaultOperators.filter(op => op.name === '='),
        };
      }
      if (field.enum) {
        return {
          valueEditorType: 'select',
          values: field.enum.map(value => ({
            name: value,
            label: value,
          })),
        };
      }
      return {
        valueEditorType: 'text',
      };
    }

    // Add custom fields from the organization's schema to __fields
    Object.entries(
      currentOrganization?.inventory_model_schema.custom.schema.properties ||
        {},
    ).forEach(f => {
      const key = f[0];
      const field = f[1] as JSONSchema7;
      const item = {
        name: `custom_fields.${key}`,
        label: `Field: ${field.title}`,
        ...getItemProps(field),
      };
      __fields.push(item as Field);
    });

    // Use lodash to merge or add fields based on the 'name' attribute
    // If a field with the same 'name' exists in `fields`, update it; otherwise, add the new field
    const _fields: Field[] = _.unionBy(__fields, fields, 'name');

    // Update the state with the new or merged fields
    setFields(_fields);
  }, [currentOrganization, orgRoles, isRolesLoading]);

  return {
    fields,
    query,
    setQuery,
    defaultQuery,
  };
}
