import React from 'react';
import { Outlet } from 'react-router-dom';
import { LoadingContainer } from '../LoadingContainer';
import AppSidebarLayout from '../Layout/AppSidebarLayout';
import { SettingsSidebar } from '../SettingsSidebar';
import AdminLayout from '../Layout/AdminLayout';
import { withAuthenticationRequired } from 'react-oidc-context';
import AttestationSidebar, {
  AttestationProviderWrapper,
} from '../AttestationSidebar';

const DashboardLayout = () => {
  return (
    <AttestationProviderWrapper>
      <AppSidebarLayout>
        <SettingsSidebar />
        <PrivateRoute />
        <AttestationSidebar />
      </AppSidebarLayout>
    </AttestationProviderWrapper>
  );
};

const AdminDashboardLayout = () => {
  return (
    <AdminLayout>
      <PrivateRoute />
    </AdminLayout>
  );
};

export const PrivateRoute = () => <Outlet />;

const AuthPrivateRoute = React.memo(
  withAuthenticationRequired(DashboardLayout, {
    OnRedirecting: () => (
      <LoadingContainer isLoading={true}>Loading...</LoadingContainer>
    ),
  }),
);

// Simplified to just handle authentication
const AuthPrivateAdminRoute = React.memo(
  withAuthenticationRequired(() => <Outlet />, {
    OnRedirecting: () => (
      <LoadingContainer isLoading={true}>Loading...</LoadingContainer>
    ),
  }),
);

export { AuthPrivateRoute, AuthPrivateAdminRoute };
