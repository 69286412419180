import {
  Box,
  HStack,
  Tag,
  TagLabel,
  useColorModeValue,
} from '@chakra-ui/react';
import { CustomFieldProps, CustomFieldSchemaModelUserProps } from '../types';
import { useCallback, useContext, useMemo, useState, useEffect } from 'react';
import { Select } from 'chakra-react-select';
import NullFieldDisplay from '../Generic/NullFieldDisplay';
import UsersContext from '../../../contexts/UsersContext';
import AvatarProxy from '../../AvatarProxy';

const ArrayFieldDisplay = ({ cuids }: { cuids: string[] }) => {
  const { organizationUsers } = useContext(UsersContext);
  return (
    <Box>
      {cuids.map((cuid: string) => {
        const user = organizationUsers.find(u => u.cuid === cuid)!;
        if (!user) {
          console.log('[CustomUserSelect] user not found: ', cuid);
          return;
        }

        return (
          <HStack mb={2} key={user.cuid}>
            {/* <Avatar size="xs" name={user.name} src={user.picture} />
            <Text fontSize="sm">{user.name}</Text> */}

            <Tag key={user.cuid} size={'md'}>
              <AvatarProxy
                src={user.picture}
                size="sm"
                name={user.name}
                ml={-2}
                mr={2}
              />
              <TagLabel fontSize={'md'}>{user.name}</TagLabel>
            </Tag>
          </HStack>
        );
      })}
    </Box>
  );
};

export default function CustomUserSelect({
  mode,
  value,
  onChange,
  schema,
}: CustomFieldProps) {
  const { organizationUsers } = useContext(UsersContext);
  const schemaProps = schema.props as CustomFieldSchemaModelUserProps;

  const initialValue = useMemo(() => {
    if (!value) return [];

    const valueArray = Array.isArray(value) ? value : [value];
    return valueArray
      .map((cuid: string) => {
        const user = organizationUsers.find(ou => ou.cuid === cuid);
        if (!user) return null;
        return {
          value: cuid,
          label: user.name,
        };
      })
      .filter(Boolean);
  }, [value, organizationUsers]);

  const [localValue, setLocalValue] = useState(initialValue);
  const multiple = schemaProps.many;

  useEffect(() => {
    setLocalValue(initialValue);
  }, [initialValue]);

  const options = useMemo(() => {
    return organizationUsers.map(u => ({
      value: u.cuid,
      label: u.name,
    }));
  }, [organizationUsers]);

  const onChangeCallback = useCallback(
    (newValue: any) => {
      setLocalValue(newValue);

      let onChangeValue;

      if (!Array.isArray(newValue)) {
        onChangeValue = [newValue.value];
      } else {
        onChangeValue = newValue.map(({ value }) => value);
      }

      onChange(onChangeValue);
    },
    [schema.type],
  );

  if (mode === 'display' || mode === 'read-only') {
    if (value) {
      let valueAsArray: string[] = value;

      if (!Array.isArray(value)) {
        valueAsArray = [value];
      }

      if (valueAsArray.length === 0) {
        return <NullFieldDisplay />;
      }

      return <ArrayFieldDisplay cuids={valueAsArray} />;
    } else if (mode === 'read-only') {
      return <NullFieldDisplay />;
    } else {
      return null;
    }
  }

  return (
    <Box bg={useColorModeValue('white', 'neutral.850')} rounded={'md'}>
      <Select
        isMulti={multiple}
        options={options}
        onChange={onChangeCallback}
        value={localValue}
        focusBorderColor="brand.base"
        isClearable={false}
        menuPosition="fixed"
      />
    </Box>
  );
}
