import { NodeProps, Position, useStore } from 'reactflow';
import {
  Box,
  Button,
  HStack,
  Icon,
  ListItem,
  Tag,
  TagLabel,
  Text,
  UnorderedList,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
import { generateRandomCodeForNodes } from '../../../../../../utils';
import NodeBox from '../../layouts/NodeBox';
import { CursorArrowRaysIcon } from '@heroicons/react/20/solid';
import useWorkflow from '../../../../../../hooks/useWorkflow';
import { UserActionNodeType } from '../../types';
import CustomHandle from '../../handles/CustomHandle';
import { ArrowRightIcon } from '@heroicons/react/24/outline';

const zoomSelector = (s: any) => s.transform[2] >= 0.5;
function UserActionNode(props: NodeProps) {
  const { setSelectedNodeId } = useWorkflow();
  const showContent = useStore(zoomSelector);
  return (
    <NodeBox
      onDoubleClick={() => setSelectedNodeId!(props.id)}
      title={'USER ACTION (v2)'}
      icon={CursorArrowRaysIcon}
      bg={useColorModeValue('neutral.50', 'neutral.800')}
      border={2}
      borderColor={useColorModeValue('neutral.200', 'neutral.700')}
      borderStyle={'solid'}
      nodeProps={props}
      rounded={'lg'}
      p={0}
    >
      <CustomHandle
        type="target"
        position={Position.Top}
        style={{ top: -6 }}
        id="top"
      />
      <HStack alignItems={'flex-start'}>
        <VStack gap={2} alignItems={'flex-start'} w={'full'}>
          <Box w={'full'}>
            <VStack
              gap={0}
              w={'full'}
              rounded={'md'}
              px={showContent ? 4 : 2}
              py={2}
              alignItems={'center'}
            >
              <Button
                pointerEvents={'none'}
                leftIcon={<Icon as={ArrowRightIcon} boxSize={4} />}
              >
                {props.data.form.buttonLabel}
              </Button>
            </VStack>
            {props.data.form.required_fields.length > 0 && showContent && (
              <VStack mx={4} mb={4}>
                <Text fontSize={'sm'}>Request fields:</Text>
                <UnorderedList spacing={0.5}>
                  {props.data.form.required_fields.map((field: string) => {
                    return (
                      <ListItem>
                        <Tag
                          key={field}
                          fontSize={'xs'}
                          fontFamily={'monospace'}
                          colorScheme="purple"
                        >
                          <TagLabel>{field}</TagLabel>
                        </Tag>
                      </ListItem>
                    );
                  })}
                </UnorderedList>
              </VStack>
            )}
          </Box>
        </VStack>
      </HStack>
      <CustomHandle
        type="source"
        position={Position.Bottom}
        style={{ bottom: -6 }}
        id="bottom"
      />
    </NodeBox>
  );
}

UserActionNode.type = 'user_action';
UserActionNode.autoRuns = false;

UserActionNode.getDefaultNode = (): UserActionNodeType => {
  return {
    id: `${UserActionNode.type}_${generateRandomCodeForNodes()}`,
    type: UserActionNode.type,
    data: {
      state_callbacks: {
        on_enter: [
          {
            func: 'run_save_fields',
            args: {
              required_fields: [],
            },
          },
        ],
        on_exit: [],
      },
      form: {
        required_fields: [],
        buttonLabel: 'Submit',
      },
      transition_callbacks: {
        prepare: [
          {
            func: 'validate_form',
            args: {
              required_fields: [],
            },
          },
        ],
        conditions: [],
        before: [],
        after: [],
      },
    },
    position: { x: 0, y: 0 },
  };
};

export default UserActionNode;
