import {
  Center,
  Fade,
  Image,
  VStack,
  useColorModeValue,
} from '@chakra-ui/react';
import NoComments from './img/no-comments.svg';
import NoFindings from './img/no-findings-inventory-model.svg';
import NoGuidelines from './img/no-guidelines.svg';
import NoModels from './img/no-models.svg';
import NoReports from './img/no-reports.svg';
import WaitAppDoc from './img/waiting-app-doc.svg';
import WaitAppRep from './img/waiting-app-rep.svg';
import NoActivity from './img/no-activity.svg';
import NoWorkflow from './img/no-workflow.svg';
import NoActivityLight from './img/no-activity-light.svg';
import NoHistory from './img/no-history.svg';
import NoUser from './img/user-missing.svg';

export interface IEmptyStateDisplayProps {
  variant?:
    | 'no-comments'
    | 'no-findings'
    | 'no-guidelines'
    | 'no-models'
    | 'no-reports'
    | 'wait-app-doc'
    | 'no-activity'
    | 'no-workflow'
    | 'wait-app-rep'
    | 'no-activity-light'
    | 'no-history'
    | 'no-user';
  size?: 'sm' | 'md';
  children?: any;
}

export function EmptyStateDisplay({
  variant,
  size = 'md',
  children,
}: IEmptyStateDisplayProps) {
  const illustration: string =
    variant === 'no-comments'
      ? NoComments
      : variant === 'no-findings'
      ? NoFindings
      : variant === 'no-guidelines'
      ? NoGuidelines
      : variant === 'no-models'
      ? NoModels
      : variant === 'no-reports'
      ? NoReports
      : variant === 'wait-app-doc'
      ? WaitAppDoc
      : variant === 'wait-app-rep'
      ? WaitAppRep
      : variant === 'no-activity'
      ? NoActivity
      : variant === 'no-workflow'
      ? NoWorkflow
      : variant === 'no-activity-light'
      ? NoActivityLight
      : variant === 'no-history'
      ? NoHistory
      : variant === 'no-user'
      ? NoUser
      : '';

  // Adjust sizes based on the size prop
  const paddingY = size === 'sm' ? 4 : 12;

  return (
    <Fade in={true} transition={{ enter: { duration: 0.3 } }}>
      <Center color={'neutral.500'}>
        <VStack
          spacing={0}
          mb={0}
          bg={useColorModeValue(
            'radial-gradient(ellipse at 50% 35%, var(--chakra-colors-neutral-50) 15%, transparent 30%);',
            'radial-gradient(ellipse at 50% 35%, var(--chakra-colors-neutral-900) 15%, transparent 30%);',
          )}
          py={paddingY}
          w={'full'}
        >
          {illustration && size !== 'sm' && (
            <Image src={illustration} alt={variant} boxSize={'120px'} />
          )}
          <VStack
            spacing={1}
            fontStyle={'oblique'}
            color={'neutral.500'}
            gap={size === 'sm' ? 1 : 2}
          >
            {children}
          </VStack>
        </VStack>
      </Center>
    </Fade>
  );
}
