import _ from 'lodash';
import { Edge, Node, Position } from 'reactflow';
import { WorkflowSource } from '../../../../../models/workflow';
import { ApprovalNodeType } from '../types';

/**
 * This function takes an array of nodes and a new node.
 * If a node with the same id as the new node exists in the array, it updates that node with the new node.
 * If no such node exists, it adds the new node to the array.
 *
 * @param {Node[]} nodes - An array of nodes.
 * @param {Node} newNode - The new node to be inserted or updated in the array.
 * @returns {Node[]} A new array of nodes with the new node inserted or updated.
 */
export function upsertNode(nodes: Node[], newNode: Node): Node[] {
  const index = _.findIndex(nodes, { id: newNode.id });

  if (index !== -1) {
    // Node exists, update it
    // Using map to create a new array with the updated node
    return nodes.map((node, i) => (i === index ? newNode : node));
  } else {
    // Node doesn't exist, add it
    return [...nodes, newNode];
  }
}

/**
 * This function takes a source object containing 'nodes' and 'edges' arrays,
 * and returns a new object where the 'dragging', 'selected' and other properties
 * are removed from each node in the 'nodes' array.
 * The 'edges' array remains unchanged.
 *
 * @param {Object} source - The object containing 'nodes' and 'edges' arrays.
 * @returns {Object} A new object with the cleaned 'nodes' array and unchanged 'edges' array.
 */
export function cleanUpSource(source: WorkflowSource): WorkflowSource {
  const cleanedNodes = source.nodes.map(node => {
    // Destructure to remove unwanted properties from node
    const { dragging, selected, positionAbsolute, ...rest } = node;
    return rest;
  });

  const cleanedEdges = source.edges.map(edge => {
    // Destructure to remove unwanted properties from node
    const { markerStart, markerEnd, style, selected, ...rest } = edge;
    return rest;
  });

  return {
    ...source,
    nodes: cleanedNodes,
    edges: cleanedEdges,
  };
}

/**
 * This function calculates the intersection point of the line between the center of the intersectionNode and the target node.
 * It first calculates the centers of both nodes and the differences in x and y coordinates.
 * Then, based on whether the difference in x (dx) is greater than the difference in y (dy), it calculates the intersection coordinates.
 * If dx > dy, it means the horizontal distance is dominant, so it calculates the intersection based on the horizontal gradient.
 * If dx <= dy, it means the vertical distance is dominant, so it calculates the intersection based on the vertical gradient.
 * Finally, it clamps the x and y coordinates to be within the borders of both nodes.
 *
 * @param {any} intersectionNode - The node from which the intersection is calculated.
 * @param {any} targetNode - The node to which the intersection is calculated.
 * @returns {object} An object containing the x and y coordinates of the intersection point.
 */
export function getNodeIntersection(intersectionNode: any, targetNode: any) {
  const {
    width: intersectionNodeWidth,
    height: intersectionNodeHeight,
    positionAbsolute: intersectionNodePosition,
  } = intersectionNode;
  const {
    width: targetNodeWidth,
    height: targetNodeHeight,
    positionAbsolute: targetPosition,
  } = targetNode;

  // Centers of both nodes
  const x2 = intersectionNodePosition.x + intersectionNodeWidth / 2;
  const y2 = intersectionNodePosition.y + intersectionNodeHeight / 2;
  const x1 = targetPosition.x + targetNodeWidth / 2;
  const y1 = targetPosition.y + targetNodeHeight / 2;

  // Differences
  const dx = x1 - x2;
  const dy = y1 - y2;

  let x, y; // Intersection coordinates

  // Calculate intersection based on which node dimension is dominant
  if (Math.abs(dx) > Math.abs(dy)) {
    // Horizontal dominance
    const gradient = dy / dx;
    const halfWidth = intersectionNodeWidth / 2;
    const targetHalfWidth = targetNodeWidth / 2;

    if (dx > 0) {
      x = x2 + halfWidth;
      y = y2 + gradient * halfWidth;
    } else {
      x = x2 - halfWidth;
      y = y2 - gradient * halfWidth;
    }
  } else {
    // Vertical dominance
    const gradient = dx / dy;
    const halfHeight = intersectionNodeHeight / 2;
    const targetHalfHeight = targetNodeHeight / 2;

    if (dy > 0) {
      x = x2 + gradient * halfHeight;
      y = y2 + halfHeight;
    } else {
      x = x2 - gradient * halfHeight;
      y = y2 - halfHeight;
    }
  }

  // Clamp x and y to be within the borders of both nodes
  x = Math.max(
    intersectionNodePosition.x,
    Math.min(x, intersectionNodePosition.x + intersectionNodeWidth),
  );
  y = Math.max(
    intersectionNodePosition.y,
    Math.min(y, intersectionNodePosition.y + intersectionNodeHeight),
  );

  return { x, y };
}

/**
 * This function determines the position of an edge relative to a node based on the intersection point.
 * It first rounds the x and y coordinates of the node and intersection point.
 * Then, it checks the position of the intersection point relative to the node:
 * - If the x-coordinate of the intersection point is less than or equal to the x-coordinate of the node, the position is 'Left'.
 * - If the x-coordinate of the intersection point is greater than or equal to the x-coordinate of the node plus its width, the position is 'Right'.
 * - If the y-coordinate of the intersection point is less than or equal to the y-coordinate of the node, the position is 'Top'.
 * - If the y-coordinate of the intersection point is greater than or equal to the y-coordinate of the node plus its height, the position is 'Bottom'.
 * If none of these conditions are met, the position defaults to 'Top'.
 *
 * @param {any} node - The node relative to which the position is determined.
 * @param {any} intersectionPoint - The intersection point used to determine the position.
 * @returns {Position} The position of the edge relative to the node.
 */
export function getEdgePosition(node: any, intersectionPoint: any) {
  const n = { ...node.positionAbsolute, ...node };
  const nx = Math.round(n.x);
  const ny = Math.round(n.y);
  const px = Math.round(intersectionPoint.x);
  const py = Math.round(intersectionPoint.y);

  if (px <= nx + 1) {
    return Position.Left;
  }
  if (px >= nx + n.width - 1) {
    return Position.Right;
  }
  if (py <= ny + 1) {
    return Position.Top;
  }
  if (py >= n.y + n.height - 1) {
    return Position.Bottom;
  }

  return Position.Top;
}

// returns the parameters (sx, sy, tx, ty, sourcePos, targetPos) you need to create an edge
export function getEdgeParams(source: any, target: any) {
  const sourceIntersectionPoint = getNodeIntersection(source, target);
  const targetIntersectionPoint = getNodeIntersection(target, source);

  const sourcePos = getEdgePosition(source, sourceIntersectionPoint);
  const targetPos = getEdgePosition(target, targetIntersectionPoint);

  return {
    sx: sourceIntersectionPoint.x,
    sy: sourceIntersectionPoint.y,
    tx: targetIntersectionPoint.x,
    ty: targetIntersectionPoint.y,
    sourcePos,
    targetPos,
  };
}

export function createNodesAndEdges() {
  const nodes = [];
  const edges = [];
  const center = { x: window.innerWidth / 2, y: window.innerHeight / 2 };

  nodes.push({ id: 'target', data: { label: 'Target' }, position: center });

  for (let i = 0; i < 8; i++) {
    const degrees = i * (360 / 8);
    const radians = degrees * (Math.PI / 180);
    const x = 250 * Math.cos(radians) + center.x;
    const y = 250 * Math.sin(radians) + center.y;

    nodes.push({ id: `${i}`, data: { label: 'Source' }, position: { x, y } });

    edges.push({
      id: `edge-${i}`,
      target: 'target',
      source: `${i}`,
      type: 'floating',
    });
  }

  return { nodes, edges };
}

export function getApprovals(nodes: Node[]) {
  return nodes
    .filter(node => node.id.startsWith('approval_'))
    .map((node: ApprovalNodeType) => {
      const approvalCallback = node.data.state_callbacks.on_enter.find(
        callback => callback.func === 'run_approvals',
      );
      return approvalCallback?.args;
    });
}
