import {
  Button,
  Collapse,
  Flex,
  Heading,
  HStack,
  Icon,
  IconButton,
  Tag,
  TagLabel,
  Text,
  useColorModeValue,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import React, { useEffect, useMemo } from 'react';
import {
  TAttestationExecution,
  TAttestationStatuses,
} from '../../models/attestation';
import {
  ArrowBackIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  CloseIcon,
} from '@chakra-ui/icons';
import { QueryObserverResult, useQuery } from 'react-query';
import API from '../../api/API';
import { LoadingContainer } from '../LoadingContainer';
import AvatarProxy from '../AvatarProxy';
import { useSearchParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { CKEditorWrapper } from '../TextContentEditor/CKEditorWrapper';
import { UsersContext } from '../../contexts';
import AttestationTransitionModal from './AttestationTransitionModal';
import AttestationStatus from './AttestationStatus';
import AttestationActivityModal from './AttestationActivityModal';
import { ExclamationCircleIcon, SignalIcon } from '@heroicons/react/24/solid';
import { useFlags } from '../../hooks/useFlags';

type RefreshAttestations = QueryObserverResult<
  TAttestationExecution[],
  unknown
> | void;

export const AttestationSidebarContext = React.createContext({
  isFetchingExecutions: true,
  showSidebar: false,
  selectedAttestationExecution: null as TAttestationExecution | null,
  attestationExecutions: [] as TAttestationExecution[],
  setShowSidebar: (open: boolean) => {},
  setSelectedAttestationExecution: (
    attestation: TAttestationExecution | null,
  ) => {},
  refetchAttestations: async (): Promise<RefreshAttestations> => {},
});

export const AttestationProviderWrapper = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { currentOrganization } = React.useContext(UsersContext);
  const [showSidebar, setShowSidebar] = React.useState(false);
  const [selectedAttestationExecution, setSelectedAttestationExecution] =
    React.useState<TAttestationExecution | null>(null);
  const { attestationsUi } = useFlags();

  const {
    data: attestationExecutions,
    isFetching,
    refetch,
  } = useQuery(
    ['attestation-executions', currentOrganization?.cuid],
    async () => {
      return API.GetAttestationExecutions();
    },
    {
      initialData: [],
      enabled: !!attestationsUi,
    },
  );

  useEffect(() => {
    if (!showSidebar) {
      setSelectedAttestationExecution(null);
    }
  }, [showSidebar]);

  return (
    <AttestationSidebarContext.Provider
      value={{
        isFetchingExecutions: isFetching,
        showSidebar,
        selectedAttestationExecution,
        attestationExecutions: attestationExecutions || [],
        setShowSidebar,
        setSelectedAttestationExecution,
        refetchAttestations: refetch,
      }}
    >
      {children}
    </AttestationSidebarContext.Provider>
  );
};

const AttestationExecutionItem = ({
  attestationEx,
  onClick,
}: {
  attestationEx: TAttestationExecution;
  onClick?: () => void;
}) => {
  const overDueDays = useMemo(() => {
    const dueDate = dayjs(attestationEx.schedule_snapshot_json.end_date);
    const now = dayjs();
    return now.diff(dueDate, 'days');
  }, [attestationEx.schedule_snapshot_json.end_date]);

  const isSignedOff = attestationEx.current_status === 'SIGNED_OFF';

  return (
    <VStack
      key={attestationEx.cuid}
      w="full"
      align="flex-start"
      borderWidth={1}
      borderColor={'var(--chakra-colors-chakra-border-color)'}
      borderRadius={'md'}
      bg={useColorModeValue('white', 'transparent')}
      _hover={{
        cursor: 'pointer',
        bg: useColorModeValue('brandSecondary.25', 'brandSecondary.950'),
        borderColor: useColorModeValue(
          'brandSecondary.100',
          'brandSecondary.800',
        ),
        color: useColorModeValue('inherit', 'brandSecondary.25'),
      }}
      transition="all 0.3s ease-in-out"
      onClick={onClick}
      gap={0}
      mb={2}
    >
      <VStack px={3} py={3} w="full" align="flex-start">
        {!isSignedOff && overDueDays > 0 && (
          <HStack
            w="full"
            bgColor={'red.100'}
            fontSize="sm"
            gap={0.5}
            px={5}
            py={1}
          >
            <Icon as={ExclamationCircleIcon} boxSize={4} color="red.500" />
            <Text color="red.500">
              Overdue by {overDueDays} day{overDueDays > 1 ? 's' : ''}
            </Text>
          </HStack>
        )}
        <VStack w="full" align="flex-start">
          <Heading as="h5">
            {attestationEx.attestation_snapshot_json.name}
          </Heading>
          <HStack alignSelf="flex-end">
            <Tag size="md">
              <AvatarProxy
                size={'xs'}
                borderWidth={0}
                key={`${attestationEx.cuid}-avatar`}
                name={attestationEx.owner.name}
                src={attestationEx.owner.picture}
                ml={-2}
                mr={2}
              />
              <TagLabel>{attestationEx.owner.name}</TagLabel>
            </Tag>
            <AttestationStatus
              attestationStatus={attestationEx.current_status}
            />
          </HStack>
        </VStack>
        <Tag alignSelf="flex-end">
          {!isSignedOff ? (
            <>
              <strong>Due Date: &nbsp;</strong>
              {dayjs(attestationEx.schedule_snapshot_json.end_date).format(
                'ddd, MMM D, YYYY',
              )}
            </>
          ) : (
            <>
              <strong>Signed Off: &nbsp;</strong>
              {dayjs(attestationEx.signed_off_at).format('ddd, MMM D, YYYY')}
            </>
          )}
        </Tag>
      </VStack>
    </VStack>
  );
};

const AttestationExecutionList = () => {
  const { attestationExecutions, setSelectedAttestationExecution } =
    React.useContext(AttestationSidebarContext);

  const [showHistory, setShowHistory] = React.useState(false);

  const { activeAttestations, completedAttestations } = useMemo(() => {
    const result = {
      activeAttestations: [] as TAttestationExecution[],
      completedAttestations: [] as TAttestationExecution[],
    };

    attestationExecutions.forEach(attestationEx => {
      if (attestationEx.current_status === 'SIGNED_OFF') {
        result.completedAttestations.push(attestationEx);
      } else {
        result.activeAttestations.push(attestationEx);
      }
    });

    return result;
  }, [attestationExecutions]);

  return (
    <VStack p={4} w="full" flexGrow={1} align="flex-start" overflowY="auto">
      <Flex h={'40px'} align="center">
        <Heading as="h2">Attestations</Heading>
      </Flex>
      {activeAttestations.length > 0 && (
        <>
          <Flex h={'40px'} align="center">
            <Heading as="h4">Active</Heading>
          </Flex>
          {activeAttestations.map(attestationEx => (
            <AttestationExecutionItem
              attestationEx={attestationEx}
              onClick={() => setSelectedAttestationExecution(attestationEx)}
            />
          ))}
        </>
      )}
      {completedAttestations.length > 0 && (
        <>
          <HStack
            align="center"
            onClick={() => setShowHistory(!showHistory)}
            cursor="pointer"
            py={2}
          >
            <Heading as="h4">History</Heading>
            {showHistory ? (
              <ChevronUpIcon boxSize={6} />
            ) : (
              <ChevronDownIcon boxSize={6} />
            )}
          </HStack>
          <Collapse in={showHistory} animateOpacity style={{ width: '100%' }}>
            {completedAttestations.map(attestationEx => (
              <AttestationExecutionItem
                attestationEx={attestationEx}
                onClick={() => setSelectedAttestationExecution(attestationEx)}
              />
            ))}
          </Collapse>
        </>
      )}
    </VStack>
  );
};

type ActionButtons = {
  label: string;
  toStatus: TAttestationStatuses;
  variant: 'primary' | 'outline' | 'ghost';
  color?: string;
};

const AttestationExecutionDetails: React.FC<{
  attestationExecution: TAttestationExecution;
}> = ({ attestationExecution }) => {
  const transitionModal = useDisclosure();
  const activityModal = useDisclosure();
  const { currentUser, userHasPermission } = React.useContext(UsersContext);
  const { refetchAttestations, setSelectedAttestationExecution } =
    React.useContext(AttestationSidebarContext);
  const [toStatus, setToStatus] = React.useState<TAttestationStatuses>(
    attestationExecution.current_status,
  );

  const [newQuestionaire, setNewQuestionaire] = React.useState<string>(
    attestationExecution.questionaire,
  );

  const isAttestationOwner =
    attestationExecution.owner.cuid === currentUser?.cuid;
  const isAttestationReviewer = userHasPermission(
    ['review_attestation'],
    'any',
  );
  const isAttestationApprover = userHasPermission(
    ['approve_attestation'],
    'any',
  );

  let questionaireReadOnly = true;
  let actionButtons: ActionButtons[] = [];

  useEffect(() => {
    setNewQuestionaire(attestationExecution.questionaire);
  }, [attestationExecution]);

  const status = attestationExecution.current_status;

  switch (status) {
    case 'NOT_STARTED':
      if (isAttestationOwner) {
        questionaireReadOnly = true;
        actionButtons = [
          {
            label: 'Start Attestation',
            toStatus: 'IN_PROGRESS',
            variant: 'primary',
          },
        ];
      }
      break;
    case 'IN_PROGRESS':
      if (isAttestationOwner) {
        questionaireReadOnly = false;
        actionButtons = [
          {
            label: 'Submit for Review',
            toStatus: 'READY_FOR_REVIEW',
            variant: 'primary',
          },
        ];
      }
      break;
    case 'READY_FOR_REVIEW':
      if (isAttestationReviewer) {
        questionaireReadOnly = true;
        actionButtons = [
          {
            label: 'Reject',
            toStatus: 'IN_PROGRESS',
            variant: 'ghost',
            color: 'red',
          },
          {
            label: 'Accept',
            toStatus: 'REVIEWED',
            variant: 'primary',
          },
        ];
      }
      break;
    case 'REVIEWED':
      if (isAttestationApprover) {
        questionaireReadOnly = true;
        actionButtons = [
          {
            label: 'Sign Off',
            toStatus: 'SIGNED_OFF',
            variant: 'primary',
          },
        ];
      }
      break;
    case 'SIGNED_OFF':
      questionaireReadOnly = true;
      break;
  }

  const isSignedOff = status === 'SIGNED_OFF';

  return (
    <>
      <AttestationTransitionModal
        attestationExecution={attestationExecution}
        toStatus={toStatus}
        isOpen={transitionModal.isOpen}
        onClose={transitionModal.onClose}
        onAccept={async (notes: string) => {
          try {
            await API.TransitionAttestationExecutionStatus(
              attestationExecution.cuid,
              toStatus,
              notes,
              newQuestionaire,
            );
            setSelectedAttestationExecution(null);
            await refetchAttestations();
            return { success: true };
          } catch (e) {
            return { success: false, message: 'Error changing status' };
          }
        }}
      />
      <AttestationActivityModal
        attestationExecution={attestationExecution}
        isOpen={activityModal.isOpen}
        onClose={activityModal.onClose}
      />
      <VStack p={4} w="full" align="flex-start" mb={12}>
        <Flex h={'40px'} align="center">
          <Heading as="h3">
            {attestationExecution.schedule_snapshot_json.name}
          </Heading>
        </Flex>
        <HStack alignSelf="flex-end">
          <Tag size="md">
            <AvatarProxy
              size={'xs'}
              borderWidth={0}
              key={`${attestationExecution.cuid}-avatar`}
              name={attestationExecution.owner.name}
              src={attestationExecution.owner.picture}
              ml={-2}
              mr={2}
            />
            <TagLabel>{attestationExecution.owner.name}</TagLabel>
          </Tag>
          <AttestationStatus
            attestationStatus={attestationExecution.current_status}
          />
        </HStack>
        <Tag alignSelf="flex-end">
          {!isSignedOff ? (
            <>
              <strong>Due Date: &nbsp;</strong>{' '}
              {dayjs(
                attestationExecution.schedule_snapshot_json.end_date,
              ).format('ddd, MMM D, YYYY')}
            </>
          ) : (
            <>
              <strong>Signed Off: &nbsp;</strong>
              {dayjs(attestationExecution.signed_off_at).format(
                'ddd, MMM D, YYYY',
              )}
            </>
          )}
        </Tag>
        <Button
          alignSelf="flex-end"
          variant="ghost"
          colorScheme="primary"
          onClick={activityModal.onOpen}
          leftIcon={<Icon as={SignalIcon} boxSize={4} />}
        >
          See Activity
        </Button>
        <Flex overflowY="auto" w="full">
          <CKEditorWrapper
            readOnly={questionaireReadOnly}
            withReadOnlyStyle={questionaireReadOnly}
            text={newQuestionaire}
            onChange={text => {
              setNewQuestionaire(text);
            }}
            enabledFeatures={{
              images: false,
              comments: false,
              revisions: false,
              deleteBlock: false,
              generateWithAI: false,
            }}
            hideTooltip={true}
            showOutline={true}
            autoSave={false}
            containerStyle={{
              width: '100%',
              borderWidth: 1,
              borderColor: useColorModeValue('neutral.200', 'neutral.800'),
              alignSelf: 'flex-start',
              borderRadius: 4,
              opacity: questionaireReadOnly ? 0.8 : 1,
            }}
          />
        </Flex>
        <HStack
          w="full"
          justify={actionButtons.length > 1 ? 'space-between' : 'flex-end'}
        >
          {actionButtons.map(({ label, toStatus, variant, color }) => (
            <Button
              key={label}
              variant={variant}
              colorScheme={color}
              onClick={() => {
                setToStatus(toStatus);
                transitionModal.onOpen();
              }}
            >
              {label}
            </Button>
          ))}
        </HStack>
      </VStack>
    </>
  );
};

const AttestationSidebar = () => {
  const {
    isFetchingExecutions,
    showSidebar,
    selectedAttestationExecution,
    setShowSidebar,
    setSelectedAttestationExecution,
  } = React.useContext(AttestationSidebarContext);
  const [searchParams] = useSearchParams();
  const openAttestations = searchParams.get('open_attestations');

  const nonAttestationPath =
    location.pathname !== '/model-inventory' &&
    location.pathname !== '/model-findings';

  useEffect(() => {
    if (openAttestations === 'true') {
      setShowSidebar(true);
    }
  }, [openAttestations]);

  if (nonAttestationPath || !showSidebar) {
    return null;
  }

  return (
    <VStack
      maxW="350px"
      minW="350px"
      h={'full'}
      maxH="100%"
      align="flex-start"
      borderLeft="1px solid var(--chakra-colors-chakra-border-color)"
      data-testid="attestation-sidebar"
    >
      <LoadingContainer isLoading={isFetchingExecutions}>
        <HStack
          bg={useColorModeValue('brandSecondary.25', 'brandSecondary.900')}
          w="full"
          justify="space-between"
          borderTop={'2px solid'}
          borderTopColor={'brandSecondary.500'}
          p={2}
          py={2.5}
        >
          {selectedAttestationExecution ? (
            <Button
              leftIcon={<ArrowBackIcon />}
              variant="ghost"
              onClick={() => setSelectedAttestationExecution(null)}
            >
              Attestations
            </Button>
          ) : (
            <div />
          )}
          <IconButton
            aria-label="Close sidebar"
            icon={<Icon as={CloseIcon} boxSize={3} />}
            onClick={() => setShowSidebar(false)}
            variant="ghost"
            cursor={'pointer'}
          />
        </HStack>
        <Flex flexGrow={1} w="100%" overflow="hidden">
          {!selectedAttestationExecution && <AttestationExecutionList />}
          {selectedAttestationExecution && (
            <AttestationExecutionDetails
              attestationExecution={selectedAttestationExecution}
            />
          )}
        </Flex>
      </LoadingContainer>
    </VStack>
  );
};

export default AttestationSidebar;
