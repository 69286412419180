import 'react-datepicker/dist/react-datepicker.css';

import {
  Box,
  Button,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
  HStack,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Tag,
  TagCloseButton,
  TagLabel,
  TagLeftIcon,
  Text,
  useColorModeValue,
  useDisclosure,
  VStack,
  Wrap,
} from '@chakra-ui/react';
import { ContentPageTitle } from '../../components/Layout';
import { LoadingContainer } from '../../components/LoadingContainer';
import { useContext, useEffect, useMemo, useState } from 'react';
import {
  TInventoryModel,
  TModelInventoryFilters,
} from '../../models/inventory_model';
import {
  allFiltersEmpty,
  convertRJSFToCustomFieldJSON,
  getSchemaPropertyByKey,
  schemaPropertiesToSchemaPropertyItems,
} from '../../utils';
import { FunnelIcon, XMarkIcon } from '@heroicons/react/24/solid';
import {
  ClipboardDocumentCheckIcon,
  MagnifyingGlassIcon,
  CubeIcon,
} from '@heroicons/react/24/outline';
import ModelInventoryFilters from '../../components/ModelInventoryFilters';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { APIRequestSortBy, AvailableSort } from '../../models/utils';
import { BarsArrowDownIcon } from '@heroicons/react/20/solid';
import ListingSearchButton from '../../components/ListingSearchButton';
import ListingSortButton from '../../components/ListingSortButton';
import { Copyright } from '../../components/Copyright';
import ModelInventoryAdd from '../../components/ModelInventoryAdd';
import CubesIcon from '../../components/icons/CubesIcon';
import { Squares2X2Icon, TableCellsIcon } from '@heroicons/react/24/outline';
import GetStartedChecklist from '../../components/GetStartedChecklist';

import useModelSchema from '../../hooks/useModelSchema';
import TableColumnPicker from '../../components/TableColumnPicker';
import { useUserUISettings } from '../../hooks/useUserUISettings';
import AvatarProxy from '../../components/AvatarProxy';
import MoreInfoPopOver from '../../components/MoreInfoPopOver';
import ManageViewsButton from '../../components/ManageViewsButton';
import { TSavedView } from '../../models/saved_view';
import ModelInventoryTable, {
  MODELS_TABLE_ALL_NON_CUSTOM_COLUMNS,
} from '../../components/ModelInventoryTable';
import { ArrowDownTrayIcon } from '@heroicons/react/24/outline';
import dayjs from 'dayjs';
import API from '../../api/API';
import { CONFIG } from '../../config';
import { AttestationSidebarContext } from '../../components/AttestationSidebar';
import { InventoryModelDetails } from './InventoryModel/Overview';
import { SchemaPropertyItem } from '../../models/json_schemas';
import { useFlags } from '../../hooks/useFlags';
import InventoryModelContext from '../../contexts/InventoryModel';

const availableSorts: AvailableSort[] = [
  {
    fieldLabel: 'Name',
    field: 'name',
    columnId: 'name',
    criteria: [
      {
        orderLabel: 'A to Z',
        order: 'asc',
      },
      {
        orderLabel: 'Z to A',
        order: 'desc',
      },
    ],
  },
  {
    fieldLabel: 'Business Unit',
    field: 'business_unit.name',
    columnId: 'business_unit',
    criteria: [
      {
        orderLabel: 'A to Z',
        order: 'asc',
      },
      {
        orderLabel: 'Z to A',
        order: 'desc',
      },
    ],
  },
  {
    fieldLabel: 'Use',
    field: 'use',
    columnId: 'use',
    criteria: [
      {
        orderLabel: 'A to Z',
        order: 'asc',
      },
      {
        orderLabel: 'Z to A',
        order: 'desc',
      },
    ],
  },
  {
    fieldLabel: 'Version',
    field: 'version',
    columnId: 'version',
    criteria: [
      {
        orderLabel: 'Low to High',
        order: 'asc',
      },
      {
        orderLabel: 'Hight to Low',
        order: 'desc',
      },
    ],
  },
  {
    fieldLabel: 'Tier',
    field: 'tiering',
    columnId: 'tier',
    criteria: [
      {
        orderLabel: 'Low to High',
        order: 'asc',
      },
      {
        orderLabel: 'High to Low',
        order: 'desc',
      },
    ],
  },
  {
    fieldLabel: 'Status',
    field: 'status',
    columnId: 'status',
    criteria: [
      {
        orderLabel: 'A to Z',
        order: 'asc',
      },
      {
        orderLabel: 'Z to A',
        order: 'desc',
      },
    ],
  },
  {
    fieldLabel: 'Creation date',
    field: 'created_at',
    columnId: 'created_at',
    criteria: [
      {
        orderLabel: 'Latest first',
        order: 'desc',
      },
      {
        orderLabel: 'Oldest first',
        order: 'asc',
      },
    ],
  },
  {
    fieldLabel: 'Vendor Name',
    field: 'vendor_name',
    columnId: 'vendor_name',
    criteria: [
      {
        orderLabel: 'A to Z',
        order: 'asc',
      },
      {
        orderLabel: 'Z to A',
        order: 'desc',
      },
    ],
  },
];

const initialSortBy: APIRequestSortBy = {
  label: 'Creation date',
  field: 'created_at',
  order: 'desc',
  orderLabel: 'Latest first',
};

const defaultColumns = MODELS_TABLE_ALL_NON_CUSTOM_COLUMNS.map(c => c.id);

export default function ModelInventory() {
  const { attestationsUi } = useFlags();
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedView, setSelectedView] = useState<TSavedView>();
  const filtersState = searchParams.get('filters');
  const register = searchParams.get('register');
  const {
    showSidebar,
    setShowSidebar,
    attestationExecutions,
    selectedAttestationExecution,
    setSelectedAttestationExecution,
  } = useContext(AttestationSidebarContext);
  const [filters, setFilters] = useState<TModelInventoryFilters | undefined>(
    filtersState ? JSON.parse(decodeURIComponent(filtersState)) : undefined,
  );
  const [modelInventorySchema, setModelInventorySchema] = useState<
    SchemaPropertyItem[]
  >([]);
  const [selectedModelSnapshot, setSelectedModelSnapshot] =
    useState<TInventoryModel>();
  const viewSnapshotDisclosure = useDisclosure();
  const { setInventoryModel } = useContext(InventoryModelContext);

  useEffect(() => {
    if (filters) {
      setSearchParams(
        `?filters=${encodeURIComponent(JSON.stringify(filters))}`,
      );
    }
  }, [filters]);

  const {
    getModelInventoryColumns,
    updateModelInventoryColumns,
    getDisplayTableModel,
    updateDisplayTableModel,
  } = useUserUISettings();

  const storedColumnConfig = getModelInventoryColumns();

  const [selectedColumns, setSelectedColumns] = useState<string[]>(
    !!storedColumnConfig ? storedColumnConfig : defaultColumns,
  );

  const storedDisplayTable = getDisplayTableModel();

  const [displayTable, setDisplayTable] = useState(storedDisplayTable);

  const [sortBy, setSortBy] = useState<APIRequestSortBy | undefined>(
    initialSortBy,
  );

  const {
    isLoading: loadingSchema,
    data: schemaData,
    propertyItems,
  } = useModelSchema();

  const navigate = useNavigate();

  const requiredCustomFields = useMemo(() => {
    if (schemaData) {
      const requiredSchemaKeys: string[] = [];

      Object.keys(schemaData.settings.properties).forEach(key => {
        if (schemaData.settings.properties[key].requiredOnRegistration) {
          requiredSchemaKeys.push(key);
        }
      });

      return requiredSchemaKeys.map(key => {
        const [schemaProperty, settingsProperty] = getSchemaPropertyByKey(
          schemaData,
          key,
        );
        return convertRJSFToCustomFieldJSON(
          key,
          schemaProperty,
          settingsProperty,
        );
      });
    }
    return [];
  }, [schemaData]);

  const onTableSort = (column: any[]) => {
    if (column.length > 0) {
      const c = availableSorts.find(s => s.columnId === column[0].id)!;
      const order = column[0].desc ? 'desc' : 'asc';
      const orderLabel = c.criteria.find(o => o.order === order)?.orderLabel;

      setSortBy({
        label: c.fieldLabel,
        field: c.field,
        order,
        orderLabel,
      });
    } else {
      setSortBy(initialSortBy);
    }
  };

  const [isExporting, setIsExporting] = useState(false);

  const handleExport = async () => {
    try {
      setIsExporting(true);
      const csvData = await API.ExportModelInventory(
        selectedColumns,
        filters,
        searchQuery,
        sortBy,
      );

      const timestamp = dayjs().format('YYYY-MM-DD-HH-mm-ss');
      const url = window.URL.createObjectURL(csvData);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `model-inventory-${timestamp}.csv`);
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Failed to export model inventory:', error);
    } finally {
      setIsExporting(false);
    }
  };

  const modelInventoryGetDataOverride = useMemo(() => {
    if (selectedAttestationExecution) {
      const newColumns = ['name'];

      selectedAttestationExecution.attestation_snapshot_json.custom_fields_json?.forEach(
        field => {
          newColumns.push(field.fieldId);
        },
      );

      setSelectedColumns(newColumns);

      return async (page: number, pageSize: number) => {
        const { models, schema } =
          await API.GetAttestationExecutionSnapshotModels(
            selectedAttestationExecution.cuid,
          );

        const propertyItems = schemaPropertiesToSchemaPropertyItems(schema)
          .filter(p => newColumns.includes(p.key))
          .sort((a, b) => a.key.localeCompare(b.key));

        setModelInventorySchema(propertyItems);

        return {
          results: models.slice((page - 1) * pageSize, page * pageSize),
          total: models.length,
          limit: pageSize,
          page: page,
        };
      };
    } else {
      setSelectedColumns(
        !!storedColumnConfig ? storedColumnConfig : defaultColumns,
      );
    }
    return undefined;
  }, [selectedAttestationExecution]);

  return (
    <VStack
      overflow="inherit"
      align="flex-start"
      gap={0}
      bg={
        selectedAttestationExecution
          ? useColorModeValue('brandSecondary.25', 'brandSecondary.900')
          : 'inherit'
      }
      borderTop={selectedAttestationExecution ? '2px solid' : 'none'}
      borderTopColor={'brandSecondary.500'}
      flexGrow={1}
    >
      <Modal
        isOpen={viewSnapshotDisclosure.isOpen}
        onClose={viewSnapshotDisclosure.onClose}
        size={'6xl'}
        scrollBehavior={'inside'}
      >
        <ModalOverlay />
        <ModalContent bgColor="white">
          <ModalHeader>{selectedModelSnapshot?.name}</ModalHeader>
          <ModalCloseButton />
          <ModalBody pt={10}>
            <InventoryModelDetails
              inventoryModel={selectedModelSnapshot!}
              schema={modelInventorySchema}
              attestationExecution={selectedAttestationExecution || undefined}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
      {selectedAttestationExecution && (
        <Stack justifyContent={'center'} pl={8} py={5}>
          <Breadcrumb as={'h5'}>
            <BreadcrumbItem>
              <BreadcrumbLink
                href="#"
                onClick={() => setSelectedAttestationExecution(null)}
                color={useColorModeValue(
                  'brandSecondary.600',
                  'brandSecondary.100',
                )}
              >
                Attestations
              </BreadcrumbLink>
            </BreadcrumbItem>

            <BreadcrumbItem isCurrentPage>
              <BreadcrumbLink href="#">
                {selectedAttestationExecution.schedule_snapshot_json.name}
              </BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>
        </Stack>
      )}
      <Box
        py={10}
        px={8}
        flex={1}
        overflow={'auto'}
        className="no-scrollbar"
        bg={useColorModeValue('white', 'black')}
        w={'full'}
      >
        <LoadingContainer isLoading={loadingSchema}>
          <Stack>
            <HStack>
              <Flex
                width={'full'}
                justify={'space-between'}
                alignItems={'flex-end'}
              >
                <HStack gap={5} pl={2} color={'inherit'}>
                  <Icon as={CubesIcon} boxSize={10} />
                  <ContentPageTitle>
                    Inventory
                    <MoreInfoPopOver
                      title="Inventory"
                      description="Track comprehensive details for all your models throughout the model lifecycle."
                      link={`${CONFIG.VALIDMIND_DOCS_URL}/guide/model-inventory/working-with-model-inventory.html`}
                      placement="right-end"
                      iconProps={{
                        ml: 2,
                        fill: 'brand.base',
                      }}
                    />
                  </ContentPageTitle>
                </HStack>

                {!selectedAttestationExecution && (
                  <ModelInventoryAdd
                    open={!!register}
                    requiredCustomFields={requiredCustomFields}
                  />
                )}
              </Flex>
            </HStack>

            <HStack
              gap={8}
              w={'full'}
              pl={0.5}
              justifyContent={'space-between'}
            >
              {!selectedAttestationExecution ? (
                <ManageViewsButton
                  viewType="model"
                  selectedView={selectedView}
                  onViewSelected={(view: TSavedView) => {
                    setSelectedColumns(view.content.columns);
                    setSortBy(view.content.sortBy);
                    setFilters(view.content.filters);
                    setSelectedView(view);
                  }}
                  getCurrentContent={() => ({
                    columns: selectedColumns,
                    sortBy,
                    filters,
                  })}
                />
              ) : (
                <HStack />
              )}
              <HStack>
                {!selectedAttestationExecution && (
                  <>
                    <ListingSearchButton
                      term={searchQuery}
                      setTerm={setSearchQuery}
                      popOverTitle="Search by model name"
                    />
                    <ModelInventoryFilters
                      filters={filters}
                      setFilters={setFilters}
                    />
                    <TableColumnPicker
                      selectedColumnIds={selectedColumns}
                      allColumns={[
                        ...MODELS_TABLE_ALL_NON_CUSTOM_COLUMNS.map(c => ({
                          id: c.id,
                          label: c.Header,
                        })),
                        ...propertyItems.map(p => ({
                          id: p.key,
                          label: p.title,
                        })),
                      ]}
                      setColumns={newColumns => {
                        updateModelInventoryColumns(newColumns);
                        setSelectedColumns(newColumns as any);
                      }}
                    />

                    <ListingSortButton
                      sorts={availableSorts}
                      sortBy={sortBy}
                      setSortBy={setSortBy}
                    />
                    <Button
                      variant={'ghost'}
                      onClick={() => {
                        updateDisplayTableModel(!displayTable);
                        setDisplayTable(!displayTable);
                      }}
                      data-testid="display-models-toggle"
                    >
                      <HStack mr={2}>
                        <Icon
                          as={TableCellsIcon}
                          boxSize={5}
                          display={!displayTable ? 'block' : 'none'}
                        />
                        <Icon
                          as={Squares2X2Icon}
                          boxSize={5}
                          display={displayTable ? 'block' : 'none'}
                        />
                      </HStack>
                      {displayTable ? 'Display Cards' : 'Display Table'}
                    </Button>
                    <Button
                      leftIcon={<Icon as={ArrowDownTrayIcon} />}
                      onClick={handleExport}
                      variant="ghost"
                      isLoading={isExporting}
                      loadingText="Exporting..."
                      disabled={isExporting}
                    >
                      Export
                    </Button>
                    {attestationsUi && attestationExecutions.length > 0 && (
                      <Button
                        isActive={showSidebar}
                        leftIcon={
                          <Icon as={ClipboardDocumentCheckIcon} boxSize={5} />
                        }
                        onClick={() => {
                          setShowSidebar(!showSidebar);
                        }}
                        variant="ghost"
                      >
                        Attestations
                      </Button>
                    )}
                  </>
                )}
              </HStack>
            </HStack>

            <HStack
              pl={5}
              alignItems={'flex-start'}
              gap={
                (filters && !allFiltersEmpty(filters)) || searchQuery ? 4 : 0
              }
            >
              {searchQuery && (
                <HStack margin={'0 !important'}>
                  <Flex
                    alignItems={'center'}
                    gap={2}
                    color={'neutral.500'}
                    whiteSpace={'nowrap'}
                  >
                    <Icon as={MagnifyingGlassIcon} width={5} height={5} />
                    <Text fontSize={'sm'} fontWeight={'semibold'}>
                      Searched:
                    </Text>
                    <Wrap>
                      <Tag
                        variant={'outline'}
                        colorScheme={'neutral'}
                        key={searchQuery}
                        size={'md'}
                      >
                        <TagLabel>
                          <strong>Model name contains: </strong>
                          {searchQuery}
                        </TagLabel>
                        <TagCloseButton onClick={() => setSearchQuery('')} />
                      </Tag>
                    </Wrap>
                  </Flex>
                </HStack>
              )}
              {filters && !allFiltersEmpty(filters) && (
                <HStack alignItems={'flex-start'}>
                  <Flex
                    alignItems={'center'}
                    gap={2}
                    color={'neutral.500'}
                    whiteSpace={'nowrap'}
                  >
                    <Icon as={FunnelIcon} width={5} height={5} />
                    <Text fontSize={'sm'} fontWeight={'semibold'}>
                      Filtered by:
                    </Text>
                  </Flex>

                  <Flex>
                    {filters && (
                      <Wrap>
                        {filters.cuids && filters.cuids.length > 0 && (
                          <Tag
                            key={'cuid-filter-tag'}
                            size={'md'}
                            borderRadius="full"
                          >
                            <TagLabel>
                              <strong>ID Filter: </strong>{' '}
                              {filters.cuids.length} Models
                            </TagLabel>
                            <TagCloseButton
                              onClick={() =>
                                setFilters({
                                  ...filters,
                                  cuids: [],
                                })
                              }
                            />
                          </Tag>
                        )}
                        {filters.tiering &&
                          filters.tiering.map(t => (
                            <Tag
                              variant={'outline'}
                              colorScheme={'neutral'}
                              key={t}
                              size={'md'}
                            >
                              <TagLabel>
                                <strong>Tier:</strong> {t}
                              </TagLabel>
                              <TagCloseButton
                                onClick={() =>
                                  setFilters({
                                    ...filters,
                                    tiering: filters.tiering.filter(
                                      o => o !== t,
                                    ),
                                  })
                                }
                              />
                            </Tag>
                          ))}
                        {filters.business_units &&
                          filters.business_units.map(u => (
                            <Tag
                              variant={'outline'}
                              colorScheme={'neutral'}
                              key={u.cuid}
                              size={'md'}
                            >
                              <TagLabel>
                                <strong>Business Unit:</strong> {u.name}
                              </TagLabel>
                              <TagCloseButton
                                onClick={() =>
                                  setFilters({
                                    ...filters,
                                    business_units:
                                      filters?.business_units.filter(
                                        o => o.cuid !== u.cuid,
                                      ),
                                  })
                                }
                              />
                            </Tag>
                          ))}

                        {filters.status &&
                          filters.status.map(s => (
                            <Tag
                              variant={'outline'}
                              colorScheme={'neutral'}
                              key={s.cuid}
                              size={'md'}
                            >
                              <TagLeftIcon as={CubeIcon} />
                              <TagLabel>
                                <strong>Status:</strong> {s.name}
                              </TagLabel>
                              <TagCloseButton
                                onClick={() =>
                                  setFilters({
                                    ...filters,
                                    status: filters?.status.filter(
                                      o => o.cuid !== s.cuid,
                                    ),
                                  })
                                }
                              />
                            </Tag>
                          ))}

                        {filters.uses &&
                          filters.uses.map(use => (
                            <Tag
                              variant={'outline'}
                              colorScheme={'neutral'}
                              key={use}
                              size={'md'}
                            >
                              <TagLabel>
                                <strong>Use:</strong> {use}
                              </TagLabel>
                              <TagCloseButton
                                onClick={() =>
                                  setFilters({
                                    ...filters,
                                    uses: filters?.uses.filter(o => o !== use),
                                  })
                                }
                              />
                            </Tag>
                          ))}

                        {filters.owners &&
                          filters.owners.map(u => (
                            <Tag
                              variant={'outline'}
                              colorScheme={'neutral'}
                              key={u.cuid}
                              size={'md'}
                            >
                              <AvatarProxy
                                src={u.picture}
                                size="xs"
                                name={u.name}
                                ml={-2}
                                mr={2}
                              />
                              <TagLabel>
                                <strong>Owner:</strong> {u.name}
                              </TagLabel>
                              <TagCloseButton
                                onClick={() =>
                                  setFilters({
                                    ...filters,
                                    owners: filters?.owners.filter(
                                      o => o.cuid !== u.cuid,
                                    ),
                                  })
                                }
                              />
                            </Tag>
                          ))}
                        {filters.developers &&
                          filters.developers.map(u => (
                            <Tag
                              variant={'outline'}
                              colorScheme={'neutral'}
                              key={u.cuid}
                              size={'md'}
                            >
                              <AvatarProxy
                                src={u.picture}
                                size="xs"
                                name={u.name}
                                ml={-2}
                                mr={2}
                              />
                              <TagLabel>
                                <strong>Developer:</strong> {u.name}
                              </TagLabel>
                              <TagCloseButton
                                onClick={() =>
                                  setFilters({
                                    ...filters,
                                    developers: filters?.developers.filter(
                                      o => o.cuid !== u.cuid,
                                    ),
                                  })
                                }
                              />
                            </Tag>
                          ))}
                        {filters.validators &&
                          filters.validators.map(u => (
                            <Tag
                              variant={'outline'}
                              colorScheme={'neutral'}
                              key={u.cuid}
                              size={'md'}
                            >
                              <AvatarProxy
                                src={u.picture}
                                size="xs"
                                name={u.name}
                                ml={-2}
                                mr={2}
                              />
                              <TagLabel>
                                <strong>Validator:</strong> {u.name}
                              </TagLabel>
                              <TagCloseButton
                                onClick={() =>
                                  setFilters({
                                    ...filters,
                                    validators: filters?.validators.filter(
                                      o => o.cuid !== u.cuid,
                                    ),
                                  })
                                }
                              />
                            </Tag>
                          ))}
                      </Wrap>
                    )}
                  </Flex>
                </HStack>
              )}

              {sortBy && (
                <HStack margin={'0 !important'}>
                  <Flex
                    alignItems={'center'}
                    gap={2}
                    whiteSpace={'nowrap'}
                    color={'neutral.500'}
                  >
                    <Icon as={BarsArrowDownIcon} boxSize={5} />
                    <Text fontSize={'sm'} fontWeight={'semibold'}>
                      Sorted by:
                    </Text>

                    <Wrap>
                      {sortBy && (
                        <Tag key={sortBy.field} size={'md'}>
                          <TagLabel>
                            <strong>{sortBy.label}: </strong>
                            {sortBy.orderLabel}
                          </TagLabel>
                        </Tag>
                      )}
                    </Wrap>
                  </Flex>
                </HStack>
              )}
              {selectedView && (
                <Button
                  variant={'ghost'}
                  size={'xs'}
                  fontSize={'sm'}
                  rounded={'full'}
                  leftIcon={<Icon as={XMarkIcon} boxSize={4} />}
                  onClick={() => {
                    setSelectedView(undefined);
                    setSelectedColumns(defaultColumns);
                    setSortBy(initialSortBy);
                    setFilters(undefined);
                  }}
                >
                  Clear All
                </Button>
              )}
            </HStack>
            <ModelInventoryTable
              displayTable={displayTable}
              filters={filters}
              searchQuery={searchQuery}
              sortBy={sortBy}
              columns={selectedColumns}
              pageSize={30}
              onTableSort={onTableSort}
              onClickRow={(row: any) => {
                if (!selectedAttestationExecution) {
                  navigate(`/model-inventory/${row.original.cuid}/overview`);
                } else {
                  setInventoryModel(row.original);
                  setSelectedModelSnapshot(row.original);
                  viewSnapshotDisclosure.onOpen();
                }
              }}
              getData={modelInventoryGetDataOverride}
            />
          </Stack>
        </LoadingContainer>
        <Copyright />
        <GetStartedChecklist />
      </Box>
    </VStack>
  );
}
