import React, { useEffect, useState } from 'react';
import {
  Badge,
  Box,
  HStack,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { useQuery } from 'react-query';
import {
  Execution,
  ExecutionStatus,
  Workflow,
} from '../../../../models/workflow';
import API from '../../../../api/API';
import { LoadingContainer } from '../../../LoadingContainer';
import ExecutionItem from '../ExecutionItem';
import {
  useNavigation,
  WorkflowManagerNavigationState,
} from '../../contexts/navigation/NavigationContext';

interface WorkflowsListProps {
  target: any;
  initialListingTab?: string;
}

interface TabOption<T> {
  key: string;
  label: string;
  status: ExecutionStatus | null;
  sort?: {
    field: keyof T;
    direction: 'asc' | 'desc';
  };
}

const executionTabOptions: TabOption<Execution>[] = [
  {
    key: 'active',
    label: 'Active',
    status: ExecutionStatus.ACTIVE,
    sort: { field: 'created_at', direction: 'desc' },
  },
  {
    key: 'scheduled',
    label: 'Scheduled',
    status: ExecutionStatus.SCHEDULED,
    sort: { field: 'scheduled_at', direction: 'asc' },
  },
  {
    key: 'waiting',
    label: 'Waiting',
    status: ExecutionStatus.WAITING,
    sort: { field: 'created_at', direction: 'desc' },
  },
  {
    key: 'finished',
    label: 'Finished',
    status: ExecutionStatus.FINISHED,
    sort: { field: 'finished_at', direction: 'desc' },
  },
  {
    key: 'aborted',
    label: 'Aborted',
    status: ExecutionStatus.ABORTED,
    sort: { field: 'aborted_at', direction: 'asc' },
  },
];

const workflowTabOptions: TabOption<Workflow>[] = [
  {
    key: 'available',
    label: 'Available',
    status: null,
    sort: { field: 'created_at', direction: 'desc' },
  },
];

const tabOptions = [...executionTabOptions, ...workflowTabOptions];

function WorkflowsList({
  target,
  initialListingTab = 'active',
}: WorkflowsListProps) {
  const { navigate, params } = useNavigation();

  // Use the navigation param if available, otherwise fall back to prop
  const activeListingTab = params.listingTab || initialListingTab;

  // Find initial tab index based on current active tab
  const initialTabIndex = tabOptions.findIndex(
    tab => tab.key === activeListingTab,
  );

  const [tabIndex, setTabIndex] = useState(
    initialTabIndex !== -1 ? initialTabIndex : 0,
  );

  // Update navigation params when tab changes
  const handleTabChange = (newIndex: number) => {
    setTabIndex(newIndex);
    // Update the navigation state with the new tab
    navigate(WorkflowManagerNavigationState.LIST, {
      ...params,
      listingTab: tabOptions[newIndex].key,
    });
  };

  // Fetch executions
  const {
    data: executions = [],
    isLoading: isLoadingExecutions,
    isFetching: isFetchingExecutions,
  } = useQuery(['targets', target.cuid, 'executions'], async () =>
    API.GetWorkflowExecutionsForTarget(
      target.cuid,
      tabOptions.map(t => t.key),
    ),
  );

  // Fetch available workflows
  const {
    data: availableWorkflows = [],
    isLoading: isLoadingAvailableWorkflows,
    isFetching: isFetchingAvailableWorkflows,
  } = useQuery(['targets', target.cuid, 'workflows', 'available'], async () =>
    API.GetAvailableWorkflowsForTarget(target.cuid),
  );

  // Categorize executions/workflows
  const categorizedExecutions = tabOptions.reduce((acc, tab) => {
    if (tab.status) {
      const filteredExecutions = executions.filter(
        execution => execution.status === tab.status,
      );
      acc[tab.key] = sortItems(
        filteredExecutions,
        tab.sort as TabOption<Execution>['sort'],
      );
    } else {
      acc[tab.key] = sortItems(
        availableWorkflows,
        tab.sort as TabOption<Workflow>['sort'],
      );
    }
    return acc;
  }, {} as Record<string, any[]>);

  return (
    <Box id="list-of-all">
      <Tabs
        size="lg"
        colorScheme="brand"
        index={tabIndex}
        onChange={handleTabChange}
      >
        <TabList>
          {tabOptions.map((tab, index) => (
            <Tab key={tab.key}>
              <HStack>
                <Text>{tab.label}</Text>
                {categorizedExecutions[tab.key]?.length > 0 && (
                  <Badge
                    px={2}
                    py={0.5}
                    rounded="full"
                    bg={
                      index === tabIndex
                        ? 'brand.50'
                        : useColorModeValue('neutral.200', 'neutral.700')
                    }
                    color={index === tabIndex ? 'brand.600' : 'inherit'}
                  >
                    {categorizedExecutions[tab.key]?.length}
                  </Badge>
                )}
              </HStack>
            </Tab>
          ))}
        </TabList>

        <LoadingContainer
          isLoading={
            isLoadingExecutions ||
            isLoadingAvailableWorkflows ||
            isFetchingAvailableWorkflows ||
            isFetchingExecutions
          }
        >
          <TabPanels>
            {tabOptions.map(tab => (
              <TabPanel key={tab.key} px={4} py={8}>
                <Stack gap={2} w="full">
                  {categorizedExecutions[tab.key].length > 0 ? (
                    categorizedExecutions[tab.key].map(item => (
                      <ExecutionItem
                        key={item.cuid}
                        target={target}
                        workflow={'workflow' in item ? item.workflow : item}
                        execution={'workflow' in item ? item : undefined}
                      />
                    ))
                  ) : (
                    <Box textAlign="center" w="full">
                      <Text color="neutral.500" fontStyle="italic">
                        No {tab.label.toLowerCase()} workflows
                      </Text>
                    </Box>
                  )}
                </Stack>
              </TabPanel>
            ))}
          </TabPanels>
        </LoadingContainer>
      </Tabs>
    </Box>
  );
}

const sortItems = <T extends Record<string, any>>(
  items: T[],
  sort?: TabOption<T>['sort'],
) => {
  if (!sort) return items;

  return [...items].sort((a, b) => {
    const aValue = a[sort.field];
    const bValue = b[sort.field];

    // Handle undefined values
    if (!aValue && !bValue) return 0;
    if (!aValue) return 1;
    if (!bValue) return -1;

    // Sort based on direction
    const comparison = aValue > bValue ? 1 : -1;
    return sort.direction === 'desc' ? -comparison : comparison;
  });
};

export default WorkflowsList;
