import React from 'react';
import { Execution } from '../../../../models/workflow';
import { EventFilters } from '../../../../models/event';
import ActivityFeedWidget from '../../../ActivityFeedWidget';

interface ExecutionTimelineProps {
  execution: Execution;
}

function ExecutionTimeline({ execution }: ExecutionTimelineProps) {
  const eventFilters: EventFilters = {
    inventory_models: [execution.target.cuid],
    executions: [execution.cuid],
  };

  return <ActivityFeedWidget variant={'workflows'} filters={eventFilters} />;
}

export default ExecutionTimeline;
